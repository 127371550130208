export const visualeditorLocalization = {
  'Enter a text': {
    eng: 'Enter a text',
    ru: 'Введите текст',
    pt: 'Digite uma mensagem',
  },
  'Enter a number': {
    eng: 'Enter a number',
    ru: 'Введите число',
    pt: 'Digite um número',
  },
  'Enter a phone number': {
    eng: 'Enter a phone number',
    ru: 'Введите номер телефона',
    pt: 'Digite um número de telefone',
  },
  'Invalid phone number': {
    eng: 'Invalid phone number',
    ru: 'Некорректный номер телефона',
    pt: 'Número de telefone inválido',
  },
  'Enter the correct date / time': {
    eng: 'Enter the correct date/time',
    ru: 'Введите корректное значение даты/времени',
    pt: 'Digite a data/hora correta',
  },
  'Enter a number from 1 to 5': {
    eng: 'Enter a number from 1 to 5',
    ru: 'Введите число от 1 до 5',
    pt: 'Digite um número de 1 a 5',
  },
  Help: {
    eng: 'Help',
    ru: 'Помощь',
    pt: 'Ajuda',
    cn: '帮助',
  },
  Phone: {
    eng: 'Phone',
    ru: 'Телефон',
    pt: 'Telefone',
    cn: '电话',
  },
  Text: {
    eng: 'Text',
    ru: 'Текст',
    pt: 'Texto',
    cn: '文字',
  },
  Syntez: {
    eng: 'Text-to-speech',
    ru: 'Синтез речи',
    pt: 'Texto para fala',
  },
  Video: {
    eng: 'Video',
    ru: 'Видео',
    pt: 'Vídeo',
  },
  Intents: {
    eng: 'Intents',
    ru: 'Интенты',
    pt: 'Intenções',
    cn: '句子',
  },
  'Create your own intent': {
    eng: 'Create your own intent',
    ru: 'Сделать свой интент',
    pt: 'Criar sua própria intenção',
    cn: '句子',
  },
  'Number input': {
    eng: 'num to $var',
    ru: 'num to $var',
    pt: 'num to $var',
    cn: '数字输入',
  },
  'Text input': {
    eng: 'text to $var',
    ru: 'text to $var',
    pt: 'text to $var',
    cn: '文字输入',
  },
  'Transfer to operator': {
    eng: 'Transfer to agent',
    ru: 'Перевод на оператора',
    pt: 'Transferência para o agente',
    cn: '跳转到运营商',
  },
  Transition: {
    eng: 'Transition',
    ru: 'Переход',
    pt: 'Transição',
    cn: '跳转',
  },
  Intent: {
    eng: 'Create an intent',
    ru: 'Создать интент',
    pt: 'Criar uma intenção',
    cn: '句组',
  },
  Block: {
    eng: 'Block',
    ru: 'Блок',
    pt: 'Bloco',
    cn: '单元',
  },
  Condition: {
    eng: 'Condition',
    ru: 'Условие',
    pt: 'Condição',
    cn: '条件',
  },
  'Http request': {
    eng: 'HTTP request',
    ru: 'HTTP-запрос',
    pt: 'Solicitação HTTP',
    cn: 'Http 请求',
  },
  "User's input": {
    eng: 'User says',
    ru: 'Реплики пользователя',
    pt: 'O usuário diz',
    cn: '用户回复',
  },
  "Bot's output": {
    eng: 'Bot response',
    ru: 'Реплики бота',
    pt: 'Resposta do bot',
    cn: ' ',
  },
  'More blocks': {
    eng: 'More blocks',
    ru: 'Еще блоки',
    pt: 'Mais blocos',
    cn: ' ',
  },
  'Extended capabilities': {
    eng: 'Extended capabilities',
    ru: 'Продвинутые инструменты',
    pt: 'Capacidades estendidas',
    cn: ' ',
  },
  'Additional menu emotions': {
    eng: 'Emotions',
    ru: 'Эмоции',
    pt: 'Emoções',
    cn: ' ',
  },
  Actions: {
    eng: 'Actions',
    ru: 'Действия',
    pt: 'Ações',
    cn: '操作',
  },
  Blocks: {
    eng: 'Blocks menu',
    ru: 'Блоки',
    pt: 'Menu de blocos',
    cn: '单元',
  },
  'Image block': {
    eng: 'Image',
    ru: 'Изображение',
    pt: 'Imagem',
    cn: '图案',
  },
  'Can not add block "INPUT" to already exist': {
    eng: 'Cannot add block "INPUT" to the existing one',
    ru: "Нельзя добавить блок 'INPUT' к уже существующему",
    pt: 'Não é possível adicionar "INPUT" de bloco ao bloco existente',
  },
  'Phone number': {
    eng: 'phone to $var',
    ru: 'phone to $var',
    pt: 'phone to $var',
  },
  'Input date': {
    eng: 'Input date',
    ru: 'Ввод даты',
    pt: 'Data da entrada',
  },
  'End session': {
    eng: 'End of script',
    ru: 'Завершение сценария',
    pt: 'Fim do script',
  },
  Image: {
    eng: 'Image',
    ru: 'Изображение',
    pt: 'Imagem',
  },
  'Available in commercial version': {
    eng: 'Available in commercial version',
    ru: 'Будет доступно в коммерческой версии',
    pt: 'Disponível na versão comercial',
  },
  'Input date link': {
    eng: 'Input date link',
    ru: 'Input date link',
    pt: 'Link da data de entrada',
  },
  'AddingMenu CrmIntegration': {
    eng: 'CRM Integration',
    ru: 'Интеграция с CRM',
    pt: 'Integração de CRM',
  },
  Audios: {
    eng: 'Audio',
    ru: 'Аудио',
    pt: 'Áudio',
  },
  'Audios calls': {
    eng: 'Voice recording',
    ru: 'Запись диктора',
    pt: 'Gravação de voz',
  },
  audios: {
    eng: 'Audio',
    ru: 'Аудио',
    pt: 'Áudio',
  },
  'AddingMenu timeout': {
    eng: 'Timeout settings',
    ru: 'Переход по таймауту',
    pt: 'Configurações de tempo limite',
  },
  'AddingMenu Sms': {
    eng: 'Send SMS',
    ru: 'Отправка SMS',
    pt: 'Enviar SMS',
  },
  'AddingMenu GoogleSheets': {
    eng: 'Google Sheets integration',
    ru: 'Интеграция с Google Таблицами',
    pt: 'Integração do Planilhas Google',
  },
  'AddingMenu Timetable': {
    eng: 'Schedule',
    ru: 'Расписание',
    pt: 'Agenda',
  },
  'Timeout title': {
    eng: 'Timeout settings',
    ru: 'Переход по таймауту',
    pt: 'Configurações de tempo limite',
  },
  Save: {
    eng: 'Save',
    ru: 'Сохранить',
    pt: 'Salvar',
    cn: '保存',
  },
  Remove: {
    eng: 'Remove',
    ru: 'Удалить',
    pt: 'Remover',
    cn: '删除',
  },
  Link: {
    eng: 'Link',
    ru: 'Ссылка',
    pt: 'Link',
  },
  'Save link': {
    eng: 'Save link',
    ru: 'Сохранить ссылку',
    pt: 'Salvar link',
  },
  Button: {
    eng: 'Button',
    ru: 'Кнопка',
    pt: 'Botão',
    cn: '按钮',
  },
  'No color': {
    eng: 'No fill',
    ru: 'Без заливки',
    pt: 'Sem preenchimento',
  },
  DateTime: {
    eng: 'Input date',
    ru: 'Ввод даты',
    pt: 'Data da entrada',
  },
  CrmIntegration: {
    eng: 'Save to CRM',
    ru: 'Запись в CRM',
    pt: 'Salvar em CRM',
  },
  To: {
    eng: 'To',
    ru: 'До',
    pt: 'Até',
    cn: '到',
  },
  to: {
    eng: 'to',
    ru: 'до',
    pt: 'até',
    cn: '到',
  },
  'Add screen': {
    eng: 'Add screen',
    ru: 'Добавить экран',
    pt: 'Adicionar tela',
  },
  'VisualEditor Unsupported browser': {
    eng: 'Unfortunately, the script editor $[1] does not work correctly in your browser',
    ru: 'К сожалению, редактор сценариев $[1] работает некорректно в Вашем браузере',
    pt: 'Infelizmente, o editor de script $[1] não funciona corretamente no seu navegador',
  },
  'Download Google Chrome': {
    eng: 'Download Google Chrome',
    ru: 'Скачать Google Chrome',
    pt: 'Baixar Google Chrome',
  },
  'To cancel last changes press': {
    eng: 'To discard the latest changes, press',
    ru: 'Для отмены действия нажмите',
    pt: 'Para descartar as alterações mais recentes, pressione',
  },
  Cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
    pt: 'Cancelar',
    cn: '取消',
  },
  'ImageEditModal from computer': {
    eng: 'From your computer',
    ru: 'С компьютера',
    pt: 'No seu computador',
  },
  'ImageEditModal from link': {
    eng: 'Using link',
    ru: 'По ссылке',
    pt: 'Usando link',
  },
  'ImageEditModal pseudolink': {
    eng: 'Attach file',
    ru: 'Прикрепите файл',
    pt: 'Anexe o arquivo',
  },
  'ImageEditModal drop text': {
    eng: 'or drag it here',
    ru: 'или перетащите его сюда',
    pt: 'ou arraste-o para cá',
  },
  'ImageEditModal offline': {
    eng: 'Upload failed. Connection error',
    ru: 'Загрузка не удалась. Соединение прервано.',
    pt: 'Falha no carregamento. Erro de conexão',
  },
  ImageEditModal: {
    eng: '',
    ru: '',
  },
  'ImageEditModal Drop file image': {
    eng: 'Drop the file',
    ru: 'Отпустите файл',
    pt: 'Solte o arquivo',
  },
  'ImageEditModal Drop file image subtext': {
    eng: 'to upload to server',
    ru: 'для загрузки на сервер',
    pt: 'para carregar para o servidor',
  },
  'AudiosEditModal maintext 0': {
    eng: 'Audio must be:',
    ru: 'Рекомендации для загружаемых аудио:',
    pt: 'O áudio deve ser:',
  },
  'AudiosEditModal subtext 0': {
    eng: 'for phone calls: file extension: .wav, 1 channel (mono), 16-bit (PCM), sample rate: 8–48 kHz;',
    ru: 'исходящие звонки: формат .wav, 1 канал (моно), кодирование 16-bit (PCM), частота дискретизации 8–48 кГц',
    pt: 'para chamadas telefônicas: ramal: .wav. 1 canal (mono), 16 bits (PCM), taxa de amostra: 8–48 kHz;',
  },
  'AudiosEditModal subtext 1': {
    eng: 'for voice assistants: file extension: .mp3, not longer than 240 seconds;',
    ru: 'голосовые ассистенты: формат .mp3, длительность не более 240 секунд',
    pt: 'para assistentes de voz: extensão de arquivo: .mp3, não superior a 240 segundos;',
  },
  'AudiosEditModal subtext 2': {
    eng: 'file size should be less than 10 Mb;',
    ru: 'размер файла не более 10МБ',
    pt: 'o tamanho do arquivo deve ser menor que 10 Mb;',
  },
  'AudiosEditModal subtext 3': {
    eng: 'Check the documentation for more',
    ru: 'Подробные рекомендации смотрите в',
    pt: 'Consulte a documentação para obter mais informações',
  },
  'AudiosEditModal subtext 3 documents': {
    eng: 'details',
    ru: 'документации',
    pt: 'detalhes',
  },
  'AudiosEditModal file extension error': {
    eng: 'Upload failed. Wrong format',
    ru: 'Загрузка $[1] не удалась. Неверное расширение файла.',
    pt: 'Falha no carregamento. Formato incorreto',
  },
  'AudiosEditModal file size error': {
    eng: 'The selected file is in the wrong format. Please select an .mp3 or .wav file not larger than 10 Mb.',
    ru: 'Неверный формат аудио файла. Загружаемый файл должен быть в .mp3 или .wav формате и не превышать 10 мб.',
    pt: 'O arquivo selecionado está no formato errado. Selecione um arquivo .mp3 ou .wav menor que 10 Mb.',
  },
  'AudiosEditModal audios list': {
    eng: 'Audio list',
    ru: 'Список аудио',
    pt: 'Lista de áudios',
  },
  'AudiosEditModal info message': {
    eng: 'You can paste script variables into the URL. The bot will upload the file automatically during the script running.',
    ru: 'В URL можно вставлять переменные сценария. Бот загрузит файл автоматически во время выполнения сценария',
    pt: 'Você pode colar variáveis de script no URL. O bot carregará o arquivo automaticamente durante a execução do script.',
  },
  'AudiosEditModal add': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'AudiosEditModal audio url': {
    eng: 'Enter audio file URL',
    ru: 'Введите URL аудио файла',
    pt: 'Digite o URL do arquivo de áudio',
  },
  'AudioEditModal TextArea https error': {
    eng: 'The URL is incorrect. Please use an HTTPS link.',
    ru: 'Значение URL некорректно. Ссылка должна быть вида HTTPS.',
    pt: 'O URL está incorreto. Use um link HTTPS.',
  },
  'AudiosEditModal offline': {
    eng: 'File was not saved. Please try again. If the error reappears, please contact support.',
    ru: 'Не удалось сохранить файл. Попробуйте повторить еще раз. Если ошибка повторится, обратитесь в техподдержку.',
    pt: 'O arquivo não foi salvo. Tente novamente. Se o erro reaparecer, fale com o suporte.',
  },
  'AudiosEditModal i help': {
    eng: 'Bot will randomly use the audio from the list',
    ru: 'Бот выберет добавленное аудио из списка случайным образом',
    pt: 'O bot usará aleatoriamente o áudio da lista',
  },
  AudiosEditModal: {
    eng: '',
    ru: '',
  },
  'Choose ready intent': {
    eng: 'Choose a built-in intent',
    ru: 'Выбрать готовый интент',
    pt: 'Escolher uma intenção incorporada',
  },
  Spravka: {
    eng: 'Help',
    ru: 'Справка',
    pt: 'Ajuda',
  },
  'Choose ready intent title': {
    eng: 'Choose the intent',
    ru: 'Выбор готовых интентов',
    pt: 'Escolha a intenção',
  },
  'Choose ready intent body p': {
    eng: 'Choose one or more built-in intents',
    ru: 'Выберите один или более интентов',
    pt: 'Escolha uma ou mais intenções internas',
  },
  'Choose ready intent choose': {
    eng: 'Choose',
    ru: 'Выбрать',
    pt: 'Escolher',
  },
  'ChooseReadyIntent Cities': {
    eng: 'City',
    ru: 'Город',
    pt: 'Cidade',
  },
  'ChooseReadyIntent Capitals': {
    eng: 'Capital',
    ru: 'Столица',
    pt: 'Capital',
  },
  'ChooseReadyIntent Counties': {
    eng: 'Country',
    ru: 'Страна',
    pt: 'País',
  },
  'ChooseReadyIntent Names': {
    eng: 'Name',
    ru: 'Имена',
    pt: 'Nome',
  },
  'ChooseReadyIntent Gender': {
    eng: 'Gender',
    ru: 'Пол',
    pt: 'Gênero',
  },
  'ChooseReadyIntent Phone': {
    eng: 'Phone',
    ru: 'Номер телефона',
    pt: 'Telefone',
  },
  'ChooseReadyIntent Date and time': {
    eng: 'Date&Time',
    ru: 'Дата и время',
    pt: 'Data e hora',
  },
  'ChooseReadyIntent Normal': {
    eng: 'Neutral reaction',
    ru: 'Нейтральная оценка',
    pt: 'Reação neutra',
  },
  'ChooseReadyIntent Negative': {
    eng: 'Negative reaction',
    ru: 'Негативная оценка',
    pt: 'Reação negativa',
  },
  'ChooseReadyIntent Approval': {
    eng: 'Positive reaction',
    ru: 'Положительная оценка',
    pt: 'Reação positiva',
  },
  'ChooseReadyIntent Insults': {
    eng: 'Explicit language',
    ru: 'Нецензурная лексика',
    pt: 'Linguagem explícita',
  },
  'ChooseReadyIntent Uncertainty': {
    eng: 'Doubt',
    ru: 'Сомнение',
    pt: 'Dúvida',
  },
  'ChooseReadyIntent Thanks': {
    eng: 'Gratitude',
    ru: 'Благодарность',
    pt: 'Gratidão',
  },
  'ChooseReadyIntent Parting': {
    eng: 'Parting',
    ru: 'Прощание',
    pt: 'Despedida',
  },
  'ChooseReadyIntent Hello': {
    eng: 'Greeting',
    ru: 'Приветствие',
    pt: 'Cumprimento',
  },
  'ChooseReadyIntent Negation': {
    eng: 'Disagreement',
    ru: 'Отказ',
    pt: 'Discordância',
  },
  'ChooseReadyIntent Agreement': {
    eng: 'Agreement',
    ru: 'Согласие',
    pt: 'Concordância',
  },
  'ChooseReadyIntent E-mail': {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail',
  },
  'ChooseReadyIntent Switch to operator': {
    eng: 'Transfer to agent',
    ru: 'Запрос на диалог с человеком',
    pt: 'Transferência para o agente',
  },
  'ChooseReadyIntent Redialrequest': {
    eng: 'Request a callback',
    ru: 'Запрос на повторный звонок',
    pt: 'Solicitar um retorno de chamada',
  },
  'ChooseReadyIntent Phraserequest': {
    eng: 'Request to repeat the phrase',
    ru: 'Запрос на повтор фразы',
    pt: 'Solicitar a repetição da frase',
  },
  'ChooseReadyIntent Canthear': {
    eng: 'Cannot hear well',
    ru: 'Плохо слышно',
    pt: 'Não consegue ouvir bem',
  },
  'ChooseReadyIntent Offeragreement': {
    eng: 'Consent to listen to the offer',
    ru: 'Согласие прослушать предложение',
    pt: 'Consentimento para escutar a oferta',
  },
  'ChooseReadyIntent Offerreject': {
    eng: 'Refusal to listen to the offer',
    ru: 'Отказ прослушать предложение',
    pt: 'Recusa para escutar a oferta',
  },
  'ChooseReadyIntent SMSofferagreement': {
    eng: 'Consent to receive SMS',
    ru: 'Согласие получить SMS',
    pt: 'Consentimento para receber SMS',
  },
  'js condition': {
    eng: 'JavaScript condition',
    ru: 'JavaScript-выражение',
    pt: 'Condição de JavaScript',
    cn: 'JS 条件',
  },
  'ModalEdit CrmIntegration label': {
    eng: 'Platform',
    ru: 'Система',
    pt: 'Plataforma',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION': {
    eng: 'Create lead',
    ru: 'Создать лида',
    pt: 'Criar cliente em potencial',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION': {
    eng: 'Create new deal',
    ru: 'Создать новую сделку',
    pt: 'Criar novo cliente em potencial',
  },
  'ModalEdit CrmIntegration BITRIX should choose one parametr': {
    eng: 'Select and fill at least one field to use this action',
    ru: 'Для использования блока необходимо заполнить как минимум один параметр',
    pt: 'Selecione e preencha, pelo menos, um campo para usar esta ação',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE': {
    eng: 'Update an existing deal',
    ru: 'Обновить существующую сделку',
    pt: 'Atualizar acordo existente',
  },
  'ModalEdit CrmIntegration BITRIX CONTACT_CREATION': {
    eng: 'Create contact',
    ru: 'Создать контакт',
    pt: 'Criar contato',
  },
  'ModalEdit CrmIntegration BITRIX ISSUE_CREATION': {
    eng: 'Create task',
    ru: 'Создать задачу',
    pt: 'Criar tarefa',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION TITLE': {
    eng: 'Lead name',
    ru: 'Название лида',
    pt: 'Nome do cliente em potencial',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION NAME': {
    eng: 'First Name',
    ru: 'Имя',
    pt: 'Primeiro nome',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION LAST_NAME': {
    eng: 'Last Name',
    ru: 'Фамилия',
    pt: 'Sobrenome',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION ADDRESS': {
    eng: 'Address',
    ru: 'Адрес',
    pt: 'Endereço',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION ADDRESS_CITY': {
    eng: 'City',
    ru: 'Город',
    pt: 'Cidade',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION ADDRESS_COUNTRY': {
    eng: 'Country',
    ru: 'Страна',
    pt: 'País',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION ADDRESS_COUNTRY_CODE': {
    eng: 'Country code',
    ru: 'Код страны',
    pt: 'Código do país',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION ADDRESS_POSTAL_CODE': {
    eng: 'Postal code',
    ru: 'Почтовый индекс',
    pt: 'CEP',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION ADDRESS_PROVINCE': {
    eng: 'Province',
    ru: 'Область',
    pt: 'Província',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION ADDRESS_REGION': {
    eng: 'Region',
    ru: 'Район',
    pt: 'Região',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION BIRTHDATE': {
    eng: 'Birth date',
    ru: 'Дата рождения',
    pt: 'Data de nascimento',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION COMMENTS': {
    eng: 'Comments',
    ru: 'Комментарий',
    pt: 'Comentários',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION EMAIL_HOME': {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION IM_OTHER': {
    eng: 'IM',
    ru: 'Мессенджеры',
    pt: 'MI',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION PHONE_HOME': {
    eng: 'Phone number',
    ru: 'Телефон контакта',
    pt: 'Número de telefone',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION POST': {
    eng: 'Job title',
    ru: 'Должность',
    pt: 'Cargo',
  },
  'ModalEdit CrmIntegration BITRIX LEAD_CREATION WEB_OTHER': {
    eng: 'Website url',
    ru: 'URL ресурсов лида',
    pt: 'URL do site',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION TITLE': {
    eng: 'Name',
    ru: 'Название',
    pt: 'Nome',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION ADDITIONAL_INFO': {
    eng: 'Additional Information',
    ru: 'Дополнительная информация',
    pt: 'Informações adicionais',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION ASSIGNED_BY_ID': {
    eng: 'Associated with user by ID',
    ru: 'Связано с пользователем по ID',
    pt: 'Associada ao usuário por ID',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION BEGINDATE': {
    eng: 'Start date',
    ru: 'Дата начала',
    pt: 'Data inicial',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION CLOSEDATE': {
    eng: 'Date of completion',
    ru: 'Дата завершения',
    pt: 'Data de conclusão',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION COMMENTS': {
    eng: 'Comments',
    ru: 'Комментарии',
    pt: 'Comentários',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION COMPANY_ID': {
    eng: 'Linked Company ID',
    ru: 'Идентификатор привязанной компании',
    pt: 'ID da empresa vinculada',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION CURRENCY_ID': {
    eng: 'Deal currency ID',
    ru: 'Идентификатор валюты сделки',
    pt: 'ID da moeda do acordo',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION OPPORTUNITY': {
    eng: 'Opportunity',
    ru: 'Сумма',
    pt: 'Oportunidade',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION PROBABILITY': {
    eng: 'Probability',
    ru: 'Вероятность',
    pt: 'Probabilidade',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION STAGE_ID': {
    eng: 'Stage ID',
    ru: 'Идентификатор стадии',
    pt: 'ID do estágio',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION STAGE_SEMANTIC_ID': {
    eng: 'Stage semantic ID',
    ru: 'Имя',
    pt: 'ID semântico do estágio',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION TAX_VALUE': {
    eng: 'Tax value',
    ru: 'Ставка налога',
    pt: 'Valor do imposto',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION TYPE_ID': {
    eng: 'Deal type',
    ru: 'Тип сделки',
    pt: 'Tipo de acordo',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_CREATION UTM_CAMPAIGN': {
    eng: 'Campaign designation',
    ru: 'Обозначение рекламной кампании',
    pt: 'Designação de campanha',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE TITLE': {
    eng: 'Name',
    ru: 'Название',
    pt: 'Nome',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE ADDITIONAL_INFO': {
    eng: 'Additional information',
    ru: 'Дополнительная информация',
    pt: 'Informações adicionais',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE ASSIGNED_BY_ID': {
    eng: 'Associated with user by ID',
    ru: 'Связано с пользователем по ID',
    pt: 'Associado com usuário por ID',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE BEGINDATE': {
    eng: 'Start date',
    ru: 'Дата начала',
    pt: 'Data inicial',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE CLOSEDATE': {
    eng: 'Date of completion',
    ru: 'Дата завершения',
    pt: 'Data de conclusão',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE COMMENTS': {
    eng: 'Comments',
    ru: 'Комментарии',
    pt: 'Comentários',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE COMPANY_ID': {
    eng: 'Linked Company ID',
    ru: 'Идентификатор привязанной компании',
    pt: 'ID da empresa vinculada',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE CURRENCY_ID': {
    eng: 'Deal currency ID',
    ru: 'Идентификатор валюты сделки',
    pt: 'ID da moeda do acordo',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE OPPORTUNITY': {
    eng: 'Opportunity',
    ru: 'Сумма',
    pt: 'Oportunidade',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE PROBABILITY': {
    eng: 'Probability',
    ru: 'Вероятность',
    pt: 'Probabilidade',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE STAGE_ID': {
    eng: 'Stage ID',
    ru: 'Идентификатор стадии',
    pt: 'ID do estágio',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE STAGE_SEMANTIC_ID': {
    eng: 'Stage semantic ID',
    ru: 'Имя',
    pt: 'ID semântico do estágio',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE TAX_VALUE': {
    eng: 'Tax value',
    ru: 'Ставка налога',
    pt: 'Valor do imposto',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE TYPE_ID': {
    eng: 'Deal type',
    ru: 'Тип сделки',
    pt: 'Tipo de acordo',
  },
  'ModalEdit CrmIntegration BITRIX DEAL_UPDATE UTM_CAMPAIGN': {
    eng: 'Campaign Designation',
    ru: 'Обозначение рекламной кампании',
    pt: 'Designação de campanha',
  },
  'ModalEdit CrmIntegration add parametr': {
    eng: 'Add a field',
    ru: 'Добавить параметр',
    pt: 'Adicione um campo',
  },
  'ModalEdit CrmIntegration choose parametr title': {
    eng: 'Fields selection',
    ru: 'Выбор параметров',
    pt: 'Seleção de campos',
  },
  'ModalEdit CrmIntegration choose parametr subtitle': {
    eng: 'Choose one or more fields',
    ru: 'Выберите один или несколько параметров',
    pt: 'Escolha um ou mais campos',
  },
  'ModalEdit CrmIntegration task': {
    eng: 'Task',
    ru: 'Задача',
    pt: 'Tarefa',
  },
  'ModalEdit CrmIntegration fields list title': {
    eng: 'Field list for saving',
    ru: 'Список параметров для записи',
    pt: 'Lista do campo para salvamento',
  },
  "ModalEdit CrmIntegration Required Parametr can't be empty": {
    eng: 'The required field must be completed',
    ru: 'Обязательный параметр должен быть заполнен',
    pt: 'O campo obrigatório deve ser preenchido',
  },
  'ModalEdit CrmIntegration Go to channels': {
    eng: 'Go to channels',
    ru: 'Перейти к каналам',
    pt: 'Acessar canais',
  },
  'ModalEdit CrmIntegration Connect title': {
    eng: 'Bitrix24 connection',
    ru: 'Подключение Битрикс24',
    pt: 'Conexão Bitrix24',
  },
  'ModalEdit CrmIntegration Connect text': {
    eng: 'You need to connect a Bitrix24 channel to enable CRM interaction.\n $[1] integration with other common CRMs will be available soon',
    ru: 'Для взаимодействия с CRM сначала нужно подключить канал Битрикс24.\n Скоро будет доступна интеграция $[1] с другими популярными CRM',
    pt: 'Você precisa conectar um canal Bitrix24 para ativar a interação de CRM.\n A integração do $[1] com outros CRMs comuns estará disponível em breve',
  },
  'SwitchParams BITRIX': {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
    pt: 'Bitrix24',
  },
  BITRIX: {
    eng: 'Bitrix24',
    ru: 'Битрикс24',
    pt: 'Bitrix24',
  },
  'SwitchParams LIVETEX': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'SwitchParams WEBIM': {
    eng: 'Webim',
    ru: 'Webim',
    pt: 'Webim',
  },
  'SwitchParams INCOMING_JIVOSITE': {
    eng: 'JivoChat',
    ru: 'Jivo',
    pt: 'JivoChat',
  },
  'SwitchParams INCOMING_WEBIM2': {
    eng: 'Webim v2',
    ru: 'Webim v2',
    pt: 'Webim v2',
  },
  'SwitchParams INBOUND_LIVETEX': {
    eng: 'LiveTex',
    ru: 'LiveTex',
    pt: 'LiveTex',
  },
  'SwitchParams THREADS': {
    eng: 'edna.chatCenter',
    ru: 'edna.chatCenter',
    pt: 'edna.chatCenter',
  },
  'CrmIntegrationEditModal title': {
    eng: 'Save to CRM',
    ru: 'Запись в CRM',
    pt: 'Salvar em CRM',
  },
  'Text block': {
    eng: 'Text',
    ru: 'Текст',
    pt: 'Texto',
    cn: '文本单元',
  },
  'Bot answer': {
    eng: 'Bot says',
    ru: 'Реплика бота',
    pt: 'O bot diz',
  },
  on: {
    eng: 'ON',
    ru: 'ВКЛ',
    pt: 'LIGADO',
  },
  On: {
    eng: 'On',
    ru: 'Включить',
    pt: 'Ativado',
  },
  'Add variant': {
    eng: 'Add option',
    ru: 'Добавить другую реплику',
    pt: 'Adicionar opção',
  },
  'Bot says one of the variants randomly': {
    eng: 'Bot chooses one of the options randomly',
    ru: 'Реплика каждый раз выбирается случайным образом',
    pt: 'O bot escolhe uma das opções aleatoriamente',
  },
  'Speech synthesis markup': {
    eng: 'Speech synthesis markup',
    ru: 'Разметка синтеза речи',
    pt: 'Marcação de síntese de fala',
  },
  'Use HTML markup': {
    eng: 'Use HTML markup in the message',
    ru: 'Использовать в сообщении HTML-разметку',
    pt: 'Usar marcação HTML na mensagem',
  },
  'Incorrect text value': {
    eng: 'Text field cannot be empty or contain <script> tags',
    ru: 'Реплика не может состоять из пробелов и содержать тег <script>',
    pt: 'O campo de texto não pode ficar vazio ou conter tagas <script>',
  },
  'Extended options': {
    eng: 'Additional options',
    ru: 'Дополнительные опции',
    pt: 'Opções adicionais',
  },
  'Select voice for Alexa': {
    eng: 'Select voice for Alexa',
    ru: 'Выбор голоса для Alexa',
    pt: 'Selecionar voz da Alexa',
  },
  OnAlexa: {
    eng: 'On',
    ru: 'Вкл',
    pt: 'Ligado',
  },
  'Speech synthesis': {
    eng: 'Speech synthesis',
    ru: 'Синтез речи',
    pt: 'Síntese de fala',
  },
  'Voice for Alexa': {
    eng: 'Voice for Alexa',
    ru: 'Голос для Alexa',
    pt: 'Voz da Alexa',
  },
  'New header name and value': {
    eng: 'Header name and value',
    ru: 'Имя заголовка и значение',
    pt: 'Valor e nome do cabeçalho',
    cn: '头部名称和值',
  },
  'New variable name and value': {
    eng: 'Variable name and value',
    ru: 'Имя и значение переменной',
    pt: 'Valor e nome da variável',
    cn: '变量名称和值',
  },
  Method: {
    eng: 'Method',
    ru: 'Метод',
    pt: 'Método',
    cn: '方法',
  },
  URL: {
    eng: 'URL',
    ru: 'URL',
    pt: 'URL',
    cn: 'URL链接',
  },
  Header: {
    eng: 'Header',
    ru: 'Заголовок',
    pt: 'Cabeçalho',
    cn: '头部',
  },
  Value: {
    eng: 'Value',
    ru: 'Значение',
    pt: 'Valor',
    cn: '值',
  },
  'Var name': {
    eng: 'Variable name',
    ru: 'Имя переменной',
    pt: 'Nome da variável',
    cn: '变量名称',
  },
  'Unexpected name': {
    eng: 'Unexpected name',
    ru: 'Невалидное имя',
    pt: 'Nome inesperado',
  },
  'Valid JavaScript variable name': {
    eng: 'Use Aa–Zz, _, 0–9',
    ru: 'Используйте Aa–Zz, _, 0–9',
    pt: 'Usar Aa–Zz, _, 0–9',
  },
  'ImageEditModal files accept description': {
    eng: 'File should be in .jpg, .png, or .gif',
    ru: 'Допускаются файлы: .jpg, .png и .gif',
    pt: 'O arquivo deve estar em .jpg, .png, ou .gif',
  },
  'ImageEditModal files size description': {
    eng: 'File size cannot exceed 10 MB',
    ru: 'Размер файла не должен превышать 10 Мб',
    pt: 'O tamanho do arquivo não pode exceder 10 MB',
  },
  'ImageEditModal file extension error': {
    eng: 'Upload failed. Wrong format',
    ru: 'Загрузка не удалась. Неверное расширение файла.',
    pt: 'Falha no carregamento. Formato incorreto',
  },
  'ImageEditModal file size error': {
    eng: 'Upload failed. File exceeds 10 Mb',
    ru: 'Загрузка не удалась. Размер файла превышает масимальный размер 10 МБ',
    pt: 'Falha de carregamento. O arquivo é maior que 10 Mb',
  },
  'ImageEditModal delete file': {
    eng: 'Delete file',
    ru: 'Удалить файл',
    pt: 'Excluir arquivo',
  },
  'ImageEditModal image url': {
    eng: 'Enter image URL',
    ru: 'Введите URL-картинки',
    pt: 'Digite o URL da imagem',
  },
  'ImageEditModal retry': {
    eng: 'Retry',
    ru: 'Повторить',
    pt: 'Tentar novamente',
  },
  'ImageEditModal vars can be added': {
    eng: 'You can paste script variables into the URL.',
    ru: 'В URL можно вставлять переменные сценария',
    pt: 'Você pode colar variáveis de script no URL.',
  },
  'ImageEditModal info message': {
    eng: 'The bot will upload the image automatically while the script is running',
    ru: 'Бот загрузит картинку автоматически во время выполнения сценария',
    pt: 'O bot carregará a imagem automaticamente enquanto o script estiver sendo executado',
  },
  'Error message': {
    eng: 'Error message',
    ru: 'Сообщение об ошибке',
    pt: 'Mensagem de erro',
  },
  'JS valid identifier names': {
    eng: 'Use Aa–Zz, _, and 0–9 characters only. The name should start with Aa–Zz. JS reserved words are not allowed.',
    ru: 'Используйте Aa–Zz, _, 0–9. Начните имя с Aa–Zz. Нельзя использовать зарезервированные слова JS.',
    pt: 'Use apenas caracteres Aa–Zz, _ e 0–9. O nome deve começar com Aa–Zz. Palavras reservadas JS não são permitidas.',
  },
  from: {
    eng: 'from',
    ru: 'из',
    pt: 'de',
    cn: '从',
  },
  From: {
    eng: 'From',
    ru: 'От',
    pt: 'De',
    cn: '从',
  },
  'Error messages': {
    eng: 'Error messages',
    ru: 'Сообщение об ошибке',
    pt: 'Mensagens de erro',
    cn: '错误信息',
  },
  Message: {
    eng: 'Message',
    ru: 'Сообщение',
    pt: 'Mensagem',
    cn: '信息',
  },
  'Check the entered values': {
    eng: 'Check the entered values',
    ru: 'Проверьте правильность ввода',
    pt: 'Confira os valores digitados',
  },
  MetaAttention: {
    eng: 'Attention. All buttons on above block will be removed!',
    ru: 'Внимание: при добавлении это блока кнопки в вышестоящих элементах будут удалены!',
    pt: 'Atenção: todos os botões no bloco acima serão removidos!',
    cn: '注意：上面单元的所有按钮将会被删除！',
  },
  Pattern: {
    eng: 'Pattern',
    ru: 'Шаблон',
    pt: 'Padrão',
    cn: '模板',
  },
  Example: {
    eng: 'Example',
    ru: 'Пример',
    pt: 'Exemplo',
    cn: '例子',
  },
  'Phrase example': {
    eng: 'Example',
    ru: 'Введите пример',
    pt: 'Exemplo',
    cn: '句例',
  },
  'Create available from anywhere in the script': {
    eng: 'Make the block available from any script point',
    ru: 'Сделать доступным из любой точки сценария',
    pt: 'Disponibilizar o bloco a partir de qualquer ponto do script',
    cn: '创建上下文任何地方可见单元',
  },
  'Phrases limit': {
    eng: 'Need more limits?',
    ru: 'Не хватает интентов?',
    pt: 'Precisa de mais limites?',
  },
  'Choose tariff': {
    eng: 'Choose plan',
    ru: 'Выбрать тариф',
    pt: 'Escolher plano',
  },
  'Phrases counter': {
    eng: 'The list of active intents $[1] ',
    ru: 'Список активных интентов — $[1] ',
    pt: 'A lista de intenções ativas $[1] ',
  },
  'IntentGroupEditModal emptyIntents title': {
    eng: 'The intents list is empty',
    ru: 'Список интентов пуст',
    pt: 'A lista de intenções está vazia',
  },
  'IntentGroupEditModal emptyIntents body': {
    eng: 'Create the first intent or choose a built-in one and enhance it.',
    ru: 'Создайте первый интент или выберите готовый и дополните его.',
    pt: 'Crie a primeira intenção ou escolha uma intenção interna e a aprimore.',
  },
  'IntentGroupEditModal Delete group': {
    eng: 'Delete intent',
    ru: 'Удалить интент',
    pt: 'Excluir intenção',
  },
  'About block': {
    eng: 'About block',
    ru: 'О блоке',
    pt: 'Sobre o bloco',
  },
  'If you need a transfer to the operator, you can connect the appropriate tariff.': {
    eng: 'If you need a transfer to the agent, you can choose a subscription plan that provides it.',
    ru: 'Если вам необходим перевод на оператора, вы можете подключить подходящий тариф.',
    pt: 'Se você precisa de transferências para o agente, escolha um plano de assinatura que forneça isso.',
  },
  'Change tariff': {
    eng: 'Change plan',
    ru: 'Изменить тариф',
    pt: 'Alterar plano',
  },
  'PseudoButtonComponent Add example': {
    eng: 'Add an example',
    ru: 'Добавить пример',
    pt: 'Adicionar um exemplo',
  },
  Close: {
    eng: 'Close',
    ru: 'Закрыть',
    pt: 'Fechar',
    cn: '关闭',
  },
  'Close chat button text': {
    eng: 'Button to transfer the dialog back to the bot',
    ru: 'Кнопка перевода диалога на бота',
    pt: 'Botão para transferir o diálogo de volta para o bot',
  },
  'Message before transfer to the operator': {
    eng: 'Message before transferring to the agent',
    ru: 'Сообщение перед переводом на оператора',
    pt: 'Mensagem antes de transferir para o agente',
  },
  'Operator groupId destination': {
    eng: 'Agent group',
    ru: 'Группа операторов',
    pt: 'Grupo de agentes',
  },
  'Operator bitrix USER_ID destination': {
    eng: 'User ID',
    ru: 'Идентификатор оператора (user_id)',
    pt: 'ID de usuário',
  },
  'Report no operators': {
    eng: 'Wait if no agents are available',
    ru: 'Ожидать, если нет свободных операторов',
    pt: 'Aguarde se nenhum agente estiver disponível',
  },
  'Message waiting': {
    eng: 'Waiting message',
    ru: 'Сообщение об ожидании',
    pt: 'Mensagem de espera',
  },
  'SwitchEditModal messageForWaitingOperator': {
    eng: 'One of our agents will be with you shortly',
    ru: 'Вам ответит первый освободившийся оператор',
    pt: 'Um dos nossos agentes atenderá você em breve',
  },
  'SwitchEditModal messageBeforeTransfer': {
    eng: 'You will now be transferred to the agent',
    ru: 'Вы будете переведены на оператора',
    pt: 'Você será transferido para o agente agora',
  },
  'SwitchEditModal titleOfCloseButton': {
    eng: 'Transfer dialog to bot',
    ru: 'Переключить на бота',
    pt: 'Transferir diálogo para o bot',
  },
  'SwitchEditModal helperText of close button': {
    eng: 'The user can select the button or enter the phrase manually. In WhatsApp and Bitrix24, the button is unavailable.',
    ru: 'Пользователь сможет нажать на кнопку или ввести команду вручную. В WhatsApp и Битрикс24 кнопка недоступна.',
    pt: 'O usuário pode selecionar o botão ou inserir a frase manualmente. No WhatsApp e no Bitrix24, o botão não está disponível.',
  },
  'SwitchEditModal helperText of close button euroInstance': {
    eng: 'The user can select the button or enter the phrase manually. In WhatsApp and Instagram the button is unavailable.',
    ru: 'Пользователь сможет нажать на кнопку или ввести команду вручную. В WhatsApp и Instagram кнопка недоступна.',
    pt: 'O usuário pode selecionar o botão ou digitar a frase manualmente. No WhatsApp e no Instagram, o botão está indisponível.',
  },
  'Add data to the operator': {
    eng: 'Add data for the agent',
    ru: 'Добавить данные для оператора',
    pt: 'Adicionar dados para o agente',
  },
  Key: {
    eng: 'Key',
    ru: 'Ключ',
    pt: 'Chave',
  },
  'Online chat integration': {
    eng: 'Online chat integration',
    ru: 'Интеграция с онлайн чатами',
    pt: 'Integração do chat on-line',
  },
  'No integration with online channels added': {
    eng: 'No integrations with online channels',
    ru: 'Нет добавленных интеграций с онлайн каналами',
    pt: 'Nenhuma integração com canais on-line',
  },
  'Go to': {
    eng: 'Go to',
    ru: 'Перейдите в',
    pt: 'Acessar',
    cn: '跳转至',
  },
  'Go to settings': {
    eng: 'Go to settings',
    ru: 'Перейти в настройки',
    pt: 'Acessar configurações',
  },
  'Connecting online operators': {
    eng: 'Connecting online agents',
    ru: 'Подключение онлайн операторов',
    pt: 'Conectando agentes on-line',
  },
  'To transfer to the online chat operator, you must first connect the carrier channels in the settings of your bot': {
    eng: 'To transfer to the agent, connect a customer engagement platform first',
    ru: 'Для перевода на оператора онлайн чата нужно сначала подключить операторские каналы',
    pt: 'Para transferir para o agente, conecte uma plataforma de engajamento primeiro',
  },
  'Webhook settings': {
    eng: 'Webhook settings',
    ru: 'Webhook настройки',
    pt: 'Configurações do webhook',
  },
  'Server URL': {
    eng: 'Server URL',
    ru: 'URL вашего сервера',
    pt: 'URL do servidor',
  },
  Event: {
    eng: 'Action',
    ru: 'Событие',
    pt: 'Ação',
  },
  'Event name': {
    eng: 'Action name',
    ru: 'Настройки события',
    pt: 'Nome da ação',
  },
  'Drop settings': {
    eng: 'Reset settings',
    ru: 'Сбросить настройки',
    pt: 'Redefinir configurações',
  },
  'NodeImage will be load in runtime': {
    eng: 'Your image will be uploaded when bot starts working',
    ru: 'Ваша картинка\n будет загружена по ссылке\n во время работы бота',
    pt: 'Sua imagem será carregada quando o bot começar a funcionar',
  },
  'Node CrmIntegration saved': {
    eng: 'Data is saved',
    ru: 'Данные сохранены',
    pt: 'Os dados são salvos',
  },
  'Node CrmIntegration not saved': {
    eng: 'Data is not saved',
    ru: 'Данные не сохранены',
    pt: 'Os dados não são salvos',
  },
  'Node GoogleSheets saved': {
    eng: 'Data is saved',
    ru: 'Данные сохранены',
    pt: 'Os dados são salvos',
  },
  'Node GoogleSheets not saved': {
    eng: 'Error saving data',
    ru: 'Ошибка сохранения',
    pt: 'Erro ao salvar dados',
  },
  'Http success': {
    eng: 'Success',
    ru: 'Завершен успешно',
    pt: 'Sucesso',
    cn: 'Http 请求成功',
  },
  'Http error': {
    eng: 'Error',
    ru: 'Завершен с ошибкой',
    pt: 'Erro',
    cn: 'Http 请求错误',
  },
  Variable: {
    eng: 'Variable',
    ru: 'Переменная',
    pt: 'Variável',
    cn: '变量',
  },
  'Any other phrase': {
    eng: 'Other intents',
    ru: 'Любая другая фраза',
    pt: 'Outras intenções',
    cn: '任意其他句子',
  },
  'Drag here': {
    eng: 'Drop here to connect with the screen',
    ru: 'Отпустите, чтобы соединить\n с этим экраном',
    pt: 'Solte aqui para se conectar com a tela',
  },
  or: {
    eng: 'or',
    ru: 'или',
    pt: 'ou',
  },
  'SeparatedBlockMenu Cut block': {
    eng: 'Cut block',
    ru: 'Вырезать блок',
    pt: 'Cortar bloco',
  },
  'SeparatedBlockMenu Copy block': {
    eng: 'Copy block',
    ru: 'Скопировать блок',
    pt: 'Copiar bloco',
  },
  'SeparatedBlockMenu Delete block': {
    eng: 'Delete block',
    ru: 'Удалить блок',
    pt: 'Excluir bloco',
  },
  'SeparatedBlockMenu Copied': {
    eng: 'Copied',
    ru: 'Скопировано',
    pt: 'Copiado',
  },
  'Remove screen': {
    eng: 'Remove screen',
    ru: 'Удалить экран',
    pt: 'Remover tela',
  },
  'SeparatedMenu Cant insert block': {
    eng: 'You cannot paste the block here',
    ru: 'Нельзя вставить блок',
    pt: 'Não é possível colar o bloco aqui',
  },
  'SeparatedMenu Copy info': {
    eng: 'Some blocks might not be available for adding. It happens when you are trying to add a block that is incompatible with the blocks that are already added to the screen.',
    ru: 'Некоторые блоки могут быть недоступны для добавления на экран. Это происходит, если вы пытаетесь добавить блок, который является несовместимым с уже добавленным блоком на этом экране.',
    pt: 'Alguns blocos podem não estar disponíveis para adição. Isso acontece quando você está tentando adicionar um bloco incompatível com os blocos que já estão adicionados à tela.',
  },
  'Paste block': {
    eng: 'Paste block',
    ru: 'Вставить блок',
    pt: 'Colar bloco',
  },
  'Dialogue completed': {
    eng: 'Dialog completed',
    ru: 'Диалог завершен',
    pt: 'Diálogo concluído',
  },
  'No free operators': {
    eng: 'No agents available',
    ru: 'Нет свободных операторов',
    pt: 'Nenhum agente disponível',
  },
  'Transfer to the operator is not available at the tariff $[1]': {
    eng: 'Transfer to agent is not available for the $[1] plan',
    ru: 'Перевод на оператора не доступен на тарифе $[1]',
    pt: 'A transferência para agentes não está disponível no plano $[1]',
  },
  off: {
    eng: 'OFF',
    ru: 'ВЫКЛ',
    pt: 'DESLIGADO',
  },
  'not configured': {
    eng: 'not configured',
    ru: 'не настроено',
    pt: 'não configurado',
  },
  Off: {
    eng: 'Off',
    ru: 'Выключить',
    pt: 'Desligado',
  },
  Set: {
    eng: 'Set',
    ru: 'Настроить',
    pt: 'Definir',
  },
  configured: {
    eng: 'configured',
    ru: 'настроено',
    pt: 'configured',
  },
  and: {
    eng: 'and',
    ru: 'и',
    pt: 'e',
  },
  enabled: {
    eng: 'enabled',
    ru: 'включено',
    pt: 'enabled',
  },
  disabled: {
    eng: 'disabled',
    ru: 'выключено',
    pt: 'desativado',
  },
  Conditions: {
    eng: 'Conditions',
    ru: 'Условия',
    pt: 'Condições',
    cn: '条件',
  },
  'SeparatedMenu answer': {
    eng: 'Text',
    ru: 'Текст',
    pt: 'Texto',
    cn: '文本单元',
  },
  'SeparatedMenu answers': {
    eng: 'Text',
    ru: 'Текст',
    pt: 'Texto',
    cn: '文本单元',
  },
  'SeparatedMenu image': {
    eng: 'Image',
    ru: 'Изображение',
    pt: 'Imagem',
    cn: '图案',
  },
  'SeparatedMenu condition': {
    eng: 'Conditions',
    ru: 'Условия',
    pt: 'Condições',
    cn: '条件',
  },
  'SeparatedMenu script': {
    eng: 'Code',
    ru: 'Код',
    pt: 'Código',
  },
  'SeparatedMenu InputNumber': {
    eng: 'Number input',
    ru: 'Ввод числа',
    pt: 'Entrada de número',
    cn: '数字输入',
  },
  'SeparatedMenu IntentGroup': {
    eng: 'Intents',
    ru: 'Интенты',
    pt: 'Intenções',
    cn: '句子',
  },
  'SeparatedMenu InputText': {
    eng: 'Text input',
    ru: 'Ввод текста',
    pt: 'Entrada de texto',
    cn: '文字输入',
  },
  'SeparatedMenu TransferToOperator': {
    eng: 'Transfer to agent',
    ru: 'Перевод на оператора',
    pt: 'Transferência para o agente',
    cn: '跳转到运营商',
  },
  'SeparatedMenu HttpRequest': {
    eng: 'HTTP request',
    ru: 'HTTP-запрос',
    pt: 'Solicitação HTTP',
    cn: 'HTTP 请求',
  },
  'SeparatedMenu Email': {
    eng: 'Send email',
    ru: 'Отправка email',
    pt: 'Enviar e-mail',
  },
  'SeparatedMenu InputPhoneNumber': {
    eng: 'Phone number',
    ru: 'Номер телефона',
    pt: 'Número de telefone',
  },
  'SeparatedMenu EndSession': {
    eng: 'End of script',
    ru: 'Завершение сценария',
    pt: 'Fim do script',
  },
  'SeparatedMenu Transition': {
    eng: 'Transition',
    ru: 'Переход',
    pt: 'Transição',
    cn: '跳转',
  },
  'SeparatedMenu timeout': {
    eng: 'Timeout transition',
    ru: 'Переход по таймауту',
    pt: 'Transição de tempo limite',
  },
  'SeparatedMenu Sms': {
    eng: 'Send SMS',
    ru: 'Отправка SMS',
    pt: 'Enviar SMS',
  },
  'SeparatedMenu Timetable': {
    eng: 'Schedule',
    ru: 'Расписание',
    pt: 'Agenda',
  },
  'SeparatedMenu TelegramPayment': {
    eng: 'Telegram payment',
    ru: 'Оплата в Telegram',
    pt: 'Pagamento no Telegram',
  },
  'Duplicate header: $[1]': {
    eng: 'Duplicate header: $[1]',
    ru: 'Одинаковые хэдеры: $[1]',
    pt: 'Cabeçalho duplo: $[1]',
    cn: '重复头部 : $[1]',
  },
  example1: {
    eng: 'example',
    ru: 'пример',
    pt: 'exemplo',
  },
  example2: {
    eng: 'examples',
    ru: 'примера',
    pt: 'exemplos',
  },
  example5: {
    eng: 'examples',
    ru: 'примеров',
    pt: 'exemplos',
  },
  Timeout: {
    eng: 'Timeout transition',
    ru: 'Переход по таймауту',
    pt: 'Transição de tempo limite',
  },
  'TimeoutEditModal placeholder': {
    eng: 'Should be a number or a variable, e.g. $delay',
    ru: 'Укажите число или переменную, например, $delay',
    pt: 'Deve ser um número ou uma variável, p. ex: $delay',
  },
  'TimeoutEditModal text label': {
    eng: 'Timeout value',
    ru: 'Значение таймаута',
    pt: 'Valor do tempo limite',
  },
  'TimeoutEditModal seconds': {
    eng: 'sec',
    ru: 'сек',
    pt: 's',
  },
  'TimeoutEditModal minutes': {
    eng: 'min',
    ru: 'мин',
    pt: 'min',
  },
  'TimeoutEditModal hours': {
    eng: 'hrs',
    ru: 'ч',
    pt: 'h',
  },
  'TimeoutEditModal timeout message': {
    eng: 'Make sure to read the documentation to learn how this block works in voice channels',
    ru: 'Рекомендуем ознакомиться с особенностями работы блока в телефонных каналах',
    pt: 'Certifique-se de ler a documentação para aprender como este bloco funciona nos canais de voz',
  },
  'TimeoutEditModal cant be empty': {
    eng: 'Invalid value. Timeout can be either a double digit number or a variable in the $timeout format',
    ru: 'Невалидное значение. Значение таймаута может быть двузначным числом или переменной в формате $timeout',
    pt: 'Valor inválido. O tempo limite pode ser tanto um número com dois dígitos ou uma variável no formato $timeout',
  },
  'TimeoutEditModal link text': {
    eng: 'Open documentation',
    ru: 'Перейти в документацию',
    pt: 'Abrir documentação',
  },
  'NodeTimeout transition after': {
    eng: 'Transition after',
    ru: 'Переход после',
    pt: 'Transição após',
  },
  'Transition after entering a $$[1]': {
    eng: 'Transition after entering a $$[1]',
    ru: 'Переход после ввода $$[1]',
    pt: 'Transição após entrar em um $$[1]',
    cn: '输入 $$[1] 后跳转',
  },
  'AudiosEditModal supported channels': {
    eng: 'Supports:',
    ru: 'Поддерживает:',
    pt: 'É compatível com:',
  },
  'AudiosEditModal notsupported channels': {
    eng: 'Doesn’t support',
    ru: 'Не поддерживает:',
    pt: 'Não é compatível',
  },
  'Player renderIncompatibleChannels ALISA': {
    eng: 'Audio requirements: \nFormat — MP3, WAV, \nSize — no more than 5Mb \nDuration — up to 120 seconds',
    ru: 'Требования к аудио:\nФормат — MP3, WAV,\nРазмер — не более 5Mb \nДлительность — до 120 сек',
    pt: 'Requisitos de áudio: \nFormato — MP3, WAV, \nTamanho — menor que 5 Mb \nDuração — até 120 segundos',
  },
  'Player renderIncompatibleChannels GOOGLE_ASSISTANCE': {
    eng: 'Audio requirements: \nFormat — MP3 (MPEG v2), \nSample rate index — 24 kHz, \nBitrate — 24–96 kbps, \nDuration — up to 240 seconds',
    ru: 'Требования к аудио:\nФормат — MP3(MPEG v2),\nЧастота — 24 кГц, \nБитрейт — 24–96 kbps,\nДлительность — до 240 сек',
    pt: 'Requisitos de áudio: \nFormato — MP3 (MPEG v2), \nÍndice de taxa de amostra — 24 kHz, \nTaxa de bits — 24–96 kbps, \nDuração — até 240 segundos',
  },
  'Player renderIncompatibleChannels TELEGRAM': {
    eng: 'Audio requirements: \nFormat — MP3 (MPEG v2), \nSize — no more than 10Mb',
    ru: 'Требования к аудио:\nРазмер — до 10Mb',
    pt: 'Requisitos de áudio: \nFormato — MP3 (MPEG v2), \nTamanho — menor que 10 Mb',
  },
  'Player renderIncompatibleChannels FACEBOOK': {
    eng: 'Audio requirements: \nFormat — MP3 (MPEG v2) / WAV, \nSize — no more than 10Mb',
    ru: 'Требования к аудио:\nФормат — MP3(MPEG v2) / WAV,\nРазмер — до 10Mb',
    pt: 'Requisitos de áudio: \nFormato — MP3 (MPEG v2)/WAV, \nTamanho — menor que 10 Mb',
  },
  'Player renderIncompatibleChannels VK': {
    eng: 'Audio requirements: \nFormat — MP3 (MPEG v2) / WAV, \nSize — no more than 10Mb',
    ru: 'Требования к аудио:\nФормат — MP3(MPEG v2) / WAV,\nРазмер — до 10Mb',
    pt: 'Requisitos de áudio: \nFormato — MP3 (MPEG v2)/WAV, \nTamanho — menor que 10 Mb',
  },
  'Player renderIncompatibleChannels WHATSAPP': {
    eng: 'Audio requirements: \nFormat — MP3 (MPEG v2) / WAV, \nSize — no more than 10Mb',
    ru: 'Требования к аудио:\nФормат — MP3(MPEG v2) / WAV,\nРазмер — до 10Mb',
    pt: 'Requisitos de áudio: \nFormato — MP3 (MPEG v2)/WAV, \nTamanho — menor que 10 Mb',
  },
  'Player renderIncompatibleChannels OUTGOING_CALLS': {
    eng: 'Audio requirements: \nFormat — WAV 1 channel (mono), \nEncoding — 16-bit (PCM), \nSample rate index — 8–48 kHz',
    ru: 'Требования к аудио:\nФормат — WAV 1 канал (моно),\nКодирование — 16-bit (PCM),\nЧастота — 8–48 кГц',
    pt: 'Requisitos de áudio: \nFormato — canal WAV 1 (mono), \nCodificação — 16 bits (PCM), \nÍndice de taxa de amostra — 8–48 kHz',
  },
  'Player renderIncompatibleChannels ALEXA': {
    eng: 'Audio requirements: \nFormat is MP3, \nSample rate index — 16 kHz, \nBitrate — 48 kbps \nDuration — up to 240 seconds',
    ru: 'Требования к аудио:\nФормат — MP3,\nЧастота — 16 кГц, \nБитрейт — 48 kbps \nДлительность — до 240 сек',
    pt: 'Requisitos de áudio: \nFormato — MP3, \nÍndice de taxa de amostra — 16 kHz, \nTaxa de bits — 48 kbps \nDuração — até 240 segundos',
  },
  'Player renderIncompatibleChannels AIMYBOX': {
    eng: 'Audio requirements: \nFormat — MP3, WAV, \nSize — no more than 10Mb',
    ru: 'Требования к аудио:\nФормат — MP3, WAV,\nРазмер — не более 10Mb',
    pt: 'Requisitos de áudio: \nFormato — MP3, WAV, \nTamanho — menor que 10 Mb',
  },
  'Player renderIncompatibleChannels MESSENGERS': {
    eng: 'Audio requirements: \nFormat — MP3, WAV, \nSize — no more than 10Mb',
    ru: 'Требования к аудио:\nФормат — MP3, WAV,\nРазмер — не более 10Mb',
    pt: 'Requisitos de áudio: \nFormato — MP3, WAV, \nTamanho — menor que 10 Mb',
  },
  'Player renderCompatibleChannels MESSENGERS': {
    eng: 'Messengers: \nTelegram \nFacebook \nVK \nWhatsApp Business',
    ru: 'Мессенджеры:\nTelegram\nFacebook\nВКонтакте\nWhatsApp Business',
    pt: 'Mensageiros:\nTelegram \nFacebook \nVK \nWhatsApp Business',
  },
  'File input title': {
    eng: 'file to $var',
    ru: 'file to $var',
    pt: 'file to $var',
  },
  'file input link': {
    eng: '',
    ru: '',
  },
  'Upload file': {
    eng: 'Attach your file',
    ru: 'Прикрепите файл',
    pt: 'Anexe seu arquivo',
  },
  'File uploaded': {
    eng: 'File received — $$[1]',
    ru: 'Файл получен — $$[1]',
    pt: 'Arquivo recebido — $$[1]',
  },
  Error: {
    eng: 'Input error',
    ru: 'Ошибка ввода',
    pt: 'Erro de entrada',
  },
  'NodeAnswers:answers.length': {
    eng: 'replies',
    ru: 'реплик',
    pt: 'respostas',
  },
  'Transfer call to operator': {
    eng: 'Call transfer to agent',
    ru: 'Перевод звонка на оператора',
    pt: 'Transferência de chamada para o agente',
  },
  'TransferCallToOperator then state': {
    eng: 'Successfully transferred',
    ru: 'Перевод состоялся',
    pt: 'Transferida com sucesso',
  },
  'TransferCallToOperator errorState': {
    eng: 'Failed to transfer',
    ru: 'Перевод не состоялся',
    pt: 'Com falha de transferência',
  },
  'TransferCallToOperator phoneNumber label': {
    eng: 'Phone number',
    ru: 'Номер телефона',
    pt: 'Número de telefone',
  },
  'TransferCallToOperator telephony AL warning DIALER': {
    eng: 'The call will be transferred to an agent through the SIP connection used for this call campaign. You can create a call campaign and select a connection on the Outbound campaigns page.',
    ru: 'Перевод звонка на оператора будет происходить через телефонное подключение, которое используется для обзвона. Создать обзвон и выбрать подключение вы можете на странице Обзвоны.',
    pt: 'A chamada será transferida para um agente por meio da conexão SIP utilizada para esta campanha de chamadas. Você pode criar uma campanha de chamadas e selecionar uma conexão na página Campanhas de saída.',
  },
  'TransferCallToOperator telephony AL warning IVR': {
    eng: 'The call will be transferred to an agent through the SIP connection used for receiving calls. You can start receiving calls and select a connection on the Inbound telephony page.',
    ru: 'Перевод звонка на оператора будет происходить через телефонное подключение, которое используется для приема звонков. Запустить прием звонков и выбрать подключение вы можете на странице Входящая телефония.',
    pt: 'A chamada será transferida para um agente por meio da conexão SIP utilizada para receber chamadas. Você pode começar a receber chamadas e selecionar uma conexão na página Telefonia de entrada.',
  },
  'TransferCallToOperator phoneNumber helperText': {
    eng: 'If you use the Aimylogic telephony, enter the number in the format 7XXXXXXXXXX. If you use third-party telephony, check with your provider about the expected format.',
    ru: 'Если вы используете телефонию Aimylogic, укажите номер в формате 7XXXXXXXXXX. При использовании сторонней телефонии уточните ожидаемый формат у провайдера.',
  },
  'TransferCallToOperator phoneNumber select': {
    eng: 'Telephony',
    ru: 'Телефония',
    pt: 'Telefonia',
  },
  'TransferCallToOperator add connection': {
    eng: 'Add connection',
    ru: 'Добавить подключение',
    pt: 'Adicionar conexão',
  },
  'TransferCallToOperator: sip is not configured': {
    eng: 'No SIP connection is configured',
    ru: 'SIP-подключение не настроено',
    pt: 'Nenhuma conexão SIP configurada',
  },
  'TransferCallToOperator: sip is not configured text': {
    eng: 'To enable calls, add a SIP connection. ',
    ru: 'Чтобы включить звонки, создайте SIP-подключение. ',
    pt: 'Para ativar chamadas, adicione uma conexão SIP. ',
  },
  'TransferCallToOperator: what is sip link': {
    eng: '<a target="_blank" href="#{config.zenflow.helpUrl}/docs/en/smart_calls/sip_settings">What is a SIP connection?</a>',
    ru: '<a target="_blank" href="#{config.zenflow.helpUrl}/docs/ru/smart_calls/sip_settings">Что такое SIP?</a>',
    pt: '<a target="_blank" href="#{config.zenflow.helpUrl}/docs/en/smart_calls/sip_settings">O que é uma conexão SIP?</a>',
  },
  'Transfer to call operator': {
    eng: 'Call transfer to agent',
    ru: 'Перевод звонка на оператора',
    pt: 'Transferência de chamada para o agente',
  },
  'Send dialog history to operator': {
    eng: 'Forward conversation history to the agent chat',
    ru: 'Передавать историю диалога в операторский чат',
    pt: 'Encaminhar histórico de conversa para o chat de agente',
  },
  'Messages quantity to operator': {
    eng: 'Count of last conversation messages to be forwarded to the agent:',
    ru: 'Сколько последних сообщений диалога передать оператору:',
    pt: 'Contagem das últimas mensagens da conversa a serem encaminhadas para o agente:',
  },
  'Messages quantity to operator errors negativeValue': {
    eng: 'Cannot be negative',
    ru: 'Значение не может быть отрицательным',
    pt: 'Não pode ser negativa',
  },
  'Messages quantity to operator errors nullValue': {
    eng: 'Cannot be zero',
    ru: 'Значение не может быть 0',
    pt: 'Não pode ser zero',
  },
  'Messages quantity to operator errors empty': {
    eng: 'Field cannot be empty',
    ru: 'Поле не может быть пустым',
    pt: 'O campo não pode ficar vazio',
  },
  'SeparatedMenu TransferCallToOperator': {
    eng: 'Transfer to agent',
    ru: 'Перевод на оператора',
    pt: 'Transferência para o agente',
    cn: '跳转到运营商',
  },
  'TelephonySettings our telephony': {
    eng: '$[1] telephony',
    ru: 'Телефония $[1]',
    pt: 'Telefonia $[1]',
  },
  'ChooseReadyIntent Answering machine': {
    eng: 'Voicemail',
    ru: 'Автоответчик',
    pt: 'Correio de voz',
  },
  'doubleClick text node title': {
    eng: 'Double click to edit the block',
    ru: 'Для редактирования блока щелкните дважды',
    pt: 'Clique duas vezes para editar o bloco',
  },
  'click on edit title block': {
    eng: 'Click to rename the block',
    ru: 'Нажмите, чтобы переименовать блок',
    pt: 'Clique para renomear o bloco',
  },
  'VideoEditModal:youtube link': {
    eng: 'Insert a link to a YouTube video',
    ru: 'Вставьте ссылку на видео с YouTube',
    pt: 'Inserir um link para um vídeo do YouTube',
  },
  'VideoEditModal:video will be available during bot execution': {
    eng: 'The video will be loaded from the link by the bot',
    ru: 'Видео будет загружено по ссылке во время работы бота',
    pt: 'O vídeo será carregado a partir do link pelo bot',
  },
  'VideoEditModal:error youtube link': {
    eng: 'The <b>Video</b> block only accepts YouTube links',
    ru: 'Блок <b>Видео</b> принимает только ссылки с YouTube',
    pt: 'O bloco <b>Vídeo</b> só aceita links do YouTube',
  },
  'Syntez:subheading': {
    eng: 'bots speak with the Alena premium voice (Yandex)',
    ru: 'боты говорят премиум-голосом Алена (Yandex)',
    pt: 'bots speak with the Alena premium voice (Yandex)',
  },
  'Syntez:attachmentTitle': {
    eng: 'TTS markup tips',
    ru: 'Подсказки по разметке синтеза',
    pt: 'Dicas de marcação TTS',
  },
  'Syntez:attachmentBody': {
    eng: 'Use "+" before a stressed vowel: +export, ex+port.\nUse "-" to specify a pause between words.\nString length limit: 500 characters.\n',
    ru: 'Используйте «+» перед ударной гласной: з+амок, зам+ок.\nЧтобы отметить паузу между словами используйте «-».\nОграничение на длину строки: 500 символов.\n',
    pt: 'Use "+" antes de uma vogal tônica: export+ar, exportaç+ão.\nUse "-" para especificar uma pausa entre as palavras.\nLimite de comprimento da string: 500 caracteres.\n',
  },
  'Syntez:attachment_instruction_text': {
    eng: 'Detailed instructions',
    ru: 'Подробная инструкция',
    pt: 'Instruções detalhadas',
  },
  'WebhookSettings:firstAddLAbel': {
    eng: 'To use the webhook, fill in the # field',
    ru: 'Чтобы использовать вебхук, заполните поле #',
    pt: 'Para usar o webhook, preencha o campo #',
  },
  'WebhookSettings:webhookOn': {
    eng: 'Webhook on',
    ru: 'Вебхук включен',
    pt: 'Webhook ligado',
  },
  'WebhookSettings:webhookOff': {
    eng: 'Webhook off',
    ru: 'Вебхук выключен',
    pt: 'Webhook desligado',
  },
  'AddChannelCard THREADS': {
    eng: 'edna.chatCenter',
    ru: 'edna.chatCenter',
    pt: 'edna.chatCenter',
  },
  'EmailEditModal destination': {
    eng: 'Recipient email',
    ru: 'Email получателя',
    pt: 'E-mail do destinatário',
  },
  'EmailEditModal subject': {
    eng: 'Message subject',
    ru: 'Тема письма',
    pt: 'Assunto da mensagem',
  },
  'EmailEditModal comment': {
    eng: 'Message body',
    ru: 'Текст письма',
    pt: 'Corpo da mensagem',
  },
  Email: {
    eng: 'Send email',
    ru: 'Отправка email',
    pt: 'Enviar e-mail',
  },
  'Http email success': {
    eng: 'The message has been sent',
    ru: 'Запрос осуществлен',
    pt: 'A mensagem foi enviada',
  },
  'Email email error': {
    eng: 'Please enter the correct recipient email',
    ru: 'Введите корректный email получателя',
    pt: 'Digite o e-mail correto do destinatário',
  },
  'Email required field': {
    eng: 'Required field',
    ru: 'Обязательное поле',
    pt: 'Campo obrigatório',
  },
  'AddChannelCard OPERATORAPI': {
    eng: 'Operator API',
    ru: 'Operator API',
    pt: 'Operator API',
  },
  'SwitchParams OPERATORAPI': {
    eng: 'Operator API',
    ru: 'Operator API',
    pt: 'Operator API',
  },
  'SwitchParams AIMYCHAT': {
    eng: 'Aimychat',
    ru: 'Aimychat',
    pt: 'Aimychat',
  },
  'SwitchParams AIMYCHAT:Tovie': {
    eng: 'Tovie Agent',
    ru: 'Tovie Agent',
    pt: 'Tovie Agent',
  },
  'Sms title': {
    eng: 'Send SMS',
    ru: 'Отправка SMS',
    pt: 'Enviar SMS',
  },
  'GoogleSheets title': {
    eng: 'Google Sheets integration',
    ru: 'Интеграция с Google Таблицами',
    pt: 'Integração do Planilhas Google',
  },
  'SmsEditModal destination': {
    eng: 'Phone number*',
    ru: 'Номер телефона*',
    pt: 'Número de telefone*',
  },
  'SmsEditModal text': {
    eng: 'Message text*',
    ru: 'Текст сообщения*',
    pt: 'Texto da mensagem*',
  },
  'SmsEditModal info': {
    eng: 'No more than 6 SMS messages can be sent at a time. Refer to the <a href="$[1]" target="_blank" rel="noopener noreferref">documentation</a> to find out how SMS characters are counted.',
    ru: 'В рамках одного запроса можно отправить максимум 6 SMS. Как подсчитать количество символов в SMS, читайте в <a href="$[1]" target="_blank" rel="noopener noreferref">документации.</a>',
    pt: 'Não podem ser enviadas mais de 6 mensagens SMS de cada vez. Consulte <a href="$[1]" target="_blank" rel="noopener noreferref">documentação</a> para descobrir como os caracteres SMS são contados.',
  },
  'SmsEditModal:Info:Restriction': {
    eng: 'SMS messages can only be sent to Russian numbers.',
    ru: 'SMS-сообщения можно отправлять только на номера РФ.',
  },
  'SmsEditModal destination error': {
    eng: 'Invalid phone number',
    ru: 'Некорректный номер телефона',
    pt: 'Número de telefone inválido',
  },
  'SmsEditModal:NumberPhoneRU:Error': {
    eng: 'Enter the number in the format 79XXXXXXXXXXX or specify the variable',
    ru: 'Введите номер в формате 79XXXXXXXXX или укажите переменную',
  },
  'SmsEditModal text error': {
    eng: 'Required field',
    ru: 'Поле обязательно для заполнения',
    pt: 'Campo obrigatório',
  },
  'SmsEditModal go to pricing': {
    eng: 'Go to Pricing',
    ru: 'Открыть тарифы',
    pt: 'Acessar Preços',
  },
  'SmsEditModal buy sms title': {
    eng: 'Additional SMS package',
    ru: 'Дополнительный пакет SMS',
    pt: 'Pacote SMS adicional',
  },
  'SmsEditModal buy sms description': {
    eng: 'To send SMS messages, buy an additional SMS package',
    ru: 'Чтобы отправлять сообщения, купите дополнительный пакет SMS',
    pt: 'Para enviar mensagens SMS, compre um pacote SMS adicional',
  },
  'Node Sms success': {
    eng: 'Message sent successfully',
    ru: 'Успешная отправка',
    pt: 'Mensagem enviada com sucesso',
  },
  'Node Sms declined': {
    eng: 'Error',
    ru: 'Ошибка',
    pt: 'Erro',
  },
  'ConfirmNumber in editor': {
    eng: 'Please confirm your phone number to be able to send SMS messages',
    ru: 'Для отправки SMS-сообщений подтвердите свой номер телефона',
    pt: 'Confirme seu número de telefone para poder enviar mensagens SMS',
  },
  'GoogleSheets:integrationId': {
    eng: 'Account',
    ru: 'Аккаунт',
    pt: 'Conta',
  },
  'GoogleSheets:spreadsheetId': {
    eng: 'Spreadsheet',
    ru: 'Таблица',
    pt: 'Planilha',
  },
  'GoogleSheets:sheetName': {
    eng: 'Sheet',
    ru: 'Лист таблицы',
    pt: 'Folha',
  },
  'GoogleSheets:operationType': {
    eng: 'Action',
    ru: 'Действие',
    pt: 'Ação',
  },
  'GoogleSheets:readDataFromCells': {
    eng: 'Read data from cells',
    ru: 'Получить значение',
    pt: 'Ler dados das células',
  },
  'GoogleSheets:writeDataToCells': {
    eng: 'Write data to cells',
    ru: 'Сохранить в ячейку',
    pt: 'Gravar dados nas células',
  },
  'GoogleSheets:writeDataToLine': {
    eng: 'Write data to line',
    ru: 'Сохранить в строку',
    pt: 'Gravar dados na linha',
  },
  'GoogleSheets:deleteRowOrColumn': {
    eng: 'Delete line/column',
    ru: 'Удалить строку/столбец',
    pt: 'Excluir linha/coluna',
  },
  'GoogleSheets:clearCellData': {
    eng: 'Clear cells',
    ru: 'Очистить ячейки',
    pt: 'Limpar células',
  },
  'GoogleSheets:subtitle': {
    eng: 'List of parameters',
    ru: 'Список параметров для записи',
    pt: 'Lista de parâmetros',
  },
  'GoogleSheets:varNameOrValue': {
    eng: 'Value or variable',
    ru: 'Значение или переменная',
    pt: 'Valor ou variável',
  },
  'GoogleSheets:fromCell': {
    eng: 'From cell',
    ru: 'Из ячейки',
    pt: 'Da célula',
  },
  'GoogleSheets:addValue': {
    eng: 'Add value',
    ru: 'Добавить значение',
    pt: 'Adicionar valor',
  },
  'GoogleSheets:toCell': {
    eng: 'To cell',
    ru: 'В ячейку',
    pt: 'Para a célula',
  },
  'GoogleSheets:noIntegrationAdded': {
    eng: 'Google Sheets integration',
    ru: 'Интеграция с Google Таблицами',
    pt: 'Integração do Planilhas Google',
  },
  GoogleSheets: {
    eng: 'Google Sheets integration',
    ru: 'Интеграция с Google Таблицами',
    pt: 'Integração do Planilhas Google',
  },
  'CustomGoogleSheetOption:Create sheet': {
    eng: 'Create new spreadsheet',
    ru: 'Создать новую таблицу',
    pt: 'Criar nova planilha',
  },
  'CustomGoogleSheetOption:Create sheet subtext': {
    eng: 'The bot name will be the name of the new spreadsheet',
    ru: 'Название бота будет названием новой таблицы',
    pt: 'O nome do bot será o nome da nova planilha',
  },
  'CustomGoogleSheetOption:new_sheet_label': {
    eng: 'Sheet 1',
    ru: 'Лист 1',
    pt: 'Folha 1',
  },
  'CustomGoogleSheetOption:new_sheet_value': {
    eng: 'Sheet 1',
    ru: 'Лист 1',
    pt: 'Folha 1',
  },
  'SeparatedMenu GoogleSheets': {
    eng: 'Google Sheets integration',
    ru: 'Интеграция с Google Таблицами',
    pt: 'Integração do Planilhas Google',
  },
  'GoogleSheets:goToIntegrations': {
    eng: 'Go to settings',
    ru: 'Перейти в настройки',
    pt: 'Acessar configurações',
  },
  'GoogleSheets:integrationText': {
    eng: 'To enable interaction with Google Sheets, you need to authorize $[1] in your Google account. <a href="$[2]" rel="noopener noreferrer" target="_blank">Learn more about the block</a>',
    ru: 'Для взаимодействия с таблицами необходимо авторизовать $[1] в вашем аккаунте Google. <a href="$[2]" rel="noopener noreferrer" target="_blank">Подробнее о блоке</a>',
    pt: 'Para ativar a interação com as Planilhas Google, você precisa autorizar o $[1] em sua conta do Google. <a href="$[2]" rel="noopener noreferrer" target="_blank">Saiba mais sobre o bloco</a>',
  },
  Script: {
    eng: 'Code',
    ru: 'Код',
    pt: 'Código',
  },
  'Add some code': {
    eng: '// Write some code',
    ru: '// Добавьте код',
    pt: '// Escrever algum código',
  },
  'EmailFileUpload add button text': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'EmailFileUpload muted text': {
    eng: 'Use Aa–Zz, _, and 0–9 characters only. The name should start with Aa–Zz. JS reserved words are not allowed.',
    ru: 'Используйте Aa–Zz, _, 0–9. Начните имя с Aa–Zz. Нельзя использовать зарезервированные слова JS.',
    pt: 'Use apenas caracteres Aa–Zz, _ e 0–9. O nome deve começar com Aa–Zz. Palavras reservadas JS não são permitidas.',
  },
  'EmailFileUpload info message': {
    eng: 'The file will be attached to the email if previously stored into a variable using the <b style="font-size: 0.75rem">file to $var</b> block',
    ru: 'Файл будет прикреплен к письму, если ранее в сценарии он был сохранен в переменную с помощью блока <b style="font-size: 0.75rem">file to $var</b>',
    pt: 'O arquivo será anexado ao e-mail se anteriormente armazenado em uma variável usando o bloco <b style="font-size: 0.75rem">file to $var</b>',
  },
  'EmailFileUpload files size description': {
    eng: 'File size cannot exceed 25 MB',
    ru: 'Размер файла не должен превышать 25 Мб',
    pt: 'O tamanho do arquivo não pode exceder 25 MB',
  },
  'EmailFileUpload text input placeholder': {
    eng: 'Variable name',
    ru: 'Название переменной',
    pt: 'Nome da variável',
  },
  'EmailFileUpload tabname 1': {
    eng: 'via a variable',
    ru: 'через переменную',
    pt: 'por meio de uma variável',
  },
  'EmailFileUpload tabname 0': {
    eng: 'upload file',
    ru: 'загрузить с компьютера',
    pt: 'carregar arquivo',
  },
  'EmailFileUpload delete file': {
    eng: 'Delete file',
    ru: 'Удалить файл',
    pt: 'Excluir arquivo',
  },
  'EmailFileUpload file size error': {
    eng: 'The uploaded file is too large',
    ru: 'Вы пытаетесь загрузить файл слишком большого размера',
    pt: 'O arquivo enviado é muito grande',
  },
  'EmailEditModal file list title': {
    eng: 'File list',
    ru: 'Список файлов',
    pt: 'Lista de arquivos',
  },
  'Node Email saved': {
    eng: 'Email sent successfully',
    ru: 'Отправлено успешно',
    pt: 'E-mail enviado com sucesso',
  },
  'Node Email not saved': {
    eng: 'Error sending email',
    ru: 'Ошибка при отправке',
    pt: 'Erro ao enviar e-mail',
  },
  Telephony: {
    eng: 'Telephony',
    ru: 'Телефония',
    pt: 'Telefonia',
  },
  Timetable: {
    eng: 'Schedule',
    ru: 'Расписание',
    pt: 'Agenda',
  },
  'Node Timetable saved': {
    eng: 'The time chosen',
    ru: 'Выбранное время',
    pt: 'A hora escolhida',
  },
  'Node Timetable not saved': {
    eng: 'Any other time',
    ru: 'Любое другое время',
    pt: 'Qualquer outra hora',
  },
  'Timetable title': {
    eng: 'Schedule',
    ru: 'Расписание',
    pt: 'Agenda',
  },
  'TimetableModal:offsetlabel': {
    eng: 'Time zone',
    ru: 'Часовой пояс',
    pt: 'Fuso horário',
  },
  'TimetableModal:everyDayParam-label': {
    eng: 'Every day',
    ru: 'Каждый день',
    pt: 'Todos os dias',
  },
  'TimetableModal:body-label': {
    eng: 'Choose days of the week',
    ru: 'Выбрать дни недели',
    pt: 'Escolha os dias da semana',
  },
  'TimetableModal:from': {
    eng: 'From',
    ru: 'С',
    pt: 'Das',
  },
  'TimetableModal:to': {
    eng: 'To',
    ru: 'До',
    pt: 'Até',
  },
  'TimetableModal:DayOfWeek:0': {
    eng: 'Monday',
    ru: 'Понедельник',
    pt: 'Segunda',
  },
  'TimetableModal:DayOfWeek:1': {
    eng: 'Tuesday',
    ru: 'Вторник',
    pt: 'Terça',
  },
  'TimetableModal:DayOfWeek:2': {
    eng: 'Wednesday',
    ru: 'Среда',
    pt: 'Quarta',
  },
  'TimetableModal:DayOfWeek:3': {
    eng: 'Thursday',
    ru: 'Четверг',
    pt: 'Quinta',
  },
  'TimetableModal:DayOfWeek:4': {
    eng: 'Friday',
    ru: 'Пятница',
    pt: 'Sexta',
  },
  'TimetableModal:DayOfWeek:5': {
    eng: 'Saturday',
    ru: 'Суббота',
    pt: 'Sábado',
  },
  'TimetableModal:DayOfWeek:6': {
    eng: 'Sunday',
    ru: 'Воскресенье',
    pt: 'Domingo',
  },
  'AddingMenu TelegramPayment': {
    eng: 'Telegram payment',
    ru: 'Оплата в Telegram',
    pt: 'Pagamento no Telegram',
  },
  TelegramPayment: {
    eng: 'Telegram payment',
    ru: 'Оплата в Telegram',
    pt: 'Pagamento no Telegram',
  },
  'TelegramPayment title': {
    eng: 'Telegram payment',
    ru: 'Оплата в Telegram',
    pt: 'Pagamento no Telegram',
  },
  'TelegramPayment info message': {
    eng: 'Learn how to get a token and use \nthe “Telegram payment” block <a target="_blank" rel="noopener noreferrer" href="$[1]">in the documentation</a>',
    ru: 'Узнайте как получить токен и как устроен блок \n«Оплата в Telegram» <a target="_blank" rel="noopener noreferrer" href="$[1]">в документации</a>',
    pt: 'Saiba como obter um token e usar o bloco "Pagamento no Telegram" <a target="_blank" rel="noopener noreferrer" href="$[1]">na documentação</a>',
  },
  'TelegramPayment heading 1': {
    eng: 'Configure Telegram',
    ru: 'Настройте Telegram',
    pt: 'Configurar Telegram',
  },
  'TelegramPaymentModal token field placeholder': {
    eng: 'Payment system token',
    ru: 'Токен платежной системы',
    pt: 'Token do sistema de pagamento',
  },
  'TelegramPaymentModal startParameter checkbox': {
    eng: 'Allow clients to forward payment links',
    ru: 'Разрешить клиенту делиться ссылкой на оплату',
    pt: 'Permitir que os clientes encaminhem links de pagamento',
  },
  'TelegramPayment heading 2': {
    eng: 'Describe the product',
    ru: 'Опишите продукт',
    pt: 'Descreva o produto',
  },
  'TelegramPaymentModal paymentTitle field placeholder': {
    eng: 'Title',
    ru: 'Название',
    pt: 'Título',
  },
  'TelegramPaymentModal paymentTitle field helperText': {
    eng: 'Use no more than 32 characters',
    ru: 'Используйте не более 32 символов',
    pt: 'Não use mais de 32 caracteres',
  },
  'TelegramPaymentModal description field placeholder': {
    eng: 'Description',
    ru: 'Описание',
    pt: 'Descrição',
  },
  'TelegramPaymentModal description field helperText': {
    eng: 'Use no more than 255 characters',
    ru: 'Используйте не более 255 символов',
    pt: 'Não use mais de 255 caracteres',
  },
  'TelegramPaymentModal image field placeholder': {
    eng: 'Image URL',
    ru: 'Ссылка на изображение',
    pt: 'URL da imagem',
  },
  'TelegramPaymentModal amount field placeholder': {
    eng: 'Price (0.00)',
    ru: 'Цена (0.00)',
    pt: 'Preço (0,00)',
  },
  'TelegramPaymentModal invoicePayload field placeholder': {
    eng: 'Invoice details',
    ru: 'Данные для счета-фактуры',
    pt: 'Detalhes da fatura',
  },
  'TelegramPaymentModal invoicePayload field helperText': {
    eng: 'Invoice details are only used for internal processes and will not be displayed to any clients',
    ru: 'Данные не будут отображаться клиентам. Они используются только для внутренних процессов',
    pt: 'Os detalhes da fatura são usados apenas para processos internos e não serão exibidos para nenhum cliente',
  },
  'TelegramPaymentModal:precheckoutEnabled': {
    eng: 'Check the goods availability before checkout',
    ru: 'Проверять наличие товара перед оплатой',
    pt: 'Verifique a disponibilidade de mercadorias antes de finalizar a compra',
  },
  'TelegramPaymentModal:precheckoutEnabled_text': {
    eng: 'This parameter allows you to specify a URL where requests should be sent to check the goods availability in your system before making a payment',
    ru: 'Данный параметр позволяет указать адрес для запросов в вашу систему, чтобы проверять наличие товара перед осуществлением платежа',
    pt: 'Este parâmetro permite especificar um URL para onde devem ser enviadas as solicitações de verificação de disponibilidade de mercadorias em seu sistema antes de efetuar um pagamento',
  },
  'Node TelegramPayment saved': {
    eng: 'Payment successful',
    ru: 'Успешная оплата',
    pt: 'Pagamento bem-sucedido',
  },
  'Node TelegramPayment not saved': {
    eng: 'Form validation failed',
    ru: 'Ошибка валидации формы',
    pt: 'Falha na validação do formulário',
  },
  'Node TelegramPayment catchAllState': {
    eng: 'Payment failed',
    ru: 'Сбой при оплате',
    pt: 'Falha no pagamento',
  },
  'Node buttonGroups new button': {
    eng: 'New button',
    ru: 'Новая кнопка',
    pt: 'Novo botão',
  },
  'Node buttonGroups system button': {
    eng: 'system button',
    ru: 'системная кнопка',
    pt: 'botão do sistema',
  },
  'Node random component': {
    eng: 'Random transition',
    ru: 'Случайный переход',
    pt: 'Transição aleatória',
  },
  'Node:Errors: set image url': {
    eng: 'Insert URL',
    ru: 'Вставьте ссылку',
    pt: 'Inserir URL',
  },
  'Node:Errors: scenario logic error': {
    eng: 'Failed to upload the script: ',
    ru: 'Ошибка загрузки сценария: ',
    pt: 'Falha ao carregar o script: ',
  },
  'No search results': {
    eng: 'No search results',
    ru: 'Ничего не найдено',
    pt: 'Sem resultados de pesquisa',
    cn: '未找到任何结果',
  },
  '': {
    eng: '',
    ru: '',
  },
};
