import { ProjectsApi as BotProjectsApi, } from "../../generated/Botadmin";
export class ProjectService {
    constructor(accountId, axios) {
        this.getProjects = (options) => {
            return this.projectsApi.readAll(this.accountId, undefined, options);
        };
        this.createProject = (botProjectCreateDto, options) => {
            return this.projectsApi
                .create(this.accountId, botProjectCreateDto, undefined, options)
                .then((res) => res.data);
        };
        this.updateProject = (projectId, botProjectCreateDto, options) => {
            return this.projectsApi.update(this.accountId, projectId, botProjectCreateDto, options);
        };
        this.cloneProject = (projectShortName, newName, options) => {
            if (!newName)
                newName = projectShortName + "-clone";
            return this.projectsApi.clone(this.accountId, projectShortName, newName, true, options);
        };
        this.accountId = accountId;
        this.projectsApi = new BotProjectsApi({}, ProjectService.BASE_PATH, axios);
    }
}
ProjectService.BASE_PATH = "";
