import { parseErrors } from '../../pipes/pureFunctions';
import {
  VALIDATE_PROMOCODE_PENDING,
  VALIDATE_PROMOCODE_FULFILLED,
  VALIDATE_PROMOCODE_REJECTED,
  DROP_ERRORS,
  DROP_PROMOCODE,
} from '../../constants/promocodes.actions';

/*
{
    "id": 2,
    "title": "101",
    "usageType": "reusable",
    "discountType": "fix",
    "discountValue": 200
}*/
const InitialState = {
  errors: [],
  fetching: false,
  fetched: false,
  promoCode: null,
};

export default function PromocodesReducer(state = InitialState, action) {
  switch (action.type) {
    case DROP_PROMOCODE: {
      return {
        ...state,
        promoCode: InitialState.promoCode,
      };
    }

    case DROP_ERRORS: {
      return {
        ...state,
        errors: InitialState.errors,
      };
    }

    case VALIDATE_PROMOCODE_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
        errors: InitialState.errors,
        promoCode: InitialState.promoCode,
      };
    }

    case VALIDATE_PROMOCODE_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        fetched: true,
        errors: errors,
      };
    }

    case VALIDATE_PROMOCODE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        promoCode: action.payload.data,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
