const prefix = '@entities/';

export const LOAD_ENTITIES = prefix + 'LOAD_ENTITIES';
export const LOAD_ENTITIES_FULFILLED = prefix + 'LOAD_ENTITIES_FULFILLED';
export const LOAD_ENTITIES_PENDING = prefix + 'LOAD_ENTITIES_PENDING';
export const LOAD_ENTITIES_REJECTED = prefix + 'LOAD_ENTITIES_REJECTED';

export const LOAD_SYSTEM_ENTITIES = prefix + 'LOAD_SYSTEM_ENTITIES';
export const LOAD_SYSTEM_ENTITIES_FULFILLED = prefix + 'LOAD_SYSTEM_ENTITIES_FULFILLED';
export const LOAD_SYSTEM_ENTITIES_PENDING = prefix + 'LOAD_SYSTEM_ENTITIES_PENDING';
export const LOAD_SYSTEM_ENTITIES_REJECTED = prefix + 'LOAD_SYSTEM_ENTITIES_REJECTED';

export const ADD_ENTITY = prefix + 'ADD_ENTITY';
export const ADD_ENTITY_FULFILLED = prefix + 'ADD_ENTITY_FULFILLED';
export const ADD_ENTITY_PENDING = prefix + 'ADD_ENTITY_PENDING';
export const ADD_ENTITY_REJECTED = prefix + 'ADD_ENTITY_REJECTED';

export const LOAD_DETAIL_ENTITY = prefix + 'LOAD_DETAIL_ENTITY';
export const LOAD_DETAIL_ENTITY_FULFILLED = prefix + 'LOAD_DETAIL_ENTITY_FULFILLED';
export const LOAD_DETAIL_ENTITY_PENDING = prefix + 'LOAD_DETAIL_ENTITY_PENDING';
export const LOAD_DETAIL_ENTITY_REJECTED = prefix + 'LOAD_DETAIL_ENTITY_REJECTED';

export const SAVE_DETAIL_ENTITY = prefix + 'SAVE_DETAIL_ENTITY';
export const SAVE_DETAIL_ENTITY_FULFILLED = prefix + 'SAVE_DETAIL_ENTITY_FULFILLED';
export const SAVE_DETAIL_ENTITY_PENDING = prefix + 'SAVE_DETAIL_ENTITY_PENDING';
export const SAVE_DETAIL_ENTITY_REJECTED = prefix + 'SAVE_DETAIL_ENTITY_REJECTED';

export const SAVE_DETAIL_ENTITY_NAME = prefix + 'SAVE_DETAIL_ENTITY_NAME';
export const SAVE_DETAIL_ENTITY_NAME_FULFILLED = prefix + 'SAVE_DETAIL_ENTITY_NAME_FULFILLED';
export const SAVE_DETAIL_ENTITY_NAME_PENDING = prefix + 'SAVE_DETAIL_ENTITY_NAME_PENDING';
export const SAVE_DETAIL_ENTITY_NAME_REJECTED = prefix + 'SAVE_DETAIL_ENTITY_NAME_REJECTED';

export const DELETE_DETAIL_ENTITY = prefix + 'DELETE_DETAIL_ENTITY';
export const DELETE_DETAIL_ENTITY_FULFILLED = prefix + 'DELETE_DETAIL_ENTITY_FULFILLED';
export const DELETE_DETAIL_ENTITY_PENDING = prefix + 'DELETE_DETAIL_ENTITY_PENDING';
export const DELETE_DETAIL_ENTITY_REJECTED = prefix + 'DELETE_DETAIL_ENTITY_REJECTED';

export const UPLOAD_FILE = prefix + 'UPLOAD_FILE';
export const UPLOAD_FILE_FULFILLED = prefix + 'UPLOAD_FILE_FULFILLED';
export const UPLOAD_FILE_PENDING = prefix + 'UPLOAD_FILE_PENDING';
export const UPLOAD_FILE_REJECTED = prefix + 'UPLOAD_FILE_REJECTED';
