import React from 'react';
import localize from 'localization';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import { allChannels } from '../../../../../constants/channelTypes';
import { InputText, SwitchButton } from '@just-ai/just-ui';
import classnames from 'classnames';

const { translate: t } = localize;

export default class DefaultEditForm extends BaseEditForm {
  constructor(props) {
    super(props);
    this.form = {};

    let startDialogueWithUserMessage = true;
    let forceReply = false;
    let access_token = '';

    if (
      Boolean(props.editableChannel) &&
      Boolean(props.editableChannel.id) &&
      Boolean(props.editableChannel.customData)
    ) {
      if (props.editableChannel.customData.hasOwnProperty('startDialogueWithUserMessage')) {
        startDialogueWithUserMessage = props.editableChannel.customData.startDialogueWithUserMessage;
      }

      if (props.editableChannel.customData.hasOwnProperty('force_reply')) {
        forceReply = props.editableChannel.customData['force_reply'];
      }

      if (props.editableChannel.customData.hasOwnProperty('access_token')) {
        access_token = props.editableChannel.customData['access_token'];
      }
    }

    this.state = {
      isLoading: false,
      rollout: true,
      accessToken: '',
      userID: '',
      errors: [],
      bannerDescription: '',
      customSubmitTitle: '',
      helpUrl: '',
      blockForm: false,
      startDialogueWithUserMessage,
      forceReply,
      access_token,
    };
  }
  getChannelData() {
    return {
      botName: this.form.name?.value,
      senderName: this.form.name?.value,
      accessToken: this.form.botToken?.value || this.props.editableChannel.accessToken,
      channelType: this.props.editableChannel.channelType,
      id: this.props.editableChannel?.id,
      rollout: 'MANUAL',
      branch: 'master',
      project: {
        ...this.props.editableChannel.project,
      },
    };
  }
  createChannel = async () => {
    const { createChannel } = this.props;

    const channelData = this.getChannelData();

    if (this.props.editableChannel.channelType === 'VK') {
      channelData.customData = {
        startDialogueWithUserMessage: this.state.startDialogueWithUserMessage,
      };
    }

    if (this.props.editableChannel.channelType === allChannels.JIVOSITE) {
      channelData.customData = {
        force_reply: this.state.forceReply,
      };
    }

    await createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async () => {
    const { editChannel } = this.props;

    const channelData = this.getChannelData();

    if (this.props.editableChannel.channelType === 'VK') {
      channelData.customData = {
        startDialogueWithUserMessage: this.state.startDialogueWithUserMessage,
      };
    }

    if (this.props.editableChannel.channelType === allChannels.JIVOSITE) {
      channelData.customData = {
        force_reply: this.state.forceReply,
      };
    }

    if (this.props.editableChannel.channelType === allChannels.MARUSIA) {
      channelData.customData = {
        access_token: this.form.access_token.value,
      };
    }

    await editChannel(channelData);
    this.props.onClose();
  };

  getLabelName = () => {
    const { editableChannel } = this.props;
    switch (editableChannel.channelType) {
      case 'GOOGLE': {
        return t('Project ID');
      }
      case 'VK': {
        return t('Bot key');
      }
      default: {
        return t('Bot token');
      }
    }
  };

  toggleStartDialogueWithUserMessage = value => this.setState({ startDialogueWithUserMessage: value });

  toggleForceReply = value => this.setState({ forceReply: value });

  renderInputs() {
    const { editableChannel } = this.props;
    const { startDialogueWithUserMessage, forceReply } = this.state;

    return (
      <>
        {(editableChannel.id ||
          [allChannels.MARUSIA, allChannels.JIVOSITE, allChannels.SBER].includes(editableChannel.channelType) ===
            false) &&
          editableChannel.channelType !== 'ZENBOX' && (
            <div className={classes.formControl}>
              <p className={classes.label}>{this.getLabelName()}</p>
              <InputText
                innerRef={input => (this.form.botToken = input)}
                id='bot_token'
                data-test-id='Channel.EditForm.BotToken'
                readOnly={editableChannel.channelType === 'CHAT_API' && !!editableChannel.botserverBotId}
                defaultValue={editableChannel.accessToken}
                placeholder={t('Enter custom', this.getLabelName().toLowerCase())}
              />
            </div>
          )}

        {editableChannel.channelType === 'VK' && (
          <div className={classnames(classes.formControl, classes.switchContainer)}>
            <SwitchButton
              id='startDialogueWithUserMessage'
              value={startDialogueWithUserMessage}
              onChange={this.toggleStartDialogueWithUserMessage}
              color='primary'
            />
            <label htmlFor='startDialogueWithUserMessage'>{t('Start a dialogue with any user messages')}</label>
          </div>
        )}
        {editableChannel.channelType === allChannels.JIVOSITE && (
          <div className={classnames(classes.formControl, classes.switchContainer)}>
            <SwitchButton id='forceReply' value={forceReply} color='primary' onChange={this.toggleForceReply} />
            <label htmlFor='forceReply'>{t('ChannelsConfig: force reply jivo label')}</label>
          </div>
        )}

        {editableChannel.channelType === allChannels.MARUSIA && editableChannel.id && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('Service Access token')}</p>
            <InputText
              id='access_token'
              defaultValue={this.state.access_token}
              innerRef={input => (this.form.access_token = input)}
              placeholder={t('Enter custom', t('Service Access token'))}
            />
          </div>
        )}
      </>
    );
  }
}
