import React, { Component } from 'react';
import forEach from 'lodash/forEach';
import localize from '../../../../../localization';
import { JustSelect } from '@just-ai/just-ui';
import classes from './Groups.module.scss';

export default class DestinationSelect extends Component {
  componentSelectIDS = ids => {
    const { k, selectIDS } = this.props;
    let array =
      ids?.map(id => {
        return id;
      }) || [];
    selectIDS(k, array);
  };

  render() {
    const { destinationIds, liveTexOptions, k } = this.props;

    let destinationIdsVals = [];
    if (!!destinationIds) {
      forEach(destinationIds, id => {
        destinationIdsVals.push(id);
      });
    } else {
      destinationIdsVals = null;
    }

    return (
      <div>
        <label>Ids</label>

        <JustSelect
          options={liveTexOptions}
          value={destinationIdsVals}
          onChange={this.componentSelectIDS}
          inputPlaceholder={localize.translate('Destination select placeholder')}
          className={classes.destinationSelect}
          multiple
          message={{
            NOT_FOUND: localize.translate('No matches'),
          }}
        />

        <div className='hidden'>
          {destinationIds?.map((item, z) => (
            <input
              type='text'
              key={`${k}_destinationids_input_${z}`}
              required
              defaultValue={item}
              name={`config.destinations[${k}].ids[${z}]`}
            />
          ))}
        </div>
      </div>
    );
  }
}
