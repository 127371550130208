// tslint:disable
// @ts-nocheck
/**
 * Botadmin REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 *
 * @export
 * @enum {string}
 */
export var AimylogicTtsProviderType;
(function (AimylogicTtsProviderType) {
    AimylogicTtsProviderType["YANDEX"] = "YANDEX";
    AimylogicTtsProviderType["AIMYVOICE"] = "AIMYVOICE";
})(AimylogicTtsProviderType || (AimylogicTtsProviderType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var BotCategory;
(function (BotCategory) {
    BotCategory["GAMES"] = "GAMES";
    BotCategory["NEWS"] = "NEWS";
    BotCategory["ECOMMERCE"] = "E_COMMERCE";
    BotCategory["SUPPORT"] = "SUPPORT";
    BotCategory["SMARTHOME"] = "SMART_HOME";
    BotCategory["PRODUCTIVITY"] = "PRODUCTIVITY";
    BotCategory["OTHER"] = "OTHER";
})(BotCategory || (BotCategory = {}));
/**
 * @export
 * @enum {string}
 */
export var ProjectSkillStatusEnum;
(function (ProjectSkillStatusEnum) {
    ProjectSkillStatusEnum["CREATED"] = "CREATED";
    ProjectSkillStatusEnum["FILLED"] = "FILLED";
    ProjectSkillStatusEnum["FINISHED"] = "FINISHED";
})(ProjectSkillStatusEnum || (ProjectSkillStatusEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var BotType;
(function (BotType) {
    BotType["CHATBOT"] = "CHAT_BOT";
    BotType["CALLSBOT"] = "CALLS_BOT";
})(BotType || (BotType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var CallListDeleteStatusDto;
(function (CallListDeleteStatusDto) {
    CallListDeleteStatusDto["Ok"] = "ok";
    CallListDeleteStatusDto["Error"] = "error";
})(CallListDeleteStatusDto || (CallListDeleteStatusDto = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var CallsBotType;
(function (CallsBotType) {
    CallsBotType["DIALER"] = "DIALER";
    CallsBotType["IVR"] = "IVR";
})(CallsBotType || (CallsBotType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ClientListDeleteStatus;
(function (ClientListDeleteStatus) {
    ClientListDeleteStatus["Ok"] = "ok";
    ClientListDeleteStatus["Error"] = "error";
})(ClientListDeleteStatus || (ClientListDeleteStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var LiveChatType;
(function (LiveChatType) {
    LiveChatType["WEBIM"] = "WEBIM";
    LiveChatType["LIVETEX"] = "LIVETEX";
    LiveChatType["SALESFORCE"] = "SALESFORCE";
    LiveChatType["JIVOSITE"] = "JIVOSITE";
    LiveChatType["OPERATORAPI"] = "OPERATORAPI";
    LiveChatType["CHAT2DESK"] = "CHAT2DESK";
    LiveChatType["AIMYCHAT"] = "AIMYCHAT";
})(LiveChatType || (LiveChatType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ProjectWarning;
(function (ProjectWarning) {
    ProjectWarning["GITHUBWRONGACCOUNTTOKEN"] = "GITHUB_WRONG_ACCOUNT_TOKEN";
})(ProjectWarning || (ProjectWarning = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var RepositoryAuthType;
(function (RepositoryAuthType) {
    RepositoryAuthType["PERSONALUSER"] = "PERSONAL_USER";
    RepositoryAuthType["GUESTUSER"] = "GUEST_USER";
})(RepositoryAuthType || (RepositoryAuthType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ValidationErrorType;
(function (ValidationErrorType) {
    ValidationErrorType["BADFORMAT"] = "BADFORMAT";
    ValidationErrorType["DUPLICATED"] = "DUPLICATED";
    ValidationErrorType["TIMEZONEBADFORMAT"] = "TIMEZONEBADFORMAT";
})(ValidationErrorType || (ValidationErrorType = {}));
/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Initialize account
         * @param {number} accountId Account identifier
         * @param {InitializeAccountDto} [initializeAccountDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeAccount(accountId, initializeAccountDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling initializeAccount.");
            }
            const localVarPath = `/api/botadmin/internal/accounts/{accountId}/initialize`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof initializeAccountDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(initializeAccountDto !== undefined ? initializeAccountDto : {})
                : initializeAccountDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Initialize account
         * @param {number} accountId Account identifier
         * @param {InitializeAccountDto} [initializeAccountDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeAccount(accountId, initializeAccountDto, options) {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).initializeAccount(accountId, initializeAccountDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Initialize account
         * @param {number} accountId Account identifier
         * @param {InitializeAccountDto} [initializeAccountDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeAccount(accountId, initializeAccountDto, options) {
            return AccountsApiFp(configuration).initializeAccount(accountId, initializeAccountDto, options)(axios, basePath);
        },
    };
};
/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     *
     * @summary Initialize account
     * @param {number} accountId Account identifier
     * @param {InitializeAccountDto} [initializeAccountDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    initializeAccount(accountId, initializeAccountDto, options) {
        return AccountsApiFp(this.configuration).initializeAccount(accountId, initializeAccountDto, options)(this.axios, this.basePath);
    }
}
/**
 * AimylogicVoiceApi - axios parameter creator
 * @export
 */
export const AimylogicVoiceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get the Aimylogic voices list
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVoicesAimylogic(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling listVoicesAimylogic.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/aimylogic/voices`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AimylogicVoiceApi - functional programming interface
 * @export
 */
export const AimylogicVoiceApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get the Aimylogic voices list
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVoicesAimylogic(accountId, options) {
            const localVarAxiosArgs = AimylogicVoiceApiAxiosParamCreator(configuration).listVoicesAimylogic(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AimylogicVoiceApi - factory interface
 * @export
 */
export const AimylogicVoiceApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get the Aimylogic voices list
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVoicesAimylogic(accountId, options) {
            return AimylogicVoiceApiFp(configuration).listVoicesAimylogic(accountId, options)(axios, basePath);
        },
    };
};
/**
 * AimylogicVoiceApi - object-oriented interface
 * @export
 * @class AimylogicVoiceApi
 * @extends {BaseAPI}
 */
export class AimylogicVoiceApi extends BaseAPI {
    /**
     *
     * @summary Get the Aimylogic voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimylogicVoiceApi
     */
    listVoicesAimylogic(accountId, options) {
        return AimylogicVoiceApiFp(this.configuration).listVoicesAimylogic(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * AimyvoiceApi - axios parameter creator
 * @export
 */
export const AimyvoiceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get the Aimyvoice voices list
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVoicesAimyvoice(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling listVoicesAimyvoice.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/aimyvoice/voices`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AimyvoiceApi - functional programming interface
 * @export
 */
export const AimyvoiceApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get the Aimyvoice voices list
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVoicesAimyvoice(accountId, options) {
            const localVarAxiosArgs = AimyvoiceApiAxiosParamCreator(configuration).listVoicesAimyvoice(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AimyvoiceApi - factory interface
 * @export
 */
export const AimyvoiceApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get the Aimyvoice voices list
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVoicesAimyvoice(accountId, options) {
            return AimyvoiceApiFp(configuration).listVoicesAimyvoice(accountId, options)(axios, basePath);
        },
    };
};
/**
 * AimyvoiceApi - object-oriented interface
 * @export
 * @class AimyvoiceApi
 * @extends {BaseAPI}
 */
export class AimyvoiceApi extends BaseAPI {
    /**
     *
     * @summary Get the Aimyvoice voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyvoiceApi
     */
    listVoicesAimyvoice(accountId, options) {
        return AimyvoiceApiFp(this.configuration).listVoicesAimyvoice(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * AsmAsrApi - axios parameter creator
 * @export
 */
export const AsmAsrApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get ASM ASR models
         * @param {number} accountId Account identifier
         * @param {number} speechId Speech Provider identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsmModels(accountId, speechId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAsmModels.");
            }
            // verify required parameter 'speechId' is not null or undefined
            if (speechId === null || speechId === undefined) {
                throw new RequiredError("speechId", "Required parameter speechId was null or undefined when calling getAsmModels.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/resterisk-speech/asm/{speechId}/models`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"speechId"}}`, encodeURIComponent(String(speechId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get internal ASM ASR models
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAsmModels(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getInternalAsmModels.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/resterisk-speech/asm/internal/models`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AsmAsrApi - functional programming interface
 * @export
 */
export const AsmAsrApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get ASM ASR models
         * @param {number} accountId Account identifier
         * @param {number} speechId Speech Provider identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsmModels(accountId, speechId, options) {
            const localVarAxiosArgs = AsmAsrApiAxiosParamCreator(configuration).getAsmModels(accountId, speechId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get internal ASM ASR models
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAsmModels(accountId, options) {
            const localVarAxiosArgs = AsmAsrApiAxiosParamCreator(configuration).getInternalAsmModels(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AsmAsrApi - factory interface
 * @export
 */
export const AsmAsrApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get ASM ASR models
         * @param {number} accountId Account identifier
         * @param {number} speechId Speech Provider identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsmModels(accountId, speechId, options) {
            return AsmAsrApiFp(configuration).getAsmModels(accountId, speechId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get internal ASM ASR models
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalAsmModels(accountId, options) {
            return AsmAsrApiFp(configuration).getInternalAsmModels(accountId, options)(axios, basePath);
        },
    };
};
/**
 * AsmAsrApi - object-oriented interface
 * @export
 * @class AsmAsrApi
 * @extends {BaseAPI}
 */
export class AsmAsrApi extends BaseAPI {
    /**
     *
     * @summary Get ASM ASR models
     * @param {number} accountId Account identifier
     * @param {number} speechId Speech Provider identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsmAsrApi
     */
    getAsmModels(accountId, speechId, options) {
        return AsmAsrApiFp(this.configuration).getAsmModels(accountId, speechId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get internal ASM ASR models
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsmAsrApi
     */
    getInternalAsmModels(accountId, options) {
        return AsmAsrApiFp(this.configuration).getInternalAsmModels(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * AzureAsrApi - axios parameter creator
 * @export
 */
export const AzureAsrApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get Azure ASR recommended settings
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAzureAsrRecommendedSettings(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAzureAsrRecommendedSettings.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/resterisk-speech/azure/recommended-settings`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AzureAsrApi - functional programming interface
 * @export
 */
export const AzureAsrApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get Azure ASR recommended settings
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAzureAsrRecommendedSettings(accountId, options) {
            const localVarAxiosArgs = AzureAsrApiAxiosParamCreator(configuration).getAzureAsrRecommendedSettings(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AzureAsrApi - factory interface
 * @export
 */
export const AzureAsrApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get Azure ASR recommended settings
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAzureAsrRecommendedSettings(accountId, options) {
            return AzureAsrApiFp(configuration).getAzureAsrRecommendedSettings(accountId, options)(axios, basePath);
        },
    };
};
/**
 * AzureAsrApi - object-oriented interface
 * @export
 * @class AzureAsrApi
 * @extends {BaseAPI}
 */
export class AzureAsrApi extends BaseAPI {
    /**
     *
     * @summary Get Azure ASR recommended settings
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AzureAsrApi
     */
    getAzureAsrRecommendedSettings(accountId, options) {
        return AzureAsrApiFp(this.configuration).getAzureAsrRecommendedSettings(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * BotConfigsApi - axios parameter creator
 * @export
 */
export const BotConfigsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get all livechat configs by accountId
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLivechatConfigsByAccountId(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAllLivechatConfigsByAccountId.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/configs/livechat`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get widget options. Parameter language must be one of `ru`, `en`, `cn`, `pt`, `kz`, `es`.
         * @param {number} accountId Account identifier
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgetOptions(accountId, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getWidgetOptions.");
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError("language", "Required parameter language was null or undefined when calling getWidgetOptions.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/widgetOptions/{language}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * BotConfigsApi - functional programming interface
 * @export
 */
export const BotConfigsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get all livechat configs by accountId
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLivechatConfigsByAccountId(accountId, options) {
            const localVarAxiosArgs = BotConfigsApiAxiosParamCreator(configuration).getAllLivechatConfigsByAccountId(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get widget options. Parameter language must be one of `ru`, `en`, `cn`, `pt`, `kz`, `es`.
         * @param {number} accountId Account identifier
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgetOptions(accountId, language, options) {
            const localVarAxiosArgs = BotConfigsApiAxiosParamCreator(configuration).getWidgetOptions(accountId, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * BotConfigsApi - factory interface
 * @export
 */
export const BotConfigsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get all livechat configs by accountId
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLivechatConfigsByAccountId(accountId, options) {
            return BotConfigsApiFp(configuration).getAllLivechatConfigsByAccountId(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get widget options. Parameter language must be one of `ru`, `en`, `cn`, `pt`, `kz`, `es`.
         * @param {number} accountId Account identifier
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgetOptions(accountId, language, options) {
            return BotConfigsApiFp(configuration).getWidgetOptions(accountId, language, options)(axios, basePath);
        },
    };
};
/**
 * BotConfigsApi - object-oriented interface
 * @export
 * @class BotConfigsApi
 * @extends {BaseAPI}
 */
export class BotConfigsApi extends BaseAPI {
    /**
     *
     * @summary Get all livechat configs by accountId
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotConfigsApi
     */
    getAllLivechatConfigsByAccountId(accountId, options) {
        return BotConfigsApiFp(this.configuration).getAllLivechatConfigsByAccountId(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get widget options. Parameter language must be one of `ru`, `en`, `cn`, `pt`, `kz`, `es`.
     * @param {number} accountId Account identifier
     * @param {string} language language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotConfigsApi
     */
    getWidgetOptions(accountId, language, options) {
        return BotConfigsApiFp(this.configuration).getWidgetOptions(accountId, language, options)(this.axios, this.basePath);
    }
}
/**
 * ClientListApi - axios parameter creator
 * @export
 */
export const ClientListApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create client list by uploading excel file.
         * @param {number} accountId Account identifier
         * @param {string} channelType Channel type
         * @param {boolean} agreementApplied Flag confirming that the list of clients was received with the consent of subscribers
         * @param {string} name
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientList(accountId, channelType, agreementApplied, name, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createClientList.");
            }
            // verify required parameter 'channelType' is not null or undefined
            if (channelType === null || channelType === undefined) {
                throw new RequiredError("channelType", "Required parameter channelType was null or undefined when calling createClientList.");
            }
            // verify required parameter 'agreementApplied' is not null or undefined
            if (agreementApplied === null || agreementApplied === undefined) {
                throw new RequiredError("agreementApplied", "Required parameter agreementApplied was null or undefined when calling createClientList.");
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError("name", "Required parameter name was null or undefined when calling createClientList.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/client-list/upload`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (channelType !== undefined) {
                localVarQueryParameter["channelType"] = channelType;
            }
            if (agreementApplied !== undefined) {
                localVarQueryParameter["agreementApplied"] = agreementApplied;
            }
            if (name !== undefined) {
                localVarFormParams.append("name", name);
            }
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create client list with analytic filters
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} channelType Channel type
         * @param {string} name Name
         * @param {FiltersDto} filtersDto
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientListWithAnalyticFilters(accountId, projectShortName, channelType, name, filtersDto, timezone, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createClientListWithAnalyticFilters.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling createClientListWithAnalyticFilters.");
            }
            // verify required parameter 'channelType' is not null or undefined
            if (channelType === null || channelType === undefined) {
                throw new RequiredError("channelType", "Required parameter channelType was null or undefined when calling createClientListWithAnalyticFilters.");
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError("name", "Required parameter name was null or undefined when calling createClientListWithAnalyticFilters.");
            }
            // verify required parameter 'filtersDto' is not null or undefined
            if (filtersDto === null || filtersDto === undefined) {
                throw new RequiredError("filtersDto", "Required parameter filtersDto was null or undefined when calling createClientListWithAnalyticFilters.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/client-list/with-analytic-filters`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (channelType !== undefined) {
                localVarQueryParameter["channelType"] = channelType;
            }
            if (name !== undefined) {
                localVarQueryParameter["name"] = name;
            }
            if (timezone !== undefined && timezone !== null) {
                localVarHeaderParameter["Timezone"] = String(JSON.stringify(timezone));
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof filtersDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(filtersDto !== undefined ? filtersDto : {})
                : filtersDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete client list.
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientList(accountId, clientListId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteClientList.");
            }
            // verify required parameter 'clientListId' is not null or undefined
            if (clientListId === null || clientListId === undefined) {
                throw new RequiredError("clientListId", "Required parameter clientListId was null or undefined when calling deleteClientList.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/client-list/{clientListId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"clientListId"}}`, encodeURIComponent(String(clientListId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete call list
         * @param {number} accountId Account identifier
         * @param {number} callListId Dialer call list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientListByDialerId(accountId, callListId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteClientListByDialerId.");
            }
            // verify required parameter 'callListId' is not null or undefined
            if (callListId === null || callListId === undefined) {
                throw new RequiredError("callListId", "Required parameter callListId was null or undefined when calling deleteClientListByDialerId.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/call-list/{callListId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"callListId"}}`, encodeURIComponent(String(callListId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Download excel file with clients
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClientList(accountId, clientListId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling downloadClientList.");
            }
            // verify required parameter 'clientListId' is not null or undefined
            if (clientListId === null || clientListId === undefined) {
                throw new RequiredError("clientListId", "Required parameter clientListId was null or undefined when calling downloadClientList.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/client-list/{clientListId}/download`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"clientListId"}}`, encodeURIComponent(String(clientListId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Download csv file with clients
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClientListCsv(accountId, clientListId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling downloadClientListCsv.");
            }
            // verify required parameter 'clientListId' is not null or undefined
            if (clientListId === null || clientListId === undefined) {
                throw new RequiredError("clientListId", "Required parameter clientListId was null or undefined when calling downloadClientListCsv.");
            }
            const localVarPath = `/api/botadmin/internal/accounts/{accountId}/client-list/{clientListId}/download/csv`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"clientListId"}}`, encodeURIComponent(String(clientListId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Return summary information about clientList.
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientListSummary(accountId, clientListId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getClientListSummary.");
            }
            // verify required parameter 'clientListId' is not null or undefined
            if (clientListId === null || clientListId === undefined) {
                throw new RequiredError("clientListId", "Required parameter clientListId was null or undefined when calling getClientListSummary.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/client-list/{clientListId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"clientListId"}}`, encodeURIComponent(String(clientListId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Return list of clientList
         * @param {number} accountId Account identifier
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientList(accountId, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling listClientList.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/client-list`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Validate client list by uploading excel file.
         * @param {number} accountId Account identifier
         * @param {string} channelType Channel type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClientList(accountId, channelType, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling validateClientList.");
            }
            // verify required parameter 'channelType' is not null or undefined
            if (channelType === null || channelType === undefined) {
                throw new RequiredError("channelType", "Required parameter channelType was null or undefined when calling validateClientList.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/client-list/validate`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (channelType !== undefined) {
                localVarQueryParameter["channelType"] = channelType;
            }
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ClientListApi - functional programming interface
 * @export
 */
export const ClientListApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create client list by uploading excel file.
         * @param {number} accountId Account identifier
         * @param {string} channelType Channel type
         * @param {boolean} agreementApplied Flag confirming that the list of clients was received with the consent of subscribers
         * @param {string} name
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientList(accountId, channelType, agreementApplied, name, file, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).createClientList(accountId, channelType, agreementApplied, name, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create client list with analytic filters
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} channelType Channel type
         * @param {string} name Name
         * @param {FiltersDto} filtersDto
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientListWithAnalyticFilters(accountId, projectShortName, channelType, name, filtersDto, timezone, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).createClientListWithAnalyticFilters(accountId, projectShortName, channelType, name, filtersDto, timezone, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete client list.
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientList(accountId, clientListId, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).deleteClientList(accountId, clientListId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete call list
         * @param {number} accountId Account identifier
         * @param {number} callListId Dialer call list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientListByDialerId(accountId, callListId, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).deleteClientListByDialerId(accountId, callListId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Download excel file with clients
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClientList(accountId, clientListId, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).downloadClientList(accountId, clientListId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Download csv file with clients
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClientListCsv(accountId, clientListId, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).downloadClientListCsv(accountId, clientListId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Return summary information about clientList.
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientListSummary(accountId, clientListId, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).getClientListSummary(accountId, clientListId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Return list of clientList
         * @param {number} accountId Account identifier
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientList(accountId, page, size, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).listClientList(accountId, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Validate client list by uploading excel file.
         * @param {number} accountId Account identifier
         * @param {string} channelType Channel type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClientList(accountId, channelType, file, options) {
            const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).validateClientList(accountId, channelType, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ClientListApi - factory interface
 * @export
 */
export const ClientListApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create client list by uploading excel file.
         * @param {number} accountId Account identifier
         * @param {string} channelType Channel type
         * @param {boolean} agreementApplied Flag confirming that the list of clients was received with the consent of subscribers
         * @param {string} name
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientList(accountId, channelType, agreementApplied, name, file, options) {
            return ClientListApiFp(configuration).createClientList(accountId, channelType, agreementApplied, name, file, options)(axios, basePath);
        },
        /**
         *
         * @summary Create client list with analytic filters
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} channelType Channel type
         * @param {string} name Name
         * @param {FiltersDto} filtersDto
         * @param {number} [timezone] Timezone offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientListWithAnalyticFilters(accountId, projectShortName, channelType, name, filtersDto, timezone, options) {
            return ClientListApiFp(configuration).createClientListWithAnalyticFilters(accountId, projectShortName, channelType, name, filtersDto, timezone, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete client list.
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientList(accountId, clientListId, options) {
            return ClientListApiFp(configuration).deleteClientList(accountId, clientListId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete call list
         * @param {number} accountId Account identifier
         * @param {number} callListId Dialer call list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientListByDialerId(accountId, callListId, options) {
            return ClientListApiFp(configuration).deleteClientListByDialerId(accountId, callListId, options)(axios, basePath);
        },
        /**
         *
         * @summary Download excel file with clients
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClientList(accountId, clientListId, options) {
            return ClientListApiFp(configuration).downloadClientList(accountId, clientListId, options)(axios, basePath);
        },
        /**
         *
         * @summary Download csv file with clients
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadClientListCsv(accountId, clientListId, options) {
            return ClientListApiFp(configuration).downloadClientListCsv(accountId, clientListId, options)(axios, basePath);
        },
        /**
         *
         * @summary Return summary information about clientList.
         * @param {number} accountId Account identifier
         * @param {number} clientListId Client list id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientListSummary(accountId, clientListId, options) {
            return ClientListApiFp(configuration).getClientListSummary(accountId, clientListId, options)(axios, basePath);
        },
        /**
         *
         * @summary Return list of clientList
         * @param {number} accountId Account identifier
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClientList(accountId, page, size, options) {
            return ClientListApiFp(configuration).listClientList(accountId, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Validate client list by uploading excel file.
         * @param {number} accountId Account identifier
         * @param {string} channelType Channel type
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateClientList(accountId, channelType, file, options) {
            return ClientListApiFp(configuration).validateClientList(accountId, channelType, file, options)(axios, basePath);
        },
    };
};
/**
 * ClientListApi - object-oriented interface
 * @export
 * @class ClientListApi
 * @extends {BaseAPI}
 */
export class ClientListApi extends BaseAPI {
    /**
     *
     * @summary Create client list by uploading excel file.
     * @param {number} accountId Account identifier
     * @param {string} channelType Channel type
     * @param {boolean} agreementApplied Flag confirming that the list of clients was received with the consent of subscribers
     * @param {string} name
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    createClientList(accountId, channelType, agreementApplied, name, file, options) {
        return ClientListApiFp(this.configuration).createClientList(accountId, channelType, agreementApplied, name, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create client list with analytic filters
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} channelType Channel type
     * @param {string} name Name
     * @param {FiltersDto} filtersDto
     * @param {number} [timezone] Timezone offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    createClientListWithAnalyticFilters(accountId, projectShortName, channelType, name, filtersDto, timezone, options) {
        return ClientListApiFp(this.configuration).createClientListWithAnalyticFilters(accountId, projectShortName, channelType, name, filtersDto, timezone, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete client list.
     * @param {number} accountId Account identifier
     * @param {number} clientListId Client list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    deleteClientList(accountId, clientListId, options) {
        return ClientListApiFp(this.configuration).deleteClientList(accountId, clientListId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete call list
     * @param {number} accountId Account identifier
     * @param {number} callListId Dialer call list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    deleteClientListByDialerId(accountId, callListId, options) {
        return ClientListApiFp(this.configuration).deleteClientListByDialerId(accountId, callListId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Download excel file with clients
     * @param {number} accountId Account identifier
     * @param {number} clientListId Client list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    downloadClientList(accountId, clientListId, options) {
        return ClientListApiFp(this.configuration).downloadClientList(accountId, clientListId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Download csv file with clients
     * @param {number} accountId Account identifier
     * @param {number} clientListId Client list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    downloadClientListCsv(accountId, clientListId, options) {
        return ClientListApiFp(this.configuration).downloadClientListCsv(accountId, clientListId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Return summary information about clientList.
     * @param {number} accountId Account identifier
     * @param {number} clientListId Client list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    getClientListSummary(accountId, clientListId, options) {
        return ClientListApiFp(this.configuration).getClientListSummary(accountId, clientListId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Return list of clientList
     * @param {number} accountId Account identifier
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    listClientList(accountId, page, size, options) {
        return ClientListApiFp(this.configuration).listClientList(accountId, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Validate client list by uploading excel file.
     * @param {number} accountId Account identifier
     * @param {string} channelType Channel type
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientListApi
     */
    validateClientList(accountId, channelType, file, options) {
        return ClientListApiFp(this.configuration).validateClientList(accountId, channelType, file, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin request for end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError("loggingEnabledE2ECheck", "Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.");
            }
            const localVarPath = `/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter["loggingEnabledE2ECheck"] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin request for end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Botadmin request for end to end check
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     *
     * @summary Botadmin request for end to end check
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, options)(this.axios, this.basePath);
    }
}
/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Connect vonage account
         * @param {number} accountId Account identifier
         * @param {ConnectVonageAccountDto} connectVonageAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectVonageAccountRequest(accountId, connectVonageAccountDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling connectVonageAccountRequest.");
            }
            // verify required parameter 'connectVonageAccountDto' is not null or undefined
            if (connectVonageAccountDto === null ||
                connectVonageAccountDto === undefined) {
                throw new RequiredError("connectVonageAccountDto", "Required parameter connectVonageAccountDto was null or undefined when calling connectVonageAccountRequest.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/email/connect-vonage-account`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof connectVonageAccountDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(connectVonageAccountDto !== undefined ? connectVonageAccountDto : {})
                : connectVonageAccountDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Enable imputer feature request for account
         * @param {number} accountId Account identifier
         * @param {EnableImputerRequestDto} enableImputerRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableImputerFeatureRequest(accountId, enableImputerRequestDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling enableImputerFeatureRequest.");
            }
            // verify required parameter 'enableImputerRequestDto' is not null or undefined
            if (enableImputerRequestDto === null ||
                enableImputerRequestDto === undefined) {
                throw new RequiredError("enableImputerRequestDto", "Required parameter enableImputerRequestDto was null or undefined when calling enableImputerFeatureRequest.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/email/enable-imputer-request`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof enableImputerRequestDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(enableImputerRequestDto !== undefined ? enableImputerRequestDto : {})
                : enableImputerRequestDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Enable text campaign feature request for account
         * @param {number} accountId Account identifier
         * @param {EnableImputerRequestDto} enableImputerRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableTextCampaignFeatureRequest(accountId, enableImputerRequestDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling enableTextCampaignFeatureRequest.");
            }
            // verify required parameter 'enableImputerRequestDto' is not null or undefined
            if (enableImputerRequestDto === null ||
                enableImputerRequestDto === undefined) {
                throw new RequiredError("enableImputerRequestDto", "Required parameter enableImputerRequestDto was null or undefined when calling enableTextCampaignFeatureRequest.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/email/enable-text-campaign-request`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof enableImputerRequestDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(enableImputerRequestDto !== undefined ? enableImputerRequestDto : {})
                : enableImputerRequestDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Connect vonage account
         * @param {number} accountId Account identifier
         * @param {ConnectVonageAccountDto} connectVonageAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectVonageAccountRequest(accountId, connectVonageAccountDto, options) {
            const localVarAxiosArgs = EmailApiAxiosParamCreator(configuration).connectVonageAccountRequest(accountId, connectVonageAccountDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Enable imputer feature request for account
         * @param {number} accountId Account identifier
         * @param {EnableImputerRequestDto} enableImputerRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableImputerFeatureRequest(accountId, enableImputerRequestDto, options) {
            const localVarAxiosArgs = EmailApiAxiosParamCreator(configuration).enableImputerFeatureRequest(accountId, enableImputerRequestDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Enable text campaign feature request for account
         * @param {number} accountId Account identifier
         * @param {EnableImputerRequestDto} enableImputerRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableTextCampaignFeatureRequest(accountId, enableImputerRequestDto, options) {
            const localVarAxiosArgs = EmailApiAxiosParamCreator(configuration).enableTextCampaignFeatureRequest(accountId, enableImputerRequestDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Connect vonage account
         * @param {number} accountId Account identifier
         * @param {ConnectVonageAccountDto} connectVonageAccountDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectVonageAccountRequest(accountId, connectVonageAccountDto, options) {
            return EmailApiFp(configuration).connectVonageAccountRequest(accountId, connectVonageAccountDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Enable imputer feature request for account
         * @param {number} accountId Account identifier
         * @param {EnableImputerRequestDto} enableImputerRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableImputerFeatureRequest(accountId, enableImputerRequestDto, options) {
            return EmailApiFp(configuration).enableImputerFeatureRequest(accountId, enableImputerRequestDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Enable text campaign feature request for account
         * @param {number} accountId Account identifier
         * @param {EnableImputerRequestDto} enableImputerRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableTextCampaignFeatureRequest(accountId, enableImputerRequestDto, options) {
            return EmailApiFp(configuration).enableTextCampaignFeatureRequest(accountId, enableImputerRequestDto, options)(axios, basePath);
        },
    };
};
/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     *
     * @summary Connect vonage account
     * @param {number} accountId Account identifier
     * @param {ConnectVonageAccountDto} connectVonageAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    connectVonageAccountRequest(accountId, connectVonageAccountDto, options) {
        return EmailApiFp(this.configuration).connectVonageAccountRequest(accountId, connectVonageAccountDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Enable imputer feature request for account
     * @param {number} accountId Account identifier
     * @param {EnableImputerRequestDto} enableImputerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    enableImputerFeatureRequest(accountId, enableImputerRequestDto, options) {
        return EmailApiFp(this.configuration).enableImputerFeatureRequest(accountId, enableImputerRequestDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Enable text campaign feature request for account
     * @param {number} accountId Account identifier
     * @param {EnableImputerRequestDto} enableImputerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    enableTextCampaignFeatureRequest(accountId, enableImputerRequestDto, options) {
        return EmailApiFp(this.configuration).enableTextCampaignFeatureRequest(accountId, enableImputerRequestDto, options)(this.axios, this.basePath);
    }
}
/**
 * LabelsApi - axios parameter creator
 * @export
 */
export const LabelsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create log label
         * @param {number} accountId Account identifier
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(accountId, logLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createLabel.");
            }
            // verify required parameter 'logLabelCreate' is not null or undefined
            if (logLabelCreate === null || logLabelCreate === undefined) {
                throw new RequiredError("logLabelCreate", "Required parameter logLabelCreate was null or undefined when calling createLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof logLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelCreate !== undefined ? logLabelCreate : {})
                : logLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create log label group
         * @param {number} accountId Account identifier
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelGroup(accountId, logLabelGroupCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createLabelGroup.");
            }
            // verify required parameter 'logLabelGroupCreate' is not null or undefined
            if (logLabelGroupCreate === null || logLabelGroupCreate === undefined) {
                throw new RequiredError("logLabelGroupCreate", "Required parameter logLabelGroupCreate was null or undefined when calling createLabelGroup.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof logLabelGroupCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelGroupCreate !== undefined ? logLabelGroupCreate : {})
                : logLabelGroupCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel(accountId, sessionLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createSessionLabel.");
            }
            // verify required parameter 'sessionLabelCreate' is not null or undefined
            if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
                throw new RequiredError("sessionLabelCreate", "Required parameter sessionLabelCreate was null or undefined when calling createSessionLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sessionLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
                : sessionLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelGroup(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteLabelGroup.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteLabelGroup.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteSessionLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling deleteSessionLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get log label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLabelGroup.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getLabelGroup.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLabelGroups.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get log labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLabels.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getSessionLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getSessionLabels.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority(accountId, labelPriorityChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateGroupsPriority.");
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null ||
                labelPriorityChangeDto === undefined) {
                throw new RequiredError("labelPriorityChangeDto", "Required parameter labelPriorityChangeDto was null or undefined when calling updateGroupsPriority.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/change-priority`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelPriorityChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel(accountId, id, logLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling updateLabel.");
            }
            // verify required parameter 'logLabelCreate' is not null or undefined
            if (logLabelCreate === null || logLabelCreate === undefined) {
                throw new RequiredError("logLabelCreate", "Required parameter logLabelCreate was null or undefined when calling updateLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof logLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelCreate !== undefined ? logLabelCreate : {})
                : logLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update log label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabelGroup(accountId, id, logLabelGroupCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateLabelGroup.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling updateLabelGroup.");
            }
            // verify required parameter 'logLabelGroupCreate' is not null or undefined
            if (logLabelGroupCreate === null || logLabelGroupCreate === undefined) {
                throw new RequiredError("logLabelGroupCreate", "Required parameter logLabelGroupCreate was null or undefined when calling updateLabelGroup.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof logLabelGroupCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(logLabelGroupCreate !== undefined ? logLabelGroupCreate : {})
                : logLabelGroupCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update log labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogLabelsPriority(accountId, labelPriorityChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateLogLabelsPriority.");
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null ||
                labelPriorityChangeDto === undefined) {
                throw new RequiredError("labelPriorityChangeDto", "Required parameter labelPriorityChangeDto was null or undefined when calling updateLogLabelsPriority.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/change-priority`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelPriorityChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel(accountId, id, sessionLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateSessionLabel.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling updateSessionLabel.");
            }
            // verify required parameter 'sessionLabelCreate' is not null or undefined
            if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
                throw new RequiredError("sessionLabelCreate", "Required parameter sessionLabelCreate was null or undefined when calling updateSessionLabel.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof sessionLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
                : sessionLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority(accountId, labelPriorityChangeDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateSessionsPriority.");
            }
            // verify required parameter 'labelPriorityChangeDto' is not null or undefined
            if (labelPriorityChangeDto === null ||
                labelPriorityChangeDto === undefined) {
                throw new RequiredError("labelPriorityChangeDto", "Required parameter labelPriorityChangeDto was null or undefined when calling updateSessionsPriority.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/change-priority`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof labelPriorityChangeDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
                : labelPriorityChangeDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LabelsApi - functional programming interface
 * @export
 */
export const LabelsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create log label
         * @param {number} accountId Account identifier
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(accountId, logLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createLabel(accountId, logLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create log label group
         * @param {number} accountId Account identifier
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelGroup(accountId, logLabelGroupCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createLabelGroup(accountId, logLabelGroupCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel(accountId, sessionLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createSessionLabel(accountId, sessionLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelGroup(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabelGroup(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteSessionLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get log label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroup(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups(accountId, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroups(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get log labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels(accountId, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabels(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel(accountId, id, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabel(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels(accountId, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabels(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority(accountId, labelPriorityChangeDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel(accountId, id, logLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLabel(accountId, id, logLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update log label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabelGroup(accountId, id, logLabelGroupCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLabelGroup(accountId, id, logLabelGroupCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update log labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogLabelsPriority(accountId, labelPriorityChangeDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLogLabelsPriority(accountId, labelPriorityChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel(accountId, id, sessionLabelCreate, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority(accountId, labelPriorityChangeDto, options) {
            const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * LabelsApi - factory interface
 * @export
 */
export const LabelsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create log label
         * @param {number} accountId Account identifier
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(accountId, logLabelCreate, options) {
            return LabelsApiFp(configuration).createLabel(accountId, logLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Create log label group
         * @param {number} accountId Account identifier
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabelGroup(accountId, logLabelGroupCreate, options) {
            return LabelsApiFp(configuration).createLabelGroup(accountId, logLabelGroupCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Create session label
         * @param {number} accountId Account identifier
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionLabel(accountId, sessionLabelCreate, options) {
            return LabelsApiFp(configuration).createSessionLabel(accountId, sessionLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel(accountId, id, options) {
            return LabelsApiFp(configuration).deleteLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete labels group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelGroup(accountId, id, options) {
            return LabelsApiFp(configuration).deleteLabelGroup(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSessionLabel(accountId, id, options) {
            return LabelsApiFp(configuration).deleteSessionLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get log label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabel(accountId, id, options) {
            return LabelsApiFp(configuration).getLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroup(accountId, id, options) {
            return LabelsApiFp(configuration).getLabelGroup(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get label groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelGroups(accountId, options) {
            return LabelsApiFp(configuration).getLabelGroups(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get log labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels(accountId, options) {
            return LabelsApiFp(configuration).getLabels(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabel(accountId, id, options) {
            return LabelsApiFp(configuration).getSessionLabel(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get session labels
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionLabels(accountId, options) {
            return LabelsApiFp(configuration).getSessionLabels(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update groups priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroupsPriority(accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update log labels
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelCreate} logLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel(accountId, id, logLabelCreate, options) {
            return LabelsApiFp(configuration).updateLabel(accountId, id, logLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update log label group
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {LogLabelGroupCreate} logLabelGroupCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabelGroup(accountId, id, logLabelGroupCreate, options) {
            return LabelsApiFp(configuration).updateLabelGroup(accountId, id, logLabelGroupCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update log labels priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogLabelsPriority(accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateLogLabelsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
        /**
         *
         * @summary Update session label
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {SessionLabelCreate} sessionLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionLabel(accountId, id, sessionLabelCreate, options) {
            return LabelsApiFp(configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Update sessions priotiry
         * @param {number} accountId Account identifier
         * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionsPriority(accountId, labelPriorityChangeDto, options) {
            return LabelsApiFp(configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options)(axios, basePath);
        },
    };
};
/**
 * LabelsApi - object-oriented interface
 * @export
 * @class LabelsApi
 * @extends {BaseAPI}
 */
export class LabelsApi extends BaseAPI {
    /**
     *
     * @summary Create log label
     * @param {number} accountId Account identifier
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    createLabel(accountId, logLabelCreate, options) {
        return LabelsApiFp(this.configuration).createLabel(accountId, logLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create log label group
     * @param {number} accountId Account identifier
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    createLabelGroup(accountId, logLabelGroupCreate, options) {
        return LabelsApiFp(this.configuration).createLabelGroup(accountId, logLabelGroupCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create session label
     * @param {number} accountId Account identifier
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    createSessionLabel(accountId, sessionLabelCreate, options) {
        return LabelsApiFp(this.configuration).createSessionLabel(accountId, sessionLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete labels group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteLabelGroup(accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteLabelGroup(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    deleteSessionLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).deleteSessionLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get log label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).getLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getLabelGroup(accountId, id, options) {
        return LabelsApiFp(this.configuration).getLabelGroup(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get label groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getLabelGroups(accountId, options) {
        return LabelsApiFp(this.configuration).getLabelGroups(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get log labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getLabels(accountId, options) {
        return LabelsApiFp(this.configuration).getLabels(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getSessionLabel(accountId, id, options) {
        return LabelsApiFp(this.configuration).getSessionLabel(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    getSessionLabels(accountId, options) {
        return LabelsApiFp(this.configuration).getSessionLabels(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update groups priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateGroupsPriority(accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateGroupsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateLabel(accountId, id, logLabelCreate, options) {
        return LabelsApiFp(this.configuration).updateLabel(accountId, id, logLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update log label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateLabelGroup(accountId, id, logLabelGroupCreate, options) {
        return LabelsApiFp(this.configuration).updateLabelGroup(accountId, id, logLabelGroupCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update log labels priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateLogLabelsPriority(accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateLogLabelsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateSessionLabel(accountId, id, sessionLabelCreate, options) {
        return LabelsApiFp(this.configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update sessions priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelsApi
     */
    updateSessionsPriority(accountId, labelPriorityChangeDto, options) {
        return LabelsApiFp(this.configuration).updateSessionsPriority(accountId, labelPriorityChangeDto, options)(this.axios, this.basePath);
    }
}
/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Delete the bot project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling _delete.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling _delete.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Clone the bot project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} name Name
         * @param {boolean} [toJGraphLite] flag for front.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(accountId, projectShortName, name, toJGraphLite, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling clone.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling clone.");
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError("name", "Required parameter name was null or undefined when calling clone.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/clone`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (name !== undefined) {
                localVarQueryParameter["name"] = name;
            }
            if (toJGraphLite !== undefined) {
                localVarQueryParameter["toJGraphLite"] = toJGraphLite;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a project
         * @param {number} accountId Account identifier
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(accountId, botProjectModificationDto, product, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling create.");
            }
            // verify required parameter 'botProjectModificationDto' is not null or undefined
            if (botProjectModificationDto === null ||
                botProjectModificationDto === undefined) {
                throw new RequiredError("botProjectModificationDto", "Required parameter botProjectModificationDto was null or undefined when calling create.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/botproject`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (product !== undefined && product !== null) {
                localVarHeaderParameter["product"] = String(product);
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof botProjectModificationDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(botProjectModificationDto !== undefined
                    ? botProjectModificationDto
                    : {})
                : botProjectModificationDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a bot project from zip
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {any} [file]
         * @param {any} [botProjectFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByZip(accountId, product, file, botProjectFile, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createByZip.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/by-zip`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (product !== undefined && product !== null) {
                localVarHeaderParameter["product"] = String(product);
            }
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            if (botProjectFile !== undefined) {
                localVarFormParams.append("botProjectFile", botProjectFile);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read(accountId, id, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling read.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling read.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all bot projects
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll(accountId, product, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling readAll.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/botproject`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (product !== undefined && product !== null) {
                localVarHeaderParameter["product"] = String(product);
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(accountId, id, botProjectModificationDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling update.");
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling update.");
            }
            // verify required parameter 'botProjectModificationDto' is not null or undefined
            if (botProjectModificationDto === null ||
                botProjectModificationDto === undefined) {
                throw new RequiredError("botProjectModificationDto", "Required parameter botProjectModificationDto was null or undefined when calling update.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/{id}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof botProjectModificationDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(botProjectModificationDto !== undefined
                    ? botProjectModificationDto
                    : {})
                : botProjectModificationDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Delete the bot project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(accountId, id, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration)._delete(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Clone the bot project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} name Name
         * @param {boolean} [toJGraphLite] flag for front.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(accountId, projectShortName, name, toJGraphLite, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).clone(accountId, projectShortName, name, toJGraphLite, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create a project
         * @param {number} accountId Account identifier
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(accountId, botProjectModificationDto, product, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).create(accountId, botProjectModificationDto, product, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create a bot project from zip
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {any} [file]
         * @param {any} [botProjectFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByZip(accountId, product, file, botProjectFile, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).createByZip(accountId, product, file, botProjectFile, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read(accountId, id, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).read(accountId, id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get all bot projects
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll(accountId, product, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).readAll(accountId, product, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(accountId, id, botProjectModificationDto, options) {
            const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).update(accountId, id, botProjectModificationDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Delete the bot project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(accountId, id, options) {
            return ProjectsApiFp(configuration)._delete(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Clone the bot project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {string} name Name
         * @param {boolean} [toJGraphLite] flag for front.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(accountId, projectShortName, name, toJGraphLite, options) {
            return ProjectsApiFp(configuration).clone(accountId, projectShortName, name, toJGraphLite, options)(axios, basePath);
        },
        /**
         *
         * @summary Create a project
         * @param {number} accountId Account identifier
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(accountId, botProjectModificationDto, product, options) {
            return ProjectsApiFp(configuration).create(accountId, botProjectModificationDto, product, options)(axios, basePath);
        },
        /**
         *
         * @summary Create a bot project from zip
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {any} [file]
         * @param {any} [botProjectFile]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createByZip(accountId, product, file, botProjectFile, options) {
            return ProjectsApiFp(configuration).createByZip(accountId, product, file, botProjectFile, options)(axios, basePath);
        },
        /**
         *
         * @summary Get the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        read(accountId, id, options) {
            return ProjectsApiFp(configuration).read(accountId, id, options)(axios, basePath);
        },
        /**
         *
         * @summary Get all bot projects
         * @param {number} accountId Account identifier
         * @param {string} [product] Product type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAll(accountId, product, options) {
            return ProjectsApiFp(configuration).readAll(accountId, product, options)(axios, basePath);
        },
        /**
         *
         * @summary Update the project
         * @param {number} accountId Account identifier
         * @param {number} id Object id
         * @param {BotProjectModificationDto} botProjectModificationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(accountId, id, botProjectModificationDto, options) {
            return ProjectsApiFp(configuration).update(accountId, id, botProjectModificationDto, options)(axios, basePath);
        },
    };
};
/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     *
     * @summary Delete the bot project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    _delete(accountId, id, options) {
        return ProjectsApiFp(this.configuration)._delete(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Clone the bot project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} name Name
     * @param {boolean} [toJGraphLite] flag for front.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    clone(accountId, projectShortName, name, toJGraphLite, options) {
        return ProjectsApiFp(this.configuration).clone(accountId, projectShortName, name, toJGraphLite, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create a project
     * @param {number} accountId Account identifier
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    create(accountId, botProjectModificationDto, product, options) {
        return ProjectsApiFp(this.configuration).create(accountId, botProjectModificationDto, product, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create a bot project from zip
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {any} [file]
     * @param {any} [botProjectFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    createByZip(accountId, product, file, botProjectFile, options) {
        return ProjectsApiFp(this.configuration).createByZip(accountId, product, file, botProjectFile, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    read(accountId, id, options) {
        return ProjectsApiFp(this.configuration).read(accountId, id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get all bot projects
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    readAll(accountId, product, options) {
        return ProjectsApiFp(this.configuration).readAll(accountId, product, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    update(accountId, id, botProjectModificationDto, options) {
        return ProjectsApiFp(this.configuration).update(accountId, id, botProjectModificationDto, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Botadmin healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Botadmin healthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @summary Botadmin healthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
}
/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create tag for account
         * @param {number} accountId Account identifier
         * @param {TagLabelCreate} tagLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagForAccount(accountId, tagLabelCreate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createTagForAccount.");
            }
            // verify required parameter 'tagLabelCreate' is not null or undefined
            if (tagLabelCreate === null || tagLabelCreate === undefined) {
                throw new RequiredError("tagLabelCreate", "Required parameter tagLabelCreate was null or undefined when calling createTagForAccount.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/tags`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tagLabelCreate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tagLabelCreate !== undefined ? tagLabelCreate : {})
                : tagLabelCreate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete tag for account
         * @param {number} accountId Account identifier
         * @param {number} tagId Tag id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagForAccount(accountId, tagId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteTagForAccount.");
            }
            // verify required parameter 'tagId' is not null or undefined
            if (tagId === null || tagId === undefined) {
                throw new RequiredError("tagId", "Required parameter tagId was null or undefined when calling deleteTagForAccount.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/tags/{tagId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Getting all tags for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsForAccount(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAllTagsForAccount.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/tags`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update tags for project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsForProject(accountId, projectShortName, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateTagsForProject.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling updateTagsForProject.");
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError("requestBody", "Required parameter requestBody was null or undefined when calling updateTagsForProject.");
            }
            const localVarPath = `/api/botadmin/accounts/{accountId}/project/{projectShortName}/tags`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectShortName"}}`, encodeURIComponent(String(projectShortName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create tag for account
         * @param {number} accountId Account identifier
         * @param {TagLabelCreate} tagLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagForAccount(accountId, tagLabelCreate, options) {
            const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).createTagForAccount(accountId, tagLabelCreate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete tag for account
         * @param {number} accountId Account identifier
         * @param {number} tagId Tag id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagForAccount(accountId, tagId, options) {
            const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).deleteTagForAccount(accountId, tagId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Getting all tags for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsForAccount(accountId, options) {
            const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).getAllTagsForAccount(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update tags for project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsForProject(accountId, projectShortName, requestBody, options) {
            const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).updateTagsForProject(accountId, projectShortName, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create tag for account
         * @param {number} accountId Account identifier
         * @param {TagLabelCreate} tagLabelCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagForAccount(accountId, tagLabelCreate, options) {
            return TagsApiFp(configuration).createTagForAccount(accountId, tagLabelCreate, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete tag for account
         * @param {number} accountId Account identifier
         * @param {number} tagId Tag id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagForAccount(accountId, tagId, options) {
            return TagsApiFp(configuration).deleteTagForAccount(accountId, tagId, options)(axios, basePath);
        },
        /**
         *
         * @summary Getting all tags for account
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsForAccount(accountId, options) {
            return TagsApiFp(configuration).getAllTagsForAccount(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update tags for project
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {Array<number>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsForProject(accountId, projectShortName, requestBody, options) {
            return TagsApiFp(configuration).updateTagsForProject(accountId, projectShortName, requestBody, options)(axios, basePath);
        },
    };
};
/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     *
     * @summary Create tag for account
     * @param {number} accountId Account identifier
     * @param {TagLabelCreate} tagLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    createTagForAccount(accountId, tagLabelCreate, options) {
        return TagsApiFp(this.configuration).createTagForAccount(accountId, tagLabelCreate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete tag for account
     * @param {number} accountId Account identifier
     * @param {number} tagId Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    deleteTagForAccount(accountId, tagId, options) {
        return TagsApiFp(this.configuration).deleteTagForAccount(accountId, tagId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Getting all tags for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    getAllTagsForAccount(accountId, options) {
        return TagsApiFp(this.configuration).getAllTagsForAccount(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update tags for project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    updateTagsForProject(accountId, projectShortName, requestBody, options) {
        return TagsApiFp(this.configuration).updateTagsForProject(accountId, projectShortName, requestBody, options)(this.axios, this.basePath);
    }
}
