import { axios } from '../../pipes/functions';
import * as knowledgeActions from '../../constants/knowledge.actions';
import queryString from 'query-string';

export const uploadQuestionsList = (projectShortName, baseName, file) => {
  let formData = new FormData();
  formData.append('file', file);

  return {
    type: knowledgeActions.UPLOAD_QUESTIONS,
    payload: axios.post(`/restapi/kb/${projectShortName}/${baseName}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  };
};

export const restoreLastState = () => {
  return {
    type: knowledgeActions.RESTORE_LAST_STATE,
  };
};

export const toggleDisableAll = disabled => {
  return {
    type: knowledgeActions.TOGGLE_DISABLE_ALL,
    disabled,
  };
};

export const toggleSpeechSynthesis = enabled => {
  return {
    type: knowledgeActions.TOGGLE_SPEECH_SYNTHESIS,
    enabled,
  };
};

export const saveBase = (projectShortName, baseName, diff, isDisabled = false, ttsEnabled = false) => {
  return {
    type: knowledgeActions.SAVE_BASE,
    payload: axios.post(
      `/restapi/kb/${projectShortName}/${baseName}/save`,
      {
        content: diff,
        isDisabled,
        ttsEnabled,
      },
      {
        baseName: baseName,
      }
    ),
  };
};

export const fetchBaseList = projectShortName => {
  return {
    type: knowledgeActions.FETCH_BASE,
    payload: axios.get(`/restapi/kb/${projectShortName}`),
  };
};

export const dropBaseList = () => {
  return {
    type: knowledgeActions.DROP_BASE_LIST,
  };
};

export const setCurrentBase = baseData => {
  return {
    type: knowledgeActions.SET_CURRENT_BASE,
    baseData,
  };
};

export const createBase = (projectShortName, knowledgeBaseName, name, withExamples = false) => {
  const baseData = {
    name: name,
    withExamples: withExamples,
  };

  return {
    type: knowledgeActions.CREATE_BASE,
    payload: axios.put(`/restapi/kb/${projectShortName}/${knowledgeBaseName}?${queryString.stringify(baseData)}`),
  };
};

export const paginate = (projectShortName, baseName, page = 0, drop = false) => {
  return {
    type: knowledgeActions.PAGINATE,
    payload: axios.get(`/restapi/kb/${projectShortName}/${baseName}`, {
      params: {
        page: page,
      },
      baseName: baseName,
      drop: drop,
    }),
  };
};

export const dropBaseData = () => {
  return {
    type: knowledgeActions.DROP_BASE,
  };
};

export const dropBaseQuestions = () => {
  return {
    type: knowledgeActions.DROP_BASE_QUESTIONS,
  };
};

export const fetchAllQuestions = (projectShortName, baseName) => {
  return {
    type: knowledgeActions.FETCH_ALL_QUESTIONS,
    payload: axios.get(`/restapi/kb/${projectShortName}/${baseName}?page=0&size=2147483647`),
  };
};

export const toggleQuestionEdit = questionId => {
  return {
    type: knowledgeActions.TOGGLE_QUESTION_EDIT,
    questionId,
  };
};

export const dropEditQuestion = () => {
  return {
    type: knowledgeActions.DROP_EDIT_QUESTION,
  };
};

export const addQuestion = question => {
  return {
    type: knowledgeActions.ADD_QUESTION,
    question,
  };
};

export const removeQuestion = questionId => {
  return {
    type: knowledgeActions.REMOVE_QUESTION,
    questionId,
  };
};

export const editQuestion = question => {
  return {
    type: knowledgeActions.EDIT_QUESTION,
    question,
  };
};

export const search = searchedStr => {
  return {
    type: knowledgeActions.SEARCH,
    searchedStr,
  };
};

export const cancelSearch = () => {
  return {
    type: knowledgeActions.CANCEL_SEARCH,
  };
};

export const deployBase = (projectShortName, knowledgeBaseShortName) => {
  return {
    type: knowledgeActions.KNOWLEDGE_DEPLOY,
    payload: axios.post(`/restapi/kb/${projectShortName}/${knowledgeBaseShortName}/generate`),
  };
};
