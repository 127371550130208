import React, { FC, useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'storeHooks';
import { Template } from 'reducers/templates.reducer/teplatesReducerTypes';
import { getLanguagesBasedOnFeatures } from 'constants/botprojects.actions';
import { t } from '../../../../localization';
import LanguageSelectNew from '../../../../views/PersonalSettings/LanguageSelectNew';
import { GA, lang2toFullLowerCase } from '../../../../pipes/pureFunctions';

import { NewTemplateCard } from './card';
import classes from './graphWay.module.scss';
import history from '../../../../appHistory';
import { BASE_WIZARD_PATH } from '../../shared/consts';
import { useLocation } from 'react-router';

export const GraphWay: FC<{ aimy?: boolean }> = ({ aimy }) => {
  const { currentUser, templatesList } = useAppSelector(store => ({
    currentUser: store.CurrentUserReducer.currentUser,
    templatesList: store.TemplatesReducer.templatesList as Template[],
  }));

  const location = useLocation<{
    isCallBot?: boolean;
    callType?: string;
  }>();

  const isCallBot = useMemo(() => {
    return location?.state?.isCallBot || false;
  }, [location?.state]);

  const { languages, initialLanguageOption } = useMemo(() => {
    const initialLanguage =
      currentUser?.language?.toLowerCase() === 'en' ? 'eng' : currentUser?.language?.toLowerCase();
    const languages = [...getLanguagesBasedOnFeatures(isCallBot)];
    const initialLanguageOption = languages.find(language => language.value === initialLanguage);

    return {
      languages,
      initialLanguageOption,
    };
  }, [currentUser?.language, isCallBot]);

  const [language, setLanguage] = useState(initialLanguageOption?.value || 'eng');
  const setProjectLang = useCallback(
    value => {
      if (value?.[0] !== language) {
        GA(undefined, isCallBot ? 'Calls_Language_Change' : 'Chat_Language_Change', undefined, value?.[0]);
        setLanguage(value?.[0]);
      }
    },
    [isCallBot, language]
  );

  const templatesListFilteredByBotType = useMemo(() => {
    return templatesList.filter(template => template.type === (isCallBot ? 'CALLS_BOT' : 'CHAT_BOT'));
  }, [isCallBot, templatesList]);

  const filteredTemplatesList = useMemo(() => {
    return (
      templatesListFilteredByBotType?.filter(
        template =>
          template?.parsedJson?.forCreateForm &&
          (aimy ? !template?.parsedJson?.jgraph : template?.parsedJson?.jgraph) &&
          lang2toFullLowerCase(template?.language) === language
      ) || []
    );
  }, [aimy, language, templatesListFilteredByBotType]);

  const goToEndPage = useCallback(
    (template: Template) => {
      GA(undefined, isCallBot ? 'Calls_Template_Select' : 'Chat_Template_Select', undefined, template.name);
      history.push({
        pathname: `${BASE_WIZARD_PATH}/graph-choose/${template.language}/${template.name}`,
        state: location.state,
      });
    },
    [isCallBot, location.state]
  );

  return (
    <div className={classes.graphWayContainer}>
      <h1 className='mt-3 mb-6 text-center'>{t('CreateProject:GraphWay:Title')}</h1>

      <h3 className={classes['mb-3_5']}>{t('CreateProject:GraphWay:Choose_language')}</h3>

      <LanguageSelectNew
        dataTestId='TemplatesWizard.Graph.LanguageSelect'
        languages={languages}
        language={language}
        setProjectLang={setProjectLang}
        disabled={false}
        error=''
        helperText={
          <span dangerouslySetInnerHTML={{ __html: t('CreateProject:GraphWay:LanguageSelect_helperText') }} />
        }
        isCallBot={isCallBot}
      />
      <h3 className='mt-6 mb-6'>{t('CreateProject:GraphWay:ChooseYour_template')}</h3>
      <div className={classes.graphWayTemplatesContainer} data-test-id='GraphWay:Choose_template'>
        {filteredTemplatesList.map(template => (
          <NewTemplateCard
            iconName={template.parsedJson?.icon || 'falCube'}
            title={template.title}
            summary={template.summary}
            key={template.name}
            onClick={() => goToEndPage(template)}
            dataTestId={`TemplateCard:${template.name}`}
          />
        ))}
      </div>

      {/*<pre>{JSON.stringify(filteredTemplatesList, null, 4)}</pre>*/}
    </div>
  );
};
