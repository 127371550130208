import {
  FETCH_CHANNELS,
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  REMOVE_CHANNEL,
  DROP_CHANNEL,
  SET_CHANNEL_TO_EDIT,
  SET_CHANNEL_TO_CREATION,
  EDIT_CHANNEL,
  SAVE_EDITABLE_CHANNEL,
  FETCH_THEMES,
  SAVE_WIDGET_LOGO_URL_TO_SERVER,
  ALEXA_SET_REFRESH_TOKEN,
  GET_CONFIRM_URL,
  PUBLISH_CHANNEL,
  DELETE_IDS_DESTINATION,
  ADD_DESTINATION_IDS,
  REMOVE_GROUP,
  SELECT_DESTINATION_IDS,
  ADD_DESTINATION,
  FETCH_OPERATOR_INCOMING_WEBIM2,
} from '../../constants/channels.actions';
import queryString from 'query-string';

import { axios } from '../../pipes/functions';

export const getChannelsList = projectShortName => {
  return {
    type: FETCH_CHANNELS,
    payload: axios.get(`/restapi/botconfig/project/${projectShortName}`),
  };
};

export const createChannel = (currentProjectShortName, channelData) => {
  return {
    type: CREATE_CHANNEL,
    payload: axios.post(`/restapi/botconfig/${currentProjectShortName}`, channelData),
  };
};

export const removeChannel = channelId => {
  return {
    type: REMOVE_CHANNEL,
    payload: axios.delete(`/restapi/botconfig/${channelId}`),
  };
};

export const publishChannel = channelId => {
  return {
    type: PUBLISH_CHANNEL,
    payload: axios.post(`/restapi/botconfig/${channelId}/publish`, { channelId: channelId }),
  };
};

export const saveEditableChannel = (id, channelData) => {
  return {
    type: SAVE_EDITABLE_CHANNEL,
    payload: axios({
      method: 'put',
      url: `/restapi/botconfig/${id}`,
      data: channelData,
    }),
  };
};

export const updateChannel = channelData => {
  return {
    type: UPDATE_CHANNEL,
    channelData: channelData,
  };
};

export const setChannelToCreation = channelType => {
  return {
    type: SET_CHANNEL_TO_CREATION,
    channelType: channelType,
  };
};

export const setChannelToEdit = channel => {
  return {
    type: SET_CHANNEL_TO_EDIT,
    channel,
  };
};

export const dropEditableChannel = () => {
  return {
    type: DROP_CHANNEL,
  };
};

export const editChannel = channelData => {
  return {
    type: EDIT_CHANNEL,
    payload: axios.put(`/restapi/channel`, channelData),
  };
};

export const loadChatwidgetThemes = () => {
  return {
    type: FETCH_THEMES,
    payload: axios.get('/restapi/botconfig/chatwidget/themes'),
  };
};

export const fetchWebim2Operators = ({ domain, email, password }) => {
  return {
    type: FETCH_OPERATOR_INCOMING_WEBIM2,
    payload: axios.get(
      `/restapi/channels/incoming_webim2/operators?domain=${domain}&adminEmail=${email}&adminPassword=${password}`
    ),
  };
};

export const saveUrlImageToServer = file => {
  let fd = new FormData();
  fd.append('file', file);
  return {
    type: SAVE_WIDGET_LOGO_URL_TO_SERVER,
    payload: axios.post('/restapi/file/upload', fd),
  };
};

export const alexaSetRefreshToken = (obj, state) => {
  let str = queryString.stringify(obj);
  return {
    type: ALEXA_SET_REFRESH_TOKEN,
    payload: axios.post(`/restapi/botconfig/alexa/processAlexaLoginWithAmazonCode?${str}`, {
      ...state,
    }),
  };
};

export const getAlexaConfirmUrl = (state, redirect_url) => {
  return {
    type: GET_CONFIRM_URL,
    payload: axios.get(`/restapi/botconfig/alexa/loginWithAmazonUrl`, {
      params: {
        state,
        redirect_url,
      },
    }),
  };
};

export const addDestination = () => {
  return {
    type: ADD_DESTINATION,
  };
};
export const removeGroup = indexInArray => {
  return {
    type: REMOVE_GROUP,
    indexInArray,
  };
};

export const deleteDestinationIdsItem = (f_key, s_key) => {
  return {
    type: DELETE_IDS_DESTINATION,
    f_key,
    s_key,
  };
};

export const selectIDS = (f_key, value = {}) => {
  return {
    type: SELECT_DESTINATION_IDS,
    f_key,
    value,
  };
};

export const addDestinationIdsItem = f_key => {
  return {
    type: ADD_DESTINATION_IDS,
    f_key,
  };
};
