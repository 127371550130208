// tslint:disable
// @ts-nocheck
/**
 * Aimychat service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 * How clients are sorted in the \"Queue\" tab
 * @export
 * @enum {string}
 */
export var ClientQueueSorting;
(function (ClientQueueSorting) {
    ClientQueueSorting["ASC"] = "ASC";
    ClientQueueSorting["DESC"] = "DESC";
})(ClientQueueSorting || (ClientQueueSorting = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var OperatorRole;
(function (OperatorRole) {
    OperatorRole["AGENT"] = "AGENT";
    OperatorRole["ADMIN"] = "ADMIN";
})(OperatorRole || (OperatorRole = {}));
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError("loggingEnabledE2ECheck", "Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.");
            }
            const localVarPath = `/api/aimychat-be/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter["Z-requestId"] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter["loggingEnabledE2ECheck"] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    }
}
/**
 * OperatorApi - axios parameter creator
 * @export
 */
export const OperatorApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace(accountId, addOperatorDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addOperatorToOperatorPlace.");
            }
            // verify required parameter 'addOperatorDto' is not null or undefined
            if (addOperatorDto === null || addOperatorDto === undefined) {
                throw new RequiredError("addOperatorDto", "Required parameter addOperatorDto was null or undefined when calling addOperatorToOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/add-operator`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof addOperatorDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(addOperatorDto !== undefined ? addOperatorDto : {})
                : addOperatorDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator(accountId, operatorId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperator.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling getOperator.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId(accountId, ccUserId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorByCcUserId.");
            }
            // verify required parameter 'ccUserId' is not null or undefined
            if (ccUserId === null || ccUserId === undefined) {
                throw new RequiredError("ccUserId", "Required parameter ccUserId was null or undefined when calling getOperatorByCcUserId.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/by-cc-user-id`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (ccUserId !== undefined) {
                localVarQueryParameter["ccUserId"] = ccUserId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperators.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace(accountId, operatorId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling removeOperatorFromOperatorPlace.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling removeOperatorFromOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator(accountId, operatorId, updateOperatorDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperator.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling updateOperator.");
            }
            // verify required parameter 'updateOperatorDto' is not null or undefined
            if (updateOperatorDto === null || updateOperatorDto === undefined) {
                throw new RequiredError("updateOperatorDto", "Required parameter updateOperatorDto was null or undefined when calling updateOperator.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorDto !== undefined ? updateOperatorDto : {})
                : updateOperatorDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator status
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorStatus(accountId, operatorId, updateOperatorStatusDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorStatus.");
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new RequiredError("operatorId", "Required parameter operatorId was null or undefined when calling updateOperatorStatus.");
            }
            // verify required parameter 'updateOperatorStatusDto' is not null or undefined
            if (updateOperatorStatusDto === null ||
                updateOperatorStatusDto === undefined) {
                throw new RequiredError("updateOperatorStatusDto", "Required parameter updateOperatorStatusDto was null or undefined when calling updateOperatorStatus.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operators/{operatorId}/status`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PATCH" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorStatusDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorStatusDto !== undefined ? updateOperatorStatusDto : {})
                : updateOperatorStatusDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorApi - functional programming interface
 * @export
 */
export const OperatorApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace(accountId, addOperatorDto, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator(accountId, operatorId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperator(accountId, operatorId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId(accountId, ccUserId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperatorByCcUserId(accountId, ccUserId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators(accountId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).getOperators(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace(accountId, operatorId, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator(accountId, operatorId, updateOperatorDto, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator status
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorStatus(accountId, operatorId, updateOperatorStatusDto, options) {
            const localVarAxiosArgs = OperatorApiAxiosParamCreator(configuration).updateOperatorStatus(accountId, operatorId, updateOperatorStatusDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorApi - factory interface
 * @export
 */
export const OperatorApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {AddOperatorDto} addOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToOperatorPlace(accountId, addOperatorDto, options) {
            return OperatorApiFp(configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(axios, basePath);
        },
        /**
         * Get operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperator(accountId, operatorId, options) {
            return OperatorApiFp(configuration).getOperator(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Add operator to operator place
         * @param {number} accountId Account identifier
         * @param {number} ccUserId Conversational cloud user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorByCcUserId(accountId, ccUserId, options) {
            return OperatorApiFp(configuration).getOperatorByCcUserId(accountId, ccUserId, options)(axios, basePath);
        },
        /**
         * Get operators
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperators(accountId, options) {
            return OperatorApiFp(configuration).getOperators(accountId, options)(axios, basePath);
        },
        /**
         * Remove operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromOperatorPlace(accountId, operatorId, options) {
            return OperatorApiFp(configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(axios, basePath);
        },
        /**
         * Update operator
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorDto} updateOperatorDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperator(accountId, operatorId, updateOperatorDto, options) {
            return OperatorApiFp(configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(axios, basePath);
        },
        /**
         * Update operator status
         * @param {number} accountId Account identifier
         * @param {number} operatorId Operator identifier
         * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorStatus(accountId, operatorId, updateOperatorStatusDto, options) {
            return OperatorApiFp(configuration).updateOperatorStatus(accountId, operatorId, updateOperatorStatusDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorApi - object-oriented interface
 * @export
 * @class OperatorApi
 * @extends {BaseAPI}
 */
export class OperatorApi extends BaseAPI {
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {AddOperatorDto} addOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    addOperatorToOperatorPlace(accountId, addOperatorDto, options) {
        return OperatorApiFp(this.configuration).addOperatorToOperatorPlace(accountId, addOperatorDto, options)(this.axios, this.basePath);
    }
    /**
     * Get operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    getOperator(accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).getOperator(accountId, operatorId, options)(this.axios, this.basePath);
    }
    /**
     * Add operator to operator place
     * @param {number} accountId Account identifier
     * @param {number} ccUserId Conversational cloud user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    getOperatorByCcUserId(accountId, ccUserId, options) {
        return OperatorApiFp(this.configuration).getOperatorByCcUserId(accountId, ccUserId, options)(this.axios, this.basePath);
    }
    /**
     * Get operators
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    getOperators(accountId, options) {
        return OperatorApiFp(this.configuration).getOperators(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Remove operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    removeOperatorFromOperatorPlace(accountId, operatorId, options) {
        return OperatorApiFp(this.configuration).removeOperatorFromOperatorPlace(accountId, operatorId, options)(this.axios, this.basePath);
    }
    /**
     * Update operator
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorDto} updateOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    updateOperator(accountId, operatorId, updateOperatorDto, options) {
        return OperatorApiFp(this.configuration).updateOperator(accountId, operatorId, updateOperatorDto, options)(this.axios, this.basePath);
    }
    /**
     * Update operator status
     * @param {number} accountId Account identifier
     * @param {number} operatorId Operator identifier
     * @param {UpdateOperatorStatusDto} updateOperatorStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorApi
     */
    updateOperatorStatus(accountId, operatorId, updateOperatorStatusDto, options) {
        return OperatorApiFp(this.configuration).updateOperatorStatus(accountId, operatorId, updateOperatorStatusDto, options)(this.axios, this.basePath);
    }
}
/**
 * OperatorGroupApi - axios parameter creator
 * @export
 */
export const OperatorGroupApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling addOperatorToGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling addOperatorToGroup.");
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError("operatorIdDto", "Required parameter operatorIdDto was null or undefined when calling addOperatorToGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/add-operator`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof operatorIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
                : operatorIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup(accountId, createOperatorGroupDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createOperatorGroup.");
            }
            // verify required parameter 'createOperatorGroupDto' is not null or undefined
            if (createOperatorGroupDto === null ||
                createOperatorGroupDto === undefined) {
                throw new RequiredError("createOperatorGroupDto", "Required parameter createOperatorGroupDto was null or undefined when calling createOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createOperatorGroupDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOperatorGroupDto !== undefined ? createOperatorGroupDto : {})
                : createOperatorGroupDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup(accountId, operatorGroupId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling getOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorGroups.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken(token, options = {}) {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError("token", "Required parameter token was null or undefined when calling getOperatorGroupsByToken.");
            }
            const localVarPath = `/api/aimychat-be/tokens/{token}/operator-groups`.replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling removeOperatorFromGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling removeOperatorFromGroup.");
            }
            // verify required parameter 'operatorIdDto' is not null or undefined
            if (operatorIdDto === null || operatorIdDto === undefined) {
                throw new RequiredError("operatorIdDto", "Required parameter operatorIdDto was null or undefined when calling removeOperatorFromGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}/remove-operator`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof operatorIdDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(operatorIdDto !== undefined ? operatorIdDto : {})
                : operatorIdDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup(accountId, operatorGroupId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling removeOperatorGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling removeOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorGroup.");
            }
            // verify required parameter 'operatorGroupId' is not null or undefined
            if (operatorGroupId === null || operatorGroupId === undefined) {
                throw new RequiredError("operatorGroupId", "Required parameter operatorGroupId was null or undefined when calling updateOperatorGroup.");
            }
            // verify required parameter 'updateOperatorGroupDto' is not null or undefined
            if (updateOperatorGroupDto === null ||
                updateOperatorGroupDto === undefined) {
                throw new RequiredError("updateOperatorGroupDto", "Required parameter updateOperatorGroupDto was null or undefined when calling updateOperatorGroup.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-groups/{operatorGroupId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"operatorGroupId"}}`, encodeURIComponent(String(operatorGroupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorGroupDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorGroupDto !== undefined ? updateOperatorGroupDto : {})
                : updateOperatorGroupDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorGroupApi - functional programming interface
 * @export
 */
export const OperatorGroupApiFp = function (configuration) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup(accountId, createOperatorGroupDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup(accountId, operatorGroupId, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroup(accountId, operatorGroupId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups(accountId, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroups(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken(token, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).getOperatorGroupsByToken(token, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup(accountId, operatorGroupId, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).removeOperatorGroup(accountId, operatorGroupId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options) {
            const localVarAxiosArgs = OperatorGroupApiAxiosParamCreator(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorGroupApi - factory interface
 * @export
 */
export const OperatorGroupApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add operator to operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Create operator group
         * @param {number} accountId Account identifier
         * @param {CreateOperatorGroupDto} createOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOperatorGroup(accountId, createOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(axios, basePath);
        },
        /**
         * Get operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroup(accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroups(accountId, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroups(accountId, options)(axios, basePath);
        },
        /**
         * Get operator groups
         * @param {string} token Operator place token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorGroupsByToken(token, options) {
            return OperatorGroupApiFp(configuration).getOperatorGroupsByToken(token, options)(axios, basePath);
        },
        /**
         * Remove operator from operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {OperatorIdDto} operatorIdDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options) {
            return OperatorGroupApiFp(configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(axios, basePath);
        },
        /**
         * Remove operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOperatorGroup(accountId, operatorGroupId, options) {
            return OperatorGroupApiFp(configuration).removeOperatorGroup(accountId, operatorGroupId, options)(axios, basePath);
        },
        /**
         * Update operator group
         * @param {number} accountId Account identifier
         * @param {number} operatorGroupId Operator group identifier
         * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options) {
            return OperatorGroupApiFp(configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(axios, basePath);
        },
    };
};
/**
 * OperatorGroupApi - object-oriented interface
 * @export
 * @class OperatorGroupApi
 * @extends {BaseAPI}
 */
export class OperatorGroupApi extends BaseAPI {
    /**
     * Add operator to operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).addOperatorToGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    }
    /**
     * Create operator group
     * @param {number} accountId Account identifier
     * @param {CreateOperatorGroupDto} createOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    createOperatorGroup(accountId, createOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).createOperatorGroup(accountId, createOperatorGroupDto, options)(this.axios, this.basePath);
    }
    /**
     * Get operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    getOperatorGroup(accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    }
    /**
     * Get operator groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    getOperatorGroups(accountId, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroups(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Get operator groups
     * @param {string} token Operator place token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    getOperatorGroupsByToken(token, options) {
        return OperatorGroupApiFp(this.configuration).getOperatorGroupsByToken(token, options)(this.axios, this.basePath);
    }
    /**
     * Remove operator from operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {OperatorIdDto} operatorIdDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorFromGroup(accountId, operatorGroupId, operatorIdDto, options)(this.axios, this.basePath);
    }
    /**
     * Remove operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    removeOperatorGroup(accountId, operatorGroupId, options) {
        return OperatorGroupApiFp(this.configuration).removeOperatorGroup(accountId, operatorGroupId, options)(this.axios, this.basePath);
    }
    /**
     * Update operator group
     * @param {number} accountId Account identifier
     * @param {number} operatorGroupId Operator group identifier
     * @param {UpdateOperatorGroupDto} updateOperatorGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorGroupApi
     */
    updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options) {
        return OperatorGroupApiFp(this.configuration).updateOperatorGroup(accountId, operatorGroupId, updateOperatorGroupDto, options)(this.axios, this.basePath);
    }
}
/**
 * OperatorPlaceApi - axios parameter creator
 * @export
 */
export const OperatorPlaceApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace(accountId, updateOperatorPlaceDto, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorPlace.");
            }
            // verify required parameter 'updateOperatorPlaceDto' is not null or undefined
            if (updateOperatorPlaceDto === null ||
                updateOperatorPlaceDto === undefined) {
                throw new RequiredError("updateOperatorPlaceDto", "Required parameter updateOperatorPlaceDto was null or undefined when calling updateOperatorPlace.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateOperatorPlaceDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateOperatorPlaceDto !== undefined ? updateOperatorPlaceDto : {})
                : updateOperatorPlaceDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateOperatorPlaceToken.");
            }
            const localVarPath = `/api/aimychat-be/accounts/{accountId}/operator-place/token`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PATCH" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OperatorPlaceApi - functional programming interface
 * @export
 */
export const OperatorPlaceApiFp = function (configuration) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace(accountId, options) {
            const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).getOperatorPlace(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace(accountId, updateOperatorPlaceDto, options) {
            const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken(accountId, options) {
            const localVarAxiosArgs = OperatorPlaceApiAxiosParamCreator(configuration).updateOperatorPlaceToken(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OperatorPlaceApi - factory interface
 * @export
 */
export const OperatorPlaceApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get operator place configuration
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOperatorPlace(accountId, options) {
            return OperatorPlaceApiFp(configuration).getOperatorPlace(accountId, options)(axios, basePath);
        },
        /**
         * Update operator place configuration
         * @param {number} accountId Account identifier
         * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlace(accountId, updateOperatorPlaceDto, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(axios, basePath);
        },
        /**
         * Update operator place token
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOperatorPlaceToken(accountId, options) {
            return OperatorPlaceApiFp(configuration).updateOperatorPlaceToken(accountId, options)(axios, basePath);
        },
    };
};
/**
 * OperatorPlaceApi - object-oriented interface
 * @export
 * @class OperatorPlaceApi
 * @extends {BaseAPI}
 */
export class OperatorPlaceApi extends BaseAPI {
    /**
     * Get operator place configuration
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    getOperatorPlace(accountId, options) {
        return OperatorPlaceApiFp(this.configuration).getOperatorPlace(accountId, options)(this.axios, this.basePath);
    }
    /**
     * Update operator place configuration
     * @param {number} accountId Account identifier
     * @param {UpdateOperatorPlaceDto} updateOperatorPlaceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    updateOperatorPlace(accountId, updateOperatorPlaceDto, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlace(accountId, updateOperatorPlaceDto, options)(this.axios, this.basePath);
    }
    /**
     * Update operator place token
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperatorPlaceApi
     */
    updateOperatorPlaceToken(accountId, options) {
        return OperatorPlaceApiFp(this.configuration).updateOperatorPlaceToken(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options = {}) {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Service health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @summary Service version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @summary Service health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Service version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version(options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }
}
