import React, { FC } from 'react';

export const MenuButtons: FC<{ buttons: string[] }> = ({ buttons }) => {
  if (buttons.length === 0) return null;
  return (
    <div className='justwidget--buttons justwidget--buttons-top'>
      {buttons.map(button => (
        <button type='button' className='justwidget--button' key={button}>
          <span>{button}</span>
        </button>
      ))}
    </div>
  );
};
