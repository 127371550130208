import React, { FC, useCallback, useMemo } from 'react';

import { MessageContainer } from './MessageContainer';
import { Button } from '@just-ai/just-ui';
import { t } from 'localization';
import { MessageType, useAutomessageSequenceContext } from './types';
import { SortableContainerWrapper } from './SortableContainer';
import { arrayMove } from 'react-sortable-hoc';

export type TAutomessageSequenceContainer = { messages: MessageType[] };

export const AutomessageSequence: FC<TAutomessageSequenceContainer> = ({ messages }) => {
  const { addMessage, resortMessages } = useAutomessageSequenceContext();
  const messagesWithoutFirst = useMemo(() => {
    const newMessages = [...messages];
    newMessages.shift();
    return newMessages;
  }, [messages]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      const newBlocks = arrayMove(messagesWithoutFirst || [], oldIndex, newIndex);
      resortMessages(newBlocks);
    },
    [messagesWithoutFirst, resortMessages]
  );

  return (
    <>
      <MessageContainer message={messages[0]} messageIndex={1} showTimeSelector={false} />
      {messagesWithoutFirst.length > 0 && (
        <SortableContainerWrapper
          sortableMessages={messagesWithoutFirst}
          useDragHandle
          axis='y'
          lockAxis='y'
          lockOffset={['0%', '100%']}
          onSortEnd={onSortEnd}
          lockToContainerEdges
          helperClass='draggingHelper'
        />
      )}
      <Button className='d-flex align-self-start' flat iconLeft='farPlus' color='primary' onClick={addMessage}>
        {t('Add')}
      </Button>
    </>
  );
};
