import { AccountTokenApi, OAuthAuthenticationApi, GoogleSheetsApi, } from "../../generated/Integrations";
export class IntegrationsService {
    constructor(accountId, projectShortName) {
        this.changeProjectShortName = (projectShortName) => {
            this.projectShortName = projectShortName;
        };
        this.accountId = accountId;
        this.projectShortName = projectShortName;
        this.accountTokenApi = new AccountTokenApi({}, IntegrationsService.BASE_PATH);
        this.oAuthAuthenticationApi = new OAuthAuthenticationApi({}, IntegrationsService.BASE_PATH);
        this.googleSheetsApi = new GoogleSheetsApi({}, IntegrationsService.BASE_PATH);
    }
    getAllIntegrations() {
        return this.accountTokenApi.getProjectIntegrations(this.accountId, this.projectShortName);
    }
    getIntegrationTokenData() {
        return this.accountTokenApi.getIntegrationTokenData(this.accountId, this.projectShortName);
    }
    getGoogleAuthUrl(projectShortName, redirectUrl) {
        return this.oAuthAuthenticationApi.requestGoogleOauthCode(this.accountId, projectShortName || this.projectShortName, redirectUrl);
    }
    saveIntegration(integration, tokenData) {
        return this.accountTokenApi.saveNewIntegrationForAccount(this.accountId, integration, tokenData, this.projectShortName);
    }
    updateIntegrationTokenData(integration, tokenData) {
        return this.accountTokenApi.updateIntegrationTokenData(this.accountId, integration, tokenData, this.projectShortName);
    }
    deleteIntegration(integrationId) {
        return this.accountTokenApi.deleteIntegrationTokenData(this.accountId, integrationId);
    }
    getListOfSpreadsheets(integrationId) {
        return this.googleSheetsApi.getListOfSpreadsheets(this.accountId, integrationId);
    }
    getListOfSheets(spreadSheetsId, integrationId) {
        return this.googleSheetsApi.getListOfSheets(this.accountId, spreadSheetsId, integrationId);
    }
    createNewSpreadsheet(integrationId, spreadSheetName) {
        return this.googleSheetsApi.createNewSpreadsheet(this.accountId, integrationId, spreadSheetName);
    }
}
IntegrationsService.BASE_PATH = "";
