import {
  SOCKETS_CONNECTING,
  SOCKETS_DISCONNECTING,
  SOCKETS_MESSAGE_SENDING,
  SOCKETS_MESSAGE_RECEIVING,
} from './socketActionsConstants';

export function socketsConnecting() {
  return { type: SOCKETS_CONNECTING };
}
export function socketsDisconnecting() {
  return { type: SOCKETS_DISCONNECTING };
}
export function socketsMessageSending(sendMessage) {
  return { type: SOCKETS_MESSAGE_SENDING, payload: sendMessage };
}
export function socketsMessageReceiving(receiveMessage) {
  return {
    type: SOCKETS_MESSAGE_RECEIVING,
    payload: receiveMessage,
  };
}
