export const templatesWizardLocalization = {
  'WizardStartPage:TitleH1': {
    eng: 'Create a chatbot',
    ru: 'Способ создания чат-бота',
    pt: 'Criar um chatbot',
  },
  'StartCard:WizardTitle': {
    eng: 'In skill builder',
    ru: 'В редакторе навыков',
    pt: 'No construtor de habilidades',
  },
  'StartCard:GraphTitle': {
    eng: 'In visual editor',
    ru: 'В конструкторе',
    pt: 'No editor visual',
  },
  'StartCard:Wizard:label': {
    eng: 'For beginners',
    ru: 'Новичкам',
    pt: 'Para iniciantes',
  },
  'StartCard:Wizard:ChatGPT:label': {
    eng: '<i class="just-emoji-brain"></i> ChatGPT',
    ru: '<i class="just-emoji-brain"></i> ChatGPT',
    pt: '<i class="just-emoji-brain"></i> ChatGPT',
  },
  'StartCard:Wizard:li-item-1': {
    eng: 'Requires no previous experience or extra time',
    ru: 'Не требует много времени и подготовки',
    pt: 'Não requer experiência anterior ou tempo extra',
  },
  'StartCard:Wizard:li-item-2': {
    eng: 'Allows you to select and customize bot features',
    ru: 'Позволяет выбрать задачи бота и настроить их под себя',
    pt: 'Permite que você selecione e personalize os recursos do bot',
  },
  'StartCard:Wizard:li-item-3': {
    eng: 'Helps to solve the common business tasks',
    ru: 'Помогает решить основные задачи бизнеса',
    pt: 'Ajuda a resolver as tarefas comuns de negócios',
  },
  'StartCard:Graph:label': {
    eng: 'For advanced users',
    ru: 'Опытным',
    pt: 'Para usuários avançados',
  },
  'StartCard:Graph:li-item-1': {
    eng: 'Suitable for users with experience in bot development',
    ru: 'Подходит продвинутым пользователям',
    pt: 'Adequado para usuários com experiência em desenvolvimento de bots',
  },
  'StartCard:Graph:li-item-2': {
    eng: 'Allows you to create and configure a bot from scratch',
    ru: 'Позволяет создать и настроить бота с нуля',
    pt: 'Permite criar e configurar um bot do zero',
  },
  'StartCard:Graph:li-item-3': {
    eng: 'Helps to solve business tasks of any complexity',
    ru: 'Позволяет решить задачи любой сложности',
    pt: 'Ajuda a resolver tarefas de negócios de qualquer complexidade',
  },
  'CreateProject:GraphWay:Title': {
    eng: 'Main settings',
    ru: 'Основные настройки',
    pt: 'Configurações principais',
  },
  'CreateProject:GraphWay:LanguageSelect_helperText': {
    eng: 'After you create the bot, the language <b>cannot</b> be changed',
    ru: 'После создания бота поменять язык <b>нельзя</b>',
    pt: 'Depois de criar o bot, o idioma <b>não</b> pode ser alterado',
  },
  'CreateProject:GraphWay:ChooseYour_template': {
    eng: 'Bot template',
    ru: 'Шаблон бота',
    pt: 'Modelo de bot',
  },
  'CreateProject:GraphWay:Choose_language': {
    eng: 'Communication language',
    ru: 'Язык общения',
    pt: 'Linguagem de comunicação',
  },
  'CreateProject:GraphWayEnd:Title': {
    eng: 'Your bot',
    ru: 'Ваш бот',
    pt: 'Seu bot',
  },
  'CreateProject:GraphWayEnd:ProjectName:Label': {
    eng: 'Project name',
    ru: 'Название проекта',
    pt: 'Nome do projeto',
  },
  'CreateProject:GraphWayEnd:ProjectName:InputPlaceholder': {
    eng: 'My flower shop',
    ru: 'Цветы 24',
    pt: 'Minha floricultura',
  },
  'CreateProject:GraphWayEnd:ProjectDetails:CommunicationTypeLabel': {
    eng: 'Communication method',
    ru: 'Способ общения',
    pt: 'Método de comunicação',
  },
  'CreateProject:GraphWayEnd:ProjectDetails:Call': {
    eng: 'Calls',
    ru: 'Звонки',
    pt: 'Chamadas',
  },
  'CreateProject:GraphWayEnd:ProjectDetails:Chat': {
    eng: 'Chat',
    ru: 'Чат',
    pt: 'Chat',
  },
  'CreateProject:GraphWayEnd:ProjectDetails:CommunicationLanguage': {
    eng: 'Communication language',
    ru: 'Язык общения бота',
    pt: 'Linguagem de comunicação',
  },
  'CreateProject:GraphWayEnd:ProjectDetails:Template': {
    eng: 'Template',
    ru: 'Шаблон',
    pt: 'Modelo',
  },
  'CreateProject:GraphWayEnd:ProjectDetails:ButtonText': {
    eng: 'To visual editor',
    ru: 'В конструктор',
    pt: 'Para editor visual',
  },
  'CreateProject:SkillWayStart:ButtonText': {
    eng: 'Next',
    ru: 'Продолжить',
    pt: 'Avançar',
  },
  'CreateProject:SkillWayStart:Title': {
    eng: 'Project name',
    ru: 'Название проекта',
    pt: 'Nome do projeto',
  },
  'CreateProject:SkillWayStart:ProjectNameRequired': {
    eng: 'Project name is required',
    ru: 'Название проекта обязательно для заполнения',
    pt: 'O nome do projeto é obrigatório',
  },
  'ProjectSkillWizard:Back': {
    eng: 'Back',
    ru: 'Назад',
    pt: 'Voltar',
  },
  'CreateProject:SelectSkills': {
    eng: 'Create a bot',
    ru: 'Собираем бота',
    pt: 'Criar um bot',
  },
  'CreateProject:FillSkills': {
    eng: 'Set up skills',
    ru: 'Настраиваем навыки',
    pt: 'Configurar habilidades',
  },
  'CreateProject:Connect': {
    eng: 'Connect channels',
    ru: 'Подключаем каналы',
    pt: 'Conectar canais',
  },
  'PseudoWidget:Title': {
    eng: 'Bot demo',
    ru: 'Демонстрация работы',
    pt: 'Demonstração de bot',
  },
  'PseudoWidget:UserMessageTitle': {
    eng: 'Client',
    ru: 'Клиент',
    pt: 'Cliente',
  },
  'PseudoWidget:BotMessageTitle': {
    eng: 'Bot',
    ru: 'Бот',
    pt: 'Bot',
  },
  'PseudoWidget:BotMessageTitleEditing': {
    eng: 'Bot – editing',
    ru: 'Бот – редактирование',
    pt: 'Bot — edição',
  },
  'PseudoWidget:DeployButtonText': {
    eng: 'Test your bot',
    ru: 'Протестировать бота',
    pt: 'Testar seu bot',
  },
  'PseudoWidget:DefaultEmptyState': {
    eng: 'Click “Try now” to test your bot',
    ru: 'Чтобы протестировать навык, нажмите «Попробовать»',
    pt: 'Clique em “Experimentar agora” para testar seu bot',
  },
  'PseudoWidget:Unavailable:Text': {
    eng: 'You can test your bot here',
    ru: 'Здесь вы сможете протестировать вашего бота',
    pt: 'Você pode testar seu bot aqui',
  },
  'CallTypeSelect:Title': {
    eng: 'Your bot will',
    ru: 'Что будет делать бот',
    pt: 'Seu bot vai',
  },
  'CallType_DIALER:Title': {
    eng: 'Call clients',
    ru: 'Звонить клиентам',
    pt: 'Ligar para clientes',
  },
  'CallType_DIALER:Summary': {
    eng: 'The bot will call your clients using phone numbers from a list or a CRM system',
    ru: 'Бот обзвонит ваших клиентов по номерам телефонов из списка или CRM-системы',
    pt: 'O bot ligará para seus clientes usando números de telefone de uma lista ou de um sistema de CRM',
  },
  'CallType_IVR:Title': {
    eng: 'Answer calls',
    ru: 'Отвечать на звонки',
    pt: 'Atender chamadas',
  },
  'CallType_IVR:Summary': {
    eng: 'The bot will answer calls from your clients and answer their questions',
    ru: 'Бот примет звонки от клиентов и ответит на их вопросы',
    pt: 'O bot atenderá chamadas de seus clientes e responderá às perguntas deles',
  },
  'SkillsSelectContainer:H1Title': {
    eng: 'Bot skills',
    ru: 'Навыки бота',
    pt: 'Habilidades de bot',
  },
  'SkillsSelectContainer:SetupSkills': {
    eng: 'Set up skills',
    ru: 'Настроить навыки',
    pt: 'Configurar habilidades',
  },
  'ChatGPTBanner:Text': {
    eng: 'You can use <b>ChatGPT</b> to communicate with clients. <br />Select the “Question answering (FAQ)” skill, and your bot will respond as a live agent.',
    ru: 'Используйте <b>ChatGPT</b> для общения с клиентами! <br />Выберите навык «Ответы на вопросы (FAQ)», и ваш бот будет отвечать как живой оператор.',
    pt: 'Você pode usar o <b>ChatGPT</b> para se comunicar com os clientes. <br />Selecione a habilidade “Perguntas-Respostas”, e o seu bot responderá com um agente ao vivo.',
  },
  'ChatGPTBanner:ExceededText': {
    eng: 'The <b>ChatGPT</b> usage limit has been reached.<br />To increase your limits, <a href="$[1]" target="_blank">switch to another subscription plan</a>.',
    ru: 'Лимит использования <b>ChatGPT</b> исчерпан.<br />Чтобы увеличить лимиты, <a href="$[1]" target="_blank">перейдите на другой тариф</a>.',
    pt: 'O limite de uso do <b>ChatGPT</b> foi alcançado.<br />Para aumentar seus limites, <a href="$[1]" target="_blank">mude para outro plano de assinatura</a>.',
  },
  'WizardCard:ButtonDefault': {
    eng: 'Try now',
    ru: 'Попробовать',
    pt: 'Experimentar agora',
  },
  'WizardCard:Button_Started': {
    eng: 'Stop testing',
    ru: 'Остановить тестирование',
    pt: 'Parar de testar',
  },
  'WizardCard:Button:UnselectAllSkills': {
    eng: 'Disable selected skills',
    ru: 'Отключить выбранные навыки',
    pt: 'Desativar habilidades selecionadas',
  },
  'WizardCard:ChatGPR:Hint': {
    eng: 'You can try this skill after setting it up',
    ru: 'Можно попробовать после настройки навыка',
    pt: 'Você pode experimentar esta habilidade depois da configuração',
  },
  'WizardCard:DisableHint': {
    eng: 'This skill cannot be connected together with $[1]',
    ru: 'Этот навык нельзя подключить вместе с $[1]',
    pt: 'Esta habilidade não pode ser conectada junto com $[1]',
  },
  'WizardCard:SpecialMark': {
    eng: 'special skill',
    ru: 'специальный',
    pt: 'habilidade especial',
  },
  'WizardCard:ChatGPTTooltip': {
    eng: 'ChatGPT answers questions like a live agent based on the context and provided information. <b>The ChatGPT usage on the <a href="$[1]" target="_blank">free plan</a> is limited.</b>',
    ru: 'ChatGPT отвечает на вопросы как живой оператор, исходя из контекста и известной ему информации. <b>Лимит использования ChatGPT на <a href="$[1]" target="_blank">бесплатном тарифе</a> ограничен.</b>',
    pt: 'O ChatGPT responde a perguntas como um agente ao vivo, com base no contexto e nas informações fornecidos. <b>O uso do ChatGPT no <a href="$[1]" target="_blank">plano gratuito</a> é limitado.</b>',
  },
  'Wizard:ManagerNotify:SMS:Hint': {
    eng: 'The maximum SMS length is 400 characters',
    ru: 'Максимальная длина SMS 400 символов',
    pt: 'O tamanho máximo do SMS é de 400 caracteres',
  },
  'Wizard:ManagerNotify:Google:Title': {
    eng: 'Google Sheets',
    ru: 'Google Таблицы',
    pt: 'Planilhas Google',
  },
  'Wizard:ManagerNotify:Google:ConnectButton': {
    eng: 'Continue with Google',
    ru: 'Продолжить с Google',
    pt: 'Continuar com o Google',
  },
  'Wizard:ManagerNotify:Google:Settings:Title': {
    eng: 'Google Sheets integration',
    ru: 'Интеграция с Google Таблицами',
    pt: 'Integração do Planilhas Google',
  },
  'Wizard:ManagerNotify:Google:Settings:Submit': {
    eng: 'Connect',
    ru: 'Подключить',
    pt: 'Conectar',
  },
  'Wizard:ManagerNotify:Google:Settings:CreateNewTable': {
    eng: 'Create a new spreadsheet',
    ru: 'Создать новую таблицу',
    pt: 'Criar nova planilha',
  },
  'Wizard:ManagerNotify:Google:Settings:Account': {
    eng: 'Account',
    ru: 'Аккаунт',
    pt: 'Conta',
  },
  'Wizard:ManagerNotify:Google:Settings:Table': {
    eng: 'Spreadsheet',
    ru: 'Таблица',
    pt: 'Planilha',
  },
  'Wizard:ManagerNotify:Google:Settings:Sheet': {
    eng: 'Sheet',
    ru: 'Лист таблицы',
    pt: 'Folha',
  },
  'Wizard:ManagerNotify:Google:Settings:SheetShort': {
    eng: 'Sheet',
    ru: 'Лист',
    pt: 'Folha',
  },
  'Wizard:ManagerNotify:Errors:Email:Empty': {
    eng: 'Enter email',
    ru: 'Введите email',
    pt: 'Digite o e-mail',
  },
  'Wizard:ManagerNotify:Errors:Phone:Empty': {
    eng: 'Enter phone number',
    ru: 'Введите номер телефона',
    pt: 'Digite o número de telefone',
  },
  'Wizard:ManagerNotify:Warning:Google:EnabledButNotIntegrated': {
    eng: 'All permitions must be granted',
    ru: 'Необходимо предоставить все разрешения',
    pt: 'Todas as permissões devem ser concedidas',
  },
  'Wizard:ManagerNotify:Errors:Google:EnabledButNotIntegrated': {
    eng: 'Log in to Google and provide all permissions',
    ru: 'Авторизуйтесь в Google и предоставьте все разрешения',
    pt: 'Entrar com o Google e dar todas as permissões',
  },
  'Wizard:PreventLeaveIfUnsavedSkillsModal:Title': {
    eng: 'Are you sure, you want to leave?',
    ru: 'Вы уверены, что хотите выйти?',
    pt: 'Tem certeza de que deseja sair?',
  },
  'Wizard:PreventLeaveIfUnsavedSkillsModal:Submit': {
    eng: 'Continue creating the bot',
    ru: 'Продолжить создание бота',
    pt: 'Continuar criando o bot',
  },
  'Wizard:PreventLeaveIfUnsavedSkillsModal:Cancel': {
    eng: 'Yes, delete the skills',
    ru: 'Да, удалить навыки',
    pt: 'Sim, excluir as habilidades',
  },
  'Wizard:PreventLeaveIfUnsavedSkillsModal:Description': {
    eng: 'If you leave now, the bot will lose all its skills. Finish creating the bot to save it. You can return to setting up the skills later.',
    ru: 'Если вы выйдете, бот потеряет все созданные навыки. Завершите создание бота, чтобы сохранить его. Вы сможете вернуться к настройке навыков позже.',
    pt: 'Se você sair agora, o bot perderá todas as habilidades. Finalize a criação do bot para salvá-lo. Você pode voltar para configuração de habilidades posteriormente.',
  },
  'CreateProject:ChooseGraphEditor:Title': {
    eng: 'Choose a visual editor',
    ru: 'Выбор конструктора',
    pt: 'Escolha um editor visual',
  },
  'CreateProject:ChooseGraphEditor:JgTitle': {
    eng: 'New version',
    ru: 'Новая версия',
    pt: 'Nova versão',
  },
  'CreateProject:ChooseGraphEditor:JgDescription': {
    eng: 'Updated interface and new features for convenient and flexible script configuration',
    ru: 'Обновленный интерфейс и новые возможности для удобной и гибкой настройки сценариев',
    pt: 'Interface atualizada e novos recursos para configuração de script conveniente e flexível',
  },
  'CreateProject:ChooseGraphEditor:AimyButtonText': {
    eng: 'Continue in the old version',
    ru: 'Продолжить в старой версии',
    pt: 'Continuar na versão antiga',
  },
  'CreateProject:ChooseGraphEditor:AimyButtonDescription': {
    eng: 'We continue to support it',
    ru: 'Мы по-прежнему поддерживаем ее',
    pt: 'Continuamos com o suporte',
  },
  'MenuSkill:Title': {
    eng: 'Bot menu',
    ru: 'Меню бота',
    pt: 'Menu do bot',
  },
  'MenuSkill:Fixed:Order:ButtonText': {
    eng: 'Order',
    ru: 'Заказ',
    pt: 'Pedido',
  },
  'MenuSkill:Fixed:ServiceEvaluation:ButtonText': {
    eng: 'Leave feedback',
    ru: 'Оставить отзыв',
    pt: 'Deixar feedback',
  },
  'MenuSkill:Fixed:CollectingContacts:ButtonText': {
    eng: 'Get promo code',
    ru: 'Получить промокод',
    pt: 'Receber o código da promoção',
  },
  'MenuSkill:Operator:ButtonText': {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  'CollectingContactsSkill:Title': {
    eng: 'Collecting contacts',
    ru: 'Сбор контактов',
    pt: 'Coletando contatos',
  },
  'CollectingContactsSkill:ShortDescription': {
    eng: 'The bot will collect client contacts in exchange for useful information or a promo code.',
    ru: 'Бот соберет контакты клиентов в обмен на полезный материал или промокод.',
    pt: 'O bot coletará os contatos do cliente em troca de informações úteis ou um código promocional.',
  },
  'CollectingContactsSkill:Fields:StartDialogue': {
    eng: 'Dialog start',
    ru: 'Начало диалога',
    pt: 'Início do diálogo',
  },
  'CollectingContactsSkill:Fields:StartOrderText:Title': {
    eng: 'Offer for the client',
    ru: 'Предложение для клиента',
    pt: 'Oferta para o cliente',
  },
  'CollectingContactsSkill:Fields:StartOrderText:Placeholder': {
    eng: 'I have a gift for you! It’s a promo code with a 20% discount on your order. Do you want to receive it?',
    ru: 'У меня для вас подарок – промокод со скидкой 20% на заказ! Хотите получить?',
    pt: 'Eu tenho um presente para você! É um código promocional com 20% de desconto no seu pedido. Quer receber?',
  },
  'CollectingContactsSkill:Fields:StartOrderText:HelperText': {
    eng: 'The bot will send this phrase right after the greeting.',
    ru: 'Бот отправит эту фразу после приветствия.',
    pt: 'O bot enviará esta frase logo após a saudação.',
  },
  'CollectingContactsSkill:Fields:AgreementReaction': {
    eng: 'Bot reaction to agreement',
    ru: 'Реакция бота на согласие',
    pt: 'Reação do bot ao contrato',
  },
  'CollectingContactsSkill:Fields:ContactsRequest:Title': {
    eng: 'Contact information request',
    ru: 'Запрос контактов',
    pt: 'Solicitação de informações de contato',
  },
  'CollectingContactsSkill:Fields:ContactsRequest:Placeholder': {
    eng: 'Please leave your contact details, and I will send you a promo code.',
    ru: 'Оставьте свои контактные данные, и я вышлю вам промокод.',
    pt: 'Deixe seus dados de contato e enviarei um código promocional.',
  },
  'CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Header': {
    eng: 'Information to request',
    ru: 'Что нужно узнать',
    pt: 'Informações a solicitar',
  },
  'CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Email': {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail',
  },
  'CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Email:Placeholder': {
    eng: 'Enter your email',
    ru: 'Напишите ваш email',
    pt: 'Digite seu e-mail',
  },
  'CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Name': {
    eng: 'Name',
    ru: 'Имя',
    pt: 'Nome',
  },
  'CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Name:Placeholder': {
    eng: 'Enter your name',
    ru: 'Напишите ваше имя',
    pt: 'Digite seu nome',
  },
  'CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Placeholder': {
    eng: 'Information to request',
    ru: 'Что нужно узнать',
    pt: 'Informações a solicitar',
  },
  'CollectingContactsSkill:Fields:OrderInfo:Columns:PhraseOfBot:Header': {
    eng: 'Bot phrase',
    ru: 'Фраза бота',
    pt: 'Frase de bot',
  },
  'CollectingContactsSkill:Fields:OrderInfo:AddRowText': {
    eng: 'Add parameter',
    ru: 'Добавить параметр',
    pt: 'Adicionar parâmetro',
  },
  'CollectingContactsSkill:Fields:VerifyQuestionsText:Title': {
    eng: 'Information check',
    ru: 'Проверка данных',
    pt: 'Verificação de informações',
  },
  'CollectingContactsSkill:Fields:VerifyQuestionsText:Placeholder': {
    eng: 'Please check the provided information. Is everything correct?',
    ru: 'Проверьте правильность введенных данных. Все верно?',
    pt: 'Verifique as informações fornecidas. Tudo certo?',
  },
  'CollectingContactsSkill:Fields:VerifyQuestionsText:HelperText': {
    eng: 'The bot will send the collected information to the dialog. If the client finds an error in them, they can correct it.',
    ru: 'Бот отправит в диалог полученные данные. Если клиент найдет в них ошибку, он сможет ее исправить.',
    pt: 'O bot enviará as informações coletadas para a caixa de diálogo. Se o cliente encontrar algum erro, poderá corrigi-lo.',
  },
  'CollectingContactsSkill:Fields:SubmitOrderText:Title': {
    eng: 'Bot response after collecting all the information',
    ru: 'Ответ после сбора данных',
    pt: 'Resposta do bot após coletar todas as informações',
  },
  'CollectingContactsSkill:Fields:SubmitOrderText:Placeholder': {
    eng: 'Thanks for the information. Here is your promo code: GIFT20.',
    ru: 'Спасибо за информацию. Ваш промокод: GIFT20.',
    pt: 'Agradecemos pelas informações. Aqui está o seu código promocional: GIFT20.',
  },
  'CollectingContactsSkill:Fields:SubmitOrderText:HelperText': {
    eng: 'The bot can send a promo code to the client or offer to wait for a message from the manager.',
    ru: 'Бот может отправить промокод клиенту или предложить подождать сообщение от менеджера.',
    pt: 'O bot pode enviar um código promocional ao cliente ou se oferecer para aguardar uma mensagem do gerente.',
  },
  'CollectingContactsSkill:Fields:RejectBotText': {
    eng: 'Bot reaction to offer refusal',
    ru: 'Реакция бота на отказ',
    pt: 'Reação do bot para oferecer recusa',
  },
  'CollectingContactsSkill:Fields:RejectOrderText:Title': {
    eng: 'Bot response',
    ru: 'Ответ бота',
    pt: 'Resposta do bot',
  },
  'CollectingContactsSkill:Fields:RejectOrderText:Placeholder': {
    eng: 'Let me know if you would be interested later.',
    ru: 'Пишите, если вам будет это интересно в будущем!',
    pt: 'Caso tenha interesse mais tarde, me avise.',
  },
  'CollectingContactsSkill:Fields:SendManagerInfo:Title': {
    eng: 'Sending information to the manager',
    ru: 'Отправка информации менеджеру',
    pt: 'Enviando informações ao gerente',
  },
  'CollectingContactsSkill:Fields:SendManagerInfo:Placeholder': {
    eng: 'The bot will send an SMS or email with the collected information to the manager.',
    ru: 'Бот отправит менеджеру SMS или электронное письмо с собранной информацией.',
    pt: 'O bot enviará um SMS ou e-mail com as informações coletadas para o gerente.',
  },
  'FaqSkill:Title': {
    eng: 'Question answering (FAQ)',
    ru: 'Ответы на вопросы (FAQ)',
    pt: 'Resposta a perguntas frequentes (FAQ)',
  },
  'FaqSkill:ShortDescription': {
    eng: 'The bot will answer your clients’ frequently asked questions.',
    ru: 'Бот ответит на часто задаваемые вопросы ваших клиентов.',
    pt: 'O bot responderá às perguntas frequentes dos seus clientes.',
  },
  'FaqSkill:Description': {
    eng: 'ChatGPT uses this information when communicating with the client. The more it knows, the better and more natural the answers will be.',
    ru: 'ChatGPT использует эту информацию при общении с клиентом. Чем больше нейросеть будет знать, тем качественнее и живее ее будут ответы.',
    pt: 'O ChatGPT usa essas informações durante a comunicação com o cliente. Quanto mais ele sabe, melhores e mais naturais serão as respostas.',
  },
  'OrderSkill:Title': {
    eng: 'Making an order or a request',
    ru: 'Оформление заказа или заявки',
    pt: 'Fazendo um pedido ou uma solicitação',
  },
  'OrderSkill:ShortDescription': {
    eng: 'The bot will help to place an order and collect the necessary information and client contacts.',
    ru: 'Бот поможет клиенту оформить заказ, соберет нужную информацию и контакты клиента.',
    pt: 'O bot ajudará a fazer um pedido e coletar as informações necessárias e os contatos do cliente.',
  },
  'OrderSkill:Fields:Header:Title': {
    eng: 'Requesting information from the client',
    ru: 'Запрос информации у клиента',
    pt: 'Solicitando informações do cliente',
  },
  'OrderSkill:Fields:Header:Description': {
    eng: 'Use ready-made phrases or enter your own according to examples.',
    ru: 'Используйте готовые фразы или введите свои по примеру.',
    pt: 'Use frases prontas ou insira as suas próprias de acordo com os exemplos.',
  },
  'OrderSkill:Fields:StartOrderText:Title': {
    eng: 'First message',
    ru: 'Начало оформления',
    pt: 'Primeira mensagem',
  },
  'OrderSkill:Fields:StartOrderText:Placeholder': {
    eng: 'To place an order, I will ask you a few questions. By continuing the dialog, you consent to the processing of personal data.',
    ru: 'Чтобы оформить заказ, я задам вам несколько вопросов. Продолжая диалог, вы даете согласие на обработку персональных данных.',
    pt: 'Para fazer um pedido, farei algumas perguntas. Ao continuar o diálogo, você concorda com o processamento de dados pessoais.',
  },
  'OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header': {
    eng: 'Information to request',
    ru: 'Что нужно узнать',
    pt: 'Informações a solicitar',
  },
  'OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Order': {
    eng: 'Product or service',
    ru: 'Товар или услуга',
    pt: 'Produto ou serviço',
  },
  'OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Delivery': {
    eng: 'Delivery method',
    ru: 'Способ доставки',
    pt: 'Método de entrega',
  },
  'OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Name': {
    eng: 'Name',
    ru: 'Имя',
    pt: 'Nome',
  },
  'OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Phone': {
    eng: 'Phone number',
    ru: 'Телефон',
    pt: 'Número de telefone',
  },
  'OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:AppointmentTime': {
    eng: 'Delivery time',
    ru: 'Время доставки',
    pt: 'Horário de entrega',
  },
  'OrderSkill:Fields:Questions:Columns:HowBotWillRequestInformation:Header': {
    eng: 'Bot phrase',
    ru: 'Фраза бота',
    pt: 'Frase de bot',
  },
  'OrderSkill:Fields:Questions:AddRowText': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'OrderSkill:Questions:Values:ForOrder': {
    eng: 'What would you like to order?',
    ru: 'Что бы вы хотели заказать?',
    pt: 'O que você gostaria de pedir?',
  },
  'OrderSkill:Questions:Values:ForDelivery': {
    eng: 'How would you like to receive an order? Enter “Pickup” or your delivery address.',
    ru: 'Как хотите получить заказ? Напишите «Самовывоз» или адрес, куда его привезти.',
    pt: 'Como você gostaria de receber um pedido? Digite “Coleta” ou seu endereço de entrega.',
  },
  'OrderSkill:Questions:Values:ForName': {
    eng: 'Enter the recipient’s name',
    ru: 'Введите имя получателя',
    pt: 'Digite o nome do destinatário',
  },
  'OrderSkill:Questions:Values:ForPhone': {
    eng: 'Enter the recipient’s phone number',
    ru: 'Введите телефон получателя',
    pt: 'Digite o número de telefone do destinatário',
  },
  'OrderSkill:Questions:Values:ForAppointmentTime': {
    eng: 'When is the best time for you to receive the order?',
    ru: 'В какое время вам удобнее получить заказ?',
    pt: 'Qual é o melhor horário para você receber o pedido?',
  },
  'OrderSkill:Fields:VerifyQuestionsText:Title': {
    eng: 'Information check',
    ru: 'Проверка данных',
    pt: 'Verificação de informações',
  },
  'OrderSkill:Fields:VerifyQuestionsText:Placeholder': {
    eng: 'Please check the correctness of the provided information. If everything is correct, click “Continue”. To correct something, select the information you want to change.',
    ru: 'Проверьте правильность введенных данных. Если все верно, нажмите «Продолжить». Чтобы внести исправления, выберите, какие данные вы хотите изменить.',
    pt: 'Verifique a veracidade das informações fornecidas. Se tudo estiver correto, clique em “Continuar”. Para corrigir algo, selecione as informações que deseja alterar.',
  },
  'OrderSkill:Fields:VerifyQuestionsText:HelperText': {
    eng: 'The bot will send the order information to the dialog. If the client finds an error in it, they can correct it.',
    ru: 'Бот отправит в диалог данные о заказе. Если клиент найдет в них ошибку, он сможет ее исправить.',
    pt: 'O bot enviará as informações do pedido para a caixa de diálogo. Se o cliente encontrar algum erro, poderá-corrigi-lo.',
  },
  'OrderSkill:Fields:SubmitOrderText:Title': {
    eng: 'Confirmation',
    ru: 'Подтверждение',
    pt: 'Confirmação',
  },
  'OrderSkill:Fields:SubmitOrderText:Placeholder': {
    eng: 'I have sent the information to the manager. You will be contacted soon.',
    ru: 'Я отправил информацию менеджеру. Скоро с вами свяжутся.',
    pt: 'Enviei as informações ao gerente. Em breve, entraremos em contato.',
  },
  'OrderSkill:Fields:SendInfoLocation:Title': {
    eng: 'Sending information to the manager',
    ru: 'Отправка информации менеджеру',
    pt: 'Enviando informações ao gerente',
  },
  'OrderSkill:Fields:SendInfoLocation:Description': {
    eng: 'The bot will send an SMS or email with the collected information to the manager.',
    ru: 'Бот отправит менеджеру SMS или электронное письмо с собранной информацией.',
    pt: 'O bot enviará um SMS ou e-mail com as informações coletadas para o gerente.',
  },
  'ServiceEvaluationSkill:Title': {
    eng: 'Service evaluation',
    ru: 'Оценка сервиса',
    pt: 'Avaliação do serviço',
  },
  'ServiceEvaluationSkill:Description': {
    eng: 'The bot will ask to evaluate the service using a 1 to 5 rating scale and will send the information to the manager.',
    ru: 'Бот попросит клиента оценить качество услуг от 1 до 5 и передаст информацию менеджеру.',
    pt: 'O bot pedirá para avaliar o serviço usando uma escala de classificação de 1 a 5 e enviará as informações ao gerente.',
  },
  'ServiceEvaluationSkill:ShortDescription': {
    eng: 'The bot will ask to evaluate the quality of services and send the information to the manager.',
    ru: 'Бот попросит клиента оценить качество услуг и передаст информацию менеджеру.',
    pt: 'O bot pedirá para avaliar a qualidade dos serviços e enviará as informações ao gerente.',
  },
  'ServiceEvaluationSkill:Fields:EstimationSuggest:Title': {
    eng: 'Service evaluation prompt',
    ru: 'Предложение оценить сервис',
    pt: 'Solicitação de avaliação de serviço',
  },
  'ServiceEvaluationSkill:Fields:EstimationSuggest:Placeholder': {
    eng: 'Help us to improve by rating our service on a scale of 1 to 5, where 5 is the highest score.',
    ru: 'Помогите нам стать лучше, оцените удобство нашего сайта по шкале от 1 до 5, где 5 – все отлично.',
    pt: 'Ajude-nos a melhorar classificando nosso serviço em uma escala de 1 a 5, em que 5 é a pontuação mais alta.',
  },
  'ServiceEvaluationSkill:Fields:ReactionOnEstimation': {
    eng: 'Bot reaction to evaluation',
    ru: 'Реакция бота на оценку',
    pt: 'Reação do bot à avaliação',
  },
  'ServiceEvaluationSkill:Fields:Marks:Columns:Estimation': {
    eng: 'Score',
    ru: 'Оценка',
    pt: 'Pontuação',
  },
  'ServiceEvaluationSkill:Fields:Marks:Columns:PhraseOfBot': {
    eng: 'Bot phrase',
    ru: 'Фраза бота',
    pt: 'Frase de bot',
  },
  'ServiceEvaluationSkill:Fields:Marks:Placeholder:LowMark': {
    eng: 'Please let us know what we can improve.',
    ru: 'Поделитесь, пожалуйста, как нам стать лучше!',
    pt: 'Informe-nos sobre o que podemos melhorar.',
  },
  'ServiceEvaluationSkill:Fields:Marks:Placeholder:RegularMark': {
    eng: 'Thank you for rating us! We want to improve our service, please share your suggestions with us.',
    ru: 'Спасибо за оценку! Мы хотим стать лучше, поделитесь своими впечатлениями.',
    pt: 'Agradecemos por nos avaliar! Compartilhe suas sugestões conosco para que possamos melhorar.',
  },
  'ServiceEvaluationSkill:Fields:Marks:Placeholder:HighMark': {
    eng: 'Thanks for the high score! If you have any ideas on how we can improve our service, please let us know.',
    ru: 'Спасибо за высокую оценку! Если у вас есть идеи, как мы можем стать лучше, пожалуйста, напишите.',
    pt: 'Agradecemos pela pontuação alta! Caso tenha alguma ideia sobre como podemos melhorar nosso serviço, avise-nos.',
  },
  'ServiceEvaluationSkill:Fields:FinishDialogText:Title': {
    eng: 'Dialog end',
    ru: 'Завершение диалога',
    pt: 'Final do diálogo',
  },
  'ServiceEvaluationSkill:Fields:FinishDialogText:Placeholder': {
    eng: 'Thank you! I have sent your feedback to the manager.',
    ru: 'Спасибо! Я отправил вашу обратную связь менеджеру.',
    pt: 'Obrigado! Enviei seu feedback ao gerente.',
  },
  'ServiceEvaluationSkill:Fields:ClientReject': {
    eng: 'The client refused to evaluate the service',
    ru: 'Клиент отказался поставить оценку',
    pt: 'O cliente se recusou a avaliar o serviço',
  },
  'ServiceEvaluationSkill:Fields:RejectOrderText:Title': {
    eng: 'Response to refusal',
    ru: 'Ответ на отказ',
    pt: 'Resposta à recusa',
  },
  'ServiceEvaluationSkill:Fields:RejectOrderText:Placeholder': {
    eng: 'If you change your mind, click the evaluation button in the menu.',
    ru: 'Если передумаете, нажмите кнопку для оценки в меню.',
    pt: 'Se mudar de ideia, clique no botão de avaliação no menu.',
  },
  'ServiceEvaluationSkill:Fields:SendInfoToManager:Title': {
    eng: 'Sending information to the manager',
    ru: 'Отправка информации менеджеру',
    pt: 'Enviando informações ao gerente',
  },
  'ServiceEvaluationSkill:Fields:SendInfoToManager:Description': {
    eng: 'The bot will send an SMS or email with the collected information to the manager.',
    ru: 'Бот отправит менеджеру SMS или электронное письмо с собранной информацией.',
    pt: 'O bot enviará um SMS ou e-mail com as informações coletadas para o gerente.',
  },
  'TemplatesWizard:Menu:Title': {
    eng: 'Menu',
    ru: 'Меню',
    pt: 'Menu',
  },
  'TemplatesWizard:Menu:Description': {
    eng: 'The bot menu is a set of buttons for launching skills and activating bot reactions. Buttons that launch skills cannot be deleted but can be renamed.',
    ru: 'Меню бота — это набор кнопок, которыми клиент может запустить навыки и активировать действия бота. Кнопки запуска навыков нельзя удалить, но можно переименовать.',
    pt: 'O menu do bot é um conjunto de botões para iniciar habilidades e ativar as reações do bot. Botões que ativam habilidades não podem ser excluídos, mas podem ser renomeados.',
  },
  'TemplatesWizard:Menu:aboveTableTitle': {
    eng: 'Menu buttons',
    ru: 'Кнопки меню',
    pt: 'Botões de menu',
  },
  'TemplatesWizard:Menu:TableHeader:Title': {
    eng: 'Button',
    ru: 'Кнопка',
    pt: 'Botão',
  },
  'TemplatesWizard:Menu:TableHeader:Action': {
    eng: 'Launches',
    ru: 'Запускает',
    pt: 'Inicia',
  },
  'TemplatesWizard:Menu:TableHeader:BotAction': {
    eng: 'Bot reaction',
    ru: 'Реакция бота',
    pt: 'Reação do bot',
  },
  'TemplatesWizard:Menu:Table:AddRowButton': {
    eng: 'Add button',
    ru: 'Добавить кнопку',
    pt: 'Adicionar botão',
  },
  'TemplatesWizard:Menu:Fields:Title:Placeholder': {
    eng: 'Button text',
    ru: 'Текст кнопки',
    pt: 'Texto do botão',
  },
  'TemplatesWizard:Menu:Errors:ButtonName': {
    eng: 'Enter button name',
    ru: 'Введите название кнопки',
    pt: 'Insira o nome do botão',
  },
  'TemplatesWizard:Menu:Errors:Action': {
    eng: 'Choose a skill',
    ru: 'Выберите навык',
    pt: 'Escolha uma habilidade',
  },
  'TemplatesWizard:Menu:Errors:BotAction': {
    eng: 'Choose an action',
    ru: 'Выберите действие',
    pt: 'Escolha uma ação',
  },
  'TemplatesWizard:Menu:Fields:Action:Placeholder': {
    eng: 'Choose a skill',
    ru: 'Выберите навык',
    pt: 'Escolha uma habilidade',
  },
  'TemplatesWizard:Menu:Fields:BotAction:Placeholder': {
    eng: 'Choose a bot reaction',
    ru: 'Выберите реакцию бота',
    pt: 'Escolha uma reação de bot',
  },
  'TemplatesWizard:Menu:Fields:Action:Question': {
    eng: 'Question answering (FAQ)',
    ru: 'Ответы на вопросы (FAQ)',
    pt: 'Resposta a perguntas frequentes (FAQ)',
  },
  'TemplatesWizard:Menu:Fields:BotAction:Transition': {
    eng: 'Launch the skill',
    ru: 'Запустить навык',
    pt: 'Iniciar a habilidade',
  },
  'FAQSkill:Fields:Title': {
    eng: 'Recommended questions',
    ru: 'Рекомендованные вопросы',
    pt: 'Perguntas recomendadas',
  },
  'FAQSkill:Fields:Description': {
    eng: 'The bot understands different question wordings, such as “What are the delivery terms?”, “What shipping methods are available?”, “How do I get my order?”.',
    ru: 'Бот понимает разные формулировки вопросов клиента, например, «Какие условия доставки?», «Какие способы доставки?», «Как получить заказ?».',
    pt: 'O bot entende diferentes formulações de perguntas, como “Quais são as condições de entrega?”, “Quais métodos de envio estão disponíveis?”, “Como faço para receber meu pedido?”.',
  },
  'FAQSkill:Fields:CustomQuestions:Title': {
    eng: 'Your questions',
    ru: 'Ваши вопросы',
    pt: 'Suas perguntas',
  },
  'FAQWithChatGPTSkill:Fields:Title': {
    eng: 'ChatGPT answer style',
    ru: 'Стиль ответа ChatGPT на вопросы',
    pt: 'Estilo de resposta do ChatGPT',
  },
  'BotSettings:ConversationTone:Label': {
    eng: 'Tone of communication',
    ru: 'Тон общения',
    pt: 'Tom da comunicação',
  },
  'BotSettings:ConversationTone:NEUTRAL': {
    eng: '<i class="just-emoji-bust-in-silhouette"></i> Neutral',
    ru: '<i class="just-emoji-bust-in-silhouette"></i> Нейтральный',
    pt: '<i class="just-emoji-bust-in-silhouette"></i> Neutro',
  },
  'BotSettings:ConversationTone:FRIENDLY': {
    eng: '<i class="just-emoji-smiling-face"></i> Friendly',
    ru: '<i class="just-emoji-smiling-face"></i> Дружеский',
    pt: '<i class="just-emoji-smiling-face"></i> Amigável',
  },
  'BotSettings:ConversationTone:FORMAL': {
    eng: '<i class="just-emoji-briefcase"></i> Formal',
    ru: '<i class="just-emoji-briefcase"></i> Формальный',
    pt: '<i class="just-emoji-briefcase"></i> Formal',
  },
  'BotSettings:EmojiUsing:Label': {
    eng: 'Uses emoji',
    ru: 'Использует эмодзи',
    pt: 'Usa emoji',
  },
  'BotSettings:EmojiUsing:NEVER': {
    eng: '<i class="just-emoji-cross-mark"></i> Never',
    ru: '<i class="just-emoji-cross-mark"></i> Никогда',
    pt: '<i class="just-emoji-cross-mark"></i> Nunca',
  },
  'BotSettings:EmojiUsing:RARELY': {
    eng: '<i class="just-emoji-eyes"></i> Rarely',
    ru: '<i class="just-emoji-eyes"></i> Редко',
    pt: '<i class="just-emoji-eyes"></i> Raramente',
  },
  'BotSettings:EmojiUsing:OFTEN': {
    eng: '<i class="just-emoji-grinning-face"></i> Often',
    ru: '<i class="just-emoji-grinning-face"></i> Часто',
    pt: '<i class="just-emoji-grinning-face"></i> Com frequência',
  },
  'FAQSkill:Fields:CompanyInfo:Title': {
    eng: 'Information about the company',
    ru: 'Информация о компании',
    pt: 'Informações sobre a empresa',
  },
  'FAQSkill:Fields:Questions:Title': {
    eng: 'Questions and answers',
    ru: 'Вопросы и ответы',
    pt: 'Perguntas e respostas',
  },
  'FAQSkill:Fields:Questions:Description': {
    eng: 'Use ready-made phrases or enter your own. You can add up to 5 questions.',
    ru: 'Используйте готовые вопросы или введите свои. Вы можете добавить не более 5 вопросов.',
    pt: 'Use frases prontas ou insira as de sua autoria. Você pode adicionar até 5 perguntas.',
  },
  'FAQSkill:CompanyNameAndDescription:Title': {
    eng: 'Company name and description',
    ru: 'Название и описание компании',
    pt: 'Nome e descrição da empresa',
  },
  'FAQSkill:CompanyNameAndDescription:HelperText': {
    eng: 'Please provide the information about your company and add helpful information for the client.<br/> <a href="#{config.zenflow.helpUrl}/docs/en/quick_start/chatgpt/#company-info" target="_blank">Learn more about how to fill in the field</a>. The maximum text length is 1,000 characters.',
    ru: 'Расскажите, чем занимается компания и добавьте любую информацию, которая может быть полезна клиенту.<br /> <a href="#{config.zenflow.helpUrl}/docs/ru/quick_start/chatgpt/#how-to-fill-in-the-company-information" target="_blank">Подробнее о том, как заполнить поле</a>. Максимальная длина текста — 1000 символов.',
    pt: 'Forneça informações sobre sua empresa e adicione informações úteis para o cliente.<br/> <a href="#{config.zenflow.helpUrl}/docs/pt/quick_start/chatgpt/#how-to-fill-in-the-company-information" target="_blank">Saiba mais sobre como preencher o campo</a>. O comprimento máximo do texto é 1000 caracteres.',
  },
  'FAQSkill:Empty': {
    eng: 'Fill out this field',
    ru: 'Заполните поле',
    pt: 'Preencha este campo',
  },
  'FAQSkill:MaxLength': {
    eng: 'The maximum text length is $[1] characters',
    ru: 'Максимальная длина текста — $[1] символов',
    pt: 'O comprimento máximo do texto é $[1] caracteres',
  },
  'FAQSkill:Contacts:Header': {
    eng: 'Contacts',
    ru: 'Контакты',
    pt: 'Contatos',
  },
  'FAQSkill:Contacts:Columns:Email': {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail do bot',
  },
  'FAQSkill:Contacts:Columns:Site': {
    eng: 'Website',
    ru: 'Сайт',
    pt: 'Site',
  },
  'FAQSkill:Contacts:Columns:Vkontakte': {
    eng: 'VK',
    ru: 'ВКонтакте',
    pt: 'VK',
  },
  'FAQSkill:Contacts:Columns:Odnoklassniki': {
    eng: 'OK',
    ru: 'Одноклассники',
    pt: 'OK',
  },
  'FAQSkill:Contacts:Columns:Telegram': {
    eng: 'Telegram',
    ru: 'Telegram',
    pt: 'Telegram',
  },
  'FAQSkill:Contacts:AddRowText': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'FAQSkill:Fields:DefaultQuestions:Add': {
    eng: 'Add question',
    ru: 'Добавить вопрос',
    pt: 'Adicionar pergunta',
  },
  'FAQSkill:QuestionPlaceholder': {
    eng: 'Client question',
    ru: 'Вопрос клиента',
    pt: 'Pergunta do cliente',
  },
  'FAQSkill:AnswerPlaceholder': {
    eng: 'Bot response',
    ru: 'Ответ бота',
    pt: 'Resposta do bot',
  },
  'FAQSkill:Actions:Back': {
    eng: 'Back',
    ru: 'Назад',
    pt: 'Voltar',
  },
  'FAQSkill:Actions:Forward': {
    eng: 'Next',
    ru: 'Далее',
    pt: 'Avançar',
  },
  'FAQSkill:Actions:Done': {
    eng: 'Done',
    ru: 'Готово',
    pt: 'Feito',
  },
  'FAQSkill:Actions:DeleteSkill': {
    eng: 'Delete skill',
    ru: 'Удалить навык',
    pt: 'Excluir habilidade',
  },
  'FAQSkill:Actions:DeleteSkill:Tooltip': {
    eng: 'It is impossible to delete a single bot skill. Add a new skill to remove this one, or remove the bot from the project list later.',
    ru: 'Удалить единственный навык бота невозможно. Добавьте новый навык, чтобы удалить этот, или удалите бота в списке проектов позже.',
    pt: 'É impossível excluir uma única habilidade de bot. Adicione uma nova habilidade para remover esta ou remova o bot da lista de projetos posteriormente.',
  },
  'FAQSkill:DeleteLastQuestion:Tooltip': {
    eng: 'It is impossible to delete a single question. To remove it, add a new one first.',
    ru: 'Удалить единственный вопрос невозможно. Чтобы удалить этот вопрос, сначала добавьте новый.',
    pt: 'É impossível excluir uma única pergunta. Para removê-la, adicione uma nova pergunta primeiro.',
  },
  'FAQSkill:StaticSuggests:question1': {
    eng: 'What are your working hours?',
    ru: 'Какой у вас режим работы?',
    pt: 'Qual é o seu horário de trabalho?',
  },
  'FAQSkill:StaticSuggests:answerPlaceholder1': {
    eng: 'We are open daily from 9 am to 8 pm.',
    ru: 'Мы работаем ежедневно с 09:00 до 20:00.',
    pt: 'Estamos abertos diariamente das 9h às 20h.',
  },
  'FAQSkill:StaticSuggests:question2': {
    eng: 'What are the delivery terms?',
    ru: 'Какие условия доставки?',
    pt: 'Quais são as condições de entrega?',
  },
  'FAQSkill:StaticSuggests:answerPlaceholder2': {
    eng: 'We send orders of $30 or more by courier service for free. You can also pick up your order from the distribution point.',
    ru: 'Отправляем курьерской службой бесплатно при заказе от 3000₽. Также можно забрать самовывозом.',
    pt: 'Enviamos gratuitamente pedidos de US$ 30 ou mais por serviço de correio. Você também pode retirar seu pedido no ponto de distribuição.',
  },
  'FAQSkill:StaticSuggests:question3': {
    eng: 'When is the doctor’s office open?',
    ru: 'В какие часы принимает специалист?',
    pt: 'Qual horário de funcionamento do consultório médico?',
  },
  'FAQSkill:StaticSuggests:answerPlaceholder3': {
    eng: 'From 10 am to 5 pm from Monday to Thursday and from 10 am to 4 pm on Friday.',
    ru: 'Работает в понедельник и вторник с 15:00 до 20:00 и в четверг и пятницу с 10:00 до 15:00.',
    pt: 'Das 10h às 17h de segunda a quinta e das 10h às 16h na sexta.',
  },
  'FAQSkill:StaticSuggests:question4': {
    eng: 'What are the payment terms?',
    ru: 'Какие условия оплаты?',
    pt: 'Quais são os termos de pagamento?',
  },
  'FAQSkill:Error:Validate': {
    eng: 'You haven’t filled in all the skills yet. To continue, add the necessary information.',
    ru: 'Вы еще не заполнили все навыки. Чтобы продолжить, добавьте необходимые данные.',
    pt: '',
  },
  'FAQSkill:StaticSuggests:question5': {
    eng: 'How can I cancel my order?',
    ru: 'Как отменить заказ?',
    pt: 'Como cancelar meu pedido?',
  },
  'FAQSkill:StaticSuggests:answerPlaceholder5': {
    eng: 'Email us at shop@example.com specifying your order number.',
    ru: 'Напишите письмо на почту shop@example.com, указав номер заказа.',
    pt: 'Envie-nos um e-mail para shop@example.com especificando o número do seu pedido.',
  },
  'FAQSkill:StaticSuggests:question6': {
    eng: 'How do I know the status of my order?',
    ru: 'Как узнать статус заказа?',
    pt: 'Como sei o status do meu pedido?',
  },
  'FAQSkill:StaticSuggests:answerPlaceholder6': {
    eng: 'Email us at shop@example.com specifying your order number.',
    ru: 'Напишите письмо на почту shop@example.com, указав номер заказа.',
    pt: 'Envie-nos um e-mail para shop@example.com especificando o número do seu pedido.',
  },
  'FAQSkill:StaticSuggests:question7': {
    eng: 'What is the return policy?',
    ru: 'Какие условия и сроки возврата?',
    pt: 'Qual é a política de devolução?',
  },
  'FAQSkill:StaticSuggests:answerPlaceholder7': {
    eng: 'Returns are made within three working days. To issue a return, email us at shop@example.com specifying your order number.',
    ru: 'Возврат осуществляется в течение 3 рабочих дней. Чтобы оформить возврат, напишите на почту shop@example.com, указав номер заказа.',
    pt: 'As devoluções são feitas dentro de três dias úteis. Para fazer uma devolução, envie-nos um e-mail para shop@example.com especificando seu número do pedido.',
  },
  'SkillsWizard:SkillPrefix': {
    eng: '“$[1]” skill',
    ru: 'Навык «$[1]»',
    pt: '“$[1]” habilidade',
  },
  'SkillsWizard:Actions:AddSkill': {
    eng: 'Add skill',
    ru: 'Добавить навык',
    pt: 'Adicionar habilidade',
  },
  'SkillsWizard:Actions:GoForward': {
    eng: 'Proceed to connecting the bot',
    ru: 'Вперед к подключению бота',
    pt: 'Prossiga para conectar o bot',
  },
  'SkillsWizard:Actions:SaveAndPublish': {
    eng: 'Save and publish',
    ru: 'Сохранить и опубликовать',
    pt: 'Salvar e publicar',
  },
  'SkillsWizard:PostSetup:Page:Title': {
    eng: 'Skill builder',
    ru: 'Редактор навыков',
    pt: 'Construtor de habilidades',
  },
  'SkillsWizard:PostSetup:Page:Description': {
    eng: 'Change parameters, add and remove skills. For more flexible settings, you can switch to the visual editor mode. <a href="#{config.botadmin.helpUrl}/docs/en/visual-editor/quick-start/simple-bot">Learn more about the visual editor</a>',
    ru: 'Меняйте параметры, добавляйте и удаляйте навыки. Для гибкой настройки можно переключиться в режим конструктора. <a href="#{config.botadmin.helpUrl}/docs/ru/visual-editor/quick-start/simple-bot">Подробнее о конструкторе</a>',
    pt: 'Alterar parâmetros, adicionar e remover habilidades. Para configurações mais flexíveis, você pode alternar para o modo de editor visual. <a href="#{config.botadmin.helpUrl}/docs/en/visual-editor/quick-start/simple-bot">Saiba mais sobre o editor visual</a>',
  },
  'SkillsWizard:PostSetup:Buttons:InConstructor': {
    eng: 'Switch to visual editor mode',
    ru: 'В режим конструктора',
    pt: 'Alternar para o modo de editor visual',
  },
  'SkillsWizard:AddNewSkillModal:Title': {
    eng: 'Available skills',
    ru: 'Доступные навыки',
    pt: 'Habilidades disponíveis',
  },
  'SkillsWizard:AddNewSkillModal:Submit': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'SkillsWizard:AddNewSkillModal:Cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
    pt: 'Cancelar',
  },
  'SkillsWizard:AddNewSkillModal:Empty': {
    eng: 'No skills available',
    ru: 'Нет доступных навыков',
    pt: 'Nenhuma habilidade disponível',
  },
  'SkillsWizard:DeleteSkillModal:Title': {
    eng: 'Are you sure you want to delete this skill?',
    ru: 'Вы уверены, что хотите удалить навык?',
    pt: 'Tem certeza de que deseja excluir esta habilidade?',
  },
  'SkillsWizard:DeleteSkillModal:Actions:Cancel': {
    eng: 'No, leave it',
    ru: 'Нет, оставить',
    pt: 'Não, deixa-a como está',
  },
  'SkillsWizard:DeleteSkillModal:Actions:Submit': {
    eng: 'Yes, delete',
    ru: 'Да, удалить',
    pt: 'Sim, excluir',
  },
  'SkillsWizard:DeleteSkillModal:DeleteManyText': {
    eng: 'Skills $[1] will be deleted along with the current skill',
    ru: 'Вместе с текущим навыком удалятся навыки $[1]',
    pt: 'As habilidades $[1] serão excluídas junto com a habilidade atual',
  },
  'SkillsWizard:DeleteSkillModal:DeleteOneText': {
    eng: 'The bot will lose the “$[1]” skill.<br /> You can add it again by clicking the button at the bottom of this page.',
    ru: 'Бот потеряет навык «$[1]».<br /> Вы сможете добавить его снова по кнопке внизу этой страницы.',
    pt: 'O bot perderá a habilidade “$[1]”.<br /> Você pode adicioná-la novamente clicando no botão na parte inferior desta página.',
  },
  'SkillsWizard:PreventDelete:Hit': {
    eng: 'It is impossible to delete a single parameter. To remove it, add a new one first.',
    ru: 'Удалить единственный параметр невозможно. Чтобы удалить этот параметр, сначала добавьте новый.',
    pt: 'É impossível excluir um parâmetro único. Para removê-lo, adicione um parâmetro primeiro.',
  },
  'SkillsWizard:GoToConstructorModal:Title': {
    eng: 'Switching to visual editor mode',
    ru: 'Переход в режим конструктора',
    pt: 'Alternando para o modo de editor visual',
  },
  'SkillsWizard:GoToConstructorModal:Content': {
    eng: 'This method is suitable for experienced users, it allows you to solve complex tasks. You will be able to customize the bot business logic using blocks and links. <a href="#{config.botadmin.helpUrl}/docs/en/visual-editor/quick-start/simple-bot">Learn more</a>',
    ru: 'Этот способ подойдет опытным пользователям, он позволяет решить сложные задачи. Вы сможете настроить логику бота с помощью отдельных блоков и связей. <a href="#{config.botadmin.helpUrl}/docs/ru/visual-editor/quick-start/simple-bot">Подробнее</a>',
    pt: 'Este método é adequado para usuários experientes, pois permite resolver tarefas complexas. Você poderá personalizar a lógica de negócios do bot usando blocos e links. <a href="#{config.botadmin.helpUrl}/docs/en/visual-editor/quick-start/simple-bot">Saiba mais</a>',
  },
  'SkillsWizard:GoToConstructorModal:BannerText': {
    eng: 'We will create a copy of the project and save all its settings. You only need to connect channels to the new bot.',
    ru: 'Мы создадим копию проекта, сохранив все настройки. Вам понадобится только подключить каналы к новому боту.',
    pt: 'Vamos criar uma cópia do projeto e salvar todas as suas configurações. Você só precisa conectar os canais ao novo bot.',
  },
  'SkillsWizard:GoToConstructorModal:Submit': {
    eng: 'Go to visual editor',
    ru: 'Перейти в конструктор',
    pt: 'Ir para o editor visual',
  },
  'FAQSkill:Fields:CustomQuestions:Add': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'OperatorSkill:OperatorSkill:Title': {
    eng: 'Transferring the dialog to an agent',
    ru: 'Перевод диалога на оператора',
    pt: 'Transferindo o diálogo para um agente',
  },
  'OperatorSkill:OperatorSkill:Description': {
    eng: 'The client can call an agent with messages such as “Agent”, “May I speak to the manager?”, and others.',
    ru: 'Клиент может вызвать оператора сообщениями «Оператор», «Позови оператора» и другими.',
    pt: 'O cliente pode ligar para um agente com mensagens como “Agente”, “Posso falar com o gerente?”, entre outras.',
  },
  'OperatorSkill:OperatorType:Title': {
    eng: 'How to transfer the dialog to the agent',
    ru: 'Как передавать диалог от бота оператору',
    pt: 'Como transferir a caixa de diálogo para o agente',
  },
  'OperatorSkill:OperatorType:Aimychat': {
    eng: 'Using Aimychat',
    ru: 'Через операторский чат Aimychat',
    pt: 'Usando Aimychat',
  },
  'OperatorSkill:OperatorType:Aimychat:Tovie': {
    eng: 'Using Tovie Agent',
    pt: 'Usando Tovie Agent',
  },
  'OperatorSkill:OperatorType:Aimychat:Description': {
    eng: 'This is our customer engagement platform. It is free to use. <a target="_blank" href="#{config.zenflow.helpUrl}/docs/en/publication_channels/agent-platform">Learn more</a>',
    ru: 'Это наш операторский чат. Вы можете использовать его бесплатно. <a target="_blank" href="#{config.zenflow.helpUrl}/docs/ru/publication_channels/agent-platform">Подробнее</a>',
    pt: 'Esta é a nossa plataforma de engajamento do cliente. É de uso gratuito. <a target="_blank" href="#{config.zenflow.helpUrl}/docs/en/publication_channels/agent-platform">Saiba mais</a>',
  },
  'OperatorSkill:OperatorType:Jivo': {
    eng: 'Using JivoChat',
    ru: 'Через сервис Jivo',
    pt: 'Usando o JivoChat',
  },
  'OperatorSkill:OperatorType:SendContacts': {
    eng: 'Send client contacts to a manager',
    ru: 'Отправить контакты клиента менеджеру',
    pt: 'Enviar contatos do cliente para um gerente',
  },
  'OperatorSkill:OperatorType:DontSendContacts': {
    eng: 'Do not transfer the dialog to an agent',
    ru: 'Не передавать диалог оператору',
    pt: 'Não transfirir o diálogo para um agente',
  },
  'OperatorSkill:OperatorsSettingsBlock:OperatorsAvailable:Title': {
    eng: 'Agents are available',
    ru: 'Операторы доступны',
    pt: 'Os agentes estão disponíveis',
  },
  'OperatorSkill:OperatorsSettingsBlock:OperatorsAvailable:Placeholder': {
    eng: 'I’m sorry I couldn’t help you. An agent will contact you shortly.',
    ru: 'Жаль, что я не смог вам помочь. В ближайшее время к вам подключится оператор.',
    pt: 'Lamento não poder ajudar. Um agente entrará em contato com você em breve.',
  },
  'OperatorSkill:OperatorsSettingsBlock:OperatorsIsHighLoaded:Title': {
    eng: 'No agents available',
    ru: 'Нет доступных операторов',
    pt: 'Nenhum agente disponível',
  },
  'OperatorSkill:OperatorsSettingsBlock:OperatorsIsHighLoaded:Placeholder': {
    eng: 'I’m sorry I couldn’t help you. I have forwarded your request, the first available agent will respond to you.',
    ru: 'Жаль, что я не смог вам помочь. Я передал ваше обращение, вам ответит первый доступный оператор.',
    pt: 'Lamento não poder ajudar. Encaminhei sua solicitação, o primeiro agente disponível responderá a você.',
  },
  'OperatorSkill:OperatorAimylogicBlock:Title': {
    eng: 'Connecting to Aimychat',
    ru: 'Подключение к Aimychat',
    pt: 'Conectando ao Aimychat',
  },
  'OperatorSkill:OperatorAimylogicBlock:Title:Tovie': {
    eng: 'Connecting to Tovie Agent',
    pt: 'Conectando ao Tovie Agent',
  },
  'OperatorSkill:OperatorAimylogicBlock:Description': {
    eng: 'We will create an agent workplace in Aimychat and connect your bot to it.',
    ru: 'Мы создадим рабочее место оператора в Aimychat и подключим к нему бота.',
    pt: 'Criaremos um local de trabalho do agente no Aimychat e conectaremos seu bot a ele.',
  },
  'OperatorSkill:OperatorAimylogicBlock:Description:Tovie': {
    eng: 'We will create an agent workplace in Tovie Agent and connect your bot to it.',
    pt: 'Criaremos um local de trabalho do agente no Tovie Agent e conectaremos seu bot a ele.',
  },
  'OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connect': {
    eng: 'Connect',
    ru: 'Подключить',
    pt: 'Conectar',
  },
  'OperatorSkill:OperatorAimylogicBlock:ConnectButton:Loading': {
    eng: 'Connecting',
    ru: 'Подключаем',
    pt: 'Conectando',
  },
  'OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connected': {
    eng: 'Connected',
    ru: 'Подключено',
    pt: 'Conectado',
  },
  'OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connected:LinkText': {
    eng: 'Go to Aimychat',
    ru: 'Перейти в Aimyсhat',
    pt: 'Acessar Aimychat',
  },
  'OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connected:LinkText:Tovie': {
    eng: 'Go to Tovie Agent',
    pt: 'Acessar Tovie Agent',
  },
  'OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connected:Link': {
    eng: 'https://app.aimychat.com/',
    ru: 'https://app.aimychat.com/',
    pt: 'https://app.aimychat.com/',
  },
  'OperatorSkill:OperatorAimylogicBlock:ConnectButton:Connected:Link:Tovie': {
    eng: 'https://agent.tovie.ai/',
    pt: 'https://agent.tovie.ai/en/',
  },
  'OperatorSkill:OperatorJivoBlock:ConnectButton:Title': {
    eng: 'Connecting to JivoChat',
    ru: 'Подключение к Jivo',
    pt: 'Conectando-se ao JivoChat',
  },
  'OperatorSkill:OperatorJivoBlock:ConnectButton:DescriptionFirst': {
    eng: '1. Generate and copy the token to continue the connection in JivoChat.',
    ru: '1. Сгенерируйте и скопируйте токен, чтобы продолжить подключение на стороне Jivo.',
    pt: '1. Gere e copie o token para continuar a conexão no JivoChat.',
  },
  'OperatorSkill:OperatorJivoBlock:ConnectButton:DescriptionLast': {
    eng: '2. Sign in to the service and open <a target="_blank" href="https://app.jivosite.com/settings/integrations/add-bot/4">the bot connection page</a>. Paste the token in the connection settings.',
    ru: '2. Войдите в сервис, откройте <a target="_blank" href="https://app.jivo.ru/settings/integrations/add-bot/4">страницу подключения бота</a>. Вставьте токен в настройках подключения.',
    pt: '2. Entre no serviço e abra <a target="_blank" href="https://app.jivosite.com/settings/integrations/add-bot/4">a página de conexão do bot</a>. Cole o token nas configurações de conexão.',
  },
  'OperatorSkill:OperatorJivoBlock:ConnectButton:DescriptionLast:Tovie': {
    eng: '2. Sign in to your personal account in JivoChat. Go to Manage → Extensions in the sidebar. On the Integrations tab, select Tovie DialogStudio. Paste the token in the connection settings.',
    pt: '2. Entre na sua conta pessoal no JivoChat. Acesse a Configurações → Extensões na barra lateral. Na guia Integrações, selecione Tovie DialogStudio. Cole o token nas configurações de conexão.',
  },
  'OperatorSkill:OperatorJivoBlock:ApproveTokenInfo:Title': {
    eng: 'Confirm the connection',
    ru: 'Подтвердите подключение',
    pt: 'Confirmar a conexão',
  },
  'OperatorSkill:OperatorJivoBlock:ApproveTokenInfo::Description': {
    eng: 'Please confirm the successful bot connection in JivoChat. If you have not been able to connect it yet, please select a different agent connection method.',
    ru: 'Подтвердите успешное подключение бота в Jivo. Если подключить еще не удалось, выберите другой способ подключения оператора.',
    pt: 'Confirme a conexão bem-sucedida do bot no JivoChat. Se você ainda não conseguiu conectá-lo, selecione um método de conexão de agente diferente.',
  },
  'OperatorSkill:OperatorJivoBlock:GenerateToken': {
    eng: 'Generate token',
    ru: 'Сгенерировать токен',
    pt: 'Gerar token',
  },
  'OperatorSkill:OperatorJivoBlock:CopyToken': {
    eng: 'Copy',
    ru: 'Копировать',
    pt: 'Copiar',
  },
  'OperatorSkill:OperatorJivoBlock:Connected': {
    eng: 'Connected',
    ru: 'Подключено',
    pt: 'Conectado',
  },
  'OperatorSkill:OperatorJivoBlock:connectedApprove': {
    eng: 'Yes, I have connected it',
    ru: 'Да, я подключил',
    pt: 'Sim, confirmo a conexão',
  },
  'OperatorSkill:WarningOperatorModal:Aimychat:Title': {
    eng: 'Connecting to Aimychat',
    ru: 'Подключение к Aimychat',
    pt: 'Conectando ao Aimychat',
  },
  'OperatorSkill:WarningOperatorModal:Aimychat:Title:Tovie': {
    eng: 'Connecting to Tovie Agent',
    pt: 'Conectando ao Tovie Agent',
  },
  'OperatorSkill:WarningOperatorModal:Aimychat:Description': {
    eng: 'Complete the connection to Aimychat or select a different agent connection method.',
    ru: 'Завершите подключение к Aimychat или выберите другой способ передачи диалога оператору.',
    pt: 'Conclua a conexão com o Aimychat ou selecione um método de conexão de agente diferente.',
  },
  'OperatorSkill:WarningOperatorModal:Aimychat:Description:Tovie': {
    eng: 'Complete the connection to Tovie Agent or select a different agent connection method.',
    pt: 'Conclua a conexão com o Tovie Agent ou selecione um método de conexão de agente diferente.',
  },
  'OperatorSkill:WarningOperatorModal:Cancel': {
    eng: 'Back to connection',
    ru: 'Вернуться к подключению',
    pt: 'Voltar à conexão',
  },
  'OperatorSkill:WarningOperatorModal:Aimychat:Submit': {
    eng: 'Connect Aimychat',
    ru: 'Подключить Aimychat',
    pt: 'Conectar Aimychat',
  },
  'OperatorSkill:WarningOperatorModal:Aimychat:Submit:Tovie': {
    eng: 'Connect Tovie Agent',
    pt: 'Conectar Tovie Agent',
  },
  'OperatorSkill:WarningOperatorModal:Jivo:Title': {
    eng: 'Confirm the connection to JivoChat',
    ru: 'Подтвердите подключение к Jivo',
    pt: 'Confirmar a conexão com o JivoChat',
  },
  'OperatorSkill:WarningOperatorModal:Jivo:Description': {
    eng: 'If you have not been able to connect it yet, please select a different agent connection method.',
    ru: 'Если подключить еще не удалось, выберите другой способ перевода диалога на оператора.',
    pt: 'Se você ainda não conseguiu conectá-lo, selecione um método de conexão de agente diferente.',
  },
  'OperatorSkill:WarningOperatorModal:Jivo:Submit': {
    eng: 'Yes, I have connected it',
    ru: 'Да, я подключил',
    pt: 'Sim, confirmo a conexão',
  },
  'OperatorSkill:WarningJivoGenerateTokenModal:Jivo:Title': {
    eng: 'Complete the connection to JivoChat',
    ru: 'Завершите подключение к Jivo',
    pt: 'Complete a conexão com o JivoChat',
  },
  'OperatorSkill:WarningJivoGenerateTokenModal:Jivo:Description': {
    eng: 'Generate a token to continue the connection in JivoChat or select a different agent connection method.',
    ru: 'Сгенерируйте токен, чтобы продолжить подключение на стороне Jivo, или выберите другой способ подключения оператора.',
    pt: 'Gere um token para continuar a conexão no JivoChat ou selecione um método diferente de conexão de agente.',
  },
  'OperatorSkill:WarningJivoGenerateTokenModal:Jivo:Submit': {
    eng: 'Back to connection',
    ru: 'Вернуться к подключению',
    pt: 'Voltar à conexão',
  },
  'OperatorSkill:BottomText': {
    eng: 'You can also add and configure a button for transferring the dialog to an agent in the “Bot menu” later.',
    ru: 'Далее на шаге «Меню бота» вы также сможете добавить и настроить кнопку вызова оператора.',
    pt: 'Você também pode adicionar e configurar um botão para transferir a caixa de diálogo para um agente no “Menu do bot” posteriormente.',
  },
  'OperatorSkill:Fields:CallOperatorAnswer:Title': {
    eng: 'Bot response to a call for an agent',
    ru: 'Ответ бота на вызов оператора',
    pt: 'Resposta do bot a uma chamada para um agente',
  },
  'OperatorSkill:Fields:CallOperatorAnswer:Placeholder': {
    eng: 'Unfortunately, I cannot connect you with an agent.',
    ru: 'К сожалению, я не могу связать вас с оператором.',
    pt: 'Infelizmente, não posso conectar você a um agente.',
  },
  'OperatorSkill:Fields:ClientInfoRequest:Title': {
    eng: 'Requesting information from the client',
    ru: 'Запрос информации у клиента',
    pt: 'Solicitando informações do cliente',
  },
  'OperatorSkill:Fields:ClientInfoRequest:Description': {
    eng: 'Use ready-made phrases or enter your own according to examples.',
    ru: 'Используйте готовые фразы или введите свои по примеру.',
    pt: 'Use frases prontas ou insira as suas próprias de acordo com os exemplos.',
  },
  'OperatorSkill:Fields:StartOrderText:Title': {
    eng: 'First message',
    ru: 'Начало оформления',
    pt: 'Primeira mensagem',
  },
  'OperatorSkill:Fields:StartOrderText:Placeholder': {
    eng: 'I’m sorry I couldn’t help you. Leave your contact details, and our manager will contact you shortly.',
    ru: 'Жаль, что я не смог вам помочь. Оставьте свои контактные данные, менеджер свяжется с вами.',
    pt: 'Lamento não poder ajudar. Deixe seus dados de contato e nosso gerente entrará em contato com você em breve.',
  },
  'OperatorSkill:Fields:OrderInfo:Columns:Name:Header': {
    eng: 'Information to request',
    ru: 'Что нужно узнать',
    pt: 'Informações a solicitar',
  },
  'OperatorSkill:Fields:OrderInfo:Columns:Name:Placeholder': {
    eng: 'Information to request',
    ru: 'Что нужно узнать',
    pt: 'Informações a solicitar',
  },
  'OperatorSkill:Fields:OrderInfo:SelectOptions:Name': {
    eng: 'Name',
    ru: 'Имя',
    pt: 'Nome',
  },
  'OperatorSkill:Fields:OrderInfo:SelectOptions:Phone': {
    eng: 'Phone number',
    ru: 'Телефон',
    pt: 'Número de telefone',
  },
  'OperatorSkill:Fields:OrderInfo:Columns:Value:Header': {
    eng: 'Bot phrase',
    ru: 'Фраза бота',
    pt: 'Frase de bot',
  },
  'OperatorSkill:Fields:OrderInfo:Name:Placeholder': {
    eng: 'Your name',
    ru: 'Ваше имя',
    pt: 'Seu nome',
  },
  'OperatorSkill:Fields:OrderInfo:Phone:Placeholder': {
    eng: 'Your phone number',
    ru: 'Ваш контактный телефон',
    pt: 'Seu número de telefone',
  },
  'OperatorSkill:Fields:OrderInfo:Placeholder': {
    eng: 'Bot phrase',
    ru: 'Фраза бота',
    pt: 'Frase de bot',
  },
  'OperatorSkill:Fields:OrderInfo:AddRowText': {
    eng: 'Add',
    ru: 'Добавить',
    pt: 'Adicionar',
  },
  'OperatorSkill:Fields:VerifyQuestionsText:Title': {
    eng: 'Information check',
    ru: 'Проверка данных',
    pt: 'Verificação de informações',
  },
  'OperatorSkill:Fields:VerifyQuestionsText:Placeholder': {
    eng: 'Please check the provided information. Is everything correct?',
    ru: 'Проверьте правильность введенных данных. Все верно?',
    pt: 'Verifique as informações fornecidas. Tudo certo?',
  },
  'OperatorSkill:Fields:VerifyQuestionsText:HelperText': {
    eng: 'The bot will send the collected information to the dialog. If the client finds an error in it, they can correct it.',
    ru: 'Бот отправит в диалог собранные данные. Если клиент найдет в них ошибку, он сможет ее исправить.',
    pt: 'O bot enviará as informações coletadas para a caixa de diálogo. Se o cliente encontrar algum erro, poderá corrigi-lo.',
  },
  'OperatorSkill:Fields:SubmitOrderText:Title': {
    eng: 'Confirmation',
    ru: 'Подтверждение',
    pt: 'Confirmação',
  },
  'OperatorSkill:Fields:SubmitOrderText:Placeholder': {
    eng: 'I have sent the information to the manager. You will be contacted soon.',
    ru: 'Я отправил информацию менеджеру. Скоро с вами свяжутся.',
    pt: 'Enviei as informações ao gerente. Em breve, entraremos em contato.',
  },
  'OperatorSkill:Fields:SendManagerInfo:Title': {
    eng: 'Sending information to the manager',
    ru: 'Отправка информации менеджеру',
    pt: 'Enviando informações ao gerente',
  },
  'OperatorSkill:Fields:SendManagerInfo:Description': {
    eng: 'The bot will send an SMS or email with the collected information to the manager.',
    ru: 'Бот отправит менеджеру SMS или электронное письмо с собранной информацией.',
    pt: 'O bot enviará um SMS ou e-mail com as informações coletadas para o gerente.',
  },
  'AnswerBlocks:invalid_url_format': {
    eng: 'Invalid URL format',
    ru: 'Некорректный формат URL',
    pt: 'Formato de URL inválido',
  },
  'AnswerBlocks:delete_block_title': {
    eng: 'Delete message',
    ru: 'Удалить',
    pt: 'Excluir mensagem',
  },
  'AnswerBlocks:delete_block_text': {
    eng: 'Are you sure you want to delete $[1]?',
    ru: 'Вы уверены, что хотите удалить $[1]?',
    pt: 'Tem certeza de que deseja excluir $[1]?',
  },
  'AnswerBlocks:type_message': {
    eng: 'Message',
    ru: 'Сообщение',
    pt: 'Mensagem',
  },
  'AnswerBlocks:AddButton:type_file': {
    eng: 'File',
    ru: 'Файл',
    pt: 'Arquivo',
  },
  'AnswerBlocks:AddButton:type_audio': {
    eng: 'Audio',
    ru: 'Аудио',
    pt: 'Áudio',
  },
  'AnswerBlocks:AddButton:type_text': {
    eng: 'Text',
    ru: 'Текст',
    pt: 'Texto',
  },
  'AnswerBlocks:AddButton:type_image': {
    eng: 'Image',
    ru: 'Изображение',
    pt: 'Imagem',
  },
  'AnswerBlocks:type_text': {
    eng: 'Text message',
    ru: 'Текстовое сообщение',
    pt: 'Mensagem de texto',
  },
  'AnswerBlocks:type_file': {
    eng: 'File',
    ru: 'Файл',
    pt: 'Arquivo',
  },
  'AnswerBlocks:type_audio': {
    eng: 'Audio',
    ru: 'Аудио',
    pt: 'Áudio',
  },
  'AnswerBlocks:type_image': {
    eng: 'Image',
    ru: 'Изображение',
    pt: 'Imagem',
  },
  'AnswerModal:title_image': {
    eng: 'Upload an image',
    ru: 'Загрузка изображения',
    pt: 'Carregar uma imagem',
  },
  'AnswerModal:title_audio': {
    eng: 'Upload an audio',
    ru: 'Загрузка аудио',
    pt: 'Carregar um áudio',
  },
  'AnswerModal:title_file': {
    eng: 'Upload a file',
    ru: 'Загрузка файла',
    pt: 'Carregar um arquivo',
  },
  'AnswerModal:subtext_file': {
    eng: 'Supported formats: XLSX, PDF',
    ru: 'Поддерживаемые форматы: XLSX, PDF',
    pt: 'Formatos suportados: XLSX, PDF',
  },
  'AnswerModal:subtext_audio': {
    eng: 'Supported formats: WAV, MP3',
    ru: 'Поддерживаемые форматы: WAV, MP3',
    pt: 'Formatos suportados: WAV, MP3',
  },
  'AnswerModal:info_audio': {
    eng: 'Make sure your audio file matches all <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">channel requirements</a>.',
    ru: 'Убедитесь, что ваш аудиофайл соответствует всем <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">требованиям канала</a>.',
    pt: 'Certifique-se de que seu arquivo de áudio corresponda a todos os <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">requisitos do canal</a>.',
  },
  'AnswerModal:info_file': {
    eng: 'Make sure your file matches all channel requirements.',
    ru: 'Убедитесь, что ваш файл соответствует всем требованиям канала.',
    pt: 'Certifique-se de que o arquivo corresponda a todos os requisitos do canal.',
  },
  'AnswerModal:info_image': {
    eng: 'Make sure your image matches all <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">channel requirements</a>.',
    ru: 'Убедитесь, что ваше изображение соответствует всем <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">требованиям канала</a>.',
    pt: 'Certifique-se de que sua imagem corresponda a todos os <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">requisitos do canal</a>.',
  },
  'AnswerModal:close_warning': {
    eng: 'Do not close this window until your file is uploaded',
    ru: 'Не закрывайте окно до конца загрузки',
    pt: 'Não feche esta janela até que seu arquivo seja carregado',
  },
  'AnswerBlocks:saving': {
    eng: 'Saving',
    ru: 'Сохранение',
    pt: 'Salvando',
  },
  'AnswerBlocks:question_tooltip': {
    eng: 'Learn more about how to correctly <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">formulate questions</a>',
    ru: 'Подробнее о том, как правильно <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">формулировать вопросы</a>',
    pt: 'Saiba mais sobre como <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">formular perguntas corretamente</a>',
  },
  'AnswerBlocks:answer_tooltip': {
    eng: 'Make sure the file you select is supported by the channel you are using. <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">Learn more</a>',
    ru: 'Убедитесь, что выбранный тип ответа поддерживается каналом, который вы используете. <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">Подробнее</a>',
    pt: 'Certifique-se de que o arquivo selecionado seja compatível com o canal que você está usando. <a href="$[1]" rel="noopener noreferrer" target="_blank" tabindex="-1">Saiba mais</a>',
  },
  'AnswerBlocks:answer_media_date': {
    eng: '$[1]',
    ru: '$[1]',
    pt: '$[1]',
  },
  'AnswerSettings:HtmlEnabler': {
    eng: 'Text formatting',
    ru: 'Форматирование текста',
    pt: 'Formatação de texto',
  },
  'AnswerBlocks:delete_block_submit': {
    eng: 'Delete',
    ru: 'Удалить',
    pt: 'Excluir',
  },
  'AnswersPartModal:upload-file': {
    eng: 'Upload',
    ru: 'Загрузить',
    pt: 'Carregar',
  },
  'AudioSettings:compatibleChannelsTitle': {
    eng: 'Supported in',
    ru: 'Поддерживается в',
    pt: 'Suportado em',
  },
  'AudioSettings:incompatibleChannelsTitle': {
    eng: 'Not supported in',
    ru: 'Не поддерживается в',
    pt: 'Não suportado em',
  },
  'AnswerBlocks:max_answers': {
    eng: 'The maximum number of messages in a reply is 20.',
    ru: 'Максимум сообщений в ответе – 20.',
    pt: 'O número máximo de mensagens em uma resposta é 20.',
  },
  'AudioUploadField:Error:FileSize': {
    eng: 'File size cannot exceed 10 MB.',
    ru: 'Размер файла не должен превышать 10 Мбайт.',
    pt: 'O tamanho do arquivo não pode exceder 10 MB',
  },
  'FileUploadField:Error:InvalidFileExtension': {
    eng: 'Invalid file extension. Supported file formats: .xlsx or .pdf.',
    ru: 'Неверное расширение файла. Поддерживаемые форматы: .xlsx или .pdf.',
    pt: 'Extensão de arquivo inválida. Formatos de arquivo suportados: .xlsx ou .pdf.',
  },
  'AudioUploadField:Error:InvalidImageExtension': {
    eng: 'Invalid file extension. Supported file formats: .gif, .png, or .jpeg.',
    ru: 'Неверное расширение файла. Поддерживаемые форматы: .gif, .png или .jpeg.',
    pt: 'Extensão de arquivo inválida. Formatos de arquivo suportados: .gif, .png ou .jpeg.',
  },
  'AnswersPartModal:InsertLink': {
    eng: 'Insert link',
    ru: 'Вставить ссылку',
    pt: 'Inserir link',
  },
  'AudioSettings:urlHint': {
    eng: 'Link in the format https://example.com/photo.jpg',
    ru: 'Ссылка в формате https://example.com/photo.jpg',
    pt: 'Link no formato https://example.com/photo.jpg',
  },
  'AutoMessageSequence:MessageTitle': {
    eng: 'Message #$[1]',
    ru: 'Сообщение #$[1]',
    pt: 'Mensagem #$[1]',
  },
  'AutoMessageSequence:Skill:title': {
    eng: 'Automatic messaging',
    ru: 'Автоворонка',
    pt: 'Mensagens automáticas',
  },
  'AutoMessageSequence:Skill:Description': {
    eng: 'Set up a schedule for sending messages to the client. Delayed messages can be sent after at least 30 seconds after the previous message.',
    ru: 'Настройте расписание отправки сообщений клиенту. Сообщения с задержкой можно отправить не менее чем через 30 секунд после предыдущего.',
    pt: 'Configure um cronograma para enviar mensagens para o cliente. Mensagens atrasadas podem ser enviadas depois de, pelo menos, 30 segundos da mensagem anterior.',
  },
  'AutoMessageSequence:Skill:ShortDescription': {
    eng: 'The bot will send the client scheduled messages with useful information.',
    ru: 'Бот отправит клиенту полезную информацию по расписанию.',
    pt: 'O bot enviará ao cliente mensagens agendadas com informações úteis.',
  },
  'AutomessageSequence:RadioTitle:send': {
    eng: 'Send:',
    ru: 'Отправить:',
    pt: 'Enviar:',
  },
  'AutomessageSequence:Radio:Immediately': {
    eng: 'Right after the previous one',
    ru: 'Сразу после предыдущего',
    pt: 'Logo após o anterior',
  },
  'AutomessageSequence:Radio:SelectTime': {
    eng: 'After a period of time',
    ru: 'Через определенное время',
    pt: 'Após um período de tempo',
  },
  'AutomessageSequence:Radio:SelectTime:OneLetter:day': {
    eng: 'd',
    ru: 'д',
    pt: 'd',
  },
  'AutomessageSequence:Radio:SelectTime:OneLetter:hour': {
    eng: 'h',
    ru: 'ч',
    pt: 'h',
  },
  'AutomessageSequence:Radio:SelectTime:OneLetter:minute': {
    eng: 'm',
    ru: 'м',
    pt: 'm',
  },
  'AutomessageSequence:Radio:SelectTime:OneLetter:second': {
    eng: 's',
    ru: 'с',
    pt: 's',
  },
  'BaseInfoSkill:SkillName': {
    eng: 'General phrases',
    ru: 'Общие фразы бота',
    pt: 'Frases gerais',
  },
  'BaseInfoSkill:Description': {
    eng: 'Use ready-made phrases or enter your own according to examples.',
    ru: 'Используйте готовые фразы или введите свои.',
    pt: 'Use frases prontas ou insira as suas próprias de acordo com os exemplos.',
  },
  'BaseInfoSkill:Fields:Greeting:Title': {
    eng: 'Greeting',
    ru: 'Приветствие',
    pt: 'Saudações',
  },
  'BaseInfoSkill:Fields:Greeting:Placeholder': {
    eng: 'Welcome to our store!',
    ru: 'Приветствуем в нашем магазине!',
    pt: 'Boas-vindas à nossa loja!',
  },
  'BaseInfoSkill:Fields:HelpText:Title': {
    eng: 'Dialog start',
    ru: 'Приглашение к диалогу',
    pt: 'Início do diálogo',
  },
  'BaseInfoSkill:Fields:HelpText:Placeholder': {
    eng: 'How can I help you?',
    ru: 'Могу вам чем-то помочь?',
    pt: 'Como posso ajudar você?',
  },
  'BaseInfoSkill:Fields:HelpText:HelperText': {
    eng: 'Bot sends this phrase at the beginning of the dialog and each time it completes one task.',
    ru: 'Бот отправляет эту фразу в начале диалога и каждый раз, когда выполняет одну задачу клиента.',
    pt: 'O bot envia esta frase no início do diálogo e cada vez que conclui uma tarefa.',
  },
  'BaseInfoSkill:Fields:NoMatch:Title': {
    eng: 'Bot response to unrecognized client messages',
    ru: 'Реакция бота на непонятную фразу',
    pt: 'Resposta do bot a mensagens de cliente não reconhecidas',
  },
  'BaseInfoSkill:Fields:NoMatch:Placeholder': {
    eng: 'I’m sorry, I did not understand you. Please reformulate your query.',
    ru: 'Извините, я вас не понял. Пожалуйста, перефразируйте.',
    pt: 'Lamento, mas não entendi. Reformule sua consulta.',
  },
  'BaseInfoSkill:Fields:FinalPhrase:Title': {
    eng: 'Dialog end',
    ru: 'Завершение диалога',
    pt: 'Final do diálogo',
  },
  'BaseInfoSkill:Fields:FinalPhrase:Placeholder': {
    eng: 'I’m glad I could help. If you have any more questions, please do not hesitate to ask. Have a good day!',
    ru: 'Рад был помочь! Если будут еще вопросы, обращайтесь. Хорошего дня!',
    pt: 'Fico feliz em poder ajudar. Se você tiver mais perguntas, não hesite em perguntar. Tenha um bom dia!',
  },
  'FAQ:importModal:success': {
    eng: 'File uploaded',
    ru: 'Файл загружен',
    pt: 'Arquivo enviado',
  },
  'AutomessageSequence:noMatch:title': {
    eng: 'Bot response to unrecognized client messages',
    ru: 'Реакция бота на непонятную фразу',
    pt: 'Resposta do bot a mensagens de cliente não reconhecidas',
  },
  'AutomessageSequence:noMatch:placeholder': {
    eng: 'Unfortunately, I won’t be able to help you. If you have any questions, please email us at shop@example.com.',
    ru: 'К сожалению, я не смогу вам помочь. Если у вас остались вопросы, напишите нам на почту shop@example.com.',
    pt: 'Infelizmente, não poderei ajudar. Em caso de dúvida, envie um e-mail para shop@example.com.',
  },
  'AutomessageSequence:footer:text': {
    eng: 'The client can unsubscribe at any time. The bot will stop sending messages after messages such as “Stop”, “Unsubscribe”, and others.',
    ru: 'Клиент сможет отказаться от рассылки в любой момент. Бот остановит рассылку после сообщений «Стоп», «Отписаться» и других.',
    pt: 'O cliente pode cancelar a assinatura a qualquer momento. O bot vai parar de enviar mensagens após mensagens como “Parar”, “Cancelar assinatura” e outras.',
  },
  'ChannelsStep:Title': {
    eng: 'Channels of communication with clients',
    ru: 'Каналы общения с клиентами',
    pt: 'Canais de comunicação com os clientes',
  },
  'ChannelsStep:ChatWidget:SettingsButton:Hint': {
    eng: 'You can customize the widget after the bot is created',
    ru: 'Настроить внешний вид виджета вы сможете после завершения создания бота',
    pt: 'Você pode personalizar o widget após a criação do bot',
  },
};
