import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TemplateWizardState {
  value: number;
}
const initialState: TemplateWizardState = {
  value: 0,
};

export const templateWizardSlice = createSlice({
  name: 'templateWizard',
  initialState,
  reducers: {
    increment: state => {
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
  },
});

export const { increment, decrement, incrementByAmount } = templateWizardSlice.actions;

export default templateWizardSlice.reducer;
