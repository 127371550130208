import React from 'react';

export const STATE = {
  hidden: 0,
  inProcess: 1,
  shown: 2,
};

export const DEFAULT_AVATARS = {
  user: '/img/operator_avatar.svg',
  assitant: '/img/bot_avatar.svg',
  operator: '/img/user_avatar.svg',
};

export const THEMES = [
  //blue
  {
    headlineBackgroundColor: '#5A9CED',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#CBDFF8',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#5A9CED',
    formTextColor: '#000',
  },
  //pink
  {
    headlineBackgroundColor: '#EAC5D9',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#F9EEF4',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#EAC5D9',
    formTextColor: '#000',
  },
  //orange
  {
    headlineBackgroundColor: '#FF8427',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#FFDBBF',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#FF8427',
    formTextColor: '#000',
  },
  //фуксия, наверно
  {
    headlineBackgroundColor: '#E13A91',
    headlineTextColor: 'light',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#F6C4DE',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#E13A91',
    formTextColor: '#000',
  },
  //green
  {
    headlineBackgroundColor: '#3AB795',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#C4EAE0',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#3AB795',
    formTextColor: '#000',
  },
  //green-light
  {
    headlineBackgroundColor: '#A8C256',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#E5EDCD',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#A8C256',
    formTextColor: '#000',
  },
  //yellow
  {
    headlineBackgroundColor: '#FFC300',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#FFEDB3',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#FFC300',
    formTextColor: '#000',
  },
  //red
  {
    headlineBackgroundColor: '#D0262F',
    headlineTextColor: 'light',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#F1BEC1',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#D0262F',
    formTextColor: '#000',
  },
  //purple
  {
    headlineBackgroundColor: '#B2ABF2',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#E8E6FC',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#B2ABF2',
    formTextColor: '#000',
  },
  //bright-blue
  {
    headlineBackgroundColor: '#56CFFF',
    headlineTextColor: 'dark',
    chatBackgroundColor: '#FFFFFF',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#CDF1FF',
    userMessageTextColor: 'dark',
    botMessageBackgroundColor: '#F4F5F5',
    botMessageTextColor: 'dark',
    formButtonsColor: '#56CFFF',
    formTextColor: '#000',
  },
  //dark-green
  {
    headlineBackgroundColor: '#095256',
    headlineTextColor: 'light',
    chatBackgroundColor: '#1F282D',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#19353A',
    userMessageTextColor: 'light',
    botMessageBackgroundColor: '#273238',
    botMessageTextColor: 'light',
    formButtonsColor: '#095256',
    formTextColor: '#fff',
  },
  //dark-blue
  {
    headlineBackgroundColor: '#0C4767',
    headlineTextColor: 'light',
    chatBackgroundColor: '#1F282D',
    chatBackgroundOpacity: 100,
    userMessageBackgroundColor: '#1A313F',
    userMessageTextColor: 'light',
    botMessageBackgroundColor: '#273238',
    botMessageTextColor: 'light',
    formButtonsColor: '#0C4767',
    formTextColor: '#fff',
  },
];

export const BASE_WIDGET_OPTIONS = {
  showLogo: false,
  logo: '',
  channel: null,
  percentage: 100,
  copySource: null,
  headline: {
    show: false,
    text: '',
  },
  names: {
    show: false,
    assistantName: '',
    userName: '',
  },
  sendBtn: {
    show: false,
  },
  blockForm: {
    show: false,
  },
  avatar: {
    show: false,
    assistantAvatarUrl: '',
    userAvatarUrl: '',
    operatorAvatarUrl: '',
    size: 24,
  },
  linkPreview: {
    show: true,
  },
  captions: {
    inputPlaceholder: '',
  },
  fileUpload: {
    show: false,
    uploadErrorMessage: '',
    tooBigFileMessage: '',
  },
  position: 'right',
  involvement: {
    enabled: false,
    events: [],
  },
  bubble: {
    show: false,
    delay: 10,
    bubbletext: '',
    mobileBubbletext: '',
  },
  sessionByUserMessage: {
    show: false,
    welcomemessage: '',
    delay: 0, //FIXME 100 секунд??? в виджете это 100мс. сломаный конфиг,
    buttonsShow: false,
    buttons: [],
  },
  positionOffset: {
    bottom: 24,
    right: 24,
  },
  palette: { ...THEMES[0] },
  font: 'Roboto',
  sizes: {
    font: 'small',
    avatar: 'big',
  },
  buttonsSamePlace: false,
  blinkOnNewMessage: true,
  soundNotification: {
    option: 'none', //['client_bot', 'bot', 'client', 'none']
    sound: '',
  },
  embedmentContainer: {
    id: '',
    enabled: false,
  },
  showCloseButton: false,
  showSpinner: true,
};

export const SETTINGS_KEYS = [
  'sessionByUserMessage',
  'bubble',
  'involvement',
  'position',
  'fileUpload',
  'captions',
  'rollout',
  'showLogo',
  'logo',
  'channel',
  'percentage',
  'headline',
  'names',
  'blockForm',
  'sendBtn',
  'avatar',
  'linkPreview',
  'positionOffset',
  'palette',
  'font',
  'sizes',
  'blinkOnNewMessage',
  'buttonsSamePlace',
  'soundNotification',
  'embedmentContainer',
  'sessionInfoWebhook',
  'showCloseButton',
  'showSpinner',
];
export const FONT_SIZE_OPTIONS = [
  {
    value: 'small',
    label: <span style={{ fontSize: 14 }}>Aa</span>,
  },
  {
    value: 'medium',
    label: <span style={{ fontSize: 18 }}>Aa</span>,
  },
  {
    value: 'big',
    label: <span style={{ fontSize: 20 }}>Aa</span>,
  },
];
export const AVATAR_SIZE_OPTIONS = [
  {
    value: 'small',
    label: 24,
  },
  {
    value: 'medium',
    label: 32,
  },
  {
    value: 'big',
    label: 40,
  },
];

export const FONTS = ['Roboto', 'Arial', 'Georgia', 'Tahoma', 'Helvetica', 'Verdana', 'Trebuchet MS'];
