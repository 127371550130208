import React, { useCallback } from 'react';
import cn from 'classnames';
import { Icon, Button, useId, JustSelect } from '@just-ai/just-ui';

import { t } from 'localization';

import TextareaAutosize from 'modules/TemplatesWizard/primitives/TextareaAutosize';
import WizardTooltip from 'modules/TemplatesWizard/primitives/WizardTooltip';

import { FAQQuestionsRow, FAQQuestionsSuggest } from '../index';
import styles from '../styles.module.scss';

interface FieldsCollectionsProps {
  row: FAQQuestionsRow & { index: number; suggests?: FAQQuestionsSuggest[] };
  answerPlaceholder?: string;
  questionPlaceholder?: string;
  onDelete: (index: number) => void;
  onChange: (index: number, value: string, name: string) => void;
  deleteDisable?: boolean;
}
const FieldsCollections = ({
  row,
  onDelete,
  onChange,
  questionPlaceholder,
  answerPlaceholder,
  deleteDisable,
}: FieldsCollectionsProps) => {
  const tooltipId = useId();
  const onDeleteInner = useCallback(() => {
    onDelete(row.index);
  }, [onDelete, row.index]);

  const answerPlaceholderInner = row.suggests
    ? row.suggests.find(suggest => suggest.question === row.question)?.answerPlaceholder
    : answerPlaceholder;

  return (
    <div className={styles.row}>
      <div className={styles.row__fields}>
        <div className={styles.field}>
          {row.suggests ? (
            <JustSelect
              className={styles.dropDown}
              onChange={val => {
                const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
                onChange(row.index, oneEl.toString(), 'question');
              }}
              options={row.suggests.map(option => ({
                value: option.question,
                label: option.question,
              }))}
              value={row.question || ''}
              position='fixed'
            />
          ) : (
            <TextareaAutosize
              className={styles.textArea}
              value={row.question || ''}
              rowsMin={1}
              rowsMax={20}
              maxLength={500}
              placeholder={questionPlaceholder}
              bsSize='md'
              onChange={val => onChange(row.index, val, 'question')}
            />
          )}
        </div>
        <div className={styles.field}>
          <TextareaAutosize
            className={styles.textArea}
            value={row.answer || ''}
            rowsMin={1}
            rowsMax={20}
            maxLength={500}
            placeholder={answerPlaceholderInner}
            bsSize='md'
            onChange={val => onChange(row.index, val, 'answer')}
          />
        </div>
      </div>
      <div
        id={tooltipId}
        className={cn({
          preventPointerEvents: deleteDisable,
        })}
      >
        <Button
          className={styles.deleteBtn}
          type='button'
          color='secondary'
          disabled={deleteDisable}
          size='md'
          withoutPadding
          flat
          onClick={onDeleteInner}
        >
          <Icon name='farTrashAlt' />
        </Button>
      </div>
      {deleteDisable && (
        <WizardTooltip trigger='hover' placement='top' target={tooltipId}>
          {t('FAQSkill:DeleteLastQuestion:Tooltip')}
        </WizardTooltip>
      )}
    </div>
  );
};

export default React.memo(FieldsCollections);
