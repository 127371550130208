import { t } from 'localization';

import { FAQSkillConfig, SkillType, RealSkill } from '../types';

export const faqSkillConfig: () => FAQSkillConfig = () => ({
  type: SkillType.FAQ,
  skillName: RealSkill.FAQ,
  title: t('FaqSkill:Title'),
  description: '',
  shortDescription: t('FaqSkill:ShortDescription'),
  withBaseInfo: true,
  withOperator: true,
  withMenu: true,
  orderWeight: 5,
  skillVersion: 1,
  startState: '',
  language: '',
  menuType: 'question',
  questionPath: ['static[*].question', 'custom[*].question'],
});
