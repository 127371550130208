import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './DeletingIcons.scss';

class DeletingIcon extends PureComponent {
  state = {
    wasPending: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.pending && prevProps.pending) {
      this.setState({
        wasPending: true,
      });
    }
  }

  animationEnd = e => {
    e.stopPropagation();
    this.props.onAnimationEnd();
  };

  render() {
    const { pending } = this.props;
    const { wasPending } = this.state;

    return (
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={classNames(
          'deletingIcon',
          {
            process: pending && !wasPending,
          },
          {
            finished: wasPending,
          }
        )}
        onAnimationEnd={this.animationEnd}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.11111 4.00011L8.11111 7.889L7 9.00011L7 14.5557L8.11111 15.6668V19.5557H10.3333V4.00011H8.11111Z'
          fill='#7AB2DA'
          className='hat'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.1111 28.7777C12.1111 30.005 13.106 30.9999 14.3333 30.9999H23.2222C24.4495 30.9999 25.4444 30.005 25.4444 28.7777V15.4443H12.1111V28.7777Z'
          fill='#7AB2DA'
        />
        {pending && (
          <g className='circles'>
            <circle cx='15.5' cy='1.5' r='1.5' fill='#7AB2DA' />
            <circle cx='22.5' cy='1.5' r='1.5' fill='#7AB2DA' />
          </g>
        )}
      </svg>
    );
  }
}
export default DeletingIcon;
