import React, { useCallback, useEffect, useMemo } from 'react';
import { Collapse } from 'reactstrap';
import { Button, Icon } from '@just-ai/just-ui';
import cn from 'classnames';
import { useForceUpdate } from '@just-ai/just-ui/dist/utils';

import { t } from 'localization';
import { BaseSkill } from 'modules/TemplatesWizard/skillClasses/BaseSkill';

import { BaseSkillConfig } from '../../types';
import WizardTooltip from '../WizardTooltip';
import styles from './styles.module.scss';

const emptyValidate = () => true;

interface SkillCollapseContainerProps {
  skill: BaseSkill;
  index: number;
  children: React.ReactNode;
  wrapperClassName?: string;
  onValidate?: () => boolean;
}
const SkillCollapseContainer = ({
  skill,
  index,
  children,
  onValidate = emptyValidate,
  wrapperClassName,
}: SkillCollapseContainerProps) => {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    skill.onMount();
    return skill.subscribe('forceUpdate', forceUpdate);
  }, [forceUpdate, skill]);
  const onToggleInner = useCallback(() => {
    if (!skill.isOpened) {
      skill.notify('onToggle');
      skill.isDone = true;
      return;
    }
    if (!onValidate()) {
      skill.isDone = false;
      return;
    }
    skill.notify('onToggle');
  }, [onValidate, skill]);
  const onBack = useCallback(() => skill.notify('onNext', -1), [skill]);
  const onNext = useCallback(
    () => (onValidate() ? skill.notify('onNext', 1) : (skill.isDone = false)),
    [onValidate, skill]
  );
  const onDone = useCallback(() => {
    const isValid = onValidate();
    skill.isDone = isValid;
    if (isValid) {
      skill.notify('onToggle');
    }
  }, [onValidate, skill]);
  const onDelete = useCallback(() => skill.notify('onDeleteSkill'), [skill]);

  const skillName = useMemo(
    () =>
      skill.isRealSkill
        ? t(`SkillsWizard:SkillPrefix`, (skill.skillConfig as BaseSkillConfig).title)
        : (skill.skillConfig as BaseSkillConfig).title,
    [skill]
  );

  const skillDeleteId = `delete-skill-${skill.skillConfig.skillName}`;
  return (
    <div
      data-skill-name={skill.skillConfig.skillName}
      className={cn(styles.SkillCollapseContainer, {
        [styles.SkillCollapseContainer_opened]: skill.isOpened,
        [styles.isPostSetup]: skill.isPostSetup,
      })}
    >
      <div className={styles.SkillCollapseContainer__header} onClick={onToggleInner}>
        <div className={styles.SkillCollapseContainer__header__name}>
          <Icon name='faChevronRight' wrapperClassName={styles.icon} />
          <h1>{`${index + 1}/${skill.otherSkills.length} ${skillName}`}</h1>
        </div>
        {!skill.isPostSetup && <Icon name='farCheckDouble' size='lg' color={skill.isDone ? 'success' : 'secondary'} />}
      </div>
      <Collapse isOpen={skill.isOpened} className={cn(styles.SkillCollapseContainer__content)}>
        <div className={wrapperClassName}>{children}</div>

        <div className={styles.bottomButtons}>
          <div className={styles.bottomButtons__buttonsGroup}>
            {!skill.isPostSetup && (
              <>
                {index > 0 && (
                  <Button data-test-id='Skill:Actions:Back' color='secondary' flat outline onClick={onBack}>
                    {t('FAQSkill:Actions:Back')}
                  </Button>
                )}
                {index + 1 < skill.otherSkills.length ? (
                  <Button data-test-id='Skill:Actions:Forward' color='primary' onClick={onNext}>
                    {t('FAQSkill:Actions:Forward')}
                  </Button>
                ) : (
                  <Button data-test-id='Skill:Actions:Done' color='success' onClick={onDone}>
                    {t('FAQSkill:Actions:Done')}
                  </Button>
                )}
              </>
            )}
          </div>

          {skill.skillConfig.denySkills !== '*' && (
            <>
              {skill.isRealSkill && (
                <div id={skillDeleteId}>
                  <Button
                    color='secondary'
                    disabled={skill.isLast}
                    flat
                    onClick={onDelete}
                    className={styles.bottomButtons__buttonWithIcon}
                    data-test-id='Skill:Actions:DeleteSkill'
                  >
                    <Icon name='farTrashAlt' color='secondary' style={{ opacity: skill.isLast ? 0.5 : 1 }} />
                    <span>{t('FAQSkill:Actions:DeleteSkill')}</span>
                  </Button>
                </div>
              )}
              {skill.isRealSkill && skill.isLast && (
                <WizardTooltip
                  data-test-id='Skill:Actions:DeleteSkill:Tooltip'
                  trigger='hover'
                  placement='bottom-start'
                  target={skillDeleteId}
                >
                  {t('FAQSkill:Actions:DeleteSkill:Tooltip')}
                </WizardTooltip>
              )}
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default SkillCollapseContainer;
