import * as constants from '../../constants/catchAllSettings.actions';

import { axios } from '../../pipes/functions';
import { SAVE_AUDIOS_TO_SERVER } from '../../components/visualeditor/visualEditorConstants';

export const loadSettings = projectShortName => {
  return {
    type: constants.LOAD_CATCH_ALL_SETTINGS,
    payload: axios.get(`/restapi/content/${projectShortName}/file?filePath=src/params.yaml`),
  };
};

export const loadVariables = projectShortName => {
  return {
    type: constants.LOAD_VARIABLES,
    payload: axios.get(`/restapi/content/${projectShortName}/file?filePath=src/variables.yaml`),
  };
};
export const loadVariablesV2 = templateName => {
  return {
    type: constants.LOAD_VARIABLES,
    payload: axios.get(`/restapi/bottemplates/variables/${templateName}`),
  };
};

export const loadErrorMessages = projectShortName => {
  return {
    type: constants.LOAD_CATCH_ALL_ERROR_MESSAGES,
    payload: axios.get(`/restapi/content/${projectShortName}/onError`),
  };
};

export const saveErrorMessages = (projectShortName, messages) => {
  const params = messages.join('&message=');
  return {
    type: constants.SAVE_CATCH_ALL_ERROR_MESSAGES,
    payload: axios.post(`/restapi/content/${projectShortName}/onError?message=${params}`),
  };
};

export const saveSettings = (projectShortName, obj) => {
  return {
    type: constants.SAVE_CATCH_ALL_SETTINGS,
    payload: axios.post(`/restapi/content/${projectShortName}/file`, obj),
  };
};

export const loadSimpleWidgetChannel = projectShortName => {
  return {
    type: constants.LOAD_SIMPLE_WIDGET_CHANNEL,
    payload: axios.get(`/restapi/botconfig/testwidget/${projectShortName}`),
  };
};
export const loadVoiceWidgetChannel = projectShortName => {
  return {
    type: constants.LOAD_VOICE_WIDGET_CHANNEL,
    payload: axios.get(`/restapi/botconfig/voicetestwidget/${projectShortName}`),
  };
};

export const saveAudiosToServer = (files, config) => {
  let fd = new FormData();
  for (let i = 0; i < files.length; i += 1) {
    fd.append('files', files[i]);
  }
  return {
    type: SAVE_AUDIOS_TO_SERVER,
    payload: axios.post('/restapi/file/upload/audios', fd, {
      ...config,
    }),
  };
};
