import React, { FC } from 'react';
import { BotImage, UserImage } from './icons';
import { t } from 'localization';
import { Button, Buttons } from './Buttons';

type ResponseMessageType = { text: string; buttons?: Button[] };

export type MessageType = {
  id: string | number;
  request?: string;
  response?: ResponseMessageType;
  system?: string;
  active?: boolean;
};

export const Message: FC<MessageType> = React.memo(({ request, response, system, id, active }) => {
  if (system)
    return (
      <div className='justwidget--system-message'>
        <span>{system}</span>
      </div>
    );

  return (
    <>
      <div
        className={`justwidget--message ${
          request ? 'justwidget--message_user' : 'justwidget--message_asst'
        } justwidget--message_cell`}
        data-test-id='Justwidget.Message'
      >
        {response && (
          <div className='justwidget--avatar'>
            <div className='justwidget--image'>
              <BotImage />
            </div>
          </div>
        )}
        <div
          className={`justwidget--message_content ${active ? 'active' : ''}`}
          data-test-id='Justwidget.Message.Content'
          id={String(id)}
        >
          <div className='justwidget--username'>
            {response ? (active ? t('PseudoWidget:BotMessageTitleEditing') : t('PseudoWidget:BotMessageTitle')) : null}
            {request && t('PseudoWidget:UserMessageTitle')}
          </div>
          <div className='justwidget--lineHeightNormal' data-test-id='Justwidget.Message.Text'>
            {request || response?.text}
          </div>
          <span className='justwidget--message--date'>15:59</span>
        </div>
        {request && (
          <div className='justwidget--avatar'>
            <div className='justwidget--image'>
              <UserImage />
            </div>
          </div>
        )}
      </div>

      <Buttons buttons={response?.buttons} />
    </>
  );
});
