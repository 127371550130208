import React, { FC, useCallback } from 'react';

import { AnswerBlockProps } from '@just-ai/just-ui/dist/RichTextEditor';
import { t } from 'localization';
import { withTranslation } from '@just-ai/just-ui/dist/contexts/transtationContext';
import { saveAudioToServer, saveFileToServer } from '../../../../../pipes/axiosFunctions';

import { answerPartsToReplies, validateFile } from './utils';
import { Button, DragHandler, RadioButton, RichTextEditor } from '@just-ai/just-ui';
import { AnswerReplyType } from '@just-ai/just-ui/dist/RichTextEditor/types';
import classes from './AutomessageSequence.module.scss';
import { TimePicker } from './TimePicker';
import { MessageType, useAutomessageSequenceContext } from './types';
import cn from 'classnames';

const AnswerBlockWithTranslation = withTranslation(t)(RichTextEditor);

export const MessageContainer: FC<{
  messageIndex: number;
  message: MessageType;
  showTimeSelector?: boolean;
  controlsEnabled?: boolean;
}> = ({ messageIndex, message, showTimeSelector = true, controlsEnabled = false }) => {
  const { onChange, deleteMessageItem } = useAutomessageSequenceContext();
  const onUpdateReplies = useCallback(
    replies => {
      const newReplies = answerPartsToReplies(replies);
      onChange(messageIndex - 1, { replies: newReplies });
    },
    [messageIndex, onChange]
  );

  const deleteMessageHandler = useCallback(() => {
    deleteMessageItem(messageIndex - 1);
  }, [deleteMessageItem, messageIndex]);

  const onChangeWhenHandler = useCallback(
    value => {
      onChange(messageIndex - 1, { when: value });
    },
    [messageIndex, onChange]
  );

  return (
    <div className={classes.MessageContainer}>
      <div className='d-flex justify-content-between align-items-center padding-bottom-3x border-bottom-1px-blue-200'>
        <div className='d-flex align-items-center'>
          <DragHandler
            className={cn('padding-3', { 'gray-300': !controlsEnabled, 'cursor-pointer': controlsEnabled })}
          />

          <b className='margin-left-2x'>{t('AutoMessageSequence:MessageTitle', messageIndex)}</b>
        </div>
        <Button
          disabled={!controlsEnabled}
          color='secondary'
          iconLeft='farTrashAlt'
          flat
          size='sm'
          onClick={deleteMessageHandler}
        >
          {t('Delete')}
        </Button>
      </div>
      <div className='d-flex flex-column padding-left-2x padding-right-2x padding-bottom-1x gap-3x'>
        {showTimeSelector && (
          <>
            <p className='mb-0'>{t('AutomessageSequence:RadioTitle:send')}</p>
            <div className={classes.MessageContainer_radio}>
              <RadioButton
                label={t('AutomessageSequence:Radio:Immediately')}
                name={`timeout${message.uuid}`}
                value='afterPrevious'
                checked={message.when === 'afterPrevious'}
                onChange={onChangeWhenHandler}
              />
              <RadioButton
                label={t('AutomessageSequence:Radio:SelectTime')}
                name={`timeout${message.uuid}`}
                value='afterTime'
                checked={message.when === 'afterTime'}
                onChange={onChangeWhenHandler}
              />
              <TimePicker
                time={message.time}
                messageIndex={messageIndex - 1}
                disabled={message.when === 'afterPrevious'}
              />
            </div>
          </>
        )}

        <div className={classes.MessageContainer_content}>
          <AnswerBlockWithTranslation
            showButtons={[AnswerReplyType.text, AnswerReplyType.image, AnswerReplyType.file]}
            replies={message.replies}
            markupMode={true}
            writeDisabled={false}
            onUpdate={onUpdateReplies}
            MAX_ANSWERS_COUNT={20}
            maxTextSize={500}
            INITIAL_REPLIES={[{ type: 'text', markup: 'plain', text: '' }]}
            isWhiteLabel={() => false}
            saveAudioToServer={saveAudioToServer}
            saveFileToServer={saveFileToServer}
            getErrorMessageFromReason={() => ''}
            validateFile={validateFile as AnswerBlockProps['validateFile']}
            onUpdateDebounce={0}
          />
        </div>
      </div>
    </div>
  );
};
