import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const commonChannels = {
  CHAT_WIDGET: 'CHAT_WIDGET',
  TELEGRAM: 'TELEGRAM',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  VK: 'VK',
  GOOGLE: 'GOOGLE',
  I_DIGITAL: 'I_DIGITAL',
  WHATSAPP: 'WHATSAPP',
  AZURE: 'AZURE',
  CHAT_API: 'CHAT_API',
  VIBER: 'VIBER',
  INCOMING_WEBIM2: 'INCOMING_WEBIM2',
  JIVOSITE: 'INCOMING_JIVOSITE',
  ZENBOX: 'ZENBOX',
  NEXMO: 'NEXMO',
  WECHAT: 'WECHAT',
  SBER: 'SBER',
  WAZZUP: 'WAZZUP',
};

export const chatChannels = {
  CHAT_WIDGET: 'CHAT_WIDGET',
  TELEGRAM: 'TELEGRAM',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  VK: 'VK',
  WHATSAPP: 'WHATSAPP',
  AZURE: 'AZURE',
  VIBER: 'VIBER',
  CHAT_API: 'CHAT_API',
  JIVOSITE: 'INCOMING_JIVOSITE',
  WECHAT: 'WECHAT',
  WAZZUP: 'WAZZUP',
};

export const voiceChannels = {
  YANDEX: 'YANDEX',
  MARUSIA: 'MARUSIA',
  GOOGLE: 'GOOGLE',
  ZENBOX: 'ZENBOX',
  SBER: 'SBER',
  ALEXA: 'ALEXA',
};

export const businessChannels = {
  BITRIX: 'BITRIX',
  JIVOSITE: 'INCOMING_JIVOSITE',
  INCOMING_WEBIM2: 'INCOMING_WEBIM2',
  THREADS: 'THREADS',
  INBOUND_LIVETEX: 'INBOUND_LIVETEX',
};

export const allChannels = { ...commonChannels, ...voiceChannels, ...businessChannels };

export function getChannelIconType(channelType) {
  if (!channelType) return undefined;
  if (channelType === commonChannels.JIVOSITE) return 'jivosite';
  if (channelType === commonChannels.INCOMING_WEBIM2) return 'webim';
  if (channelType === businessChannels.INBOUND_LIVETEX) return 'livetex';
  return [commonChannels.I_DIGITAL, commonChannels.NEXMO].includes(channelType)
    ? 'whatsapp'
    : channelType.toLowerCase();
}

export const channelTypesByLang = {
  RU: {
    YANDEX: 'YANDEX',
    MARUSIA: 'MARUSIA',
    BITRIX: 'BITRIX',
    ...commonChannels,
  },
  EN: {
    ...commonChannels,
  },
  CN: {
    ...commonChannels,
  },
  PT: {
    ...commonChannels,
  },
  ES: {
    ...commonChannels,
  },
  KZ: {
    ...commonChannels,
  },
};

export const extraChannelTypes = {
  WECHAT: 'WECHAT',
};

const commonOperatorChannels = {
  AIMYCHAT: 'AIMYCHAT',
  OPERATORAPI: 'OPERATORAPI',
  LIVETEX: 'LIVETEX',
  WEBIM: 'WEBIM',
  BITRIX: 'BITRIX',
  JIVOSITE: 'JIVOSITE',
  RETAILCRM: 'RETAILCRM',
  TALKME: 'TALKME',
};

export const operatorChannelsTypes = {
  RU: {
    ...commonOperatorChannels,
  },
  EN: {
    ...commonOperatorChannels,
  },
  CN: {
    ...commonOperatorChannels,
  },
  PT: {
    ...commonOperatorChannels,
  },
  ES: {
    ...commonOperatorChannels,
  },
  KZ: {
    ...commonOperatorChannels,
  },
};

export const businessChannelsTypesByLang = {
  RU: {
    ...businessChannels,
  },
  EN: {
    ...businessChannels,
  },
  CN: {
    ...businessChannels,
  },
  PT: {
    ...businessChannels,
  },
  ES: {
    ...businessChannels,
  },
  KZ: {
    ...businessChannels,
  },
};

export const pseudoOperatorChannels = [
  allChannels.JIVOSITE,
  allChannels.BITRIX,
  allChannels.INCOMING_WEBIM2,
  allChannels.INBOUND_LIVETEX,
  allChannels.THREADS,
];

export const baseAvailableTariffChannels = {
  community: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'WAZZUP',
  ],
  skillmaster: ['GOOGLE', 'ZENBOX', 'YANDEX', 'MARUSIA', 'SBER'],
  adv_skillmaster: ['GOOGLE', 'ZENBOX', 'YANDEX', 'MARUSIA', 'INCOMING_JIVOSITE', 'AZURE', 'VIBER', 'WAZZUP'],
  developer: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'NEXMO',
    'WAZZUP',
    'AIMYCHAT',
  ],
  business_jivo: ['INCOMING_JIVOSITE', 'VOICE_CHANNEL', 'CALLS_API'],
  business_jivo_brazil: ['INCOMING_JIVOSITE', 'VOICE_CHANNEL', 'CALLS_API'],
  business_jivo_xussr: ['INCOMING_JIVOSITE', 'VOICE_CHANNEL', 'CALLS_API'],
  basic: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'RESTERISK',
    'VOICE_CHANNEL',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'CALLS_API',
    'OPERATORAPI',
    'I_DIGITAL',
    'WHATSAPP',
    'CHAT_API',
    'WAZZUP',
    'AIMYCHAT',
  ],
  standard: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  pro: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  ultra: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'CHAT_API',
    'VOICE_CHANNEL',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'SBER',
    'CALLS_API',
    'WAZZUP',
    'AIMYCHAT',
  ],
  yoga: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'CHAT_API',
    'VOICE_CHANNEL',
    'BITRIX',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_yoga: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'CHAT_API',
    'VOICE_CHANNEL',
    'BITRIX',
    'I_DIGITAL',
    'WHATSAPP',
    'NEXMO',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_ultra: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_developer: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  advanced: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'VIBER',
    'CALLS_API',
    'WAZZUP',
    'AIMYCHAT',
  ],
  telephony_basic: ['RESTERISK', 'VOICE_CHANNEL', 'CALLS_API'],
  telephony_standard: ['RESTERISK', 'VOICE_CHANNEL', 'CALLS_API'],
  telephony_pro: ['RESTERISK', 'VOICE_CHANNEL', 'CALLS_API'],
  telephony_basic_xussr: ['RESTERISK', 'VOICE_CHANNEL', 'CALLS_API'],
  telephony_standard_xussr: ['RESTERISK', 'VOICE_CHANNEL', 'CALLS_API'],
  telephony_pro_xussr: ['RESTERISK', 'VOICE_CHANNEL', 'CALLS_API'],

  '': [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'CHAT_API',
    'VOICE_CHANNEL',
    'BITRIX',
    'I_DIGITAL',
    'WHATSAPP',
    'NEXMO',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'WAZZUP',
    'AIMYCHAT',
  ],
  undefined: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_standard: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_pro: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_pro_plus: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_enterprise: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  jacp_basic: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'RESTERISK',
    'VOICE_CHANNEL',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'CALLS_API',
    'OPERATORAPI',
    'I_DIGITAL',
    'WHATSAPP',
    'CHAT_API',
    'WAZZUP',
    'AIMYCHAT',
  ],
  basic_brazil: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'RESTERISK',
    'VOICE_CHANNEL',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'CALLS_API',
    'OPERATORAPI',
    'I_DIGITAL',
    'WHATSAPP',
    'CHAT_API',
    'WAZZUP',
    'AIMYCHAT',
  ],
  standard_brazil: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  pro_brazil: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  basic_xussr: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'RESTERISK',
    'VOICE_CHANNEL',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'CALLS_API',
    'OPERATORAPI',
    'I_DIGITAL',
    'WHATSAPP',
    'CHAT_API',
    'WAZZUP',
    'AIMYCHAT',
  ],
  standard_xussr: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
  pro_xussr: [
    'CHAT_WIDGET',
    'FACEBOOK',
    'INSTAGRAM',
    'GOOGLE',
    'TELEGRAM',
    'VK',
    'ZENBOX',
    'YANDEX',
    'BITRIX',
    'CHAT_API',
    'RESTERISK',
    'VOICE_CHANNEL',
    'I_DIGITAL',
    'WHATSAPP',
    'CALLS_API',
    'MARUSIA',
    'INCOMING_WEBIM2',
    'INCOMING_JIVOSITE',
    'AZURE',
    'VIBER',
    'WEBIM',
    'LIVETEX',
    'INBOUND_LIVETEX',
    'NEXMO',
    'WECHAT',
    'SBER',
    'THREADS',
    'OPERATORAPI',
    'WAZZUP',
    'AIMYCHAT',
  ],
};

baseAvailableTariffChannels.free = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.jacp_free = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.startup = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.calls_startup = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.growth = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.calls_growth = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.optimal = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.calls_optimal = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.premium = baseAvailableTariffChannels.undefined;
baseAvailableTariffChannels.calls_premium = baseAvailableTariffChannels.undefined;
