import React, { useCallback } from 'react';
import { Icon, Button } from '@just-ai/just-ui';
import { JustSelect } from '@just-ai/just-ui/';

import TextareaAutosize from 'modules/TemplatesWizard/primitives/TextareaAutosize';

import { FieldsList } from '../index';
import styles from '../styles.module.scss';

interface FieldsCollectionsProps {
  row: Record<string, any> & { fields: FieldsList[]; index: number };
  onDelete: (index: number) => void;
  onChange: (index: number, value: string, name: string) => void;
}
const FieldsCollections = ({ row, onDelete, onChange }: FieldsCollectionsProps) => {
  function drawField(field: FieldsList) {
    const key = field.envName;
    switch (field.type) {
      case 'text':
        return (
          <div key={key} className={styles.field}>
            <TextareaAutosize
              className={styles.textArea}
              key={field.type}
              value={row[field.envName] || ''}
              rowsMin={1}
              rowsMax={20}
              maxLength={500}
              placeholder={field.placeholder}
              bsSize='md'
              onChange={val => onChange(row.index, val, field.envName)}
            />
          </div>
        );
      case 'select':
        return (
          <div className={styles.field}>
            <JustSelect
              key={key}
              className={styles.dropDown}
              onChange={val => {
                const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
                onChange(row.index, oneEl.toString(), field.envName);
              }}
              options={field.selectOptions.map(option => ({
                value: option,
                label: option,
              }))}
              disabled={field.selectOptions.length === 0}
              multiple={field.selectMultiple}
              value={row[field.envName] || ''}
              position='fixed'
            />
          </div>
        );
      default:
        return null;
    }
  }

  const onDeleteInner = useCallback(() => {
    onDelete(row.index);
  }, [onDelete, row.index]);

  return (
    <div className={styles.row}>
      <div className={styles.row__fields}>{row.fields.map(drawField)}</div>
      <Button
        className={styles.deleteBtn}
        type='button'
        color='secondary'
        size='md'
        withoutPadding
        flat
        onClick={onDeleteInner}
      >
        <Icon name='farTrashAlt' />
      </Button>
    </div>
  );
};

export default React.memo(FieldsCollections);
