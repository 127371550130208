import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { animationFrames, endWith, map, takeWhile } from 'rxjs';

function tween(start, end, duration) {
  const diffX = end - start;
  return animationFrames().pipe(
    // Figure out what percentage of time has passed
    map(({ elapsed }) => elapsed / duration),
    // Take the vector while less than 100%
    takeWhile(v => v < 1),
    // Finish with 100%
    endWith(1),
    // Calculate the distance traveled between start and end
    map(v => v * diffX + start)
  );
}

export const smoothScrollTo = 'smoothScrollTo';

export default class SpringScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);

    this.scrollbars = null;
  }

  getRef = target => {
    this.scrollbars = target;
  };
  updateHeightListener = event => {
    if (this.scrollbars) {
      tween(this.scrollbars.getScrollTop(), event.detail.top, 300).subscribe(x => {
        this.scrollbars.scrollTop(x);
      });
    }
  };
  componentDidMount() {
    document.addEventListener(smoothScrollTo, this.updateHeightListener);
  }
  componentWillUnmount() {
    document.removeEventListener(smoothScrollTo, this.updateHeightListener);
  }

  render() {
    return <Scrollbars {...this.props} ref={this.getRef} />;
  }
}
