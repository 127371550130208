import React, { useCallback } from 'react';

import './StudioBanner.scss';
import localize, { t } from 'localization';
import { Button } from '@just-ai/just-ui';
import { studioBannerLocalization } from './studioBanner.loc';
import { GA } from '../../../pipes/pureFunctions';
import { bannersAvailabed, getCurrentUserLanguage, isEuroInstance } from '../../../isAccessFunction';

localize.addTranslations(studioBannerLocalization);

export enum TypeEnum {
  DASHBOARD = 'DASHBOARD',
  CHANNELS = 'CHANNELS',
  WIZARD_CHANNELS = 'WIZARD_CHANNELS',
}

const PREFIX_MAP = {
  [TypeEnum.DASHBOARD]: 'StudioBanner:dashboard',
  [TypeEnum.CHANNELS]: 'StudioBanner:channels',
  [TypeEnum.WIZARD_CHANNELS]: 'StudioBanner:wizardChannels',
};
const EVENT_CATEGORY_MAP = {
  [TypeEnum.DASHBOARD]: 'Interview_Offer_Banner',
  [TypeEnum.CHANNELS]: 'Quiz_Integrations_Banner',
  [TypeEnum.WIZARD_CHANNELS]: '',
};

const LINK_TYPE = {
  SPANISH: 'spanish',
  MAIN: 'main',
};

const getLanguage = (isSpanish: boolean) => {
  const language = getCurrentUserLanguage();

  if (language === 'PT') return 'pt';
  if (language === 'RU') return 'ru';

  if (isSpanish) {
    return 'es';
  }

  return 'en';
};

const getEventLabel = (type: TypeEnum, isSpanish: boolean) => {
  const language = getCurrentUserLanguage();

  if (language === 'PT') return 'Pt';

  if (type === TypeEnum.DASHBOARD && isSpanish) {
    return 'Es';
  }

  return 'En';
};

export default function StudioBanner({ type, countryIsoCode }: { type: TypeEnum; countryIsoCode?: string }) {
  const translatePrefix = PREFIX_MAP[type || TypeEnum.DASHBOARD];

  const isSpanish = Boolean(countryIsoCode && ['ES', 'MX', 'AR'].includes(countryIsoCode));
  const linkType = type === TypeEnum.DASHBOARD && isSpanish ? LINK_TYPE.SPANISH : LINK_TYPE.MAIN;

  const eventLabel = getEventLabel(type, isSpanish);
  const postfix = `${isEuroInstance() ? '' : ':aimylogic'}`;

  const onClick = useCallback(() => {
    GA('GAEvent', EVENT_CATEGORY_MAP[type || TypeEnum.DASHBOARD], 'clicked', eventLabel);
  }, [eventLabel, type]);

  if (type === TypeEnum.DASHBOARD && !bannersAvailabed().includes(getLanguage(isSpanish))) return null;
  if (type === TypeEnum.WIZARD_CHANNELS && isEuroInstance()) return null;

  return (
    <div className='StudioBannerContainer '>
      <div className='backgrounds'>
        {type === TypeEnum.CHANNELS ? (
          <svg width='100%' height='100%' viewBox='0 0 470 160' fill='none'>
            <path
              d='M24.9764 66.2914C96.6547 -135.083 381.744 -277.773 534.616 -366C634.235 25.296 908.191 244.148 793.385 485.914C707.177 667.459 303.786 590.223 160.43 485.918C17.0753 381.614 -36.1198 237.936 24.9764 66.2914Z'
              fill='url(#paint0_linear_5212_3373)'
              fillOpacity='0.4'
            />
            <defs>
              <linearGradient
                id='paint0_linear_5212_3373'
                x1='168.324'
                y1='-44.0793'
                x2='310.194'
                y2='-14.1781'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#E3D5FF' />
                <stop offset='1' stopColor='#C2E7FF' stopOpacity='0' />
              </linearGradient>
            </defs>
          </svg>
        ) : (
          <>
            <svg width='100%' height='100%' viewBox='0 0 557 160' fill='none'>
              <path
                d='M74.9615 -99.0169C167.267 -178.453 672.568 -113.32 772.408 -113.608C709.873 108.628 479.732 198.99 354.853 286.191C261.08 351.67 62.3422 239.871 21.8948 147.443C-18.5527 55.0147 -3.71626 -31.3085 74.9615 -99.0169Z'
                fill='url(#paint0_linear_5212_3387)'
                fillOpacity='0.9'
              />
              <defs>
                <linearGradient
                  id='paint0_linear_5212_3387'
                  x1='64.5677'
                  y1='289.733'
                  x2='85.911'
                  y2='-25.0214'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#E3D5FF' />
                  <stop offset='1' stopColor='#C2E7FF' stopOpacity='0' />
                </linearGradient>
              </defs>
            </svg>
            <svg width='100%' height='100%' viewBox='0 0 547 160' fill='none'>
              <path
                d='M300.753 -58.8061C415.26 -18.072 496.398 143.942 546.566 230.818C324.064 287.431 199.619 443.117 62.1442 377.874C-41.0868 328.883 2.83153 99.6388 62.142 18.1713C121.452 -63.2962 203.151 -93.5266 300.753 -58.8061Z'
                fill='url(#paint0_linear_5212_3386)'
                fillOpacity='0.6'
              />
              <defs>
                <linearGradient
                  id='paint0_linear_5212_3386'
                  x1='363.513'
                  y1='22.6572'
                  x2='346.53'
                  y2='103.284'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#E3D5FF' />
                  <stop offset='1' stopColor='#C2E7FF' stopOpacity='0' />
                </linearGradient>
              </defs>
            </svg>
          </>
        )}
      </div>
      <div className='StudioBannerInfo'>
        <div className='StudioBannerInfo_text'>
          <h3>{t(`${translatePrefix}:mainText`)}</h3>
          <span>{t(`${translatePrefix}:subText`)}</span>
        </div>
        <a href={t(`${translatePrefix}:Button:link:${linkType}${postfix}`)} target='_blank' rel='noopener noreferrer'>
          <Button square={false} color='link' onClick={onClick}>
            {t(`${translatePrefix}:Button`).toUpperCase()}
          </Button>
        </a>
      </div>
    </div>
  );
}
