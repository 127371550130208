import {
  CREATE_CHANNEL_FULFILLED,
  CREATE_CHANNEL_PENDING,
  CREATE_CHANNEL_REJECTED,
  UPDATE_CHANNEL,
  DROP_CHANNEL,
  EDIT_CHANNEL_FULFILLED,
  SET_CHANNEL_TO_EDIT,
  SET_CHANNEL_TO_CREATION,
  FETCH_THEMES_PENDING,
  FETCH_THEMES_FULFILLED,
  FETCH_THEMES_REJECTED,
  ALEXA_SET_REFRESH_TOKEN_FULFILLED,
  DELETE_IDS_DESTINATION,
  ADD_DESTINATION_IDS,
  REMOVE_GROUP,
  SELECT_DESTINATION_IDS,
  ADD_DESTINATION,
} from '../../constants/channels.actions';
import { parseErrors } from '../../pipes/pureFunctions';
import { merge, isEmpty } from 'lodash';

const blankChannel = {
  id: null,
  channelType: 'TELEGRAM',
  botName: '',
  senderName: '',
  accessToken: '',
  nodeId: null,
  nodePassword: '',
  rollout: 'MANUAL',
  branch: 'master',
  project: {
    defaultBranch: 'master',
    contentDirectory: '.',
  },
  customData: {
    oauthToken: '',
    invocationName: '',
    locale: null,
  },
};

const InitialState = {
  fetching: false,
  errors: [],
  chatWidgetThemes: [],
  implementationScript: null,
  webhook: null,
  editableChannel: blankChannel,
};

const newGroup = {
  name: '',
  description: '',
  ids: null,
};

export default function ChannelDetailReducer(state = InitialState, action) {
  switch (action.type) {
    case CREATE_CHANNEL_FULFILLED: {
      return {
        ...state,
        fetching: false,
        editableChannel: action.payload.data,
      };
    }

    case EDIT_CHANNEL_FULFILLED:
    case SET_CHANNEL_TO_EDIT: {
      return {
        ...state,
        fetching: false,
        editableChannel: {
          ...action.channel,
          customData: action.channel.customData ? JSON.parse(action.channel.customData) : {},
        },
        errors: InitialState.errors,
      };
    }

    case SET_CHANNEL_TO_CREATION: {
      return {
        ...state,
        editableChannel: merge({}, InitialState.editableChannel, {
          channelType: action.channelType,
        }),
      };
    }

    case CREATE_CHANNEL_PENDING: {
      return {
        ...state,
        errors: [],
        fetching: true,
      };
    }

    case CREATE_CHANNEL_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case UPDATE_CHANNEL:
      return {
        ...state,
        editableChannel: merge({}, state.editableChannel, action.channelData),
      };

    case DROP_CHANNEL:
      return {
        ...state,
        errors: InitialState.errors,
        editableChannel: InitialState.editableChannel,
        implementationScript: InitialState.implementationScript,
      };

    case FETCH_THEMES_FULFILLED:
      return {
        ...state,
        chatWidgetThemes: !!action.payload.data && !isEmpty(action.payload.data) ? action.payload.data : [],
        fetching: false,
      };

    case FETCH_THEMES_REJECTED:
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        error: errors,
        fetching: false,
      };

    case FETCH_THEMES_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case ALEXA_SET_REFRESH_TOKEN_FULFILLED: {
      action.payload.config.data = JSON.parse(action.payload.config.data);
      return {
        ...state,
        editableChannel: {
          ...blankChannel,
          id: Boolean(action.payload.config.data.id) ? parseInt(action.payload.config.data.id, 10) : null,
          channelType: 'ALEXA',
          accessToken: action.payload.config.data.botToken,
          senderName: action.payload.config.data.name,
          botName: action.payload.config.data.name,
          customData: {
            ...blankChannel.customData,
            locale: action.payload.config.data.locale,
            invocationName: action.payload.config.data.invocationName,
          },
        },
        fetching: false,
      };
    }

    case ADD_DESTINATION: {
      let destinations =
        !!state.editableChannel.config && !!state.editableChannel.config.destinations
          ? [...state.editableChannel.config.destinations]
          : [];
      destinations.push({ ...newGroup });
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case REMOVE_GROUP: {
      let destinations = [...state.editableChannel.config.destinations];
      destinations.splice(action.indexInArray, 1);
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case SELECT_DESTINATION_IDS: {
      let destinations = [...state.editableChannel.config.destinations];
      destinations[action.f_key].ids = action.value;
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case ADD_DESTINATION_IDS: {
      let destinations =
        !!state.editableChannel.config && !!state.editableChannel.config.destinations
          ? [...state.editableChannel.config.destinations]
          : [];
      destinations[action.f_key].ids.push('');
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    case DELETE_IDS_DESTINATION: {
      let destinations =
        !!state.editableChannel.config && !!state.editableChannel.config.destinations
          ? [...state.editableChannel.config.destinations]
          : [];
      destinations[action.f_key].ids.splice(action.s_key, 1);
      if (destinations[action.f_key].ids.length === 0) {
        destinations[action.f_key].ids.push('');
      }
      return {
        ...state,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            destinations: destinations,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
