import { forEach, isArray, isDate } from 'lodash';
import localize from '../../components/visualeditor/localization';

import md5 from 'md5';
import { isEuroInstance } from '../../isAccessFunction';

function replaceGoBack(name, parentPath) {
  if (!!name && name.indexOf('../') > -1) {
    let replaceCount = name.match(/\.\.\//g).length;
    let dontMatch = name.replace(/\.\.\//g, '');
    let splitFromNode = parentPath.split(/\//g);
    splitFromNode.splice(replaceCount * -1, replaceCount, dontMatch);
    name = splitFromNode.join('/');
  }

  return name;
}
export const makeFromStateConnections = (mainPatternIndex = 0, currentNodeId, patterns, array = []) => {
  forEach(patterns, (patternArray, key) => {
    forEach(patternArray, (pattern, index) => {
      if (!!pattern.fromState) {
        array.push({
          from_node: replaceGoBack(pattern.fromState, currentNodeId), //end
          fromGroup: mainPatternIndex,
          from: index,
          key: key,
          to_node: currentNodeId, //start
        });
        mainPatternIndex++;
      }
    });
  });

  return [array, mainPatternIndex];
};
export const makeActionConnections = (from_node, actions, connections = [], groupIterator = 0) => {
  forEach(actions, action => {
    if (!!action.type) {
      switch (action.type) {
        case 'timeout': {
          if (action.then) {
            connections.push({
              from_node: from_node,
              fromGroup: groupIterator,
              from: 0,
              to_node: replaceGoBack(action.then, from_node),
              to: 0,
            });
          }
          break;
        }
        case 'random': {
          forEach(action.transitions, (transition, transitionIndex) => {
            connections.push({
              from_node: from_node,
              fromGroup: groupIterator,
              from: transitionIndex,
              to_node: replaceGoBack(transition, from_node),
              to: 0,
            });
          });
          groupIterator += 1;
          break;
        }
        case 'condition': {
          forEach(action.conditions, (connection, conditionIndex) => {
            if (Boolean(connection.transition)) {
              connections.push({
                from_node: from_node,
                fromGroup: groupIterator,
                from: conditionIndex,
                to_node: replaceGoBack(connection.transition, from_node),
                to: 0,
              });
            }
          });

          if (Boolean(action.defaultTransition)) {
            connections.push({
              from_node: from_node,
              fromGroup: groupIterator,
              from: action.conditions.length,
              to_node: replaceGoBack(action.defaultTransition, from_node),
              to: 0,
            });
          }
          groupIterator += 1;
          break;
        }
        case 'buttons': {
          forEach(action.buttons, (button, buttonIndex) => {
            if (button.transition) {
              connections.push({
                from_node: from_node,
                fromGroup: groupIterator,
                from: buttonIndex,
                to_node: replaceGoBack(button.transition, from_node),
                to: 0,
              });
            }
          });
          groupIterator += 1;
          break;
        }
        default:
          break;
      }
    }
  });

  return connections;
};
export const makePatternConnections = (from_node, patterns, connections = [], groupIterator = 0) => {
  forEach(patterns, (pattern, key) => {
    //console.log(pattern, key);
    switch (key) {
      case 'global': {
        break;
      }
      case 'theme':
      case 'context': {
        forEach(pattern, item => {
          if (!!item.toState) {
            connections.push({
              from_node: from_node,
              fromGroup: groupIterator,
              from: 0,
              to_node: item.toState,
              to: 0,
            });
          }

          groupIterator += 1;
        });
        break;
      }
      default:
        break;
    }
  });
  return [connections, groupIterator];
};

export const pushNewNode = (newNodes, iterator, windowType = 'screen', col, blockData = {}) => {
  if (newNodes.findIndex(item => item.id === `/newNode_${iterator}`) === -1) {
    switch (windowType) {
      case 'TelegramPayment':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          boundsTo: '',
          providerToken: '',
          startParameter: false,
          paymentTitle: '',
          description: '',
          imageUrl: '',
          amount: '',
          currency: isEuroInstance() ? 'USD' : 'RUB',
          invoicePayload: '',
          okState: null,
          errorState: null,
          catchAllState: null,
          precheckoutEnabled: false,
          precheckoutUrl: '',
        });
        break;
      case 'InputText':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          boundsTo: '',
          prompt: localize.translate('Enter a text'), // сообщение приглашающее к вводу данных
          varName: 'text', // имя переменной для сохранения результата
          then: null,
          actions: [
            {
              type: 'buttons',
              buttons: [],
            },
          ],
        });
        break;
      case 'Timetable':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          boundsTo: '',
          okState: null,
          errorState: null,
          offset: 3,
          everyDayParam: {
            enabled: true,
            from: '10:00',
            to: '20:00',
          },
          body: [
            ...Array(7).fill({
              enabled: false,
              from: '10:00',
              to: '20:00',
            }),
          ],
        });
        break;
      case 'Email':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          boundsTo: '',
          destination: '',
          subject: '',
          text: '',
          files: [],
          html: '',
          htmlEnabled: false,
          okState: '',
          errorState: '',
        });
        break;
      case 'Sms':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          boundsTo: '',
          text: '',
          destination: '',
          okState: null,
          errorState: null,
        });
        break;
      case 'InputNumber':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          prompt: localize.translate('Enter a number'),
          varName: 'number',
          minValue: 1,
          maxValue: 5,
          failureMessage: [localize.translate('Enter a number from 1 to 5')],
          then: null,
          boundsTo: '',
          actions: [
            {
              type: 'buttons',
              buttons: [],
            },
          ],
        });
        break;
      case 'InputFile':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          prompt: localize.translate('Upload file'),
          varName: 'userFile',
          failureMessage: [],
          then: null,
          boundsTo: '',
        });
        break;
      case 'Transition':
        newNodes.push(
          makeNewUniqueId({
            id: `/newNode_${iterator}`,
            name: `col#${col}`,
            type: 'Transition',
            then: null,
            boundsTo: '',
          })
        );
        break;
      case 'EndSession':
        newNodes.push(
          makeNewUniqueId({
            id: `/newNode_${iterator}`,
            name: `col#${col}`,
            type: 'EndSession',
            boundsTo: '',
          })
        );
        break;
      case 'TransferToOperator':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          boundsTo: '',
          type: windowType,
          messageForWaitingOperator: localize.translate('SwitchEditModal messageForWaitingOperator'),
          messageBeforeTransfer: localize.translate('SwitchEditModal messageBeforeTransfer'),
          titleOfCloseButton: localize.translate('SwitchEditModal titleOfCloseButton'),
          //firstMessage : "",
          prechatAttributes: null,
          ignoreOffline: true,
          noOperatorsOnlineState: '', //задаётся если ignoreOffline = false
          //oneTimeMessage : false,//опциональные
          //closeChatPhrases : [],
          //destination : "", //группа операторов
          dialogCompletedState: '', //переход при закрытии
          //chatClosedMessage : "Оператор завершил диалог", //сообщение пользователю при закрытии чата оператором, задаётся либо стейт перехода onClose либо сообщение. Если задан стейт сообщение не выводится.
        });
        break;
      case 'TransferCallToOperator':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          boundsTo: '',
          type: windowType,
          then: '',
          errorState: '',
        });
        break;
      case 'dummyNode':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
        });
        break;
      case 'IntentGroup':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          boundsTo: '',
          fallback: '',
          intents: [],
          actions: [
            {
              type: 'buttons',
              buttons: [],
            },
          ],
          ...blockData,
        });
        break;
      case 'HttpRequest':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,

          okState: '',
          errorState: '',

          url: '',
          method: 'GET',

          body: '',
          headers: [],
          vars: [],
          boundsTo: '',
        });
        break;

      case 'InputPhoneNumber':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          prompt: localize.translate('Enter a phone number'),
          varName: 'phone',
          failureMessage: [localize.translate('Invalid phone number')],
          then: null,
          boundsTo: '',
          actions: [
            {
              type: 'buttons',
              buttons: [],
            },
          ],
        });
        break;

      case 'InputDateTime':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          prompt: localize.translate('Enter a phone number'),
          varName: 'dateTime',
          failureMessage: [localize.translate('Enter the correct date / time')],
          then: null,
          boundsTo: '',
          actions: [
            {
              type: 'buttons',
              buttons: [],
            },
          ],
        });
        break;
      case 'CrmIntegration': {
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: 'CrmIntegration',
          okState: null,
          errorState: null,
          channelType: 'BITRIX',
          task: 'LEAD_CREATION',
          parameters:
            '' /*"{ \"fields[TITLE]\": \"ИП Титов\", \"fields[NAME]\":\"Глеб\", \"fields[SECOND_NAME]\":\"Егорович\", \"fields[LAST_NAME]\":\"Титов\", \"fields[STATUS_ID]\":\"NEW\", \"fields[OPENED]\":\"Y\", \"fields[ASSIGNED_BY_ID]\":1, \"fields[CURRENCY_ID]\":\"USD\", \"fields[OPPORTUNITY]\":1250, \"fields[PHONE][0][VALUE]\":55588, \"fields[PHONE][0][VALUE_TYPE]\":\"WORK\", \"params[REGISTER_SONET_EVENT]\":\"Y\"}",*/,
          fields: {},
          boundsTo: '',
        });
        break;
      }
      case 'GoogleSheets': {
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: 'GoogleSheets',
          okState: null,
          errorState: null,

          integrationId: '', //integration from integration channels
          spreadsheetId: '', //table id from integration api
          sheetName: '', // list id

          operationType: 'readDataFromCells', //writeDataToCells, writeDataToLine

          body: [],
          boundsTo: '',
        });

        /**
         * readDataFromCells: [
         * {
              "varName": "var1",
              "cell": "A1"
            },
            {
              "varName": "var2",
              "cell": "A5"
            }
         * ]
         * writeDataToCells: [
           {
                    "values": "{{JSON.stringify($session.asdasdasd)}}",
                    "cell": "A26"
                },
           {
                    "values": "asdasd",
                    "cell": "A5"
                }
         ]
         * writeDataToLine: {
                "values": [
                    "qq",
                    "w",
                    "e"
                ]
            },
         * */
        break;
      }
      case 'screen':
        newNodes.push({
          id: `/newNode_${iterator}`,
          name: `col#${col}`,
          type: windowType,
          patterns: null,
          examples: null,
          events: null,
          actions: null,
        });
        break;
      default:
        break;
    }

    return newNodes;
  } else {
    return pushNewNode(newNodes, iterator + 1, windowType, col, blockData);
  }
};

export const getNewNodeID = (newNodes, iterator) => {
  if (newNodes.findIndex(item => item.id === `/newNode_${iterator}`) === -1) {
    return `/newNode_${iterator}`;
  } else {
    return getNewNodeID(newNodes, iterator + 1);
  }
};

export const calculateGroupIndex = (actions, patterns, examples, target, group = 0) => {
  let i = -1;
  let name = '';
  let _patternName = '';
  if (!!patterns) {
    forEach(patterns, (pattern, patternName) => {
      switch (patternName) {
        case 'global': {
          break;
        }
        case 'theme':
        case 'context': {
          if (!!pattern) {
            forEach(pattern, () => {
              if (group === target) {
                i = group;
                name = 'patterns';
                _patternName = patternName;
              }
              group += 1;
            });
          }
          break;
        }

        default:
          break;
      }
    });
    if (i > -1) {
      return [i, name, _patternName];
    }
  }

  i = -1;

  if (!!examples) {
    forEach(examples, (pattern, patternName) => {
      switch (patternName) {
        case 'global': {
          break;
        }
        case 'theme':
        case 'context': {
          if (!!pattern) {
            forEach(pattern, () => {
              if (group === target) {
                i = group;
                name = 'examples';
                _patternName = patternName;
              }
              group += 1;
            });
          }
          break;
        }

        default:
          break;
      }
    });
    if (i > -1) {
      return [i, name, _patternName];
    }
  }

  i = -1;

  forEach(actions, (action, index) => {
    switch (action.type) {
      case 'condition':
      case 'buttons':
      case 'random':
      case 'timeout':
      case 'transition': {
        if (group === target) {
          i = index;
          name = 'actions';
        }
        group += 1;
        break;
      }
      default:
        break;
    }
  });

  return [i, name, _patternName];
};
export const guessBlockHeight = (actions = [], patterns = null) => {
  const blockHeight = 40;
  const headerHeight = 15;
  const marginBottom = 150;
  let aArray = 0;
  let pArray = 0;
  if (!!patterns) {
    forEach(patterns, pattern => {
      if (!!pattern) {
        pArray += pattern.length;
      }
    });
  }
  if (!!actions) {
    aArray = actions.length;
  }

  return (aArray + pArray + 1) * blockHeight + headerHeight + marginBottom;
};

export const addNewButton = (buttonsArray, iterator, text) => {
  if (buttonsArray.findIndex(item => item.name === `${text} ${iterator}`) > -1) {
    return addNewButton(buttonsArray, buttonsArray.length + 1, text);
  } else {
    buttonsArray.push({ name: `${text} ${iterator}` });
    return buttonsArray;
  }
};
export const changeButtonText = (buttonsArray, arrayIndex, text) => {
  let newButtonsArray = [...buttonsArray];
  newButtonsArray.splice(arrayIndex, 1);
  let filterArray = newButtonsArray.filter(item => item.name === `${text}`);
  if (filterArray.length > 0) {
    return changeButtonText(buttonsArray, arrayIndex, `${text} ${filterArray.length}`);
  } else {
    return {
      ...buttonsArray[arrayIndex],
      name: text,
    };
  }
};
export const getColFromName = name => {
  return parseInt(name.split('#')[1], 10);
};

export const findAllSameName = (nodes, name) => {
  let found = [];
  nodes.forEach(node => {
    if (node.name === name) {
      found.push(node);
    }
  });
  return found;
};

export const changeCol = (item, nodes, i) => {
  if (item.type === 'screen') {
    item.name = 'col#' + (i + 1);
  } else {
    if (Boolean(item.boundsTo)) {
      let boundIndex = nodes.findIndex(z => z.id === item.boundsTo);
      if (boundIndex > -1) {
        item.name = nodes[boundIndex].name;
      } else {
        item.name = 'col#' + i;
      }
    } else {
      item.name = 'col#' + (i + 1);
    }
  }
};

export const findClosestCol = (nodes, item, col, filtered) => {
  for (let i = col - 2; i >= 0; i--) {
    let index = nodes.findIndex(node => node.name === 'col#' + i);
    if (index > -1) {
      filtered = findAllSameName(nodes, 'col#' + col);
      filtered.forEach(filter => {
        changeCol(filter, nodes, i);
      });

      changeCol(item, nodes, i);

      break;
    }
  }
};

export function colsResolver(nodes, iterator) {
  if (typeof iterator === 'undefined') {
    iterator = 0;
  } else {
    iterator++;
  }

  let filtered = [];

  nodes.forEach(item => {
    let col = getColFromName(item.name);
    if (parseInt(col, 10) > 0) {
      if (nodes.findIndex(node => node.name === 'col#0') > -1) {
        if (nodes.findIndex(node => node.name === 'col#' + (col - 1)) === -1) {
          findClosestCol(nodes, item, col, filtered);
          filtered = nodes.filter(item => {
            let itemCol = getColFromName(item.name);
            return itemCol >= col;
          });
        } else {
          switch (item.type) {
            case 'screen': {
              let index = nodes.findIndex(node => node.name === 'col#' + (col - 1));
              break;
            }
            default: {
              if (Boolean(item.boundsTo)) {
                let boundIndex = nodes.findIndex(z => z.id === item.boundsTo);
                if (boundIndex > -1) {
                  item.name = nodes[boundIndex].name;
                }
              }
              break;
            }
          }
        }
      } else {
        item.name = 'col#' + (col - 1);
        filtered = nodes.filter(item => {
          let itemCol = getColFromName(item.name);
          return itemCol > col - 1;
        });
      }
    }
  });

  if (iterator === nodes.length) {
    nodes = nodes.map(item => {
      let itemCol = getColFromName(item.name);
      if (itemCol > 0) {
        item.name = 'col#' + (itemCol - 1);
      }
      return item;
    });
    return nodes;
  }
  return filtered.length > 0 ? colsResolver(nodes, iterator) : nodes;
}

export function makeNewUniqueId(node) {
  delete node.uniqueId;
  node.uniqueId = md5(JSON.stringify({ ...node, date: new Date() }));
  return node;
}

export function bx24JSON(arData, prefix = 'fields', data = '') {
  let objects = [];

  for (let i in arData) {
    if (!arData.hasOwnProperty(i)) {
      continue;
    }

    let name = i;

    if (prefix) name = prefix + '[' + name + ']';

    if (typeof arData[i] === 'object') {
      objects.push([name, arData[i]]);
    } else {
      if (data.length > 0) {
        data += '&';
      }

      data += name + '=' + arData[i];
    }
  }

  let cnt = objects.length;
  if (cnt > 0) {
    let cb = function (str) {
      data += (!!str ? '&' : '') + str;
    };

    for (let i = 0; i < cnt; i++) {
      if (isDate(objects[i][1])) {
        cb(objects[i][0] + '=' + objects[i][1].toJSON());
      } else if (isArray(objects[i][1]) && objects[i][1].length === 0) {
        cb(objects[i][0] + '=');
      } else {
        data += bx24JSON(objects[i][1], objects[i][0], data);
      }
    }
  }

  return data;
}

export function bx24Splitter(str = '') {
  let obj = {};
  const arrValues = str.split('&');
  for (let i = 0; i < arrValues.length; i++) {
    let key_val = arrValues[i].split('=');
    obj[key_val[0]] = key_val[1];
  }
  return obj;
}

export const transformNodeActions = node => {
  if (node.type === 'screen' && node.actions) {
    return node.actions.map(i_action => {
      if (i_action.type === 'script') {
        let actionScript = i_action.script;
        const newRegex = new timeoutRegex();
        let timeoutRegexResult = newRegex.exec(actionScript);
        if (timeoutRegexResult && timeoutRegexResult.length > 0) {
          const timeValueAndType = timeoutRegexResult[1].split(' ');
          return {
            type: 'timeout',
            timeValue:
              timeValueAndType.length > 2
                ? timeValueAndType.splice(0, timeValueAndType.length - 2).join(' ')
                : timeValueAndType[0],
            timeType: timeValueAndType[timeValueAndType.length - 1],
            then: timeoutRegexResult[2],
          };
        }
      }
      return i_action;
    });
  }
  return node.actions;
};

export function normalizeNexmoName(oldName) {
  let name = oldName.replace('Nexmo whatsapp', 'Vonage');
  name = name.replace(/##\d*$/, '');
  return name;
}

function timeoutRegex() {
  return /\$reactions\.timeout\({interval:\s*_\.template\(['"](.*)['"],\s+{.+}\)\(\$session\),\s*targetState:\s*['"](\/*\w*)['"]}\)/g;
}

export const getBounding = (nodesList, currentNode, bounding) => {
  return nodesList.filter(node => node.id !== currentNode.id).findIndex(item => item.id === bounding) > -1
    ? bounding
    : '';
};

export const createBlockOnPutBeforeMeta = (
  windowIndex,
  copiedBlock,
  nodeToAddTo,
  allNodesArray,
  allNodesArrayByColumn
) => {
  const column = getColFromName(nodeToAddTo.name);
  const metaId = nodeToAddTo.id;
  const newNodeId = getNewNodeID(allNodesArray, allNodesArray.length);

  let newNode = {
    id: metaId,
    name: `col#${column}`,
    type: 'screen',
    patterns: null,
    examples: null,
    events: null,
    actions: [
      { ...copiedBlock },
      {
        type: 'transition',
        deferred: false,
        transition: newNodeId,
      },
    ],
  };

  nodeToAddTo.boundsTo = metaId; // because it moves to newNode
  delete nodeToAddTo.uniqueId;
  nodeToAddTo.id = newNodeId;
  nodeToAddTo = makeNewUniqueId(nodeToAddTo);
  newNode = makeNewUniqueId(newNode);

  let modifiedNode = { ...nodeToAddTo };

  allNodesArray.splice(windowIndex, 1, modifiedNode);
  allNodesArray.splice(windowIndex, 0, newNode);

  let index = allNodesArrayByColumn[column].findIndex(i => i.id === metaId);

  allNodesArrayByColumn[column].splice(index, 1, modifiedNode);
  allNodesArrayByColumn[column].splice(index, 0, newNode);

  return { newNode, allNodesArray, allNodesArrayByColumn };
};
