import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner } from '@just-ai/just-ui';

import { t } from 'localization';
import { GA } from 'pipes/pureFunctions';
import { useAppSelector } from 'storeHooks';

import useSyncCurrentProjectInState from 'helpers/hooks/useSyncCurrentProjectInState';
import Channels from 'views/Settings/Channels';

import styles from './styles.module.scss';

const ConnectChannels = () => {
  const { chanelList, currentBot } = useAppSelector(store => ({
    // @ts-ignore
    chanelList: store.ChannelsReducer.channels as { channelType: string }[],
    currentBot: store.CurrentProjectsReducer.currentBot,
  }));

  const pageParams = useParams<{ projectShortName: string }>();
  useSyncCurrentProjectInState(pageParams.projectShortName);

  const history = useHistory();

  const goForward = useCallback(() => {
    GA(undefined, 'Channels_Next', undefined, chanelList.map(channel => channel.channelType).join(', '));
    history.push(`/bot_dashboard/${pageParams.projectShortName}`);
  }, [chanelList, history, pageParams.projectShortName]);

  if (!currentBot) return <Spinner />;

  return (
    <div className={styles.ConnectChannels}>
      <div className={styles.ConnectChannels__card}>
        <Channels isInWizard currentProject={pageParams.projectShortName} />
        <div className={styles.bottomActions}>
          <Button color='secondary' data-test-id='Skill:Actions:Back' flat outline onClick={history.goBack}>
            {t('FAQSkill:Actions:Back')}
          </Button>
          <Button color='primary' data-test-id='Skill:Actions:Forward' onClick={goForward}>
            {t('FAQSkill:Actions:Forward')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ConnectChannels);
