import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { MessageType } from '../types';
import { MessageContainer } from '../MessageContainer';
import classes from '../../styles.module.scss';

export const SortableElementWrapper = SortableElement(
  ({ message, messageIndex }: { message: MessageType; messageIndex: number }) => {
    return <MessageContainer message={message} key={message.uuid} messageIndex={messageIndex} controlsEnabled={true} />;
  }
);

export const SortableContainerWrapper = SortableContainer(
  ({ sortableMessages }: { sortableMessages: MessageType[] }) => {
    if (sortableMessages.length === 0) return null;

    return (
      <div className={classes.AutomessageSequenceSortableContainer}>
        {sortableMessages.map((message, index) => (
          <SortableElementWrapper key={message.uuid} index={index} messageIndex={index + 2} message={message} />
        ))}
      </div>
    );
  }
);
