import { t } from 'localization';

import { SkillType, MenuSkillConfig } from '../types';

export const menuSkillConfig: () => MenuSkillConfig = () => ({
  type: SkillType.MENU,
  skillName: 'menu',
  title: t('MenuSkill:Title'),
  orderWeight: 999,
  description: '',
  language: '',
  skillVersion: 0,
  startState: '',
  menuItems: [],
});
