import * as StatisticConstants from '../../constants/statistic.actions';

import { parseErrors } from '../../pipes/pureFunctions';
import moment from 'moment';

const InitialState = {
  data: [],
  uniq: [],
  clients: [],
  fetching: false,
  uniqFetching: false,
  statFetching: false,
};

const randomInteger = (min, max) => {
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  rand = Math.round(rand);
  if (rand > -3 && rand < 3) {
    return randomInteger(min, max);
  }
  return rand;
};

export default function StatisticReducer(state = InitialState, action) {
  switch (action.type) {
    case StatisticConstants.LOAD_DUMMY_STATISTIC_PENDING:
    case StatisticConstants.STATISTIC_LOAD_PENDING:
    case StatisticConstants.UNIQ_CLIENTS_LOAD_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case StatisticConstants.STATISTIC_LOAD_REJECTED:
    case StatisticConstants.UNIQ_CLIENTS_LOAD_REJECTED: {
      return {
        ...state,
        errors: parseErrors(action.payload.response),
        fetching: false,
      };
    }
    case StatisticConstants.STATISTIC_LOAD_FULFILLED:
    case StatisticConstants.UNIQ_CLIENTS_LOAD_FULFILLED: {
      return {
        ...state,
        data: action.payload.data,
        fetching: false,
      };
    }
    case StatisticConstants.LOAD_DUMMY_STATISTIC: {
      let data = [
        0,
        20,
        25,
        45,
        65,
        75,
        85,
        95,
        125,
        140,
        105,
        110,
        135,
        180,
        260,
        230,
        220,
        175,
        184,
        215,
        230,
        245,
        265,
        278,
        295,
        320,
        300,
        273,
        245,
        320,
      ];

      let datasets = action.channels.map((item, i) => {
        return {
          data: data.map(item => parseInt(item + randomInteger(-15, 15), 10)),
          label: item.senderName,
          id: item.id,
        };
      });

      let labels = Array(30)
        .fill(0)
        .map((item, i) =>
          parseInt(
            moment()
              .subtract(i + 1, 'days')
              .format('x'),
            10
          )
        )
        .reverse();
      return {
        ...state,
        data: {
          labels,
          datasets,
        },
        fetching: false,
      };
    }
    case StatisticConstants.DROP_STATISTIC: {
      return {
        ...InitialState,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
