import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import classes from './NewHeader.module.scss';
import localize from 'localization/index';
// @ts-ignore

import AppIcon from '../../AppIcon/index';

import { isEuroInstance } from '../../../isAccessFunction';
const { translate: t } = localize;

function AdditionalInfo({
  teaser,
  manualControl,
}: {
  teaser?: { link: string; image: string };
  language: string;
  manualControl?: boolean;
}) {
  const [blockPopupOnAppear, setBlockPopupOnAppear] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setBlockPopupOnAppear(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={classNames(
        classes.popup,
        classes.additional,
        classes.additionalInfo,
        teaser && classes.withTeaser,
        blockPopupOnAppear && classes.blocked
      )}
    >
      <div className={classes.links}>
        <div className={classes.label}>
          <AppIcon small />
          <span>{t(isEuroInstance() ? 'tovi' : 'aimy')}</span>
        </div>

        {!isEuroInstance() && (
          <AdditionalInfoLink link={t('Header: user community link')} label={t('Header: user community')} />
        )}
        {!isEuroInstance() && (
          <AdditionalInfoLink
            link={t('Header: aimylogic news and updates link')}
            label={t('Header: aimylogic news and updates')}
          />
        )}
        {!isEuroInstance() && (
          <AdditionalInfoLink link={t('Header: youtube channel link')} label={t('Header: youtube channel label')} />
        )}
        {!manualControl && <AdditionalInfoLink link={t('ToS link')} label={t('Terms of use')} />}
        <AdditionalInfoLink link={t('privacy policy link')} label={t('Header: personal data  label')} />
      </div>
      {teaser && (
        <a href={teaser.link} target='_blank' rel='noopener noreferrer' className={classes.teaser}>
          <img src={teaser.image} />
        </a>
      )}
    </div>
  );
}

function AdditionalInfoLink({ link, label }: { link: string; label: string }) {
  return (
    <a
      href={link}
      target='_blank'
      rel='noreferrer noopener'
      className={classes.link}
      data-test-id={'AdditionalInfoLink.' + label}
    >
      {label}
    </a>
  );
}

export default React.memo(AdditionalInfo);
