import React, { FC } from 'react';
import cn from 'classnames';

export type Button = {
  text: string;
  active?: boolean;
};

export const Buttons: FC<{ buttons?: Button[] }> = ({ buttons }) => {
  if (!buttons || buttons.length === 0) return null;

  return (
    <div className='justwidget--buttons-inline'>
      <div className='justwidget--buttons'>
        {buttons?.map((button, index) => (
          <button className={cn(`justwidget--button`, { active: button.active })} key={index}>
            {button.text}
          </button>
        ))}
      </div>
    </div>
  );
};
