import * as templateActions from '../../constants/templates.actions';

import { axios } from '../../pipes/functions';
import { isEuroInstance } from '../../isAccessFunction';

export const init = () => {
  return {
    type: templateActions.FETCH_TEMPLATES,
    payload: axios.get(`/restapi/bottemplates/${isEuroInstance() ? 'tovieds' : 'zenflow'}?ignoreLanguage=true`),
  };
};
