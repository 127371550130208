import React from 'react';
import localize from '../../../../../localization';
import { InputText, Button, Icon } from '@just-ai/just-ui';
import { isArray } from 'lodash';
import classnames from 'classnames';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import BaseEditForm from '../BaseEditForm';
import { Tooltip } from '@just-ai/just-ui';

class GoolgeEditForm extends BaseEditForm {
  triggers = [];
  state = {
    rollout: true,
    triggers: [],
    errors: [],
  };

  asyncSetState = state => {
    const setState = async () => {
      await this.setState({
        ...state,
      });
    };
    return setState();
  };

  setTriggers = (props = this.props) => {
    let triggers = [];
    if (Boolean(props.editableChannel.customData)) {
      try {
        let customData = JSON.parse(props.editableChannel.customData);
        if (Boolean(customData.triggers)) {
          triggers = customData.triggers;
        } else if (isArray(customData.triggers)) {
          triggers = customData.triggers;
        } else {
          triggers = [customData.triggers];
        }
      } catch (e) {
        if (isArray(props.editableChannel.customData.triggers)) {
          triggers = props.editableChannel.customData.triggers;
        } else {
          triggers = [];
        }
      }
    }

    this.setState({
      triggers: triggers,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setTriggers(nextProps);
  }

  componentWillMount() {
    this.setTriggers();
  }

  createChannel = async () => {
    const { createChannel } = this.props;

    const channelData = {
      ...this.props.editableChannel,
      botName: this.form.name.value,
      senderName: this.form.name.value,
      accessToken: this.form.botToken.value,
      channelType: this.props.editableChannel.channelType,
      project: {
        ...this.props.editableChannel.project,
      },
      customData: {
        triggers: this.state.triggers,
      },
    };

    await createChannel(channelData);
    this.cancelEdit();
  };

  saveChannel = async () => {
    const { editChannel } = this.props;

    const channelData = {
      botName: this.form.name.value,
      senderName: this.form.name.value,

      accessToken: this.props.editableChannel.accessToken,
      channelType: this.props.editableChannel.channelType,
      rollout: 'MANUAL',
      id: this.props.editableChannel.id,
      branch: 'master',
      project: {
        ...this.props.editableChannel.project,
      },
      customData: {
        triggers: this.state.triggers,
      },
    };

    await editChannel(channelData);
    this.cancelEdit();
  };

  keyDown = key => e => {
    switch (e.keyCode) {
      case 9: {
        e.preventDefault();
        if (!e.shiftKey) {
          setTimeout(() => {
            this.addNewTriggerInArray();
          }, 0);
        }
        break;
      }
      default:
        break;
    }
  };

  changeTriggerItem = key => value => {
    if (this.state.triggers[key] !== value && value.length === 0) {
      this.removeTrigger(key)(value);
    }
    this.state.triggers[key] = value;
  };

  addNewTriggerInArray = () => {
    let newTriggers = [...this.state.triggers];
    newTriggers.push('');
    this.asyncSetState({
      triggers: newTriggers,
    }).then(() => {
      setTimeout(() => {
        this.triggers[Object.keys(this.triggers).length - 1].focus();
      }, 50);
    });
  };

  removeTrigger = key => e => {
    let newTriggers = [...this.state.triggers];
    newTriggers.splice(key, 1);
    this.triggers.splice(key, 1);
    this.setState({
      triggers: newTriggers,
    });
  };

  cancelEdit = () => {
    const { onClose } = this.props;
    this.setState({
      errors: [],
      triggers: [],
    });
    onClose();
  };

  renderInputs() {
    return (
      <div>
        <input type='hidden' ref={input => (this.form.botToken = input)} defaultValue='' />

        <p className={classnames(classes.label, classes.container)}>
          <span>{localize.translate('Triggers')}</span>
          <div className={classes.googleTriggerInfo}>
            <Icon id='googleTriggerInfo' name='farQuestionCircle' color='primary' />
            <Tooltip target='googleTriggerInfo'>{localize.translate('Channels:Google:TriggerTooltip')}</Tooltip>
          </div>
        </p>
        {this.state.triggers.map((item, key) => (
          <div key={`answer${key}${item}`}>
            <InputText
              className={classes.googleTrigger}
              inputProps={{
                maxLength: 100,
              }}
              autoFocus={true}
              defaultValue={Boolean(item) ? item : ''}
              innerRef={input => (this.triggers[key] = input)}
              onChange={this.changeTriggerItem(key)}
              onKeyDown={this.keyDown(key)}
            />
          </div>
        ))}
        <Button
          onClick={this.addNewTriggerInArray}
          className={classes.formControl}
          iconLeft='faPlus'
          color='primary'
          size='sm'
          square
          flat
        >
          {localize.translate('Add trigger')}
        </Button>
      </div>
    );
  }
}

export default GoolgeEditForm;
