import { parseErrors } from '../../pipes/pureFunctions';
import {
  FETCH_TARIFFS_FULFILLED,
  FETCH_TARIFFS_PENDING,
  FETCH_TARIFFS_REJECTED,
  DROP_ERRORS,
  SET_CURRENT_TARIFFS,
  tariffsGroups,
} from '../../constants/subscribtion.actions';

const InitialState = {
  errors: [],
  fetching: false,
  fetched: false,
  tariffs: [],
  chatbotTariffs: [],
  callsTariffs: [],
  demoDays: null,
  groupName: 'chatbotTariffs',
  businessJivo: null,
};

export default function SubscriptionReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_TARIFFS_FULFILLED: {
      const data = action.payload.data;
      const chatbotTariffs = data.tariffs.filter(tariff => {
        return tariffsGroups.chatbotTariffsUniqueNames.includes(tariff.uniqueName);
      });

      const callsTariffs = data.tariffs.filter(tariff => {
        return tariffsGroups.callsTariffsUniqueNames.includes(tariff.uniqueName);
      });

      const businessJivo = data.tariffs.find(tariff => tariff.uniqueName === 'business_jivo');

      return {
        ...state,
        tariffs: chatbotTariffs,
        chatbotTariffs: chatbotTariffs,
        callsTariffs: callsTariffs,
        businessJivo: businessJivo,
        demoDays: data.demoDays,
        groupName: InitialState.groupName,
        fetching: false,
        fetched: true,
      };
    }

    case FETCH_TARIFFS_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        errors: errors,
        fetching: false,
        fetched: true,
      };
    }

    case FETCH_TARIFFS_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
        errors: InitialState.errors,
      };
    }

    case DROP_ERRORS: {
      return {
        ...state,
        errors: InitialState.errors,
      };
    }

    case SET_CURRENT_TARIFFS: {
      return {
        ...state,
        errors: InitialState.errors,
        groupName: action.payload.groupName,
        tariffs: state[action.payload.groupName],
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
