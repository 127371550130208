import React from 'react';
import localize from 'localization';
import { InputText } from '@just-ai/just-ui';

import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';

const { translate: t } = localize;

export default class AzureEditForm extends BaseEditForm {
  constructor(props) {
    super(props);
    this.form = {};

    let startDialogueWithUserMessage = true;

    if (
      Boolean(props.editableChannel) &&
      Boolean(props.editableChannel.id) &&
      Boolean(props.editableChannel.customData)
    ) {
      if (props.editableChannel.customData.hasOwnProperty('startDialogueWithUserMessage')) {
        startDialogueWithUserMessage = props.editableChannel.customData.startDialogueWithUserMessage;
      }
    }

    this.state = {
      rollout: true,
      startDialogueWithUserMessage: startDialogueWithUserMessage,
    };
  }

  saveChannel = async () => {
    const { editChannel } = this.props;

    const channelData = {
      botName: this.form.name.value,
      senderName: this.form.name.value,

      accessToken: this.form.botToken.value,
      appId: this.form.appId.value,
      channelType: this.props.editableChannel.channelType,
      rollout: 'MANUAL',
      id: this.props.editableChannel.id,
      branch: 'master',
      customData: {
        appId: this.form.appId.value,
        password: this.form.password.value,
      },
      project: {
        ...this.props.editableChannel.project,
      },
    };

    await editChannel(channelData);
    this.props.onClose();
  };

  getLabelName = () => t('Bot token');

  renderInputs() {
    const { editableChannel } = this.props;
    return (
      <>
        {editableChannel.accessToken && (
          <div className={classes.formControl}>
            <p className={classes.label}>{this.getLabelName()}</p>
            <InputText
              placeholder={t('Enter custom', this.getLabelName().toLowerCase())}
              innerRef={input => (this.form.botToken = input)}
              id='bot_token'
              defaultValue={editableChannel.accessToken}
            />
          </div>
        )}
        {editableChannel.id && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('AZURE app id label')}</p>
            <InputText
              placeholder={t('Enter custom', t('AZURE app id label'))}
              innerRef={input => (this.form.appId = input)}
              id='appId'
              defaultValue={editableChannel.customData?.appId}
            />
          </div>
        )}
        {editableChannel.id && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('Password')}</p>
            <InputText
              placeholder={t('Enter custom', t('Password').toLowerCase())}
              innerRef={input => (this.form.password = input)}
              id='appId'
              defaultValue={editableChannel.customData?.password}
            />
          </div>
        )}
      </>
    );
  }
}
