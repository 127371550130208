import React, { FC, ReactElement } from 'react';
import classes from './newTemplateCard.module.scss';
import { Icon, IconProps } from '@just-ai/just-ui';
import cn from 'classnames';

export const NewTemplateCard: FC<{
  IconComponent?: ReactElement;
  iconName?: IconProps['name'];
  onClick: () => unknown;
  title: string;
  summary: string;
  vertical?: boolean;
  dataTestId?: string;
}> = ({ iconName, IconComponent, onClick, title, summary, vertical, dataTestId = '' }) => {
  return (
    <div
      className={cn(classes.NewTemplateCard, {
        'flex-row gap-16': vertical,
        [classes.NewTemplateCard_vertical]: vertical,
      })}
      onClick={onClick}
      data-test-id={dataTestId}
    >
      {IconComponent || null}
      {iconName && <Icon name={iconName} size='3x' />}
      <div className='d-flex flex-grow-1 flex-column'>
        <h2 className={cn('mb-3', classes.NewTemplateCard_fixedHeight, { 'mt-4': !vertical })}>{title}</h2>
        <p className='mb-0'>{summary}</p>
      </div>
    </div>
  );
};
