import { t } from '../../../localization';

interface Declined extends Array<string> {
  decline(minutes: number): string;
}

export const getMinutesInfo = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const translations = [
    'TariffLimitsTable minutes plur1',
    'TariffLimitsTable minutes plur2',
    'TariffLimitsTable minutes plur3',
  ] as Declined;

  const translate = t(`${translations.decline(minutes)}`);

  return `${minutes.toLocaleString('ru-RU')} ${translate}`;
};
