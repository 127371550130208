import React from 'react';
import { AtreidesTypo, Modal, Grid } from 'altreidsds';
import { withStyles, Button, Slide } from 'altreidsds';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class GlobalAlertModal extends React.Component {
  onClose = () => {
    const { dismissGlobalAlert } = this.props;
    dismissGlobalAlert();
  };

  render() {
    const { classes, open, globalAlert } = this.props;

    return (
      <Modal
        disableBackdropClick={true}
        transition={Transition}
        open={open}
        onClose={this.onClose}
        title={globalAlert.title}
        key='global_alert'
        aria-labelledby='global_alert-title'
        aria-describedby='global_alert-description'
        modalBottom={
          <Grid container spacing={8} justify='flex-end'>
            <Grid item></Grid>
            <Grid item>
              <Button onClick={this.onClose} color='primary' variant='contained'>
                {globalAlert.btn}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <AtreidesTypo noMargin>{globalAlert.message}</AtreidesTypo>
      </Modal>
    );
  }
}

const styles = theme => ({
  dialog: {
    paddingTop: '0 !important',
  },
  paper: {
    border: 'solid 1px var(--gray-300)',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: 'var(--gray-700)',
    padding: '30px 24px 20px 24px',
  },
  actions: {
    margin: '0 20px 30px 20px',
  },
  icon: {
    top: '30px',
    color: 'var(--gray-300)',
    right: '16px',
    cursor: 'pointer',
    position: 'absolute',
  },
});

export default withStyles(styles, { withTheme: true })(GlobalAlertModal);
