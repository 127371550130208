import * as dialogsActions from '../../constants/dialog.actions';

import moment from 'moment';
import { sortBy, uniqBy } from 'lodash';

const InitialState = {
  filterData: {},

  channels: [],

  dialogs: [],
  sessionsWav: [],
  dialogsLoadedPages: [0],
  clientsLoading: false,
  clientsPageLoading: false,

  activeDialogId: -1,
  dialogMainData: null,
  fetchingDialog: false,
  fetchingDialogPage: false,
  dialogContentSessions: [],
  originalClientData: [],

  dialogLoadedPages: [0],
  dialogPageNumber: 0,
  dialogTotalPages: 1,

  clientTotalPages: 1,
  clientTotal: 0,
  clientLoaded: 0,
  clientPage: 0,
};

export default function DialogsReducer(state = InitialState, action) {
  switch (action.type) {
    case dialogsActions.LOAD_CHANNELS_FULFILLED: {
      return {
        ...state,
        channels: action.payload.data,
      };
    }

    case dialogsActions.DUMMY_LOAD: {
      return {
        ...InitialState,
        channels: state.channels,
      };
    }

    case dialogsActions.LOAD_PENDING: {
      return {
        ...state,
        clientsLoading: true,
        dialogContentSessions: [],
        activeDialogId: -1,
        dialogMainData: null,
      };
    }
    case dialogsActions.LOAD_REJECTED: {
      return {
        ...state,
        clientsLoading: false,
      };
    }
    case dialogsActions.LOAD_FULFILLED: {
      return {
        ...state,
        dialogs: action.payload.data.content,
        clientsLoading: false,

        clientTotal: action.payload.data.totalElements,
        clientTotalPages: action.payload.data.totalPages,
        clientPage: action.payload.data.number,
        clientLoaded: 0,
        dialogsLoadedPages: [0],
      };
    }

    case dialogsActions.DIALOGS_PAGE_PENDING: {
      return {
        ...state,
        clientsPageLoading: true,
      };
    }
    case dialogsActions.DIALOGS_PAGE_REJECTED: {
      return {
        ...state,
        clientsPageLoading: false,
      };
    }
    case dialogsActions.DIALOGS_PAGE_FULFILLED: {
      let newDialogsLoadedPages = state.dialogsLoadedPages;
      newDialogsLoadedPages.push(action.payload.data.number);
      return {
        ...state,
        clientsPageLoading: false,
        dialogs: state.dialogs.concat(action.payload.data.content),
        dialogsLoadedPages: newDialogsLoadedPages,
        clientPage: action.payload.data.number,
      };
    }

    case dialogsActions.SELECT_DIALOG_PENDING: {
      return {
        ...state,
        fetchingDialog: true,
      };
    }
    case dialogsActions.SELECT_DIALOG_REJECTED: {
      return {
        ...state,
        fetchingDialog: false,
      };
    }

    case dialogsActions.SELECT_DIALOG_FULFILLED: {
      let originalClientData = action.payload.data.logs.content;
      originalClientData = sortBy(originalClientData, i => {
        return i.creationDate;
      });
      let objSortedByDay = {};

      let foundLoadedSessionsIDs = Array.from(new Set(originalClientData.map(i => i.sessionId)));

      foundLoadedSessionsIDs.forEach(sessionid => {
        let sessionData = state.sessionsWav.find(sessionWav => sessionWav.sessionId === sessionid);
        if (sessionData && sessionData.audioFileUrl) {
          const index = originalClientData.findIndex(i => i.sessionId === sessionData.sessionId);
          originalClientData.splice(index, 0, sessionData);
        }
      });
      originalClientData.forEach(item => {
        let dataStr = moment(item.creationDate).format('DD.MM.YYYY');
        if (Boolean(objSortedByDay[dataStr])) {
          objSortedByDay[dataStr].push(item);
        } else {
          objSortedByDay[dataStr] = [item];
        }
      });

      let sortedByDay = [];

      for (let i in objSortedByDay) {
        if (objSortedByDay.hasOwnProperty(i)) {
          sortedByDay.push(objSortedByDay[i]);
        }
      }

      return {
        ...state,
        dialogMainData: {
          ...state.dialogs.find(item => item.clientId === action.payload.config.externalParams.id),
          chatId: action.payload.data.client.chatId,
        },
        activeDialogId: action.payload.config.externalParams.id,
        dialogContentSessions: sortedByDay,
        originalClientData: originalClientData,
        fetchingDialog: false,
        dialogPageNumber: action.payload.data.logs.number,
        dialogTotalPages: action.payload.data.logs.totalPages,
        dialogLoadedPages: [action.payload.config.params.page],
      };
    }

    case dialogsActions.COMMUNITY_SET_ID: {
      return {
        ...state,
        activeDialogId: action.payload.id,
        dialogMainData: state.dialogs.find(item => item.clientId === action.payload.id),
      };
    }

    case dialogsActions.LOAD_PAGE_PENDING: {
      return {
        ...state,
        fetchingDialogPage: true,
      };
    }

    case dialogsActions.LOAD_PAGE_REJECTED: {
      return {
        ...state,
        fetchingDialogPage: false,
      };
    }

    case dialogsActions.LOAD_PAGE_FULFILLED: {
      let originalClientData = uniqBy(state.originalClientData.concat(action.payload.data.logs.content), log => {
        return log.id && log.question ? String(log.id) + log.question : String(log.id) + log.audioFileUrl;
      });
      originalClientData = sortBy(originalClientData, i => {
        return i.creationDate;
      });
      let originalClientDataReverse = [...originalClientData];
      let objSortedByDay = {};

      let foundLoadedSessionsIDs = Array.from(new Set(originalClientDataReverse.map(i => i.sessionId)));

      foundLoadedSessionsIDs.forEach(sessionid => {
        let sessionData = state.sessionsWav.find(sessionWav => sessionWav.sessionId === sessionid);
        if (sessionData && sessionData.audio) {
          const index = originalClientDataReverse.findIndex(i => i.sessionId === sessionData.sessionId);
          originalClientDataReverse.splice(index, 0, sessionData);
        }
      });

      originalClientDataReverse.forEach(item => {
        let dataStr = moment(item.creationDate).format('DD.MM.YYYY');
        if (Boolean(objSortedByDay[dataStr])) {
          objSortedByDay[dataStr].push(item);
        } else {
          objSortedByDay[dataStr] = [item];
        }
      });

      let sortedByDay = [];

      for (let i in objSortedByDay) {
        if (objSortedByDay.hasOwnProperty(i)) {
          sortedByDay.push(objSortedByDay[i]);
        }
      }

      let newDialogLoadedPages = [...state.dialogLoadedPages];
      newDialogLoadedPages.push(action.payload.config.params.page);

      return {
        ...state,
        dialogContentSessions: sortedByDay,
        originalClientData: originalClientData,
        fetchingDialogPage: false,
        dialogPageNumber: action.payload.data.logs.number,
        dialogLoadedPages: newDialogLoadedPages,
      };
    }

    case dialogsActions.LOAD_SESSION_WAV_PENDING: {
      return {
        ...state,
        fetchingDialogPage: true,
      };
    }
    case dialogsActions.LOAD_SESSION_WAV_REJECTED: {
      return {
        ...state,
        fetchingDialogPage: false,
      };
    }
    case dialogsActions.LOAD_SESSION_WAV_FULFILLED: {
      /**
       * shape
       * private Long id;
       * private String sessionId;
       * private Date startTime;
       * private Date endTime;
       * private String audioFileUrl;
       * */

      if (Boolean(action.payload.data.audioFileUrl)) {
        let data = {
          ...action.payload.data,
          audioFileUrl: action.payload.data.audioFileUrl.replace(
            /^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/g,
            (match, s1, s2, s3) => {
              return s3;
            }
          ),
          creationDate: action.payload.data.startTime,
          audioFileUrlId: action.payload.data.id,
        };

        let sessionsWav = [
          ...state.sessionsWav,
          {
            audioFileUrl: data.audioFileUrl,
            sessionId: data.sessionId,
            wav: data.audioFileUrl,
            creationDate: data.creationDate,
            startTime: data.startTime,
            lastTime: data.lastTime,
          },
        ];

        // sessionsWav = uniqBy(sessionsWav, (item) => item.id)

        let originalClientData = [...state.originalClientData];

        const index = originalClientData.findIndex(i => i.sessionId === data.sessionId);
        originalClientData.splice(index, 0, { ...data, id: originalClientData[index].id });

        let uniqOriginalClientData = uniqBy(originalClientData, log => {
          return log.id && log.question ? String(log.id) + log.question : String(log.id) + log.audioFileUrl;
        });

        let objSortedByDay = {};

        uniqOriginalClientData.forEach(item => {
          let dataStr = moment(item.creationDate).format('DD.MM.YYYY');

          if (Boolean(objSortedByDay[dataStr])) {
            objSortedByDay[dataStr].push(item);
          } else {
            objSortedByDay[dataStr] = [item];
          }
        });

        let dialogContentSessions = [];

        for (let i in objSortedByDay) {
          if (objSortedByDay.hasOwnProperty(i)) {
            dialogContentSessions.push(objSortedByDay[i]);
          }
        }

        return {
          ...state,
          sessionsWav: sessionsWav,
          originalClientData: uniqOriginalClientData,
          dialogContentSessions,
          fetchingDialogPage: false,
        };
      }

      return {
        ...state,
        fetchingDialogPage: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
