import React, { useCallback } from 'react';
import { Modal } from '@just-ai/just-ui';

import { t } from 'localization';

import { BaseSkill } from 'modules/TemplatesWizard/skillClasses/BaseSkill';

import skills from './styles.module.scss';

type DeleteSkillModalProps = {
  onSubmit: (skills: BaseSkill[]) => void;
  onCancel: () => void;
  isOpen: boolean;
  skillsToDelete: BaseSkill[];
};
function DeleteSkillModal({ isOpen, onSubmit, onCancel, skillsToDelete }: DeleteSkillModalProps) {
  const onSubmitInner = useCallback(() => {
    onSubmit(skillsToDelete);
  }, [onSubmit, skillsToDelete]);

  if (skillsToDelete.length === 0) return null;
  return (
    <Modal
      size='md'
      isOpen={isOpen}
      title={t('SkillsWizard:DeleteSkillModal:Title')}
      buttonSubmitText={t('SkillsWizard:DeleteSkillModal:Actions:Submit')}
      buttonCancelText={t('SkillsWizard:DeleteSkillModal:Actions:Cancel')}
      buttonSubmitColor='danger'
      onActionClick={onSubmitInner}
      onCancelClick={onCancel}
      centered
    >
      <div
        className={skills.DeleteSkillModal}
        dangerouslySetInnerHTML={{
          __html:
            skillsToDelete.length > 1
              ? t(
                  'SkillsWizard:DeleteSkillModal:DeleteManyText',
                  skillsToDelete.map(skill => skill.skillConfig.title).join(', ')
                )
              : t('SkillsWizard:DeleteSkillModal:DeleteOneText', skillsToDelete[0].skillConfig.title),
        }}
      />
    </Modal>
  );
}

export default React.memo(DeleteSkillModal);
