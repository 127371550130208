import React from 'react';
import { Route } from 'react-router-dom';

import { BASE_WIZARD_PATH } from '../../shared/consts';
import PseudoWidget from '../../pseudo-widget';
import SetupSkills from '../setup-skills';
import ConnectChannels from '../connect-channels';

import classes from './WizardSteps.module.scss';

import { SkillsSelectContainer } from '../skills-select';

export const WizardSteps = () => {
  return (
    <>
      <Route
        path={BASE_WIZARD_PATH + '/skill/skills-select/:projectShortName/connect'}
        exact
        component={ConnectChannels}
      />
      <Route
        path={[
          BASE_WIZARD_PATH + '/skill/skills-select/:projectShortName',
          BASE_WIZARD_PATH + '/skill/skills-select/:projectShortName/wizard',
        ]}
        exact
        render={() => (
          <div className={classes.WizardSteps}>
            <div className={classes.WizardStepsContent}>
              <Route
                path={BASE_WIZARD_PATH + '/skill/skills-select/:projectShortName'}
                exact
                component={SkillsSelectContainer}
              />
              <Route
                path={BASE_WIZARD_PATH + '/skill/skills-select/:projectShortName/wizard'}
                exact
                component={SetupSkills}
              />
            </div>
            <div className={classes.WizardStepsPseudoWidget}>
              <PseudoWidget />
            </div>
          </div>
        )}
      />
    </>
  );
};
