import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import App from './App';
import { Provider } from 'react-redux';
import { createAtreidesTheme, AtreidesThemeProvider, colors } from 'altreidsds';
import 'font-awesome/css/font-awesome.css';
import './sentry';

import 'typeface-roboto';

import fonts from 'google-fonts';
import localize, { t, tWithCheck } from 'localization';
import { TranslationContextProvider } from '@just-ai/just-ui/dist/contexts/transtationContext';

fonts.add({
  Roboto: [
    100,
    '100i',
    200,
    '200i',
    300,
    '300i',
    400,
    '400i',
    500,
    '500i',
    600,
    '600i',
    700,
    '700i',
    800,
    '800i',
    900,
    '900i',
    '&subset=latin,cyrillic',
  ],
});
fonts.add({
  Montserrat: [
    100,
    '100i',
    200,
    '200i',
    300,
    '300i',
    400,
    '400i',
    500,
    '500i',
    600,
    '600i',
    700,
    '700i',
    800,
    '800i',
    900,
    '900i',
    '&subset=latin,cyrillic',
  ],
});

const theme = createAtreidesTheme({
  banner: {
    left: 0,
    right: 0,
  },
  overrides: {
    MuiInput: {
      // todo this breaks multiline input fields
      input: {
        fontSize: 16,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: 'var(--gray-300)',
      },
      colorPrimary: {
        fill: 'var(--white)',
      },
      colorSecondary: {
        fill: 'var(--gray-700)',
      },
    },
    MuiMenuItem: {
      root: {
        textTransform: 'uppercase',
        fontSize: '0.75rem',
      },
    },
    MuiSwitchBase: {
      root: {
        width: '80px',
        fontFamily: '"Montserrat", sans-serif',
        '& > span:first-child': {
          justifyContent: 'flex-start',
          transition: 'justify-content 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          position: 'relative',
          '&:before': {
            left: '30px',
            right: 'auto',
            top: '5px',
            height: '100%',
            position: 'absolute',
            color: 'var(--white)',
            content: "'off'",
            fontSize: 12,
            textTransform: 'uppercase',
            textAlign: 'right',
          },
        },
      },
      checked: {
        '& > span:first-child': {
          justifyContent: 'flex-end',
          '&:before': {
            left: '22px',
            content: "'on'",
            textAlign: 'left',
          },
        },
      },
    },
    MuiSwitch: {
      root: {
        width: '80px',
      },
      bar: {
        height: 24,
        borderRadius: 12,
        marginTop: '-12px',
        left: '22%',
        width: '79px',
        opacity: 1,
        backgroundColor: 'rgba(83, 88, 92, 0.6)',
      },
      icon: {
        boxShadow: 'none',
        height: 24,
        width: 24,
        border: 'solid 1px rgba(83, 88, 92, 0.6)',
      },
      iconChecked: {
        boxShadow: 'none',
        borderColor: 'var(--success)',
      },
      checked: {
        transform: 'none',
        '& + $bar': {
          opacity: 1,
          backgroundColor: 'var(--success)',
        },
        '&$colorPrimary': {
          color: 'var(--white)',
          '& + $bar': {
            opacity: 1,
            backgroundColor: 'var(--primary-500)',
          },
          '& $iconChecked': {
            borderColor: 'var(--primary-500)',
          },
        },
        '&$colorSecondary': {
          color: 'var(--white)',
          '& + $bar': {
            opacity: 1,
            backgroundColor: 'var(--success)',
          },
          '& $iconChecked': {
            borderColor: 'var(--success)',
          },
        },
        '&$disabled': {
          opacity: 1,
          '& $icon': {
            boxShadow: 'none',
            background: 'var(--white)',
            borderColor: 'var(--success) !important',
          },
          '& $iconChecked': {
            background: 'var(--white)',
            borderColor: 'rgba(1, 169, 97, 0.3) !important',
          },
          '& + $bar': {
            backgroundColor: 'var(--success) !important',
            opacity: '0.3 !important',
          },
        },
      },
      colorPrimary: {
        '&$checked': {
          color: 'var(--white)',
          '& + $bar': {
            opacity: 1,
            backgroundColor: 'var(--primary-500)',
          },
        },
      },
      colorSecondary: {
        '&$checked': {
          color: 'var(--white)',
          '& + $bar': {
            opacity: 1,
            backgroundColor: 'var(--success)',
          },
        },
      },
      disabled: {
        '& $icon': {
          boxShadow: 'none',
          background: 'var(--white)',
          borderColor: 'rgba(83, 88, 92, 0.3) !important',
        },
        '& $iconChecked': {
          background: 'var(--white)',
          borderColor: 'rgba(83, 88, 92, 0.3) !important',
        },
        '& + $bar': {
          backgroundColor: 'rgba(83, 88, 92, 0.3) !important',
          opacity: 1,
        },
      },
    },
    MuiCheckbox: {
      root: {
        width: '24px',
        height: '24px',
        padding: 0,
        '& > span:first-child': {
          '&:before': {
            display: 'none',
          },
        },
      },
      colorPrimary: {
        '&$checked': {
          '& svg': {
            fill: 'var(--primary-500)',
          },
        },
      },
      checked: {
        '& > span:first-child': {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
  },
  palette: {
    warning: {
      ...colors.warning,
    },
    black: {
      ...colors.black,
    },
    blue: {
      ...colors.aimylogic,
    },
    gray: {
      main: 'var(--gray-950)',
      blackTwo: 'var(--gray-950)',
      light: 'var(--gray-700)',
      bc: 'var(--gray-400)',
      lightest: 'var(--gray-200)',
      black: 'var(--gray-950)',
    },
    accent: {
      main: 'var(--primary)',
      success: 'var(--success)',
      warning: 'var(--warning)',
      danger: 'var(--danger)',

      paleGold: 'var(--yellow-100)',
      coral: 'var(--pink)',
      green: 'var(--success)',
    },
    primary: {
      main: 'var(--primary-500)',
      3: 'var(--primary-100)',
      ...colors.aimylogic,
    },
    contrast: {
      main: 'var(--gray-700)',
      light: 'var(--gray-200)',
      lightest: 'var(--primary-100)',
      white: 'var(--white)',
    },
    text: {
      white: 'var(--white)',
      complete: colors.success.main,
    },
    social: {
      vk: '#4A76A8',
      google: '#3367D6',
      facebook: '#3b5998',
      telegram: '#1f96d4',
      viber: '#59267c',
      viberPublic: '#59267c',
      chatwidget: '#25d366',
      chatapi: '#25d366',
      resterisk: '#f68f1f',
      wechat: '#A7DF2B',
      sms: '#b00b00',
    },
    background: {
      paper: 'var(--white)',
      default: '#e0e1e3',
      visualEditor: 'var(--gray-100)',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Montserrat", sans-serif',
    sidebar: {
      fontSize: 14,
      smallFontSize: 12,
    },
    headline: {
      color: 'var(--gray-950)',
      fontWeight: 600,
    },
    subheading: {
      color: 'var(--gray-950)',
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    caption: {
      color: 'var(--gray-700)',
      fontSize: '0.75rem',
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    title: {
      color: 'var(--gray-600)',
    },
    display3: {
      color: 'var(--gray-950)',
      fontWeight: 600,
      fontSize: '1rem',
    },
    display2: {
      fontSize: '0.75rem',
    },
    display4: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    button: {
      fontSize: '0.75rem',
    },
  },
  bar: {},
});

ReactDOM.render(
  <Provider store={store}>
    <AtreidesThemeProvider theme={theme}>
      <TranslationContextProvider t={t} tWithCheck={tWithCheck} getLocale={localize.getLocale}>
        <App />
      </TranslationContextProvider>
    </AtreidesThemeProvider>
  </Provider>,

  document.getElementById('root')
);
