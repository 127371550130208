import React, { Component } from 'react';
import localize from 'localization';
import { WSContext } from 'contexts/WebSocketContext';

import { bindActionCreators } from 'redux';
import { getDeployStatus, setDeployments, setDeployStatus } from '../../actions/currentProject.actions';
import { addSnackbar, changeSnackbar, dismissGlobalAlert } from '../../actions/snackbars.actions';
import { connect } from 'react-redux';

export const WS_NOTIFICATIONS_PATH = '/user/topic/notifications';
export const NOTIFICATIONS_PATH = '/app/notifications';
export const NOTIFICATIONS_LIMIT = 500;

const STATUS_CODES = {
  'editorbe.deploy.bot.finished': 'OK',
  'editorbe.deploy.bot.failed': 'DEPLOY_FAILED',
};

const { translate } = localize;

class NotificationController extends Component {
  static contextType = WSContext;
  refInput = null;

  componentDidMount() {
    const {
      currentBot,
      actions: { getDeployStatus },
    } = this.props;
    this.statusJobStart = true;
    this.context.connect();
    this.subscribeId = this.context.subscribe(WS_NOTIFICATIONS_PATH, this.subscriber);
    this.context.send(NOTIFICATIONS_PATH, { size: NOTIFICATIONS_LIMIT });
    currentBot && getDeployStatus(currentBot.id);
  }

  componentWillUnmount() {
    if (this.subscribeId) this.context.unsubscribe(WS_NOTIFICATIONS_PATH, this.subscribeId);
  }

  componentDidUpdate() {
    if (this.statusJobStart) return;

    const {
      currentBot,
      actions: { getDeployStatus },
    } = this.props;
    if (!this.statusJobStart) {
      this.statusJobStart = true;
      this.context.connect();
      this.subscribeId = this.context.subscribe(WS_NOTIFICATIONS_PATH, this.subscriber);
      this.context.send(NOTIFICATIONS_PATH, { size: NOTIFICATIONS_LIMIT });
      currentBot && getDeployStatus(currentBot.id);
    }
  }

  subscriber = body => {
    if (Array.isArray(body)) {
      body.map(this.showSnackbar);
      body.map(this.setChannelDeployStatus);
    }
  };

  findChannelByBotId = botId => {
    const { channels } = this.props;
    return channels.find(x => x.botserverBotId === botId);
  };

  showSnackbar = notification => {
    const {
      actions: { addSnackbar },
    } = this.props;
    const { message } = notification;
    const code = message?.code?.code;
    const botId = message?.data?.botId;
    const output = message?.data?.output;
    const channel = this.findChannelByBotId(botId);
    if (!channel) return;

    switch (code) {
      case 'editorbe.deploy.bot.finished':
        return addSnackbar(
          channel.autoCreated
            ? translate('Publishing was successful')
            : translate('Scenario was published successfully', channel.senderName, 'success')
        );
      case 'editorbe.deploy.bot.failed':
        return addSnackbar(
          channel.autoCreated
            ? translate('Publishing was failed')
            : translate('Scenario publications was failed', channel.senderName),
          'error',
          false,
          translate('Copy log'),
          this.snackbarBtnAction(output)
        );
    }
  };

  setChannelDeployStatus = async notification => {
    const {
      actions: { setDeployStatus, getDeployStatus, setDeployments },
      currentBot,
    } = this.props;
    const { message } = notification;
    const code = message?.code?.code;
    const botConfigId = message?.data?.botId;
    const logOutput = message?.data?.output;
    const finishDate = message.timestamp;
    const id = message.taskId;
    const channel = this.findChannelByBotId(botConfigId);

    if (!channel || !code) return;

    const status = STATUS_CODES[code];

    setDeployStatus({ status, finishDate, logOutput, id, outdated: false, botConfigId });

    try {
      const {
        value: { data },
      } = await getDeployStatus(currentBot.id);
      let deployments = data.filter(i => i.status === 'IN_PROGRESS');
      setDeployments(currentBot.shortName, deployments);
    } catch (e) {
      console.error(e);
    }
  };

  snackbarBtnAction = log => id => {
    let supported = document.queryCommandSupported('copy');

    if (this.refInput && supported) {
      this.refInput.value = log;
      this.refInput.select();
      this.refInput.focus();
      window.document.execCommand('copy');
      this.props.actions.changeSnackbar(id, { buttonText: translate('Copied') });
    }
  };

  render() {
    return <input type='text' ref={input => (this.refInput = input)} readOnly className='hidden_input' />;
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    changeSnackbar: bindActionCreators(changeSnackbar, dispatch),
    addSnackbar: bindActionCreators(addSnackbar, dispatch),
    dismissGlobalAlert: bindActionCreators(dismissGlobalAlert, dispatch),
    getDeployStatus: bindActionCreators(getDeployStatus, dispatch),
    setDeployStatus: bindActionCreators(setDeployStatus, dispatch),
    setDeployments: bindActionCreators(setDeployments, dispatch),
  },
});

function mapStateToProps(state) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
    fetching: state.BotProjectsReducer.fetching,
    currentProject: state.CurrentProjectsReducer.currentProject,
    botList: state.BotProjectsReducer.botList,
    channels: state.ChannelsReducer.channels,

    deployments: state.CurrentProjectsReducer.deployments,
    currentBot: state.CurrentProjectsReducer.currentBot,
    appConfig: {
      fetching: state.AppConfigReducer.fetching,
      fetched: state.AppConfigReducer.fetched,
      zenflow: state.AppConfigReducer.zenflow,
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationController);
