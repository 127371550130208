import { useRef, useEffect, useState } from 'react';

import { PubSub } from 'services/PubSub';

import { SkillConfig, WizardState } from '../types';

export type ViewComponentProps = {
  index: number;
};

export function useSkillStateSelector<INNER_STATE extends object, RETURN_TYPE>(
  skill: BaseSkill<INNER_STATE>,
  selector: (state: INNER_STATE) => RETURN_TYPE
): RETURN_TYPE | undefined {
  const selectorRef = useRef(selector);
  const [state, setState] = useState<RETURN_TYPE | undefined>(() =>
    skill.innerState ? selector(skill.innerState) : undefined
  );

  useEffect(() => {
    return skill.subscribe('onSkillInnerStateChange', state => {
      setState(selectorRef.current(state));
    });
  }, [skill]);
  return state;
}

type SubscriptionsMap<INNER_STATE = any> = {
  onFieldsChange: { value: Record<string, any>; isDefaultValue?: boolean };
  onToggle: never;
  onNext: number;
  forceUpdate: void;
  onDeleteSkill: void;
  onSkillInnerStateChange: INNER_STATE;
  onOpened: boolean;
  onValid: boolean;
  onDone: boolean;
} & Record<string, any>;

export type InitSkillArgs = {
  accountId: number;
  projectId: string;
  isRealSkill: boolean;
  isOpened: boolean;
  isDone: boolean;
  otherSkills: BaseSkill[];
  isLast: boolean;
  initialValues?: any | undefined;
  isPostSetup?: boolean;
};

export abstract class BaseSkill<INNER_STATE extends object = any> extends PubSub<SubscriptionsMap<INNER_STATE>> {
  public isRealSkill = false;
  public otherSkills: BaseSkill[] = [];
  public initialValues?: any;
  public isPostSetup = false;
  public innerState: INNER_STATE = {} as INNER_STATE;
  public isLast = false;
  protected accountId?: number;
  protected projectId?: string;
  private _isOpened = false;
  private _isDone = false;
  private _isValid = true;
  private _isInitialized = false;

  init(args: InitSkillArgs) {
    this.isOpened = args.isOpened;
    this.isDone = args.isDone;
    this.isRealSkill = args.isRealSkill;
    this.otherSkills = args.otherSkills;
    this.accountId = args.accountId;
    this.projectId = args.projectId;
    this.isLast = args.isLast;
    this.isPostSetup = !!args.isPostSetup;
    if (args.initialValues) this.initialValues = args.initialValues;
    this._isInitialized = true;
  }

  protected constructor(public skillConfig: SkillConfig) {
    super();
  }

  onMount = () => {};

  onStateChange = (state: WizardState) => {};

  forceUpdate = () => this.notify('forceUpdate');

  skillInnerStateChange = (state: Partial<INNER_STATE>) => {
    this.innerState = { ...this.innerState, ...state };
    this.notify('onSkillInnerStateChange', this.innerState);
  };

  get isDone(): boolean {
    return this._isDone;
  }

  set isDone(value: boolean) {
    this._isDone = value;
    this.notify('onDone', value);
  }

  get isOpened(): boolean {
    return this._isOpened;
  }

  set isOpened(value: boolean) {
    this._isOpened = value;
    this.notify('onOpened', value);
  }

  get isValid(): boolean {
    return this._isValid;
  }

  set isValid(value: boolean) {
    this._isValid = value;
    this.notify('onValid', value);
  }

  abstract ViewComponent(props: ViewComponentProps): JSX.Element | null;
}
