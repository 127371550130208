import React from 'react';

import localize from 'localization';
import { Select, MenuItem } from 'altreidsds';
import { forIn } from 'lodash';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import { InputText } from '@just-ai/just-ui';

import classNames from 'classnames';

const { translate: t } = localize;

// TODO так и не нашла, где появляется это модальное окно

export default class ViberPublicEditForm extends BaseEditForm {
  form = {};

  createChannel = e => {
    e.preventDefault();
    const { createChannel } = this.props;

    const channelData = {
      name: this.form.name.value,
      type: this.props.editableChannel.type,
      nodeId: this.form.nodeId.value,
      nodePassword: this.form.nodePassword.value,
    };

    createChannel(channelData);
  };

  save = () => {
    const { editChannel } = this.props;

    const channelData = {
      name: this.form.name.value,
      type: this.props.editableChannel.type,
      nodeId: this.form.nodeId.value,
      nodePassword: this.form.nodePassword.value,
      id: this.props.editableChannel.id,
    };

    editChannel(channelData);
  };
  renderChannelTypeFormControl = () => {
    const { classes, editableChannel, channelTypes, changeChannelType } = this.props;

    const channelTypeSelectValues = [];
    forIn(channelTypes, (value, key) => {
      channelTypeSelectValues.push(
        <MenuItem key={key} value={value}>
          {key}
        </MenuItem>
      );
    });

    return (
      <div className={classNames(classes.formControl, { hidden: Boolean(editableChannel.id) })}>
        <p className={classes.label}>{t('Channel type')}</p>
        {Boolean(editableChannel.id) ? (
          <InputText
            disabled={true}
            id='channel_type'
            defaultValue={editableChannel.channelType}
            placeholder={t('Enter custom', t('Channel type').toLowerCase())}
          />
        ) : (
          <Select
            value={editableChannel.channelType}
            onChange={changeChannelType}
            inputProps={{
              name: 'channel_type',
              id: 'channel_type',
            }}
          >
            {channelTypeSelectValues}
          </Select>
        )}
      </div>
    );
  };

  renderInputs() {
    const { editableChannel } = this.props;

    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Node id')}</p>
          <InputText
            innerRef={input => (this.form.nodeId = input)}
            id='node_id'
            defaultValue={editableChannel.nodeId}
            placeholder={t('Enter custom', t('Node id'))}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Node password')}</p>
          <InputText
            innerRef={input => (this.form.nodePassword = input)}
            id='node_password'
            defaultValue={editableChannel.nodePassword}
            placeholder={t('Enter custom', t('Node password'))}
          />
        </div>
      </>
    );
  }
}
