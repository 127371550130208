// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AccountBaseInfo
 */
export interface AccountBaseInfo {
  /**
   *
   * @type {number}
   * @memberof AccountBaseInfo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AccountBaseInfo
   */
  name: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AccountBlockType {
  NONE = 'NONE',
  NOTPAYED = 'NOT_PAYED',
  FULL = 'FULL',
}

/**
 *
 * @export
 * @interface AccountFullInfo
 */
export interface AccountFullInfo {
  /**
   *
   * @type {number}
   * @memberof AccountFullInfo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AccountFullInfo
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AccountFullInfo
   */
  description?: string;
  /**
   *
   * @type {UserBaseInfo}
   * @memberof AccountFullInfo
   */
  owner: UserBaseInfo;
  /**
   *
   * @type {boolean}
   * @memberof AccountFullInfo
   */
  active: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountFullInfo
   */
  projectFeatures: Array<string>;
}
/**
 *
 * @export
 * @interface AccountFullNameDto
 */
export interface AccountFullNameDto {
  /**
   *
   * @type {string}
   * @memberof AccountFullNameDto
   */
  fullName: string;
}
/**
 *
 * @export
 * @interface AccountInvitationDto
 */
export interface AccountInvitationDto {
  /**
   *
   * @type {number}
   * @memberof AccountInvitationDto
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof AccountInvitationDto
   */
  accountName: string;
}
/**
 *
 * @export
 * @interface AccountInvitationSearchDto
 */
export interface AccountInvitationSearchDto {
  /**
   *
   * @type {number}
   * @memberof AccountInvitationSearchDto
   */
  invitationId: number;
  /**
   *
   * @type {number}
   * @memberof AccountInvitationSearchDto
   */
  userId?: number;
  /**
   *
   * @type {string}
   * @memberof AccountInvitationSearchDto
   */
  userLogin: string;
  /**
   *
   * @type {string}
   * @memberof AccountInvitationSearchDto
   */
  userFullName?: string;
  /**
   *
   * @type {Array<RoleDto>}
   * @memberof AccountInvitationSearchDto
   */
  userRoles: Array<RoleDto>;
}
/**
 *
 * @export
 * @interface AccountSearchFilter
 */
export interface AccountSearchFilter {
  /**
   *
   * @type {number}
   * @memberof AccountSearchFilter
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AccountSearchFilter
   */
  size: number;
  /**
   *
   * @type {string}
   * @memberof AccountSearchFilter
   */
  searchText?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSearchFilter
   */
  tariffUniqueNames?: Array<string>;
  /**
   *
   * @type {Array<AccountBlockType>}
   * @memberof AccountSearchFilter
   */
  blockType?: Array<AccountBlockType>;
  /**
   *
   * @type {PartnerDataDto}
   * @memberof AccountSearchFilter
   */
  partner?: PartnerDataDto;
  /**
   *
   * @type {Date}
   * @memberof AccountSearchFilter
   */
  accountCreatedTo?: Date;
  /**
   *
   * @type {Date}
   * @memberof AccountSearchFilter
   */
  accountCreatedFrom?: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSearchFilter
   */
  featuresPresent?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountSearchFilter
   */
  featuresAbsent?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof AccountSearchFilter
   */
  zone?: string;
}
/**
 *
 * @export
 * @interface AccountSearchRecord
 */
export interface AccountSearchRecord {
  /**
   *
   * @type {number}
   * @memberof AccountSearchRecord
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof AccountSearchRecord
   */
  accountShortName: string;
  /**
   *
   * @type {string}
   * @memberof AccountSearchRecord
   */
  accountName: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountSearchRecord
   */
  active: boolean;
  /**
   *
   * @type {AccountBlockType}
   * @memberof AccountSearchRecord
   */
  blockType: AccountBlockType;
  /**
   *
   * @type {string}
   * @memberof AccountSearchRecord
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof AccountSearchRecord
   */
  createDate?: Date;
  /**
   *
   * @type {number}
   * @memberof AccountSearchRecord
   */
  usersCount: number;
  /**
   *
   * @type {UserSearchRecord}
   * @memberof AccountSearchRecord
   */
  owner: UserSearchRecord;
  /**
   *
   * @type {PartnerDataDto}
   * @memberof AccountSearchRecord
   */
  partner?: PartnerDataDto;
  /**
   *
   * @type {Array<object>}
   * @memberof AccountSearchRecord
   */
  projectFeatures: Array<object>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  PAYMENTBLOCKED = 'PAYMENT_BLOCKED',
  FULLBLOCKED = 'FULL_BLOCKED',
}

/**
 *
 * @export
 * @interface AllowedAccountListItem
 */
export interface AllowedAccountListItem {
  /**
   *
   * @type {number}
   * @memberof AllowedAccountListItem
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AllowedAccountListItem
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof AllowedAccountListItem
   */
  default: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AllowedAccountListItem
   */
  owner: boolean;
}
/**
 *
 * @export
 * @interface AuthorizationOptions
 */
export interface AuthorizationOptions {
  /**
   *
   * @type {boolean}
   * @memberof AuthorizationOptions
   */
  ccSessionsAreEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthorizationOptions
   */
  smsCodeAuthorizationEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthorizationOptions
   */
  oauth2AuthorizationEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthorizationOptions
   */
  onpremOpenIDAuthorizationEnabled: boolean;
  /**
   *
   * @type {object}
   * @memberof AuthorizationOptions
   */
  loginPasswordAuthorizationEnabled: object;
}
/**
 *
 * @export
 * @interface AutoLoginDto
 */
export interface AutoLoginDto {
  /**
   *
   * @type {string}
   * @memberof AutoLoginDto
   */
  token?: string;
}
/**
 *
 * @export
 * @interface CaptchaOptions
 */
export interface CaptchaOptions {
  /**
   *
   * @type {boolean}
   * @memberof CaptchaOptions
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof CaptchaOptions
   */
  siteKey?: string;
}
/**
 *
 * @export
 * @interface ChangeAccountDto
 */
export interface ChangeAccountDto {
  /**
   *
   * @type {number}
   * @memberof ChangeAccountDto
   */
  accountId?: number;
}
/**
 *
 * @export
 * @interface ChangeCountryIsoCodeRequest
 */
export interface ChangeCountryIsoCodeRequest {
  /**
   *
   * @type {string}
   * @memberof ChangeCountryIsoCodeRequest
   */
  countryIsoCode: string;
}
/**
 *
 * @export
 * @interface ChangeEmailDto
 */
export interface ChangeEmailDto {
  /**
   *
   * @type {string}
   * @memberof ChangeEmailDto
   */
  redirectUrl: string;
  /**
   *
   * @type {ConversationalCloudProduct}
   * @memberof ChangeEmailDto
   */
  product?: ConversationalCloudProduct;
  /**
   *
   * @type {string}
   * @memberof ChangeEmailDto
   */
  newEmail: string;
}
/**
 *
 * @export
 * @interface ChangeFullNameDto
 */
export interface ChangeFullNameDto {
  /**
   *
   * @type {string}
   * @memberof ChangeFullNameDto
   */
  fullName: string;
}
/**
 *
 * @export
 * @interface ChangeRolesDto
 */
export interface ChangeRolesDto {
  /**
   *
   * @type {Array<string>}
   * @memberof ChangeRolesDto
   */
  roles: Array<string>;
}
/**
 *
 * @export
 * @interface ChangeUserPasswordDto
 */
export interface ChangeUserPasswordDto {
  /**
   *
   * @type {string}
   * @memberof ChangeUserPasswordDto
   */
  newPassword: string;
  /**
   *
   * @type {string}
   * @memberof ChangeUserPasswordDto
   */
  oldPassword: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ConversationalCloudProduct {
  Jaicp = 'jaicp',
  Aimylogic = 'aimylogic',
  Aimyvoice = 'aimyvoice',
  Cc = 'cc',
  Caila = 'caila',
  Aimychat = 'aimychat',
}

/**
 *
 * @export
 * @interface CreateAccountAsSadminDto
 */
export interface CreateAccountAsSadminDto {
  /**
   *
   * @type {string}
   * @memberof CreateAccountAsSadminDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateAccountAsSadminDto
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof CreateAccountAsSadminDto
   */
  ownerId: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateAccountAsSadminDto
   */
  defaultAccount: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateAccountAsSadminDto
   */
  projectFeatures: Array<string>;
}
/**
 *
 * @export
 * @interface CreateUserAsSadminDto
 */
export interface CreateUserAsSadminDto {
  /**
   *
   * @type {string}
   * @memberof CreateUserAsSadminDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserAsSadminDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserAsSadminDto
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUserAsSadminDto
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateUserAsSadminDto
   */
  internal: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateUserAsSadminDto
   */
  internalRoles: Array<string>;
}
/**
 *
 * @export
 * @interface DomainOptions
 */
export interface DomainOptions {
  /**
   *
   * @type {string}
   * @memberof DomainOptions
   */
  domain: string;
  /**
   *
   * @type {boolean}
   * @memberof DomainOptions
   */
  redirectAllowed: boolean;
  /**
   *
   * @type {string}
   * @memberof DomainOptions
   */
  defaultTheme: string;
  /**
   *
   * @type {ConversationalCloudProduct}
   * @memberof DomainOptions
   */
  product: ConversationalCloudProduct;
  /**
   *
   * @type {boolean}
   * @memberof DomainOptions
   */
  showInNavigation: boolean;
  /**
   *
   * @type {string}
   * @memberof DomainOptions
   */
  crispToken?: string;
  /**
   *
   * @type {{ [key: string]: ToSAndPoSConfig; }}
   * @memberof DomainOptions
   */
  tosAndPos: { [key: string]: ToSAndPoSConfig };
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  file: any;
}
/**
 *
 * @export
 * @interface InviteUserDto
 */
export interface InviteUserDto {
  /**
   *
   * @type {string}
   * @memberof InviteUserDto
   */
  login: string;
  /**
   *
   * @type {number}
   * @memberof InviteUserDto
   */
  accountId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof InviteUserDto
   */
  roles: Array<string>;
}
/**
 *
 * @export
 * @interface JustSessionUserData
 */
export interface JustSessionUserData {
  /**
   *
   * @type {number}
   * @memberof JustSessionUserData
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  accountShortName?: string;
  /**
   *
   * @type {number}
   * @memberof JustSessionUserData
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  language: string;
  /**
   *
   * @type {boolean}
   * @memberof JustSessionUserData
   */
  phoneVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JustSessionUserData
   */
  emailVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JustSessionUserData
   */
  emailVerificationRequired: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof JustSessionUserData
   */
  permissions: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof JustSessionUserData
   */
  features: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof JustSessionUserData
   */
  internal: boolean;
  /**
   *
   * @type {boolean}
   * @memberof JustSessionUserData
   */
  accountOwner: boolean;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof JustSessionUserData
   */
  countryIsoCode?: string;
}
/**
 *
 * @export
 * @interface LanguageOptions
 */
export interface LanguageOptions {
  /**
   *
   * @type {Locale}
   * @memberof LanguageOptions
   */
  default: Locale;
  /**
   *
   * @type {Array<Locale>}
   * @memberof LanguageOptions
   */
  allowed: Array<Locale>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum Locale {
  RU = 'RU',
  EN = 'EN',
  CN = 'CN',
  PT = 'PT',
  KZ = 'KZ',
  ES = 'ES',
}

/**
 *
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
  /**
   *
   * @type {string}
   * @memberof LoginDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LoginDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface MailVerificationInfo
 */
export interface MailVerificationInfo {
  /**
   *
   * @type {number}
   * @memberof MailVerificationInfo
   */
  remainingTimeMs: number;
  /**
   *
   * @type {string}
   * @memberof MailVerificationInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface NotEditableRolesChangeDto
 */
export interface NotEditableRolesChangeDto {
  /**
   *
   * @type {Array<string>}
   * @memberof NotEditableRolesChangeDto
   */
  rolesToAdd: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof NotEditableRolesChangeDto
   */
  rolesToRemove: Array<string>;
}
/**
 *
 * @export
 * @interface OptionsResponseDto
 */
export interface OptionsResponseDto {
  /**
   *
   * @type {CaptchaOptions}
   * @memberof OptionsResponseDto
   */
  captcha: CaptchaOptions;
  /**
   *
   * @type {RegistrationOptions}
   * @memberof OptionsResponseDto
   */
  registration: RegistrationOptions;
  /**
   *
   * @type {SiteAnalyticsOptions}
   * @memberof OptionsResponseDto
   */
  analytics: SiteAnalyticsOptions;
  /**
   *
   * @type {SecurityOptions}
   * @memberof OptionsResponseDto
   */
  security: SecurityOptions;
  /**
   *
   * @type {{ [key: string]: DomainOptions; }}
   * @memberof OptionsResponseDto
   */
  domains: { [key: string]: DomainOptions };
  /**
   *
   * @type {AuthorizationOptions}
   * @memberof OptionsResponseDto
   */
  authorization: AuthorizationOptions;
  /**
   *
   * @type {boolean}
   * @memberof OptionsResponseDto
   */
  universalLoginMenuEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OptionsResponseDto
   */
  hidePartnerBankDetails: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OptionsResponseDto
   */
  ccProfileEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OptionsResponseDto
   */
  euroInstance: boolean;
  /**
   *
   * @type {LanguageOptions}
   * @memberof OptionsResponseDto
   */
  language: LanguageOptions;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface PartnerDataDto
 */
export interface PartnerDataDto {
  /**
   *
   * @type {boolean}
   * @memberof PartnerDataDto
   */
  enabled?: boolean;
  /**
   *
   * @type {PartnerType}
   * @memberof PartnerDataDto
   */
  type?: PartnerType;
  /**
   *
   * @type {boolean}
   * @memberof PartnerDataDto
   */
  vip?: boolean;
  /**
   *
   * @type {PartnerState}
   * @memberof PartnerDataDto
   */
  partnerState?: PartnerState;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PartnerState {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  UNVERIFIED = 'UNVERIFIED',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PartnerType {
  JAICP = 'JAICP',
  AIMYLOGIC = 'AIMYLOGIC',
  JAICPANDAIMYLOGIC = 'JAICP_AND_AIMYLOGIC',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentSystem {
  YANDEXCASH = 'YANDEX_CASH',
  UNDEFINED = 'UNDEFINED',
  SYSTEMCASH = 'SYSTEM_CASH',
  STRIPE = 'STRIPE',
}

/**
 *
 * @export
 * @interface Read
 */
export interface Read {
  /**
   *
   * @type {number}
   * @memberof Read
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Read
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof Read
   */
  accountId?: number;
  /**
   *
   * @type {Read}
   * @memberof Read
   */
  feature?: Read;
}
/**
 *
 * @export
 * @interface RegistrationOptions
 */
export interface RegistrationOptions {
  /**
   *
   * @type {boolean}
   * @memberof RegistrationOptions
   */
  enabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RegistrationOptions
   */
  userNeedToSpecifyCountryIsoCode: boolean;
}
/**
 *
 * @export
 * @interface RegistrationRequestDto
 */
export interface RegistrationRequestDto {
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  countryIsoCode: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  language: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  redirectUrl: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  theme?: string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequestDto
   */
  referralToken?: string;
  /**
   *
   * @type {ConversationalCloudProduct}
   * @memberof RegistrationRequestDto
   */
  product?: ConversationalCloudProduct;
}
/**
 *
 * @export
 * @interface RegistrationResultDto
 */
export interface RegistrationResultDto {
  /**
   *
   * @type {JustSessionUserData}
   * @memberof RegistrationResultDto
   */
  userData: JustSessionUserData;
}
/**
 *
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDto
   */
  newPassword: string;
}
/**
 *
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
  /**
   *
   * @type {string}
   * @memberof RoleDto
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof RoleDto
   */
  internal: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RoleDto
   */
  editable: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RoleDto
   */
  custom: boolean;
  /**
   *
   * @type {number}
   * @memberof RoleDto
   */
  accountId?: number;
}
/**
 *
 * @export
 * @interface SearchResultAccountInvitationSearchDto
 */
export interface SearchResultAccountInvitationSearchDto {
  /**
   *
   * @type {Pagination}
   * @memberof SearchResultAccountInvitationSearchDto
   */
  paging: Pagination;
  /**
   *
   * @type {Array<AccountInvitationSearchDto>}
   * @memberof SearchResultAccountInvitationSearchDto
   */
  records: Array<AccountInvitationSearchDto>;
}
/**
 *
 * @export
 * @interface SearchResultAccountSearchRecord
 */
export interface SearchResultAccountSearchRecord {
  /**
   *
   * @type {Pagination}
   * @memberof SearchResultAccountSearchRecord
   */
  paging: Pagination;
  /**
   *
   * @type {Array<AccountSearchRecord>}
   * @memberof SearchResultAccountSearchRecord
   */
  records: Array<AccountSearchRecord>;
}
/**
 *
 * @export
 * @interface SearchResultUserSearchRecord
 */
export interface SearchResultUserSearchRecord {
  /**
   *
   * @type {Pagination}
   * @memberof SearchResultUserSearchRecord
   */
  paging: Pagination;
  /**
   *
   * @type {Array<UserSearchRecord>}
   * @memberof SearchResultUserSearchRecord
   */
  records: Array<UserSearchRecord>;
}
/**
 *
 * @export
 * @interface SecurityOptions
 */
export interface SecurityOptions {
  /**
   *
   * @type {string}
   * @memberof SecurityOptions
   */
  ccBaseUrl: string;
  /**
   *
   * @type {boolean}
   * @memberof SecurityOptions
   */
  usersCanChangeEmail: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SecurityOptions
   */
  usersCanChangePassword: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SecurityOptions
   */
  usersCanResetPassword: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SecurityOptions
   */
  usersCanCreateAccount: boolean;
}
/**
 *
 * @export
 * @interface SendResetPasswordDto
 */
export interface SendResetPasswordDto {
  /**
   *
   * @type {string}
   * @memberof SendResetPasswordDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SendResetPasswordDto
   */
  redirectUrl: string;
  /**
   *
   * @type {ConversationalCloudProduct}
   * @memberof SendResetPasswordDto
   */
  product?: ConversationalCloudProduct;
}
/**
 *
 * @export
 * @interface SendSmsCodeDto
 */
export interface SendSmsCodeDto {
  /**
   *
   * @type {string}
   * @memberof SendSmsCodeDto
   */
  phone: string;
}
/**
 *
 * @export
 * @interface SendVerificationEmailDto
 */
export interface SendVerificationEmailDto {
  /**
   *
   * @type {string}
   * @memberof SendVerificationEmailDto
   */
  redirectUrl: string;
  /**
   *
   * @type {ConversationalCloudProduct}
   * @memberof SendVerificationEmailDto
   */
  product?: ConversationalCloudProduct;
}
/**
 *
 * @export
 * @interface SessionLogoutDto
 */
export interface SessionLogoutDto {
  /**
   *
   * @type {string}
   * @memberof SessionLogoutDto
   */
  sessionId?: string;
}
/**
 *
 * @export
 * @interface Simple
 */
export interface Simple {
  /**
   *
   * @type {number}
   * @memberof Simple
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  login?: string;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  fullName?: string;
  /**
   *
   * @type {Date}
   * @memberof Simple
   */
  createDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Simple
   */
  internal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Simple
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  editorTheme?: string;
  /**
   *
   * @type {Locale}
   * @memberof Simple
   */
  language?: Locale;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  timeZone?: string;
  /**
   *
   * @type {string}
   * @memberof Simple
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof Simple
   */
  phoneVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Simple
   */
  emailVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Simple
   */
  emailVerificationRequired?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Simple
   */
  externalRegistration?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Simple
   */
  projectReportsEnabled?: boolean;
  /**
   *
   * @type {Array<Read>}
   * @memberof Simple
   */
  roles?: Array<Read>;
}
/**
 *
 * @export
 * @interface SimpleRead
 */
export interface SimpleRead {
  /**
   *
   * @type {number}
   * @memberof SimpleRead
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SimpleRead
   */
  accountId?: number;
  /**
   *
   * @type {boolean}
   * @memberof SimpleRead
   */
  enabled?: boolean;
  /**
   *
   * @type {PartnerType}
   * @memberof SimpleRead
   */
  type?: PartnerType;
  /**
   *
   * @type {boolean}
   * @memberof SimpleRead
   */
  vip?: boolean;
}
/**
 *
 * @export
 * @interface SiteAnalyticsOptions
 */
export interface SiteAnalyticsOptions {
  /**
   *
   * @type {boolean}
   * @memberof SiteAnalyticsOptions
   */
  gaEnabled: boolean;
}
/**
 *
 * @export
 * @interface ToSAndPoSConfig
 */
export interface ToSAndPoSConfig {
  /**
   *
   * @type {string}
   * @memberof ToSAndPoSConfig
   */
  termsOfUseUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ToSAndPoSConfig
   */
  privacyPolicyUrl?: string;
}
/**
 *
 * @export
 * @interface Total
 */
export interface Total {
  /**
   *
   * @type {number}
   * @memberof Total
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Total
   */
  shortName?: string;
  /**
   *
   * @type {number}
   * @memberof Total
   */
  ownerId?: number;
  /**
   *
   * @type {string}
   * @memberof Total
   */
  zone?: string;
  /**
   *
   * @type {string}
   * @memberof Total
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Total
   */
  description?: string;
  /**
   *
   * @type {Date}
   * @memberof Total
   */
  createDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof Total
   */
  deactivationDate?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Total
   */
  active?: boolean;
  /**
   *
   * @type {AccountStatus}
   * @memberof Total
   */
  accountStatus?: AccountStatus;
  /**
   *
   * @type {string}
   * @memberof Total
   */
  bxuid?: string;
  /**
   *
   * @type {PaymentSystem}
   * @memberof Total
   */
  paymentSystem?: PaymentSystem;
  /**
   *
   * @type {number}
   * @memberof Total
   */
  dialogSessionTimeLength?: number;
  /**
   *
   * @type {Simple}
   * @memberof Total
   */
  owner?: Simple;
  /**
   *
   * @type {Array<Read>}
   * @memberof Total
   */
  featureHolders?: Array<Read>;
  /**
   *
   * @type {boolean}
   * @memberof Total
   */
  test?: boolean;
  /**
   *
   * @type {SimpleRead}
   * @memberof Total
   */
  partner?: SimpleRead;
  /**
   *
   * @type {boolean}
   * @memberof Total
   */
  projectReportsEnabled?: boolean;
}
/**
 *
 * @export
 * @interface UniversalLoginResponseDto
 */
export interface UniversalLoginResponseDto {
  /**
   *
   * @type {string}
   * @memberof UniversalLoginResponseDto
   */
  host: string;
  /**
   *
   * @type {string}
   * @memberof UniversalLoginResponseDto
   */
  token: string;
}
/**
 *
 * @export
 * @interface UpdateAccountAsOwnerDto
 */
export interface UpdateAccountAsOwnerDto {
  /**
   *
   * @type {string}
   * @memberof UpdateAccountAsOwnerDto
   */
  fullName: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateAccountAsOwnerDto
   */
  enabled: boolean;
}
/**
 *
 * @export
 * @interface UpdateAccountAsSadminDto
 */
export interface UpdateAccountAsSadminDto {
  /**
   *
   * @type {string}
   * @memberof UpdateAccountAsSadminDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAccountAsSadminDto
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof UpdateAccountAsSadminDto
   */
  ownerId: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdateAccountAsSadminDto
   */
  active: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateAccountAsSadminDto
   */
  projectFeatures: Array<string>;
}
/**
 *
 * @export
 * @interface UpdateUserAsSadminDto
 */
export interface UpdateUserAsSadminDto {
  /**
   *
   * @type {number}
   * @memberof UpdateUserAsSadminDto
   */
  userId: number;
  /**
   *
   * @type {string}
   * @memberof UpdateUserAsSadminDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserAsSadminDto
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserAsSadminDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserAsSadminDto
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserAsSadminDto
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserAsSadminDto
   */
  enabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUserAsSadminDto
   */
  internal: boolean;
  /**
   *
   * @type {number}
   * @memberof UpdateUserAsSadminDto
   */
  defaultAccountId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateUserAsSadminDto
   */
  internalRoles: Array<string>;
}
/**
 *
 * @export
 * @interface UserBaseInfo
 */
export interface UserBaseInfo {
  /**
   *
   * @type {number}
   * @memberof UserBaseInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserBaseInfo
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof UserBaseInfo
   */
  email?: string;
}
/**
 *
 * @export
 * @interface UserDataDto
 */
export interface UserDataDto {
  /**
   *
   * @type {JustSessionUserData}
   * @memberof UserDataDto
   */
  userData: JustSessionUserData;
}
/**
 *
 * @export
 * @interface UserFullInfo
 */
export interface UserFullInfo {
  /**
   *
   * @type {number}
   * @memberof UserFullInfo
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserFullInfo
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof UserFullInfo
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserFullInfo
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UserFullInfo
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserFullInfo
   */
  enabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserFullInfo
   */
  internal: boolean;
  /**
   *
   * @type {Array<AllowedAccountListItem>}
   * @memberof UserFullInfo
   */
  accounts: Array<AllowedAccountListItem>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserFullInfo
   */
  internalRoles: Array<string>;
}
/**
 *
 * @export
 * @interface UserSearchRecord
 */
export interface UserSearchRecord {
  /**
   *
   * @type {number}
   * @memberof UserSearchRecord
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserSearchRecord
   */
  login: string;
  /**
   *
   * @type {string}
   * @memberof UserSearchRecord
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UserSearchRecord
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserSearchRecord
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof UserSearchRecord
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UserSearchRecord
   */
  phone?: string;
  /**
   *
   * @type {Array<RoleDto>}
   * @memberof UserSearchRecord
   */
  roles: Array<RoleDto>;
  /**
   *
   * @type {Array<AccountBaseInfo>}
   * @memberof UserSearchRecord
   */
  accounts: Array<AccountBaseInfo>;
  /**
   *
   * @type {boolean}
   * @memberof UserSearchRecord
   */
  owner: boolean;
}
/**
 *
 * @export
 * @interface VerifySmsCodeDto
 */
export interface VerifySmsCodeDto {
  /**
   *
   * @type {string}
   * @memberof VerifySmsCodeDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof VerifySmsCodeDto
   */
  phone: string;
}

/**
 * AccountInvitationEndpointApi - axios parameter creator
 * @export
 */
export const AccountInvitationEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} userId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptInvitationByToken(userId: number, token: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling acceptInvitationByToken.'
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling acceptInvitationByToken.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/account-invitation/accept-by-token`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptInvitationByUserId(userId: number, accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling acceptInvitationByUserId.'
        );
      }
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling acceptInvitationByUserId.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/account-invitation/accept-by-user-id`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      if (accountId !== undefined) {
        localVarQueryParameter['accountId'] = accountId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} invitationId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeInvitationRoles(invitationId: number, changeRolesDto: ChangeRolesDto, options: any = {}): RequestArgs {
      // verify required parameter 'invitationId' is not null or undefined
      if (invitationId === null || invitationId === undefined) {
        throw new RequiredError(
          'invitationId',
          'Required parameter invitationId was null or undefined when calling changeInvitationRoles.'
        );
      }
      // verify required parameter 'changeRolesDto' is not null or undefined
      if (changeRolesDto === null || changeRolesDto === undefined) {
        throw new RequiredError(
          'changeRolesDto',
          'Required parameter changeRolesDto was null or undefined when calling changeInvitationRoles.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/account-invitation/{invitationId}/roles/change`.replace(
        `{${'invitationId'}}`,
        encodeURIComponent(String(invitationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeRolesDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeRolesDto !== undefined ? changeRolesDto : {})
        : changeRolesDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} invitationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInvitation(invitationId: number, options: any = {}): RequestArgs {
      // verify required parameter 'invitationId' is not null or undefined
      if (invitationId === null || invitationId === undefined) {
        throw new RequiredError(
          'invitationId',
          'Required parameter invitationId was null or undefined when calling deleteInvitation.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/account-invitation/{invitationId}`.replace(
        `{${'invitationId'}}`,
        encodeURIComponent(String(invitationId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByLoginOrFullNameInAccount(
      accountId: number,
      page: number,
      size: number,
      searchText: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling findByLoginOrFullNameInAccount.'
        );
      }
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError(
          'page',
          'Required parameter page was null or undefined when calling findByLoginOrFullNameInAccount.'
        );
      }
      // verify required parameter 'size' is not null or undefined
      if (size === null || size === undefined) {
        throw new RequiredError(
          'size',
          'Required parameter size was null or undefined when calling findByLoginOrFullNameInAccount.'
        );
      }
      // verify required parameter 'searchText' is not null or undefined
      if (searchText === null || searchText === undefined) {
        throw new RequiredError(
          'searchText',
          'Required parameter searchText was null or undefined when calling findByLoginOrFullNameInAccount.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/account-invitation`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accountId !== undefined) {
        localVarQueryParameter['accountId'] = accountId;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (searchText !== undefined) {
        localVarQueryParameter['searchText'] = searchText;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationsByUser(userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling getInvitationsByUser.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/account-invitation/get-by-user`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InviteUserDto} inviteUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteUser(inviteUserDto: InviteUserDto, options: any = {}): RequestArgs {
      // verify required parameter 'inviteUserDto' is not null or undefined
      if (inviteUserDto === null || inviteUserDto === undefined) {
        throw new RequiredError(
          'inviteUserDto',
          'Required parameter inviteUserDto was null or undefined when calling inviteUser.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/account-invitation/invite`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof inviteUserDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(inviteUserDto !== undefined ? inviteUserDto : {})
        : inviteUserDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountInvitationEndpointApi - functional programming interface
 * @export
 */
export const AccountInvitationEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} userId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptInvitationByToken(
      userId: number,
      token: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).acceptInvitationByToken(
        userId,
        token,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptInvitationByUserId(
      userId: number,
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).acceptInvitationByUserId(
        userId,
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} invitationId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeInvitationRoles(
      invitationId: number,
      changeRolesDto: ChangeRolesDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).changeInvitationRoles(
        invitationId,
        changeRolesDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} invitationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInvitation(
      invitationId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).deleteInvitation(
        invitationId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByLoginOrFullNameInAccount(
      accountId: number,
      page: number,
      size: number,
      searchText: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultAccountInvitationSearchDto> {
      const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(
        configuration
      ).findByLoginOrFullNameInAccount(accountId, page, size, searchText, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationsByUser(
      userId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountInvitationDto>> {
      const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).getInvitationsByUser(
        userId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {InviteUserDto} inviteUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteUser(
      inviteUserDto: InviteUserDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountInvitationEndpointApiAxiosParamCreator(configuration).inviteUser(
        inviteUserDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountInvitationEndpointApi - factory interface
 * @export
 */
export const AccountInvitationEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} userId
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptInvitationByToken(userId: number, token: string, options?: any): AxiosPromise<number> {
      return AccountInvitationEndpointApiFp(configuration).acceptInvitationByToken(
        userId,
        token,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptInvitationByUserId(userId: number, accountId: number, options?: any): AxiosPromise<void> {
      return AccountInvitationEndpointApiFp(configuration).acceptInvitationByUserId(
        userId,
        accountId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} invitationId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeInvitationRoles(invitationId: number, changeRolesDto: ChangeRolesDto, options?: any): AxiosPromise<void> {
      return AccountInvitationEndpointApiFp(configuration).changeInvitationRoles(
        invitationId,
        changeRolesDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} invitationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInvitation(invitationId: number, options?: any): AxiosPromise<void> {
      return AccountInvitationEndpointApiFp(configuration).deleteInvitation(invitationId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findByLoginOrFullNameInAccount(
      accountId: number,
      page: number,
      size: number,
      searchText: string,
      options?: any
    ): AxiosPromise<SearchResultAccountInvitationSearchDto> {
      return AccountInvitationEndpointApiFp(configuration).findByLoginOrFullNameInAccount(
        accountId,
        page,
        size,
        searchText,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInvitationsByUser(userId: number, options?: any): AxiosPromise<Array<AccountInvitationDto>> {
      return AccountInvitationEndpointApiFp(configuration).getInvitationsByUser(userId, options)(axios, basePath);
    },
    /**
     *
     * @param {InviteUserDto} inviteUserDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inviteUser(inviteUserDto: InviteUserDto, options?: any): AxiosPromise<void> {
      return AccountInvitationEndpointApiFp(configuration).inviteUser(inviteUserDto, options)(axios, basePath);
    },
  };
};

/**
 * AccountInvitationEndpointApi - object-oriented interface
 * @export
 * @class AccountInvitationEndpointApi
 * @extends {BaseAPI}
 */
export class AccountInvitationEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} userId
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountInvitationEndpointApi
   */
  public acceptInvitationByToken(userId: number, token: string, options?: any) {
    return AccountInvitationEndpointApiFp(this.configuration).acceptInvitationByToken(
      userId,
      token,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountInvitationEndpointApi
   */
  public acceptInvitationByUserId(userId: number, accountId: number, options?: any) {
    return AccountInvitationEndpointApiFp(this.configuration).acceptInvitationByUserId(
      userId,
      accountId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} invitationId
   * @param {ChangeRolesDto} changeRolesDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountInvitationEndpointApi
   */
  public changeInvitationRoles(invitationId: number, changeRolesDto: ChangeRolesDto, options?: any) {
    return AccountInvitationEndpointApiFp(this.configuration).changeInvitationRoles(
      invitationId,
      changeRolesDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} invitationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountInvitationEndpointApi
   */
  public deleteInvitation(invitationId: number, options?: any) {
    return AccountInvitationEndpointApiFp(this.configuration).deleteInvitation(invitationId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId
   * @param {number} page
   * @param {number} size
   * @param {string} searchText
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountInvitationEndpointApi
   */
  public findByLoginOrFullNameInAccount(
    accountId: number,
    page: number,
    size: number,
    searchText: string,
    options?: any
  ) {
    return AccountInvitationEndpointApiFp(this.configuration).findByLoginOrFullNameInAccount(
      accountId,
      page,
      size,
      searchText,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountInvitationEndpointApi
   */
  public getInvitationsByUser(userId: number, options?: any) {
    return AccountInvitationEndpointApiFp(this.configuration).getInvitationsByUser(userId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {InviteUserDto} inviteUserDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountInvitationEndpointApi
   */
  public inviteUser(inviteUserDto: InviteUserDto, options?: any) {
    return AccountInvitationEndpointApiFp(this.configuration).inviteUser(inviteUserDto, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * AccountManagementEndpointApi - axios parameter creator
 * @export
 */
export const AccountManagementEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountAsSadmin(createAccountAsSadminDto: CreateAccountAsSadminDto, options: any = {}): RequestArgs {
      // verify required parameter 'createAccountAsSadminDto' is not null or undefined
      if (createAccountAsSadminDto === null || createAccountAsSadminDto === undefined) {
        throw new RequiredError(
          'createAccountAsSadminDto',
          'Required parameter createAccountAsSadminDto was null or undefined when calling createAccountAsSadmin.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sadmin/accounts`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createAccountAsSadminDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createAccountAsSadminDto !== undefined ? createAccountAsSadminDto : {})
        : createAccountAsSadminDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AccountSearchFilter} accountSearchFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAccountsByFilterAsSadmin(accountSearchFilter: AccountSearchFilter, options: any = {}): RequestArgs {
      // verify required parameter 'accountSearchFilter' is not null or undefined
      if (accountSearchFilter === null || accountSearchFilter === undefined) {
        throw new RequiredError(
          'accountSearchFilter',
          'Required parameter accountSearchFilter was null or undefined when calling findAccountsByFilterAsSadmin.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sadmin/accounts/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountSearchFilter !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountSearchFilter !== undefined ? accountSearchFilter : {})
        : accountSearchFilter || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountAsSadmin(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountAsSadmin.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sadmin/accounts/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountAsSadmin(
      accountId: number,
      updateAccountAsSadminDto: UpdateAccountAsSadminDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAccountAsSadmin.'
        );
      }
      // verify required parameter 'updateAccountAsSadminDto' is not null or undefined
      if (updateAccountAsSadminDto === null || updateAccountAsSadminDto === undefined) {
        throw new RequiredError(
          'updateAccountAsSadminDto',
          'Required parameter updateAccountAsSadminDto was null or undefined when calling updateAccountAsSadmin.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sadmin/accounts/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateAccountAsSadminDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateAccountAsSadminDto !== undefined ? updateAccountAsSadminDto : {})
        : updateAccountAsSadminDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountManagementEndpointApi - functional programming interface
 * @export
 */
export const AccountManagementEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountAsSadmin(
      createAccountAsSadminDto: CreateAccountAsSadminDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).createAccountAsSadmin(
        createAccountAsSadminDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {AccountSearchFilter} accountSearchFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAccountsByFilterAsSadmin(
      accountSearchFilter: AccountSearchFilter,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultAccountSearchRecord> {
      const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(
        configuration
      ).findAccountsByFilterAsSadmin(accountSearchFilter, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountAsSadmin(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFullInfo> {
      const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).getAccountAsSadmin(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountAsSadmin(
      accountId: number,
      updateAccountAsSadminDto: UpdateAccountAsSadminDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountManagementEndpointApiAxiosParamCreator(configuration).updateAccountAsSadmin(
        accountId,
        updateAccountAsSadminDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountManagementEndpointApi - factory interface
 * @export
 */
export const AccountManagementEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountAsSadmin(createAccountAsSadminDto: CreateAccountAsSadminDto, options?: any): AxiosPromise<number> {
      return AccountManagementEndpointApiFp(configuration).createAccountAsSadmin(createAccountAsSadminDto, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {AccountSearchFilter} accountSearchFilter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAccountsByFilterAsSadmin(
      accountSearchFilter: AccountSearchFilter,
      options?: any
    ): AxiosPromise<SearchResultAccountSearchRecord> {
      return AccountManagementEndpointApiFp(configuration).findAccountsByFilterAsSadmin(accountSearchFilter, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountAsSadmin(accountId: number, options?: any): AxiosPromise<AccountFullInfo> {
      return AccountManagementEndpointApiFp(configuration).getAccountAsSadmin(accountId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountAsSadmin(
      accountId: number,
      updateAccountAsSadminDto: UpdateAccountAsSadminDto,
      options?: any
    ): AxiosPromise<void> {
      return AccountManagementEndpointApiFp(configuration).updateAccountAsSadmin(
        accountId,
        updateAccountAsSadminDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AccountManagementEndpointApi - object-oriented interface
 * @export
 * @class AccountManagementEndpointApi
 * @extends {BaseAPI}
 */
export class AccountManagementEndpointApi extends BaseAPI {
  /**
   *
   * @param {CreateAccountAsSadminDto} createAccountAsSadminDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountManagementEndpointApi
   */
  public createAccountAsSadmin(createAccountAsSadminDto: CreateAccountAsSadminDto, options?: any) {
    return AccountManagementEndpointApiFp(this.configuration).createAccountAsSadmin(createAccountAsSadminDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {AccountSearchFilter} accountSearchFilter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountManagementEndpointApi
   */
  public findAccountsByFilterAsSadmin(accountSearchFilter: AccountSearchFilter, options?: any) {
    return AccountManagementEndpointApiFp(this.configuration).findAccountsByFilterAsSadmin(
      accountSearchFilter,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountManagementEndpointApi
   */
  public getAccountAsSadmin(accountId: number, options?: any) {
    return AccountManagementEndpointApiFp(this.configuration).getAccountAsSadmin(accountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId
   * @param {UpdateAccountAsSadminDto} updateAccountAsSadminDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountManagementEndpointApi
   */
  public updateAccountAsSadmin(accountId: number, updateAccountAsSadminDto: UpdateAccountAsSadminDto, options?: any) {
    return AccountManagementEndpointApiFp(this.configuration).updateAccountAsSadmin(
      accountId,
      updateAccountAsSadminDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * AccountUserEndpointApi - axios parameter creator
 * @export
 */
export const AccountUserEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAccountRoles(
      accountId: number,
      userId: number,
      changeRolesDto: ChangeRolesDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling changeAccountRoles.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling changeAccountRoles.'
        );
      }
      // verify required parameter 'changeRolesDto' is not null or undefined
      if (changeRolesDto === null || changeRolesDto === undefined) {
        throw new RequiredError(
          'changeRolesDto',
          'Required parameter changeRolesDto was null or undefined when calling changeAccountRoles.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/users/{userId}/roles/change`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeRolesDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeRolesDto !== undefined ? changeRolesDto : {})
        : changeRolesDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserFromAccount(accountId: number, userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteUserFromAccount.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling deleteUserFromAccount.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/users/{userId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountUserEndpointApi - functional programming interface
 * @export
 */
export const AccountUserEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAccountRoles(
      accountId: number,
      userId: number,
      changeRolesDto: ChangeRolesDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountUserEndpointApiAxiosParamCreator(configuration).changeAccountRoles(
        accountId,
        userId,
        changeRolesDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserFromAccount(
      accountId: number,
      userId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountUserEndpointApiAxiosParamCreator(configuration).deleteUserFromAccount(
        accountId,
        userId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountUserEndpointApi - factory interface
 * @export
 */
export const AccountUserEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {ChangeRolesDto} changeRolesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAccountRoles(
      accountId: number,
      userId: number,
      changeRolesDto: ChangeRolesDto,
      options?: any
    ): AxiosPromise<void> {
      return AccountUserEndpointApiFp(configuration).changeAccountRoles(
        accountId,
        userId,
        changeRolesDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserFromAccount(accountId: number, userId: number, options?: any): AxiosPromise<void> {
      return AccountUserEndpointApiFp(configuration).deleteUserFromAccount(accountId, userId, options)(axios, basePath);
    },
  };
};

/**
 * AccountUserEndpointApi - object-oriented interface
 * @export
 * @class AccountUserEndpointApi
 * @extends {BaseAPI}
 */
export class AccountUserEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} userId
   * @param {ChangeRolesDto} changeRolesDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountUserEndpointApi
   */
  public changeAccountRoles(accountId: number, userId: number, changeRolesDto: ChangeRolesDto, options?: any) {
    return AccountUserEndpointApiFp(this.configuration).changeAccountRoles(
      accountId,
      userId,
      changeRolesDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountUserEndpointApi
   */
  public deleteUserFromAccount(accountId: number, userId: number, options?: any) {
    return AccountUserEndpointApiFp(this.configuration).deleteUserFromAccount(
      accountId,
      userId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * AccountsEndpointV2Api - axios parameter creator
 * @export
 */
export const AccountsEndpointV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeCountryIsoCode(
      accountId: number,
      changeCountryIsoCodeRequest: ChangeCountryIsoCodeRequest,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling changeCountryIsoCode.'
        );
      }
      // verify required parameter 'changeCountryIsoCodeRequest' is not null or undefined
      if (changeCountryIsoCodeRequest === null || changeCountryIsoCodeRequest === undefined) {
        throw new RequiredError(
          'changeCountryIsoCodeRequest',
          'Required parameter changeCountryIsoCodeRequest was null or undefined when calling changeCountryIsoCode.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/change-country-iso-code`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeCountryIsoCodeRequest !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeCountryIsoCodeRequest !== undefined ? changeCountryIsoCodeRequest : {})
        : changeCountryIsoCodeRequest || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AccountFullNameDto} accountFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(accountFullNameDto: AccountFullNameDto, options: any = {}): RequestArgs {
      // verify required parameter 'accountFullNameDto' is not null or undefined
      if (accountFullNameDto === null || accountFullNameDto === undefined) {
        throw new RequiredError(
          'accountFullNameDto',
          'Required parameter accountFullNameDto was null or undefined when calling createAccount.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountFullNameDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountFullNameDto !== undefined ? accountFullNameDto : {})
        : accountFullNameDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccount.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(accountId: number, updateAccountAsOwnerDto: UpdateAccountAsOwnerDto, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAccount.'
        );
      }
      // verify required parameter 'updateAccountAsOwnerDto' is not null or undefined
      if (updateAccountAsOwnerDto === null || updateAccountAsOwnerDto === undefined) {
        throw new RequiredError(
          'updateAccountAsOwnerDto',
          'Required parameter updateAccountAsOwnerDto was null or undefined when calling updateAccount.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts/{accountId}`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateAccountAsOwnerDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateAccountAsOwnerDto !== undefined ? updateAccountAsOwnerDto : {})
        : updateAccountAsOwnerDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountsEndpointV2Api - functional programming interface
 * @export
 */
export const AccountsEndpointV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeCountryIsoCode(
      accountId: number,
      changeCountryIsoCodeRequest: ChangeCountryIsoCodeRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).changeCountryIsoCode(
        accountId,
        changeCountryIsoCodeRequest,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {AccountFullNameDto} accountFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(
      accountFullNameDto: AccountFullNameDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).createAccount(
        accountFullNameDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(accountId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Total> {
      const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).getAccount(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(
      accountId: number,
      updateAccountAsOwnerDto: UpdateAccountAsOwnerDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Total> {
      const localVarAxiosArgs = AccountsEndpointV2ApiAxiosParamCreator(configuration).updateAccount(
        accountId,
        updateAccountAsOwnerDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountsEndpointV2Api - factory interface
 * @export
 */
export const AccountsEndpointV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeCountryIsoCode(
      accountId: number,
      changeCountryIsoCodeRequest: ChangeCountryIsoCodeRequest,
      options?: any
    ): AxiosPromise<UserDataDto> {
      return AccountsEndpointV2ApiFp(configuration).changeCountryIsoCode(
        accountId,
        changeCountryIsoCodeRequest,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {AccountFullNameDto} accountFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(accountFullNameDto: AccountFullNameDto, options?: any): AxiosPromise<number> {
      return AccountsEndpointV2ApiFp(configuration).createAccount(accountFullNameDto, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(accountId: number, options?: any): AxiosPromise<Total> {
      return AccountsEndpointV2ApiFp(configuration).getAccount(accountId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(
      accountId: number,
      updateAccountAsOwnerDto: UpdateAccountAsOwnerDto,
      options?: any
    ): AxiosPromise<Total> {
      return AccountsEndpointV2ApiFp(configuration).updateAccount(
        accountId,
        updateAccountAsOwnerDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * AccountsEndpointV2Api - object-oriented interface
 * @export
 * @class AccountsEndpointV2Api
 * @extends {BaseAPI}
 */
export class AccountsEndpointV2Api extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {ChangeCountryIsoCodeRequest} changeCountryIsoCodeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsEndpointV2Api
   */
  public changeCountryIsoCode(
    accountId: number,
    changeCountryIsoCodeRequest: ChangeCountryIsoCodeRequest,
    options?: any
  ) {
    return AccountsEndpointV2ApiFp(this.configuration).changeCountryIsoCode(
      accountId,
      changeCountryIsoCodeRequest,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {AccountFullNameDto} accountFullNameDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsEndpointV2Api
   */
  public createAccount(accountFullNameDto: AccountFullNameDto, options?: any) {
    return AccountsEndpointV2ApiFp(this.configuration).createAccount(accountFullNameDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsEndpointV2Api
   */
  public getAccount(accountId: number, options?: any) {
    return AccountsEndpointV2ApiFp(this.configuration).getAccount(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {UpdateAccountAsOwnerDto} updateAccountAsOwnerDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsEndpointV2Api
   */
  public updateAccount(accountId: number, updateAccountAsOwnerDto: UpdateAccountAsOwnerDto, options?: any) {
    return AccountsEndpointV2ApiFp(this.configuration).updateAccount(
      accountId,
      updateAccountAsOwnerDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * AuthorizationEndpointApi - axios parameter creator
 * @export
 */
export const AuthorizationEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AutoLoginDto} autoLoginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoLogin(autoLoginDto: AutoLoginDto, options: any = {}): RequestArgs {
      // verify required parameter 'autoLoginDto' is not null or undefined
      if (autoLoginDto === null || autoLoginDto === undefined) {
        throw new RequiredError(
          'autoLoginDto',
          'Required parameter autoLoginDto was null or undefined when calling autoLogin.'
        );
      }
      const localVarPath = `/api/accountsadmin/public/authorization/auto-login`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof autoLoginDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(autoLoginDto !== undefined ? autoLoginDto : {})
        : autoLoginDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ChangeAccountDto} changeAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAccount(changeAccountDto: ChangeAccountDto, options: any = {}): RequestArgs {
      // verify required parameter 'changeAccountDto' is not null or undefined
      if (changeAccountDto === null || changeAccountDto === undefined) {
        throw new RequiredError(
          'changeAccountDto',
          'Required parameter changeAccountDto was null or undefined when calling changeAccount.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/authorization/change-account`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeAccountDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeAccountDto !== undefined ? changeAccountDto : {})
        : changeAccountDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsUserAuthorized(options: any = {}): RequestArgs {
      const localVarPath = `/api/accountsadmin/c/authorization/check-authorized`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginDto} loginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginDto: LoginDto, options: any = {}): RequestArgs {
      // verify required parameter 'loginDto' is not null or undefined
      if (loginDto === null || loginDto === undefined) {
        throw new RequiredError('loginDto', 'Required parameter loginDto was null or undefined when calling login.');
      }
      const localVarPath = `/api/accountsadmin/public/authorization/login`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof loginDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(loginDto !== undefined ? loginDto : {})
        : loginDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SessionLogoutDto} sessionLogoutDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(sessionLogoutDto: SessionLogoutDto, options: any = {}): RequestArgs {
      // verify required parameter 'sessionLogoutDto' is not null or undefined
      if (sessionLogoutDto === null || sessionLogoutDto === undefined) {
        throw new RequiredError(
          'sessionLogoutDto',
          'Required parameter sessionLogoutDto was null or undefined when calling logout.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/internal/authorization/logout`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sessionLogoutDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sessionLogoutDto !== undefined ? sessionLogoutDto : {})
        : sessionLogoutDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout1(options: any = {}): RequestArgs {
      const localVarPath = `/api/accountsadmin/c/authorization/logout`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthorizationEndpointApi - functional programming interface
 * @export
 */
export const AuthorizationEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AutoLoginDto} autoLoginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoLogin(
      autoLoginDto: AutoLoginDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).autoLogin(
        autoLoginDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {ChangeAccountDto} changeAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAccount(
      changeAccountDto: ChangeAccountDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).changeAccount(
        changeAccountDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsUserAuthorized(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).checkIsUserAuthorized(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {LoginDto} loginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginDto: LoginDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).login(loginDto, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SessionLogoutDto} sessionLogoutDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(
      sessionLogoutDto: SessionLogoutDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).logout(
        sessionLogoutDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout1(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AuthorizationEndpointApiAxiosParamCreator(configuration).logout1(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AuthorizationEndpointApi - factory interface
 * @export
 */
export const AuthorizationEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {AutoLoginDto} autoLoginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    autoLogin(autoLoginDto: AutoLoginDto, options?: any): AxiosPromise<UserDataDto> {
      return AuthorizationEndpointApiFp(configuration).autoLogin(autoLoginDto, options)(axios, basePath);
    },
    /**
     *
     * @param {ChangeAccountDto} changeAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeAccount(changeAccountDto: ChangeAccountDto, options?: any): AxiosPromise<void> {
      return AuthorizationEndpointApiFp(configuration).changeAccount(changeAccountDto, options)(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIsUserAuthorized(options?: any): AxiosPromise<UserDataDto> {
      return AuthorizationEndpointApiFp(configuration).checkIsUserAuthorized(options)(axios, basePath);
    },
    /**
     *
     * @param {LoginDto} loginDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginDto: LoginDto, options?: any): AxiosPromise<UserDataDto> {
      return AuthorizationEndpointApiFp(configuration).login(loginDto, options)(axios, basePath);
    },
    /**
     *
     * @param {SessionLogoutDto} sessionLogoutDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(sessionLogoutDto: SessionLogoutDto, options?: any): AxiosPromise<void> {
      return AuthorizationEndpointApiFp(configuration).logout(sessionLogoutDto, options)(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout1(options?: any): AxiosPromise<void> {
      return AuthorizationEndpointApiFp(configuration).logout1(options)(axios, basePath);
    },
  };
};

/**
 * AuthorizationEndpointApi - object-oriented interface
 * @export
 * @class AuthorizationEndpointApi
 * @extends {BaseAPI}
 */
export class AuthorizationEndpointApi extends BaseAPI {
  /**
   *
   * @param {AutoLoginDto} autoLoginDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorizationEndpointApi
   */
  public autoLogin(autoLoginDto: AutoLoginDto, options?: any) {
    return AuthorizationEndpointApiFp(this.configuration).autoLogin(autoLoginDto, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {ChangeAccountDto} changeAccountDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorizationEndpointApi
   */
  public changeAccount(changeAccountDto: ChangeAccountDto, options?: any) {
    return AuthorizationEndpointApiFp(this.configuration).changeAccount(changeAccountDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorizationEndpointApi
   */
  public checkIsUserAuthorized(options?: any) {
    return AuthorizationEndpointApiFp(this.configuration).checkIsUserAuthorized(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {LoginDto} loginDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorizationEndpointApi
   */
  public login(loginDto: LoginDto, options?: any) {
    return AuthorizationEndpointApiFp(this.configuration).login(loginDto, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {SessionLogoutDto} sessionLogoutDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorizationEndpointApi
   */
  public logout(sessionLogoutDto: SessionLogoutDto, options?: any) {
    return AuthorizationEndpointApiFp(this.configuration).logout(sessionLogoutDto, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthorizationEndpointApi
   */
  public logout1(options?: any) {
    return AuthorizationEndpointApiFp(this.configuration).logout1(options)(this.axios, this.basePath);
  }
}

/**
 * CcRolesEndpointApi - axios parameter creator
 * @export
 */
export const CcRolesEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeNotEditableRoles(
      accountId: number,
      userId: number,
      notEditableRolesChangeDto: NotEditableRolesChangeDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling changeNotEditableRoles.'
        );
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling changeNotEditableRoles.'
        );
      }
      // verify required parameter 'notEditableRolesChangeDto' is not null or undefined
      if (notEditableRolesChangeDto === null || notEditableRolesChangeDto === undefined) {
        throw new RequiredError(
          'notEditableRolesChangeDto',
          'Required parameter notEditableRolesChangeDto was null or undefined when calling changeNotEditableRoles.'
        );
      }
      const localVarPath = `/api/accountsadmin/accounts/{accountId}/users/{userId}/roles/not-editable/change`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof notEditableRolesChangeDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(notEditableRolesChangeDto !== undefined ? notEditableRolesChangeDto : {})
        : notEditableRolesChangeDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CcRolesEndpointApi - functional programming interface
 * @export
 */
export const CcRolesEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeNotEditableRoles(
      accountId: number,
      userId: number,
      notEditableRolesChangeDto: NotEditableRolesChangeDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CcRolesEndpointApiAxiosParamCreator(configuration).changeNotEditableRoles(
        accountId,
        userId,
        notEditableRolesChangeDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CcRolesEndpointApi - factory interface
 * @export
 */
export const CcRolesEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} userId
     * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeNotEditableRoles(
      accountId: number,
      userId: number,
      notEditableRolesChangeDto: NotEditableRolesChangeDto,
      options?: any
    ): AxiosPromise<void> {
      return CcRolesEndpointApiFp(configuration).changeNotEditableRoles(
        accountId,
        userId,
        notEditableRolesChangeDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * CcRolesEndpointApi - object-oriented interface
 * @export
 * @class CcRolesEndpointApi
 * @extends {BaseAPI}
 */
export class CcRolesEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} userId
   * @param {NotEditableRolesChangeDto} notEditableRolesChangeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CcRolesEndpointApi
   */
  public changeNotEditableRoles(
    accountId: number,
    userId: number,
    notEditableRolesChangeDto: NotEditableRolesChangeDto,
    options?: any
  ) {
    return CcRolesEndpointApiFp(this.configuration).changeNotEditableRoles(
      accountId,
      userId,
      notEditableRolesChangeDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * EmailVerificationEndpointApi - axios parameter creator
 * @export
 */
export const EmailVerificationEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVerificationEmailInfo(options: any = {}): RequestArgs {
      const localVarPath = `/api/accountsadmin/c/verification/verification-email-info`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SendVerificationEmailDto} sendVerificationEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerificationMail(sendVerificationEmailDto: SendVerificationEmailDto, options: any = {}): RequestArgs {
      // verify required parameter 'sendVerificationEmailDto' is not null or undefined
      if (sendVerificationEmailDto === null || sendVerificationEmailDto === undefined) {
        throw new RequiredError(
          'sendVerificationEmailDto',
          'Required parameter sendVerificationEmailDto was null or undefined when calling sendVerificationMail.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/verification/send-verification-mail`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendVerificationEmailDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendVerificationEmailDto !== undefined ? sendVerificationEmailDto : {})
        : sendVerificationEmailDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailVerificationEndpointApi - functional programming interface
 * @export
 */
export const EmailVerificationEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVerificationEmailInfo(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailVerificationInfo> {
      const localVarAxiosArgs = EmailVerificationEndpointApiAxiosParamCreator(configuration).getVerificationEmailInfo(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SendVerificationEmailDto} sendVerificationEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerificationMail(
      sendVerificationEmailDto: SendVerificationEmailDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EmailVerificationEndpointApiAxiosParamCreator(configuration).sendVerificationMail(
        sendVerificationEmailDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EmailVerificationEndpointApi - factory interface
 * @export
 */
export const EmailVerificationEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVerificationEmailInfo(options?: any): AxiosPromise<MailVerificationInfo> {
      return EmailVerificationEndpointApiFp(configuration).getVerificationEmailInfo(options)(axios, basePath);
    },
    /**
     *
     * @param {SendVerificationEmailDto} sendVerificationEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendVerificationMail(sendVerificationEmailDto: SendVerificationEmailDto, options?: any): AxiosPromise<void> {
      return EmailVerificationEndpointApiFp(configuration).sendVerificationMail(sendVerificationEmailDto, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * EmailVerificationEndpointApi - object-oriented interface
 * @export
 * @class EmailVerificationEndpointApi
 * @extends {BaseAPI}
 */
export class EmailVerificationEndpointApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailVerificationEndpointApi
   */
  public getVerificationEmailInfo(options?: any) {
    return EmailVerificationEndpointApiFp(this.configuration).getVerificationEmailInfo(options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {SendVerificationEmailDto} sendVerificationEmailDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailVerificationEndpointApi
   */
  public sendVerificationMail(sendVerificationEmailDto: SendVerificationEmailDto, options?: any) {
    return EmailVerificationEndpointApiFp(this.configuration).sendVerificationMail(sendVerificationEmailDto, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * OAuth2EndpointApi - axios parameter creator
 * @export
 */
export const OAuth2EndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} provider
     * @param {string} language
     * @param {ConversationalCloudProduct} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuth2PreLogin(
      provider: string,
      language: string,
      product?: ConversationalCloudProduct,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'provider' is not null or undefined
      if (provider === null || provider === undefined) {
        throw new RequiredError(
          'provider',
          'Required parameter provider was null or undefined when calling oAuth2PreLogin.'
        );
      }
      // verify required parameter 'language' is not null or undefined
      if (language === null || language === undefined) {
        throw new RequiredError(
          'language',
          'Required parameter language was null or undefined when calling oAuth2PreLogin.'
        );
      }
      const localVarPath = `/api/accountsadmin/public/authorization/oauth2/{provider}`.replace(
        `{${'provider'}}`,
        encodeURIComponent(String(provider))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      if (product !== undefined) {
        localVarQueryParameter['product'] = product;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OAuth2EndpointApi - functional programming interface
 * @export
 */
export const OAuth2EndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} provider
     * @param {string} language
     * @param {ConversationalCloudProduct} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuth2PreLogin(
      provider: string,
      language: string,
      product?: ConversationalCloudProduct,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = OAuth2EndpointApiAxiosParamCreator(configuration).oAuth2PreLogin(
        provider,
        language,
        product,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OAuth2EndpointApi - factory interface
 * @export
 */
export const OAuth2EndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} provider
     * @param {string} language
     * @param {ConversationalCloudProduct} [product]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuth2PreLogin(
      provider: string,
      language: string,
      product?: ConversationalCloudProduct,
      options?: any
    ): AxiosPromise<object> {
      return OAuth2EndpointApiFp(configuration).oAuth2PreLogin(provider, language, product, options)(axios, basePath);
    },
  };
};

/**
 * OAuth2EndpointApi - object-oriented interface
 * @export
 * @class OAuth2EndpointApi
 * @extends {BaseAPI}
 */
export class OAuth2EndpointApi extends BaseAPI {
  /**
   *
   * @param {string} provider
   * @param {string} language
   * @param {ConversationalCloudProduct} [product]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OAuth2EndpointApi
   */
  public oAuth2PreLogin(provider: string, language: string, product?: ConversationalCloudProduct, options?: any) {
    return OAuth2EndpointApiFp(this.configuration).oAuth2PreLogin(
      provider,
      language,
      product,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * OptionsEndpointApi - axios parameter creator
 * @export
 */
export const OptionsEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOptions(options: any = {}): RequestArgs {
      const localVarPath = `/api/accountsadmin/public/options`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OptionsEndpointApi - functional programming interface
 * @export
 */
export const OptionsEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOptions(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptionsResponseDto> {
      const localVarAxiosArgs = OptionsEndpointApiAxiosParamCreator(configuration).getOptions(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OptionsEndpointApi - factory interface
 * @export
 */
export const OptionsEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOptions(options?: any): AxiosPromise<OptionsResponseDto> {
      return OptionsEndpointApiFp(configuration).getOptions(options)(axios, basePath);
    },
  };
};

/**
 * OptionsEndpointApi - object-oriented interface
 * @export
 * @class OptionsEndpointApi
 * @extends {BaseAPI}
 */
export class OptionsEndpointApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OptionsEndpointApi
   */
  public getOptions(options?: any) {
    return OptionsEndpointApiFp(this.configuration).getOptions(options)(this.axios, this.basePath);
  }
}

/**
 * PasswordResetEndpointApi - axios parameter creator
 * @export
 */
export const PasswordResetEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(resetPasswordDto: ResetPasswordDto, options: any = {}): RequestArgs {
      // verify required parameter 'resetPasswordDto' is not null or undefined
      if (resetPasswordDto === null || resetPasswordDto === undefined) {
        throw new RequiredError(
          'resetPasswordDto',
          'Required parameter resetPasswordDto was null or undefined when calling resetPassword.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/password/reset`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof resetPasswordDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(resetPasswordDto !== undefined ? resetPasswordDto : {})
        : resetPasswordDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SendResetPasswordDto} sendResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendResetPasswordEmail(sendResetPasswordDto: SendResetPasswordDto, options: any = {}): RequestArgs {
      // verify required parameter 'sendResetPasswordDto' is not null or undefined
      if (sendResetPasswordDto === null || sendResetPasswordDto === undefined) {
        throw new RequiredError(
          'sendResetPasswordDto',
          'Required parameter sendResetPasswordDto was null or undefined when calling sendResetPasswordEmail.'
        );
      }
      const localVarPath = `/api/accountsadmin/public/password/reset/send-email`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendResetPasswordDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendResetPasswordDto !== undefined ? sendResetPasswordDto : {})
        : sendResetPasswordDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PasswordResetEndpointApi - functional programming interface
 * @export
 */
export const PasswordResetEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      resetPasswordDto: ResetPasswordDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PasswordResetEndpointApiAxiosParamCreator(configuration).resetPassword(
        resetPasswordDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {SendResetPasswordDto} sendResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendResetPasswordEmail(
      sendResetPasswordDto: SendResetPasswordDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PasswordResetEndpointApiAxiosParamCreator(configuration).sendResetPasswordEmail(
        sendResetPasswordDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * PasswordResetEndpointApi - factory interface
 * @export
 */
export const PasswordResetEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {ResetPasswordDto} resetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(resetPasswordDto: ResetPasswordDto, options?: any): AxiosPromise<void> {
      return PasswordResetEndpointApiFp(configuration).resetPassword(resetPasswordDto, options)(axios, basePath);
    },
    /**
     *
     * @param {SendResetPasswordDto} sendResetPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendResetPasswordEmail(sendResetPasswordDto: SendResetPasswordDto, options?: any): AxiosPromise<void> {
      return PasswordResetEndpointApiFp(configuration).sendResetPasswordEmail(sendResetPasswordDto, options)(
        axios,
        basePath
      );
    },
  };
};

/**
 * PasswordResetEndpointApi - object-oriented interface
 * @export
 * @class PasswordResetEndpointApi
 * @extends {BaseAPI}
 */
export class PasswordResetEndpointApi extends BaseAPI {
  /**
   *
   * @param {ResetPasswordDto} resetPasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PasswordResetEndpointApi
   */
  public resetPassword(resetPasswordDto: ResetPasswordDto, options?: any) {
    return PasswordResetEndpointApiFp(this.configuration).resetPassword(resetPasswordDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {SendResetPasswordDto} sendResetPasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PasswordResetEndpointApi
   */
  public sendResetPasswordEmail(sendResetPasswordDto: SendResetPasswordDto, options?: any) {
    return PasswordResetEndpointApiFp(this.configuration).sendResetPasswordEmail(sendResetPasswordDto, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * RegistrationEndpointApi - axios parameter creator
 * @export
 */
export const RegistrationEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectCountryIsoCode(options: any = {}): RequestArgs {
      const localVarPath = `/api/accountsadmin/public/registration/detect-country-iso-code`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RegistrationRequestDto} registrationRequestDto
     * @param {string} [CAPTCHA_BYPASS_TOKEN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      registrationRequestDto: RegistrationRequestDto,
      CAPTCHA_BYPASS_TOKEN?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'registrationRequestDto' is not null or undefined
      if (registrationRequestDto === null || registrationRequestDto === undefined) {
        throw new RequiredError(
          'registrationRequestDto',
          'Required parameter registrationRequestDto was null or undefined when calling register.'
        );
      }
      const localVarPath = `/api/accountsadmin/public/registration/register`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof registrationRequestDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(registrationRequestDto !== undefined ? registrationRequestDto : {})
        : registrationRequestDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RegistrationEndpointApi - functional programming interface
 * @export
 */
export const RegistrationEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectCountryIsoCode(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = RegistrationEndpointApiAxiosParamCreator(configuration).detectCountryIsoCode(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {RegistrationRequestDto} registrationRequestDto
     * @param {string} [CAPTCHA_BYPASS_TOKEN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      registrationRequestDto: RegistrationRequestDto,
      CAPTCHA_BYPASS_TOKEN?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationResultDto> {
      const localVarAxiosArgs = RegistrationEndpointApiAxiosParamCreator(configuration).register(
        registrationRequestDto,
        CAPTCHA_BYPASS_TOKEN,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RegistrationEndpointApi - factory interface
 * @export
 */
export const RegistrationEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectCountryIsoCode(options?: any): AxiosPromise<string> {
      return RegistrationEndpointApiFp(configuration).detectCountryIsoCode(options)(axios, basePath);
    },
    /**
     *
     * @param {RegistrationRequestDto} registrationRequestDto
     * @param {string} [CAPTCHA_BYPASS_TOKEN]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      registrationRequestDto: RegistrationRequestDto,
      CAPTCHA_BYPASS_TOKEN?: string,
      options?: any
    ): AxiosPromise<RegistrationResultDto> {
      return RegistrationEndpointApiFp(configuration).register(
        registrationRequestDto,
        CAPTCHA_BYPASS_TOKEN,
        options
      )(axios, basePath);
    },
  };
};

/**
 * RegistrationEndpointApi - object-oriented interface
 * @export
 * @class RegistrationEndpointApi
 * @extends {BaseAPI}
 */
export class RegistrationEndpointApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationEndpointApi
   */
  public detectCountryIsoCode(options?: any) {
    return RegistrationEndpointApiFp(this.configuration).detectCountryIsoCode(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {RegistrationRequestDto} registrationRequestDto
   * @param {string} [CAPTCHA_BYPASS_TOKEN]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationEndpointApi
   */
  public register(registrationRequestDto: RegistrationRequestDto, CAPTCHA_BYPASS_TOKEN?: string, options?: any) {
    return RegistrationEndpointApiFp(this.configuration).register(
      registrationRequestDto,
      CAPTCHA_BYPASS_TOKEN,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * RolesEndpointApi - axios parameter creator
 * @export
 */
export const RolesEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternalRoles(options: any = {}): RequestArgs {
      const localVarPath = `/api/accountsadmin/c/sadmin/roles/internal`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRolesAvailableToAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getRolesAvailableToAccount.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/available-roles`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RolesEndpointApi - functional programming interface
 * @export
 */
export const RolesEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternalRoles(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>> {
      const localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).getInternalRoles(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRolesAvailableToAccount(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>> {
      const localVarAxiosArgs = RolesEndpointApiAxiosParamCreator(configuration).getRolesAvailableToAccount(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RolesEndpointApi - factory interface
 * @export
 */
export const RolesEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternalRoles(options?: any): AxiosPromise<Array<RoleDto>> {
      return RolesEndpointApiFp(configuration).getInternalRoles(options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRolesAvailableToAccount(accountId: number, options?: any): AxiosPromise<Array<RoleDto>> {
      return RolesEndpointApiFp(configuration).getRolesAvailableToAccount(accountId, options)(axios, basePath);
    },
  };
};

/**
 * RolesEndpointApi - object-oriented interface
 * @export
 * @class RolesEndpointApi
 * @extends {BaseAPI}
 */
export class RolesEndpointApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RolesEndpointApi
   */
  public getInternalRoles(options?: any) {
    return RolesEndpointApiFp(this.configuration).getInternalRoles(options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RolesEndpointApi
   */
  public getRolesAvailableToAccount(accountId: number, options?: any) {
    return RolesEndpointApiFp(this.configuration).getRolesAvailableToAccount(accountId, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * SmsCodeEndpointApi - axios parameter creator
 * @export
 */
export const SmsCodeEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Send sms code for authorization
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSmsCodeForAuthorization(sendSmsCodeDto: SendSmsCodeDto, options: any = {}): RequestArgs {
      // verify required parameter 'sendSmsCodeDto' is not null or undefined
      if (sendSmsCodeDto === null || sendSmsCodeDto === undefined) {
        throw new RequiredError(
          'sendSmsCodeDto',
          'Required parameter sendSmsCodeDto was null or undefined when calling sendSmsCodeForAuthorization.'
        );
      }
      const localVarPath = `/api/accountsadmin/public/sms-code/send/authorization`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendSmsCodeDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendSmsCodeDto !== undefined ? sendSmsCodeDto : {})
        : sendSmsCodeDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send sms code for verification
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSmsCodeForPhoneVerification(sendSmsCodeDto: SendSmsCodeDto, options: any = {}): RequestArgs {
      // verify required parameter 'sendSmsCodeDto' is not null or undefined
      if (sendSmsCodeDto === null || sendSmsCodeDto === undefined) {
        throw new RequiredError(
          'sendSmsCodeDto',
          'Required parameter sendSmsCodeDto was null or undefined when calling sendSmsCodeForPhoneVerification.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sms-code/send/phone-verification`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendSmsCodeDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendSmsCodeDto !== undefined ? sendSmsCodeDto : {})
        : sendSmsCodeDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Verify phone by entered sms code
     * @param {VerifySmsCodeDto} verifySmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifySmsCode(verifySmsCodeDto: VerifySmsCodeDto, options: any = {}): RequestArgs {
      // verify required parameter 'verifySmsCodeDto' is not null or undefined
      if (verifySmsCodeDto === null || verifySmsCodeDto === undefined) {
        throw new RequiredError(
          'verifySmsCodeDto',
          'Required parameter verifySmsCodeDto was null or undefined when calling verifySmsCode.'
        );
      }
      const localVarPath = `/api/accountsadmin/public/sms-code/verify`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof verifySmsCodeDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(verifySmsCodeDto !== undefined ? verifySmsCodeDto : {})
        : verifySmsCodeDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SmsCodeEndpointApi - functional programming interface
 * @export
 */
export const SmsCodeEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Send sms code for authorization
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSmsCodeForAuthorization(
      sendSmsCodeDto: SendSmsCodeDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).sendSmsCodeForAuthorization(
        sendSmsCodeDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Send sms code for verification
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSmsCodeForPhoneVerification(
      sendSmsCodeDto: SendSmsCodeDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).sendSmsCodeForPhoneVerification(
        sendSmsCodeDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Verify phone by entered sms code
     * @param {VerifySmsCodeDto} verifySmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifySmsCode(
      verifySmsCodeDto: VerifySmsCodeDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = SmsCodeEndpointApiAxiosParamCreator(configuration).verifySmsCode(
        verifySmsCodeDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SmsCodeEndpointApi - factory interface
 * @export
 */
export const SmsCodeEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Send sms code for authorization
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSmsCodeForAuthorization(sendSmsCodeDto: SendSmsCodeDto, options?: any): AxiosPromise<void> {
      return SmsCodeEndpointApiFp(configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options)(axios, basePath);
    },
    /**
     * Send sms code for verification
     * @param {SendSmsCodeDto} sendSmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSmsCodeForPhoneVerification(sendSmsCodeDto: SendSmsCodeDto, options?: any): AxiosPromise<void> {
      return SmsCodeEndpointApiFp(configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options)(
        axios,
        basePath
      );
    },
    /**
     * Verify phone by entered sms code
     * @param {VerifySmsCodeDto} verifySmsCodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifySmsCode(verifySmsCodeDto: VerifySmsCodeDto, options?: any): AxiosPromise<UserDataDto> {
      return SmsCodeEndpointApiFp(configuration).verifySmsCode(verifySmsCodeDto, options)(axios, basePath);
    },
  };
};

/**
 * SmsCodeEndpointApi - object-oriented interface
 * @export
 * @class SmsCodeEndpointApi
 * @extends {BaseAPI}
 */
export class SmsCodeEndpointApi extends BaseAPI {
  /**
   * Send sms code for authorization
   * @param {SendSmsCodeDto} sendSmsCodeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsCodeEndpointApi
   */
  public sendSmsCodeForAuthorization(sendSmsCodeDto: SendSmsCodeDto, options?: any) {
    return SmsCodeEndpointApiFp(this.configuration).sendSmsCodeForAuthorization(sendSmsCodeDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Send sms code for verification
   * @param {SendSmsCodeDto} sendSmsCodeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsCodeEndpointApi
   */
  public sendSmsCodeForPhoneVerification(sendSmsCodeDto: SendSmsCodeDto, options?: any) {
    return SmsCodeEndpointApiFp(this.configuration).sendSmsCodeForPhoneVerification(sendSmsCodeDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   * Verify phone by entered sms code
   * @param {VerifySmsCodeDto} verifySmsCodeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsCodeEndpointApi
   */
  public verifySmsCode(verifySmsCodeDto: VerifySmsCodeDto, options?: any) {
    return SmsCodeEndpointApiFp(this.configuration).verifySmsCode(verifySmsCodeDto, options)(this.axios, this.basePath);
  }
}

/**
 * UniversalLoginEndpointApi - axios parameter creator
 * @export
 */
export const UniversalLoginEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalLogin(token: string, options: any = {}): RequestArgs {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError('token', 'Required parameter token was null or undefined when calling universalLogin.');
      }
      const localVarPath = `/api/accountsadmin/public/universal/login/login.gif`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalLoginRequest(options: any = {}): RequestArgs {
      const localVarPath = `/api/accountsadmin/c/universal/login/request`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UniversalLoginEndpointApi - functional programming interface
 * @export
 */
export const UniversalLoginEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalLogin(
      token: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = UniversalLoginEndpointApiAxiosParamCreator(configuration).universalLogin(
        token,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalLoginRequest(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniversalLoginResponseDto>> {
      const localVarAxiosArgs = UniversalLoginEndpointApiAxiosParamCreator(configuration).universalLoginRequest(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UniversalLoginEndpointApi - factory interface
 * @export
 */
export const UniversalLoginEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalLogin(token: string, options?: any): AxiosPromise<Array<string>> {
      return UniversalLoginEndpointApiFp(configuration).universalLogin(token, options)(axios, basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    universalLoginRequest(options?: any): AxiosPromise<Array<UniversalLoginResponseDto>> {
      return UniversalLoginEndpointApiFp(configuration).universalLoginRequest(options)(axios, basePath);
    },
  };
};

/**
 * UniversalLoginEndpointApi - object-oriented interface
 * @export
 * @class UniversalLoginEndpointApi
 * @extends {BaseAPI}
 */
export class UniversalLoginEndpointApi extends BaseAPI {
  /**
   *
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UniversalLoginEndpointApi
   */
  public universalLogin(token: string, options?: any) {
    return UniversalLoginEndpointApiFp(this.configuration).universalLogin(token, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UniversalLoginEndpointApi
   */
  public universalLoginRequest(options?: any) {
    return UniversalLoginEndpointApiFp(this.configuration).universalLoginRequest(options)(this.axios, this.basePath);
  }
}

/**
 * UserEndpointApi - axios parameter creator
 * @export
 */
export const UserEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} userId
     * @param {ChangeEmailDto} changeEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeEmail(userId: number, changeEmailDto: ChangeEmailDto, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling changeEmail.');
      }
      // verify required parameter 'changeEmailDto' is not null or undefined
      if (changeEmailDto === null || changeEmailDto === undefined) {
        throw new RequiredError(
          'changeEmailDto',
          'Required parameter changeEmailDto was null or undefined when calling changeEmail.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/email`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeEmailDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeEmailDto !== undefined ? changeEmailDto : {})
        : changeEmailDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {ChangeFullNameDto} changeFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeFullName(userId: number, changeFullNameDto: ChangeFullNameDto, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling changeFullName.'
        );
      }
      // verify required parameter 'changeFullNameDto' is not null or undefined
      if (changeFullNameDto === null || changeFullNameDto === undefined) {
        throw new RequiredError(
          'changeFullNameDto',
          'Required parameter changeFullNameDto was null or undefined when calling changeFullName.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/full-name`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeFullNameDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeFullNameDto !== undefined ? changeFullNameDto : {})
        : changeFullNameDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLanguage(userId: number, locale: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling changeLanguage.'
        );
      }
      // verify required parameter 'locale' is not null or undefined
      if (locale === null || locale === undefined) {
        throw new RequiredError(
          'locale',
          'Required parameter locale was null or undefined when calling changeLanguage.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/language`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locale !== undefined) {
        localVarQueryParameter['locale'] = locale;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {ChangeUserPasswordDto} changeUserPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(userId: number, changeUserPasswordDto: ChangeUserPasswordDto, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling changePassword.'
        );
      }
      // verify required parameter 'changeUserPasswordDto' is not null or undefined
      if (changeUserPasswordDto === null || changeUserPasswordDto === undefined) {
        throw new RequiredError(
          'changeUserPasswordDto',
          'Required parameter changeUserPasswordDto was null or undefined when calling changePassword.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/password`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof changeUserPasswordDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changeUserPasswordDto !== undefined ? changeUserPasswordDto : {})
        : changeUserPasswordDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAvatar(userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling deleteAvatar.');
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/avatar`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllowedAccounts(userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling getAllowedAccounts.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/allowed-accounts`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} actionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isCaptchaNeeded(userId: number, actionType: string, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling isCaptchaNeeded.'
        );
      }
      // verify required parameter 'actionType' is not null or undefined
      if (actionType === null || actionType === undefined) {
        throw new RequiredError(
          'actionType',
          'Required parameter actionType was null or undefined when calling isCaptchaNeeded.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/is-captcha-needed`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (actionType !== undefined) {
        localVarQueryParameter['actionType'] = actionType;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAndSetAvatar(userId: number, file: any, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling uploadAndSetAvatar.'
        );
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          'file',
          'Required parameter file was null or undefined when calling uploadAndSetAvatar.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/users/{userId}/upload-and-set-avatar`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserEndpointApi - functional programming interface
 * @export
 */
export const UserEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} userId
     * @param {ChangeEmailDto} changeEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeEmail(
      userId: number,
      changeEmailDto: ChangeEmailDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeEmail(
        userId,
        changeEmailDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {ChangeFullNameDto} changeFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeFullName(
      userId: number,
      changeFullNameDto: ChangeFullNameDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeFullName(
        userId,
        changeFullNameDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLanguage(
      userId: number,
      locale: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changeLanguage(userId, locale, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {ChangeUserPasswordDto} changeUserPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(
      userId: number,
      changeUserPasswordDto: ChangeUserPasswordDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).changePassword(
        userId,
        changeUserPasswordDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAvatar(
      userId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).deleteAvatar(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllowedAccounts(
      userId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllowedAccountListItem>> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).getAllowedAccounts(userId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} actionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isCaptchaNeeded(
      userId: number,
      actionType: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).isCaptchaNeeded(
        userId,
        actionType,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAndSetAvatar(
      userId: number,
      file: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataDto> {
      const localVarAxiosArgs = UserEndpointApiAxiosParamCreator(configuration).uploadAndSetAvatar(
        userId,
        file,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserEndpointApi - factory interface
 * @export
 */
export const UserEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} userId
     * @param {ChangeEmailDto} changeEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeEmail(userId: number, changeEmailDto: ChangeEmailDto, options?: any): AxiosPromise<void> {
      return UserEndpointApiFp(configuration).changeEmail(userId, changeEmailDto, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {ChangeFullNameDto} changeFullNameDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeFullName(userId: number, changeFullNameDto: ChangeFullNameDto, options?: any): AxiosPromise<UserDataDto> {
      return UserEndpointApiFp(configuration).changeFullName(userId, changeFullNameDto, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {string} locale
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLanguage(userId: number, locale: string, options?: any): AxiosPromise<UserDataDto> {
      return UserEndpointApiFp(configuration).changeLanguage(userId, locale, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {ChangeUserPasswordDto} changeUserPasswordDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(userId: number, changeUserPasswordDto: ChangeUserPasswordDto, options?: any): AxiosPromise<void> {
      return UserEndpointApiFp(configuration).changePassword(userId, changeUserPasswordDto, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAvatar(userId: number, options?: any): AxiosPromise<UserDataDto> {
      return UserEndpointApiFp(configuration).deleteAvatar(userId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllowedAccounts(userId: number, options?: any): AxiosPromise<Array<AllowedAccountListItem>> {
      return UserEndpointApiFp(configuration).getAllowedAccounts(userId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {string} actionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isCaptchaNeeded(userId: number, actionType: string, options?: any): AxiosPromise<boolean> {
      return UserEndpointApiFp(configuration).isCaptchaNeeded(userId, actionType, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAndSetAvatar(userId: number, file: any, options?: any): AxiosPromise<UserDataDto> {
      return UserEndpointApiFp(configuration).uploadAndSetAvatar(userId, file, options)(axios, basePath);
    },
  };
};

/**
 * UserEndpointApi - object-oriented interface
 * @export
 * @class UserEndpointApi
 * @extends {BaseAPI}
 */
export class UserEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} userId
   * @param {ChangeEmailDto} changeEmailDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public changeEmail(userId: number, changeEmailDto: ChangeEmailDto, options?: any) {
    return UserEndpointApiFp(this.configuration).changeEmail(
      userId,
      changeEmailDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {ChangeFullNameDto} changeFullNameDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public changeFullName(userId: number, changeFullNameDto: ChangeFullNameDto, options?: any) {
    return UserEndpointApiFp(this.configuration).changeFullName(
      userId,
      changeFullNameDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {string} locale
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public changeLanguage(userId: number, locale: string, options?: any) {
    return UserEndpointApiFp(this.configuration).changeLanguage(userId, locale, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {ChangeUserPasswordDto} changeUserPasswordDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public changePassword(userId: number, changeUserPasswordDto: ChangeUserPasswordDto, options?: any) {
    return UserEndpointApiFp(this.configuration).changePassword(
      userId,
      changeUserPasswordDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public deleteAvatar(userId: number, options?: any) {
    return UserEndpointApiFp(this.configuration).deleteAvatar(userId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public getAllowedAccounts(userId: number, options?: any) {
    return UserEndpointApiFp(this.configuration).getAllowedAccounts(userId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {string} actionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public isCaptchaNeeded(userId: number, actionType: string, options?: any) {
    return UserEndpointApiFp(this.configuration).isCaptchaNeeded(
      userId,
      actionType,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserEndpointApi
   */
  public uploadAndSetAvatar(userId: number, file: any, options?: any) {
    return UserEndpointApiFp(this.configuration).uploadAndSetAvatar(userId, file, options)(this.axios, this.basePath);
  }
}

/**
 * UserManagementEndpointApi - axios parameter creator
 * @export
 */
export const UserManagementEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateUserAsSadminDto} createUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserAsSadmin(createUserAsSadminDto: CreateUserAsSadminDto, options: any = {}): RequestArgs {
      // verify required parameter 'createUserAsSadminDto' is not null or undefined
      if (createUserAsSadminDto === null || createUserAsSadminDto === undefined) {
        throw new RequiredError(
          'createUserAsSadminDto',
          'Required parameter createUserAsSadminDto was null or undefined when calling createUserAsSadmin.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sadmin/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createUserAsSadminDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createUserAsSadminDto !== undefined ? createUserAsSadminDto : {})
        : createUserAsSadminDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [searchText]
     * @param {boolean} [internal]
     * @param {boolean} [fetchRoles]
     * @param {boolean} [fetchAccounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsersByFilterAsSadmin(
      page?: number,
      size?: number,
      searchText?: string,
      internal?: boolean,
      fetchRoles?: boolean,
      fetchAccounts?: boolean,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/api/accountsadmin/c/sadmin/users/search`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (searchText !== undefined) {
        localVarQueryParameter['searchText'] = searchText;
      }

      if (internal !== undefined) {
        localVarQueryParameter['internal'] = internal;
      }

      if (fetchRoles !== undefined) {
        localVarQueryParameter['fetchRoles'] = fetchRoles;
      }

      if (fetchAccounts !== undefined) {
        localVarQueryParameter['fetchAccounts'] = fetchAccounts;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAsSadmin(userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling getUserAsSadmin.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sadmin/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAsSadmin(userId: number, updateUserAsSadminDto: UpdateUserAsSadminDto, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling updateUserAsSadmin.'
        );
      }
      // verify required parameter 'updateUserAsSadminDto' is not null or undefined
      if (updateUserAsSadminDto === null || updateUserAsSadminDto === undefined) {
        throw new RequiredError(
          'updateUserAsSadminDto',
          'Required parameter updateUserAsSadminDto was null or undefined when calling updateUserAsSadmin.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/sadmin/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateUserAsSadminDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateUserAsSadminDto !== undefined ? updateUserAsSadminDto : {})
        : updateUserAsSadminDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserManagementEndpointApi - functional programming interface
 * @export
 */
export const UserManagementEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateUserAsSadminDto} createUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserAsSadmin(
      createUserAsSadminDto: CreateUserAsSadminDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
      const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).createUserAsSadmin(
        createUserAsSadminDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [searchText]
     * @param {boolean} [internal]
     * @param {boolean} [fetchRoles]
     * @param {boolean} [fetchAccounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsersByFilterAsSadmin(
      page?: number,
      size?: number,
      searchText?: string,
      internal?: boolean,
      fetchRoles?: boolean,
      fetchAccounts?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultUserSearchRecord> {
      const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).findUsersByFilterAsSadmin(
        page,
        size,
        searchText,
        internal,
        fetchRoles,
        fetchAccounts,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAsSadmin(
      userId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserFullInfo> {
      const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).getUserAsSadmin(
        userId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAsSadmin(
      userId: number,
      updateUserAsSadminDto: UpdateUserAsSadminDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserManagementEndpointApiAxiosParamCreator(configuration).updateUserAsSadmin(
        userId,
        updateUserAsSadminDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserManagementEndpointApi - factory interface
 * @export
 */
export const UserManagementEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {CreateUserAsSadminDto} createUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserAsSadmin(createUserAsSadminDto: CreateUserAsSadminDto, options?: any): AxiosPromise<number> {
      return UserManagementEndpointApiFp(configuration).createUserAsSadmin(createUserAsSadminDto, options)(
        axios,
        basePath
      );
    },
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [searchText]
     * @param {boolean} [internal]
     * @param {boolean} [fetchRoles]
     * @param {boolean} [fetchAccounts]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsersByFilterAsSadmin(
      page?: number,
      size?: number,
      searchText?: string,
      internal?: boolean,
      fetchRoles?: boolean,
      fetchAccounts?: boolean,
      options?: any
    ): AxiosPromise<SearchResultUserSearchRecord> {
      return UserManagementEndpointApiFp(configuration).findUsersByFilterAsSadmin(
        page,
        size,
        searchText,
        internal,
        fetchRoles,
        fetchAccounts,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserAsSadmin(userId: number, options?: any): AxiosPromise<UserFullInfo> {
      return UserManagementEndpointApiFp(configuration).getUserAsSadmin(userId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserAsSadmin(
      userId: number,
      updateUserAsSadminDto: UpdateUserAsSadminDto,
      options?: any
    ): AxiosPromise<void> {
      return UserManagementEndpointApiFp(configuration).updateUserAsSadmin(
        userId,
        updateUserAsSadminDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * UserManagementEndpointApi - object-oriented interface
 * @export
 * @class UserManagementEndpointApi
 * @extends {BaseAPI}
 */
export class UserManagementEndpointApi extends BaseAPI {
  /**
   *
   * @param {CreateUserAsSadminDto} createUserAsSadminDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementEndpointApi
   */
  public createUserAsSadmin(createUserAsSadminDto: CreateUserAsSadminDto, options?: any) {
    return UserManagementEndpointApiFp(this.configuration).createUserAsSadmin(createUserAsSadminDto, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @param {number} [page]
   * @param {number} [size]
   * @param {string} [searchText]
   * @param {boolean} [internal]
   * @param {boolean} [fetchRoles]
   * @param {boolean} [fetchAccounts]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementEndpointApi
   */
  public findUsersByFilterAsSadmin(
    page?: number,
    size?: number,
    searchText?: string,
    internal?: boolean,
    fetchRoles?: boolean,
    fetchAccounts?: boolean,
    options?: any
  ) {
    return UserManagementEndpointApiFp(this.configuration).findUsersByFilterAsSadmin(
      page,
      size,
      searchText,
      internal,
      fetchRoles,
      fetchAccounts,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementEndpointApi
   */
  public getUserAsSadmin(userId: number, options?: any) {
    return UserManagementEndpointApiFp(this.configuration).getUserAsSadmin(userId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {UpdateUserAsSadminDto} updateUserAsSadminDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementEndpointApi
   */
  public updateUserAsSadmin(userId: number, updateUserAsSadminDto: UpdateUserAsSadminDto, options?: any) {
    return UserManagementEndpointApiFp(this.configuration).updateUserAsSadmin(
      userId,
      updateUserAsSadminDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * UserSearchEndpointApi - axios parameter creator
 * @export
 */
export const UserSearchEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAccountUsers(
      accountId: number,
      page: number,
      size: number,
      searchText: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling findAccountUsers.'
        );
      }
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError('page', 'Required parameter page was null or undefined when calling findAccountUsers.');
      }
      // verify required parameter 'size' is not null or undefined
      if (size === null || size === undefined) {
        throw new RequiredError('size', 'Required parameter size was null or undefined when calling findAccountUsers.');
      }
      // verify required parameter 'searchText' is not null or undefined
      if (searchText === null || searchText === undefined) {
        throw new RequiredError(
          'searchText',
          'Required parameter searchText was null or undefined when calling findAccountUsers.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/accounts/{accountId}/users`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (searchText !== undefined) {
        localVarQueryParameter['searchText'] = searchText;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} page
     * @param {number} size
     * @param {number} accountId
     * @param {string} [login]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsers(page: number, size: number, accountId: number, login?: string, options: any = {}): RequestArgs {
      // verify required parameter 'page' is not null or undefined
      if (page === null || page === undefined) {
        throw new RequiredError('page', 'Required parameter page was null or undefined when calling findUsers.');
      }
      // verify required parameter 'size' is not null or undefined
      if (size === null || size === undefined) {
        throw new RequiredError('size', 'Required parameter size was null or undefined when calling findUsers.');
      }
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling findUsers.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/internal/users`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (login !== undefined) {
        localVarQueryParameter['login'] = login;
      }

      if (accountId !== undefined) {
        localVarQueryParameter['accountId'] = accountId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsers1(userId: number, accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError('userId', 'Required parameter userId was null or undefined when calling findUsers1.');
      }
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling findUsers1.'
        );
      }
      const localVarPath = `/api/accountsadmin/c/internal/users/{userId}`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accountId !== undefined) {
        localVarQueryParameter['accountId'] = accountId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserSearchEndpointApi - functional programming interface
 * @export
 */
export const UserSearchEndpointApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAccountUsers(
      accountId: number,
      page: number,
      size: number,
      searchText: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultUserSearchRecord> {
      const localVarAxiosArgs = UserSearchEndpointApiAxiosParamCreator(configuration).findAccountUsers(
        accountId,
        page,
        size,
        searchText,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} page
     * @param {number} size
     * @param {number} accountId
     * @param {string} [login]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsers(
      page: number,
      size: number,
      accountId: number,
      login?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultUserSearchRecord> {
      const localVarAxiosArgs = UserSearchEndpointApiAxiosParamCreator(configuration).findUsers(
        page,
        size,
        accountId,
        login,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsers1(
      userId: number,
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchRecord> {
      const localVarAxiosArgs = UserSearchEndpointApiAxiosParamCreator(configuration).findUsers1(
        userId,
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UserSearchEndpointApi - factory interface
 * @export
 */
export const UserSearchEndpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} accountId
     * @param {number} page
     * @param {number} size
     * @param {string} searchText
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAccountUsers(
      accountId: number,
      page: number,
      size: number,
      searchText: string,
      options?: any
    ): AxiosPromise<SearchResultUserSearchRecord> {
      return UserSearchEndpointApiFp(configuration).findAccountUsers(
        accountId,
        page,
        size,
        searchText,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} page
     * @param {number} size
     * @param {number} accountId
     * @param {string} [login]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsers(
      page: number,
      size: number,
      accountId: number,
      login?: string,
      options?: any
    ): AxiosPromise<SearchResultUserSearchRecord> {
      return UserSearchEndpointApiFp(configuration).findUsers(page, size, accountId, login, options)(axios, basePath);
    },
    /**
     *
     * @param {number} userId
     * @param {number} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUsers1(userId: number, accountId: number, options?: any): AxiosPromise<UserSearchRecord> {
      return UserSearchEndpointApiFp(configuration).findUsers1(userId, accountId, options)(axios, basePath);
    },
  };
};

/**
 * UserSearchEndpointApi - object-oriented interface
 * @export
 * @class UserSearchEndpointApi
 * @extends {BaseAPI}
 */
export class UserSearchEndpointApi extends BaseAPI {
  /**
   *
   * @param {number} accountId
   * @param {number} page
   * @param {number} size
   * @param {string} searchText
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSearchEndpointApi
   */
  public findAccountUsers(accountId: number, page: number, size: number, searchText: string, options?: any) {
    return UserSearchEndpointApiFp(this.configuration).findAccountUsers(
      accountId,
      page,
      size,
      searchText,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} page
   * @param {number} size
   * @param {number} accountId
   * @param {string} [login]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSearchEndpointApi
   */
  public findUsers(page: number, size: number, accountId: number, login?: string, options?: any) {
    return UserSearchEndpointApiFp(this.configuration).findUsers(
      page,
      size,
      accountId,
      login,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} userId
   * @param {number} accountId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSearchEndpointApi
   */
  public findUsers1(userId: number, accountId: number, options?: any) {
    return UserSearchEndpointApiFp(this.configuration).findUsers1(
      userId,
      accountId,
      options
    )(this.axios, this.basePath);
  }
}
