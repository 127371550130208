import { isEnabledFunction, isEuroInstance } from '../isAccessFunction';

const prefix = '@botprojects/';

export const FETCH_PROJECTS = prefix + 'FETCH_PROJECTS';
export const FETCH_PROJECTS_PENDING = prefix + 'FETCH_PROJECTS_PENDING';
export const FETCH_PROJECTS_FULFILLED = prefix + 'FETCH_PROJECTS_FULFILLED';
export const FETCH_PROJECTS_REJECTED = prefix + 'FETCH_PROJECTS_REJECTED';

export const FETCH_PROJECTS_UNIQ_CLIENTS = prefix + 'FETCH_PROJECTS_UNIQ_CLIENTS';
export const FETCH_PROJECTS_UNIQ_CLIENTS_PENDING = prefix + 'FETCH_PROJECTS_UNIQ_CLIENTS_PENDING';
export const FETCH_PROJECTS_UNIQ_CLIENTS_FULFILLED = prefix + 'FETCH_PROJECTS_UNIQ_CLIENTS_FULFILLED';
export const FETCH_PROJECTS_UNIQ_CLIENTS_REJECTED = prefix + 'FETCH_PROJECTS_UNIQ_CLIENTS_REJECTED';

export const CREATE_PROJECT = prefix + 'CREATE_PROJECT';
export const CREATE_PROJECT_PENDING = prefix + 'CREATE_PROJECT_PENDING';
export const CREATE_PROJECT_FULFILLED = prefix + 'CREATE_PROJECT_FULFILLED';
export const CREATE_PROJECT_REJECTED = prefix + 'CREATE_PROJECT_REJECTED';

export const SAVE_EDITABLE_PROJECT = prefix + 'SAVE_EDITABLE_PROJECT';
export const SAVE_EDITABLE_PROJECT_PENDING = prefix + 'SAVE_EDITABLE_PROJECT_PENDING';
export const SAVE_EDITABLE_PROJECT_FULFILLED = prefix + 'SAVE_EDITABLE_PROJECT_FULFILLED';
export const SAVE_EDITABLE_PROJECT_REJECTED = prefix + 'SAVE_EDITABLE_PROJECT_REJECTED';

export const REMOVE_EDITABLE_PROJECT = prefix + 'REMOVE_EDITABLE_PROJECT';
export const REMOVE_EDITABLE_PROJECT_PENDING = prefix + 'REMOVE_EDITABLE_PROJECT_PENDING';
export const REMOVE_EDITABLE_PROJECT_FULFILLED = prefix + 'REMOVE_EDITABLE_PROJECT_FULFILLED';
export const REMOVE_EDITABLE_PROJECT_REJECTED = prefix + 'REMOVE_EDITABLE_PROJECT_REJECTED';

export const CLONE_PROJECT = prefix + 'CLONE_PROJECT';
export const CLONE_PROJECT_PENDING = prefix + 'CLONE_PROJECT_PENDING';
export const CLONE_PROJECT_FULFILLED = prefix + 'CLONE_PROJECT_FULFILLED';
export const CLONE_PROJECT_REJECTED = prefix + 'CLONE_PROJECT_REJECTED';

export const SET_BOT_TO_DELETE = prefix + 'SET_BOT_TO_DELETE';
export const FINAL_DELETE = prefix + 'FINAL_DELETE';
export const UPDATE_PROJECT_TAGS = prefix + 'UPDATE_PROJECT_TAGS';
export const DELETE_ACCOUNT_TAG = prefix + 'DELETE_ACCOUNT_TAG';

export const UPDATE_CHANNELS = prefix + 'UPDATE_CHANNELS';

export const languages = [
  {
    label: 'Русский (RU)',
    value: 'ru',
  },
  {
    label: 'English (EN)',
    value: 'eng',
  },
  {
    label: 'Chinese (CN)',
    value: 'cn',
  },
];

export const euroLanguages = [
  {
    label: 'English (EN)',
    value: 'eng',
  },
  {
    label: 'Chinese (CN)',
    value: 'cn',
  },
  {
    label: 'Português (PT)',
    value: 'pt',
  },
];

export const onlyWithCailaLanguages = [
  {
    label: 'Español (ES)',
    value: 'es',
  },
  {
    label: 'Português (PT)',
    value: 'pt',
  },
  {
    label: 'Қазақ (KK)',
    value: 'kz',
  },
];

export const euroOnlyWithCailaLanguages = [
  {
    label: 'Español (ES)',
    value: 'es',
  },
  {
    label: 'Português (PT)',
    value: 'pt',
  },
];

export const getLanguagesBasedOnFeatures = isCallBot => {
  let result = getLanguages(isCallBot);

  const resultMap = {};

  result.forEach(language => (resultMap[language.value] = language));

  return Object.values(resultMap);
};

function getLanguages(isCallBot) {
  if (isEuroInstance() && isEnabledFunction('nlu.ds')) {
    return [...euroLanguages, ...euroOnlyWithCailaLanguages];
  } else if (isEuroInstance()) {
    return euroLanguages;
  }
  if (isEnabledFunction('nlu.ds') && !isCallBot) {
    return languages.concat(onlyWithCailaLanguages);
  }
  return languages;
}

export const langBackMap = {
  EN: 'eng',
  RU: 'ru',
  CN: 'cn',
  PT: 'pt',
  ES: 'es',
  KZ: 'kz',
};
