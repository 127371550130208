import { useState, useRef, useCallback, useEffect } from 'react';
import { useForceUpdate } from '@just-ai/just-ui/dist/utils';
import { ProjectSkillRead } from '@just-ai/api/dist/generated/Editorbe';

import { useAppSelector } from 'storeHooks';
import { RCurrentUser } from 'types';

import { BaseSkill } from './skillClasses/BaseSkill';
import { buildSkillList, whichSkillWillBeDeletedWithCurrent } from './skillClasses/skillBuilder';
import { ScenarioSkill } from './skillClasses/ScenarioSkill';
import { MenuSkill } from './skillClasses/MenuSkill';
import { FaqSkill } from './skillClasses/FaqSkill';
import { OperatorSkill } from './skillClasses/OperatorSkill';
import { AutomessageSkill } from './skillClasses/AutomessageSkill';
import { FaqWithChatGPT } from './skillClasses/FaqWithChatGPTSkill';

import { RealSkill, WizardState, SkillType, SkillConfig } from './types';
import { RootState } from '../../storeTypes';
import { smoothScrollTo } from '../../views/Dialogs/springScrollbars';

export function useWizardSkills(
  initialSkillName: ProjectSkillRead[],
  registeredSkills: ProjectSkillRead[],
  values?: Record<string, any>,
  isPostSetup?: boolean
) {
  const { projectShortName, currentUser } = useAppSelector((state: RootState) => ({
    projectShortName: state.CurrentProjectsReducer.currentProject as string,
    currentUser: state.CurrentUserReducer.currentUser as RCurrentUser['currentUser'],
  }));
  const [skillsNames, setSkillsNames] = useState(initialSkillName);
  const [state, setState] = useState<WizardState>(() => ({
    values: values || {},
    default: {},
  }));
  const stateRef = useRef(state);
  const isFirstSkillsRender = useRef(false);
  const openedSkills = useRef(new Set<string>());
  const doneSkills = useRef(new Set<string>());
  const [skills, setSkills] = useState<BaseSkill[]>([]);
  const [invalidSkills, setInvalidSkills] = useState<BaseSkill[]>([]);
  const [skillsToDelete, setSkillsToDelete] = useState<BaseSkill[]>([]);
  const isAllSkillsDone = useRef(false);
  const forceUpdate = useForceUpdate();

  stateRef.current = state;

  const deleteSkillInner = useCallback((skillName: string) => {
    setSkillsNames(prev => prev.filter(skill => skill.name !== skillName));
  }, []);

  useEffect(() => {
    const skillsConfigs = buildSkillList(skillsNames, registeredSkills);
    if (!isFirstSkillsRender.current && skillsConfigs.length > 0) {
      isFirstSkillsRender.current = true;
      openedSkills.current.add(skillsConfigs[0].skillName);
      doneSkills.current.add(skillsConfigs[0].skillName);
      if (skillsConfigs.length === 1) {
        isAllSkillsDone.current = true;
      }
    }

    const skills = skillsConfigs
      .map(skillConfig => {
        switch (skillConfig.type) {
          case SkillType.SCENARIO:
            return new ScenarioSkill(skillConfig);
          case SkillType.MENU:
            return new MenuSkill(skillConfig);
          case SkillType.FAQ:
            return new FaqSkill(skillConfig);
          case SkillType.FAQ_WITH_CHAT_GPT:
            return new FaqWithChatGPT(skillConfig);
          case SkillType.OPERATOR:
            return new OperatorSkill(skillConfig);
          case SkillType.AUTOMESSAGE:
            return new AutomessageSkill(skillConfig);
          default:
            return null;
        }
      })
      .filter(Boolean) as BaseSkill[];

    skills.forEach(skill => {
      const isLast =
        whichSkillWillBeDeletedWithCurrent(skills, skill.skillConfig.skillName, registeredSkills).length ===
        skills.length;
      skill.init({
        isOpened: openedSkills.current.has(skill.skillConfig.skillName),
        isDone: isPostSetup || doneSkills.current.has(skill.skillConfig.skillName),
        otherSkills: skills,
        isRealSkill: Object.values(RealSkill).includes(skill.skillConfig.skillName as RealSkill),
        initialValues: stateRef.current.values[skill.skillConfig.skillName],
        isPostSetup,
        isLast,
        accountId: currentUser.account.id,
        projectId: projectShortName,
      });
      skill.forceUpdate();
    });
    const onFieldsChangeUnsubs = skills.map(skill => {
      return skill.subscribe('onFieldsChange', ({ value, isDefaultValue }) => {
        setState(prev => {
          const result = { ...prev };
          if (isDefaultValue) {
            result.default = {
              ...result.default,
              [skill.skillConfig.skillName]: value,
            };
            return result;
          }
          result.values = {
            ...result.values,
            [skill.skillConfig.skillName]: value,
          };
          return result;
        });
      });
    });
    const onToggleUnsubs = skills.map(skill => {
      return skill.subscribe('onToggle', () => {
        skill.isOpened = !skill.isOpened;
        if (!skill.isOpened) skill.isDone = true;
        skill.isOpened
          ? openedSkills.current.add(skill.skillConfig.skillName)
          : openedSkills.current.delete(skill.skillConfig.skillName);
        skill.forceUpdate();
      });
    });
    const onNextUnsubs = skills.map(skill => {
      return skill.subscribe('onNext', offset => {
        skill.isOpened = false;
        if (offset === 1) skill.isDone = true;
        skill.isOpened
          ? openedSkills.current.add(skill.skillConfig.skillName)
          : openedSkills.current.delete(skill.skillConfig.skillName);
        skill.forceUpdate();

        const skillIndex = skills.findIndex(otherSkill => otherSkill === skill);
        const nextSkill = skills[skillIndex + offset];
        if (offset === 1) nextSkill.isDone = true;
        nextSkill.isOpened = true;
        nextSkill.isOpened
          ? openedSkills.current.add(nextSkill.skillConfig.skillName)
          : openedSkills.current.delete(nextSkill.skillConfig.skillName);
        nextSkill.forceUpdate();
        setTimeout(() => {
          const nextSkillNode = document.querySelector(`[data-skill-name="${nextSkill.skillConfig.skillName}"]`);
          if (!nextSkillNode) return;
          document.dispatchEvent(
            new CustomEvent(smoothScrollTo, { detail: { top: (nextSkillNode as HTMLDivElement).offsetTop } })
          );
        }, 500);
      });
    });
    const onDoneUnsubs = skills.map(skill => {
      return skill.subscribe('onDone', () => {
        doneSkills.current.clear();
        doneSkills.current = new Set(skills.filter(skill => skill.isDone).map(skill => skill.skillConfig.skillName));
        const allSkillsDone = skills.every(skill => skill.isDone);
        if (allSkillsDone !== isAllSkillsDone.current) {
          isAllSkillsDone.current = allSkillsDone;
          forceUpdate();
        }
      });
    });
    const onValidUnsubs = skills.map(skill => {
      return skill.subscribe('onValid', () => setInvalidSkills(skills.filter(skill => !skill.isValid)));
    });
    const onDeleteSkillUnsubs = skills.map(skill => {
      return skill.subscribe('onDeleteSkill', () => {
        const diffSkills = whichSkillWillBeDeletedWithCurrent(skills, skill.skillConfig.skillName, registeredSkills);
        if (diffSkills.length) {
          setSkillsToDelete(
            diffSkills
              .map(conf => skills.find(skill => skill.skillConfig.skillName === conf.skillName))
              .filter(Boolean) as BaseSkill[]
          );
          return;
        }
      });
    });

    setSkills(skills);

    return () => {
      onFieldsChangeUnsubs.forEach(fn => fn());
      onToggleUnsubs.forEach(fn => fn());
      onNextUnsubs.forEach(fn => fn());
      onDoneUnsubs.forEach(fn => fn());
      onDeleteSkillUnsubs.forEach(fn => fn());
      onValidUnsubs.forEach(fn => fn());
    };
  }, [currentUser.account.id, forceUpdate, isPostSetup, projectShortName, registeredSkills, skillsNames]);

  useEffect(() => {
    skills.forEach(skill => skill.onStateChange(state));
  }, [skills, state]);

  const addNewSkills = useCallback((skillNames: SkillConfig[]) => {
    const newProjectSkills = skillNames.map(
      skill =>
        ({
          ...skill,
          name: skill.skillName,
        } as ProjectSkillRead)
    );
    setSkillsNames(prev => [...prev, ...newProjectSkills]);
  }, []);

  const resetSkillsToDelete = useCallback(() => setSkillsToDelete([]), []);

  const deleteSkills = useCallback(
    (skills: BaseSkill[]) => {
      skills.forEach(skill => {
        doneSkills.current.delete(skill.skillConfig.skillName);
        openedSkills.current.delete(skill.skillConfig.skillName);
        delete stateRef.current.values[skill.skillConfig.skillName];
        delete stateRef.current.default[skill.skillConfig.skillName];
        deleteSkillInner(skill.skillConfig.skillName);
      });
      resetSkillsToDelete();
      setState({
        default: stateRef.current.default,
        values: stateRef.current.values,
      });
    },
    [deleteSkillInner, resetSkillsToDelete]
  );

  return {
    skills,
    invalidSkills,
    skillsState: state,
    isAllSkillsDone: isPostSetup || isAllSkillsDone.current,
    addNewSkills,
    deleteSkills,
    skillsToDelete,
    resetSkillsToDelete,
  };
}
