import React, { PureComponent } from 'react';
import { AtreidesIcon, List, ListItem, ListItemIcon, ListItemText } from 'altreidsds';
import { NavLink } from 'react-router-dom';
import { GA, getUserLanguage } from '../../../pipes/pureFunctions';
import localize from '../../../localization';
import isAccess, { isIncluded } from '../../../isAccessFunction';
import { Bots, Calls, Tariffs, Webinars } from '../../Sidebar/SidebarItems/SidebarIcons';

import classNames from 'classnames';

import classes from './MobileHeader.module.scss';
import history from '../../../appHistory';

const { translate } = localize;

export default class Links extends PureComponent {
  renderNavLink = ({ key, link, GACategory, GAAction, icon, label, target }) => {
    const { withIcons, pathname } = this.props;

    if (pathname === link) return null;

    const additionalOptions = {};
    if (target === '_blank') {
      additionalOptions.rel = 'noopener noreferrer';
      additionalOptions.style = withIcons ? {} : { position: 'relative', left: 12 };
    }

    let linkProps = {
      target: target,
      className: classes.link,
      onClick: () => (GACategory ? GA('GAEvent', GACategory, GAAction) : null),
      ...additionalOptions,
    };

    const children = (
      <>
        {withIcons && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          data-test-id={link}
          disableTypography
          classes={{ root: classes.link }}
          primary={translate(label)}
          secondary={
            target === '_blank' ? (
              <AtreidesIcon
                name='externalLink'
                color='primary'
                style={{ marginLeft: 4, top: 2, position: 'relative' }}
              />
            ) : undefined
          }
        />
      </>
    );

    if (target && target === '_blank') {
      linkProps = {
        children,
        ...linkProps,
        href: link,
      };
    } else {
      linkProps = {
        ...linkProps,
        exact: true,
        activeClassName: 'active',
        to: link,
      };
    }

    return (
      <ListItem button className={classNames(classes.linkContainer, withIcons && classes.linkWithIcons)} key={key}>
        {target && target === '_blank' ? <a {...linkProps} /> : <NavLink {...linkProps}>{children}</NavLink>}
      </ListItem>
    );
  };

  logout = async () => {
    history.push('/logout');
  };

  render() {
    const language = getUserLanguage();
    const { appConfig, withIcons } = this.props;

    return (
      <List className={classNames(classes.linksList, withIcons && classes.withoutPadding)}>
        {this.renderNavLink({
          key: 'dashboard',
          link: '/dashboard',
          label: 'AppHelpNavListItems Dashboard',
        })}
        {this.renderNavLink({
          key: 'dashboard',
          link: '/',
          label: 'CallsListItem homepage',
          icon: <Bots />,
        })}
        {isAccess(['ALL']) &&
          isIncluded(['zenflow_telephony_feature']) &&
          this.renderNavLink({
            key: 'calltasks',
            link: '/callsbots',
            GACategory: 'obzvony',
            GAAction: 'clicked',
            icon: <Calls />,
            label: 'CallsListItem calls',
          })}
        {isAccess(['ALL']) &&
          isIncluded(['zenflow_billing_feature']) &&
          this.renderNavLink({
            key: 'tariffs',
            link: '/prices',
            GACategory: 'main_tariff',
            GAAction: 'clicked',
            icon: <Tariffs />,
            label: 'CallsListItem tariffs',
          })}
        {isAccess(['ALL']) &&
          language === 'ru' &&
          Boolean(appConfig?.zenflow?.webinars) &&
          this.renderNavLink({
            key: 'webinars',
            link: translate('CallsListItem webinars link'),
            target: '_blank',
            GACategory: 'webinars',
            GAAction: 'clicked',
            icon: <Webinars />,
            label: 'CallsListItem webinars',
          })}
        {isAccess(['ALL']) &&
          this.renderNavLink({
            key: 'help',
            link: translate('AppHelpNavListItems help link'),
            target: '_blank',
            icon: <AtreidesIcon name='study' color='primary' />,
            label: 'CallsListItem helpCenter',
          })}
      </List>
    );
  }
}
