import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { Button, Icon } from '@just-ai/just-ui';

import { t } from 'localization';
import { addKeyToRow } from 'modules/TemplatesWizard/FieldBuilder/fields/utils';

import FieldsCollectionsRow from './FieldsCollectionsRow';
import styles from './styles.module.scss';

export type FAQQuestionsSuggest = {
  question: string;
  answerPlaceholder: string;
};
export type FAQQuestionsRow = {
  question: string;
  answer: string;
};
type FAQQuestionsProps = {
  rows: FAQQuestionsRow[];
  suggests?: FAQQuestionsSuggest[];
  onChange: (data: FAQQuestionsRow[], isDefault: boolean) => void;
  questionPlaceholder?: string;
  addRowText?: string;
  maxRows?: number;
  answerPlaceholder?: string;
  deleteDisable?: boolean;
  errors?: Record<string, any>;
  urgentErrors?: Record<string, any>;
  isNeedToShowError?: boolean;
};
const FAQQuestions = ({
  rows,
  suggests,
  onChange,
  questionPlaceholder,
  answerPlaceholder,
  addRowText,
  deleteDisable,
  maxRows,
  errors,
  urgentErrors,
  isNeedToShowError,
}: FAQQuestionsProps) => {
  const [innerValue, setInnerValue] = useState(() => rows?.map(addKeyToRow) || []);
  const ref = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const defaultValues = innerValue.map(el => {
      const fromSuggests = suggests?.find(suggest => suggest.question === el.question)?.answerPlaceholder;
      const answer = fromSuggests || answerPlaceholder || '';
      return { ...el, answer, question: el.question || questionPlaceholder || '' };
    });
    onChange(defaultValues, true);
    onChange(innerValue, false);
  }, [onChange, innerValue, suggests, answerPlaceholder, questionPlaceholder]);

  const onChangeInner = useCallback((index: number, value: any, name: string) => {
    setInnerValue(prev => {
      prev[index] = Object.assign({}, prev[index], { [name]: value });
      return prev.concat();
    });
  }, []);

  const delRow = useCallback((index: number) => {
    setInnerValue(prev => {
      prev.splice(index, 1);
      return prev.concat();
    });
  }, []);

  const addRow = useCallback(() => {
    setInnerValue(prev => [...prev, addKeyToRow({ question: '', answer: '' })]);
  }, []);

  const rowData = useMemo(() => {
    const selectedQuestions = new Set(innerValue.map(el => el.question));
    return innerValue.map((row, index) => {
      return {
        ...row,
        index,
        suggests: suggests
          ? suggests.filter(el => row.question === el.question || !selectedQuestions.has(el.question))
          : undefined,
      };
    });
  }, [innerValue, suggests]);

  return (
    <div className={styles.FieldsCollections} ref={ref}>
      <div className={styles.FieldsCollections__wrapper}>
        {innerValue.map((row, index) => (
          <FieldsCollectionsRow
            key={row.key}
            row={rowData[index]}
            onDelete={delRow}
            onChange={onChangeInner}
            answerPlaceholder={answerPlaceholder}
            deleteDisable={deleteDisable}
            isNeedToShowError={isNeedToShowError}
            errors={errors}
            urgentErrors={urgentErrors}
          />
        ))}
      </div>
      {maxRows === undefined ||
        (rowData.length < maxRows && (
          <Button className={styles.addBtn} type='button' color='info' flat onClick={addRow}>
            <Icon name='farPlus' />
            <span>{addRowText || t('FAQSkill:Fields:CustomQuestions:Add')}</span>
          </Button>
        ))}
    </div>
  );
};

export default React.memo(FAQQuestions);
