import { loadStripe } from '@stripe/stripe-js';
import history from 'appHistory';

export const redirectToPaymentSystem = (data, currentUser) => {
  if (Boolean(data.paymentId) && currentUser) {
    localStorage.setItem(`${currentUser.email}_PAYMENT_ID`, data.paymentId);
    let event = new Event('paymentWaiting');
    window.dispatchEvent(event);
  }
  if (data.redirectUrl && data.paymentSystem === 'YANDEX_CASH') {
    window.location.href = data.redirectUrl;
  } else if (data.paymentSystem === 'STRIPE' && data.stripePublicKey && data.stripeSessionId) {
    let stripe = loadStripe(data.stripePublicKey);
    stripe.then(payload => {
      payload && payload.redirectToCheckout({ sessionId: data.stripeSessionId });
    });
  } else {
    history.push('/personal?tab=0');
  }
};
