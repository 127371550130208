export const SECTIONS_TYPE = {
  CALLS_VARIABLES: 'CALLS_VARIABLES',
  VARIABLES: 'VARIABLES',
  QUESTIONS: 'QUESTIONS',
  TIME: 'TIME',
  CONTACTS: 'CONTACTS',
};

export function validateVariables(variables, sections) {
  if (!variables || !sections || sections.length === 0) {
    return false;
  }

  let errorsKeys = [];

  const isAllRequired = !Object.values(variables).find(x => x.required);

  sections.forEach(section => {
    const { type, options, optionKey } = section || {};

    if ([SECTIONS_TYPE.VARIABLES, SECTIONS_TYPE.CALLS_VARIABLES].includes(type)) {
      options.map(key => {
        const variable = variables[key];
        if ((variable.required || isAllRequired) && !variable.value.trim()) errorsKeys.push(key);
      });
    } else if (type === SECTIONS_TYPE.CONTACTS) {
      const toggleVariable = variables[options?.toggle];
      const valueVariable = variables[options[toggleVariable?.value]];
      if ((toggleVariable?.required || isAllRequired) && (!valueVariable || !valueVariable.value.trim())) {
        errorsKeys.push(options?.toggle);
      }
    } else if (type === SECTIONS_TYPE.TIME) {
      const variable = variables[optionKey];
      if ((variable.required || isAllRequired) && !variable.value.length) {
        errorsKeys.push(optionKey);
      }
    } else if (type === SECTIONS_TYPE.QUESTIONS) {
      options.map(({ email, phone }) => {
        const mailVariable = variables[email];
        const phoneVariable = variables[phone];

        if (
          (mailVariable?.required || phoneVariable?.required) &&
          !mailVariable?.value.trim() &&
          !phoneVariable?.value.trim()
        ) {
          errorsKeys.push(email);
          errorsKeys.push(phone);
        }
      });
    }
  });
  errorsKeys = errorsKeys.filter(x => x).sort();
  return errorsKeys;
}

export function buildSections(templateJSON, variables) {
  const result = { ...templateJSON };
  try {
    if (!result.sections && result.ivrQuestions) {
      let firstSectionKeys = Object.keys(variables) || [];
      firstSectionKeys = firstSectionKeys.filter(key => variables[key]?.section === 1);

      result.sections = [
        { type: SECTIONS_TYPE.VARIABLES, options: firstSectionKeys },
        { type: SECTIONS_TYPE.QUESTIONS, options: result.ivrQuestions },
        { type: SECTIONS_TYPE.TIME, optionKey: 'workHours' },
        {
          type: SECTIONS_TYPE.CONTACTS,
          options: { toggle: 'unworkingHandler', email: 'email_for_lead', phone: 'phone_for_lead' },
        },
      ];
    } else if (!templateJSON.sections) {
      let firstSectionKeys = Object.keys(variables) || [];

      result.sections = [
        {
          type: result.type === 'CALLS_BOT' ? SECTIONS_TYPE.CALLS_VARIABLES : SECTIONS_TYPE.VARIABLES,
          options: firstSectionKeys,
        },
      ];
    }

    const keys = variables ? Object.keys(variables) : [];

    return result.sections.map(section => {
      const result = { ...section };

      const { type } = section;

      if ([SECTIONS_TYPE.VARIABLES, SECTIONS_TYPE.CALLS_VARIABLES].includes(type)) {
        result.variables = keys
          .filter(x => section.options.includes(x))
          .reduce((result, key) => {
            const value = variables[key];
            if (value) {
              result.push({ ...value, key });
            }
            return result;
          }, []);
      }
      if (type === SECTIONS_TYPE.TIME) {
        result.value = variables[result.optionKey]?.value;
        result.variable = variables[result.optionKey];
      }
      if ([SECTIONS_TYPE.CONTACTS, SECTIONS_TYPE.QUESTIONS].includes(type)) {
        result.variables = variables;
      }
      return result;
    });
  } catch (e) {
    console.error(e);
  }
}
