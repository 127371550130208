import React from 'react';
import { Modal } from '@just-ai/just-ui';

import { t } from 'localization';

type WarningJivoGenerateTokenModalProps = {
  onSubmit: () => void;
  isOpen: boolean;
};
function WarningJivoGenerateTokenModal({ isOpen, onSubmit }: WarningJivoGenerateTokenModalProps) {
  return (
    <Modal
      title={t('OperatorSkill:WarningJivoGenerateTokenModal:Jivo:Title')}
      buttonSubmitText={t('OperatorSkill:WarningJivoGenerateTokenModal:Jivo:Submit')}
      buttonSubmitColor='primary'
      onActionClick={onSubmit}
      centered
      isOpen={isOpen}
    >
      <span>{t('OperatorSkill:WarningJivoGenerateTokenModal:Jivo:Description')}</span>
    </Modal>
  );
}

export default React.memo(WarningJivoGenerateTokenModal);
