import React, { useCallback } from 'react';
import classes from './WizardStepper.module.scss';
import { useAppSelector } from '../../../../storeHooks';
import cn from 'classnames';
import { t } from 'localization';
import { Icon } from '@just-ai/just-ui';

type WizardStepProps = { activeStep: boolean; filledStep: boolean; showWizardStepDash?: boolean; text: string };

function WizardStep({ showWizardStepDash, activeStep, filledStep, text }: WizardStepProps) {
  return (
    <>
      {showWizardStepDash && <div className={classes.WizardStepDash} />}
      <div
        className={cn(
          classes.WizardStep,
          { [classes.WizardStepActive]: activeStep },
          { [classes.WizardStepFilled]: filledStep }
        )}
      >
        <Icon className={classes.WizardStepIcon} name={filledStep ? 'farCheckCircle' : 'farCircle'} size='md' />
        <b>{text}</b>
      </div>
    </>
  );
}

export const WizardStepper = () => {
  const { location } = useAppSelector(store => ({
    location: store.router.location,
  }));

  const isActiveStep = useCallback(
    (step: string) => {
      return location.pathname.endsWith(step);
    },
    [location.pathname]
  );
  const isFilledStep = useCallback(
    (step: string) => {
      if (step) return location.pathname.endsWith(step);
      return false;
    },
    [location.pathname]
  );

  return (
    <div className={classes.WizardSteps}>
      <WizardStep
        activeStep={isActiveStep('')}
        filledStep={isFilledStep('wizard') || isFilledStep('/connect')}
        text={t('CreateProject:SelectSkills')}
      />
      <WizardStep
        showWizardStepDash={true}
        activeStep={isActiveStep('wizard') || isActiveStep('/connect')}
        filledStep={isFilledStep('/connect')}
        text={t('CreateProject:FillSkills')}
      />
      <WizardStep
        showWizardStepDash={true}
        activeStep={isActiveStep('/connect')}
        filledStep={isFilledStep('')}
        text={t('CreateProject:Connect')}
      />
    </div>
  );
};
