import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import buildTemplateWizardField, { TemplateWizardField } from './fields';
import styles from '../styles.module.scss';

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <div role='alert' className={styles.errorPanel}>
      <div className={styles.errorPanel__title}>Something went wrong:</div>
      <div className={styles.errorPanel__error}>{error.message}</div>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

type FieldBuilderProps = {
  fields: any[];
  onChange: (value: string, name: string, isDefault: boolean) => void;
  initialValues?: any;
  onErrors?: (envName: string, errors: any | null) => void;
  isShowErrors?: boolean;
};
const FieldBuilder = ({ fields, onChange, initialValues, isShowErrors, onErrors }: FieldBuilderProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      {fields.map((field, i) => {
        const Cmp = buildTemplateWizardField(field.type as TemplateWizardField);
        if (!Cmp) return null;
        return (
          <ErrorBoundary key={field.type + i} FallbackComponent={ErrorFallback}>
            <Cmp
              info={field}
              isShowErrors={isShowErrors}
              onErrors={onErrors}
              envName={field.envName}
              value={initialValues?.[field.envName] || field.value}
              onChange={onChange}
            />
          </ErrorBoundary>
        );
      })}
    </div>
  );
};

export default FieldBuilder;
