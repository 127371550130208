import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { Button, Icon } from '@just-ai/just-ui';

import { t } from 'localization';
import { addKeyToRow } from 'modules/TemplatesWizard/FieldBuilder/fields/utils';

import FieldsCollectionsRow from './FieldsCollectionsRow';
import styles from './styles.module.scss';

export type FAQQuestionsSuggest = {
  question: string;
  answerPlaceholder: string;
};
export type FAQQuestionsRow = {
  question: string;
  answer: string;
};
type FAQQuestionsProps = {
  rows: FAQQuestionsRow[];
  suggests?: FAQQuestionsSuggest[];
  onChange: (data: FAQQuestionsRow[], isDefault: boolean) => void;
  questionPlaceholder?: string;
  answerPlaceholder?: string;
  deleteDisable?: boolean;
};
const FAQQuestions = ({
  rows,
  suggests,
  onChange,
  questionPlaceholder,
  answerPlaceholder,
  deleteDisable,
}: FAQQuestionsProps) => {
  const [innerValue, setInnerValue] = useState(() => rows?.map(addKeyToRow) || []);
  const ref = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const defaultValues = innerValue.map(el => {
      const fromSuggests = suggests?.find(suggest => suggest.question === el.question)?.answerPlaceholder;
      const answer = fromSuggests || answerPlaceholder || '';
      return { ...el, answer, question: el.question || questionPlaceholder || '' };
    });
    onChange(defaultValues, true);
    onChange(innerValue, false);
  }, [onChange, innerValue, suggests, answerPlaceholder, questionPlaceholder]);

  const onChangeInner = useCallback((index: number, value: any, name: string) => {
    setInnerValue(prev => {
      prev[index] = Object.assign({}, prev[index], { [name]: value });
      return prev.concat();
    });
  }, []);

  const delRow = useCallback((index: number) => {
    setInnerValue(prev => {
      prev.splice(index, 1);
      return prev.concat();
    });
  }, []);

  const addRow = useCallback(() => {
    const selectedQuestions = new Set(innerValue.map(el => el.question));
    const firstAvailableSuggest = suggests?.find(el => !selectedQuestions.has(el.question));
    setInnerValue(prev => [...prev, addKeyToRow({ question: firstAvailableSuggest?.question || '', answer: '' })]);
  }, [innerValue, suggests]);

  const rowData = useMemo(() => {
    const selectedQuestions = new Set(innerValue.map(el => el.question));
    return innerValue.map((row, index) => {
      return {
        ...row,
        index,
        suggests: suggests
          ? suggests.filter(el => row.question === el.question || !selectedQuestions.has(el.question))
          : undefined,
      };
    });
  }, [innerValue, suggests]);

  return (
    <div className={styles.FieldsCollections} ref={ref}>
      <div className={styles.FieldsCollections__wrapper}>
        {innerValue.map((row, index) => (
          <FieldsCollectionsRow
            key={row.key}
            row={rowData[index]}
            onDelete={delRow}
            onChange={onChangeInner}
            questionPlaceholder={questionPlaceholder}
            answerPlaceholder={answerPlaceholder}
            deleteDisable={deleteDisable}
          />
        ))}
      </div>
      {(!suggests || rowData.length < suggests.length) && (
        <Button className={styles.addBtn} type='button' color='info' flat onClick={addRow}>
          <Icon name='farPlus' />
          <span>{suggests ? t('FAQSkill:Fields:DefaultQuestions:Add') : t('FAQSkill:Fields:CustomQuestions:Add')}</span>
        </Button>
      )}
    </div>
  );
};

export default React.memo(FAQQuestions);
