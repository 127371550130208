import React, { useCallback, useMemo } from 'react';
import { Button, Icon } from '@just-ai/just-ui';

import { t } from 'localization';
import history from 'appHistory';

import WizardTooltip from 'modules/TemplatesWizard/primitives/WizardTooltip';

import { BASE_WIZARD_PATH } from '../../shared/consts';
import classes from '../graph-way/graphWay.module.scss';
import { NewTemplateCard } from '../graph-way/card';
import { JgIcon } from './icons';
import { useLocation } from 'react-router';
import { GA } from '../../../../pipes/pureFunctions';

export const ChooseGraphEditor = () => {
  const location = useLocation<{
    isCallBot?: boolean;
    callType?: string;
  }>();
  const isCallBot = useMemo(() => {
    return location?.state?.isCallBot || false;
  }, [location?.state]);
  const goToEndPage = useCallback(
    (value: string) => {
      GA(
        undefined,
        isCallBot ? 'Calls_Constructor_Type_Select' : 'Chat_Constructor_Type_Select',
        value === 'jgraph' ? 'j-graph' : 'Aimylogic'
      );

      history.push({
        pathname: BASE_WIZARD_PATH + `/${value}`,
        state: {
          ...location.state,
        },
      });
    },
    [isCallBot, location.state]
  );

  return (
    <div className={classes.graphWayContainer}>
      <h1 className='mt-3 mb-6 text-center'>{t('CreateProject:ChooseGraphEditor:Title')}</h1>

      <div className='d-flex flex-column gap-20'>
        <NewTemplateCard
          vertical={true}
          IconComponent={<JgIcon />}
          title={t('CreateProject:ChooseGraphEditor:JgTitle')}
          summary={t('CreateProject:ChooseGraphEditor:JgDescription')}
          onClick={() => goToEndPage('jgraph')}
        />
        <div className='d-flex justify-content-center'>
          <Button color='primary' flat onClick={() => goToEndPage('aimygraph')}>
            {t('CreateProject:ChooseGraphEditor:AimyButtonText')}
            <Icon
              className='margin-left-17'
              name='faQuestionCircle'
              color='secondary'
              id='CreateProject_ChooseGraphEditor_ButtonIcon'
            />
            <WizardTooltip
              textAlign='center'
              placement='bottom-start'
              target='CreateProject_ChooseGraphEditor_ButtonIcon'
            >
              {t('CreateProject:ChooseGraphEditor:AimyButtonDescription')}
            </WizardTooltip>
          </Button>
        </div>
      </div>
    </div>
  );
};
