import { isArray } from 'lodash';
import queryString from 'query-string';
import moment from 'moment';
import aimylogic from '../img/aimylogic.svg';
import aimyvoice from '../img/aimyvoice.svg';

import dialogstuido from '../img/icon-dialogstudio.svg';
import cloud from '../img/cloud.svg';
import jaicp from '../img/jaicp.svg';
import jaicf from '../img/jaicf.svg';
import caila from '../img/caila.svg';
import aimybox from '../img/aimybox.svg';
import { isEuroInstance } from '../isAccessFunction';

export const isDev = () => {
  return process.env.NODE_ENV === 'development' || window.isDev;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getBrowserLang = () => {
  let browserLang;
  if (navigator.languages !== undefined) browserLang = navigator.languages[0];
  else browserLang = navigator.language;
  if (isEuroInstance() && browserLang === 'ru') browserLang = 'eng';

  return browserLang.split('-')[0];
};

export function lang2toFullLowerCase(language) {
  if (!language || !language.toLowerCase) return language;
  if (language?.toLowerCase() === 'en') return 'eng';
  return language?.toLowerCase();
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export function FB(eventAction, eventLabel = undefined) {
  if (window.fbq && eventAction) {
    if (eventLabel) {
      return window.fbq('track', eventAction, eventLabel);
    }

    return window.fbq('track', eventAction);
  }
}

export function GA(event, eventCategory, eventAction, eventLabel, eventValue = undefined, options = {}) {
  if (Boolean(window.dataLayer) && process.env.NODE_ENV !== 'development') {
    window.dataLayer.push({
      event: event || 'GAEvent',
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel || undefined,
      eventValue: eventValue,
      ...options,
    });
  }

  if (window.debugAimy || isDev()) {
    console.log({
      event: event || 'GAEvent',
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
      ...options,
    });
  }
}

export function YM(event, eventType) {
  if (Boolean(window.ym) && process.env.NODE_ENV !== 'development') {
    window.ym(getYandexId(), event, eventType);
  }
}

export function getYandexId() {
  const testEnvTag = 48986630;
  const prodEnvTag = 48986630;

  if (window.location.host.startsWith('localhost')) return '';
  return window.location.host.endsWith('lo.test-ai.net') ? testEnvTag : prodEnvTag;
}

/**
 * @param [userCountryIsoCode]
 * @returns {'ru' | 'pt' | 'eng'}
 */
export const getUserLanguage = userCountryIsoCode => {
  if (Boolean(userCountryIsoCode)) {
    const isoCode = userCountryIsoCode.toLowerCase();
    if (isoCode === 'br') return 'pt';
    return isoCode;
  }
  const windowLocationParse = queryString.parse(window.location.search);
  if (['eng', 'ru', 'cn', 'pt'].includes(windowLocationParse?.language)) {
    localStorage.setItem('USER_LANGUAGE', windowLocationParse.language);
    return windowLocationParse.language;
  }

  if (Boolean(localStorage.getItem('USER_LANGUAGE'))) {
    let language = localStorage.getItem('USER_LANGUAGE');
    if (isEuroInstance() && language === 'ru') {
      language = 'eng';
      localStorage.setItem('USER_LANGUAGE', language);
    }
    return language;
  } else {
    let lang = getBrowserLang();

    switch (lang) {
      case 'ru': {
        return 'ru';
      }
      case 'pt': {
        return 'pt';
      }
      default:
        return 'eng';
    }
  }
};

export const parseErrors = response => {
  let errors;
  //todo check response status, don't save error with 401 status
  if (response && response.status !== 401) {
    if (Boolean(response.data.errors)) {
      errors = response.data.errors;
    } else if (Boolean(response.data.args)) {
      errors = [response.data];
    } else if (Boolean(response.data.error)) {
      errors = [response.data.error];
    } else if (isArray(response.data)) {
      errors = response.data;
    } else {
      errors = [response.data];
    }
  } else {
    errors = [];
  }

  return errors;
};

export const checkOnlyRussia = currentUser => {
  if (Boolean(currentUser) && Boolean(currentUser.tariff) && Boolean(currentUser.tariff.countryIsoCode)) {
    switch (currentUser.tariff.countryIsoCode) {
      case 'RU': {
        return false;
      }
      default:
        return true;
    }
  }
  return true;
};

export function checkIsPageWithOldHeader(pathname) {
  return (
    pathname.includes('/bot_dashboard/') ||
    pathname.includes('/scenario/') ||
    pathname.includes('/entities/') ||
    pathname.includes('/channels/') ||
    pathname.includes('/knowledge/') ||
    pathname.includes('/dialogs/') ||
    pathname.includes('/settings/') ||
    pathname.includes('/voicechannels/') ||
    pathname.includes('/chatwidgetEditor') ||
    pathname.includes('/faq/') ||
    pathname.includes('/faq-template/') ||
    pathname.includes('/statistics/')
  );
}

export const getLastYearMonths = () => {
  const rangeArr = [];
  for (let i = 1; i <= 12; i++) {
    rangeArr.push(moment().subtract(i, 'month'));
  }

  return rangeArr;
};

export const getLogoByProduct = product => {
  switch (product) {
    case 'aimylogic':
      return isEuroInstance() ? dialogstuido : aimylogic;
    case 'aimyvoice':
      return aimyvoice;
    case 'cc':
      return cloud;
    case 'jaicp':
      return jaicp;
    case 'jaicf':
      return jaicf;
    case 'caila':
      return caila;
    case 'aimybox':
      return aimybox;
    default:
      return;
  }
};
