var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { ProjectSkillsApi, } from "../../generated/Editorbe";
import { BotConfigsApi, } from "../../generated/Botadmin";
import { ProjectService } from "../ProjectService";
const emptySkillTemplateMap = {
    ru: "project-skill-empty-ru",
    en: "project-skill-empty-en",
    pt: "project-skill-empty-pt",
};
const scriptTagRegex = /src="(.+?)"/;
export class ProjectSkillsService {
    constructor(accountId, otherAxios = axios) {
        this.accountId = -1;
        this.getListProjectSkills = (language, options) => {
            language = language.toLowerCase();
            if (language === "eng")
                language = "en";
            return this.projectsApi
                .getListProjectSkills(this.accountId, language, options)
                .then((res) => res.data);
        };
        this.getProjectSkill = (projectId, options) => {
            return this.projectsApi
                .getProjectSkill(this.accountId, projectId, options)
                .then((res) => res.data);
        };
        this.createProject = (botProjectCreateDto, language, options) => __awaiter(this, void 0, void 0, function* () {
            switch (language) {
                case "eng":
                    language = "en";
                    break;
                case "en":
                case "pt":
                case "ru":
                    break;
                default:
                    throw new Error(`Unregistered lang for skill project: ${language}`);
            }
            return this.projectServiceApi.createProject(Object.assign(Object.assign({}, botProjectCreateDto), { isProjectSkill: true, template: emptySkillTemplateMap[language] }), options);
        });
        this.updateProjectSkill = (projectId, projectSkillData, commit, options) => {
            return this.projectsApi
                .updateProjectSkill(this.accountId, projectId, projectSkillData, commit, options)
                .then((res) => res.data);
        };
        this.getChatWidgetPreviewScriptUrl = (skillName, language, options) => __awaiter(this, void 0, void 0, function* () {
            const { data: widgetOptions } = yield this.botConfigsApi.getWidgetOptions(this.accountId, language);
            const { data: widgetScriptUrl } = yield this.projectsApi.getChatWidgetScriptUrl(this.accountId, skillName, language, JSON.stringify(widgetOptions), options);
            return widgetScriptUrl;
        });
        this.getChatWidgetUrlByProject = (projectShortName) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const { data: scriptElement } = yield this.axios.post(`/restapi/botconfig/testwidget/${projectShortName}/publish`);
            if (!scriptElement)
                return;
            return (_a = scriptElement.match(scriptTagRegex)) === null || _a === void 0 ? void 0 : _a[1];
        });
        this.axios = otherAxios;
        this.accountId = accountId;
        this.projectsApi = new ProjectSkillsApi({}, ProjectSkillsService.BASE_PATH, otherAxios);
        this.botConfigsApi = new BotConfigsApi({}, ProjectSkillsService.BASE_PATH, otherAxios);
        this.projectServiceApi = new ProjectService(accountId, otherAxios);
    }
}
ProjectSkillsService.BASE_PATH = "";
