const prefix = '@dialogs/';
export const LOAD = prefix + 'LOAD';
export const LOAD_FULFILLED = prefix + 'LOAD_FULFILLED';
export const LOAD_PENDING = prefix + 'LOAD_PENDING';
export const LOAD_REJECTED = prefix + 'LOAD_REJECTED';

export const DUMMY_LOAD = prefix + 'DUMMY_LOAD';
export const COMMUNITY_SET_ID = prefix + 'COMMUNITY_SET_ID';

export const SELECT_DIALOG = prefix + 'SELECT_DIALOG';
export const SELECT_DIALOG_FULFILLED = prefix + 'SELECT_DIALOG_FULFILLED';
export const SELECT_DIALOG_PENDING = prefix + 'SELECT_DIALOG_PENDING';
export const SELECT_DIALOG_REJECTED = prefix + 'SELECT_DIALOG_REJECTED';

export const LOAD_PAGE = prefix + 'LOAD_PAGE';
export const LOAD_PAGE_FULFILLED = prefix + 'LOAD_PAGE_FULFILLED';
export const LOAD_PAGE_PENDING = prefix + 'LOAD_PAGE_PENDING';
export const LOAD_PAGE_REJECTED = prefix + 'LOAD_PAGE_REJECTED';

export const LOAD_FOUND_PAGE = prefix + 'LOAD_FOUND_PAGE';
export const LOAD_FOUND_PAGE_FULFILLED = prefix + 'LOAD_FOUND_PAGE_FULFILLED';
export const LOAD_FOUND_PAGE_PENDING = prefix + 'LOAD_FOUND_PAGE_PENDING';
export const LOAD_FOUND_PAGE_REJECTED = prefix + 'LOAD_FOUND_PAGE_REJECTED';

export const DIALOGS_PAGE = prefix + 'DIALOGS_PAGE';
export const DIALOGS_PAGE_FULFILLED = prefix + 'DIALOGS_PAGE_FULFILLED';
export const DIALOGS_PAGE_PENDING = prefix + 'DIALOGS_PAGE_PENDING';
export const DIALOGS_PAGE_REJECTED = prefix + 'DIALOGS_PAGE_REJECTED';

export const LOAD_CHANNELS = prefix + 'LOAD_CHANNELS';
export const LOAD_CHANNELS_FULFILLED = prefix + 'LOAD_CHANNELS_FULFILLED';
export const LOAD_CHANNELS_PENDING = prefix + 'LOAD_CHANNELS_PENDING';
export const LOAD_CHANNELS_REJECTED = prefix + 'LOAD_CHANNELS_REJECTED';

export const LOAD_SESSION_WAV = prefix + 'LOAD_SESSION_WAV';
export const LOAD_SESSION_WAV_FULFILLED = prefix + 'LOAD_SESSION_WAV_FULFILLED';
export const LOAD_SESSION_WAV_PENDING = prefix + 'LOAD_SESSION_WAV_PENDING';
export const LOAD_SESSION_WAV_REJECTED = prefix + 'LOAD_SESSION_WAV_REJECTED';
