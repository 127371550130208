import React from 'react';
import { AtreidesNativeTooltip, ListItem, ListItemIcon, ListItemText } from 'altreidsds';
import isAccess from 'isAccessFunction';
import { GA } from 'pipes/pureFunctions';
import localize from 'localization';
import { Icon } from '@just-ai/just-ui';

const { translate } = localize;

const GAEventSupport = () => GA('GAEvent', 'Sidebar_Menu_Select', 'opened', 'Support');
const GAEventHelpCenter = () => () => GA('GAEvent', 'Sidebar_Menu_Select', 'opened', 'Help_Center');

const AppHelpNavListItems = props => {
  const {
    listIconClass,
    textClass,
    messages,
    listItemClass,
    toggleFeedbackPopup,
    textListItemClass,
    chatSupport,
    showHelp,
  } = props;
  return [
    isAccess(['ALL']) && Boolean(chatSupport) && (
      <ListItem button className={listItemClass} data-test-id='Sidebar.Support' key='support' onClick={GAEventSupport}>
        <a style={{ padding: '.5rem' }} onClick={toggleFeedbackPopup} target='_blank' className={textClass}>
          {messages.length > 0 && <span className='counter' />}
          <AtreidesNativeTooltip
            js
            placement='right'
            title={translate(messages.length > 0 ? 'You have new messages' : 'AppHelpNavListItems chat')}
          >
            <ListItemIcon className={listIconClass} style={{ marginBottom: 0 }}>
              <Icon name='farUserHeadset' />
            </ListItemIcon>
            <ListItemText className={textListItemClass} disableTypography primary={translate('Sidebar:Support')} />
          </AtreidesNativeTooltip>
        </a>
      </ListItem>
    ),
    isAccess(['ALL']) && showHelp && (
      <ListItem
        button
        className={listItemClass}
        key='help'
        data-test-id='Sidebar.HelpCenter'
        onClick={GAEventHelpCenter}
      >
        <a
          style={{ padding: '.5rem' }}
          target='_blank'
          rel='noopener noreferrer'
          className={textClass}
          href={translate('AppHelpNavListItems help link')}
        >
          <AtreidesNativeTooltip js placement='right' title={translate('AppHelpNavListItems Help')}>
            <ListItemIcon className={listIconClass} style={{ marginBottom: 0 }}>
              <Icon name='farQuestionCircle' />
            </ListItemIcon>
            <ListItemText
              className={textListItemClass}
              disableTypography
              primary={translate('Sidebar:ReferenceCenter')}
            />
          </AtreidesNativeTooltip>
        </a>
      </ListItem>
    ),
  ].filter(x => x);
};

export default AppHelpNavListItems;
