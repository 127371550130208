import {
  RESTORE_FULFILLED,
  RESTORE_PENDING,
  RESTORE_REJECTED,
  REMOVE_RESTORE_ALERT,
  CLEAN_RESTORE_ERRORS,
} from '../../constants/passwordRestore.actions';
import localize from '../../localization';

import { parseErrors } from '../../pipes/pureFunctions';

const InitialState = {
  errors: [],
  fetching: false,
  success: null,
};

export default function PasswordRestoreReducer(state = InitialState, action) {
  switch (action.type) {
    case RESTORE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        errors: InitialState.errors,
        success: localize.translate('We sent you an email with a link to reset your password'),
      };
    }
    case RESTORE_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case RESTORE_REJECTED: {
      let errors =
        action.payload.response.status === 404
          ? [localize.translate('The specified email is not registered')]
          : parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case REMOVE_RESTORE_ALERT: {
      let newRegisterAlert = [...state.errors];
      newRegisterAlert.splice(action.key, 1);
      return {
        ...state,
        errors: newRegisterAlert,
      };
    }

    case CLEAN_RESTORE_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
