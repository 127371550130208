import { get } from 'lodash';

function isSameObjectsFn<ELEMENT>(a: ELEMENT, b: ELEMENT): boolean {
  return a === b;
}
function equalFn<ELEMENT>(a: ELEMENT, b: ELEMENT): boolean {
  return a === b;
}
export function buildEqualFn(fieldsNames: string[]) {
  return (a: any, b: any): boolean => {
    return fieldsNames.every(field => get(a, field) === get(b, field));
  };
}

export default function diffInArrays<ELEMENT>(
  newElements: ELEMENT[],
  oldElements: ELEMENT[],
  isSameObjects?: (a: ELEMENT, b: ELEMENT) => boolean,
  isEqualFn?: (a: ELEMENT, b: ELEMENT) => boolean
) {
  if (!isSameObjects) isSameObjects = isSameObjectsFn;
  if (!isEqualFn) isEqualFn = equalFn;
  const toDelete: ELEMENT[] = [];
  const toCreate: ELEMENT[] = [];
  const toUpdate: ELEMENT[] = [];
  const updateDiff: [ELEMENT, ELEMENT][] = [];

  oldElements.forEach(oldElement => {
    const reference = newElements.find(newElement => isSameObjects!(newElement, oldElement));
    if (!reference) {
      toDelete.push(oldElement);
      return;
    }
    if (!isEqualFn!(oldElement, reference)) {
      toUpdate.push(reference);
      updateDiff.push([oldElement, reference]);
    }
  });
  newElements.forEach(newElement => {
    const reference = oldElements.find(oldElement => isSameObjects!(newElement, oldElement));
    if (!reference) {
      toCreate.push(newElement);
      return;
    }
  });

  return {
    toDelete,
    toCreate,
    toUpdate,
    updateDiff,
  };
}
