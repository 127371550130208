import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className='app-footer'>
        <a href='http://just-ai.com' target='_blank' rel='noopener noreferrer'>
          Just AI
        </a>{' '}
        &copy; 2017.
      </footer>
    );
  }
}

export default Footer;
