import React, { Component } from 'react';
import localize from 'localization';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, Tab } from 'altreidsds';
import * as TemplatesAction from 'actions/templates.actions';
import * as BotprojectsAction from 'actions/botprojects.actions';
import Spinner from 'components/Spinner';
import SimpleMediaCard from './TemplateCard';
import history from 'appHistory';
import { without } from 'lodash';
import classes from './Templates.module.scss';

const { translate: t } = localize;

class Templates extends Component {
  state = {
    createFromTemplateModal: false,
    infoTooltipOpen: false,
    loading: false,
    tab: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.botType !== this.props.botType && this.state.tab !== 0) {
      this.setState({ tab: 0 });
    }
  }

  createNewBotFromTemplate = async (template, title) => {
    this.setState({ loading: true });
    const { templatesList, botType } = this.props;
    const templateInList = templatesList.find(templateInList => templateInList.name === template);

    const createBot = isIVR => {
      let values = {
        name: title,
        template: template,
        description: '',
        contentDirectory: '.',
        defaultBranch: 'master',
        botType: botType,
      };
      if (botType === 'CALLS_BOT') {
        values.callsBotType = isIVR ? 'IVR' : 'DIALER';
      }
      return this.props.createBot(values, isIVR);
    };

    try {
      let Json = JSON.parse(templateInList.json);
      const isIVR = Json.isIVR;
      const fields = without(Object.keys(Json), ...without(Object.keys(templateInList), 'json'), 'index', 'namespaces');
      if (fields.length > 0) {
        history.push(`/startwizard/${template}`);
      } else {
        await createBot(isIVR);
        return;
      }
    } catch (e) {
      await createBot();
      return;
    } finally {
      this.setState({ loading: false });
    }
  };

  setTab = (e, value) => this.setState({ tab: Number.parseInt(value) });

  splitToIVRAndNormal = templates => {
    const result = { ivr: [], normal: [] };

    templates.forEach(template => {
      try {
        if (template.parsedJson?.isIVR) {
          result.ivr.push(template);
        } else {
          result.normal.push(template);
        }
      } catch (e) {
        result.normal.push(template);
      }
    });

    return result;
  };

  findBotWithRetreriskActiveChannel = () => {
    const { botList } = this.props;
    return botList?.find(bot => {
      return bot?.botConfigs?.find(config => config.channelType === 'RESTERISK' && config.autoCreated);
    });
  };

  render() {
    const { templatesList, currentUser, templatesListFetching, isMobile, forceDesktopView, botType } = this.props;
    const { tab, loading } = this.state;

    const filteredTemplatesList = templatesList.filter(template => {
      if (template.parsedJson?.forCreateForm) return false;
      if (template?.language !== currentUser?.language) return false;
      return template.type != null && template.type === botType;
    });

    const isMobileView = isMobile && forceDesktopView === false;

    const sortedTemplates = this.splitToIVRAndNormal(filteredTemplatesList);
    const botWithResteriskChannel = this.findBotWithRetreriskActiveChannel();

    const isSkillMasterTariff = currentUser?.tariff?.tariffName === 'Skill master';

    return (
      <>
        {filteredTemplatesList && filteredTemplatesList.length > 0 && (
          <div key='newsletters-content' style={isMobileView ? { width: '100%', margin: 0, padding: 0 } : {}}>
            <div style={isMobileView ? { padding: '1rem 0' } : {}}>
              <h2 className={classes.templateLabel}>
                {t(botType === 'CALLS_BOT' ? 'Calls templates headline' : 'Templates templates headline')}
              </h2>
              {sortedTemplates.ivr.length > 0 && (
                <Tabs style={{ marginTop: '2rem' }} onChange={this.setTab} value={tab}>
                  <Tab data-test-id='CallsTemplates.normal' label={t('Calls templates tab label normal')} value={0} />
                  <Tab data-test-id='CallsTemplates.ivr' label={t('Calls templates tab label ivr')} value={1} />
                </Tabs>
              )}
            </div>
            {(templatesListFetching || loading) && <Spinner />}
            <div className={classes.templatesContainer}>
              {sortedTemplates[tab === 0 ? 'normal' : 'ivr'].map((template, index) => (
                <SimpleMediaCard
                  data-test-id='CallsTemplates.simpleMediaCard'
                  key={`template_${index}`}
                  {...template}
                  isIVR={tab === 1}
                  skillMaster={isSkillMasterTariff}
                  botWithIVR={botWithResteriskChannel?.name}
                  onClick={this.createNewBotFromTemplate}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.CurrentProjectsReducer.currentProject,
    botList: state.BotProjectsReducer.botList,
    templatesList: state.TemplatesReducer.templatesList,
    templatesListFetching: state.TemplatesReducer.templatesListFetching,
    language: state.CurrentUserReducer.language,
    currentUser: state.CurrentUserReducer.currentUser,
    ...state.UIReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...TemplatesAction,
    },
    dispatch
  ),
  botActions: bindActionCreators(BotprojectsAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
