import React from 'react';
import { Spinner as JustUISpinner } from '@just-ai/just-ui';

export function Spinner({
  small,
  fullHeight,
  ...props
}: {
  small?: boolean;
  color?: string;
  fullHeight?: boolean;
  style?: object;
}) {
  let style: any = props.style || {};
  if (fullHeight) {
    style.position = 'fixed';
    style.height = '100vh';
  }
  return <JustUISpinner size={small ? '2x' : '4x'} style={style} {...props} />;
}

export default Spinner;
