import {
  LOGIN_TO_ACCOUNT_FULFILLED,
  LOGIN_TO_ACCOUNT_REJECTED,
  LOGOUT_FROM_ACCOUNT_FULFILLED,
  LOGOUT_FROM_ACCOUNT_REJECTED,
  CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED,
  CHANGE_CURRENT_LOGIN_ACCOUNT_REJECTED,
  SELECT_ITEM,
  DESELECT_ITEM,
  FILTER_DATA,
  CHANGE_SELECTED_ITEM,
  DROP_FILTER,
  SET_CURRENT_LOGIN_ACCOUNT,
  DROP_CURRENT_LOGIN_ACCOUNT,
  NEW_SELECT_FEATURES,
  REMOVE_ADD_ACCOUNT_ALERT,
} from '../../constants/accounts.actions';

import { LOGOUT_FULFILLED, DROP_CURRENT_USER } from '../../constants/currentUser.actions';

const InitialState = {
  accounts: [],
  selectedAccount: null,
  errors: [],
  fetching: false,
  filterFetching: false,
  page: 0,
  totalPages: 0,
  featureTypesArr: ['ACCOUNT', 'SYSTEM', 'TARIFF_PLAN', 'PROJECT'],
  featureNamesArr: ['visual.editor.feature'],
  filterData: {
    name: '',
    createDateFrom: null,
    createDateTo: null,
    ownerName: '',
    ownerEmail: '',
    featureNames: [],
    featureTypes: [],
  },
  loggedInAccount: null,
  loggedInAccountFeatures: [],
  addAccountErrors: [],
};

export default function AccountsReducer(state = InitialState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_LOGIN_ACCOUNT_FULFILLED:
    case LOGIN_TO_ACCOUNT_FULFILLED: {
      localStorage.setItem('LOGIN_TO_ACCOUNT', JSON.stringify(action.payload.data.account));
      localStorage.setItem('LOGIN_TO_ACCOUNT_FEATURES', JSON.stringify(action.payload.data.features));

      return {
        ...state,
        loggedInAccount: action.payload.data.account,
        loggedInAccountFeatures: action.payload.data.features,
      };
    }

    case CHANGE_CURRENT_LOGIN_ACCOUNT_REJECTED:
    case LOGIN_TO_ACCOUNT_REJECTED: {
      // TODO remove mock
      return {
        ...state,
        loggedInAccount: null,
      };
    }
    case LOGOUT_FULFILLED:
    case DROP_CURRENT_USER:
    case DROP_CURRENT_LOGIN_ACCOUNT:
    case LOGOUT_FROM_ACCOUNT_FULFILLED: {
      localStorage.removeItem('LOGIN_TO_ACCOUNT');
      localStorage.removeItem('LOGIN_TO_ACCOUNT_FEATURES');
      return {
        ...state,
        loggedInAccount: null,
      };
    }
    case LOGOUT_FROM_ACCOUNT_REJECTED: {
      console.log(action);
      return {
        ...state,
        loggedInAccount: null,
      };
    }
    case CHANGE_SELECTED_ITEM: {
      return {
        ...state,
        selectedAccount: {
          ...state.selectedAccount,
          [action.what]: action.value,
        },
      };
    }
    case SET_CURRENT_LOGIN_ACCOUNT: {
      localStorage.setItem('LOGIN_TO_ACCOUNT', JSON.stringify(action.obj));
      localStorage.setItem('LOGIN_TO_ACCOUNT_FEATURES', JSON.stringify(action.features));
      return {
        ...state,
        loggedInAccount: action.obj,
        loggedInAccountFeatures: action.features,
      };
    }

    case FILTER_DATA: {
      return {
        ...state,
        filterData: {
          ...state.filterData,
          [action.what]: action.value,
        },
      };
    }
    case DROP_FILTER: {
      return {
        ...state,
        filterData: InitialState.filterData,
      };
    }

    case REMOVE_ADD_ACCOUNT_ALERT: {
      let newAccountAlert = [...state.addAccountErrors];
      newAccountAlert.splice(action.key, 1);
      return {
        ...state,
        addAccountErrors: newAccountAlert,
      };
    }

    case SELECT_ITEM: {
      let selectedAccount = state.accounts.filter(item => {
        return item.id === action.id;
      })[0];
      return {
        ...state,
        selectedAccount: selectedAccount,
      };
    }
    case DESELECT_ITEM: {
      return {
        ...state,
        selectedAccount: null,
      };
    }

    case NEW_SELECT_FEATURES: {
      return {
        ...state,
        selectedAccount: {
          ...state.selectedAccount,
          [action.data.name]: action.data.data,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
