import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'altreidsds';
import classNames from 'classnames';

const styles = theme => ({
  buttonsContainer: {
    display: 'inline-block',
  },
  buttonItem: {
    backgroundColor: 'var(--white)',
    color: 'var(--gray-900)',
    fontSize: '.7em',
    padding: '8px 20px',
    fontWeight: 600,
    borderColor: 'var(--primary)',
    borderWidth: '1px 1px 1px 0',
    borderStyle: 'solid',
    borderRadius: 0,
    '&:first-child': {
      borderRadius: '16px 0 0 16px',
      borderWidth: '1px 1px 1px 1px',
    },
    '&:last-child': {
      borderRadius: '0 16px 16px 0',
    },
  },
  activeButton: {
    backgroundColor: 'var(--primary)',
    color: 'var(--white)',
    borderColor: 'transparent',

    '& + $activeButton': {
      borderLeft: '1px solid var(--white)',
    },
  },
});

class ButtonsGroup extends Component {
  render() {
    const { classes, name } = this.props;
    return (
      <div className={classNames(classes.buttonsContainer, this.props.className)}>
        {this.props.children.map((child, index) => {
          return cloneElement(child, {
            key: `buttons_${name}_${index}`,
            className: classNames(
              child.props.className,
              classes.buttonItem,
              child.props.variant === 'raised' && classes.activeButton
            ),
          });
        })}
      </div>
    );
  }
}

ButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ButtonsGroup);
