export class OAuthBehaviour {
    constructor(redirectUrl) {
        this.redirectUrl = redirectUrl;
    }
    tryToRequestAccess(accessUrl) {
        const url = new URL(this.toAbsoluteUrl(accessUrl));
        url.searchParams.append('redirectUrl', this.toAbsoluteUrl(this.redirectUrl));
        return this.openModal(url.toString());
    }
    openModal(accessUrl) {
        const windowArea = this.calcWindowPosition();
        const windowOpts = this.buildWindowOpts({
            popup: 1,
            toolbar: 0,
            scrollbars: 0,
            status: 0,
            resizable: 0,
            location: 0,
            menuBar: 0,
            width: windowArea.width,
            height: windowArea.height,
            left: windowArea.left,
            top: windowArea.top,
        });
        const authWindow = window.open(this.normalizeUrl(accessUrl), 'OAuthPopup', windowOpts);
        return new Promise((resolve, reject) => {
            if (!authWindow)
                return reject('window.open does not support');
            const timer = setInterval(() => {
                if (authWindow.closed) {
                    clearInterval(timer);
                    reject('closed OAuthPopup');
                }
            }, 1000);
            window.addEventListener('message', (e) => {
                if (e.data.type !== 'OAuthResponse')
                    return;
                authWindow.close();
                resolve(e.data);
            }, false);
        });
    }
    getCurrentHost() {
        return `${window.location.protocol}//${window.location.host}`;
    }
    toAbsoluteUrl(url) {
        if (url.startsWith('http'))
            return url;
        if (url.startsWith('/')) {
            return this.getCurrentHost() + url;
        }
        return this.getCurrentHost() + '/' + url;
    }
    calcWindowPosition() {
        const windowArea = {
            width: Math.floor(window.outerWidth * 0.3),
            height: Math.floor(window.outerHeight * 0.6),
            left: 0,
            top: 0,
        };
        windowArea.left = Math.floor(window.screenX + (window.outerWidth - windowArea.width) / 2);
        windowArea.top = Math.floor(window.screenY + (window.outerHeight - windowArea.height) / 8);
        return windowArea;
    }
    normalizeUrl(url) {
        const sep = url.indexOf('?') !== -1 ? '&' : '?';
        return `${url}${sep}`;
    }
    buildWindowOpts(params) {
        return Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join(',');
    }
}
