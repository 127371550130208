import axios from 'axios';
import { OperatorGroupApi } from '../api/client';

export class AimychatOperatorGroupService {
  static readonly BASE_PATH = '';
  private configsApi: OperatorGroupApi;

  constructor() {
    this.configsApi = new OperatorGroupApi({}, AimychatOperatorGroupService.BASE_PATH, axios);
  }

  async getOperatorGroupsByToken(token: string) {
    const { data } = await this.configsApi.getOperatorGroupsByToken(token);
    return data;
  }
}
