import React from 'react';
import localize from 'localization';
import { InputText } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';

import classes from '../BaseEditForm/BaseEditForm.module.scss';
import classnames from 'classnames';

const { translate: t } = localize;

export default class ThreadsEditForm extends BaseEditForm {
  submit = async e => {
    const { createChannel, editChannel, onClose } = this.props;
    this.setState({ fetching: true });

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      const data = this.buildData();
      const errors = this.validateData(data);

      this.setState({ errors });

      if (errors.length > 0) {
        this.setState({ fetching: false });
        return;
      }

      if (Boolean(this.props.editableChannel.id)) {
        await editChannel(data);
        onClose();
      } else {
        await createChannel(data);
        onClose();
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({ fetching: false });
  };

  buildData = () => {
    const { editableChannel } = this.props;
    let { name, senderName, clientUrl, bearerToken, botToken } = this.form;
    const isEdit = Boolean(editableChannel.id);

    let botName = name?.value || editableChannel?.botName || t(`AddChannelCard ${editableChannel.channelType}`);
    let result = isEdit ? {} : { ...editableChannel };

    result = {
      ...result,
      botName,
      senderName: senderName?.value || botName,
      channelType: editableChannel.channelType,
      customData: {
        bearerToken: bearerToken?.value,
        clientUrl: clientUrl?.value,
      },
      project: {
        ...editableChannel.project,
      },
    };

    if (isEdit) {
      result = {
        ...result,
        id: editableChannel.id,
        accessToken: botToken?.value,
        rollout: 'MANUAL',
        branch: 'master',
      };
    }
    return result;
  };

  validateData = data => {
    const errors = [];

    let { clientUrl } = data.customData;

    if (!clientUrl?.trim()) {
      errors.push(t(`ChannelsAdding: field is required`, t(`ChannelsAdding: clientUrl`)));
    }
    return errors;
  };

  renderInputs() {
    const { editableChannel } = this.props;
    const { clientUrl, bearerToken } = editableChannel.customData || {};

    const isEdit = Boolean(editableChannel.id);

    return (
      <>
        {isEdit && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('Bot token')}</p>
            <InputText
              innerRef={input => (this.form.botToken = input)}
              id='bot_token'
              defaultValue={editableChannel.accessToken}
              placeholder={t('Enter custom', t('Bot token').toLowerCase())}
            />
          </div>
        )}
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{t('ChannelsAdding: clientUrl')}</p>
          <InputText
            id='clientUrl'
            innerRef={input => (this.form.clientUrl = input)}
            defaultValue={clientUrl}
            placeholder={t('Enter custom', t('ChannelsAdding: clientUrl').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('ChannelsAdding: Bearer Token')}</p>
          <InputText
            id='bearer'
            innerRef={input => (this.form.bearerToken = input)}
            defaultValue={bearerToken}
            placeholder={t('Enter custom', t('ChannelsAdding: Bearer Token').toLowerCase())}
          />
        </div>
      </>
    );
  }
}
