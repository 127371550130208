import React, { useEffect, useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, AtreidesLabel } from 'altreidsds';
import { NavLink } from 'react-router-dom';

import isAccess, { isIncluded } from '../../../isAccessFunction';
import localize from '../../../localization';

import { Icon } from '@just-ai/just-ui';
import { useAppSelector } from '../../../storeHooks';
import { GA } from '../../../pipes/pureFunctions';

function BotNavListItem(props) {
  const { listIconClass, textClass, listItemClass, textListItem, botName, location } = props;

  const { currentBot } = useAppSelector(store => ({
    currentBot: store.CurrentProjectsReducer.currentBot,
  }));

  const [links, setLinks] = useState({
    dialogs: `/dialogs/${botName}`,
    statistic: `/statistics/${botName}`,
  });

  const fullPath = `${location.pathname}${location.search}`;

  useEffect(() => {
    if (location.pathname.includes('/statistic') && links.statistic !== fullPath) {
      setLinks({ ...links, statistic: fullPath });
    } else if (fullPath.includes('/dialogs') && links.dialogs !== fullPath) {
      setLinks({ ...links, dialogs: fullPath });
    }
  }, [fullPath, links, location.pathname]);

  const renderListItem = ({ key, link, label, icon, bottomLabel, withoutBotName, eventLabel }) => {
    const dataTestId = link.replace('/', '').replace(/\/.+/gs, '');
    return (
      <ListItem
        onClick={() => GA('GAEvent', 'Sidebar_Menu_Select', 'clicked', eventLabel)}
        disabled={!botName}
        button
        className={listItemClass}
        data-test-id={'Sidebar.' + dataTestId}
        key={key}
      >
        <NavLink to={withoutBotName ? link : `${link}/${botName}`} className={textClass} activeClassName='active'>
          <ListItemIcon className={listIconClass}>{icon}</ListItemIcon>
          <ListItemText className={textListItem} disableTypography primary={localize.translate(label)} />
        </NavLink>
        {bottomLabel}
      </ListItem>
    );
  };

  const { labelNewClass, botType, callsBotType } = props;

  const labelCallsBot = callsBotType === 'IVR' ? 'Ivr' : 'Dialer';

  return [
    isAccess(['ALL']) &&
      renderListItem({
        key: 'dashboard',
        link: '/bot_dashboard',
        label: 'Sidebar:MyBot',
        icon: <Icon name='farRobot' />,
        eventLabel: 'My_Bot',
      }),

    isAccess(['ALL']) &&
      !currentBot?.isProjectSkill &&
      renderListItem({
        key: 'statistics',
        link: '/scenario',
        label: 'Sidebar:Constructor',
        icon: <Icon name='farProjectDiagram' />,
        eventLabel: 'Constructor',
      }),

    currentBot?.isProjectSkill &&
      renderListItem({
        key: 'statistics',
        link: '/skills-post-setup',
        label: 'Sidebar:SkillPostSetup',
        icon: <Icon name='farCube' />,
      }),

    isAccess(['knowledge_base']) &&
      currentBot?.jgraphLite &&
      renderListItem({ key: 'FAQ', link: '/faq', label: 'Sidebar:FAQ', icon: <Icon name='farBookSpells' /> }),

    isAccess(['ALL']) &&
      !currentBot?.isProjectSkill &&
      renderListItem({
        key: 'Libraries',
        link: '/entities',
        label: 'Entities',
        icon: <Icon name='farBookOpen' />,
        eventLabel: 'Entities',
      }),

    isAccess(['ALL']) &&
      !currentBot?.isProjectSkill &&
      !currentBot?.jgraphLite &&
      renderListItem({
        key: 'DataStorageIcon',
        link: '/knowledge',
        label: 'Sidebar:FAQ',
        icon: <Icon name='farBookSpells' />,
        eventLabel: 'FAQ',
      }),

    botType !== 'CALLS_BOT' &&
      renderListItem({
        key: 'mailingtasks',
        link: '/mailingtasks',
        label: 'Sidebar:MailingList',
        icon: <Icon name='farPaperPlane' />,
      }),

    (isAccess(['ALL']) || (botType === 'CALLS_BOT' && isIncluded(['zenflow_telephony_feature']))) &&
      renderListItem({
        key: 'PeopleIcon',
        link: '/channels',
        label: botType === 'CALLS_BOT' ? labelCallsBot : 'Sidebar:Channels',
        icon: <Icon name='farCompressAlt' />,
        bottomLabel: (
          <AtreidesLabel release='1.7.1' currentRelease={process.env.REACT_APP_RELEASE} classes={labelNewClass} />
        ),
        eventLabel: 'Channels',
      }),

    isAccess(['ALL']) &&
      renderListItem({
        key: 'TimelineIcon',
        link: links.statistic,
        withoutBotName: true,
        label: 'Statistics',
        icon: <Icon name='farChartLine' />,
        eventLabel: 'Statistics',
      }),

    isAccess(['ALL']) &&
      isIncluded(['zenflow_dialogs_feature']) &&
      renderListItem({
        key: 'LibraryBookIcon',
        link: links.dialogs,
        withoutBotName: true,
        label: 'Dialogs',
        icon: <Icon name='farComments' />,
        eventLabel: 'Dialogs',
      }),

    isAccess(['ALL']) &&
      !currentBot?.isProjectSkill &&
      renderListItem({
        key: 'botSetting',
        link: '/settings',
        label: 'Settings',
        icon: <Icon name='farCog' />,
        eventLabel: 'Settings',
      }),
  ].filter(Boolean);
}

export default React.memo(BotNavListItem);
