import {
  FETCH_CHANNELS_FULFILLED,
  FETCH_CHANNELS_PENDING,
  FETCH_CHANNELS_REJECTED,
  DROP_CHANNELS,
  DROP_CHANNEL,
  CREATE_CHANNEL_FULFILLED,
  REMOVE_CHANNEL_FULFILLED,
  EDIT_CHANNEL_FULFILLED,
  SAVE_EDITABLE_CHANNEL_FULFILLED,
  SET_CHANNEL_TO_CREATION,
  SET_CHANNEL_TO_EDIT,
  FETCH_WEBIM_WEBHOOK_FULFILLED,
  CREATE_CHANNEL_PENDING,
  CREATE_CHANNEL_REJECTED,
} from '../../constants/operatorChannels.actions';

import * as CHANNELS_ACTIONS from '../../constants/channels.actions';

import { parseErrors } from '../../pipes/pureFunctions';
import { isArray, merge } from 'lodash';

const blankChannel = {
  id: null,
  type: 'JIVOSITE',
  config: {},
};

const InitialState = {
  fetching: false,
  errors: [],
  channels: [],
  editableChannel: blankChannel,
  fetchingAddingModal: false,
};

export default function OperatorChannelsReducer(state = InitialState, action) {
  switch (action.type) {
    case FETCH_CHANNELS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        channels: isArray(action.payload.data) ? action.payload.data : [],
      };
    }

    case FETCH_CHANNELS_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case FETCH_CHANNELS_REJECTED: {
      const errors = parseErrors(action.payload.response);
      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case DROP_CHANNELS: {
      return {
        ...state,
        fetching: false,
        errors: InitialState.errors,
      };
    }

    case CHANNELS_ACTIONS.DROP_CHANNEL:
    case DROP_CHANNEL: {
      return {
        ...state,
        errors: InitialState.errors,
        fetching: false,
        editableChannel: InitialState.editableChannel,
      };
    }

    case CREATE_CHANNEL_PENDING: {
      return {
        ...state,
        errors: [],
        fetchingAddingModal: true,
      };
    }

    case CREATE_CHANNEL_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        errors: errors,
        fetchingAddingModal: false,
      };
    }

    case CREATE_CHANNEL_FULFILLED: {
      const newChannels = [...state.channels];

      newChannels.push(action.payload.data);

      return {
        ...state,
        fetchingAddingModal: false,
        channels: newChannels,
      };
    }

    case EDIT_CHANNEL_FULFILLED: {
      const newChannels = [...state.channels];

      const index = newChannels.findIndex(channel => {
        return channel.id === action.payload.data.id;
      });

      newChannels.splice(index, 1, action.payload.data);
      //todo

      return {
        ...state,
        channels: newChannels,
      };
    }

    case REMOVE_CHANNEL_FULFILLED: {
      let newChannels = [...state.channels];

      newChannels = newChannels.filter(channel => {
        return channel.id !== action.payload.data.id;
      });

      return {
        ...state,
        channels: newChannels,
      };
    }

    case SAVE_EDITABLE_CHANNEL_FULFILLED: {
      let newChannels = [...state.channels];

      newChannels.splice(
        newChannels.findIndex(item => item.id === action.payload.data.id),
        1,
        action.payload.data
      );

      return {
        ...state,
        channels: newChannels,
        fetching: false,
      };
    }

    case SET_CHANNEL_TO_CREATION: {
      return {
        ...state,
        editableChannel: merge({}, InitialState.editableChannel, { type: action.channelType }),
      };
    }

    case SET_CHANNEL_TO_EDIT: {
      return {
        ...state,
        fetching: false,
        editableChannel: {
          ...action.channel,
        },
        errors: InitialState.errors,
      };
    }

    case FETCH_WEBIM_WEBHOOK_FULFILLED: {
      let newChannels = [...state.channels];

      const index = newChannels.findIndex(channel => {
        return channel.id === action.payload.config.channelId;
      });

      if (index > -1) {
        newChannels[index] = {
          ...newChannels[index],
          config: {
            ...newChannels[index].config,
            serverURL: action.payload.data,
          },
        };
      }

      return {
        ...state,
        channels: newChannels,
        editableChannel: {
          ...state.editableChannel,
          config: {
            ...state.editableChannel.config,
            serverURL: action.payload.data,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
