import React, { useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import cn from 'classnames';
import { mergeWith } from 'lodash';
import { bindActionCreators } from 'redux';
import { BehaviorSubject, Subject } from 'rxjs';
import { Button, Icon, useId } from '@just-ai/just-ui';
import { useToggle } from '@just-ai/just-ui/dist/utils';
import { ProjectSkillDataUpdate, StatusEnum, ProjectSkillRead } from '@just-ai/api/dist/generated/Editorbe';
import { BotProjectReadWithConfigDto } from '@just-ai/api/dist/generated/Botadmin';

import localization, { t } from 'localization';
import { GA } from 'pipes/pureFunctions';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import { useDebounce } from 'helpers/hooks/useDebounce';

import { deployScenario } from '../../actions/scenario.actions';
import { setCurrentProject } from '../../actions/currentProject.actions';
import { templatesWizardLocalization } from './localization/templatesWizard.loc';
import WizardTooltip from './primitives/WizardTooltip';
import AddNewSkillModal from './modals/AddNewSkillModal';
import DeleteSkillModal from './modals/DeleteSkillModal';
import { useWizardSkills } from './useWizardSkills';

import { BASE_WIZARD_PATH } from './shared/consts';
import PseudoWidget from './pseudo-widget';

import styles from './styles.module.scss';

localization.addTranslations(templatesWizardLocalization);

export function combineMerge(defaultList: any, targetList: any, key: string) {
  if (typeof defaultList === 'string' && typeof targetList === 'string') {
    return targetList || defaultList;
  }

  if (Array.isArray(targetList) && Array.isArray(defaultList)) {
    if (targetList.length !== defaultList.length) {
      console.error(`Different count of elements in default and values list by key: "${key}".\n
        defaultList: ${JSON.stringify(defaultList)}, \n
        targetList: ${JSON.stringify(targetList)}`);
      return targetList;
    }
    return targetList.map((item, index) => {
      const defaultEl = defaultList[index];
      const el = { ...item };
      if (!defaultEl) return el;
      for (let key in el) {
        if (!defaultEl[key]) continue;
        if (typeof el[key] === 'string' && typeof defaultEl[key] === 'string') {
          el[key] = el[key] || defaultEl[key];
        } else {
          el[key] = mergeWith(defaultEl[key], el[key], combineMerge);
        }
      }
      return el;
    });
  }
}

export const TemplatesWizardSaveAction$ = new Subject();
export const TemplatesWizardSavePromise$ = new BehaviorSubject<Promise<any> | undefined>(undefined);
export const TemplatesWizardIsValid$ = new BehaviorSubject(false);

type TemplatesWizardProps = {
  values?: Record<string, any>;
  initialSkills?: ProjectSkillRead[];
  isPostSetup?: boolean;
  onChange?: (updatedValues: ProjectSkillDataUpdate, commit?: boolean) => Promise<any>;
  registeredSkills: ProjectSkillRead[];
};
const TemplatesWizard = ({ values, initialSkills, isPostSetup, onChange, registeredSkills }: TemplatesWizardProps) => {
  const validateTooltipId = useId();
  const dispatch = useAppDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          deployScenario,
          setCurrentProject,
        },
        dispatch
      ),
    [dispatch]
  );
  const { currentBot } = useAppSelector(store => ({
    currentBot: store.CurrentProjectsReducer.currentBot as BotProjectReadWithConfigDto,
  }));
  const history = useHistory();
  const pageParams = useParams<{ projectShortName: string }>();
  const {
    skills,
    invalidSkills,
    skillsState,
    isAllSkillsDone,
    addNewSkills,
    skillsToDelete,
    deleteSkills,
    resetSkillsToDelete,
  } = useWizardSkills(initialSkills || [], registeredSkills, values, isPostSetup);

  const [isAddNewSkillOpened, , , toggleAddNewSkillOpened] = useToggle(false);

  const newState = useDebounce(skillsState, 800);

  const skillsNames = useMemo(() => skills.map(skill => skill.skillConfig.skillName), [skills]);

  useEffect(() => {
    PseudoWidget.ShowDeployButton$.next(true);
    return () => PseudoWidget.ShowDeployButton$.next(false);
  }, []);

  const isDeleteSkillOpened = useMemo(() => {
    return skillsToDelete.length > 0;
  }, [skillsToDelete.length]);

  const isAllSkillsAreValid = useMemo(() => {
    return invalidSkills.length === 0;
  }, [invalidSkills.length]);

  const finalValues = useMemo(() => {
    const defaultValues = JSON.parse(JSON.stringify(newState.default));
    const res = mergeWith(defaultValues, newState.values, combineMerge);
    console.log('Merged skills data', res);
    return res;
  }, [newState]);

  const save = useCallback(
    (status?: StatusEnum, commit?: boolean) => {
      if (isPostSetup) status = undefined;
      if (isPostSetup) GA(undefined, 'Save', undefined, skillsNames.join(', '));

      return onChange?.(
        {
          skills: skills.map(skill => ({
            name: skill.skillConfig.skillName,
            language: skill.skillConfig.language,
          })),
          envs: finalValues,
          status: status ? { status } : undefined,
        },
        commit
      );
    },
    [finalValues, isPostSetup, onChange, skills, skillsNames]
  );

  const publish = useCallback(async () => {
    GA(undefined, 'Publish', undefined, skillsNames.join(', '));
    await save(StatusEnum.FINISHED, true);
    await actions.deployScenario(currentBot.id);
  }, [actions, currentBot.id, save, skillsNames]);

  useEffect(() => {
    const sub = TemplatesWizardSaveAction$.subscribe(() => {
      GA(undefined, 'Bot_Test', undefined, skillsNames.join(', '));
      TemplatesWizardSavePromise$.next(save(StatusEnum.FILLED));
    });

    return () => {
      sub.unsubscribe();
    };
  }, [save, skills, skillsNames]);

  const goForward = useCallback(async () => {
    await save(StatusEnum.FINISHED, true);
    await actions.deployScenario(currentBot.id);
    GA(undefined, 'Customized_Skills_Next', undefined, skills.map(skill => skill.skillConfig.skillName).join(', '));
    history.push(`${BASE_WIZARD_PATH}/skill/skills-select/${pageParams.projectShortName}/connect`);
  }, [actions, currentBot.id, history, pageParams.projectShortName, save, skills]);

  const isOneStandaloneSkill = skills.length === 1 && skills[0].skillConfig.denySkills === '*';
  const canSave = isAllSkillsAreValid && isAllSkillsDone;

  useEffect(() => {
    TemplatesWizardIsValid$.next(isAllSkillsAreValid);
  }, [isAllSkillsAreValid]);

  const addSkillButton = (
    <Button
      color='secondary'
      outline
      className={styles.TemplatesWizard__bottomActions__addSkillBtn}
      onClick={toggleAddNewSkillOpened}
    >
      <Icon name='farPlus' />
      <span>{t('SkillsWizard:Actions:AddSkill')}</span>
    </Button>
  );

  return (
    <>
      <div className={styles.TemplatesWizard}>
        <div className={styles.TemplatesWizard__skills}>
          {skills.map((skill, index) => (
            <skill.ViewComponent key={skill.skillConfig.skillName} index={index} />
          ))}
        </div>
        <div className={styles.TemplatesWizard__bottomActions}>
          {isPostSetup ? (
            <>
              <div className={styles.TemplatesWizard__bottomActions__left}>
                <Button disabled={!canSave} onClick={save as () => void} color='secondary' outline>
                  {t('Save')}
                </Button>
                <Button disabled={!canSave} color='primary' onClick={publish}>
                  {t('SkillsWizard:Actions:SaveAndPublish')}
                </Button>
              </div>
              <div className={styles.TemplatesWizard__bottomActions__right}>{addSkillButton}</div>
            </>
          ) : (
            <>
              <div className={styles.TemplatesWizard__bottomActions__left}>
                {!isOneStandaloneSkill && addSkillButton}
                <div
                  id={validateTooltipId}
                  className={cn({
                    preventPointerEvents: !canSave,
                  })}
                >
                  <Button
                    data-test-id='SkillsWizard:Actions:GoForward'
                    color='primary'
                    disabled={!canSave}
                    onClick={goForward}
                  >
                    {t('SkillsWizard:Actions:GoForward')}
                  </Button>
                </div>
                {!canSave && (
                  <WizardTooltip trigger='hover' placement='top' target={validateTooltipId}>
                    {t('FAQSkill:Error:Validate')}
                  </WizardTooltip>
                )}
              </div>

              <div className={styles.TemplatesWizard__bottomActions__right}></div>
            </>
          )}
        </div>
      </div>
      <AddNewSkillModal
        currentSkills={skills}
        isOpen={isAddNewSkillOpened}
        onCancel={toggleAddNewSkillOpened}
        onSubmit={addNewSkills}
        registeredSkills={registeredSkills}
      />
      <DeleteSkillModal
        skillsToDelete={skillsToDelete}
        isOpen={isDeleteSkillOpened}
        onCancel={resetSkillsToDelete}
        onSubmit={deleteSkills}
      />
    </>
  );
};

export default TemplatesWizard;
