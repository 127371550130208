import {
  FETCH_CHANNELS_FULFILLED,
  FETCH_CHANNELS_PENDING,
  FETCH_CHANNELS_REJECTED,
  DROP_CHANNELS,
  PUBLISH_CHANNEL_FULFILLED,
  CREATE_CHANNEL_FULFILLED,
  REMOVE_CHANNEL_FULFILLED,
  EDIT_CHANNEL_FULFILLED,
  SAVE_EDITABLE_CHANNEL_FULFILLED,
  ALEXA_SET_REFRESH_TOKEN_PENDING,
  ALEXA_SET_REFRESH_TOKEN_REJECTED,
  ALEXA_SET_REFRESH_TOKEN_FULFILLED,
  PUBLISH_CHANNEL_PENDING,
  PUBLISH_CHANNEL_REJECTED,
} from '../../constants/channels.actions';

import { GET_DEPLOY_STATUS_FULFILLED, SET_DEPLOY_STATUS } from '../../constants/projects.actions';

import { parseErrors } from '../../pipes/pureFunctions';
import { cloneDeep, isArray } from 'lodash';

import {
  AUTO_CREATE_RESTERISK_FULFILLED,
  AUTO_CREATE_RESTERISK_PENDING,
  AUTO_CREATE_RESTERISK_REJECTED,
  CREATE_VOICE_CHANNEL_PENDING,
  CREATE_VOICE_CHANNEL_REJECTED,
  GET_VOICE_CHANNELS_LIST_FULFILLED,
  GET_VOICE_CHANNELS_LIST_PENDING,
  GET_VOICE_CHANNELS_LIST_REJECTED,
  REMOVE_VOICE_CHANNEL_FULFILLED,
  SET_MAILING_STATUS_FULFILLED,
  UPDATE_VOICE_CHANNEL_FULFILLED,
  AUTO_CREATE_RESTERISK_WITH_TRUNK_FULFILLED,
  AUTO_CREATE_RESTERISK_WITH_TRUNK_PENDING,
  AUTO_CREATE_RESTERISK_WITH_TRUNK_REJECTED,
} from '../../constants/voiceChannel.actions';

const InitialState = {
  fetching: false,
  errors: [],
  channels: [],
  voiceChannelsPaging: {
    pageNum: 0,
    pageSize: 50,
    totalCount: 0,
  },
  voiceChannels: [],
  channelPublishingQueue: [],
};

export default function ChannelsReducer(state = InitialState, action) {
  switch (action.type) {
    case REMOVE_VOICE_CHANNEL_FULFILLED: {
      return {
        ...state,
        voiceChannels: state.voiceChannels.filter(i => i.id !== action.payload.data.id),
      };
    }
    case GET_VOICE_CHANNELS_LIST_REJECTED: {
      return {
        ...state,
        voiceFetching: false,
      };
    }
    case GET_VOICE_CHANNELS_LIST_PENDING: {
      return {
        ...state,
        voiceFetching: true,
      };
    }
    case GET_VOICE_CHANNELS_LIST_FULFILLED: {
      return {
        ...state,
        voiceFetching: false,
        voiceChannelsPaging: action.payload.data.paging,
        voiceChannels: action.payload.data.records,
      };
    }
    case FETCH_CHANNELS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        channels: isArray(action.payload.data) ? action.payload.data : [],
      };
    }

    case FETCH_CHANNELS_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case FETCH_CHANNELS_REJECTED: {
      const errors = parseErrors(action.payload.response);
      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case PUBLISH_CHANNEL_PENDING: {
      return {
        ...state,
      };
    }

    case PUBLISH_CHANNEL_FULFILLED: {
      const data = action.payload.data;
      const newChannels = cloneDeep(state.channels);
      const deployedChannelIndex = newChannels.findIndex(channel => {
        return channel.id === data.id;
      });

      if (data.lastDeployResult.status === 'IN_PROGRESS') {
        data.outdated = false;
        data.lastDeployResult.contentOutdated = false;
      }

      newChannels.splice(deployedChannelIndex, 1, data);

      return {
        ...state,
        channels: newChannels,
      };
    }

    case PUBLISH_CHANNEL_REJECTED: {
      const errors = parseErrors(action.payload.response);
      if (errors[0] === 'editorbe.deploy.deploy_task_concurrent_insert') {
        try {
          const payloadConfig = JSON.parse(action.payload.config.data);
          if (payloadConfig.channelId) {
            const newChannels = cloneDeep(state.channels);
            const deployedChannelIndex = newChannels.findIndex(channel => {
              return channel.id === payloadConfig.channelId;
            });

            const inProgressChannel = newChannels[deployedChannelIndex];
            inProgressChannel.lastDeployResult.status = 'IN_PROGRESS';
            inProgressChannel.outdated = false;
            inProgressChannel.lastDeployResult.contentOutdated = false;

            return {
              ...state,
              channels: newChannels,
            };
          }
        } catch (e) {
          console.error(e);
        }
      }
      return state;
    }

    case SET_DEPLOY_STATUS: {
      const newChannels = [...state.channels];
      const deployedChannelIndex = newChannels.findIndex(channel => {
        return channel.id === action.deploy.botConfigId || channel.botserverBotId === action.deploy.botConfigId;
      });

      if (deployedChannelIndex === -1) return { ...state };
      const newChannel = {
        ...newChannels[deployedChannelIndex],
        outdated: action.deploy.contentOutdated,
        lastDeployResult: {
          ...newChannels[deployedChannelIndex].lastDeployResult,
          id: action.deploy.id,
          finishDate: action.deploy.finishDate,
          startDate: action.deploy.startDate,
          logOutput: action.deploy.logOutput,
          status: action.deploy.status,
        },
      };

      newChannels.splice(deployedChannelIndex, 1, newChannel);

      return {
        ...state,
        channels: newChannels,
      };
    }

    case GET_DEPLOY_STATUS_FULFILLED: {
      const channelDeployResultList = action.payload.data;
      const newChannels = [...state.channels];

      newChannels.forEach((channel, index) => {
        const channelDR = channelDeployResultList.find(dr => dr.botConfigId === channel.id);
        if (Boolean(channelDR)) {
          newChannels[index] = {
            ...channel,
            lastDeployResult: { ...channelDR },
          };
        }
      });

      return {
        ...state,
        channels: newChannels,
      };
    }

    case DROP_CHANNELS: {
      return {
        ...state,
        fetching: false,
        errors: InitialState.errors,
      };
    }

    case CREATE_CHANNEL_FULFILLED: {
      const newChannels = [...state.channels];

      newChannels.push(action.payload.data);

      return {
        ...state,
        channels: newChannels,
        fetching: false,
      };
    }

    case EDIT_CHANNEL_FULFILLED: {
      const newChannels = [...state.channels];

      const index = newChannels.findIndex(channel => {
        return channel.id === action.payload.data.id;
      });

      newChannels.splice(index, 1, action.payload.data);

      return {
        ...state,
        channels: newChannels,
      };
    }

    case REMOVE_CHANNEL_FULFILLED: {
      let newChannels = [...state.channels];

      newChannels = newChannels.filter(channel => {
        return channel.id !== action.payload.data.id;
      });

      return {
        ...state,
        channels: newChannels,
      };
    }

    case SAVE_EDITABLE_CHANNEL_FULFILLED: {
      let newChannels = [...state.channels];
      let newChannel = action.payload.data;
      if (newChannel.lastDeployResult) {
        newChannel.lastDeployResult.status = 'IN_PROGRESS';
      }
      newChannels.splice(
        newChannels.findIndex(item => item.id === action.payload.data.id),
        1,
        newChannel
      );

      return {
        ...state,
        channels: newChannels,
        fetching: false,
      };
    }

    case ALEXA_SET_REFRESH_TOKEN_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case ALEXA_SET_REFRESH_TOKEN_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }
    case ALEXA_SET_REFRESH_TOKEN_FULFILLED: {
      return {
        ...state,

        fetching: false,
      };
    }

    case SET_MAILING_STATUS_FULFILLED: {
      let newVoiceChannels = [...state.voiceChannels];

      let channelIndex = newVoiceChannels.findIndex(voiceChannel => {
        return voiceChannel.id === action.payload.data.id;
      });

      if (Boolean(newVoiceChannels[channelIndex])) {
        newVoiceChannels[channelIndex]['status'] = action.payload.data.status;
      }

      return {
        ...state,
        voiceChannels: newVoiceChannels,
      };
    }

    case AUTO_CREATE_RESTERISK_WITH_TRUNK_PENDING:
    case AUTO_CREATE_RESTERISK_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case AUTO_CREATE_RESTERISK_WITH_TRUNK_REJECTED:
    case AUTO_CREATE_RESTERISK_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case AUTO_CREATE_RESTERISK_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case AUTO_CREATE_RESTERISK_WITH_TRUNK_FULFILLED: {
      const newChannels = [...state.channels];
      newChannels.push(action.payload.data);

      return {
        ...state,
        channels: newChannels,
        fetching: false,
      };
    }

    case CREATE_VOICE_CHANNEL_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case CREATE_VOICE_CHANNEL_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case UPDATE_VOICE_CHANNEL_FULFILLED: {
      let newVoiceChannels = [...state.voiceChannels];
      const data = action.payload.data;
      const updatedChannelIndex = newVoiceChannels.findIndex(channel => channel.id === data.id);
      if (updatedChannelIndex !== -1) {
        newVoiceChannels.splice(updatedChannelIndex, 1, data);
      }
      return {
        ...state,
        voiceChannels: newVoiceChannels,
      };
    }

    default: {
      return state;
    }
  }
}
