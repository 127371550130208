import React from 'react';
import localize from 'localization';
import { InputText } from '@just-ai/just-ui';
import classNames from 'classnames';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import { SmallSwitch } from '../../../../../components';

const { translate } = localize;

export default class ChatApiEditForm extends BaseEditForm {
  componentDidMount() {
    if (!this.props.editableChannel.id) {
      this.setState({
        extraParams: {
          ...this.state.extraParams,
          customData: { ...this.state.extraParams.customData, callbackUrl: '', blockForm: false },
        },
      });
    }
  }

  setCallbackUrl = event =>
    this.setState({
      extraParams: {
        ...this.state.extraParams,
        customData: { ...this.state.extraParams.customData, callbackUrl: event },
      },
    });

  toggleBlockForm = event => {
    this.setState({
      extraParams: {
        ...this.state.extraParams,
        customData: { ...this.state.extraParams.customData, blockForm: event.target.checked },
      },
    });
  };

  renderInputs() {
    const { editableChannel } = this.props;
    const isEdit = !!editableChannel.id;

    return (
      <>
        <div className={classNames(classes.formControl)}>
          <p className={classes.label}>{translate('Bot token')}</p>
          <InputText
            innerRef={input => (this.form.botToken = input)}
            id='bot_token'
            defaultValue={editableChannel.accessToken}
          />
        </div>

        {isEdit && (
          <>
            <div className={classNames(classes.formControl, classes.chatApiInput)}>
              <p className={classes.label}>{translate('GET request example')}</p>
              <InputText
                type='textarea'
                readOnly={true}
                id='get_sample'
                multiline={true}
                rows='5'
                defaultValue={editableChannel.webhookUrl + '?clientId=test&query=/start'}
              />
            </div>
            <div className={classNames(classes.formControl, classes.chatApiInput)}>
              <p className={classes.label}>{translate('POST request example')}</p>
              <InputText
                type='textarea'
                readOnly
                id='post_sample'
                multiline
                rows='5'
                defaultValue={`curl -H "Content-Type: application/json" -X POST -d '{"clientId":"test","query":"test"}' ${editableChannel.webhookUrl}`}
              />
            </div>
          </>
        )}

        <div container alignItems='center' className={classes.formControl}>
          <p className={classes.label}>{translate('ChatWidgetEditor use button label')}</p>

          <SmallSwitch
            checked={this.state.extraParams.customData?.blockForm}
            color='primary'
            onChange={this.toggleBlockForm}
          />
        </div>
        <div className={classNames(classes.formControl)}>
          <p className={classes.label}>{translate('Webhook address')}</p>

          <InputText
            inputRef={input => (this.form.callbackUrl = input)}
            id='callbackUrl'
            onChange={this.setCallbackUrl}
            defaultValue={editableChannel.customData.callbackUrl}
          />
        </div>
      </>
    );
  }
}
