import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Button, Icon, JustSelect, InputText as JustUiInputText } from '@just-ai/just-ui';

import { t } from 'localization';

import { addKeyToRow } from 'modules/TemplatesWizard/FieldBuilder/fields/utils';

import { MenuItems, RowTableType } from '../types';
import { filterAvailableMenuOptions } from '../index';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

const DragHandleBox = SortableHandle(() => (
  <td style={{ width: 38 }}>
    <Icon name='farDrag' style={{ cursor: 'grab' }} />
  </td>
));

interface RowProps {
  availableMenuItems: MenuItems;
  onChangeInner: (value: any, name: string) => void;
  onDelete: () => void;
  rowData: RowTableType;
  rows: RowTableType[];
  isShowErrors: boolean;
}

const Row = SortableElement(
  ({ availableMenuItems, onDelete, onChangeInner, rowData, rows, isShowErrors }: RowProps) => {
    const ref = useRef<HTMLTableRowElement | null>(null);

    const availableMenuOptions = useMemo(() => {
      const existedMenuItemsWithoutCurrent = rows.filter(
        el => el.botAction !== rowData.botAction || el.action !== rowData.action
      );
      return filterAvailableMenuOptions(availableMenuItems, existedMenuItemsWithoutCurrent);
    }, [availableMenuItems, rowData, rows]);

    return (
      <tr ref={ref} className={styles.row}>
        <DragHandleBox />
        <td className={styles.textInput}>
          <JustUiInputText
            className={styles.textArea}
            value={rowData.buttonName}
            maxLength={20}
            errorText={isShowErrors && !rowData.buttonName && t('TemplatesWizard:Menu:Errors:ButtonName')}
            onChange={val => onChangeInner(val, 'buttonName')}
            placeholder={t('TemplatesWizard:Menu:Fields:Title:Placeholder')}
          />
        </td>
        <td className={styles.actionColumn}>
          <JustSelect
            position='fixed'
            options={Object.keys(availableMenuOptions).map(option => ({
              value: option,
              label: option,
            }))}
            errorText={isShowErrors && !rowData.action && t('TemplatesWizard:Menu:Errors:Action')}
            disabled={rowData.isFixed}
            inputPlaceholder={t('TemplatesWizard:Menu:Fields:Action:Placeholder')}
            value={rowData.action || ''}
            onChange={val => {
              const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
              onChangeInner(oneEl.toString(), 'action');
              onChangeInner('', 'botAction');
            }}
          />
        </td>
        <td className={styles.botActionColumn}>
          <JustSelect
            className={styles.botActionColumn}
            position='fixed'
            disabled={rowData.isFixed}
            options={(availableMenuOptions[rowData.action] || []).map(option => ({
              value: option.title,
              label: option.title,
            }))}
            errorText={isShowErrors && !rowData.botAction && t('TemplatesWizard:Menu:Errors:BotAction')}
            adaptiveDropdownWidth
            inputPlaceholder={t('TemplatesWizard:Menu:Fields:BotAction:Placeholder')}
            value={rowData.botAction || ''}
            onChange={val => {
              const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
              onChangeInner(oneEl.toString(), 'botAction');
            }}
          />
        </td>
        <td style={{ width: 38, paddingLeft: 0 }}>
          <Button
            disabled={rowData.isFixed}
            className={styles.deleteBtn}
            type='button'
            color='secondary'
            size='md'
            withoutPadding
            flat
            onClick={onDelete}
          >
            <Icon name='farTrashAlt' />
          </Button>
        </td>
      </tr>
    );
  }
);

type SortableRowsProps = {
  rows: (RowTableType & { key: string })[];
  onChangeInner: (index: number, value: any, name: string) => void;
  delRow: (index: number) => void;
  availableMenuItems: MenuItems;
  isShowErrors: boolean;
};
const SortableRows = SortableContainer(
  ({ rows, onChangeInner, delRow, availableMenuItems, isShowErrors }: SortableRowsProps) => {
    return (
      <tbody>
        {rows.map((row, index) => (
          <Row
            index={index}
            key={row.key}
            rowData={rows[index]}
            rows={rows}
            availableMenuItems={availableMenuItems}
            onChangeInner={(value, name) => onChangeInner(index, value, name)}
            onDelete={() => delRow(index)}
            isShowErrors={isShowErrors}
          />
        ))}
      </tbody>
    );
  }
);

type MenuTableProps = {
  onChange: (data: RowTableType[]) => void;
  availableMenuItems: MenuItems;
  isShowErrors: boolean;
  initialRows?: RowTableType[];
  maxItems?: number;
};
const MenuTable = ({ availableMenuItems, onChange, initialRows, isShowErrors, maxItems }: MenuTableProps) => {
  const [innerValue, setInnerValue] = useState(() => {
    if (initialRows && initialRows.length > 0) {
      return initialRows.map(addKeyToRow);
    }
    return [addKeyToRow({ buttonName: '', action: '', botAction: '' } as RowTableType)];
  });

  useEffect(() => {
    if (initialRows && initialRows.length > 0) setInnerValue(initialRows.map(addKeyToRow));
  }, [initialRows]);

  useEffect(() => {
    if (innerValue.length > 0) return;
    setInnerValue([addKeyToRow({ buttonName: '', action: '', botAction: '' } as RowTableType)]);
  }, [innerValue]);

  const ref = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    onChange(innerValue);
  }, [onChange, innerValue]);

  const onChangeInner = useCallback((index: number, value: any, name: string) => {
    setInnerValue(prev => {
      prev[index] = Object.assign({}, prev[index], {
        [name]: value,
      });
      return prev.concat();
    });
  }, []);

  const addRow = useCallback(() => {
    setInnerValue(prev => [...prev, addKeyToRow({ buttonName: '', action: '', botAction: '' } as RowTableType)]);
  }, []);

  const delRow = useCallback((index: number) => {
    setInnerValue(prev => {
      prev.splice(index, 1);
      return prev.concat();
    });
  }, []);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setInnerValue(items => arrayMove(items, oldIndex, newIndex));
  }, []);

  const canAddNewButton = useMemo(() => {
    if (maxItems && innerValue.length >= maxItems) return false;
    return !isEmpty(filterAvailableMenuOptions(availableMenuItems, innerValue));
  }, [innerValue, maxItems, availableMenuItems]);

  return (
    <div className={styles.MenuTable} ref={ref}>
      <div className={styles.MenuTable__title}>{t('TemplatesWizard:Menu:aboveTableTitle')}</div>
      <table className={styles.MenuTable__table}>
        <thead>
          <tr className={styles.row}>
            <th></th>
            <th>{t('TemplatesWizard:Menu:TableHeader:Title')}</th>
            <th>{t('TemplatesWizard:Menu:TableHeader:Action')}</th>
            <th>{t('TemplatesWizard:Menu:TableHeader:BotAction')}</th>
            <th></th>
          </tr>
        </thead>
        <SortableRows
          useDragHandle
          onSortEnd={onSortEnd}
          availableMenuItems={availableMenuItems}
          isShowErrors={isShowErrors}
          lockAxis='y'
          helperClass={styles.drugHelper}
          lockToContainerEdges
          rows={innerValue}
          onChangeInner={onChangeInner}
          delRow={delRow}
        />
      </table>
      {canAddNewButton && (
        <Button className={styles.MenuTable__addBtn} type='button' color='info' flat onClick={addRow}>
          <Icon name='farPlus' />
          <span>{t('TemplatesWizard:Menu:Table:AddRowButton')}</span>
        </Button>
      )}
    </div>
  );
};

export default React.memo(MenuTable);
