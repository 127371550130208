import React, { Component } from 'react';
import localize from 'localization';
import classNames from 'classnames';
import { Spinner } from 'components';
import { Modal, InputText, Banner } from '@just-ai/just-ui';

import classes from './BaseEditForm.module.scss';
import { tWithCheck } from '../../../../../localization';

const { translate: t } = localize;

export default class BasicEditForm extends Component {
  defaultFields = {
    name: true,
    botId: true,
    type: true,
  };

  constructor(props) {
    super(props);
    this.form = {};
    this.disableButtonCreate = false;

    this.state = {
      rollout: true,
      errors: [],
      extraParams: { customData: props.editableChannel.customData },
    };
  }

  submit = async e => {
    this.setState({ fetching: true });

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      if (Boolean(this.props.editableChannel.id)) {
        await this.saveChannel();
      } else {
        await this.createChannel();
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ fetching: false });
  };

  createChannel = async () => {
    const { createChannel, editableChannel, onClose } = this.props;
    let { name, botToken, senderName } = this.form;
    let botName = name?.value || editableChannel.botName || t(`AddChannelCard ${editableChannel.channelType}`);
    const channelData = {
      ...editableChannel,
      botName,
      senderName: senderName?.value || botName,
      accessToken: botToken?.value || '',
      channelType: editableChannel.channelType,
      project: {
        ...editableChannel.project,
      },
      ...this.state.extraParams,
    };

    await createChannel(channelData);
    onClose();
  };

  saveChannel = async () => {
    const { editChannel, onClose } = this.props;

    const channelData = {
      botName: this.form.name.value,
      senderName: this.form.name.value,
      accessToken: this.form.botToken?.value || '',
      channelType: this.props.editableChannel.channelType,
      rollout: 'MANUAL',
      id: this.props.editableChannel.id,
      branch: 'master',
      project: {
        ...this.props.editableChannel.project,
      },
      ...this.state.extraParams,
    };

    await editChannel(channelData);
    onClose();
  };

  setSubmitButtonDisabled = value => {
    this.disableButtonCreate = value;
  };

  getMessageAboutConfiguration = () => {
    const { editableChannel, appConfig } = this.props;
    const { channelType, type } = editableChannel;
    const showHelp = appConfig?.zenflow?.showHelp;

    if (this.state.bannerDescription) {
      return (
        <Banner
          className=''
          type='info'
          BannerText={() => (
            <span
              dangerouslySetInnerHTML={{
                __html: this.state.bannerDescription,
              }}
            ></span>
          )}
          withIcon
        />
      );
    }

    return (
      showHelp && (
        <Banner
          variant='info'
          withIcon
          BannerText={() => (
            <a target='_blank' rel='noopener noreferrer' href={t(`${channelType || type} doc link`)}>
              {t('ChannelsAdding: how to add', t(`AddChannelCard ${channelType || type}`))}
            </a>
          )}
        />
      )
    );
  };

  renderInputs() {
    return null;
  }

  cancelEdit = () => {
    this.setState({
      errors: [],
    });
    this.props.onClose();
  };

  renderChanneTypeFormControl = () => {
    const { editableChannel } = this.props;
    return (
      <div className={classNames(classes.formControl, classes.hidden)}>
        <p className={classes.label}>{t('Channel type')}</p>
        <InputText
          id='channel_type'
          disabled
          defaultValue={editableChannel.channelType}
          placeholder={t('Enter custom', t('Channel type').toLowerCase())}
        />
      </div>
    );
  };

  checkIsFetching = () => this.props.fetching || this.state.fetching;

  renderFooter = () => null;

  render() {
    const { open, dialogHeadline, headlineIcon, errors, fetching, editableChannel } = this.props;
    const stateErrors = this.state.errors || [];
    const localFetching = this.state.fetching;
    const allErrors = [...stateErrors, ...errors];
    const isOldFacebook = editableChannel.channelType === 'FACEBOOK' && !this.props.appConfig.metaSettings?.enabled;
    const errorHtml =
      isOldFacebook && allErrors.length
        ? t('Auth:Facebook:Error:Base')
        : allErrors
            .map(item =>
              typeof item === 'string'
                ? tWithCheck(`BaseEditForm:BE-error ${item}`)
                  ? tWithCheck(`BaseEditForm:BE-error ${item}`)
                  : item
                : item.error
            )
            .join('<br/>');

    return (
      <Modal
        isOpen={open}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>{headlineIcon}</div>
            {dialogHeadline}
          </div>
        }
        onCancelClick={this.cancelEdit}
        onActionClick={this.submit}
        buttonSubmitText={t(Boolean(editableChannel.id) ? 'Save' : this.state.customSubmitTitle || 'Connect')}
        buttonCancelText={t('Cancel')}
        buttonSubmitTestId='BasicEditForm.Submit'
        buttonCancelTestId='BasicEditForm.Cancel'
        buttonCancelDisabled={this.props.fetching || this.state.fetching || this.state.isLoading}
        buttonSubmitDisabled={this.checkIsFetching() || this.disableButtonCreate}
        headlineIcon={headlineIcon}
        className={this.modalClassName}
        disableActionButtonAutoFocus
      >
        {(fetching || localFetching) && <Spinner />}

        {errorHtml && (
          <div className={classes.formControl}>
            <Banner type='danger' BannerText={() => <div dangerouslySetInnerHTML={{ __html: errorHtml }} />} />
          </div>
        )}

        <form
          key={
            Boolean(editableChannel.id)
              ? `edit_channel_${editableChannel.id}`
              : `create_channel_${editableChannel.channelType}`
          }
          className={classes.form}
          onSubmit={this.submit}
        >
          {this.defaultFields.name && (
            <div className={classes.formControl}>
              <p className={classes.label}>{t('Name')}</p>
              <InputText
                id='channel_name'
                innerRef={input => (this.form.name = input)}
                placeholder={t('Enter custom', t('Name').toLowerCase())}
                errorText={this.state.channelNameError}
                defaultValue={
                  editableChannel.senderName ||
                  (this.defaultLabel && t(this.defaultLabel)) ||
                  editableChannel.botName ||
                  t(`AddChannelCard ${editableChannel.channelType}`)
                }
              />
            </div>
          )}

          {this.defaultFields.type && this.renderChanneTypeFormControl()}
          {this.defaultFields.botId && Boolean(editableChannel.botserverBotId) && (
            <div className={classNames(classes.formControl, classes.hidden)}>
              <p className={classes.label}>{t('Channel ID')}</p>
              <InputText
                placeholder={t('Enter custom', t('Channel ID').toLowerCase())}
                id='channel_type_botserver_bot_id'
                inputProps={{ readOnly: true }}
                defaultValue={editableChannel.botserverBotId}
              />
            </div>
          )}
          {this.renderInputs()}
          {this.renderFooter()}
          {this.getMessageAboutConfiguration()}
        </form>
      </Modal>
    );
  }
}
