import * as scenarioActions from '../../actions/scenario.actions';
import { SET_CURRENT_PROJECT } from '../../constants/projects.actions';
import { cloneDeep } from 'lodash';

import { SET_HASH_DEFAULT } from '../../actions/scenario.actions';

const InitialState = {
  fetching: false,
  initialNodes: [],
  hash: '',

  testRun: false,
  voiceTestRun: false,
  testRunPending: false,
  voiceTestRunPending: false,
};

export default function ScenarioReducer(state = InitialState, action) {
  switch (action.type) {
    case scenarioActions.TEST_RUN_PENDING: {
      return {
        ...state,
        testRunPending: true,
      };
    }
    case scenarioActions.VOICE_TEST_RUN_PENDING: {
      return {
        ...state,
        voiceTestRunPending: true,
      };
    }
    case scenarioActions.TEST_RUN_REJECTED: {
      return {
        ...state,
        testRunPending: false,
        testRun: false,
      };
    }
    case scenarioActions.VOICE_TEST_RUN_REJECTED: {
      return {
        ...state,
        voiceTestRunPending: false,
        voiceTestRun: false,
      };
    }
    case scenarioActions.TEST_RUN_FULFILLED: {
      return {
        ...state,
        testRunPending: false,
        testRun: true,
      };
    }
    case scenarioActions.VOICE_TEST_RUN_FULFILLED: {
      return {
        ...state,
        voiceTestRunPending: false,
        voiceTestRun: true,
      };
    }
    case scenarioActions.STOP_TEST: {
      return {
        ...state,
        testRun: false,
        testRunPending: false,
      };
    }
    case scenarioActions.SAVE_JSON_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case scenarioActions.SAVE_JSON_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }
    case scenarioActions.SAVE_JSON_FULFILLED: {
      let iniNodes = [];
      try {
        iniNodes = JSON.parse(action.payload.config.data);
        iniNodes = iniNodes.blocks;
      } catch (e) {
        iniNodes = {};
      }
      return {
        ...state,
        initialNodes: iniNodes,
        hash: action.payload.data,
        fetching: false,
      };
    }
    case scenarioActions.LOAD_JSON_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case scenarioActions.LOAD_JSON_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }
    case scenarioActions.LOAD_JSON_FULFILLED: {
      return {
        ...state,
        initialNodes: cloneDeep(action.payload.data.blocks),
        hash: action.payload.data.hash,
        fetching: false,
      };
    }

    case SET_HASH_DEFAULT:
    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        hash: '',
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
