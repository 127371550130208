import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  Button,
  withStyles,
  Grid,
  MenuItem,
  Typography,
  ActionConfirmModal,
  MultiButton,
  AtreidesIcon,
} from 'altreidsds';
import localize from 'localization';
import history from 'appHistory';
import PublishNoChannel from '../../ScenarioCreate/PublishNoChannel';
import * as PropTypes from 'prop-types';

class TopActions extends Component {
  static defaultProps = {
    buttonType: false,
  };

  outsideEvent = null;
  headerDOM = null;
  hInput = null;

  state = {
    isCloseAlertOpen: false,
  };

  setHeader = target => {
    if (Boolean(target)) {
      this.headerDOM = target;
    }
    if (Boolean(this.props.getRef)) {
      this.props.getRef(target);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.disableSave && !this.props.disableSave) {
      document.addEventListener('mousedown', this.clickWatcher);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickWatcher);
  }

  showCloseAlert = () => {
    this.setState({
      isCloseAlertOpen: true,
    });
  };

  closeCloseAlert = () => {
    this.setState({
      isCloseAlertOpen: false,
    });
  };

  onExit = () => {
    if (Boolean(this.outsideEvent)) {
      this.outsideEvent.target.dispatchEvent(this.outsideEvent.type);
      this.outsideEvent = null;
      this.closeCloseAlert();
    } else {
      if (this.state.isCloseAlertOpen) {
        this.closeCloseAlert();
      }
      history.goBack();
    }
  };

  clickWatcher = e => {
    //for context click
    if (e.button === 2) return;

    if (Boolean(this.headerDOM) && !this.state.isCloseAlertOpen) {
      let flag = false;
      if (this.props.isWidgetShown) {
        let widget = document.getElementsByClassName('justwidget sided right-sided')[0];
        if (Boolean(widget)) {
          flag = widget.contains(e.target);
        }
      }

      if (Boolean(e.target) && e.target.nodeName !== 'HTML') {
        const isContainTopBotSelectorClass =
          e.target.classList.contains('topBotSelector') || e.target.parentNode.classList.contains('topBotSelector');

        if (
          (Boolean(e.target.parentNode) && e.target.parentNode.nodeName === 'A') ||
          e.target.nodeName === 'A' ||
          e.target.classList.contains('sidebarItem') ||
          e.target.parentNode.parentNode.nodeName === 'A' ||
          e.target.parentNode.parentNode.parentNode.parentNode.nodeName === 'A' ||
          isContainTopBotSelectorClass
        ) {
          if (
            (isContainTopBotSelectorClass || !this.headerDOM.contains(e.target)) &&
            !this.props.fullContainer.contains(e.target) &&
            !flag &&
            !this.props.disableSave
          ) {
            let event = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            });

            this.outsideEvent = {
              target: e.target,
              type: event,
            };
            e.preventDefault();
            e.stopPropagation();
            this.showCloseAlert();
          }
        }
      }
    }
  };

  save$Exit = () => {
    this.props.save().then(() => {
      this.onExit();
    });
  };

  save = () => {
    const { currentBot, getChannelsList } = this.props;
    const asyncFocus = async () => {
      await this.hInput.focus();
    };

    return asyncFocus()
      .then(() => {
        return this.props.save();
      })
      .then(() => {
        setTimeout(() => getChannelsList(currentBot.shortName), 2000);
      });
  };

  render() {
    const {
      classes,
      node,
      widgetType,
      showWidget,
      showVoiceWidget,
      isWidgetShown,
      testRunPending,
      voiceTestRunPending,
      loadingWidget,
      disableSave,
      clickTestButton,
      scenarioFetching,
      fetchingChannels,
      channels,
      dataTestId,
    } = this.props;

    const testWidgetInProgress =
      testRunPending || (((scenarioFetching && clickTestButton) || loadingWidget) && widgetType === 'simple');
    const voiceTestWidgetInProgress =
      voiceTestRunPending || (((scenarioFetching && clickTestButton) || loadingWidget) && widgetType === 'voice');
    const testButtonsDisable = voiceTestRunPending || testRunPending || scenarioFetching || loadingWidget;

    return (
      Boolean(node) &&
      ReactDOM.createPortal(
        <div ref={this.setHeader}>
          <Grid container spacing={8} justify='flex-end'>
            <StyledTestWidgetButton
              dataTestId={dataTestId}
              widgetShown={isWidgetShown}
              hideWidget={this.props.hideWidget}
              disabled={testButtonsDisable}
              voiceTestWidgetInProgress={voiceTestWidgetInProgress}
              testWidgetInProgress={testWidgetInProgress}
              widgetType={widgetType}
              showWidget={showWidget}
              showVoiceWidget={showVoiceWidget}
            />
            {!this.props.newBase && (
              <Grid item>
                <Button
                  color='primary'
                  variant='outlined'
                  id='save-top-button'
                  disabled={disableSave}
                  onClick={this.save}
                >
                  {localize.translate('Save changes')}
                </Button>
                <input type='text' ref={i => (this.hInput = i)} id='hiddenInput' className={classes.hiddenInput} />
              </Grid>
            )}
          </Grid>
          <ActionConfirmModal
            disableBackdropClick
            key='visual-editor-action-confirm-modal'
            open={this.state.isCloseAlertOpen}
            onClose={this.closeCloseAlert}
            title={localize.translate('Close knowledge base')}
            onCancelClick={this.onExit}
            cancelText={localize.translate('Exit')}
            onOkClick={this.save$Exit}
            okText={localize.translate('Save and exit')}
            modalBottom={
              <Button color='primary' variant='outlined' onClick={this.closeCloseAlert}>
                {localize.translate('Cancel')}
              </Button>
            }
          >
            <Typography>{localize.translate('Save changes before exit?')}</Typography>
          </ActionConfirmModal>

          {channels.filter(c => c.channelType !== 'RESTERISK').length === 0 && !fetchingChannels && (
            <PublishNoChannel
              appConfig={this.props.appConfig}
              isCloseAlertOpen={this.state.isCloseAlertOpen}
              setOutsideEvent={e => (this.outsideEvent = e)}
              showCloseAlert={this.showCloseAlert}
              hasUnsavedChanges={!disableSave}
              currentBot={this.props.currentBot}
            />
          )}
        </div>,
        node
      )
    );
  }
}

const styles = theme => ({
  hiddenInput: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: -10000,
    left: -10000,
  },
  multibutton: {
    '& .dropdown-text': {
      display: 'none',
    },
  },
  multibuttonMenuItem: {
    '& .button-text': {
      display: 'none',
    },
    '& svg': {
      marginRight: `${(theme.spacing.unit * 3) / 2}px !important`,
    },
  },
  multibuttonIcon: {},
});

function TestWidgetButton(props) {
  return (
    <Grid item data-test-id={props.dataTestId}>
      {props.widgetShown ? (
        <Button color='primary' variant='outlined' onClick={props.hideWidget} id='widget-test-top-button'>
          {localize.translate('Stop test')}
        </Button>
      ) : (
        <MultiButton
          color='primary'
          id='widget-test'
          className={props.classes.multibutton}
          disabled={props.disabled}
          spinner={props.voiceTestWidgetInProgress || props.testWidgetInProgress}
        >
          <MenuItem
            className={props.classes.multibuttonMenuItem}
            selected={props.widgetType === 'simple'}
            onClick={props.showWidget}
          >
            <AtreidesIcon name='chat' color='default' size='small' className={props.classes.multibuttonIcon} />
            <span className='button-text'>{localize.translate('Run test')}</span>
            <span className='dropdown-text'>{localize.translate('chat')}</span>
          </MenuItem>
          <MenuItem
            className={props.classes.multibuttonMenuItem}
            selected={props.widgetType === 'voice'}
            onClick={props.showVoiceWidget}
          >
            <AtreidesIcon name='voice' color='default' size='small' className={props.classes.multibuttonIcon} />
            <span className='button-text'>{localize.translate('Run test')}</span>
            <span className='dropdown-text'>{localize.translate('voice')}</span>
          </MenuItem>
        </MultiButton>
      )}
    </Grid>
  );
}
export const StyledTestWidgetButton = withStyles(styles, { withTheme: true })(TestWidgetButton);

TestWidgetButton.propTypes = {
  dataTestId: PropTypes.any,
  widgetShown: PropTypes.any,
  hideWidget: PropTypes.any,
  disabled: PropTypes.any,
  voiceTestWidgetInProgress: PropTypes.any,
  testWidgetInProgress: PropTypes.any,
  widgetType: PropTypes.any,
  showWidget: PropTypes.any,
  showVoiceWidget: PropTypes.any,
};

export default withStyles(styles, { withTheme: true })(TopActions);
