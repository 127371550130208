import {
  CREATE_VOICE_CHANNEL_FULFILLED,
  GET_CURRENT_FULFILLED,
  GET_UPLOADED_FILE_FULFILLED,
  REMOVE_FILE_FROM_SERVER_FULFILLED,
  SET_MAILING_STATUS_FULFILLED,
  UPDATE_VOICE_CHANNEL_FULFILLED,
  REMOVE_VOICE_CHANNEL_FULFILLED,
  UNSET_CURRENT,
  SET_MAILING_STATUS_PENDING,
  SET_MAILING_STATUS_REJECTED,
  TEST_CALL_PENDING,
  TEST_CALL_FULFILLED,
  SET_RESTERISK_INCOMING_PENDING,
  SET_RESTERISK_INCOMING_REJECTED,
  SET_RESTERISK_INCOMING_FULFILLED,
  AUTO_CREATE_RESTERISK_PENDING,
  AUTO_CREATE_RESTERISK_REJECTED,
  AUTO_CREATE_RESTERISK_FULFILLED,
  TEST_CALL_CHANNEL_PUBLISH_PENDING,
  GET_VOICES_FULFILLED,
} from '../../constants/voiceChannel.actions';
import {
  PUBLISH_CHANNEL_FULFILLED,
  PUBLISH_CHANNEL_PENDING,
  PUBLISH_CHANNEL_REJECTED,
} from '../../constants/channels.actions';

const InitialState = {
  selectedVoiceChannel: null,
  currentFileData: null,
  fetching: false,
  voices: [],
};

export default function VoiceChannelReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_VOICES_FULFILLED: {
      return {
        ...state,
        voices: action.payload.data,
      };
    }

    case REMOVE_VOICE_CHANNEL_FULFILLED: {
      return {
        ...state,
      };
    }
    case GET_CURRENT_FULFILLED: {
      return {
        ...state,
        selectedVoiceChannel: action.payload.data,
      };
    }
    case CREATE_VOICE_CHANNEL_FULFILLED: {
      return {
        ...state,
        selectedVoiceChannel: action.payload.data,
      };
    }
    case UPDATE_VOICE_CHANNEL_FULFILLED: {
      return {
        ...state,
        selectedVoiceChannel: action.payload.data,
      };
    }
    case GET_UPLOADED_FILE_FULFILLED: {
      return {
        ...state,
        currentFileData: action.payload.data,
      };
    }
    case REMOVE_FILE_FROM_SERVER_FULFILLED: {
      return {
        ...state,
        currentFileData: null,
      };
    }
    case SET_MAILING_STATUS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        selectedVoiceChannel: {
          ...state.selectedVoiceChannel,
          status: action.payload.data.status,
        },
      };
    }

    case SET_MAILING_STATUS_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case SET_MAILING_STATUS_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case PUBLISH_CHANNEL_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case PUBLISH_CHANNEL_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case PUBLISH_CHANNEL_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case UNSET_CURRENT: {
      return {
        ...state,
        selectedVoiceChannel: null,
      };
    }

    case TEST_CALL_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case TEST_CALL_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case SET_RESTERISK_INCOMING_PENDING: {
      return {
        ...state,
        voiceFetching: true,
      };
    }

    case SET_RESTERISK_INCOMING_REJECTED: {
      return {
        ...state,
        voiceFetching: false,
      };
    }

    case SET_RESTERISK_INCOMING_FULFILLED: {
      return {
        ...state,
        voiceFetching: false,
      };
    }

    case AUTO_CREATE_RESTERISK_PENDING: {
      return {
        ...state,
        voiceFetching: true,
      };
    }

    case AUTO_CREATE_RESTERISK_REJECTED: {
      return {
        ...state,
        voiceFetching: false,
      };
    }

    case AUTO_CREATE_RESTERISK_FULFILLED: {
      return {
        ...state,
        voiceFetching: false,
      };
    }

    case TEST_CALL_CHANNEL_PUBLISH_PENDING: {
      return {
        ...state,
        testCallPublishFetching: true,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
