export const SET_EDITABLE_PROJECT = 'SET_EDITABLE_PROJECT';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT';
export const CHANGE_CURRENT_PROJECT = 'CHANGE_CURRENT_PROJECT';
export const UPDATE_EDITABLE_PROJECT = 'UPDATE_EDITABLE_PROJECT';
export const CLEAR_EDITABLE_PROJECT = 'CLEAR_EDITABLE_PROJECT';
export const TOGGLE_EDIT_PROJECT_MODAL = 'TOGGLE_EDIT_PROJECT_MODAL';

export const SAVE_EDITABLE_PROJECT = 'SAVE_EDITABLE_PROJECT';
export const SAVE_EDITABLE_PROJECT_PENDING = 'SAVE_EDITABLE_PROJECT_PENDING';
export const SAVE_EDITABLE_PROJECT_FULFILLED = 'SAVE_EDITABLE_PROJECT_FULFILLED';
export const SAVE_EDITABLE_PROJECT_REJECTED = 'SAVE_EDITABLE_PROJECT_REJECTED';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_PENDING = 'CREATE_PROJECT_PENDING';
export const CREATE_PROJECT_FULFILLED = 'CREATE_PROJECT_FULFILLED';
export const CREATE_PROJECT_REJECTED = 'CREATE_PROJECT_REJECTED';

export const REMOVE_EDITABLE_PROJECT = 'REMOVE_EDITABLE_PROJECT';
export const REMOVE_EDITABLE_PROJECT_PENDING = 'REMOVE_EDITABLE_PROJECT_PENDING';
export const REMOVE_EDITABLE_PROJECT_FULFILLED = 'REMOVE_EDITABLE_PROJECT_FULFILLED';
export const REMOVE_EDITABLE_PROJECT_REJECTED = 'REMOVE_EDITABLE_PROJECT_REJECTED';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_PENDING = 'FETCH_PROJECTS_PENDING';
export const FETCH_PROJECTS_FULFILLED = 'FETCH_PROJECTS_FULFILLED';
export const FETCH_PROJECTS_REJECTED = 'FETCH_PROJECTS_REJECTED';

export const FETCH_PROJECT_STATISTIC = 'FETCH_PROJECT_STATISTIC';
export const FETCH_PROJECT_STATISTIC_FULFILLED = 'FETCH_PROJECT_STATISTIC_FULFILLED';
export const FETCH_PROJECT_STATISTIC_REJECTED = 'FETCH_PROJECT_STATISTIC_REJECTED';
export const FETCH_PROJECT_STATISTIC_PENDING = 'FETCH_PROJECT_STATISTIC_PENDING';

export const SET_DEPLOY_STATUS = 'SET_DEPLOY_STATUS';
export const SET_DEPLOYED = 'SET_DEPLOYED';

export const GET_DEPLOY_STATUS = 'GET_DEPLOY_STATUS';
export const GET_DEPLOY_STATUS_FULFILLED = 'GET_DEPLOY_STATUS_FULFILLED';
export const GET_DEPLOY_STATUS_REJECTED = 'GET_DEPLOY_STATUS_REJECTED';
export const GET_DEPLOY_STATUS_PENDING = 'GET_DEPLOY_STATUS_PENDING';

export const SET_DEPLOYMENTS = 'SET_DEPLOYMENTS';
