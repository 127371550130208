const prefix = '@referrals/';

export const CREATE_PARTNER = prefix + 'CREATE_PARTNER';
export const CREATE_PARTNER_FULFILLED = prefix + 'CREATE_PARTNER_FULFILLED';
export const CREATE_PARTNER_PENDING = prefix + 'CREATE_PARTNER_PENDING';
export const CREATE_PARTNER_REJECTED = prefix + 'CREATE_PARTNER_REJECTED';

export const GET_REFERRALS = prefix + 'GET_REFERRALS';
export const GET_REFERRALS_FULFILLED = prefix + 'GET_REFERRALS_FULFILLED';
export const GET_REFERRALS_PENDING = prefix + 'GET_REFERRALS_PENDING';
export const GET_REFERRALS_REJECTED = prefix + 'GET_REFERRALS_REJECTED';

export const GET_TOKEN = prefix + 'GET_TOKEN';
export const GET_TOKEN_FULFILLED = prefix + 'GET_TOKEN_FULFILLED';
export const GET_TOKEN_PENDING = prefix + 'GET_TOKEN_PENDING';
export const GET_TOKEN_REJECTED = prefix + 'GET_TOKEN_REJECTED';

export const GET_CURRENT_REWARD = prefix + 'GET_CURRENT_REWARD';
export const GET_CURRENT_REWARD_FULFILLED = prefix + 'GET_CURRENT_REWARD_FULFILLED';
export const GET_CURRENT_REWARD_PENDING = prefix + 'GET_CURRENT_REWARD_PENDING';
export const GET_CURRENT_REWARD_REJECTED = prefix + 'GET_CURRENT_REWARD_REJECTED';

export const DOWNLOAD_REPORT = prefix + 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_PENDING = prefix + 'DOWNLOAD_REPORT_PENDING';
export const DOWNLOAD_REPORT_FULFILLED = prefix + 'DOWNLOAD_REPORT_FULFILLED';
export const DOWNLOAD_REPORT_REJECTED = prefix + 'DOWNLOAD_REPORT_REJECTED';

export const FETCH_PARTNER_DATA = prefix + 'FETCH_PARTNER_DATA';
export const FETCH_PARTNER_DATA_PENDING = prefix + 'FETCH_PARTNER_DATA_PENDING';
export const FETCH_PARTNER_DATA_FULFILLED = prefix + 'FETCH_PARTNER_DATA_FULFILLED';
export const FETCH_PARTNER_DATA_REJECTED = prefix + 'FETCH_PARTNER_DATA_REJECTED';

export const DROP_ERRORS = prefix + 'DROP_ERRORS';
