const prefix = '@channels/';

export const FETCH_CHANNELS = prefix + 'FETCH_CHANNELS';
export const FETCH_CHANNELS_FULFILLED = prefix + 'FETCH_CHANNELS_FULFILLED';
export const FETCH_CHANNELS_PENDING = prefix + 'FETCH_CHANNELS_PENDING';
export const FETCH_CHANNELS_REJECTED = prefix + 'FETCH_CHANNELS_REJECTED';

export const CREATE_CHANNEL = prefix + 'CREATE_CHANNEL';
export const CREATE_CHANNEL_FULFILLED = prefix + 'CREATE_CHANNEL_FULFILLED';
export const CREATE_CHANNEL_PENDING = prefix + 'CREATE_CHANNEL_PENDING';
export const CREATE_CHANNEL_REJECTED = prefix + 'CREATE_CHANNEL_REJECTED';

export const REMOVE_CHANNEL = prefix + 'REMOVE_CHANNEL';
export const REMOVE_CHANNEL_FULFILLED = prefix + 'REMOVE_CHANNEL_FULFILLED';
export const REMOVE_CHANNEL_PENDING = prefix + 'REMOVE_CHANNEL_PENDING';
export const REMOVE_CHANNEL_REJECTED = prefix + 'REMOVE_CHANNEL_REJECTED';

export const SAVE_EDITABLE_CHANNEL = prefix + 'SAVE_EDITABLE_CHANNEL';
export const SAVE_EDITABLE_CHANNEL_FULFILLED = prefix + 'SAVE_EDITABLE_CHANNEL_FULFILLED';
export const SAVE_EDITABLE_CHANNEL_PENDING = prefix + 'SAVE_EDITABLE_CHANNEL_PENDING';
export const SAVE_EDITABLE_CHANNEL_REJECTED = prefix + 'SAVE_EDITABLE_CHANNEL_REJECTED';

export const DROP_CHANNELS = prefix + 'DROP_CHANNELS';
export const DROP_CHANNEL = prefix + 'DROP_CHANNEL';
export const UPDATE_CHANNEL = prefix + 'UPDATE_CHANNEL';

export const EDIT_CHANNEL = prefix + 'EDIT_CHANNEL';
export const EDIT_CHANNEL_FULFILLED = prefix + 'EDIT_CHANNEL_FULFILLED';
export const EDIT_CHANNEL_PENDING = prefix + 'EDIT_CHANNEL_PENDING';
export const EDIT_CHANNEL_REJECTED = prefix + 'EDIT_CHANNEL_REJECTED';

export const SET_CHANNEL_TO_EDIT = prefix + 'SET_CHANNEL_TO_EDIT';
export const SET_CHANNEL_TO_CREATION = prefix + 'SET_CHANNEL_TO_CREATION';

export const FETCH_THEMES = prefix + 'FETCH_THEMES';
export const FETCH_THEMES_PENDING = prefix + 'FETCH_THEMES_PENDING';
export const FETCH_THEMES_FULFILLED = prefix + 'FETCH_THEMES_FULFILLED';
export const FETCH_THEMES_REJECTED = prefix + 'FETCH_THEMES_REJECTED';

export const SAVE_WIDGET_LOGO_URL_TO_SERVER = prefix + 'SAVE_WIDGET_LOGO_URL_TO_SERVER';

export const ALEXA_SET_REFRESH_TOKEN = prefix + 'ALEXA_SET_REFRESH_TOKEN';
export const ALEXA_SET_REFRESH_TOKEN_PENDING = prefix + 'ALEXA_SET_REFRESH_TOKEN_PENDING';
export const ALEXA_SET_REFRESH_TOKEN_FULFILLED = prefix + 'ALEXA_SET_REFRESH_TOKEN_FULFILLED';
export const ALEXA_SET_REFRESH_TOKEN_REJECTED = prefix + 'ALEXA_SET_REFRESH_TOKEN_REJECTED';

export const GET_CONFIRM_URL = prefix + 'GET_CONFIRM_URL';
export const GET_CONFIRM_URL_PENDING = prefix + 'GET_CONFIRM_URL_PENDING';
export const GET_CONFIRM_URL_FULFILLED = prefix + 'GET_CONFIRM_URL_FULFILLED';
export const GET_CONFIRM_URL_REJECTED = prefix + 'GET_CONFIRM_URL_REJECTED';

export const PUBLISH_CHANNEL = prefix + 'PUBLISH_CHANNEL';
export const PUBLISH_CHANNEL_PENDING = prefix + 'PUBLISH_CHANNEL_PENDING';
export const PUBLISH_CHANNEL_FULFILLED = prefix + 'PUBLISH_CHANNEL_FULFILLED';
export const PUBLISH_CHANNEL_REJECTED = prefix + 'PUBLISH_CHANNEL_REJECTED';

export const ADD_DESTINATION = 'ADD_DESTINATION';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const DELETE_IDS_DESTINATION = 'DELETE_IDS_DESTINATION';
export const ADD_DESTINATION_IDS = 'ADD_DESTINATION_IDS';
export const SELECT_DESTINATION_IDS = 'SELECT_DESTINATION_IDS ';

export const FETCH_OPERATOR_INCOMING_WEBIM2 = prefix + 'FETCH_OPERATOR_INCOMING_WEBIM2';
export const FETCH_OPERATOR_INCOMING_WEBIM2_PENDING = prefix + 'FETCH_OPERATOR_INCOMING_WEBIM2_PENDING';
export const FETCH_OPERATOR_INCOMING_WEBIM2_FULFILLED = prefix + 'FETCH_OPERATOR_INCOMING_WEBIM2_FULFILLED';
export const FETCH_OPERATOR_INCOMING_WEBIM2_REJECTED = prefix + 'FETCH_OPERATOR_INCOMING_WEBIM2_REJECTED';
