import { t } from 'localization';

import { OperatorSkillConfig, SkillType } from '../types';

export const operatorSkillConfig: () => OperatorSkillConfig = () => ({
  type: SkillType.OPERATOR,
  skillName: 'operator',
  title: t('OperatorSkill:OperatorSkill:Title'),
  description: t('OperatorSkill:OperatorSkill:Description'),
  orderWeight: 998,
  skillVersion: 1,
  language: '',
  menuType: 'transition',
  startState: '',
  addToMenuOnce: true,
});
