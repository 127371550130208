const prefix = '@currentUser/';
export const LOGIN = prefix + 'LOGIN';
export const LOGIN_FULFILLED = prefix + 'LOGIN_FULFILLED';
export const LOGIN_PENDING = prefix + 'LOGIN_PENDING';
export const LOGIN_REJECTED = prefix + 'LOGIN_REJECTED';
export const CLEAN_LOGIN_ERRORS = prefix + 'CLEAN_LOGIN_ERRORS';

export const LOGOUT = prefix + 'LOGOUT';
export const LOGOUT_FULFILLED = prefix + 'LOGOUT_FULFILLED';
export const LOGOUT_PENDING = prefix + 'LOGOUT_PENDING';
export const LOGOUT_REJECTED = prefix + 'LOGOUT_REJECTED';

export const SET_CURRENT_USER = prefix + 'SET_CURRENT_USER';
export const DROP_CURRENT_USER = prefix + 'DROP_CURRENT_USER';
export const DROP_EDITABLE_USER = prefix + 'DROP_EDITABLE_USER';
export const SET_LANGUAGE = prefix + 'SET_LANGUAGE';

export const CHANGE_LANGUAGE = prefix + 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_FULFILLED = prefix + 'CHANGE_LANGUAGE_FULFILLED';
export const CHANGE_LANGUAGE_PENDING = prefix + 'CHANGE_LANGUAGE_PENDING';
export const CHANGE_LANGUAGE_REJECTED = prefix + 'CHANGE_LANGUAGE_REJECTED';

export const SAVE_EDITABLE_USER = prefix + 'SAVE_EDITABLE_USER';
export const SAVE_EDITABLE_USER_FULFILLED = prefix + 'SAVE_EDITABLE_USER_FULFILLED';
export const SAVE_EDITABLE_USER_PENDING = prefix + 'SAVE_EDITABLE_USER_PENDING';
export const SAVE_EDITABLE_USER_REJECTED = prefix + 'SAVE_EDITABLE_USER_REJECTED';

export const MULTIPLE = prefix + 'MULTIPLE';
export const MULTIPLE_FULFILLED = prefix + 'MULTIPLE_FULFILLED';
export const MULTIPLE_PENDING = prefix + 'MULTIPLE_PENDING';
export const MULTIPLE_REJECTED = prefix + 'MULTIPLE_REJECTED';

export const CHECK_TOKEN = prefix + 'CHECK_TOKEN';
export const CHECK_TOKEN_FULFILLED = prefix + 'CHECK_TOKEN_FULFILLED';
export const CHECK_TOKEN_PENDING = prefix + 'CHECK_TOKEN_PENDING';
export const CHECK_TOKEN_REJECTED = prefix + 'CHECK_TOKEN_REJECTED';

export const GET_USER_ACCOUNT = prefix + 'GET_USER_ACCOUNT';
export const GET_USER_ACCOUNT_FULFILLED = prefix + 'GET_USER_ACCOUNT_FULFILLED';
export const GET_USER_ACCOUNT_PENDING = prefix + 'GET_USER_ACCOUNT_PENDING';
export const GET_USER_ACCOUNT_REJECTED = prefix + 'GET_USER_ACCOUNT_REJECTED';

export const GET_USER_SUBSCRIPTION = prefix + 'GET_USER_SUBSCRIPTION';
export const GET_USER_SUBSCRIPTION_FULFILLED = prefix + 'GET_USER_SUBSCRIPTION_FULFILLED';
export const GET_USER_SUBSCRIPTION_PENDING = prefix + 'GET_USER_SUBSCRIPTION_PENDING';
export const GET_USER_SUBSCRIPTION_REJECTED = prefix + 'GET_USER_SUBSCRIPTION_REJECTED';

export const CHANGE_USER_SUBSCRIPTION_STATUS = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS';
export const CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED';
export const CHANGE_USER_SUBSCRIPTION_STATUS_PENDING = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS_PENDING';
export const CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED = prefix + 'CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED';

export const ADD_USER_SUBSCRIPTION = prefix + 'ADD_USER_SUBSCRIPTION';
export const ADD_USER_SUBSCRIPTION_FULFILLED = prefix + 'ADD_USER_SUBSCRIPTION_FULFILLED';
export const ADD_USER_SUBSCRIPTION_PENDING = prefix + 'ADD_USER_SUBSCRIPTION_PENDING';
export const ADD_USER_SUBSCRIPTION_REJECTED = prefix + 'ADD_USER_SUBSCRIPTION_REJECTED';

export const GET_USER_ACCOUNT_PAYMENT_DATA = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA';
export const GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED';
export const GET_USER_ACCOUNT_PAYMENT_DATA_PENDING = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA_PENDING';
export const GET_USER_ACCOUNT_PAYMENT_DATA_REJECTED = prefix + 'GET_USER_ACCOUNT_PAYMENT_DATA_REJECTED';

export const SEND_FEEDBACK = prefix + 'SEND_FEEDBACK';
export const BLOCK_CURRENT_USER_TARIFF = prefix + 'BLOCK_CURRENT_USER_TARIFF';

export const GET_AVAILABLE_MINUTES_PACKAGES = prefix + 'GET_AVAILABLE_MINUTES_PACKAGES';
export const GET_AVAILABLE_MINUTES_PACKAGES_FULFILLED = prefix + 'GET_AVAILABLE_MINUTES_PACKAGES_FULFILLED';
export const GET_AVAILABLE_MINUTES_PACKAGES_PENDING = prefix + 'GET_AVAILABLE_MINUTES_PACKAGES_PENDING';
export const GET_AVAILABLE_MINUTES_PACKAGES_REJECTED = prefix + 'GET_AVAILABLE_MINUTES_PACKAGES_REJECTED';

export const GET_AVAILABLE_UNIQUES_PACKAGES = prefix + 'GET_AVAILABLE_UNIQUES_PACKAGES';
export const GET_AVAILABLE_UNIQUES_PACKAGES_FULFILLED = prefix + 'GET_AVAILABLE_UNIQUES_PACKAGES_FULFILLED';
export const GET_AVAILABLE_UNIQUES_PACKAGES_PENDING = prefix + 'GET_AVAILABLE_UNIQUES_PACKAGES_PENDING';
export const GET_AVAILABLE_UNIQUES_PACKAGES_REJECTED = prefix + 'GET_AVAILABLE_UNIQUES_PACKAGES_REJECTED';

export const GET_VERIFICATION_CODE = prefix + 'GET_VERIFICATION_CODE';
export const GET_VERIFICATION_CODE_FULFILLED = prefix + 'GET_VERIFICATION_CODE_FULFILLED';
export const GET_VERIFICATION_CODE_PENDING = prefix + 'GET_VERIFICATION_CODE_PENDING';
export const GET_VERIFICATION_CODE_REJECTED = prefix + 'GET_VERIFICATION_CODE_REJECTED';

export const CHECK_VERIFICATION_CODE = prefix + 'CHECK_VERIFICATION_CODE';
export const CHECK_VERIFICATION_CODE_FULFILLED = prefix + 'CHECK_VERIFICATION_CODE_FULFILLED';
export const CHECK_VERIFICATION_CODE_PENDING = prefix + 'CHECK_VERIFICATION_CODE_PENDING';
export const CHECK_VERIFICATION_CODE_REJECTED = prefix + 'CHECK_VERIFICATION_CODE_REJECTED';

export const CONTRACT_REQUEST = prefix + 'CONTRACT_REQUEST';
export const CONTRACT_REQUEST_FULFILLED = prefix + 'CONTRACT_REQUEST_FULFILLED';
export const CONTRACT_REQUEST_PENDING = prefix + 'CONTRACT_REQUEST_PENDING';
export const CONTRACT_REQUEST_REJECTED = prefix + 'CONTRACT_REQUEST_REJECTED';

export const UPLOAD_FILE_TO_SERVER = prefix + 'UPLOAD_FILE_TO_SERVER';
export const UPLOAD_FILE_TO_SERVER_FULFILLED = prefix + 'UPLOAD_FILE_TO_SERVER_FULFILLED';
export const UPLOAD_FILE_TO_SERVER_PENDING = prefix + 'UPLOAD_FILE_TO_SERVER_PENDING';
export const UPLOAD_FILE_TO_SERVER_REJECTED = prefix + 'UPLOAD_FILE_TO_SERVER_REJECTED';

export const GET_CALLS_AVAILABILITY = prefix + 'GET_CALLS_AVAILABILITY';
export const GET_CALLS_AVAILABILITY_FULFILLED = prefix + 'GET_CALLS_AVAILABILITY_FULFILLED';
export const GET_CALLS_AVAILABILITY_PENDING = prefix + 'GET_CALLS_AVAILABILITY_PENDING';
export const GET_CALLS_AVAILABILITY_REJECTED = prefix + 'GET_CALLS_AVAILABILITY_REJECTED';

export const CHECK_USER_PHONE_COUNTRY_CODE = prefix + 'CHECK_USER_PHONE_COUNTRY_CODE';
export const CHECK_USER_PHONE_COUNTRY_CODE_FULFILLED = prefix + 'CHECK_USER_PHONE_COUNTRY_CODE_FULFILLED';
export const CHECK_USER_PHONE_COUNTRY_CODE_PENDING = prefix + 'CHECK_USER_PHONE_COUNTRY_CODE_PENDING';
export const CHECK_USER_PHONE_COUNTRY_CODE_REJECTED = prefix + 'CHECK_USER_PHONE_COUNTRY_CODE_REJECTED';

export const GET_AVAILABLE_SMS_PACKAGES = prefix + 'GET_AVAILABLE_SMS_PACKAGES';
export const GET_AVAILABLE_SMS_PACKAGES_FULFILLED = prefix + 'GET_AVAILABLE_SMS_PACKAGES_FULFILLED';
export const GET_AVAILABLE_SMS_PACKAGES_PENDING = prefix + 'GET_AVAILABLE_SMS_PACKAGES_PENDING';
export const GET_AVAILABLE_SMS_PACKAGES_REJECTED = prefix + 'GET_AVAILABLE_SMS_PACKAGES_REJECTED';

export const CHANGE_USER_EMAIL = prefix + 'CHANGE_USER_EMAIL';
export const CHANGE_USER_EMAIL_FULFILLED = prefix + 'CHANGE_USER_EMAIL_FULFILLED';
export const CHANGE_USER_EMAIL_PENDING = prefix + 'CHANGE_USER_EMAIL_PENDING';
export const CHANGE_USER_EMAIL_REJECTED = prefix + 'CHANGE_USER_EMAIL_REJECTED';

export const BUY_ADDITIONAL_PACKAGES = prefix + 'BUY_ADDITIONAL_PACKAGES';

export const CHANGE_EMAIL_BY_TOKEN = prefix + 'CHANGE_EMAIL_BY_TOKEN';
export const CHANGE_EMAIL_BY_TOKEN_FULFILLED = prefix + 'CHANGE_EMAIL_BY_TOKEN_FULFILLED';

export const SET_PACKAGES_COUNT = prefix + 'SET_PACKAGES_COUNT';

export const GET_MANUAL_CONTROL_INFO = prefix + 'GET_MANUAL_CONTROL_INFO';
export const GET_MANUAL_CONTROL_INFO_PENDING = prefix + 'GET_MANUAL_CONTROL_INFO_PENDING';
export const GET_MANUAL_CONTROL_INFO_FULFILLED = prefix + 'GET_MANUAL_CONTROL_INFO_FULFILLED';
export const GET_MANUAL_CONTROL_INFO_REJECTED = prefix + 'GET_MANUAL_CONTROL_INFO_REJECTED';

export const SET_CURRENT_USER_PHONE = prefix + 'SET_CURRENT_USER_PHONE';
export const SET_CURRENT_USER_ON_VERIFICATION = prefix + 'SET_CURRENT_USER_ON_VERIFICATION';

export const GET_GPT_STATUS = prefix + 'GET_GPT_STATUS';
export const GET_GPT_STATUS_FULFILLED = prefix + 'GET_GPT_STATUS_FULFILLED';
