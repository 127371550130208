import { bindActionCreators } from 'redux';
import { useMemo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BotProjectReadWithConfigDto } from '@just-ai/api/dist/generated/Botadmin';

import { RootState } from 'storeTypes';
import { useAppDispatch, useAppSelector } from 'storeHooks';

import { setCurrentProject } from 'actions/currentProject.actions';
import { loadProjects, saveEditableProject } from 'actions/botprojects.actions';
import { useAppContext } from 'contexts/AppContext';

export default function useSyncCurrentProjectInState(currentProjectShortName: string) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { botProjectService } = useAppContext();
  const boundActionCreators = useMemo(
    () => bindActionCreators({ setCurrentProject, saveEditableProject, loadProjects }, dispatch),
    [dispatch]
  );
  const { projectShortName, botList } = useAppSelector((state: RootState) => ({
    projectShortName: state.CurrentProjectsReducer.currentProject as string,
    botList: state.BotProjectsReducer.botList as BotProjectReadWithConfigDto[],
  }));

  const check = useCallback(async () => {
    if (currentProjectShortName === projectShortName) return;
    const bot = botList.find(project => project.shortName === currentProjectShortName);
    if (!bot) {
      history.push('/404');
      return;
    }
    boundActionCreators.setCurrentProject(currentProjectShortName, bot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    check();
  }, [check]);

  const updateCurrentProject = useCallback(
    (project: Partial<BotProjectReadWithConfigDto>) => {
      const bot = botList.find(project => project.shortName === currentProjectShortName);
      if (!bot) return;
      const updatedProject = { ...bot, ...project };
      boundActionCreators.setCurrentProject(currentProjectShortName, updatedProject);
      boundActionCreators.saveEditableProject(() => Promise.resolve({ data: updatedProject }));
    },
    [botList, boundActionCreators, currentProjectShortName]
  );

  return { updateCurrentProject };
}
