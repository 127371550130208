import loadScript from 'simple-load-script';
import Cookies from 'universal-cookie';

import { isEuroInstance } from 'isAccessFunction';
import { timeoutPromise } from 'pipes/functions';
import { PubSub } from 'services/PubSub';

const cookies = new Cookies();

enum JustWidgetEvent {
  MOUNTED = 'justwidget_mounted',
  TOGGLE = 'justwidget_toggle',
}

type SubscriptionMap = {
  opened: boolean;
  mounted: boolean;
};

export class TestWidgetInstance extends PubSub<SubscriptionMap> {
  private language: string = 'ru';

  public async openTestWidgetByUrl(testWidgetUrl: string, language: string) {
    this.language = language;
    cookies.set('waTestMode', 'true', { path: '/' });
    await timeoutPromise(
      3000,
      new Error('Chatwidget load timeout error!'),
      loadScript({
        url: testWidgetUrl,
        inBody: true,
        attrs: {
          id: 'justwidget',
          charset: 'UTF-8',
          async: true,
        },
      })
    );
    this.notify('opened', true);

    document.addEventListener(JustWidgetEvent.MOUNTED, this.handleWidgetMounted);
    document.addEventListener(JustWidgetEvent.TOGGLE, this.handleWidgetToggle);
  }

  public closeTestWidget() {
    cookies.remove('waTestMode', { path: '/' });
    document.removeEventListener(JustWidgetEvent.MOUNTED, this.handleWidgetMounted);
    document.removeEventListener(JustWidgetEvent.TOGGLE, this.handleWidgetToggle);
    return this.removeWidgetCode();
  }

  private handleWidgetToggle = (event: Event & { detail?: boolean }) => {
    if (!event.detail) {
      this.closeTestWidget();
      return;
    }
  };

  private handleWidgetMounted = () => {
    const widgetRoot = document.getElementById('widget-root');
    if (!widgetRoot) {
      console.error(`TestWidget opening error. Cannot found element by selector #widget-root.`);
      this.removeWidgetCode();
      return;
    }
    if (!window.JustWidget?.toggleWidget) {
      // noinspection JSIgnoredPromiseFromCall
      this.removeWidgetCode();
      return;
    }

    if (window.JustWidgetAttributes && this.language && window.JustWidget.setNewAttributes) {
      window.JustWidgetAttributes.options.forcedLanguage = this.language;
      if (isEuroInstance()) {
        window.JustWidgetAttributes.options.poweredLabel = 'Tovie DialogStudio';
        window.JustWidgetAttributes.options.poweredLink = 'https://dialogstudio.tovie.ai/';
      }
      window.JustWidget.setNewAttributes();
    }

    window.JustWidget.toggleWidget();

    const enLangClass = 'justwidget--en';
    if (this.language === 'en') {
      widgetRoot.classList.add(enLangClass);
    } else {
      widgetRoot.classList.remove(enLangClass);
    }

    this.notify('mounted', true);
  };

  private removeWidgetCode = async () => {
    this.notify('mounted', false);
    if (window.JustWidget) await window.JustWidget.unmount();
    window.webpackJsonpJustWidget = [];
    let instances = document.querySelectorAll('[data-origin="justwidget"]');
    if (instances.length > 0) {
      for (let i = 0; i < instances.length; i += 1) {
        instances[i].remove();
      }
    }
    document.getElementById('justwidget')?.remove();
    document.querySelectorAll('.justwidget:not([data-test-id="PseudoJustwidget"])').forEach(el => el.remove());
    window.JustWidget = undefined;
    this.notify('opened', false);
  };
}
