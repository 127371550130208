import React from 'react';
import localize from 'localization';
import { connect } from 'react-redux';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import { AimychatOperatorGroupService } from 'modules/AimychatApi/service/AimychatOperatorGroupService';
import { AimychatOperatorPlaceService } from 'modules/AimychatApi/service/AimychatOperatorPlaceService';
import { InputText, JustSelect, Banner } from '@just-ai/just-ui';
import { isEuroInstance } from '../../../../../isAccessFunction';

const { translate } = localize;

class AimychatEditForm extends BaseEditForm {
  defaultFields = {
    name: false,
    botId: true,
    type: true,
  };

  constructor(props) {
    super(props);
    this.form = {};

    this.state = {
      rollout: true,
      errors: [],
      groups: [],
      groupId: '',
      apiKey: '',
    };
  }

  componentDidMount() {
    const accountId = this.props.currentUser.account.id;
    this.aimychatOperatorGroupService = new AimychatOperatorGroupService();
    this.aimychatOperatorPlaceService = new AimychatOperatorPlaceService(accountId);
    const config = this.props.editableChannel?.config;

    if (!config) {
      this.getAimychatToken();
      return;
    }

    if (!!config.apiKey && config.apiKey !== '') {
      const { apiKey, groupId } = config;
      this.setState({ apiKey, groupId });

      if (this.form.apiKey?.value && !apiKey) {
        this.setState({ apiKey: this.form.apiKey.value });
      }

      if (apiKey) {
        this.getGroups(apiKey);
      }
    }
  }

  getMessageAboutConfiguration = () => {
    const showHelp = this.props.appConfig?.zenflow?.showHelp;

    return (
      showHelp && (
        <Banner
          variant='info'
          withIcon
          BannerText={() => (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={translate(isEuroInstance() ? 'AIMYCHAT:Tovie doc link' : 'AIMYCHAT doc link')}
            >
              {translate(
                'ChannelsAdding: how to add',
                translate(isEuroInstance() ? 'AddChannelCard AIMYCHAT:Tovie' : 'AddChannelCard AIMYCHAT')
              )}
            </a>
          )}
        />
      )
    );
  };

  getGroups = async apiKey => {
    try {
      const { groups } = await this.aimychatOperatorGroupService.getOperatorGroupsByToken(apiKey);
      if (groups) {
        this.setState({ groups, errors: [] });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        groups: [],
        groupId: false,
        errors: [translate('ChangeEmail:BE-error user.changeEmailToken.wrong')],
      });
    }
  };

  getAimychatToken = async () => {
    try {
      const { token } = await this.aimychatOperatorPlaceService.getOperatorPlace();
      if (token) {
        this.setState({ apiKey: token });
        this.form.apiKey.value = token;
        this.form.apiKey.focus();
        this.getGroups(token);
      }
    } catch (_) {
      this.setState({ errors: [translate('ChangeEmail:BE-error user.changeEmailToken.wrong')] });
    }
  };

  setErrorIfFieldsEmpty = async (channelData, isEdit = true) => {
    const { createChannel, editChannel, onClose } = this.props;
    const { apiKey, groupId } = channelData.config;

    if (!apiKey || !groupId) {
      return this.setState({ errors: [translate('Check fields value')] });
    }

    if (isEdit) {
      await editChannel(channelData);
    } else {
      await createChannel(channelData);
    }

    onClose();
  };
  createChannel = async () => {
    const { editableChannel } = this.props;
    const channelData = {
      type: editableChannel.channelType,
      description: this.form.description.value,
      config: {
        apiKey: this.form.apiKey.value,
        groupId: this.state.groupId,
      },
    };

    this.setErrorIfFieldsEmpty(channelData, false);
  };

  saveChannel = async () => {
    const { editableChannel } = this.props;

    const channelData = {
      id: editableChannel.id,
      type: editableChannel.type,
      description: this.form.description.value,
      config: {
        apiKey: this.form.apiKey.value,
        groupId: this.state.groupId,
      },
    };

    this.setErrorIfFieldsEmpty(channelData);
  };

  getLabelName = () => translate('Bot token');

  renderInputs() {
    const { editableChannel } = this.props;
    const description = isEuroInstance()
      ? translate('AddChannelCard AIMYCHAT:Tovie')
      : translate('AddChannelCard AIMYCHAT');

    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{translate('Name')}</p>
          <InputText
            id='description'
            defaultValue={editableChannel.id ? editableChannel.description : description}
            innerRef={input => (this.form.description = input)}
            placeholder={translate('Enter custom', translate('Name').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{translate('ChannelsAdding: accessToken')}</p>
          <InputText
            id='token'
            defaultValue={editableChannel.id ? editableChannel.config.apiKey : this.form.apiKey?.value}
            innerRef={input => (this.form.apiKey = input)}
            placeholder={translate('Enter custom', translate('ChannelsAdding: accessToken').toLowerCase())}
            onBlur={() => this.getGroups(this.form.apiKey.value)}
          />
        </div>
        <div className={classes.formControl}>
          <JustSelect
            fullWidth
            disabled={!this.state.groups.length}
            inputPlaceholder={translate('ChannelsAdding: Aimychat group placeholder')}
            data-test-id='Aimychat.name'
            value={this.state.groupId?.toString()}
            onChange={value => {
              this.setState({ groupId: value[0] });
            }}
            label={translate('ChannelsAdding: Aimychat group')}
            optionComponent={({ value, label }) => (
              <div value={value.toString()} key={`selector_key_${value}`} className={classes.flex}>
                <div className={classes.flexGrow}>{label}</div>
              </div>
            )}
            required
            options={this.state.groups.map(group => ({ label: group.name, value: group.id.toString() }))}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
  };
}

export default connect(mapStateToProps)(AimychatEditForm);
