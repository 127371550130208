import * as actions from '../../constants/botprojects.actions';

import { axios } from '../../pipes/functions';

export const loadProjects = action => {
  return {
    type: actions.FETCH_PROJECTS,
    payload: action(),
  };
};
export const loadUniqClients = accountId => {
  return {
    type: actions.FETCH_PROJECTS_UNIQ_CLIENTS,
    payload: axios.get(`/api/reporter/accounts/${accountId}/stats/unique-clients`),
  };
};

export const createProject = action => {
  return {
    type: actions.CREATE_PROJECT,
    payload: action(),
  };
};

export const updateChannels = data => {
  return {
    type: actions.UPDATE_CHANNELS,
    data,
  };
};

export const cloneProject = action => {
  return {
    type: actions.CLONE_PROJECT,
    payload: action(),
  };
};

export const saveEditableProject = action => {
  return {
    type: actions.SAVE_EDITABLE_PROJECT,
    payload: action(),
  };
};

export const setBotToDelete = id => {
  return {
    type: actions.SET_BOT_TO_DELETE,
    id,
  };
};
export const finalDelete = id => {
  return {
    type: actions.FINAL_DELETE,
    id,
  };
};

export const removeEditableProject = action => {
  return {
    type: actions.REMOVE_EDITABLE_PROJECT,
    payload: action(),
  };
};

export const updateProjectTags = (projectShortName, tagsList) => {
  return {
    type: actions.UPDATE_PROJECT_TAGS,
    payload: {
      projectShortName,
      tagsList,
    },
  };
};
export const deleteAccountTag = tagId => {
  return {
    type: actions.DELETE_ACCOUNT_TAG,
    payload: {
      tagId,
    },
  };
};
