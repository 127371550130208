const prefix = '@VoiceChannels/';

export const GET_CURRENT = prefix + 'FETCH_TEMPLATES';
export const GET_CURRENT_PENDING = prefix + 'FETCH_TEMPLATES_PENDING';
export const GET_CURRENT_FULFILLED = prefix + 'FETCH_TEMPLATES_FULFILLED';
export const GET_CURRENT_REJECTED = prefix + 'FETCH_TEMPLATES_REJECTED';

export const SAVE_FILE_TO_SERVER = prefix + 'SAVE_FILE_TO_SERVER';
export const SAVE_FILE_TO_SERVER_PENDING = prefix + 'SAVE_FILE_TO_SERVER_PENDING';
export const SAVE_FILE_TO_SERVER_FULFILLED = prefix + 'SAVE_FILE_TO_SERVER_FULFILLED';
export const SAVE_FILE_TO_SERVER_REJECTED = prefix + 'SAVE_FILE_TO_SERVER_REJECTED';

export const REMOVE_FILE_FROM_SERVER = prefix + 'REMOVE_FILE_FROM_SERVER';
export const REMOVE_FILE_FROM_SERVER_PENDING = prefix + 'REMOVE_FILE_FROM_SERVER_PENDING';
export const REMOVE_FILE_FROM_SERVER_FULFILLED = prefix + 'REMOVE_FILE_FROM_SERVER_FULFILLED';
export const REMOVE_FILE_FROM_SERVER_REJECTED = prefix + 'REMOVE_FILE_FROM_SERVER_REJECTED';

export const GET_VOICE_CHANNELS_LIST = prefix + 'GET_VOICE_CHANNELS_LIST';
export const GET_VOICE_CHANNELS_LIST_PENDING = prefix + 'GET_VOICE_CHANNELS_LIST_PENDING';
export const GET_VOICE_CHANNELS_LIST_FULFILLED = prefix + 'GET_VOICE_CHANNELS_LIST_FULFILLED';
export const GET_VOICE_CHANNELS_LIST_REJECTED = prefix + 'GET_VOICE_CHANNELS_LIST_REJECTED';

export const CREATE_VOICE_CHANNEL = prefix + 'CREATE_VOICE_CHANNEL';
export const CREATE_VOICE_CHANNEL_PENDING = prefix + 'CREATE_VOICE_CHANNEL_PENDING';
export const CREATE_VOICE_CHANNEL_FULFILLED = prefix + 'CREATE_VOICE_CHANNEL_FULFILLED';
export const CREATE_VOICE_CHANNEL_REJECTED = prefix + 'CREATE_VOICE_CHANNEL_REJECTED';

export const UPDATE_VOICE_CHANNEL = prefix + 'UPDATE_VOICE_CHANNEL';
export const UPDATE_VOICE_CHANNEL_PENDING = prefix + 'UPDATE_VOICE_CHANNEL_PENDING';
export const UPDATE_VOICE_CHANNEL_FULFILLED = prefix + 'UPDATE_VOICE_CHANNEL_FULFILLED';
export const UPDATE_VOICE_CHANNEL_REJECTED = prefix + 'UPDATE_VOICE_CHANNEL_REJECTED';

export const GET_UPLOADED_FILE = prefix + 'GET_UPLOADED_FILE';
export const GET_UPLOADED_FILE_PENDING = prefix + 'GET_UPLOADED_FILE_PENDING';
export const GET_UPLOADED_FILE_FULFILLED = prefix + 'GET_UPLOADED_FILE_FULFILLED';
export const GET_UPLOADED_FILE_REJECTED = prefix + 'GET_UPLOADED_FILE_REJECTED';

export const SET_MAILING_STATUS = prefix + 'SET_MAILING_STATUS';
export const SET_MAILING_STATUS_PENDING = prefix + 'SET_MAILING_STATUS_PENDING';
export const SET_MAILING_STATUS_FULFILLED = prefix + 'SET_MAILING_STATUS_FULFILLED';
export const SET_MAILING_STATUS_REJECTED = prefix + 'SET_MAILING_STATUS_REJECTED';

export const REMOVE_VOICE_CHANNEL = prefix + 'REMOVE_VOICE_CHANNEL';
export const REMOVE_VOICE_CHANNEL_PENDING = prefix + 'REMOVE_VOICE_CHANNEL_PENDING';
export const REMOVE_VOICE_CHANNEL_FULFILLED = prefix + 'REMOVE_VOICE_CHANNEL_FULFILLED';
export const REMOVE_VOICE_CHANNEL_REJECTED = prefix + 'REMOVE_VOICE_CHANNEL_REJECTED';

export const VALIDATE_FILE = prefix + 'VALIDATE_FILE';
export const VALIDATE_FILE_PENDING = prefix + 'VALIDATE_FILE_PENDING';
export const VALIDATE_FILE_FULFILLED = prefix + 'VALIDATE_FILE_FULFILLED';
export const VALIDATE_FILE_REJECTED = prefix + 'VALIDATE_FILE_REJECTED';

export const UNSET_CURRENT = prefix + 'UNSET_CURRENT';
export const UNSET_CURRENT_PENDING = prefix + 'UNSET_CURRENT_PENDING';
export const UNSET_CURRENT_FULFILLED = prefix + 'UNSET_CURRENT_FULFILLED';
export const UNSET_CURRENT_REJECTED = prefix + 'UNSET_CURRENT_REJECTED';

export const AUTO_CREATE_RESTERISK = prefix + 'AUTO_CREATE_RESTERISK';
export const AUTO_CREATE_RESTERISK_PENDING = prefix + 'AUTO_CREATE_RESTERISK_PENDING';
export const AUTO_CREATE_RESTERISK_FULFILLED = prefix + 'AUTO_CREATE_RESTERISK_FULFILLED';
export const AUTO_CREATE_RESTERISK_REJECTED = prefix + 'AUTO_CREATE_RESTERISK_REJECTED';

export const AUTO_CREATE_RESTERISK_WITH_TRUNK = prefix + 'AUTO_CREATE_RESTERISK_WITH_TRUNK';
export const AUTO_CREATE_RESTERISK_WITH_TRUNK_PENDING = prefix + 'AUTO_CREATE_RESTERISK_WITH_TRUNK_PENDING';
export const AUTO_CREATE_RESTERISK_WITH_TRUNK_FULFILLED = prefix + 'AUTO_CREATE_RESTERISK_WITH_TRUNK_FULFILLED';
export const AUTO_CREATE_RESTERISK_WITH_TRUNK_REJECTED = prefix + 'AUTO_CREATE_RESTERISK_WITH_TRUNK_REJECTED';

export const VOICE_CHANNEL_REPORT = prefix + 'VOICE_CHANNEL_REPORT';
export const VOICE_CHANNEL_REPORT_PENDING = prefix + 'VOICE_CHANNEL_REPORT_PENDING';
export const VOICE_CHANNEL_REPORT_FULFILLED = prefix + 'VOICE_CHANNEL_REPORT_FULFILLED';
export const VOICE_CHANNEL_REPORT_REJECTED = prefix + 'VOICE_CHANNEL_REPORT_REJECTED';

export const DOWNLOAD_CALL_LIST = prefix + 'DOWNLOAD_CALL_LIST';
export const DOWNLOAD_CALL_LIST_PENDING = prefix + 'DOWNLOAD_CALL_LIST_PENDING';
export const DOWNLOAD_CALL_LIST_FULFILLED = prefix + 'DOWNLOAD_CALL_LIST_FULFILLED';
export const DOWNLOAD_CALL_LIST_REJECTED = prefix + 'DOWNLOAD_CALL_LIST_REJECTED';

export const TEST_CALL = prefix + 'TEST_CALL';
export const TEST_CALL_PENDING = prefix + 'TEST_CALL_PENDING';
export const TEST_CALL_FULFILLED = prefix + 'TEST_CALL_FULFILLED';
export const TEST_CALL_REJECTED = prefix + 'TEST_CALL_REJECTED';

export const GET_TEST_CALL = prefix + 'GET_TEST_CALL';
export const GET_TEST_CALL_PENDING = prefix + 'GET_TEST_CALL_PENDING';
export const GET_TEST_CALL_FULFILLED = prefix + 'GET_TEST_CALL_FULFILLED';
export const GET_TEST_CALL_REJECTED = prefix + 'GET_TEST_CALL_REJECTED';

export const SET_RESTERISK_INCOMING = prefix + 'SET_RESTERISK_INCOMING';
export const SET_RESTERISK_INCOMING_PENDING = prefix + 'SET_RESTERISK_INCOMING_PENDING';
export const SET_RESTERISK_INCOMING_FULFILLED = prefix + 'SET_RESTERISK_INCOMING_FULFILLED';
export const SET_RESTERISK_INCOMING_REJECTED = prefix + 'SET_RESTERISK_INCOMING_REJECTED';

export const TEST_CALL_CHANNEL_PUBLISH = prefix + 'TEST_CALL_CHANNEL_PUBLISH';
export const TEST_CALL_CHANNEL_PUBLISH_PENDING = prefix + 'TEST_CALL_CHANNEL_PUBLISH_PENDING';
export const TEST_CALL_CHANNEL_PUBLISH_FULFILLED = prefix + 'TEST_CALL_CHANNEL_PUBLISH_FULFILLED';
export const TEST_CALL_CHANNEL_PUBLISH_REJECTED = prefix + 'TEST_CALL_CHANNEL_PUBLISH_REJECTED';

export const SCENARIO_TEST_CALL = prefix + 'SCENARIO_TEST_CALL';
export const SCENARIO_TEST_CALL_PENDING = prefix + 'SCENARIO_TEST_CALL_PENDING';
export const SCENARIO_TEST_CALL_FULFILLED = prefix + 'SCENARIO_TEST_CALL_FULFILLED';
export const SCENARIO_TEST_CALL_REJECTED = prefix + 'SCENARIO_TEST_CALL_REJECTED';

export const PUBLISH_RESTERISK_CHANNELS = prefix + 'PUBLISH_RESTERISK_CHANNELS';
export const PUBLISH_RESTERISK_CHANNELS_PENDING = prefix + 'PUBLISH_RESTERISK_CHANNELS_PENDING';
export const PUBLISH_RESTERISK_CHANNELS_FULFILLED = prefix + 'PUBLISH_RESTERISK_CHANNELS_FULFILLED';
export const PUBLISH_RESTERISK_CHANNELS_REJECTED = prefix + 'PUBLISH_RESTERISK_CHANNELS_REJECTED';

export const GET_VOICES = prefix + 'GET_VOICES';
export const GET_VOICES_FULFILLED = prefix + 'GET_VOICES_FULFILLED';
export const GET_VOICES_PENDING = prefix + 'GET_VOICES_PENDING';
export const GET_VOICES_REJECTED = prefix + 'GET_VOICES_REJECTED';
