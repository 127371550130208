import React from 'react';
import { Grid, AtreidesTypo, Button, withStyles } from 'altreidsds';
import Robot503 from './Robot503';
import localize from '../../localization';
import history from '../../appHistory';

const page404 = props => {
  const { classes } = props;
  return (
    <Grid container alignItems='center' justify='center' className={classes.container503}>
      <Grid item lg={3} md={5} xs={12} align='center'>
        <Robot503 />
        <AtreidesTypo type='h1' variant='bold'>
          {localize.translate('Technical difficulties')}
        </AtreidesTypo>
        <AtreidesTypo type='p' variant='regular'>
          {localize.translate('We work')}
        </AtreidesTypo>
        <Button variant='contained' color='primary' onClick={() => history.push('/')}>
          {localize.translate('To bot page')}
        </Button>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  container503: {
    height: 'calc(100vh - 170px)',

    '& svg': {
      width: '287px',
      maxWidth: '100%',
      height: 'auto',
    },

    '& .h1': {
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 1.5,
    },

    '& .p': {
      marginTop: '0px',
      marginBottom: theme.spacing.unit * 4,
    },

    '& #cogwheel_right': {
      animationName: 'rotate',
      animationDuration: '1.2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      transformOrigin: '65.1% 49.55%',
    },

    '& #cogwheel_left': {
      animationName: 'rotate',
      animationDuration: '1.2s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
      transformOrigin: '39.2% 49.55%',
    },
  },
});

export default withStyles(styles, { withTheme: true })(page404);
