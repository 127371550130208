import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Locale, Popover, ProgressBar } from '@just-ai/just-ui';
import { formatDateString, formatNumberToString } from '@just-ai/formatutils';
import classNames from 'classnames';
import DeletingIcon from '../../Templates/DeletingIcon';
import { BotType, ChannelType } from '../dashboardTypes';
import localize from '../../../localization';

const { translate: t } = localize;

export interface BotInterface {
  bot: BotType;
  selectBot: (shortName: string) => void;
  deleteBot: (shortName: string) => void;
  cloneBot: (shortName: string) => void;
  finalDelete: (id: number) => void;
  pending?: boolean;
  deleting?: boolean;
  locale: Locale;
  displayType: 'card' | 'list';
}

export const LANG_MAP = {
  ru: 'ru',
  cn: 'cn',
  en: 'gb',
  pt: 'pt',
  es: 'es',
  kz: 'kz',
};

export function getChannelIconClassName(channel: ChannelType) {
  let statusClass = 'channelInProgress';
  if (channel.lastDeployResult?.status === 'OK') {
    statusClass = 'channelActive';
  }
  if (
    channel.lastDeployResult?.status &&
    ['DEPLOY_FAILED', 'UNAVAILABLE', 'OUTDATED'].includes(channel.lastDeployResult?.status)
  ) {
    statusClass = 'channelError';
  }

  return classNames('channelIcon', statusClass);
}

export function ElementsWithHiddenPopup<OptionType>({
  hidden = [],
  showed = [],
  className,
  id,
  renderOptionComponent,
  additionalElements,
  displayType,
}: {
  hidden: OptionType[];
  showed: OptionType[];
  id: string;
  renderOptionComponent: (option: OptionType) => void;
  className: string;
  additionalElements?: React.ReactNode;
  displayType: BotInterface['displayType'];
}) {
  const [showHidden, setShow] = useState(false);

  useEffect(() => {
    if (showHidden === true) {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayType]);
  const onShow = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setShow(true);
  }, []);

  const onHide = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
  }, []);

  return (
    <div className={className}>
      {additionalElements}
      {showed.map(renderOptionComponent)}
      {hidden.length > 0 && (
        <button id={id} className='additionalChannelButton' onClick={onShow}>
          {hidden.length}
          <Popover
            //@ts-ignore
            onCancelClick={onHide}
            isOpen={showHidden}
            target={id}
          >
            <div className={className}>{hidden?.map(renderOptionComponent)}</div>
          </Popover>
        </button>
      )}
    </div>
  );
}

export interface DeletingCardInterface {
  pending: boolean;
  finalDelete: (id: number) => void;
  setDeleting: (state: boolean) => void;
  deleting: boolean;
  id: number;
}

export function CartAdditionalInfo({
  bot,
  displayType,
  locale,
  projectShortName,
}: {
  displayType: BotInterface['displayType'];
  bot: BotInterface['bot'];
  locale: BotInterface['locale'];
  projectShortName: string;
}) {
  const withUniq = typeof bot.uniqueClients === 'number';
  const mainContent = (
    <>
      <div className='popularity'>
        {displayType === 'list' && (
          <div className={classNames('projectShortName', withUniq && 'withBorderLine')}>{projectShortName}</div>
        )}
        {withUniq && (
          <>
            <Icon name='farUsers' size='sm' />
            <div
              className='popularity_body'
              dangerouslySetInnerHTML={{
                __html: t('UniqueClients in 30 days', formatNumberToString({ value: bot.uniqueClients || 0 })),
              }}
            />
          </>
        )}
      </div>
      <div className='date'>
        {bot.lastModificationData && (
          <>
            <Icon name='farCalendarEdit' size='sm' />
            {bot.lastModificationData ? formatDateString(bot.lastModificationData, locale) : t('Bot never published')}
          </>
        )}
      </div>
    </>
  );
  switch (displayType) {
    case 'card':
      return mainContent;
    case 'list':
      return <div className='popularity-date'>{mainContent}</div>;
    default:
      return null;
  }
}

export function DeletingCard({ id, finalDelete, setDeleting, deleting, pending }: DeletingCardInterface) {
  return (
    <div
      className={classNames('bot', 'trashContainer', { deleting: deleting })}
      onAnimationEnd={() => {
        finalDelete(id);
      }}
    >
      <DeletingIcon
        pending={pending}
        onAnimationEnd={() => {
          setDeleting(true);
        }}
      />
      <h3 onAnimationEnd={e => e.stopPropagation()}>{t('SecondSimpleCard Deleting')}</h3>
    </div>
  );
}

export function DeletingList({ id, finalDelete, setDeleting, deleting, pending }: DeletingCardInterface) {
  return (
    <div className={classNames('bot', 'listContainer', 'deleting')} onAnimationEnd={() => setDeleting(true)}>
      <div className={classNames({ pending: !pending })} onAnimationEnd={() => finalDelete(id)}>
        {deleting && <ProgressBar style={{ backgroundColor: 'white' }} />}
      </div>
    </div>
  );
}
