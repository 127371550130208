import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import BotProjectsReducer from './botprojects.reducer/';
import CurrentProjectsReducer from './currentProject.reducer/';
import TemplatesReducer from './templates.reducer/';
import CurrentUserReducer from './currentUser.reducer/';
import AccountsReducer from './accounts.reducer/';
import visualEditorReducer from './visualEditor.reducer';
import RegisterReducer from './register.reducer';
import PasswordRestoreReducer from './passwordRestore.reducer';
import NewPasswordReducer from './newPassword.reducer';
import ChannelsReducer from './channels.reducer';
import SnackbarsReducer from './snackbar.reducer';
import ChannelDetailReducer from './channelDetail.reducer';
import ClientsReducer from './clients.reducer';
import ScenarioReducer from './scenario.reducer';
import CatchAllSettingsReducer from './catchAllSettings.reducer';
import socketReducer from '../middleware/sockets/socketReducer';
import EntitiesReducer from './entities.reducer';
import StatisticReducer from './statistic.reducer';
import DialogsReducer from './dialogs.reducer';
import KnowledgeReducer from './knowledge.reducer';
import OperatorChannelsReducer from './operatorChannels.reducer';
import VoiceChannelReducer from './voiceChannel.reducer';
import ReferralsReducer from './referrals.reducer';
import PromocodesReducer from './promocodes.reducer';
import SubscriptionReducer from './subscribtion.reducer';
import AppConfigReducer from './appConfig.reducer';
import sipTrunkListReducer from './sipTrunkList.reducer';
import UIReducer from './UI.reducer';
import ivrReducer from './ivr.reducer';
import templateWizardReducer from './templateWizard.reducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    BotProjectsReducer,
    CurrentProjectsReducer,
    TemplatesReducer,
    CurrentUserReducer,
    AccountsReducer,
    visualEditorReducer,
    RegisterReducer,
    PasswordRestoreReducer,
    NewPasswordReducer,
    ChannelsReducer,
    OperatorChannelsReducer,
    SnackbarsReducer,
    ChannelDetailReducer,
    ClientsReducer,
    ScenarioReducer,
    CatchAllSettingsReducer,
    EntitiesReducer,
    StatisticReducer,
    KnowledgeReducer,
    DialogsReducer,
    VoiceChannelReducer,
    ReferralsReducer,
    socketReducer,
    PromocodesReducer,
    SubscriptionReducer,
    AppConfigReducer,
    sipTrunkListReducer,
    UIReducer,
    ivrReducer,
    templateWizardReducer,
  });

export default rootReducer;
