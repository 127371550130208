import localize from 'localization';
import { connect } from 'react-redux';
import { FacebookEditForm, mapStateToProps } from '../FacebookEditForm';

const { translate: t } = localize;

class InstagramEditForm extends FacebookEditForm {
  channelType = 'INSTAGRAM';
  scope = 'pages_show_list,instagram_basic,pages_manage_metadata,pages_messaging,instagram_manage_messages';
  getBannerDescription = () => t('Instagram:Banner:Message', `${this.state.helpUrl}/instagram`);

  getLanguage = () => {
    const availableLanguages = {
      en: 'en',
      pt: 'pt',
    } as const;
    const locale = this.context?.language as keyof typeof availableLanguages;
    return availableLanguages[locale] || 'en';
  };
}

export default connect(mapStateToProps)(InstagramEditForm as never);
