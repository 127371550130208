import { useCallback } from 'react';
import { Checkbox, InputText as JustUiInputText, useId } from '@just-ai/just-ui';

import { ManagerNotifyResult, OnChangeType } from '../..';

import styles from '../../styles.module.scss';

export function EmailBlock({
  value,
  onChange,
  isShowErrors,
  errors,
}: {
  value: ManagerNotifyResult['email'];
  onChange: OnChangeType<ManagerNotifyResult['email']>;
  isShowErrors?: boolean;
  errors: { address: string };
}) {
  const checkBoxId = useId();
  const textId = useId();
  const onChangeEnabled = useCallback((val: boolean) => onChange('enabled', val), [onChange]);
  const onChangeEmail = useCallback((val: string) => onChange('address', val), [onChange]);
  return (
    <>
      <span className={styles.checkBoxWrapper}>
        <Checkbox inline label='Email' name={checkBoxId} checked={value.enabled} onChange={onChangeEnabled} />
      </span>
      <div className='flex-column'>
        <JustUiInputText
          className={styles.ManagerNotify__email}
          type='email'
          name={textId}
          errorText={isShowErrors ? errors.address : ''}
          value={value.address}
          placeholder='example@example.com'
          onChange={onChangeEmail}
        />
      </div>
    </>
  );
}
