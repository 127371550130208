import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { JustSelect, Modal, usePromiseProcessing } from '@just-ai/just-ui';
import { IntegrationDto } from '@just-ai/api/dist/generated/Integrations';

import { useAppContext } from 'contexts/AppContext';
import { t } from 'localization';

import { ManagerNotifyResult } from '../../../index';
import styles from '../styles.module.scss';

enum GoogleSettingsModalAction {
  CREATE_SPREADSHEET = 'createSpreadsheet',
}

const createNewSpreadsheet = () => ({
  label: t('Wizard:ManagerNotify:Google:Settings:CreateNewTable'),
  value: GoogleSettingsModalAction.CREATE_SPREADSHEET as unknown as string,
});

type GoogleSettingsModalProps = {
  onSubmit: (integration: ManagerNotifyResult['googleSheets']) => void;
  onCancel: () => void;
  integration?: IntegrationDto;
  spreadsheetId: string;
  sheetId: string;
  currentBot?: any;
  allIntegrations: IntegrationDto[];
  isOpen: boolean;
};
function GoogleSettingsModal({
  isOpen,
  integration,
  currentBot,
  spreadsheetId,
  sheetId,
  allIntegrations = [],
  onSubmit,
  onCancel,
}: GoogleSettingsModalProps) {
  const { integrationsService } = useAppContext();
  const [currentIntegrationId, setCurrentIntegrationId] = useState(integration?.integrationId);
  const [currentSpreadsheetId, setCurrentSpreadsheetId] = useState(spreadsheetId);
  const [currentSheetId, setCurrentSheetId] = useState(sheetId);

  const [getListOfSpreadsheetsState, getListOfSpreadsheetsApi] = usePromiseProcessing(
    async (integrationId: string) => {
      let { data: spreadsheets } = await integrationsService.getListOfSpreadsheets(integrationId);
      if (!spreadsheets || spreadsheets.length === 0) {
        const { data: newSpreadsheet } = await integrationsService.createNewSpreadsheet(
          integrationId,
          currentBot?.name || 'New'
        );
        spreadsheets = [newSpreadsheet];
      }

      const realSpreadsheets = spreadsheets.map(spreadsheet => ({
        label: spreadsheet.name,
        value: spreadsheet.id,
      }));
      return [createNewSpreadsheet(), ...realSpreadsheets];
    },
    { deps: [integrationsService] }
  );

  const [createNewSpreadsheetRequestState, createNewSpreadsheetCall] = usePromiseProcessing(
    (integrationId: string, name: string) =>
      integrationsService
        .createNewSpreadsheet(integrationId, name)
        .then(res => res.data)
        .catch(console.error)
        .finally(() => getListOfSpreadsheetsApi(integrationId)),
    { deps: [integrationsService] }
  );

  const [getListOfSheetsState, getListOfSheetsApi] = usePromiseProcessing(
    (currentSheetId: string) => {
      return integrationsService.getListOfSheets(currentSheetId, currentIntegrationId!).then(({ data: sheets }) =>
        sheets.map(sheet => ({
          label: sheet.sheetName,
          value: sheet.sheetName,
        }))
      );
    },
    { deps: [integrationsService, currentIntegrationId] }
  );

  useEffect(() => {
    if (!isOpen) {
      getListOfSheetsState.reset();
      getListOfSpreadsheetsState.reset();
      createNewSpreadsheetRequestState.reset();
      setCurrentSpreadsheetId('');
      setCurrentSheetId('');
      return;
    }
    setCurrentIntegrationId(integration?.integrationId);
    if (integration?.integrationId) getListOfSpreadsheetsApi(integration.integrationId);
    setCurrentSpreadsheetId(spreadsheetId);
    if (spreadsheetId) getListOfSheetsApi(spreadsheetId);
    setCurrentSheetId(sheetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!currentIntegrationId) return;
    getListOfSpreadsheetsApi(currentIntegrationId);
  }, [getListOfSpreadsheetsApi, currentIntegrationId]);

  useEffect(() => {
    if (!currentSpreadsheetId) return;
    getListOfSheetsApi(currentSpreadsheetId);
  }, [getListOfSheetsApi, currentSpreadsheetId]);

  useEffect(() => {
    if (currentSpreadsheetId) return;
    if (!getListOfSpreadsheetsState.result || getListOfSpreadsheetsState.result.length < 2) return;
    setCurrentSpreadsheetId(getListOfSpreadsheetsState.result[1].value);
  }, [getListOfSpreadsheetsState.result, currentSpreadsheetId]);

  useEffect(() => {
    if (currentSheetId) return;
    if (!getListOfSheetsState.result || getListOfSheetsState.result.length === 0) return;
    setCurrentSheetId(getListOfSheetsState.result![0].value);
  }, [getListOfSheetsState.result, currentSheetId]);

  const onChangeIntegration = useCallback(
    val => {
      const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
      getListOfSheetsState.reset();
      getListOfSpreadsheetsState.reset();
      setCurrentIntegrationId(oneEl.toString());
      setCurrentSpreadsheetId('');
      setCurrentSheetId('');
    },
    [getListOfSheetsState, getListOfSpreadsheetsState]
  );

  const onChangeSpreadsheet = useCallback(
    async val => {
      const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
      getListOfSheetsState.reset();
      if (oneEl.toString() === GoogleSettingsModalAction.CREATE_SPREADSHEET) {
        const el = await createNewSpreadsheetCall(currentIntegrationId!, currentBot?.name || 'New');
        el && setCurrentSpreadsheetId(el.id);
      } else {
        setCurrentSpreadsheetId(oneEl.toString());
      }
      setCurrentSheetId('');
    },
    [createNewSpreadsheetCall, currentBot?.name, currentIntegrationId, getListOfSheetsState]
  );

  const onChangeSheet = useCallback(val => {
    const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
    setCurrentSheetId(oneEl.toString());
  }, []);

  const canSubmit = useMemo(() => {
    return currentIntegrationId && currentSpreadsheetId && currentSheetId && !createNewSpreadsheetRequestState.loading;
  }, [createNewSpreadsheetRequestState.loading, currentIntegrationId, currentSheetId, currentSpreadsheetId]);

  const submit = useCallback(() => {
    const spreadsheet = getListOfSpreadsheetsState.result?.find(el => el.value === currentSpreadsheetId);
    if (!spreadsheet) {
      console.error(`Cannot found spreadsheet by id ${currentSpreadsheetId}`);
      return;
    }
    onSubmit({
      enabled: true,
      integrationId: currentIntegrationId!,
      spreadsheetId: spreadsheet.value,
      sheetName: currentSheetId,
    });
    onCancel();
  }, [
    currentIntegrationId,
    currentSheetId,
    currentSpreadsheetId,
    getListOfSpreadsheetsState.result,
    onCancel,
    onSubmit,
  ]);

  const isSpreadsheetInputDisabled =
    createNewSpreadsheetRequestState.loading ||
    getListOfSpreadsheetsState.loading ||
    !getListOfSpreadsheetsState.result;

  return (
    <Modal
      title={t('Wizard:ManagerNotify:Google:Settings:Title')}
      buttonSubmitText={t('Wizard:ManagerNotify:Google:Settings:Submit')}
      buttonCancelText={t('Cancel')}
      buttonSubmitColor='primary'
      buttonSubmitDisabled={!canSubmit}
      onCancelClick={onCancel}
      onActionClick={submit}
      centered
      isOpen={isOpen}
    >
      <div className={styles.settingsModalContent}>
        <div className={styles.dropdownWrapper}>
          <div>{t('Wizard:ManagerNotify:Google:Settings:Account')}</div>
          <JustSelect
            fullWidth
            onChange={onChangeIntegration}
            options={allIntegrations.map(integration => ({
              label: integration.integrationName,
              value: integration.integrationId,
            }))}
            value={currentIntegrationId}
            position='fixed'
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <div>{t('Wizard:ManagerNotify:Google:Settings:Table')}</div>
          <JustSelect
            fullWidth
            onChange={onChangeSpreadsheet}
            disabled={isSpreadsheetInputDisabled}
            isLoading={getListOfSpreadsheetsState.loading}
            options={getListOfSpreadsheetsState.result || []}
            value={currentSpreadsheetId}
            position='fixed'
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <div>{t('Wizard:ManagerNotify:Google:Settings:Sheet')}</div>
          <JustSelect
            fullWidth
            onChange={onChangeSheet}
            disabled={isSpreadsheetInputDisabled || getListOfSheetsState.loading || !getListOfSheetsState.result}
            isLoading={getListOfSheetsState.loading}
            options={getListOfSheetsState.result || []}
            value={currentSheetId}
            position='fixed'
          />
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(GoogleSettingsModal);
