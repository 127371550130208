const prefix = '@visualEditor/';

export const LOAD_JSON = prefix + 'LOAD_JSON';
export const LOAD_JSON_FULFILLED = prefix + 'LOAD_JSON_FULFILLED';
export const LOAD_JSON_PENDING = prefix + 'LOAD_JSON_PENDING';
export const LOAD_JSON_REJECTED = prefix + 'LOAD_JSON_REJECTED';
export const SET_JSON = prefix + 'SET_JSON';

export const SAVE_JSON = prefix + 'SAVE_JSON';
export const SAVE_JSON_FULFILLED = prefix + 'SAVE_JSON_FULFILLED';
export const SAVE_JSON_PENDING = prefix + 'SAVE_JSON_PENDING';
export const SAVE_JSON_REJECTED = prefix + 'SAVE_JSON_REJECTED';

export const NEW_CONNECTOR = prefix + 'NEW_CONNECTOR';
export const NEW_FROM_STATE_CONNECTOR = prefix + 'NEW_FROM_STATE_CONNECTOR';

export const REMOVE_CONNECTOR = prefix + 'REMOVE_CONNECTOR';
export const REMOVE_FROM_STATE_CONNECTOR = prefix + 'REMOVE_FROM_STATE_CONNECTOR';

export const ADD_WINDOW = prefix + 'ADD_WINDOW';
export const ADD_READY_INTENT = prefix + 'ADD_READY_INTENT';
export const DUMMY_FULFILLED = prefix + 'DUMMY_FULFILLED';
export const STOP_TEST = prefix + 'STOP_TEST';

export const WINDOW_ADD_BUTTON = prefix + 'WINDOW_ADD_BUTTON';
export const WINDOW_ADD_SYSTEM_BUTTON = prefix + 'WINDOW_ADD_SYSTEM_BUTTON';
export const WINDOW_DELETE_BUTTON = prefix + 'WINDOW_DELETE_BUTTON';
export const WINDOW_CHANGE_BUTTON_TEXT = prefix + 'WINDOW_CHANGE_BUTTON_TEXT';
export const SAVE_BUTTONS = prefix + 'SAVE_BUTTONS';
export const WINDOW_CHANGE_BUTTON_JSON = prefix + 'WINDOW_CHANGE_BUTTON_JSON';
export const WINDOW_CHANGE_BUTTON_URL = prefix + 'WINDOW_CHANGE_BUTTON_URL';

export const WINDOW_ADD_RANDOM = prefix + 'WINDOW_ADD_RANDOM';
export const WINDOW_DELETE_RANDOM = prefix + 'WINDOW_DELETE_RANDOM';
export const NEW_NODE_POSITION = prefix + 'NEW_NODE_POSITION';
export const WINDOW_CHANGE_TEXT = prefix + 'WINDOW_CHANGE_TEXT';
export const WINDOW_REMOVE = prefix + 'WINDOW_REMOVE';
export const WINDOW_RESORT = prefix + 'WINDOW_RESORT';

export const ADD_ACTION = prefix + 'ADD_ACTION';
export const REMOVE_ACTION = prefix + 'REMOVE_ACTION';

export const CHANGE_TITLE = prefix + 'CHANGE_TITLE';

export const SET_SCALE = prefix + 'SET_SCALE';
export const MAKE_PRETTY = prefix + 'MAKE_PRETTY';
export const WINDOW_CHANGE_PATTERN = prefix + 'WINDOW_CHANGE_PATTERN';
export const ADD_PATTERN = prefix + 'ADD_PATTERN';
export const REMOVE_PATTERN = prefix + 'REMOVE_PATTERN';

export const ADD_EXAMPLE = prefix + 'ADD_EXAMPLE';
export const REMOVE_EXAMPLE = prefix + 'REMOVE_EXAMPLE';
export const WINDOW_CHANGE_EXAMPLE = prefix + 'WINDOW_CHANGE_EXAMPLE';

export const HOVER_WINDOW = prefix + 'HOVER_WINDOW';
export const UNHOVER_WINDOW = prefix + 'UNHOVER_WINDOW';

export const EDIT_NODE = prefix + 'EDIT_NODE';
export const SAVE_NODE = prefix + 'SAVE_NODE';
export const TOGGLE_NODE = prefix + 'TOGGLE_NODE';
export const GROUP_MOVE_SAVE_TO_STORE = prefix + 'GROUP_MOVE_SAVE_TO_STORE';
export const SET_DEFFERED = prefix + 'SET_DEFFERED';

export const WINDOW_ADD_CONDITION = prefix + 'WINDOW_ADD_CONDITION';
export const WINDOW_DELETE_CONDITION = prefix + 'WINDOW_DELETE_CONDITION';
export const WINDOW_CHANGE_CONDITION = prefix + 'WINDOW_CHANGE_CONDITION';
export const WINDOW_SAVE_CONDITIONS = prefix + 'WINDOW_SAVE_CONDITIONS';

export const SAVE_HTTP_REQUEST = prefix + 'SAVE_HTTP_REQUEST';

export const WINDOW_ADD_EVENT = prefix + 'WINDOW_ADD_EVENT';
export const WINDOW_REMOVE_EVENT = prefix + 'WINDOW_REMOVE_EVENT';
export const WINDOW_CHANGE_EVENT = prefix + 'WINDOW_CHANGE_EVENT';

export const SAVE_SCRIPT = prefix + 'SAVE_SCRIPT';

export const ADD_CONNECTION_WITH_WINDOW = prefix + 'ADD_CONNECTION_WITH_WINDOW';

export const SC_TO_JSON = prefix + 'SC_TO_JSON';
export const SC_TO_JSON_FULFILLED = prefix + 'SC_TO_JSON_FULFILLED';
export const SC_TO_JSON_PENDING = prefix + 'SC_TO_JSON_PENDING';
export const SC_TO_JSON_REJECTED = prefix + 'SC_TO_JSON_REJECTED';

export const ADD_WINDOW_INPUT_TEXT = prefix + 'ADD_WINDOW_INPUT_TEXT';
export const SAVE_INPUT_TEXT_NODE = prefix + 'SAVE_INPUT_TEXT_NODE';
export const SAVE_INPUT_NUMBER_NODE = prefix + 'SAVE_INPUT_NUMBER_NODE';
export const SAVE_INPUT_FILE_NODE = prefix + 'SAVE_INPUT_FILE_NODE';

export const SET_IMAGE_URL = prefix + 'SET_IMAGE_URL';
export const SET_IMAGE_FILE = prefix + 'SET_IMAGE_FILE';
export const SAVE_SWITCH = prefix + 'SAVE_SWITCH';
export const SAVE_INTENT_GROUP = prefix + 'SAVE_INTENT_GROUP';
export const SAVE_CONFIRMATION = prefix + 'SAVE_CONFIRMATION';
export const DROP_VISUAL_AFTER_FILE_REMOVE = prefix + 'DROP_VISUAL_AFTER_FILE_REMOVE';

export const SET_IMAGE_CDN_URLS = prefix + 'SET_IMAGE_CDN_URLS';

export const REMOVE_IMAGE_FROM_SERVER = prefix + 'REMOVE_IMAGE_FROM_SERVER';
export const REMOVE_IMAGE_FROM_SERVER_FULFILLED = prefix + 'REMOVE_IMAGE_FROM_SERVER_FULFILLED';
export const REMOVE_IMAGE_FROM_SERVER_PENDING = prefix + 'REMOVE_IMAGE_FROM_SERVER_PENDING';
export const REMOVE_IMAGE_FROM_SERVER_REJECTED = prefix + 'REMOVE_IMAGE_FROM_SERVER_REJECTED';

export const SAVE_IMAGE_TO_SERVER = prefix + 'SAVE_IMAGE_TO_SERVER';
export const SAVE_IMAGE_TO_SERVER_FULFILLED = prefix + 'SAVE_IMAGE_TO_SERVER_FULFILLED';
export const SAVE_IMAGE_TO_SERVER_PENDING = prefix + 'SAVE_IMAGE_TO_SERVER_PENDING';
export const SAVE_IMAGE_TO_SERVER_REJECTED = prefix + 'SAVE_IMAGE_TO_SERVER_REJECTED';

export const SAVE_AUDIOS_TO_STORE = prefix + 'SAVE_AUDIOS_TO_STORE';

export const SAVE_AUDIOS_TO_SERVER = prefix + 'SAVE_IMAGE_TO_SERVER';
export const SAVE_AUDIOS_TO_SERVER_FULFILLED = prefix + 'SAVE_IMAGE_TO_SERVER_FULFILLED';
export const SAVE_AUDIOS_TO_SERVER_PENDING = prefix + 'SAVE_IMAGE_TO_SERVER_PENDING';
export const SAVE_AUDIOS_TO_SERVER_REJECTED = prefix + 'SAVE_IMAGE_TO_SERVER_REJECTED';

export const SAVE_IMAGE_URL_TO_SERVER = prefix + 'SAVE_IMAGE_URL_TO_SERVER';
export const SAVE_IMAGE_URL_TO_SERVER_FULFILLED = prefix + 'SAVE_IMAGE_URL_TO_SERVER_FULFILLED';
export const SAVE_IMAGE_URL_TO_SERVER_PENDING = prefix + 'SAVE_IMAGE_URL_TO_SERVER_PENDING';
export const SAVE_IMAGE_URL_TO_SERVER_REJECTED = prefix + 'SAVE_IMAGE_URL_TO_SERVER_REJECTED';

export const SAVE_IMAGE_URL = prefix + 'SAVE_IMAGE_URL';
export const ADD_SCREEN_BEFORE_META = prefix + 'ADD_SCREEN_BEFORE_META';
export const RESORT_BUTTON = prefix + 'RESORT_BUTTON';

export const SET_WEBHOOK_DATA = prefix + 'SET_WEBHOOK_DATA';
export const SAVE_WEBHOOK_URL = prefix + 'SAVE_WEBHOOK_URL';
export const SAVE_WEBHOOK_SCREEN_ACTION = prefix + 'SAVE_WEBHOOK_SCREEN_ACTION';
export const TOGGLE_WEBHOOK_SCREEN_ACTION = prefix + 'TOGGLE_WEBHOOK_SCREEN_ACTION';

export const WINDOW_SAVE_ANSWER = prefix + 'WINDOW_SAVE_ANSWER';
export const SAVE_INPUT_PHONE = prefix + 'SAVE_INPUT_PHONE';
export const SAVE_HTTP_EMAIL = prefix + 'SAVE_HTTP_EMAIL';
export const SAVE_SMS = prefix + 'SAVE_SMS';
export const SAVE_GOOGLE_SHEETS = prefix + 'SAVE_GOOGLE_SHEETS';
export const SAVE_TIMETABLE = prefix + 'SAVE_TIMETABLE';
export const SAVE_TelegramPayment = prefix + 'SAVE_TelegramPayment';
export const SAVE_INPUT_DATETIME = prefix + 'SAVE_INPUT_DATETIME';
export const RESTORE_LAST_STATE = prefix + 'RESTORE_LAST_STATE';

export const SET_BLOCK_BETWEEN_COLS = prefix + 'SET_BLOCK_BETWEEN_COLS';
export const SET_AFTER_BLOCK_IN_COLS = prefix + 'SET_AFTER_BLOCK_IN_COLS';
export const MOVE_TO_THE_TOP_OF_COL = prefix + 'MOVE_TO_THE_TOP_OF_COL';

export const SAVE_WEBHOOK_COLOR = prefix + 'SAVE_WEBHOOK_COLOR';
export const SAVE_IMAGE_CREDENTIALS = prefix + 'SAVE_IMAGE_CREDENTIALS';

export const CUT_BLOCK = prefix + 'CUT_BLOCK';
export const COPY_BLOCK = prefix + 'COPY_BLOCK';
export const PUT_BLOCK = prefix + 'PUT_BLOCK';

export const SAVE_CRM_INTEGRATION = prefix + 'SAVE_CRM_INTEGRATION';

export const SAVE_TIMEOUT = prefix + 'SAVE_TIMEOUT';

export const SAVE_TRANSFER_TO_CALL_OPERATOR = prefix + 'SAVE_TRANSFER_TO_CALL_OPERATOR';
export const SAVE_VIDEO_URL = prefix + 'SAVE_VIDEO_URL';

export const APPLY_AUTOSAVE = prefix + 'APPLY_AUTOSAVE';

export const SAVE_EMAIL_FILE_TO_SERVER = prefix + 'SAVE_EMAIL_FILE_TO_SERVER';

export const validVariableNameRegexp = /^(?!(?:do|if|in|for|let|new|try|var|case|else|enum|eval|false|null|this|true|void|with|break|catch|class|const|super|throw|while|yield|delete|export|import|public|return|static|switch|typeof|default|extends|finally|package|private|continue|debugger|function|arguments|interface|protected|implements|instanceof)$)[a-zA-Z]+\w*$/g;

export const spaceText = /^[\s]+$/g;
export const scriptTagRegex = /<script[^>]*>(.*?)<\/script[^>]*>/gi;
