import React from 'react';
import cn from 'classnames';
import { InputWithDropdown as InputWithDropdownFromJustUi } from '@just-ai/just-ui';
import { InputWithDropdownProps } from '@just-ai/just-ui/dist/InputWithDropdown';

import styles from './styles.module.scss';

interface InputProps extends InputWithDropdownProps {
  className?: string;
}
const InputWithDropdown = (props: InputProps) => {
  // @ts-ignore
  return <InputWithDropdownFromJustUi {...props} className={cn(styles.textAreaForWizard, props.className)} />;
};

export default React.memo(InputWithDropdown);
