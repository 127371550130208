import { useCallback } from 'react';
import { Checkbox, Icon, InputText, useId } from '@just-ai/just-ui';

import { t } from 'localization';
import { isEuroInstance } from 'isAccessFunction';

import WizardTooltip from 'modules/TemplatesWizard/primitives/WizardTooltip';

import { ManagerNotifyResult, OnChangeType } from '../..';

import styles from '../../styles.module.scss';

export function SmsBlock({
  value,
  onChange,
  isShowErrors,
  errors,
}: {
  value: ManagerNotifyResult['phone'];
  onChange: OnChangeType<ManagerNotifyResult['phone']>;
  isShowErrors?: boolean;
  errors: { number: string };
}) {
  const checkBoxId = useId();
  const tooltipId = useId();
  const textId = useId();
  const onChangeEnabled = useCallback((val: boolean) => onChange('enabled', val), [onChange]);
  const onChangePhone = useCallback((val: string) => onChange('number', val), [onChange]);

  return (
    <>
      <span className={styles.checkBoxWrapper}>
        <Checkbox
          inline
          label='SMS'
          name={checkBoxId}
          className={styles.checkBoxWrapper}
          checked={value.enabled}
          onChange={onChangeEnabled}
        />
        <Icon className='margin-left-17-px' name='faQuestionCircle' color='secondary' id={tooltipId} />
        <WizardTooltip textAlign='center' placement='top' style={{ width: 200 }} target={tooltipId}>
          {t('Wizard:ManagerNotify:SMS:Hint')}
        </WizardTooltip>
      </span>
      <div className='flex-column'>
        <InputText
          className={styles.ManagerNotify__input}
          type='tel'
          name={textId}
          value={value.number}
          errorText={isShowErrors ? errors.number : ''}
          placeholder={isEuroInstance() ? '+1 206 000 0000' : '+7 910 000 0000'}
          onChange={onChangePhone}
        />
      </div>
    </>
  );
}
