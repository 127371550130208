import { applyMiddleware, createStore } from 'redux';
import createRootReducer from './reducers/index';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import history from './appHistory';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSocketMiddleware from './middleware/sockets/socketMiddleware';

const middleware = applyMiddleware(promise, thunk, routerMiddleware(history), createSocketMiddleware());

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 20 });

const store = createStore(createRootReducer(history), composeEnhancers(middleware));

export default store;
