import React from 'react';
import { AtreidesTypo, Button } from 'altreidsds';
import Robot404 from 'components/page404/robot404';
import localize from 'localization';
import history from '../../appHistory';

import classes from './ErrorPage.module.scss';

export default function ErrorPage({ logout, clearError, ccSessionsAreEnabled }) {
  return (
    <div className={classes.container} data-test-id='ErrorPage'>
      <Robot404 />
      <AtreidesTypo type='h1' variant='bold'>
        {localize.translate('Crash label')}
      </AtreidesTypo>
      <Button
        style={{ marginBottom: '2rem' }}
        variant='contained'
        color='primary'
        onClick={() => {
          clearError();
          window.location = '/';
        }}
      >
        {localize.translate('To bot page')}
      </Button>
      <Button variant='contained' color='primary' onClick={() => history.push('/logout')}>
        {localize.translate('Sign out')}
      </Button>
    </div>
  );
}
