import {
  PUBLISH_IVR_CHANNEL_FULFILLED,
  DELETE_IVR_CHANNEL_FULFILLED,
  FIND_IVR_RESTERISK_CHANNEL_FULFILLED,
  FIND_IVR_RESTERISK_CHANNEL_PENDING,
  FIND_IVR_RESTERISK_CHANNEL_REJECTED,
  UPDATE_INTERNAL_RESTERISK_CHANNEL_FULFILLED,
  UPDATE_INTERNAL_RESTERISK_CHANNEL_PENDING,
  UPDATE_INTERNAL_RESTERISK_CHANNEL_REJECTED,
  PUBLISH_IVR_CHANNEL_PENDING,
  DELETE_IVR_CHANNEL_PENDING,
  PUBLISH_IVR_CHANNEL_REJECTED,
  DELETE_IVR_CHANNEL_REJECTED,
} from '../../constants/ivr.actions';

const initialState = {
  channel: '',
  fetching: false,
  error: '',
};

export default function ivrReducer(state = initialState, action) {
  switch (action.type) {
    case FIND_IVR_RESTERISK_CHANNEL_FULFILLED:
    case UPDATE_INTERNAL_RESTERISK_CHANNEL_FULFILLED:
    case PUBLISH_IVR_CHANNEL_FULFILLED:
    case DELETE_IVR_CHANNEL_FULFILLED:
      return {
        ...state,
        channel: action.payload.data,
        fetching: false,
        error: '',
      };

    case FIND_IVR_RESTERISK_CHANNEL_PENDING:
    case UPDATE_INTERNAL_RESTERISK_CHANNEL_PENDING:
    case PUBLISH_IVR_CHANNEL_PENDING:
    case DELETE_IVR_CHANNEL_PENDING:
      return {
        ...state,
        fetching: true,
        error: '',
      };

    case UPDATE_INTERNAL_RESTERISK_CHANNEL_REJECTED:
    case FIND_IVR_RESTERISK_CHANNEL_REJECTED:
    case PUBLISH_IVR_CHANNEL_REJECTED:
    case DELETE_IVR_CHANNEL_REJECTED:
      return {
        ...state,
        fetching: false,
        error: {
          key: action.payload.response?.data.error,
          args: action.payload.response?.data.payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
}
