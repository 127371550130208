import React from 'react';
import localize from 'localization';
import { InputText, SwitchButton } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import classnames from 'classnames';

const { translate: t } = localize;

export default class ViberEditForm extends BaseEditForm {
  constructor(props) {
    super(props);
    this.form = {};
    let startMessageToggle = false;

    if (
      Boolean(props.editableChannel) &&
      Boolean(props.editableChannel.id) &&
      Boolean(props.editableChannel.customData)
    ) {
      if (props.editableChannel.customData.hasOwnProperty('startMessageToggle')) {
        startMessageToggle = props.editableChannel.customData.startMessageToggle;
      }
    }

    this.state = {
      rollout: true,
      errors: [],
      startMessageToggle,
    };
  }

  getLabelName = () => t('Bot token');

  createChannel = async () => {
    const { createChannel, editableChannel, onClose } = this.props;
    let { name, botToken, senderName } = this.form;
    let botName = name?.value || editableChannel.botName || t(`AddChannelCard ${editableChannel.channelType}`);
    let startMessage =
      typeof this.form.startMessage?.value === 'string'
        ? this.form.startMessage?.value
        : editableChannel.customData.startMessage;
    const channelData = {
      ...editableChannel,
      botName,
      senderName: senderName?.value || botName,
      accessToken: botToken?.value || '',
      channelType: editableChannel.channelType,
      project: {
        ...editableChannel.project,
      },
      customData: {
        ...editableChannel.customData,
        startMessage,
        startMessageToggle: this.state.startMessageToggle,
      },
    };

    await createChannel(channelData);
    onClose();
  };

  saveChannel = async () => {
    const { editChannel, editableChannel, onClose } = this.props;

    let startMessage =
      typeof this.form.startMessage?.value === 'string'
        ? this.form.startMessage?.value
        : editableChannel.customData.startMessage;
    const channelData = {
      botName: this.form.name.value,
      senderName: this.form.name.value,

      accessToken: this.form.botToken?.value || '',
      channelType: this.props.editableChannel.channelType,
      rollout: 'MANUAL',
      id: this.props.editableChannel.id,
      branch: 'master',
      project: {
        ...this.props.editableChannel.project,
      },
      customData: {
        ...editableChannel.customData,
        startMessage,
        startMessageToggle: this.state.startMessageToggle,
      },
    };

    await editChannel(channelData);
    onClose();
  };

  toggleStartDialogueWithUserMessage = value => {
    this.setState({ startMessageToggle: value });
  };

  renderInputs() {
    const { editableChannel } = this.props;
    const { startMessageToggle } = this.state;

    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{this.getLabelName()}</p>
          <InputText
            innerRef={input => (this.form.botToken = input)}
            id='bot_token'
            defaultValue={editableChannel.accessToken}
            placeholder={t('Enter custom', this.getLabelName().toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('AddChannelCard sender name label')}</p>
          <InputText
            innerRef={input => (this.form.senderName = input)}
            id='sender_name'
            defaultValue={editableChannel.senderName}
            placeholder={t('Enter custom', t('AddChannelCard sender name label').toLowerCase())}
          />
        </div>
        <div
          className={classnames(classes.formControl, classes.switchContainer, {
            [classes.simpleMargin]: startMessageToggle,
          })}
        >
          <SwitchButton
            id='startMessageToggle'
            value={this.state.startMessageToggle}
            color='primary'
            onChange={this.toggleStartDialogueWithUserMessage}
          />
          <label htmlFor='startMessageToggle'>{t('ChatWidgetEditor involving greeting show label')}</label>
        </div>
        {startMessageToggle && (
          <div className={classes.formControl}>
            <InputText
              innerRef={input => (this.form.startMessage = input)}
              id='start_message'
              defaultValue={editableChannel.customData.startMessage}
            />

            <small className={classes.infoText}>{t('AddChannelCard: Viber startMessage help text')}</small>
          </div>
        )}
      </>
    );
  }
}
