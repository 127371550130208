import React, { FC, useCallback, useState, useRef, useEffect } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { without } from 'lodash';
import cn from 'classnames';
import { Button, Icon, InputWithDropdown, useId } from '@just-ai/just-ui';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';

import toArray from 'helpers/array/toArray';

import WizardTooltip from 'modules/TemplatesWizard/primitives/WizardTooltip';

import { addKeyToRow } from '../../utils';
import { ITemplateWizardEditField, LocalizationField } from '../../types';
import styles from './styles.module.scss';

type DataCollectionTableColumns = {
  envName: string;
  header?: LocalizationField;
  type: 'select' | 'text' | 'checkbox';
  selectOptions: string[];
  maxLength?: number;
  selectMultiple?: boolean;
  disabled?: boolean;
  eachRowSelectUniqValue?: boolean;
  arbitrarySelectOptions?: boolean;
  placeholder?: LocalizationField | { condition: any; value: string }[];
  helperText?: LocalizationField;
};

export type DataCollectionTableProps = ITemplateWizardEditField<
  Record<any, any>[],
  {
    columns: DataCollectionTableColumns[];
    addRowText: string;
    title?: LocalizationField;
    allowNewRows?: boolean;
    maxRows?: number;
    preventDeleteLast?: boolean;
    preventDeleteLastHint?: string;
    disableDrag?: boolean;
    disableRemove?: boolean;
  }
>;

type HeaderProps = { columns: DataCollectionTableColumns[]; disableDrag?: boolean; disableRemove?: boolean };
const Header = React.memo(({ columns, disableRemove, disableDrag }: HeaderProps) => {
  return (
    <tr className={styles.row}>
      {!disableDrag && <th></th>}
      {columns.map(column => (
        <th key={column.header} style={{ width: column.type === 'text' ? '100%' : 'auto' }}>
          {column.header}
        </th>
      ))}
      {!disableRemove && <th></th>}
    </tr>
  );
});

type RowProps = {
  columns: DataCollectionTableColumns[];
  onChangeInner: (value: any, name: string) => void;
  onDelete: () => void;
  rows: Record<string, any>[];
  rowIndex: number;
  rowData: Record<string, any>;
  disableDrag?: boolean;
  disableRemove?: boolean;
  hideRemove?: boolean;
  preventDeleteLastHint?: string;
  errors?: Record<string, any>;
  urgentErrors?: Record<string, any>;
  isShowErrors?: boolean;
};

const DragHandleBox = SortableHandle(() => {
  return (
    <td>
      <Icon name='farDrag' style={{ cursor: 'grab' }} />
    </td>
  );
});

function getPlaceholder(rowData: Record<string, any>, column: DataCollectionTableColumns): string {
  if (Array.isArray(column.placeholder)) {
    const placeholderCase = column.placeholder.find(caseEl => {
      for (let key in caseEl.condition) {
        if (caseEl.condition[key] !== rowData[key]) return false;
      }
      return true;
    });
    return placeholderCase ? placeholderCase.value : '';
  }
  return (column.placeholder as string) || '';
}

const Row = SortableElement(
  ({
    columns,
    onDelete,
    onChangeInner,
    rowData,
    rows,
    disableRemove,
    hideRemove,
    disableDrag,
    preventDeleteLastHint,
    rowIndex,
    errors,
    urgentErrors,
    isShowErrors,
  }: RowProps) => {
    const ref = useRef<HTMLTableRowElement | null>(null);
    const deleteId = useId();

    function drawField(column: DataCollectionTableColumns) {
      const placeholder = getPlaceholder(rowData, column);

      const key = column.header;
      const isNeedToShowError = isShowErrors || urgentErrors?.[`${rowIndex}.${column.envName}`];
      switch (column.type) {
        case 'text':
          return (
            <td key={key} className={styles.textInput}>
              <TextAreaLikeElement
                key={column.type}
                className={styles.textArea}
                invalid={isNeedToShowError && !!errors?.[`${rowIndex}.${column.envName}`]}
                errorText={isNeedToShowError && errors?.[`${rowIndex}.${column.envName}`]}
                richValue={rowData[column.envName] || ''}
                value={rowData[column.envName] || ''}
                maxLength={column.maxLength}
                disabled={column.disabled}
                minRows={1}
                placeholder={placeholder}
                onChange={val => onChangeInner(val, column.envName)}
              />
            </td>
          );
        case 'select':
          let suggests = column.selectOptions;
          if (column.eachRowSelectUniqValue) {
            const colValue = toArray(rowData[column.envName]);
            const selectedOptions: string[] = Object.values(rows)
              .map(row => row[column.envName])
              .flat(2)
              .filter(option => !colValue.includes(option));
            suggests = without(suggests, ...selectedOptions);
          }
          return (
            <td key={key}>
              <InputWithDropdown
                className={styles.dropDown}
                onOptionClick={val => onChangeInner(val.value, column.envName)}
                disabled={column.disabled}
                onChange={val => {
                  if (column.arbitrarySelectOptions) {
                    onChangeInner(val, column.envName);
                  }
                }}
                maxlength={column.maxLength}
                dropdownOptions={suggests.map(option => ({
                  value: option,
                  label: option,
                }))}
                errorText={isNeedToShowError ? errors?.[`${rowIndex}.${column.envName}`] : ''}
                multiple={column.selectMultiple}
                value={rowData[column.envName] || ''}
                placeholder={placeholder}
                menuPositionFixed
                dontClearOnBlur={column.arbitrarySelectOptions}
                showAllOptionsWhenValueIsEmpty
              />
            </td>
          );
        default:
          return null;
      }
    }

    return (
      <tr ref={ref} className={styles.row}>
        {!disableDrag && <DragHandleBox />}
        {columns.map(drawField)}
        {!hideRemove && (
          <td style={{ width: 38, paddingLeft: 0 }} id={deleteId}>
            <Button
              className={styles.deleteBtn}
              type='button'
              disabled={disableRemove}
              color='secondary'
              size='md'
              withoutPadding
              flat
              onClick={onDelete}
            >
              <Icon name='farTrashAlt' />
            </Button>
            {disableRemove && preventDeleteLastHint && (
              <WizardTooltip trigger='hover' placement='top' target={deleteId}>
                {preventDeleteLastHint}
              </WizardTooltip>
            )}
          </td>
        )}
      </tr>
    );
  }
);

type SortableRowsProps = {
  rows: (Record<string, any> & { key: string })[];
  columns: DataCollectionTableColumns[];
  onChangeInner: (index: number, value: any, name: string) => void;
  delRow: (index: number) => void;
  disableDrag?: boolean;
  hideRemove?: boolean;
  errors?: Record<string, any>;
  urgentErrors?: Record<string, any>;
  isShowErrors?: boolean;
  preventDeleteLast?: boolean;
  preventDeleteLastHint?: string;
};
const SortableRows = SortableContainer(
  ({
    columns,
    rows,
    onChangeInner,
    delRow,
    hideRemove,
    disableDrag,
    preventDeleteLast,
    preventDeleteLastHint,
    errors,
    urgentErrors,
    isShowErrors,
  }: SortableRowsProps) => {
    const preventRemove = preventDeleteLast && rows.length === 1;

    return (
      <tbody>
        {rows.map((row, index) => (
          <Row
            index={index}
            key={row.key}
            columns={columns}
            rowData={rows[index]}
            rowIndex={index}
            rows={rows}
            errors={errors}
            urgentErrors={urgentErrors}
            isShowErrors={isShowErrors}
            onChangeInner={(value, name) => onChangeInner(index, value, name)}
            onDelete={() => delRow(index)}
            disableRemove={preventRemove}
            preventDeleteLastHint={preventDeleteLastHint}
            hideRemove={hideRemove}
            disableDrag={disableDrag}
          />
        ))}
      </tbody>
    );
  }
);

const DataCollectionTable: FC<DataCollectionTableProps> = ({
  envName,
  onChange,
  info,
  value,
  errors,
  urgentErrors,
  isShowErrors,
}) => {
  const [innerValue, setInnerValue] = useState(() => (value || []).map(addKeyToRow));
  const ref = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const defaultValues = innerValue.map(row => {
      return info.columns.reduce(
        (acc, column) => {
          acc[column.envName] = getPlaceholder(row, column);
          return acc;
        },
        { ...row } as Record<string, any>
      );
    });
    onChange(defaultValues, envName, true);
  }, [envName, info, innerValue, onChange]);

  useEffect(() => {
    onChange(innerValue, envName, false);
  }, [envName, innerValue, onChange]);

  const onChangeInner = useCallback((index: number, value: any, name: string) => {
    setInnerValue(prev => {
      prev[index] = Object.assign({}, prev[index], {
        [name]: value,
      });
      return prev.concat();
    });
  }, []);

  const addRow = useCallback(() => {
    const emptyRow = info.columns.reduce((acc, col) => {
      acc[col.envName] = '';
      return acc;
    }, {} as any);
    setInnerValue(prev => [...prev, addKeyToRow(emptyRow)]);
  }, [info.columns]);

  const delRow = useCallback((index: number) => {
    setInnerValue(prev => {
      prev.splice(index, 1);
      return prev.concat();
    });
  }, []);

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setInnerValue(items => arrayMove(items, oldIndex, newIndex));
  }, []);

  return (
    <div
      className={cn(styles.DataCollectionTable, {
        disableDrag: info.disableDrag,
        disableRemove: info.disableRemove,
      })}
      ref={ref}
    >
      <div className={styles.DataCollectionTable__title}>{info.title}</div>
      <table className={styles.DataCollectionTable__table}>
        <thead>
          <Header columns={info.columns} disableDrag={info.disableDrag} disableRemove={info.disableRemove} />
        </thead>
        <SortableRows
          useDragHandle
          onSortEnd={onSortEnd}
          lockAxis='y'
          helperClass={styles.drugHelper}
          lockToContainerEdges
          rows={innerValue}
          preventDeleteLast={info.preventDeleteLast}
          preventDeleteLastHint={info.preventDeleteLastHint}
          columns={info.columns}
          onChangeInner={onChangeInner}
          delRow={delRow}
          errors={errors?.[envName]}
          urgentErrors={urgentErrors?.[envName]}
          isShowErrors={isShowErrors}
          disableDrag={info.disableDrag}
          hideRemove={info.disableRemove}
        />
      </table>
      {info.allowNewRows && (info.maxRows === undefined || innerValue.length < info.maxRows) && (
        <Button className={styles.DataCollectionTable__addBtn} type='button' color='info' flat onClick={addRow}>
          <Icon name='farPlus' />
          <span>{info.addRowText}</span>
        </Button>
      )}
    </div>
  );
};

export default DataCollectionTable;
