import React, { Component } from 'react';
import { Button, Typography, withStyles, Grid, Modal } from 'altreidsds';
import localize from '../../localization';
import Spinner from '../../components/Spinner';

class BotDeleteModal extends Component {
  render() {
    return (
      <Modal
        disableBackdropClick={true}
        key='BotDeleteModal'
        open={this.props.open}
        onClose={this.props.onClose}
        title={localize.translate('Delete bot title')}
        modalBottom={
          <Grid container spacing={8} justify='flex-end'>
            <Grid item>
              <Button variant='outlined' color='dangerous' onClick={this.props.onClose}>
                {localize.translate('Cancel delete bot')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                data-test-id='Bot.DeleteModal.confirm'
                variant='contained'
                color='dangerous'
                onClick={this.props.onOkClick}
              >
                {localize.translate('Confirm delete bot')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        {this.props.fetching ? <Spinner /> : null}
        <Typography>{localize.translate('Delete bot body')}</Typography>
      </Modal>
    );
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(BotDeleteModal);
