import * as constants from '../../constants/catchAllSettings.actions';
import { uniqBy } from 'lodash';
import localize from '../../localization';

const InitialState = {
  fetching: false,

  hash: '',
  name: '',
  markedForDelete: false,
  content: '',

  catchAll: {
    phrases: [],
  },

  speechNotRecognized: {
    phrases: [],
  },

  tariffLimit: {
    phrases: [],
  },
  fulfillment: {
    settings: {
      default: {},
      channels: [],
    },
  },
  errorMessages: [],

  widgetChannels: [],
  variables: null,
};

export default function CatchAllSettings(state = InitialState, action) {
  switch (action.type) {
    case constants.LOAD_CATCH_ALL_SETTINGS_PENDING:
    case constants.SAVE_CATCH_ALL_SETTINGS_PENDING:
    case constants.LOAD_VARIABLES_PENDING:
    case constants.LOAD_CATCH_ALL_ERROR_MESSAGES_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.SAVE_CATCH_ALL_SETTINGS_REJECTED:
    case constants.LOAD_CATCH_ALL_SETTINGS_REJECTED:
    case constants.LOAD_VARIABLES_REJECTED:
    case constants.LOAD_CATCH_ALL_ERROR_MESSAGES_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }
    case constants.LOAD_CATCH_ALL_SETTINGS_FULFILLED: {
      let content = {};

      try {
        content = JSON.parse(action.payload.data.content);
      } catch (e) {}
      if (!Boolean(content.tariffLimit) || !Boolean(content.tariffLimit.phrases)) {
        content = {
          ...content,
          tariffLimit: {
            phrases: [localize.translate('Sorry, work is temporarily suspended.')],
          },
        };
      }

      if (!Boolean(content.fulfillment)) {
        content = {
          ...content,
          fulfillment: {
            settings: {
              default: {
                url: '',
              },
              channels: [
                /*{
                                 "channelId": "39-hello2-39-oOA-35771",
                                 "url": "https://7fc0536c.ngrok.io"
                                 }*/
              ],
            },
            actions: [
              /*{
                             state: "/newNode_0",
                             action: "get_name",
                             enabled: false
                             },*/
            ],
          },
        };
      }
      if (!Boolean(content.fulfillment.settings.channels)) {
        content.fulfillment.settings.channels = [];
      }
      return {
        ...state,
        fetching: false,

        name: action.payload.data.name,
        hash: action.payload.data.hash,
        markedForDelete: action.payload.data.markedForDelete,
        content: content,

        catchAll: content.catchAll,
        speechNotRecognized: content.speechNotRecognized,
        fulfillment: content.fulfillment,
        tariffLimit: content.tariffLimit,
      };
    }

    case constants.LOAD_CATCH_ALL_ERROR_MESSAGES_FULFILLED: {
      let errorMessages = [];
      if (typeof action.payload.data !== 'string') {
        errorMessages = action.payload.data;
      }
      return {
        ...state,
        errorMessages: errorMessages,
      };
    }

    case constants.SAVE_CATCH_ALL_SETTINGS_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case constants.LOAD_SIMPLE_WIDGET_CHANNEL_FULFILLED: {
      let channels = [...state.widgetChannels];
      channels.push({
        channelId: action.payload.data.botserverBotId,
        type: action.payload.data.channelType,
      });
      channels = uniqBy(channels, 'channelId');
      return {
        ...state,
        widgetChannels: channels,
      };
    }

    case constants.LOAD_VOICE_WIDGET_CHANNEL_FULFILLED: {
      let channels = [...state.widgetChannels];
      channels.push({
        channelId: action.payload.data.botserverBotId,
        type: action.payload.data.channelType,
      });
      channels = uniqBy(channels, 'channelId');
      return {
        ...state,
        widgetChannels: channels,
      };
    }

    case constants.LOAD_VARIABLES_FULFILLED: {
      let variables = InitialState.variables;
      if (action.payload.data.hasOwnProperty('templateVariables')) {
        variables = {
          content: action.payload.data.templateVariables,
        };
      }
      if (action.payload.data && action.payload.data.content) {
        try {
          variables = {
            ...action.payload.data,
            content: JSON.parse(action.payload.data.content),
          };
        } catch (e) {
          variables = {
            ...action.payload.data,
            content: {},
          };
          console.error(e);
        }
      }
      return {
        ...state,
        fetching: false,
        variables,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
