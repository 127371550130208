import React from 'react';
import cn from 'classnames';

import { t } from 'localization';
import { useAppSelector } from 'storeHooks';

import styles from './styles.module.scss';

type ChatGPTBannerProps = {
  showOnlyLimitExceeded?: boolean;
  className?: string;
};
const ChatGPTBanner = ({ showOnlyLimitExceeded, className }: ChatGPTBannerProps) => {
  const { cloudDomains, isChatGPTLimitExhausted } = useAppSelector(store => ({
    cloudDomains: store.AppConfigReducer.cloudDomains,
    isChatGPTLimitExhausted: store.CurrentUserReducer.isChatGPTLimitExhausted,
  }));

  if (
    (showOnlyLimitExceeded && !isChatGPTLimitExhausted) ||
    (window.location.pathname === '/' && !isChatGPTLimitExhausted)
  )
    return null;
  return (
    <div
      className={cn(styles.ChatGPTBanner, className, {
        [styles.limitExceeded]: isChatGPTLimitExhausted,
      })}
    >
      <div className={styles.ChatGPTBanner__icon} />
      <div
        className={styles.ChatGPTBanner__text}
        dangerouslySetInnerHTML={{
          __html: isChatGPTLimitExhausted
            ? t('ChatGPTBanner:ExceededText', `//${cloudDomains.aimylogic.domain}/prices`)
            : t('ChatGPTBanner:Text'),
        }}
      />
    </div>
  );
};

export default React.memo(ChatGPTBanner);
