import React from 'react';
import localize from '../../localization';
import { AtreidesIcon, withStyles } from 'altreidsds';
import classNames from 'classnames';

const HelpLink = ({ helpLink, classes, type, showHelp }) => {
  const link = localize.translate(`helpLink ${helpLink}`);
  return link.indexOf('helpLink') === -1 && showHelp ? (
    <a
      href={link}
      className={classNames(
        classes.helpLink,
        { title: type === 'title' },
        { modalTitle: type === 'modalTitle' },
        { progress: type === 'progress' }
      )}
      rel='noopener noreferrer'
      target='_blank'
    >
      <AtreidesIcon name='questionv2' size='small' />
    </a>
  ) : (
    ''
  );
};

const styles = theme => ({
  helpLink: {
    '& svg ': {
      marginLeft: 5,
      marginTop: -3,
      '& path': {
        fill: 'var(--primary-400)',
      },
    },
    '&.title svg': {
      marginLeft: 9,
      marginTop: -1,
    },
    '&.modalTitle svg': {
      marginTop: -1,
    },
    '&.progress svg': {
      marginTop: 0,
      marginLeft: 2,
    },
  },
});

export default withStyles(styles)(HelpLink);
