import React from 'react';
import { Tooltip, TooltipProps } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './styles.module.scss';

const WizardTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      popperClassName={cn(styles.dropShadow, props.popperClassName)}
      innerClassName={cn(styles.tooltipCustom, props.innerClassName)}
    >
      {props.children}
    </Tooltip>
  );
};

export default React.memo(WizardTooltip);
