import React, { useCallback, createContext, useContext, useState, FC, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Button, IconButton } from '@just-ai/just-ui';

import { t } from 'localization';
import history from 'appHistory';

import { BASE_WIZARD_PATH } from '../shared/consts';

import { WizardStepper } from './wizard-stepper';
import { WizardStart } from './wizard-start';
import { GraphWay } from './graph-way';
import { GraphWayEnd } from './graph-way/graph-way-end';
import { SkillWay } from './skill-way/create-name';
import { WizardSteps } from './wizard-steps';
import { CallsTypeSelect } from './calls-type-select';
import { ChooseGraphEditor } from './graph-choose';

import classes from './ui.module.scss';

type WizardHeaderContextType = {
  backPath: string;
  setBackPath: (path: string) => void;
};
const WizardHeaderContext = createContext({} as WizardHeaderContextType);
export const useWizardHeaderContext = () => useContext(WizardHeaderContext);
const WizardHeaderContextProvider: FC = ({ children }) => {
  const [backPath, setBackPath] = useState('');

  useEffect(() => {
    const unsub = history.listen(() => setBackPath(''));
    return () => unsub();
  }, []);

  return <WizardHeaderContext.Provider value={{ backPath, setBackPath }}>{children}</WizardHeaderContext.Provider>;
};

const RouterHeader = () => {
  const { backPath } = useWizardHeaderContext();

  const chooseWhereToGo = useCallback(() => {
    if (!backPath) return history.goBack();
    history.push(backPath);
  }, [backPath]);

  return (
    <div className={classes.header}>
      <Button className={classes.backButton} color='secondary' flat iconLeft='farArrowLeft' onClick={chooseWhereToGo}>
        {t('ProjectSkillWizard:Back')}
      </Button>
      <div className={classes.stepperContainer}>
        <Route path={BASE_WIZARD_PATH + '/skill/skills-select*'} exact component={WizardStepper} />
      </div>
      <IconButton
        className={classes.closeButton}
        name='falTimes'
        flat
        onClick={() => history.push({ pathname: '/' })}
        color='secondary'
      />
    </div>
  );
};

export const WizardRouter = () => {
  return (
    <div className={classes.wizardRouter}>
      <WizardHeaderContextProvider>
        <RouterHeader />

        <Route path={BASE_WIZARD_PATH + '/calls-type-select/graph'} exact component={ChooseGraphEditor} />
        <Route path={BASE_WIZARD_PATH + '/calls-type-select'} exact component={CallsTypeSelect} />
        <Route
          path={BASE_WIZARD_PATH + '*/graph-choose/:templateLanguage/:templateName'}
          exact
          component={GraphWayEnd}
        />
        <Route path={BASE_WIZARD_PATH + '*/graph-choose'} exact component={ChooseGraphEditor} />
        <Route path={BASE_WIZARD_PATH + '*/aimygraph'} exact render={() => <GraphWay aimy={true} />} />
        <Route path={BASE_WIZARD_PATH + '*/jgraph'} exact component={GraphWay} />
        <Route path={BASE_WIZARD_PATH + '/skill/skills-select*'} exact component={WizardSteps} />
        <Route path={BASE_WIZARD_PATH + '/skill'} exact component={SkillWay} />
        <Route path={BASE_WIZARD_PATH + '/'} exact component={WizardStart} />
      </WizardHeaderContextProvider>
    </div>
  );
};
