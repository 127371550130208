import React from 'react';

import { t } from 'localization';
import { useAppSelector } from 'storeHooks';

import WizardTooltip from 'modules/TemplatesWizard/primitives/WizardTooltip';

import styles from './WizardCard.module.scss';

interface ChatGPTTooltipProps {
  targetId: string | HTMLElement | React.RefObject<HTMLElement>;
}
const ChatGPTTooltip = ({ targetId }: ChatGPTTooltipProps) => {
  const { cloudDomains } = useAppSelector(store => ({
    cloudDomains: store.AppConfigReducer.cloudDomains,
  }));
  return (
    <WizardTooltip
      textAlign='left'
      autohide={false}
      placement='top'
      className={styles.chatGPTTooltip}
      style={{ width: 327 }}
      target={targetId}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t('WizardCard:ChatGPTTooltip', `//${cloudDomains.aimylogic.domain}/prices`),
        }}
      />
    </WizardTooltip>
  );
};

export default React.memo(ChatGPTTooltip);
