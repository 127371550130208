const prefix = '@passwordRestore';

export const RESTORE = prefix + 'RESTORE';
export const RESTORE_FULFILLED = prefix + 'RESTORE_FULFILLED';
export const RESTORE_PENDING = prefix + 'RESTORE_PENDING';
export const RESTORE_REJECTED = prefix + 'RESTORE_REJECTED';
export const CLEAN_RESTORE_ERRORS = prefix + 'CLEAN_RESTORE_ERRORS';

export const SET_UP_NEW_PASSWORD = prefix + 'SET_UP_NEW_PASSWORD';
export const SET_UP_NEW_PASSWORD_FULFILLED = prefix + 'SET_UP_NEW_PASSWORD_FULFILLED';
export const SET_UP_NEW_PASSWORD_PENDING = prefix + 'SET_UP_NEW_PASSWORD_PENDING';
export const SET_UP_NEW_PASSWORD_REJECTED = prefix + 'SET_UP_NEW_PASSWORD_REJECTED';

export const REMOVE_RESTORE_ALERT = prefix + 'REMOVE_RESTORE_ALERT';
export const REMOVE_NEW_PASSWORD_ALERT = prefix + 'REMOVE_NEW_PASSWORD_ALERT';
