import React from 'react';
import { Modal } from '@just-ai/just-ui';

import { t } from 'localization';

type PreventLeaveIfUnsavedSkillsModalProps = {
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
};
function PreventLeaveIfUnsavedSkillsModal({ isOpen, onSubmit, onCancel }: PreventLeaveIfUnsavedSkillsModalProps) {
  return (
    <Modal
      title={t('Wizard:PreventLeaveIfUnsavedSkillsModal:Title')}
      buttonSubmitText={t('Wizard:PreventLeaveIfUnsavedSkillsModal:Submit')}
      buttonCancelText={t('Wizard:PreventLeaveIfUnsavedSkillsModal:Cancel')}
      buttonSubmitColor='primary'
      buttonCancelColor='secondary'
      onCancelClick={onCancel}
      onActionClick={onSubmit}
      onClickCloseIcon={onSubmit}
      centered
      isOpen={isOpen}
    >
      <span>{t('Wizard:PreventLeaveIfUnsavedSkillsModal:Description')}</span>
    </Modal>
  );
}

export default React.memo(PreventLeaveIfUnsavedSkillsModal);
