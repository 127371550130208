import React, { useCallback, useMemo, useState } from 'react';
import { ChannelType } from '../dashboardTypes';
import './Bots.scss';
import { uniqBy } from 'lodash';
//@ts-ignore
import { ChannelIcon } from 'altreidsds/dist/build';
import { getChannelIconType } from '../../../constants/channelTypes';
import { Tooltip } from '@just-ai/just-ui';
import localize from '../../../localization';
import { BotDropDownMenu } from './BotDropDownMenu';
import { TelephonyIcon } from '../../../components/TelephonyIcon';
import { Tag } from '../TagsContext';
import {
  ElementsWithHiddenPopup,
  CartAdditionalInfo,
  DeletingCard,
  DeletingList,
  BotInterface,
  LANG_MAP,
  getChannelIconClassName,
} from './botHelpComponents';

const { translate: t } = localize;

function Bot({ bot, selectBot, deleteBot, cloneBot, locale, finalDelete, displayType }: BotInterface) {
  const { shortName, botConfigs, name, id, tagLabels } = bot;
  const [deleting, setDeleting] = useState(false);

  const [hiddenChannels, isResteriskExist] = useMemo(() => {
    let channels: ChannelType[] = [];
    if (Boolean(botConfigs)) {
      channels = botConfigs.filter(x => !['TEST_WIDGET', 'VOICE_TEST_WIDGET'].includes(x.channelType));
    }
    const isResteriskExist = channels.find(i => i.channelType === 'RESTERISK' && i.autoCreated);
    return [
      uniqBy(channels, (x: ChannelType) => x.channelType).filter((x: ChannelType) => x.channelType !== 'RESTERISK'),
      isResteriskExist,
    ];
  }, [botConfigs]);

  const showedChannels = useMemo(() => hiddenChannels.splice(0, 4), [hiddenChannels]);
  const { showedTags, hiddenTags } = useMemo(() => {
    const hiddenTags: Tag[] = tagLabels !== null ? [...tagLabels] : [];
    const showedTags: Tag[] = hiddenTags.splice(0, 3);
    return { showedTags, hiddenTags };
  }, [tagLabels]);

  const onSelect = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      selectBot(shortName);
    },
    [selectBot, shortName]
  );
  const onDelete = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      deleteBot(shortName);
    },
    [deleteBot, shortName]
  );
  const onClone = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      cloneBot(shortName);
    },
    [cloneBot, shortName]
  );

  if (bot.deleting) {
    const props = {
      finalDelete,
      setDeleting,
      pending: bot.pending!,
      deleting,
      id: id,
    };

    return displayType === 'list' ? <DeletingList {...props} /> : <DeletingCard {...props} />;
  }

  const tooltipTarget = `tooltip${id}Target`;
  const isNoChannels = showedChannels.length === 0 && !isResteriskExist;

  // @ts-ignore
  return (
    <div className='bot' onClick={onSelect} data-test-name={`BotItem.Name.${encodeURIComponent(bot?.name)}`}>
      <ElementsWithHiddenPopup
        hidden={hiddenChannels}
        showed={showedChannels}
        displayType={displayType}
        id={`botCardHiddenCHannelId_${id}`}
        renderOptionComponent={(x: ChannelType) => (
          <div className={getChannelIconClassName(x)}>
            <ChannelIcon key={x.id} type={getChannelIconType(x.channelType)} size='medium' />
          </div>
        )}
        additionalElements={
          <>
            {bot.botType === 'CALLS_BOT' && bot.callsBotType === 'DIALER' && isResteriskExist && (
              <TelephonyIcon type='outgoing' />
            )}
            {bot.botType === 'CALLS_BOT' && bot.callsBotType === 'IVR' && bot.resteriskAcceptIncoming && (
              <TelephonyIcon type='incoming' />
            )}
            {isNoChannels && (
              <div id={tooltipTarget}>
                <ChannelIcon key='noicon' type='noicon' size='medium' />
                <Tooltip target={'#' + tooltipTarget} placement='top'>
                  {t('Dashboard: no channels')}
                </Tooltip>
              </div>
            )}
          </>
        }
        className='channels'
      />

      <div className='languageAndControls' onClick={e => e.stopPropagation()} onKeyDown={e => e.stopPropagation()}>
        <span className={`flag-icon flag-icon-${LANG_MAP[bot?.language?.toLowerCase() as 'ru'] || 'ru'} flag`} />
        <BotDropDownMenu
          onDelete={onDelete}
          onClone={onClone}
          tags={tagLabels}
          hasChannels={!isNoChannels}
          shortName={shortName}
        />
      </div>

      <div className='name'>
        <div>
          <span className='nameString'>{name}</span>
          {displayType === 'card' && <span className='shortName'>{shortName}</span>}
        </div>
      </div>
      <ElementsWithHiddenPopup
        className='tags'
        showed={showedTags}
        hidden={hiddenTags}
        displayType={displayType}
        id={`botCardHiddenTagsId_${id}`}
        /*@ts-ignore*/
        renderOptionComponent={(tag: Tag) => (
          <div className='tag' key={tag.tagName}>
            <div className='tag_body'>{tag.tagName}</div>
          </div>
        )}
      />

      <CartAdditionalInfo displayType={displayType} bot={bot} locale={locale} projectShortName={shortName} />
    </div>
  );
}

export default React.memo(Bot);
