import React, { Component } from 'react';
import { withStyles, Card, Grid, Typography, Button, AtreidesIcon, ChannelIcon } from 'altreidsds';
import localize from '../../localization';
import classNames from 'classnames';
import history from '../../appHistory';
import { GA } from '../../pipes/pureFunctions';
import { getChannelTypes } from '../../isAccessFunction';

class PublishNoChannel extends Component {
  state = {
    hidden: true,
    clicked: false,
  };

  popup = null;

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.popup = document.getElementById('publish__no-channel');
  }

  componentWillReceiveProps(nextProps) {
    const { isCloseAlertOpen } = this.props;

    if (isCloseAlertOpen && !nextProps.isCloseAlertOpen) {
      this.setState({
        clicked: false,
      });
    }
  }

  onClose = () => {
    this.popup.classList.add('hidden');
  };

  goToChannels = tab => e => {
    const { currentBot, showCloseAlert, hasUnsavedChanges, setOutsideEvent } = this.props;
    const { clicked } = this.state;
    const channelsUrl = `/channels/${currentBot.shortName}`;
    if (hasUnsavedChanges && !clicked) {
      this.setState({
        clicked: true,
      });

      let event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      const outsideEvent = {
        target: e.target,
        type: event,
      };
      e.stopPropagation();
      e.preventDefault();

      setOutsideEvent(outsideEvent);
      showCloseAlert();
    } else {
      if (tab === 1) {
        GA('GAEvent', 'publish_create_campaign', 'clicked');
      } else {
        GA('GAEvent', 'publish_choose_channel', 'clicked');
      }
      history.push(channelsUrl);
    }
  };

  render() {
    const { classes, currentBot, currentUser } = this.props;
    const { hidden } = this.state;
    return (
      <Card
        id='publish__no-channel'
        className={classNames({ hidden: hidden }, 'publish__no-channel')}
        classes={{ root: classes.alexaAlertModal }}
      >
        <div onClick={this.onClose} className={classes.modalIconClose}>
          <AtreidesIcon name='close' />
        </div>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography className={classes.alexaAlertTitle}>
              {localize.translate('publish no channel title')}
            </Typography>
          </Grid>
          <Grid item xs={12} auto='true'>
            <Grid container spacing={16}>
              {currentBot?.botType === 'CALLS_BOT' ? (
                <Grid item xs={12}>
                  <div className='fake_table'>
                    <div className='header'>{localize.translate('publish no channel to call')}</div>
                    <div className='body'>
                      <div className='channels'>
                        <ChannelIcon type='voicechannel' size='medium' />
                      </div>
                      <Button variant='contained' color='success' size='small' onClick={this.goToChannels(1)}>
                        {localize.translate('Create mailing')}
                      </Button>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <div className='fake_table'>
                    <div className='header'>{localize.translate('publish no channel to ask bot')}</div>
                    <div className='body'>
                      <div className='channels'>
                        {currentBot &&
                          Object.keys(getChannelTypes()[currentBot.language]).map(item => (
                            <ChannelIcon size='medium' key={`channelUniqKey${item}`} type={item} />
                          ))}
                      </div>
                      <Button variant='contained' color='success' size='small' onClick={this.goToChannels(0)}>
                        {localize.translate('Go to channels')}
                      </Button>
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

const styles = theme => ({
  alexaAlertModal: {
    textAlign: 'left',
    width: 318,
    position: 'fixed',
    top: 50,
    right: 20,
    padding: 16,
    zIndex: 1299,
    boxShadow: '0 0 1px 1px rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16);',
  },
  modalIconClose: {
    cursor: 'pointer',
    position: 'absolute',
    top: 16,
    right: 16,
  },
  alexaAlertTitle: {
    fontSize: 16,
    color: 'var(--gray-900)',
    fontWeight: 600,
  },
  alexaAlertInfo: {
    fontSize: 12,
    lineHeight: 1.33,
    color: 'var(--gray-900)',
  },
  rootCheckBoxLabel: {
    marginLeft: -2,
    marginRight: 0,
    marginTop: 6,
    '& .error svg': {
      color: 'var(--danger)',
      fill: 'var(--danger)',
    },
  },
  labelCheckBox: {
    fontSize: 12,
    marginLeft: 5,
    color: 'var(--gray-900)',

    '& .spanLabel': {
      '&.error': {
        color: 'var(--danger)',
      },
      '& a': {
        marginLeft: 3,
      },
    },
  },
});

export default withStyles(styles, { withTheme: true })(PublishNoChannel);
