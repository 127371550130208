export const studioBannerLocalization = {
  'StudioBanner:dashboard:mainText': {
    eng: 'We want our product to attain\nits full utility',
    pt: 'Queremos tornar nosso produto\no mais útil possível para você',
    ru: 'Нам важно ваше мнение',
  },
  'StudioBanner:dashboard:subText': {
    eng: 'Please take a moment to tell us about your\nwants and needs',
    pt: 'Reserve 20 minutos para discutir\nsuas tarefas e seu desejos',
    ru: 'Пожалуйста, заполните короткий опрос о\nработе с Aimylogic',
  },
  'StudioBanner:dashboard:Button': {
    eng: 'Let’s do it!',
    pt: 'Vamos em frente',
    ru: 'Пройти опрос',
  },

  'StudioBanner:channels:mainText': {
    eng: 'Can’t find the integration\nyou want? ',
    pt: 'Não consegue achar a\nintegração que deseja?',
    ru: 'Не нашли нужную интеграцию?',
  },
  'StudioBanner:channels:subText': {
    eng: 'Please fill out the form and we will\nreview your request soon',
    pt: 'Preencha o formulário que analisaremos\nsua solicitação em breve',
    ru: 'Пожалуйста, заполните опрос о необходимых интеграциях',
  },
  'StudioBanner:channels:Button': {
    eng: 'Let us know',
    pt: 'Fale conosco',
    ru: 'Пройти опрос',
  },
  'StudioBanner:dashboard:Button:link:main': {
    eng: 'https://forms.office.com/r/Jncnm577n3',
    pt: 'https://forms.office.com/r/2FemZEHTH3',
  },
  'StudioBanner:channels:Button:link:main': {
    eng: 'https://forms.office.com/r/yqdXp9zd67',
    pt: 'https://forms.office.com/r/3tSYDJjTPH',
  },
  'StudioBanner:dashboard:Button:link:spanish': {
    eng: 'https://calendly.com/dialogstudio-sp/conversacion',
    pt: 'https://calendly.com/dialogstudio-pt-br/falando',
  },
  'StudioBanner:channels:Button:link:main:aimylogic': {
    eng: 'https://forms.office.com/r/7kWPnXGvP0',
    ru: 'https://forms.office.com/r/yvZemN3Rnp',
  },
  'StudioBanner:dashboard:Button:link:main:aimylogic': {
    eng: 'https://forms.office.com/r/vJyBtwR2xS',
    ru: 'https://forms.office.com/r/Pev16vz2PH',
  },

  'StudioBanner:wizardChannels:mainText': {
    eng: 'Need help connecting a bot?',
    ru: 'Нужна помощь с подключением?',
  },
  'StudioBanner:wizardChannels:subText': {
    eng: 'Schedule a free consultation with us to set up and launch a bot for your business',
    ru: 'Запишитесь на бесплатную консультацию по настройке и запуску бота для вашего бизнеса',
  },
  'StudioBanner:wizardChannels:Button': {
    eng: 'Select a time',
    ru: 'Выбрать время',
  },
  'StudioBanner:wizardChannels:Button:link:main:aimylogic': {
    eng: 'https://calendly.com/aimylogic-product/consultation',
    ru: 'https://calendly.com/aimylogic-product/consultation',
  },
};
