const prefix = '@IVR/';

export const FIND_IVR_RESTERISK_CHANNEL = prefix + 'FIND_IVR_RESTERISK_CHANNEL';
export const FIND_IVR_RESTERISK_CHANNEL_FULFILLED = prefix + 'FIND_IVR_RESTERISK_CHANNEL_FULFILLED';
export const FIND_IVR_RESTERISK_CHANNEL_PENDING = prefix + 'FIND_IVR_RESTERISK_CHANNEL_PENDING';
export const FIND_IVR_RESTERISK_CHANNEL_REJECTED = prefix + 'FIND_IVR_RESTERISK_CHANNEL_REJECTED';

export const UPDATE_INTERNAL_RESTERISK_CHANNEL = prefix + 'UPDATE_INTERNAL_RESTERISK_CHANNEL';
export const UPDATE_INTERNAL_RESTERISK_CHANNEL_FULFILLED = prefix + 'UPDATE_INTERNAL_RESTERISK_CHANNEL_FULFILLED';
export const UPDATE_INTERNAL_RESTERISK_CHANNEL_PENDING = prefix + 'UPDATE_INTERNAL_RESTERISK_CHANNEL_PENDING';
export const UPDATE_INTERNAL_RESTERISK_CHANNEL_REJECTED = prefix + 'UPDATE_INTERNAL_RESTERISK_CHANNEL_REJECTED';

export const PUBLISH_IVR_CHANNEL = prefix + 'PUBLISH_IVR_CHANNEL';
export const PUBLISH_IVR_CHANNEL_FULFILLED = prefix + 'PUBLISH_IVR_CHANNEL_FULFILLED';
export const PUBLISH_IVR_CHANNEL_PENDING = prefix + 'PUBLISH_IVR_CHANNEL_PENDING';
export const PUBLISH_IVR_CHANNEL_REJECTED = prefix + 'PUBLISH_IVR_CHANNEL_REJECTED';

export const DELETE_IVR_CHANNEL = prefix + 'DELETE_IVR_CHANNEL';
export const DELETE_IVR_CHANNEL_FULFILLED = prefix + 'DELETE_IVR_CHANNEL_FULFILLED';
export const DELETE_IVR_CHANNEL_PENDING = prefix + 'DELETE_IVR_CHANNEL_PENDING';
export const DELETE_IVR_CHANNEL_REJECTED = prefix + 'DELETE_IVR_CHANNEL_REJECTED';
