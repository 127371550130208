import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { findLast } from 'lodash';
import {
  Button,
  ChannelIcon,
  Checkbox,
  Icon,
  IconButton,
  Spinner,
  useOAuthBehaviour,
  useOAuthContext,
  usePromiseProcessing,
  useToggle,
  useId,
} from '@just-ai/just-ui';
import { IntegrationDto, IntegrationType } from '@just-ai/api/dist/generated/Integrations';

import { t } from 'localization';
import { useAppSelector } from 'storeHooks';
import { RootState } from 'storeTypes';

import { RCurrentUser } from 'types';

import { ManagerNotifyResult } from '../..';
import { useAppContext } from 'contexts/AppContext';

import parentStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
import GoogleSettingsModal from './GoogleSettingsModal';

export function GoogleTablesBlock({
  value,
  onChange,
  isShowErrors,
  errors,
}: {
  value: ManagerNotifyResult['googleSheets'];
  onChange: (integration: ManagerNotifyResult['googleSheets']) => void;
  isShowErrors?: boolean;
  errors: {
    enabled: string;
  };
}) {
  const checkBoxId = useId();
  const onChangeEnabled = useCallback(
    (val: boolean) =>
      onChange({
        ...value,
        enabled: val,
      }),
    [onChange, value]
  );
  const deleteIntegration = useCallback(
    () =>
      onChange({
        enabled: false,
        integrationId: '',
        spreadsheetId: '',
        sheetName: '',
      }),
    [onChange]
  );

  const { integrationsService } = useAppContext();
  const oAuthBehaviour = useOAuthBehaviour();
  const { redirectUrl } = useOAuthContext();
  const { projectShortName, currentUser, currentBot } = useAppSelector((state: RootState) => ({
    projectShortName: state.CurrentProjectsReducer.currentProject as string,
    currentUser: state.CurrentUserReducer.currentUser as RCurrentUser['currentUser'],
    currentBot: state.CurrentProjectsReducer.currentBot,
  }));
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationDto>();
  const [settingsModalOpened, showSettingsModal, closeSettingsModal] = useToggle(false);

  const [getAllIntegrationsState, getAllIntegrations] = usePromiseProcessing(
    () =>
      integrationsService.getAllIntegrations().then(res => {
        return res.data.filter(
          integration =>
            integration.integrationType === ('GOOGLE' as IntegrationType) &&
            integration.projectShortName === projectShortName &&
            integration.accountId === currentUser.account.id
        );
      }),
    {
      deps: [integrationsService],
    }
  );

  const [getListOfSpreadsheetsState, getListOfSpreadsheetsApi] = usePromiseProcessing(
    (integrationId: string) => integrationsService.getListOfSpreadsheets(integrationId).then(res => res.data),
    { deps: [integrationsService] }
  );

  useEffect(() => {
    getAllIntegrations();
  }, [getAllIntegrations]);

  useEffect(() => {
    if (!value.integrationId) return;
    getListOfSpreadsheetsApi(value.integrationId);
  }, [getListOfSpreadsheetsApi, value.integrationId]);

  const currentIntegration = useMemo(() => {
    if (!getAllIntegrationsState.result) return;
    return getAllIntegrationsState.result.find(integration => integration.integrationId === value.integrationId);
  }, [getAllIntegrationsState.result, value.integrationId]);

  const currentSpreadsheet = useMemo(() => {
    if (!getListOfSpreadsheetsState.result) return;
    return getListOfSpreadsheetsState.result.find(spreadsheet => spreadsheet.id === value.spreadsheetId);
  }, [getListOfSpreadsheetsState.result, value.spreadsheetId]);

  const [connectToGoogleState, connectToGoogle] = usePromiseProcessing(
    async () => {
      const { protocol, port, hostname } = window.location;
      const callbackUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${redirectUrl}`;
      const { data: url } = await integrationsService.getGoogleAuthUrl('', callbackUrl);
      const res = await oAuthBehaviour.tryToRequestAccess(url).catch(console.error);

      if (!res) return;
      const integrationsList = await getAllIntegrations();
      const suggestedIntegration = findLast(
        integrationsList,
        integration =>
          integration.integrationType === ('GOOGLE' as IntegrationType) &&
          integration.projectShortName === projectShortName &&
          integration.accountId === currentUser.account.id
      );
      if (!suggestedIntegration) return;
      setSelectedIntegration(suggestedIntegration);
      showSettingsModal();
    },
    { deps: [integrationsService, oAuthBehaviour, getAllIntegrations] }
  );

  const openSettings = useCallback(() => {
    setSelectedIntegration(currentIntegration);
    showSettingsModal();
  }, [currentIntegration, showSettingsModal]);

  return (
    <>
      <span
        className={parentStyles.checkBoxWrapper}
        style={{
          marginTop: currentIntegration ? 12 : '',
        }}
      >
        <Checkbox
          inline
          label={t('Wizard:ManagerNotify:Google:Title')}
          name={checkBoxId}
          checked={value.enabled}
          onChange={onChangeEnabled}
        />
      </span>
      <div className={styles.GoogleTablesBlock}>
        {currentIntegration ? (
          <div className={styles.GoogleTablesBlock__integration_card}>
            <div
              className={cn(styles.GoogleTablesBlock__integration_card__block, styles.googleButton, 'padding-right-3x')}
            >
              <ChannelIcon type='google-char' size='small' />
              <span>{currentIntegration.integrationName}</span>
            </div>
            <div className={cn(styles.GoogleTablesBlock__integration_card__block, styles.cardInfoBlock)}>
              {getListOfSpreadsheetsState.loading && <Spinner size='sm' />}
              {currentSpreadsheet &&
                `${t('Wizard:ManagerNotify:Google:Settings:Table')}: ${currentSpreadsheet.name}, ${t(
                  'Wizard:ManagerNotify:Google:Settings:SheetShort'
                )}: ${value.sheetName}`}
            </div>
            <div className={cn(styles.GoogleTablesBlock__integration_card__block, 'padding-left-3x')}>
              <IconButton name='farCog' onClick={openSettings} flat color='secondary' />
              <IconButton name='farTrashAlt' onClick={deleteIntegration} flat color='secondary' />
            </div>
          </div>
        ) : (
          <div className='flex-row gap-2x'>
            <Button color='secondary' outline className={styles.googleButton} onClick={connectToGoogle}>
              {connectToGoogleState.loading && <Spinner size='sm' />}
              <ChannelIcon type='google-char' size='small' />
              {t('Wizard:ManagerNotify:Google:ConnectButton')}
            </Button>
            {isShowErrors
              ? errors.enabled && (
                  <div className='flex-row gap-1x'>
                    <Icon color='danger' name='faExclamationCircle' />
                    <span>{errors.enabled}</span>
                  </div>
                )
              : value.enabled && (
                  <div className='flex-row gap-1x'>
                    <Icon color='warning' name='faExclamationCircle' />
                    <span>{t('Wizard:ManagerNotify:Warning:Google:EnabledButNotIntegrated')}</span>
                  </div>
                )}
          </div>
        )}
      </div>
      {selectedIntegration && (
        <GoogleSettingsModal
          isOpen={settingsModalOpened}
          onCancel={closeSettingsModal}
          onSubmit={onChange}
          currentBot={currentBot}
          integration={selectedIntegration}
          spreadsheetId={value.spreadsheetId}
          sheetId={value.sheetName}
          allIntegrations={getAllIntegrationsState.result || []}
        />
      )}
    </>
  );
}
