import {
  GET_TOKEN_FULFILLED,
  GET_TOKEN_PENDING,
  GET_TOKEN_REJECTED,
  GET_REFERRALS_FULFILLED,
  GET_REFERRALS_PENDING,
  GET_REFERRALS_REJECTED,
  CREATE_PARTNER_PENDING,
  CREATE_PARTNER_FULFILLED,
  CREATE_PARTNER_REJECTED,
  GET_CURRENT_REWARD_FULFILLED,
  GET_CURRENT_REWARD_PENDING,
  GET_CURRENT_REWARD_REJECTED,
  FETCH_PARTNER_DATA_FULFILLED,
} from '../../constants/referrals.actions';

import localize from '../../localization';

const InitialState = {
  errors: [],
  fetching: false,
  token: null,
  referrals: [],
  enabled: false,
  id: null,
  referralToken: null,
  partnerData: null,
};

export default function ReferralsReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_TOKEN_FULFILLED: {
      return {
        ...state,
        enabled: action.payload.data.enabled,
        id: action.payload.data.id,
        referralToken: action.payload.data.referralToken,
        fetching: action.payload.data.fetching,
      };
    }

    case GET_TOKEN_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_REFERRALS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        referrals: action.payload.data,
      };
    }

    case GET_REFERRALS_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case CREATE_PARTNER_FULFILLED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_CURRENT_REWARD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        reward: action.payload.data.reward,
      };
    }

    case GET_CURRENT_REWARD_REJECTED:
    case CREATE_PARTNER_REJECTED: {
      const errors = [localize.translate('Create partner error')];

      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case GET_CURRENT_REWARD_PENDING:
    case CREATE_PARTNER_PENDING:
    case GET_REFERRALS_PENDING:
    case GET_TOKEN_PENDING: {
      return {
        ...state,
        errors: InitialState.errors,
        fetching: true,
      };
    }

    case FETCH_PARTNER_DATA_FULFILLED: {
      return {
        ...state,
        partnerData: action.payload.data,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
