import React, { useCallback, useMemo, useEffect } from 'react';
import { debounce, isArray, isEmpty, isEqual, mergeWith } from 'lodash';
import { FAQApi } from '@just-ai/api/dist/generated/Caila';
import { useForceUpdate, useToggle } from '@just-ai/just-ui';

import { t } from 'localization';

import getByPath from 'helpers/object/getByPath';
import { MenuSkillConfig, RealSkill, WizardState } from 'modules/TemplatesWizard/types';
import SkillCollapseContainer from 'modules/TemplatesWizard/primitives/SkillCollapseContainer';
import { combineMerge } from 'modules/TemplatesWizard';

import { BaseSkill, InitSkillArgs, ViewComponentProps } from '../BaseSkill';
import { MenuItemConfig, MenuItems, RowTableType, RowType } from './types';
import MenuTable from './MenuTable';

export function filterAvailableMenuOptions(
  availableMenuItems: MenuItems,
  existedMenuItemsWithoutCurrent: RowTableType[]
) {
  return Object.keys(availableMenuItems).reduce((acc, optionKey) => {
    const options = availableMenuItems[optionKey].filter(
      option =>
        !existedMenuItemsWithoutCurrent.find(el =>
          option.type === 'transition'
            ? el.action === optionKey
            : el.action === optionKey && el.botAction === option.title
        )
    );
    if (options.length === 0) return acc;
    acc[optionKey] = options;
    return acc;
  }, {} as MenuItems);
}

interface MenuSkillVewProps extends ViewComponentProps {
  skill: MenuSkill;
}
const MenuSkillVew = React.memo(({ skill, index }: MenuSkillVewProps) => {
  const forceUpdate = useForceUpdate();
  useEffect(() => skill.subscribe('forceUpdate', forceUpdate), [forceUpdate, skill]);
  const [isShowErrors, showError, hideError] = useToggle(false);

  const onValidate = useCallback(() => {
    const isValid = skill.isValid;
    !isValid ? showError() : hideError();
    return isValid;
  }, [hideError, showError, skill]);

  const onChangeInner = useCallback(
    (menuData: RowTableType[]) => {
      skill.menuUpdate(menuData);
      if (skill.isValid) hideError();
    },
    [hideError, skill]
  );

  const rows = useMemo<RowTableType[]>(
    () => skill.transformRowToTableRow(skill.initialValues || [], skill.availableMenuItems),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skill.availableMenuItems]
  );

  if (isEmpty(skill.availableMenuItems)) return null;
  return (
    <SkillCollapseContainer onValidate={onValidate} skill={skill} index={index}>
      <div style={{ marginTop: 8, marginBottom: 20 }}>{t('TemplatesWizard:Menu:Description')}</div>
      <MenuTable
        initialRows={rows}
        isShowErrors={isShowErrors}
        availableMenuItems={skill.availableMenuItems}
        maxItems={skill.maxMenuItems}
        onChange={onChangeInner}
      />
    </SkillCollapseContainer>
  );
});

const getButtonNameForFixedMenuItems = (key: string): string => {
  const namesMap: Record<string, any> = {
    [RealSkill.ORDER]: t('MenuSkill:Fixed:Order:ButtonText'),
    [RealSkill.SERVICE_EVALUATION]: t('MenuSkill:Fixed:ServiceEvaluation:ButtonText'),
    [RealSkill.COLLECTING_CONTACTS]: t('MenuSkill:Fixed:CollectingContacts:ButtonText'),
    operator: t('MenuSkill:Operator:ButtonText'),
  };
  return namesMap[key as RealSkill] ?? '';
};

export class MenuSkill extends BaseSkill {
  public fixedMenuItems: RowType[] = [];
  public shouldBeAddedOnInitMenuItems: RowType[] = [];
  public availableMenuItems: Record<string, MenuItemConfig[]> = {};
  public menuData: RowTableType[] = [];
  private faqApi: FAQApi;
  public readonly maxMenuItems = 8;

  constructor(public skillConfig: MenuSkillConfig) {
    super(skillConfig);
    this.faqApi = new FAQApi({ basePath: '/cailapub' });
  }

  init(args: InitSkillArgs) {
    super.init(args);
    this.menuData = args.initialValues || [];
  }

  onStateChange = debounce((state: WizardState) => {
    this.recalculateAvailableMenuItems(state);
  }, 600);

  ViewComponent = (props: ViewComponentProps) => {
    return <MenuSkillVew skill={this} {...props} />;
  };

  public transformTableRowToRow(menuData: RowTableType[]): RowType[] {
    return menuData
      .map(menuItem => {
        const rowInfo = this.availableMenuItems[menuItem.action]?.find(el => el.title === menuItem.botAction);
        if (!rowInfo) return null;
        return {
          buttonName: menuItem.buttonName,
          type: rowInfo.type,
          value: rowInfo.value,
        } as RowType;
      })
      .filter(Boolean) as RowType[];
  }

  public transformRowToTableRow(rows: RowType[], availableMenuItems: Record<string, MenuItemConfig[]>) {
    const rowsWithFixed = this.mergeFixedToCurrentRows(rows);
    const rowsWithOnceAdded = this.mergeItemThatShouldBeAddedOnceToCurrentRows(rowsWithFixed);
    return rowsWithOnceAdded
      .map((row: RowType) => {
        let action: string | undefined;
        let botAction: string | undefined;
        switch (row.type) {
          case 'transition':
            for (let actionName in availableMenuItems) {
              availableMenuItems[actionName].find(item => {
                const searchedMenuItem = item.type === 'transition' && item.value === row.value;
                if (searchedMenuItem) action = actionName;
                return searchedMenuItem;
              });
            }
            botAction = t('TemplatesWizard:Menu:Fields:BotAction:Transition');
            break;
          case 'question':
            action = t('TemplatesWizard:Menu:Fields:Action:Question');
            const questionMenuItem = availableMenuItems[t('TemplatesWizard:Menu:Fields:Action:Question')]?.find(
              item => item.value === row.value
            );
            botAction = questionMenuItem ? questionMenuItem.value : '';
            break;
        }

        if (!action || !botAction) return null;

        return {
          buttonName: row.buttonName,
          action,
          botAction,
          isFixed: row.isFixed,
        } as RowTableType;
      })
      .filter(Boolean) as RowTableType[];
  }

  private mergeFixedToCurrentRows(rows: RowType[]): RowType[] {
    const mergedRows: RowType[] = [...rows];
    this.fixedMenuItems.forEach(fixedItem => {
      const founded = rows.find(row => row.type === fixedItem.type && row.value === fixedItem.value);
      if (founded) {
        founded.isFixed = true;
        return;
      }
      mergedRows.push(fixedItem);
    });
    return mergedRows;
  }

  private mergeItemThatShouldBeAddedOnceToCurrentRows(rows: RowType[]): RowType[] {
    if (this.initialValues === undefined) {
      const mergedRows: RowType[] = [...rows];
      this.shouldBeAddedOnInitMenuItems.forEach(item => {
        const founded = rows.find(row => row.type === item.type && row.value === item.value);
        if (founded) {
          founded.isFixed = true;
          return;
        }
        mergedRows.push(item);
      });
      return mergedRows;
    }
    return rows;
  }

  private recalculateAvailableMenuItems(state: WizardState) {
    const skillsWithMenu = this.otherSkills.map(skill => skill.skillConfig);

    const fixedMenuItems: RowType[] = [];
    const shouldBeAddedOnInitMenuItems: RowType[] = [];
    const newAvailableMenuItems = skillsWithMenu.reduce((acc, skill) => {
      if (!skill.menuType) return acc;
      if (skill.menuType === 'transition') {
        const item = {
          title: t('TemplatesWizard:Menu:Fields:BotAction:Transition'),
          type: 'transition',
          value: skill.startState,
          isFixed: skill.fixedInMenu,
        } as MenuItemConfig;
        // eslint-disable-next-line prettier/prettier
        (acc[skill.title] ??= []).push(item);

        if (skill.addToMenuOnce) {
          shouldBeAddedOnInitMenuItems.push({
            buttonName: getButtonNameForFixedMenuItems(skill.skillName),
            type: 'transition',
            value: skill.startState,
          });
        }
        if (skill.fixedInMenu) {
          fixedMenuItems.push({
            buttonName: getButtonNameForFixedMenuItems(skill.skillName),
            type: 'transition',
            value: skill.startState,
            isFixed: true,
          });
        }

        return acc;
      }
      if (skill.menuType === 'question' && skill.questionPath) {
        const skillOuterState = mergeWith(state.default[skill.skillName], state.values[skill.skillName], combineMerge);
        let list: string[] = [];
        if (isArray(skill.questionPath)) {
          for (let path of skill.questionPath) {
            list.push(...(getByPath<string[]>(skillOuterState, path) || []));
          }
        } else {
          list = getByPath<string[]>(skillOuterState, skill.questionPath) || [];
        }
        if (list.length) {
          const questions = list.map(
            text =>
              ({
                title: text,
                type: 'question',
                value: text,
              } as MenuItemConfig)
          );
          // eslint-disable-next-line prettier/prettier
          (acc[t('TemplatesWizard:Menu:Fields:Action:Question')] ??= []).push(...questions);
        }
        return acc;
      }
      return acc;
    }, {} as Record<string, MenuItemConfig[]>);

    this.shouldBeAddedOnInitMenuItems = shouldBeAddedOnInitMenuItems;
    if (!isEqual(fixedMenuItems, this.fixedMenuItems)) {
      this.fixedMenuItems = fixedMenuItems;
      this.forceUpdate();
    }
    if (!isEqual(newAvailableMenuItems, this.availableMenuItems)) {
      this.availableMenuItems = newAvailableMenuItems;
      this.forceUpdate();
    }
  }

  menuUpdate(menuData: RowTableType[]) {
    this.menuData = menuData;
    this.notify('onFieldsChange', { value: this.transformTableRowToRow(menuData) });
    this.isValid = !this.menuData.find(el => el.buttonName === '' || el.action === '' || el.botAction === '');
    if (!this.isValid) {
      this.isDone = false;
      this.forceUpdate();
    }
  }
}
