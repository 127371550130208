import React, { MouseEvent, useCallback, useRef } from 'react';
import cn from 'classnames';
import { Button, ClickAwayListener, Icon, Popover, useToggle } from '@just-ai/just-ui';

import { t } from 'localization';

import { GA } from 'pipes/pureFunctions';

import { SkillConfig } from 'modules/TemplatesWizard/types';

import ChatGPTCircle from './ChatGPTCircle';
import ChatGPTTooltip from './ChatGPTTooltip';

import styles from './WizardCard.module.scss';

type WizardCardProps = {
  skillId: string;
  title: string;
  description?: string;
  onSelect: (skillId: string) => void;
  selected?: boolean;
  canFix?: boolean;
  hideTestButton?: boolean;
  startTest?: (skillId: string) => unknown;
  started?: boolean;
  disabled?: boolean;
  isSpecial?: boolean;
  withChatGPT?: boolean;
  denyCauses?: SkillConfig[];
  forceSelect?: () => void;
};
export const WizardCard = React.memo(
  ({
    skillId,
    title,
    description,
    startTest,
    onSelect,
    started,
    selected,
    disabled,
    hideTestButton,
    denyCauses,
    canFix,
    isSpecial,
    withChatGPT,
    forceSelect,
  }: WizardCardProps) => {
    const [warningTooltipOpened, , closeTooltip, toggleTooltip] = useToggle(false);

    const cardRef = useRef<HTMLDivElement>();
    const handleStartTest = useCallback(
      (ev: MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        GA(undefined, 'Skill_Test', undefined, skillId);
        return startTest?.(skillId);
      },
      [skillId, startTest]
    );
    const handleOnSelect = useCallback(() => {
      if (disabled) {
        toggleTooltip();
        return;
      }
      onSelect(skillId);
    }, [disabled, onSelect, skillId, toggleTooltip]);

    const forceSelectHandler = useCallback(() => {
      closeTooltip();
      forceSelect?.();
    }, [closeTooltip, forceSelect]);

    const tooltipTarget = `skillCard-${skillId}`;
    return (
      <>
        <div
          ref={cardRef as any}
          className={cn(styles.WizardCard, {
            [styles.WizardCard_Selected]: selected,
            [styles.WizardCard_Disabled]: disabled,
          })}
          onClick={handleOnSelect}
          id={tooltipTarget}
          data-test-id={tooltipTarget}
        >
          {isSpecial && <div className={styles.specialMark}>{t('WizardCard:SpecialMark')}</div>}
          <Icon
            name={selected ? 'farCheckCircle' : 'farCircle'}
            color={selected ? 'primary' : 'secondary'}
            wrapperClassName={styles.WizardCard_Icon}
          />
          <h2 className={cn('mt-2 mb-3', styles.WizardCard_Title)}>{title}</h2>
          <p className={styles.WizardCard_Description}>{description}</p>
          {withChatGPT && (
            <>
              <ChatGPTCircle />
              {!warningTooltipOpened && <ChatGPTTooltip targetId={tooltipTarget} />}
            </>
          )}
          {!hideTestButton && !withChatGPT && (
            <Button
              iconLeft={started ? 'farStopCircle' : 'farPlayCircle'}
              color='primary'
              outline
              onClick={handleStartTest}
              className={styles.WizardCard_Button}
              size='sm'
            >
              {started ? t('WizardCard:Button_Started') : t('WizardCard:ButtonDefault')}
            </Button>
          )}
          {withChatGPT && <span className={styles.WizardCard_hint}>{t('WizardCard:ChatGPR:Hint')}</span>}
        </div>
        {!!denyCauses?.length && (
          <ClickAwayListener
            handleEnabled={warningTooltipOpened}
            handleClickOut={closeTooltip}
            refs={[cardRef.current]}
          >
            <Popover
              isOpen={warningTooltipOpened}
              placement='top'
              target={tooltipTarget}
              className='header-menu-popover header-menu-popover-user Header_name_popover'
            >
              <div className={cn(styles.WizardCard__tooltip)}>
                <div className={styles.tooltipText}>
                  {t('WizardCard:DisableHint', denyCauses.map(skill => `"${skill.title}"`).join(', '))}
                </div>
                {canFix && (
                  <Button color='warning' onClick={forceSelectHandler}>
                    {t('WizardCard:Button:UnselectAllSkills')}
                  </Button>
                )}
              </div>
            </Popover>
          </ClickAwayListener>
        )}
      </>
    );
  }
);
