import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon, IconButton, Modal } from '@just-ai/just-ui';
import React, { useCallback, Component } from 'react';
import './BotDropDownMenu.scss';
import localize from '../../../localization';
import { JustSelect, Button } from '@just-ai/just-ui';
import { OptionType } from '@just-ai/just-ui/dist/JustSelect/types';
import { LabelComponentType } from '@just-ai/just-ui/dist/JustSelect/JustSelectItem';
import classNames from 'classnames';
import { createOptionFromTag, Tag, TagsContext, TagsList } from '../TagsContext';
import { useMemo } from 'react';
import history from '../../../appHistory';

const { translate: t } = localize;

export type BotDropDownMenuProps = {
  onDelete: (event: React.SyntheticEvent) => unknown;
  onClone: (event: React.SyntheticEvent) => unknown;
  tags: TagsList;
  shortName: string;
  hasChannels: boolean;
};

export const LabelComponent: LabelComponentType = ({ label, value, onDelete, allTags, values, options }) => {
  const deleteLabel = useCallback(
    e => {
      e.stopPropagation();
      onDelete(value);
    },
    [onDelete, value]
  );

  const firstLetter = useMemo(() => {
    if (value !== 'new_value') {
      const foundTagIndex = allTags.findIndex((tag: Tag) => tag.id === value);
      if (foundTagIndex > -1) {
        const groupLetter = allTags[foundTagIndex].tagName[0].toLowerCase();
        const groupLetterOption: Tag = allTags.find(
          (tag: Tag) => tag.groupLetter && tag.groupLetter.toLowerCase() === groupLetter
        );
        let groupLetterOptionId = -1;
        if (groupLetterOption) {
          groupLetterOptionId = groupLetterOption.id as number;
        }
        const isOptionAlreadyInValue = values.indexOf(groupLetterOptionId) > -1;

        if (allTags[foundTagIndex].groupLetter && !isOptionAlreadyInValue) {
          return allTags[foundTagIndex].groupLetter;
        }
        if (isOptionAlreadyInValue) {
          let firstLetter = allTags[foundTagIndex].tagName[0];
          const optionsWithThisFirstLetter = (options as OptionType[])
            .filter(option => values.indexOf(option.value) === -1)
            .filter(option => (option.label as string)[0].toLowerCase() === firstLetter.toLowerCase());
          if (optionsWithThisFirstLetter.length > 0) {
            if (value === optionsWithThisFirstLetter[0].value) {
              return firstLetter;
            }
          }
        }
      }
    }
    return null;
  }, [value, allTags, values, options]);

  if (value === 'new_value')
    return (
      <div className='just-select-item__new_value_content'>
        <div>
          {t('LabelComponent:Create element')} <b>"</b>
          <b className='just-select-item__new_value_value'>{label}</b>
          <b>"</b>
        </div>
        <div>{t('LabelComponent:Press Enter')}</div>
      </div>
    );
  return (
    <div className='Just-select-label-with-delete'>
      <div className='Just-select-label-with-delete-firstLetter'>{(firstLetter || '').toUpperCase()}</div>
      <div className='Just-select-label-text'>{label}</div>
      {typeof value === 'number' && onDelete && <IconButton name='fasTimes' onClick={deleteLabel} />}
    </div>
  );
};

const LabelComponentEmptyList: LabelComponentType = () => {
  return (
    <div className='LabelEmptyList'>
      <div className='LabelEmptyList-title'>{t('LabelEmptyList-title')}</div>
      <div className='LabelEmptyList-text'>{t('LabelEmptyList-text')}</div>
    </div>
  );
};

class BotDropDownMenuState {
  options: OptionType[] = [];
  values: OptionType['value'][] = [];
  openTagsModal: boolean = false;
}

export class BotDropDownMenu extends Component<BotDropDownMenuProps, BotDropDownMenuState> {
  static contextType = TagsContext;

  state = new BotDropDownMenuState();
  componentDidUpdate(prevProps: BotDropDownMenuProps, prevState: BotDropDownMenuState) {
    if (!prevState.openTagsModal && this.state.openTagsModal) {
      this.setState({
        values: this.props.tags.map(tag => tag.id),
        options: createOptionFromTag(this.context.allTags),
      });
    }
  }

  closeModal = () => {
    this.setState({
      openTagsModal: false,
    });
  };

  openModal = () => {
    this.setState({
      openTagsModal: true,
    });
  };

  saveTags = () => {
    const { updateProjectTags } = this.context;
    const values = this.state.values;
    updateProjectTags(this.props.shortName, values);
    this.setState({
      openTagsModal: false,
    });
  };

  onInput = async (searchValue: string) => {
    if (!searchValue?.trim()) return;
    const { options } = this.state;
    const match = options.filter(x => (x.label as string).toLowerCase() === searchValue.toLowerCase());
    let newOptions =
      options.findIndex(x => x.value === 'new_value') > -1
        ? options.filter(x => x.value !== 'new_value')
        : [...options];

    if (searchValue && match.length === 0 && this.state.values.length < 5) {
      newOptions.splice(0, 0, {
        label: searchValue,
        value: 'new_value',
      });
      newOptions = [...newOptions];
    }
    this.setState({ options: newOptions });
  };

  deleteTag = (id: Tag['id']) => {
    let newOptions = [...this.state.options].filter(option => option.value !== id);
    let newValues = [...this.state.values].filter(value => value !== id);
    this.setState({
      values: [...newValues],
      options: [...newOptions],
    });
    this.context.deleteAccountTag(id);
  };

  onChange = async (values: OptionType['value'][] | null) => {
    let newOptions = this.state.options;

    let hasNewValue = (values || []).findIndex(value => value === 'new_value') > -1;
    let newValues = (values || []).filter(value => value !== 'new_value');

    if (hasNewValue) {
      const newValueIndex = this.state.options.findIndex(option => option.value === 'new_value');
      if (newValueIndex > -1) {
        await this.context.createTagInAccount(this.state.options[newValueIndex].label).then((data: Tag) => {
          newOptions = createOptionFromTag(this.context.allTags);
          newValues = newValues.concat([data.id]);
        });
      }
    }

    if (newValues.length !== this.state.values.length) {
      this.setState({
        values: newValues,
        options: newOptions.filter(x => x.value !== 'new_value'),
      });
    }
  };

  render() {
    return (
      <>
        <DropdownButton
          direction='right'
          onClick={e => e.stopPropagation()}
          className='bot-menu-dropdown'
          data-test-id='Bot.Item.Dropdown'
        >
          <DropdownToggle color='none' iconButton size='md' className='btn-round'>
            <Icon name='farEllipsisV' color='none' size='md' className='btn-icon' />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.props.onClone}>{t('BotMenu Duplicate')}</DropdownItem>
            {!this.props.hasChannels && (
              <DropdownItem onClick={() => history.push(`channels/${this.props.shortName}`)}>
                {t('Publish')}
              </DropdownItem>
            )}
            <DropdownItem onClick={this.openModal}>{t('BotMenu ChangeTags')}</DropdownItem>
            <DropdownItem divider />
            <DropdownItem danger onClick={this.props.onDelete} data-test-id='Bot.Item.Dropdown.Delete'>
              {t('Delete bot')}
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
        <Modal
          isOpen={this.state.openTagsModal}
          title={t('TagsModal bot tags')}
          className='TagsModalModal'
          disableActionButtonAutoFocus
          disableKeyboard
          customFooter={
            <div className='modal-base-buttons'>
              <Button
                size='md'
                color='primary'
                outline
                onClick={this.closeModal}
                data-test-id='TagsModal-cancel'
                type='button'
              >
                {t('Cancel')}
              </Button>
              <Button size='md' color='primary' data-test-id='TagsModal-submit' type='button' onClick={this.saveTags}>
                {t('Save')}
              </Button>
            </div>
          }
        >
          <div className='TagsModalBody_wrapper'>
            <div className='TagsModalBody'>
              <div
                key={this.state.values.length}
                className={classNames('TagsModalBody_container', { empty: this.state.options.length === 0 })}
              >
                <JustSelect
                  options={this.state.options}
                  inputPlaceholder={t('TagsModalSelectPlaceholder')}
                  fullWidth
                  multiple
                  autoFocus
                  onChange={this.onChange}
                  onInput={this.onInput}
                  value={this.state.values}
                  open={this.state.openTagsModal}
                  optionComponent={this.state.options.length === 0 ? LabelComponentEmptyList : LabelComponent}
                  messages={{
                    NOT_FOUND: t('BotControls:not_found'),
                    EMPTY: '',
                  }}
                  optionComponentProps={{
                    onDelete: this.deleteTag,
                    allTags: this.context.allTags,
                    values: this.state.values,
                    options: this.state.options,
                  }}
                  maxSelected={5}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
