import React, { createContext, useContext, PureComponent } from 'react';
import { axios } from '../../pipes/functions';
import { Spinner } from '../../components';

import { OptionType } from '@just-ai/just-ui/dist/JustSelect/types';
import { TagLabelDto, TagsApi } from '@just-ai/analytic-front';

export type TagsContextType = {
  createTagInAccount: (tagName: string) => Promise<Tag>;
  getAccountAllTags: () => Promise<void>;
  updateProjectTags: (projectShortName: string, tagsListIds: Tag['id'][]) => unknown;
  deleteAccountTag: (id: Tag['id']) => unknown;
  allTags: TagsList;
};

export const TagsContext = createContext({} as TagsContextType);

class State {
  isLoading: boolean = false;
  allTags: TagLabelDto[] = [];
}

export type TagsProviderProps = {
  accountId: number;
  updateProjectTags?: (projectShortName: string, tagsList: Tag[]) => unknown;
  deleteAccountTag?: (tag: Tag['id']) => unknown;
};

export type LoadCallback = <TResult>(promise: Promise<TResult>) => Promise<TResult>;

export interface Tag extends TagLabelDto {
  groupLetter?: string;
  [key: string]: any;
}

export type TagsList = Tag[];

const byFirstLetters: (a: Tag, b: Tag) => number = (prev, next) => {
  let prevFirstLetter = prev.tagName.toLowerCase()[0];
  let nextFirstLetter = next.tagName.toLowerCase()[0];

  return prevFirstLetter?.localeCompare(nextFirstLetter);
};
const sortAlphabeticallyAndSetGroup = (tags: TagsList): TagsList => {
  return tags.sort(byFirstLetters).reduce((resultArray, currItem, index, arrayOfSorted) => {
    if (index === 0 || currItem.tagName[0] !== arrayOfSorted[index - 1].tagName[0]) {
      currItem.groupLetter = currItem.tagName[0];
    }
    resultArray.push(currItem);
    return resultArray;
  }, [] as TagsList);
};

export class TagsProvider extends PureComponent<TagsProviderProps, State> {
  state = new State();
  // @ts-ignore
  tagsApi = new TagsApi({}, '', axios);

  load: LoadCallback = promise => {
    this.setState({ isLoading: true });
    return promise.finally(() => this.setState({ isLoading: false }));
  };

  createTagInAccount = async (tagName: string): Promise<Tag> => {
    const { data } = await this.load(this.tagsApi.createTagForAccount(this.props.accountId, { tagName: tagName }));
    let newTags = this.state.allTags.map(tag => ({
      id: tag.id,
      tagName: tag.tagName,
    }));
    newTags.splice(0, 0, data);
    this.setState(prevState => ({
      ...prevState,
      allTags: sortAlphabeticallyAndSetGroup(newTags),
    }));

    return data;
  };

  getAccountAllTags = () => {
    return this.load(
      this.tagsApi.getAllTagsForAccount(this.props.accountId).then(({ data }) => {
        this.setState({
          allTags: sortAlphabeticallyAndSetGroup(data),
        });
      })
    );
  };

  updateProjectTags = (projectShortName: string, tagsListIds: Tag['id'][]) => {
    const tagsLists = tagsListIds
      .map(tagId => this.state.allTags.find(allTag => allTag.id === tagId))
      .filter(tag => Boolean(tag));

    //@ts-expect-error filter above saves from undefined
    this.props.updateProjectTags && this.props.updateProjectTags(projectShortName, tagsLists);
    this.tagsApi.updateTagsForProject(
      this.props.accountId,
      projectShortName,
      tagsLists.map(tag => tag!.id)
    );
  };

  deleteAccountTag = (id: Tag['id']) => {
    this.props.deleteAccountTag && this.props.deleteAccountTag(id);
    this.setState({
      allTags: sortAlphabeticallyAndSetGroup(this.state.allTags.filter(tag => tag.id !== id)),
    });
    return this.load(this.tagsApi.deleteTagForAccount(this.props.accountId, id));
  };

  render() {
    return (
      <TagsContext.Provider
        value={{
          createTagInAccount: this.createTagInAccount,
          getAccountAllTags: this.getAccountAllTags,
          updateProjectTags: this.updateProjectTags,
          deleteAccountTag: this.deleteAccountTag,
          allTags: this.state.allTags,
        }}
      >
        {this.props.children}
        {this.state.isLoading ? <Spinner /> : null}
      </TagsContext.Provider>
    );
  }
}

export const useTagsContext = () => useContext(TagsContext);

export const createOptionFromTag = (tags: TagsList): OptionType[] => {
  return tags.map(tag => ({ label: tag.tagName, value: tag.id }));
};
