import React, { PureComponent } from 'react';
import { getAvailableTariffChannels } from 'isAccessFunction';
import classNames from 'classnames';
import classes from './Channels.module.scss';
import { ChannelIcon } from '@just-ai/just-ui';
import { getChannelIconType } from 'constants/channelTypes';
import ProjectService from '../../../modules/ProjectsApi/service/ProjectService';

export default class AddChannelCard extends PureComponent {
  state = {
    bitrixOpenTooMuchChannels: false,
    BotProjectsService: new ProjectService(this.props.accountId || -1),
  };

  handleClick = async () => {
    const { currentUserTariff, channelsCount, channel, toggleBitrixTooMuchChannelsModal, toggleBitrixNoAppExistModal } =
      this.props;

    if (currentUserTariff.tariffUniqueName === 'community' && channelsCount > 0) return false;

    if (channel.type === 'BITRIX') {
      const { loadProjects } = this.props;
      const { getProjects } = this.state.BotProjectsService;
      const {
        action: {
          payload: { data: projects },
        },
      } = await loadProjects(() => getProjects());

      const existsBitrixChannel = projects.some(project => Boolean(project.existsBitrixChannel));
      const bitrixChannelsCount = projects.reduce((count, project) => {
        const bitrixChannels = project.botConfigs.filter(botConfig => botConfig.channelType === 'BITRIX');
        return count + bitrixChannels.length;
      }, 0);

      if (existsBitrixChannel && bitrixChannelsCount < 5) {
        channel.createAction();
      } else if (existsBitrixChannel) {
        toggleBitrixTooMuchChannelsModal();
      } else {
        toggleBitrixNoAppExistModal();
      }
    } else {
      channel.createAction();
    }
  };

  render() {
    const {
      channel,
      disabled,
      currentUserTariff,
      appConfig: { zenflow },
    } = this.props;

    let tariffUniqueName = currentUserTariff?.tariffUniqueName;
    if (tariffUniqueName?.indexOf('archived') > 0) {
      tariffUniqueName = tariffUniqueName.slice(0, tariffUniqueName.indexOf('archived') - 1);
    }

    const availableTariffChannelsValue = getAvailableTariffChannels()[currentUserTariff ? tariffUniqueName : 'yoga'];
    let disabledByTariff = availableTariffChannelsValue?.includes(channel.type) === false;
    return (
      <>
        <button
          className={classNames(classes.card, (disabled || disabledByTariff) && classes.disabledCard)}
          onClick={!disabled && !disabledByTariff ? this.handleClick : undefined}
          data-test-id={`ChannelsAdding.addCard.${channel.type}`}
        >
          <ChannelIcon type={getChannelIconType(channel.type)} size='medium' isEuroInstance={zenflow.euroInstance} />
          <p className={classes.disabledText}>{channel.label}</p>
        </button>
      </>
    );
  }
}
