import React from 'react';

import { t } from '../../../localization';
import { Typography, withStyles, Grid, Button, Slide, Modal } from 'altreidsds';
import { isEuroInstance } from '../../../isAccessFunction';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class FeedbackModal extends React.Component {
  form = {};
  state = {
    disableSendBtn: true,
  };

  render() {
    const { classes, onClose, open } = this.props;
    return (
      <Modal
        disableBackdropClick={true}
        key='feedback_Dialog'
        open={open}
        onClose={onClose}
        title={t('Feedback')}
        modalBottom={
          <Grid container spacing={8} justify='flex-end'>
            <Grid item></Grid>
            <Grid item>
              <Button onClick={onClose}>{t('Cancel')}</Button>
            </Grid>
          </Grid>
        }
      >
        <Typography>
          {t('If you have any questions, or idea, write to our service desc', t(isEuroInstance() ? 'tovi' : 'aimy'))}{' '}
          <a
            href='https://just-ai.atlassian.net/servicedesk/customer/portal/2'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('Technical support')}
          </a>{' '}
          {t('or connect to our')}{' '}
          <a href='https://t.me/aimylogic' target='_blank' rel='noopener noreferrer'>
            {t('chat in Telegram')}
          </a>{' '}
          {t('in search type "Aimylogic"')}
        </Typography>
      </Modal>
    );
  }
}

const styles = theme => ({
  dialog: {
    paddingTop: '0 !important',
  },
  paper: {
    border: 'solid 1px var(--gray-300)',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: 'var(--gray-700)',
    padding: '30px 24px 20px 24px',
  },
  icon: {
    top: '30px',
    color: 'var(--gray-400)',
    right: '16px',
    cursor: 'pointer',
    position: 'absolute',
  },
  actions: {
    margin: '0 20px 30px 20px',
  },
});

export default withStyles(styles, { withTheme: true })(FeedbackModal);
