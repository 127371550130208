export const chatwidgeteditorLocalization = {
  'ChatWidgetEditor Color Themes label': {
    eng: 'Visual design',
    ru: 'Оформление',
    pt: 'Design visual',
  },
  'ChatWidgetEditor Reset Settings': {
    eng: 'reset settings',
    ru: 'сброс настроек',
    pt: 'redefinir configurações',
  },
  'ChatWidgetEditor Chat Header label': {
    eng: 'Chat title',
    ru: 'Заголовок чата',
    pt: 'Título do chat',
  },
  'ChatWidgetEditor Chat Name label': {
    eng: 'Chat window',
    ru: 'Окно чата',
    pt: 'Janela do chat',
  },
  'ChatWidgetEditor Chat Send label': {
    eng: 'Message sending',
    ru: 'Отправка сообщений',
    pt: 'Envio de mensagens',
  },
  'ChatWidgetEditor Chat Position label': {
    eng: 'Location',
    ru: 'Расположение',
    pt: 'Localização',
  },
  'ChatWidgetEditor Chat Chat Involving label': {
    eng: 'Involvement in conversation',
    ru: 'Вовлечение в диалог',
    pt: 'Envolvimento na conversa',
  },
  'ChatWidgetEditor Chat Additional Settings label': {
    eng: 'Additional settings',
    ru: 'Дополнительные настройки',
    pt: 'Configurações adicionais',
  },
  'ChatWidgetEditor Chat Set up label': {
    eng: 'Installation',
    ru: 'Установка',
    pt: 'Instalação',
  },
  'ChatWidgetEditor blinkOnNewMessage': {
    eng: 'Display new message notification',
    ru: 'Показывать новое сообщение миганием вкладки',
    pt: 'Exibir nova notificação de mensagem',
  },
  'ChatWidgetEditor buttonsSamePlace': {
    eng: 'Display inline buttons above text input field',
    ru: 'Показывать кнопки только над формой ввода',
    pt: 'Exibir botões em linha acima do campo de entrada de texto',
  },
  'ChatWidgetEditor sound notifications': {
    eng: 'Sound notifications',
    ru: 'Звуковые уведомления',
    pt: 'Notificações de som',
  },
  'ChatWidgetEditor headline show label': {
    eng: 'Name',
    ru: 'Название',
    pt: 'Nome',
  },
  'ChatWidgetEditor headline placeholder': {
    eng: 'Online advisor',
    ru: 'Онлайн-консультант',
    pt: 'Consultor on-line',
  },
  'ChatWidgetEditor avatar show label': {
    eng: 'Avatar',
    ru: 'Аватар',
    pt: 'Avatar',
  },
  'ChatWidgetEditor linkPreview show label': {
    eng: 'Link preview',
    ru: 'Предварительный просмотр ссылок',
    pt: 'Visualização do link',
  },
  'ChatWidgetEditor names show label': {
    eng: 'Names',
    ru: 'Имена',
    pt: 'Nomes',
  },
  'ChatWidgetEditor user name placeholder': {
    eng: 'User',
    ru: 'Пользователь',
    pt: 'Usuário',
  },
  'ChatWidgetEditor bot name placeholder': {
    eng: 'Bot',
    ru: 'Бот',
    pt: 'Bot',
  },
  'ChatWidgetEditor operator name placeholder': {
    eng: 'Agent',
    ru: 'Оператор',
    pt: 'Agente',
  },
  'ChatWidgetEditor avatars show label': {
    eng: 'Avatars',
    ru: 'Аватарки',
    pt: 'Avatares',
  },
  'ChatWidgetEditor send button placeholder': {
    eng: 'To send a message, the user will',
    ru: 'Пользователь отправит сообщение',
    pt: 'Para enviar uma mensagem, o usuário deverá',
  },
  'ChatWidgetEditor send button and enter': {
    eng: 'Press Enter or click the button',
    ru: 'по Enter или кнопке',
    pt: 'Pressionar Enter ou clicar no botão',
  },
  'ChatWidgetEditor send enter': {
    eng: 'Press Enter',
    ru: 'по Enter',
    pt: 'Pressionar Enter',
  },
  'ChatWidgetEditor user form input placehoder': {
    eng: 'Message placeholder',
    ru: 'Подсказка в поле ввода',
    pt: 'Espaço reservado de mensagem',
  },
  'ChatWidgetEditor user form input info': {
    eng: 'Leave the field empty if the tip is not required',
    ru: 'Оставьте поле пустым, если подсказка не нужна',
    pt: 'Deixe o campo vazio se a dica não for necessária',
  },
  'ChatWidgetEditor position desktop label': {
    eng: 'On desktop',
    ru: 'На десктопе',
    pt: 'No desktop',
    cn: 'On a desktop',
  },
  'ChatWidgetEditor position right placeholder': {
    eng: 'Right indent, px',
    ru: 'Отступ справа, px',
    pt: 'Recuo à direita, px',
  },
  'ChatWidgetEditor position bottom placeholder': {
    eng: 'Bottom indent, px',
    ru: 'Отступ снизу, px',
    pt: 'Recuo inferior, px',
  },
  'ChatWidgetEditor position mobile label': {
    eng: 'On mobile',
    ru: 'На мобильном',
    pt: 'No dispositivo móvel',
    cn: 'On a mobile',
  },
  'ChatWidgetEditor position mobile left': {
    eng: 'Left',
    ru: 'Слева',
    pt: 'Esquerdo',
  },
  'ChatWidgetEditor position mobile right': {
    eng: 'Right',
    ru: 'Справа',
    pt: 'Direito',
  },
  'ChatWidgetEditor involving float message show label': {
    eng: 'Popup message',
    ru: 'Всплывающее сообщение',
    pt: 'Mensagem popup',
  },
  'ChatWidgetEditor show widget close button': {
    eng: 'Widget close button',
    ru: 'Кнопка закрытия виджета',
    pt: 'Botão Fechar widget',
  },
  'ChatWidgetEditor involving float message placeholder': {
    eng: 'Message text',
    ru: 'Текст сообщения',
    pt: 'Texto da mensagem',
  },
  'ChatWidgetEditor involving float mobile message placeholder': {
    eng: 'Message text',
    ru: 'Текст сообщения для мобильного телефона',
    pt: 'Texto da mensagem',
  },
  'ChatWidgetEditor involving delay placeholder': {
    eng: 'Show after, sec',
    ru: 'Показывать спустя, сек',
    pt: 'Mostrar após, segundo(s)',
  },
  'ChatWidgetEditor involving events show label': {
    eng: 'Open the chat window when',
    ru: 'Открыть окно чата по наступлению условия',
    pt: 'Abrir a janela do chat quando',
  },
  'ChatWidgetEditor involving event page_time': {
    eng: 'Time at the page',
    ru: 'Время на странице',
    pt: 'Tempo na página',
  },
  'ChatWidgetEditor involving event website_time': {
    eng: 'Time at the website',
    ru: 'Время на сайте',
    pt: 'Tempo no site',
  },
  'ChatWidgetEditor involving event longer': {
    eng: 'greater than',
    ru: 'больше',
    pt: 'maior que',
  },
  'ChatWidgetEditor involving event seconds': {
    eng: 'sec',
    ru: 'сек',
    pt: 's',
  },
  'ChatWidgetEditor involving add event button label': {
    eng: 'Add condition',
    ru: 'Добавить условие',
    pt: 'Adicionar condição',
  },
  'ChatWidgetEditor involving greeting info': {
    eng: 'Send a greeting to the user. Add some menu buttons so the user can choose a conversation topic. Set up the welcome message and the button titles. The session will start after the user has sent their first message. ',
    ru: 'Поприветствуйте пользователя. Добавьте кнопки меню, чтобы пользователь мог выбрать тему разговора. Настройте приветствие и названия кнопок. Сессия начнется после ответа пользователя. ',
    pt: 'Envie um cumprimento ao usuário. Adicione alguns botões de menu para que o usuário possa escolher um tópico de conversa. Configure a mensagem de boas-vindas e os títulos do botão. A sessão iniciará depois que o usuário tiver enviado a primeira resposta. ',
  },
  'ChatWidgetEditor involving greeting text placeholder': {
    eng: 'Enter the greeting message text',
    ru: 'Введите текст приветствия',
    pt: 'Inserir o texto do cumprimento',
  },
  'ChatWidgetEditor urlEditor Link': {
    eng: 'Link',
    ru: 'Ссылка',
    pt: 'Link',
  },
  'ChatWidgetEditor involving greeting delay placehoder': {
    eng: 'Show after, sec',
    ru: 'Показывать спустя, сек',
    pt: 'Mostrar após, segundo(s)',
  },
  'ChatWidgetEditor additional setting users percentage placeholder': {
    eng: 'Audience (%)',
    ru: 'Аудитория (%)',
    pt: 'Público (%)',
  },
  'ChatWidgetEditor additional setting file upload label': {
    eng: 'Show the Attach File button',
    ru: 'Показывать кнопку прикрепления файлов',
    pt: 'Mostrar o botão Anexar Arquivo',
  },
  'ChatWidgetEditor additional setting copy project placeholder': {
    eng: 'Copy project settings',
    ru: 'Скопировать настройки проекта',
    pt: 'Copiar configurações do projeto',
  },
  'ChatWidgetEditor font label': {
    eng: 'Font',
    ru: 'Шрифт',
    pt: 'Fonte',
  },
  'ChatWidgetEditor avatar size label': {
    eng: 'Avatar size',
    ru: 'Размер аватаров',
    pt: 'Tamanho do avatar',
  },
  'ChatWidgetEditor colors label': {
    eng: 'Theme color',
    ru: 'Цвет темы',
    pt: 'Cor do tema',
  },
  'ChatWidgetEditor headline colors label': {
    eng: 'Title',
    ru: 'Заголовок',
    pt: 'Título',
  },
  'ChatWidgetEditor headline color': {
    eng: 'Background',
    ru: 'Фон',
    pt: 'Segundo plano',
  },
  'ChatWidgetEditor headline color text label': {
    eng: 'Text color',
    ru: 'Цвет текста',
    pt: 'Cor do texto',
  },
  'ChatWidgetEditor headline color text dark': {
    eng: 'Dark',
    ru: 'Темный',
    pt: 'Escuro',
  },
  'ChatWidgetEditor headline color text light': {
    eng: 'Light',
    ru: 'Светлый',
    pt: 'Claro',
  },
  'ChatWidgetEditor chat opacity': {
    eng: 'Transparency',
    ru: 'Прозрачность',
    pt: 'Transparência',
  },
  'ChatWidgetEditor message colors label': {
    eng: 'user messages',
    ru: 'сообщения пользователя',
    pt: 'mensagens de usuário',
  },
  'ChatWidgetEditor form label': {
    eng: 'Menu and Message sending',
    ru: 'Меню и Отправка сообщений',
    pt: 'Menu e envio de mensagens',
  },
  'ChatWidgetEditor window colors label': {
    eng: 'chat window',
    ru: 'окно чата',
    pt: 'janela do chat',
  },
  'ChatWidgetEditor menu color': {
    eng: 'Menu',
    ru: 'Меню',
    pt: 'Menu',
  },
  'ChatWidgetEditor send button color': {
    eng: 'Message sending',
    ru: 'Отправка сообщений',
    pt: 'Envio de mensagens',
  },
  'ChatWidgetEditor bot message colors label': {
    eng: 'Bot and agent messages',
    ru: 'Сообщения бота и оператора',
    pt: 'Mensagens de bot e agente',
  },
  'ChatWidgetEditor form text color': {
    eng: 'Text',
    ru: 'Текст',
    pt: 'Texto',
  },
  'ChatWidgetEditor form buttons color': {
    eng: 'Buttons',
    ru: 'Кнопки',
    pt: 'Botões',
  },
  'ChatWidgetEditor setting copy project apply': {
    eng: 'Apply',
    ru: 'Применить',
    pt: 'Aplicar',
  },
  'ChatWidgetEditor custom theme title': {
    eng: 'Change any color to create your own theme',
    ru: 'Поменяйте любой цвет, чтобы создать свою тему',
    pt: 'Alterar qualquer cor para criar seu próprio tema',
  },
  'ChatWidgetEditor show menu buttons': {
    eng: 'Show menu buttons',
    ru: 'Показать кнопки меню',
    pt: 'Mostrar botões do menu',
  },
  'ChatWidgetEditor delete link': {
    eng: 'Delete the link',
    ru: 'Удалить ссылку',
    pt: 'Excluir o link',
  },
  'ChatWidgetEditor add button': {
    eng: 'Add a new button',
    ru: 'Добавить кнопку',
    pt: 'Adicionar um novo botão',
  },
  'ChatWidgetEditor main setting webhook label': {
    eng: 'Authentication webhook',
    ru: 'Вебхук для аутентификации',
    pt: 'Webhook de autenticação',
  },
  'ChatWidgetEditor:Percentage:HelperText': {
    eng: 'The percentage of clients who will see the widget on the website',
    ru: 'Процент пользователей, которые увидят виджет на сайте',
    pt: 'A porcentagem de clientes que verão o widget no site',
  },
};
