import React from 'react';
import localize from 'localization';
import { InputText, Button } from '@just-ai/just-ui';
import { stringHashCode } from 'pipes/functions';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';

const { translate: t } = localize;
export default class Index extends BaseEditForm {
  defaultFields = {};

  constructor(props) {
    super(props);
    this.form = {};

    this.state = {
      fieldsWithError: [],
      wasChanged: false,
    };
  }

  submit = async e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ fetching: true });

    if (
      this.form.outgoingSecret.value.length > 0 &&
      this.form.webimAccountName.value.length > 0 &&
      this.form.channelId.value.length > 0
    ) {
      if (Boolean(this.props.editableChannel.id)) {
        await this.saveChannel();
      } else {
        await this.createChannel();
      }
    } else {
      const emptyFields = [];

      if (this.form.outgoingSecret.value.length === 0) {
        emptyFields.push('outgoingSecret');
      }

      if (this.form.webimAccountName.value.length === 0) {
        emptyFields.push('webimAccountName');
      }

      if (this.form.channelId.value.length === 0) {
        emptyFields.push('channelId');
      }

      this.setState({
        fieldsWithError: emptyFields,
      });
    }
    this.setState({ fetching: false });
  };

  createChannel = async () => {
    const { createChannel } = this.props;

    const channelData = {
      ...this.props.editableChannel,
      description: this.form.description.value,
      type: this.props.editableChannel.channelType,
      config: {
        outgoingSecret: this.form.outgoingSecret.value,
        webimAccountName: this.form.webimAccountName.value,
        channelId: this.form.channelId.value,
      },
    };

    await createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async () => {
    const { editChannel } = this.props;

    const channelData = {
      ...this.props.editableChannel,
      description: this.form.description.value,
      config: {
        ...this.props.editableChannel.config,
        outgoingSecret: this.form.outgoingSecret.value,
        webimAccountName: this.form.webimAccountName.value,
        channelId: this.form.channelId.value,
      },
    };

    await editChannel(channelData);
    this.props.onClose();
  };

  success = () => {
    const { addSnackbar } = this.props;
    addSnackbar(t('Copied to clipboard'), 'success');
  };

  copy = () => {
    let supported = document.queryCommandSupported('copy');
    if (supported) {
      this.refInput.select();
      this.refInput.focus();
      window.document.execCommand('copy');
      this.success();
    }
  };

  select = text => {
    if (Boolean(text) && this.refInput) {
      this.refInput.value = text;
      this.copy();
    }
  };

  validate = (fieldName, value) => {
    const { fieldsWithError, wasChanged } = this.state;

    const index = fieldsWithError.findIndex(fieldWithError => {
      return fieldWithError === fieldName;
    });

    if (!wasChanged) {
      this.setState({
        wasChanged: true,
      });
    }

    if (value.length === 0) {
      if (index === -1) {
        this.setState({
          fieldsWithError: [...fieldsWithError, fieldName],
        });
      }
    } else {
      if (index !== -1) {
        const newFieldsWithError = [...fieldsWithError];
        newFieldsWithError.splice(index, 1);
        this.setState({
          fieldsWithError: newFieldsWithError,
        });
      }
    }
  };

  checkIsFetching = () => {
    const { fetching } = this.props;
    const { fieldsWithError, fetching: localFetching } = this.state;
    return fetching || localFetching || fieldsWithError.length > 0;
  };

  renderInputs() {
    const { editableChannel, appConfig } = this.props;
    const { fieldsWithError } = this.state;

    const productName = Boolean(appConfig.zenflow) ? appConfig.zenflow.productName : 'Aimylogic';
    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Name')}</p>
          <InputText
            innerRef={input => (this.form.description = input)}
            id='description'
            defaultValue={
              editableChannel.id ? editableChannel.description : t(`AddChannelCard ${editableChannel.channelType}`)
            }
            placeholder={t('Enter custom', t('Name').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Webim secret key')}</p>
          <InputText
            id='webim_secret'
            errorText={fieldsWithError.includes('outgoingSecret') ? t('Field can not be empty') : ''}
            defaultValue={editableChannel?.config?.outgoingSecret}
            innerRef={input => (this.form.outgoingSecret = input)}
            onChange={value => this.validate('outgoingSecret', value)}
            placeholder={t('Enter custom', t('Webim secret key').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Channel ID')}</p>
          <InputText
            id='channel_id'
            errorText={fieldsWithError.includes('channelId') ? t('Field can not be empty') : ''}
            defaultValue={editableChannel?.config?.channelId}
            innerRef={input => (this.form.channelId = input)}
            onChange={value => this.validate('channelId', value)}
            placeholder={t('Enter custom', t('Channel ID').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Webim account name')}</p>
          <InputText
            id='account_name'
            errorText={fieldsWithError.includes('webimAccountName')}
            defaultValue={editableChannel?.config?.webimAccountName}
            innerRef={input => (this.form.webimAccountName = input)}
            onChange={value => this.validate('webimAccountName', value)}
            placeholder={t('Enter custom', t('Webim account name').toLowerCase())}
          />
          <small className={classes.infoText}>
            {fieldsWithError.includes('webimAccountName')
              ? t('Field can not be empty') +
                ' ' +
                t('Corresponds to the subdomain of the account {your_name_account} .webim.ru')
              : t('Corresponds to the subdomain of the account {your_name_account} .webim.ru')}
          </small>
        </div>

        {Boolean(editableChannel.id) && (
          <>
            <div className={classes.formControl} key='aimylogic_secret'>
              <p className={classes.label}>{t('Incoming secret', productName)}</p>
              <InputText
                innerRef={input => (this.form.incomingSecret = input)}
                id='aimylogic_secret'
                disabled
                defaultValue={editableChannel?.config?.incomingSecret}
                placeholder={t('Enter custom', t('Incoming secret', productName).toLowerCase())}
              />

              <Button
                color='primary'
                variant='link'
                onClick={() => {
                  this.select(editableChannel?.config?.incomingSecret);
                }}
                className={classes.operatorWebimBtn}
              >
                {t('Copy')}
              </Button>
            </div>

            <div className={classes.formControl} key='server_url'>
              <p className={classes.label}>{t('Server address')}</p>
              <InputText
                innerRef={input => (this.form.webhookUrl = input)}
                id='server_url'
                key={`server_url-${stringHashCode(editableChannel?.webhookUrl)}`}
                disabled
                defaultValue={editableChannel?.webhookUrl}
                placeholder={t('Enter custom', t('Server address').toLowerCase())}
              />

              <Button
                color='primary'
                variant='link'
                onClick={() => {
                  this.select(editableChannel?.webhookUrl);
                }}
                className={classes.operatorWebimBtn}
              >
                {t('Copy')}
              </Button>
            </div>
            <input
              key='webim_ref-input'
              type='text'
              ref={input => (this.refInput = input)}
              readOnly
              className='hidden_input'
            />
          </>
        )}
      </>
    );
  }
}
