import { useMemo, useReducer, useEffect, useCallback } from 'react';

import { useAppSelector } from 'storeHooks';
import { RootState } from 'storeTypes';

import { useAppContext } from 'contexts/AppContext';
import { TestWidgetInstance } from 'services/TestWidget';
import { TemplatesWizardSaveAction$, TemplatesWizardSavePromise$ } from '../index';
import { getUserLanguage } from 'pipes/pureFunctions';

type SkillsProjectWidgetType = { isWidgetOpened: boolean; isLoading: boolean };

export function useSkillsProjectChatWidget() {
  const { projectShortName } = useAppSelector((state: RootState) => ({
    projectShortName: state.CurrentProjectsReducer.currentProject as string,
  }));

  const { projectSkillsService } = useAppContext();
  const testWidgetInstance = useMemo(() => new TestWidgetInstance(), []);
  const [testingSkillState, setTestingSkillState] = useReducer(
    (a: SkillsProjectWidgetType, b: Partial<SkillsProjectWidgetType>) => ({ ...a, ...b }),
    { isWidgetOpened: false, isLoading: false }
  );

  const openTestWidgetForSkill = useCallback(async () => {
    await testWidgetInstance.closeTestWidget();

    setTestingSkillState({ isWidgetOpened: false, isLoading: true });

    TemplatesWizardSaveAction$.next(true);
    const promise = TemplatesWizardSavePromise$.getValue();
    if (promise) await promise;
    if (!promise) throw new Error('Save skill promise was not returned');

    try {
      const testWidgetUrl = await projectSkillsService.getChatWidgetUrlByProject(projectShortName);
      if (!testWidgetUrl) return;
      let lang = getUserLanguage() as string;
      if (lang === 'eng') lang = 'en';
      await testWidgetInstance.openTestWidgetByUrl(testWidgetUrl, lang);
    } catch (error) {
      console.error('Opening chatWidget error', error);
      setTestingSkillState({ isWidgetOpened: false, isLoading: false });
    }
    return () => {
      return testWidgetInstance.closeTestWidget();
    };
  }, [projectShortName, projectSkillsService, testWidgetInstance]);

  useEffect(() =>
    testWidgetInstance.subscribe('opened', isWidgetOpened => {
      if (!isWidgetOpened) {
        setTestingSkillState({ isWidgetOpened, isLoading: false });
        return;
      }
      setTestingSkillState({ isWidgetOpened });
    })
  );

  useEffect(
    () => () => {
      testWidgetInstance.closeTestWidget();
    },
    [testWidgetInstance]
  );

  return { testingSkillState, openTestWidgetForSkill };
}
