import { t } from 'localization';

import { ScenarioSkillConfig, SkillType } from '../types';

export const baseInfoSkillConfig: () => ScenarioSkillConfig = () => ({
  type: SkillType.SCENARIO,
  orderWeight: 0,
  skillName: 'baseInfo',
  title: t('BaseInfoSkill:SkillName'),
  description: t('BaseInfoSkill:Description'),
  skillVersion: 1,
  language: '',
  startState: '',
  fields: [
    {
      type: 'TextArea',
      envName: 'greeting',
      title: t('BaseInfoSkill:Fields:Greeting:Title'),
      placeholder: t('BaseInfoSkill:Fields:Greeting:Placeholder'),
      value: '',
    },
    {
      type: 'TextArea',
      envName: 'helpText',
      title: t('BaseInfoSkill:Fields:HelpText:Title'),
      placeholder: t('BaseInfoSkill:Fields:HelpText:Placeholder'),
      value: '',
      helperText: t('BaseInfoSkill:Fields:HelpText:HelperText'),
    },
    {
      type: 'TextArea',
      envName: 'noMatch',
      title: t('BaseInfoSkill:Fields:NoMatch:Title'),
      placeholder: t('BaseInfoSkill:Fields:NoMatch:Placeholder'),
      value: '',
    },
    {
      type: 'TextArea',
      envName: 'finalPhrase',
      title: t('BaseInfoSkill:Fields:FinalPhrase:Title'),
      placeholder: t('BaseInfoSkill:Fields:FinalPhrase:Placeholder'),
      value: '',
    },
  ],
});
