import { axios } from '../../pipes/functions';
const prefix = '@scenario/';

export const STOP_TEST = prefix + 'STOP_TEST';

export const SET_HASH_DEFAULT = prefix + 'SET_HASH_DEFAULT';

export const LOAD_JSON = prefix + 'LOAD_JSON';
export const LOAD_JSON_PENDING = prefix + 'LOAD_JSON_PENDING';
export const LOAD_JSON_FULFILLED = prefix + 'LOAD_JSON_FULFILLED';
export const LOAD_JSON_REJECTED = prefix + 'LOAD_JSON_REJECTED';

export const DEPLOY = prefix + 'DEPLOY';

export const SAVE_JSON = prefix + 'SAVE_JSON';
export const SAVE_JSON_PENDING = prefix + 'SAVE_JSON_PENDING';
export const SAVE_JSON_FULFILLED = prefix + 'SAVE_JSON_FULFILLED';
export const SAVE_JSON_REJECTED = prefix + 'SAVE_JSON_REJECTED';

export const TEST_RUN = prefix + 'TEST_RUN';
export const TEST_RUN_FULFILLED = prefix + 'TEST_RUN_FULFILLED';
export const TEST_RUN_PENDING = prefix + 'TEST_RUN_PENDING';
export const TEST_RUN_REJECTED = prefix + 'TEST_RUN_REJECTED';

export const VOICE_TEST_RUN = prefix + 'VOICE_TEST_RUN';
export const VOICE_TEST_RUN_FULFILLED = prefix + 'VOICE_TEST_RUN_FULFILLED';
export const VOICE_TEST_RUN_PENDING = prefix + 'VOICE_TEST_RUN_PENDING';
export const VOICE_TEST_RUN_REJECTED = prefix + 'VOICE_TEST_RUN_REJECTED';

export const LOAD_WEBHOOK_SETTINGS = prefix + 'LOAD_WEBHOOK_SETTINGS';
export const LOAD_WEBHOOK_SETTINGS_FULFILLED = prefix + 'LOAD_WEBHOOK_SETTINGS_FULFILLED';
export const LOAD_WEBHOOK_SETTINGS_PENDING = prefix + 'LOAD_WEBHOOK_SETTINGS_PENDING';
export const LOAD_WEBHOOK_SETTINGS_REJECTED = prefix + 'LOAD_WEBHOOK_SETTINGS_REJECTED';

export const ZAPIER_HTTP_EMAIL = 'https://hooks.zapier.com/hooks/catch/8224614/oay5ch5/';

export const init = (projectShortName, hash) => {
  return {
    type: LOAD_JSON,
    payload: axios.get(`/restapi/content/${projectShortName}/graph`, { params: { ...hash } }),
  };
};

export const saveScenario = (projectShortName, graph) => {
  let constructedGraph = { ...graph };
  constructedGraph.blocks = [...graph.blocks]
    .sort((a, b) => {
      return Number.parseInt(a.name.replace('col#', '')) - Number.parseInt(b.name.replace('col#', ''));
    })
    .map(item => {
      if (item.type === 'screen') {
        let actions = [];
        item.actions.forEach(action => {
          let newAction;
          switch (action.type) {
            case 'timeout': {
              if (action.then) {
                newAction = {
                  type: 'script',
                  script: `$reactions.timeout({interval: _.template('${action.timeValue} ${
                    action.timeType
                  }', {variable: '$session'})($session), targetState: '${action.then || ''}'});`,
                };
              }
              break;
            }
            default:
              newAction = action;
          }
          if (newAction) {
            actions.push(newAction);
          }
        });
        item.actions = actions;
      }
      return item;
    });
  return {
    type: SAVE_JSON,
    payload: axios.post(
      `/restapi/content/${projectShortName}/graph${Boolean(graph.systemSave) ? '?systemSave=' + graph.systemSave : ''}`,
      constructedGraph
    ),
  };
};

export const testRun = projectShortName => {
  return {
    type: TEST_RUN,
    payload: axios.post(`/restapi/botconfig/testwidget/${projectShortName}/publish`),
  };
};

export const voiceTestRun = projectShortName => {
  return {
    type: VOICE_TEST_RUN,
    payload: axios.post(`/restapi/botconfig/voicetestwidget/${projectShortName}/publish`),
  };
};

export const stopTest = () => {
  return {
    type: STOP_TEST,
  };
};

export const getWebHookSettings = projectShortName => {
  return {
    type: LOAD_WEBHOOK_SETTINGS,
    payload: axios.get(`/restapi/content/${projectShortName}/file?filePath=src/params.yaml`),
  };
};

export const deployScenario = id => {
  return {
    type: DEPLOY,
    payload: axios.post(`/restapi/deployments/project/${id}`),
  };
};

export const setHashDefault = () => {
  return {
    type: SET_HASH_DEFAULT,
  };
};
