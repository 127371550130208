import { FC } from 'react';

import TextMessage from './components/TextMessage';
import InputText from './components/InputText';

import ManagerNotifyBlock from './components/ManagerNotifyBlock';
import DataCollectionTable from './components/DataCollectionTable';
import FieldsCollections from './components/FieldsCollections';
import TextArea from './components/TextArea';

import { ITemplateWizardEditField, ITemplateWizardField } from './types';

export enum TemplateWizardField {
  TEXT_MESSAGE = 'textMessage',
  INPUT_TEXT = 'InputText',
  TEXTAREA = 'TextArea',
  MANAGER_NOTIFY = 'ManagerNotify',
  DATA_COLLECTION_TABLE = 'DataCollectionTable',
  FIELDS_COLLECTIONS = 'FieldsCollections',
}

const fieldsMap: Record<TemplateWizardField, FC<ITemplateWizardEditField> | FC<ITemplateWizardField>> = {
  [TemplateWizardField.TEXT_MESSAGE]: TextMessage,
  [TemplateWizardField.INPUT_TEXT]: InputText,
  [TemplateWizardField.TEXTAREA]: TextArea,
  [TemplateWizardField.MANAGER_NOTIFY]: ManagerNotifyBlock,
  [TemplateWizardField.DATA_COLLECTION_TABLE]: DataCollectionTable,
  [TemplateWizardField.FIELDS_COLLECTIONS]: FieldsCollections,
};

export default function buildTemplateWizardField(type: TemplateWizardField) {
  return fieldsMap[type];
}
