const prefix = '@knowledge/';

export const PAGINATE = prefix + 'PAGINATE';
export const PAGINATE_FULFILLED = prefix + 'PAGINATE_FULFILLED';
export const PAGINATE_PENDING = prefix + 'PAGINATE_PENDING';
export const PAGINATE_REJECTED = prefix + 'PAGINATE_REJECTED';

export const FETCH_BASE = prefix + 'FETCH_BASE';
export const FETCH_BASE_FULFILLED = prefix + 'FETCH_BASE_FULFILLED';
export const FETCH_BASE_PENDING = prefix + 'FETCH_BASE_PENDING';
export const FETCH_BASE_REJECTED = prefix + 'FETCH_BASE_REJECTED';

export const FETCH_ALL_QUESTIONS = prefix + 'FETCH_ALL_QUESTIONS';
export const FETCH_ALL_QUESTIONS_FULFILLED = prefix + 'FETCH_ALL_QUESTIONS_FULFILLED';
export const FETCH_ALL_QUESTIONS_PENDING = prefix + 'FETCH_ALL_QUESTIONS_PENDING';
export const FETCH_ALL_QUESTIONS_REJECTED = prefix + 'FETCH_ALL_QUESTIONS_REJECTED';

export const TOGGLE_QUESTION_EDIT = prefix + 'TOGGLE_QUESTION_EDIT';
export const DROP_EDIT_QUESTION = prefix + 'DROP_EDIT_QUESTION';
export const EDIT_QUESTION = prefix + 'EDIT_QUESTION';
export const DROP_BASE = prefix + 'DROP_BASE';
export const DROP_BASE_QUESTIONS = prefix + 'DROP_BASE_QUESTIONS';
export const DROP_BASE_LIST = prefix + 'DROP_BASE_LIST';
export const SET_CURRENT_BASE = prefix + 'SET_CURRENT_BASE';

export const SEARCH = prefix + 'SEARCH';
export const CANCEL_SEARCH = prefix + 'CANCEL_SEARCH';

export const ADD_QUESTION = prefix + 'ADD_QUESTION';
export const REMOVE_QUESTION = prefix + 'REMOVE_QUESTION';

export const SAVE_BASE = prefix + 'SAVE_BASE';
export const SAVE_BASE_FULFILLED = prefix + 'SAVE_BASE_FULFILLED';
export const SAVE_BASE_PENDING = prefix + 'SAVE_BASE_PENDING';
export const SAVE_BASE_REJECTED = prefix + 'SAVE_BASE_REJECTED';

export const UPLOAD_QUESTIONS = prefix + 'UPLOAD_QUESTIONS';
export const UPLOAD_QUESTIONS_FULFILLED = prefix + 'UPLOAD_QUESTIONS_FULFILLED';
export const UPLOAD_QUESTIONS_PENDING = prefix + 'UPLOAD_QUESTIONS_PENDING';
export const UPLOAD_QUESTIONS_REJECTED = prefix + 'UPLOAD_QUESTIONS_REJECTED';

export const TOGGLE_DISABLE_ALL = prefix + 'TOGGLE_DISABLE_ALL';
export const TOGGLE_SPEECH_SYNTHESIS = prefix + 'TOGGLE_SPEECH_SYNTHESIS';

export const RESTORE_LAST_STATE = prefix + 'RESTORE_LAST_STATE';

export const CREATE_BASE = prefix + 'CREATE_BASE';
export const CREATE_BASE_FULFILLED = prefix + 'CREATE_BASE_FULFILLED';
export const CREATE_BASE_PENDING = prefix + 'CREATE_BASE_PENDING';
export const CREATE_BASE_REJECTED = prefix + 'CREATE_BASE_REJECTED';

export const KNOWLEDGE_DEPLOY = prefix + 'KNOWLEDGE_DEPLOY';
export const KNOWLEDGE_DEPLOY_FULFILLED = prefix + 'KNOWLEDGE_DEPLOY_FULFILLED';
export const KNOWLEDGE_DEPLOY_PENDING = prefix + 'KNOWLEDGE_DEPLOY_PENDING';
export const KNOWLEDGE_DEPLOY_REJECTED = prefix + 'KNOWLEDGE_DEPLOY_REJECTED';
