import React from 'react';
import { t } from 'localization';
import classes from './Channels.module.scss';
import { isEuroInstance } from '../../../isAccessFunction';
import { Modal, Button } from '@just-ai/just-ui';

export const AddBitrixModal = ({
  toggleBitrixNoAppExistModal,
  showBitrixNoAppExistModal,
  showBitrixTooMuchChannelsModal,
  goToInstallBitrixApp,
  toggleBitrixTooMuchChannelsModal,
  appConfig,
  createBitrixAnyway,
}) => {
  return (
    <>
      <Modal
        key='bitrix-no-app-exist-modal'
        title={t('Adding channel Bitrix24')}
        isOpen={showBitrixNoAppExistModal}
        onClickCloseIcon={toggleBitrixNoAppExistModal}
        customFooter={
          <>
            <Button onClick={toggleBitrixNoAppExistModal} color='primary' outline>
              {t('Cancel')}
            </Button>
            <Button onClick={goToInstallBitrixApp} iconRight='faExternalLink' color='primary'>
              {t('Install app')}
            </Button>
          </>
        }
      >
        <div className={classes.paragraphMargin}>{t('No app installed text title')}</div>
        <div>{t('No app installed text 1', t(isEuroInstance() ? 'tovi' : 'aimy'))}</div>
        <div>{t('No app installed text 2', t(isEuroInstance() ? 'tovi' : 'aimy'))}</div>
        <div>{t('No app installed text 3')}</div>
      </Modal>

      <Modal
        isOpen={showBitrixTooMuchChannelsModal}
        key='bitrix-too-much-channels-modal'
        onCancelClick={toggleBitrixTooMuchChannelsModal}
        onActionClick={createBitrixAnyway}
        buttonSubmitText={t('Bitrix24 createBitrixAnyway')}
        buttonCancelText={t('Cancel')}
        title={t('Impossible to add channel Bitrix24')}
      >
        {t('Too much Bitrix channels text')}
      </Modal>
    </>
  );
};
