export type Template = {
  type: string;
  language: string;
  name: string;
  title: string;
  summary: string;
  thumbnail: string;
  jaicf?: boolean;

  parsedJson: TemplateParsedJson;
};

type TemplateParsedJson = {
  name: string;
  index: number;
  title: string;
  summary: string;

  namespaces: string[];
  thumbnail: string;
  language: string;
  experrtoId: number;
  caila: boolean;
  forCreateForm: boolean;
  defaultTemplate?: boolean;
  jgraph?: boolean;
  icon?: string;
};

export const InitialState: {
  templatesList: Template[];
  fetching: boolean;
  fetched: boolean;
} = {
  templatesList: [],
  fetching: false,
  fetched: true,
};
