import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NLUModuleContextProvider } from '@just-ai/nlu-modules/dist/context';
import { validateFile } from '@just-ai/nlu-modules/dist/utils';
import Cookies from 'universal-cookie';
import localize from 'localization';
import { withRouter } from 'react-router-dom';
import { addSnackbar, dismissSnackbar, changeSnackbar } from 'actions/snackbars.actions';
import { withTestWidgetContext } from '../../modules/TestWidget/TestWidgetContext';

import { useAppContext } from '../../contexts/AppContext';

import { saveFileToServer, saveAudioToServer } from '../../pipes/axiosFunctions';
import { axios, getWidgetTestMode, removeWidgetCode, saveWidgetModeToLocalStorage } from '../../pipes/functions';
import isAccess from '../../isAccessFunction';
import { StyledTestWidgetButton } from '../../views/Knowledge/TopActions';
import * as KnowledgeActions from '../../actions/knowledge.actions';
import * as ScenarioActions from '../../actions/scenario.actions';
import ReactDOM from 'react-dom';
import { TestWidgetInstance } from '../../services/TestWidget';

const cookies = new Cookies();
const { translate } = localize;

type NluState = {
  mounted: boolean;
  clickTestButton: boolean;
  isWidgetShown: boolean;
  loadingWidget: boolean;
  voiceTestRunPending: boolean;
  testRunPending: boolean;
  widgetType: string;
  isCloseAlertOpen: boolean;
};

type NluAction =
  | { type: 'SET_TEST_BUTTON'; payload: boolean }
  | { type: 'SET_WIDGET_SHOWN'; payload: boolean }
  | { type: 'SET_WIDGET_LOADING'; payload: boolean }
  | { type: 'SET_CLICKTEST_BTN'; payload: boolean }
  | { type: 'SET_WIDGET_TYPE'; payload: string }
  | { type: 'MOUNTED'; payload: boolean };

function NLUReducer(state: NluState, action: NluAction): NluState {
  switch (action.type) {
    case 'MOUNTED':
      return { ...state, mounted: action.payload };
    case 'SET_WIDGET_SHOWN':
      return { ...state, isWidgetShown: action.payload };
    case 'SET_CLICKTEST_BTN':
      return { ...state, clickTestButton: action.payload };
    case 'SET_WIDGET_LOADING':
      return { ...state, loadingWidget: action.payload };
    case 'SET_WIDGET_TYPE':
      return { ...state, widgetType: action.payload };
    case 'SET_TEST_BUTTON':
      return { ...state, clickTestButton: action.payload };
    default:
      throw new Error('Error in recaptcha reducer');
  }
}

const NluModuleWrapper = (props: any) => {
  const { currentUser, currentProject } = useAppContext();
  const testWidgetInstance = useRef(new TestWidgetInstance());
  const initialState: NluState = useMemo(
    () => ({
      mounted: false,
      clickTestButton: false,
      isWidgetShown: false,
      loadingWidget: false,
      isCloseAlertOpen: false,
      testRunPending: false,
      voiceTestRunPending: false,
      widgetType: getWidgetTestMode(),
    }),
    []
  );
  const refInput = useRef<HTMLInputElement>(null);
  const node = useRef(document.getElementById('headerBoundPlace'));

  const [NluState, dispatch] = useReducer(NLUReducer, initialState);

  useEffect(() => {
    node.current = document.getElementById('headerBoundPlace');
    dispatch({ type: 'MOUNTED', payload: true });
  }, []);

  const appLogger = {
    error: ({ message, exception }: any) => {
      console.log(message, exception);
    },
  };

  const { account, language } = currentUser;

  const handleLoadWidget = useCallback(
    async (payload: any) => {
      let implementationScript = payload.value.data;
      // eslint-disable-next-line
      let scriptTagRegex =
        /<script[-a-zA-Z0-9@:%_ '"\+.~#?&//=]* src="([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)"[-a-zA-Z0-9@:%_ \+.~#?&//=]*><\/script>/;
      let src = implementationScript.match(scriptTagRegex)[1];

      dispatch({ type: 'SET_CLICKTEST_BTN', payload: false });
      dispatch({ type: 'SET_WIDGET_LOADING', payload: true });

      let onLoadOk = () => {
        dispatch({ type: 'SET_WIDGET_SHOWN', payload: true });
        dispatch({ type: 'SET_WIDGET_LOADING', payload: false });
      };

      let onError = () => {
        dispatch({ type: 'SET_WIDGET_SHOWN', payload: false });
        dispatch({ type: 'SET_WIDGET_LOADING', payload: false });
      };

      try {
        await testWidgetInstance.current.openTestWidgetByUrl(src, language);
        onLoadOk();
      } catch (e) {
        onError();
      }
    },
    [language]
  );

  const removeWidgetInstance = () => {
    removeWidgetCode();
    dispatch({ type: 'SET_WIDGET_SHOWN', payload: false });
  };

  const hideWidget = (e: React.SyntheticEvent) => {
    if (!!e) {
      e.stopPropagation();
    }
    props.actions.stopTest();
    if (NluState.isWidgetShown && !NluState.loadingWidget) {
      removeWidgetInstance();
      cookies.remove('waSessionId', { path: '/' });
    }
  };

  const snackbarBtnAction = (log: string) => (id: string) => {
    let supported = document.queryCommandSupported('copy');
    if (refInput?.current && supported) {
      refInput.current.value = log;
      refInput.current.select();
      refInput.current.focus();
      window.document.execCommand('copy');
      props.actions.changeSnackbar(id, { buttonText: translate('Copied') });
    }
  };

  const showWidget = (e: React.SyntheticEvent) => {
    e?.stopPropagation();

    cookies.remove('waSessionId', { path: '/' });
    const { testRun, addSnackbar } = props.actions;
    const { projectShortName } = props.match.params;

    dispatch({ type: 'SET_WIDGET_TYPE', payload: 'simple' });
    dispatch({ type: 'SET_CLICKTEST_BTN', payload: true });

    saveWidgetModeToLocalStorage(projectShortName, 'simple');

    testRun(projectShortName)
      .then(handleLoadWidget)
      .catch((e: any) => {
        dispatch({ type: 'SET_CLICKTEST_BTN', payload: false });
        addSnackbar(
          translate('Scenario error'),
          'error',
          false,
          translate('Copy log'),
          snackbarBtnAction(e.response.data)
        );
      });
  };

  const showVoiceWidget = (e: React.SyntheticEvent) => {
    if (Boolean(e)) {
      e.stopPropagation();
    }
    cookies.remove('waSessionId', { path: '/' });

    const { voiceTestRun, addSnackbar } = props.actions;

    const { projectShortName } = props.match.params;

    dispatch({ type: 'SET_WIDGET_TYPE', payload: 'voice' });
    dispatch({ type: 'SET_CLICKTEST_BTN', payload: true });

    saveWidgetModeToLocalStorage(projectShortName, 'voice');

    voiceTestRun(projectShortName)
      .then(handleLoadWidget)
      .catch((e: any) => {
        dispatch({ type: 'SET_CLICKTEST_BTN', payload: false });

        addSnackbar(
          translate('Scenario error'),
          'error',
          false,
          translate('Copy log'),
          snackbarBtnAction(e.response.data)
        );
      });
  };

  const voiceTestWidgetInProgress =
    NluState.voiceTestRunPending ||
    NluState.clickTestButton ||
    (NluState.loadingWidget && NluState.widgetType === 'voice');

  const testWidgetInProgress =
    NluState.testRunPending || NluState.clickTestButton || (NluState.loadingWidget && NluState.widgetType === 'simple');

  return (
    <div style={{ height: '100%' }}>
      <NLUModuleContextProvider
        accountId={account?.id}
        projectShortName={currentProject}
        axiosInstance={axios}
        language={language?.toLowerCase()}
        isAccessFunction={isAccess}
        isWhiteLabel={() => false}
        actions={{
          addAlert: props.addAlert,
        }}
        appLogger={appLogger}
        saveFileToServer={saveFileToServer}
        saveAudioToServer={saveAudioToServer}
        validateFile={validateFile}
        isZfl={true}
      >
        {node.current &&
          ReactDOM.createPortal(
            <StyledTestWidgetButton
              dataTestId='Base.headerBoundPlace'
              widgetShown={NluState.isWidgetShown}
              hideWidget={hideWidget}
              disabled={NluState.voiceTestRunPending || NluState.testRunPending || NluState.loadingWidget}
              voiceTestWidgetInProgress={voiceTestWidgetInProgress}
              testWidgetInProgress={testWidgetInProgress}
              widgetType={NluState.widgetType}
              showWidget={showWidget}
              showVoiceWidget={showVoiceWidget}
            />,
            node.current
          )}
        {props.children}
      </NLUModuleContextProvider>
      {ReactDOM.createPortal(<div id='widget-root' />, document.body)}
      <input type='text' ref={refInput} readOnly className='hidden_input' />
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    language: state.CurrentUserReducer.language,
    currentUser: state.CurrentUserReducer.currentUser,
    appConfig: state.CurrentUserReducer.appConfig,

    currentProject: state.CurrentProjectsReducer.currentProject,

    baseList: state.KnowledgeReducer.baseList,
    currentBase: state.KnowledgeReducer.currentBase,

    snackItems: state.SnackbarsReducer.snackbars,
    testRun: state.ScenarioReducer.testRun,
    voiceTestRun: state.ScenarioReducer.voiceTestRun,
    testRunPending: state.ScenarioReducer.testRunPending,
    voiceTestRunPending: state.ScenarioReducer.voiceTestRunPending,
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...(KnowledgeActions as any),
      ...ScenarioActions,
      addSnackbar: addSnackbar,
      changeSnackbar: changeSnackbar,
      dismissSnackbar: dismissSnackbar,
    },
    dispatch
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTestWidgetContext(NluModuleWrapper)));
