import React from 'react';
import localize from 'localization';
import { InputText, JustSelect, Textarea, SimpleSelect, Banner } from '@just-ai/just-ui';
import classNames from 'classnames';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import { isEuroInstance } from '../../../../../isAccessFunction';
import classnames from 'classnames';

const { translate: t } = localize;

export default class WhatsAppEditForm extends BaseEditForm {
  constructor(props) {
    super(props);

    this.form = {};

    this.state = {
      rollout: true,
      errors: [],
      authType: 'basic',
      provider: props.editableChannel.channelType,
    };
  }

  setProvider = value => this.setState({ ...this.state, provider: value[0] });

  submit = async e => {
    const { createChannel, editChannel, onClose } = this.props;
    this.setState({ fetching: true });

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      const data = this.buildData();
      const errors = this.validateData(data);

      this.setState({ errors });

      if (errors.length > 0) {
        this.setState({ fetching: false });
        return;
      }

      if (Boolean(this.props.editableChannel.id)) {
        await editChannel(data);
        onClose();
      } else {
        await createChannel(data);
        onClose();
      }
    } catch (e) {}

    this.setState({ fetching: false });
  };

  buildData = () => {
    const { editableChannel } = this.props;
    const { name, botToken, senderName, apiKey, apiSecret, bearerToken, source, phoneNumberId } = this.form;
    const isEdit = Boolean(editableChannel.id);

    let botName = name?.value || editableChannel?.botName || t(`AddChannelCard ${editableChannel.channelType}`);
    let result = isEdit ? {} : { ...editableChannel };

    result = {
      ...result,
      botName,
      senderName: senderName?.value || botName,
      accessToken: botToken?.value || editableChannel.accessToken,
      channelType: this.state.provider || editableChannel.channelType,
      project: {
        ...editableChannel.project,
      },
    };

    if (isEdit) {
      result = {
        ...result,
        id: editableChannel.id,
        rollout: 'MANUAL',
        branch: 'master',
      };
    }
    const provider = this.state.provider || editableChannel.channelType;

    if (provider === 'WHATSAPP') {
      result.customData = {
        phoneNumberId: phoneNumberId?.value,
      };
    }

    if (provider === 'I_DIGITAL') {
      result.customData = {
        source: source?.value,
      };
    }

    if (provider === 'NEXMO') {
      const customData = editableChannel.customData || {};
      result.customData = {
        authType: this.state.authType || customData.authType || 'basic',
        apiKey: apiKey?.value,
        apiSecret: apiSecret?.value,
        bearerToken: bearerToken?.value,
      };
    }

    return result;
  };

  getMessageAboutConfiguration = () => {
    const { appConfig } = this.props;
    const showHelp = appConfig?.zenflow?.showHelp;
    const { provider } = this.state;

    return (
      showHelp && (
        <Banner
          variant='info'
          withIcon
          BannerText={() => (
            <a target='_blank' rel='noopener noreferrer' href={t(`${provider} doc link`)}>
              {t('ChannelsAdding: how to add', t(`AddChannelCard ${provider}`))}
            </a>
          )}
        />
      )
    );
  };

  validateData = data => {
    const errors = [];

    const {
      customData: { bearerToken, apiKey, apiSecret, authType, phoneNumberId },
      accessToken,
      channelType,
    } = data || { customData: {} };

    if (channelType === 'WHATSAPP') {
      if (!accessToken.trim()) {
        errors.push(t(`ChannelsAdding: field is required`, t(`ChannelsAdding: accessToken`)));
      }
      if (!phoneNumberId.trim()) {
        errors.push(t(`ChannelsAdding: field is required`, t(`Channels:Whatsapp:phoneNumberId`)));
      }
    }

    if (channelType === 'NEXMO') {
      if (authType === 'bearer' && !bearerToken?.trim()) {
        errors.push(t(`ChannelsAdding: field is required`, t(`ChannelsAdding: Bearer Token`)));
      }
      if (authType === 'basic' && !apiKey?.trim()) {
        errors.push(t(`ChannelsAdding: field is required`, t(`ChannelsAdding: ApiKey`)));
      }
      if (authType === 'basic' && !apiSecret?.trim()) {
        errors.push(t(`ChannelsAdding: field is required`, t(`ChannelsAdding: ApiSecret`)));
      }
    }
    return errors;
  };

  setAuthType = authType => this.setState({ authType });

  getSelectOptions = () => {
    const providers = isEuroInstance() ? ['WHATSAPP', 'NEXMO'] : ['WHATSAPP', 'I_DIGITAL', 'NEXMO'];
    return providers.map(item => ({ label: t(`WhatsAppEditForm ${item}`), value: item }));
  };

  renderInputs() {
    const { editableChannel, appConfig } = this.props;
    const { provider } = this.state;
    const { accessToken } = editableChannel;
    const { authType, bearerToken, apiKey, apiSecret, source, phoneNumberId } = editableChannel.customData || {};

    const isEdit = Boolean(editableChannel.id);
    const showHelp = appConfig?.zenflow?.showHelp;

    return (
      <>
        {isEdit && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('WhatsAppEditForm service delivered by')}</p>
            <InputText
              data-test-id='WhatsAppEditForm.serviceDeliveredBy'
              id='type'
              disabled
              defaultValue={t(`WhatsAppEditForm ${editableChannel.channelType}`)}
              placeholder={t('Enter custom', t('WhatsAppEditForm service delivered by').toLowerCase())}
            />
          </div>
        )}
        {!isEdit && (
          <div className={classNames(classes.formControl)}>
            <p className={classes.label}>{t('WhatsAppEditForm service delivered by')}</p>
            <JustSelect
              value={provider || editableChannel.channelType}
              data-test-id='WhatsAppEditForm.name'
              options={this.getSelectOptions()}
              onChange={this.setProvider}
              disabled={editableChannel.id}
              className={classes.select}
            />
          </div>
        )}
        {provider === 'I_DIGITAL' && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('WhatsAppEditForm I-DIGITAL source')}</p>
            <InputText
              id='source'
              innerRef={input => (this.form.source = input)}
              defaultValue={source}
              placeholder={t('Enter custom', t('WhatsAppEditForm I-DIGITAL source').toLowerCase())}
            />
          </div>
        )}
        {provider === 'NEXMO' && isEdit && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('ChannelsAdding: Inbound URL')}</p>
            <Textarea id='inbound' disabled defaultValue={editableChannel.webhookUrl} />
          </div>
        )}
        {provider === 'NEXMO' && isEdit && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('ChannelsAdding: Status URL')}</p>
            <Textarea id='status' disabled defaultValue={editableChannel.webhookUrl?.replace('inbound', 'status')} />
          </div>
        )}
        {provider === 'NEXMO' && !isEdit && (
          <>
            <div className={classes.formControl}>
              <p className={classes.label}>{t('ChannelsAdding: type auth')}</p>
              <SimpleSelect
                options={[
                  { value: 'basic', label: t('ChannelsAdding: Basic Authorization') },
                  { value: 'bearer', label: t('ChannelsAdding: Bearer Token') },
                ]}
                onChange={this.setAuthType}
                value={this.state.authType}
                defaultValue={this.state.authType}
              />
            </div>
            {(this.state.authType || authType) === 'bearer' ? (
              <div className={classes.formControl}>
                <p className={classnames(classes.label, classes.labelRequired)}>{t('ChannelsAdding: Bearer Token')}</p>
                <InputText
                  id='bearer'
                  innerRef={input => (this.form.bearerToken = input)}
                  defaultValue={bearerToken}
                  placeholder={t('Enter custom', t('ChannelsAdding: Bearer Token').toLowerCase())}
                />
              </div>
            ) : (
              <>
                <div className={classes.formControl}>
                  <p className={classnames(classes.label, classes.labelRequired)}>{t('ChannelsAdding: ApiKey')}</p>
                  <InputText
                    id='apiKey'
                    innerRef={input => (this.form.apiKey = input)}
                    defaultValue={apiKey}
                    placeholder={t('Enter custom', t('ChannelsAdding: ApiKey').toLowerCase())}
                  />
                </div>
                <div className={classes.formControl}>
                  <p className={classnames(classes.label, classes.labelRequired)}>{t('ChannelsAdding: ApiSecret')}</p>
                  <InputText
                    id='apiSecret'
                    innerRef={input => (this.form.apiSecret = input)}
                    defaultValue={apiSecret}
                    placeholder={t('Enter custom', t('ChannelsAdding: ApiSecret').toLowerCase())}
                  />
                </div>
              </>
            )}
          </>
        )}
        {provider === 'WHATSAPP' && (
          <>
            <div className={classes.formControl}>
              <p className={classnames(classes.label, classes.labelRequired)}>{t('ChannelsAdding: accessToken')}</p>
              <InputText
                id='accessToken'
                innerRef={input => (this.form.botToken = input)}
                defaultValue={accessToken}
                placeholder={t('ChannelsAdding:EnterToken')}
              />
            </div>
            <div className={classes.formControl}>
              <p className={classnames(classes.label, classes.labelRequired)}>{t('Channels:Whatsapp:phoneNumberId')}</p>
              <InputText
                id='phoneNumberId'
                innerRef={input => (this.form.phoneNumberId = input)}
                defaultValue={phoneNumberId}
                placeholder={t('ChannelsAdding:EnterPhoneNumberId')}
              />
            </div>
          </>
        )}
      </>
    );
  }
}
