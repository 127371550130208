import React, { Component } from 'react';
import { withStyles, Switch } from 'altreidsds';

const styles = theme => ({
  smallSwitch: {
    width: 32,
  },
  smallSwitchBar: {
    height: 16,
    marginTop: -8,
    width: 32,
    marginLeft: -7,
  },
  smallSwitchBase: {
    width: 32,
    height: 16,

    '&> span:first-child:before': {
      display: 'none',
    },
  },
  smallSwitchIcon: {
    width: 16,
    height: 16,
    marginLeft: 0,
  },
});

class SmallSwitch extends Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <Switch
        {...rest}
        classes={{
          root: classes.smallSwitch,
          bar: classes.smallSwitchBar,
          switchBase: classes.smallSwitchBase,
          icon: classes.smallSwitchIcon,
        }}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(SmallSwitch);
