import { axios } from '../../pipes/functions';
import {
  SAVE_FILE_TO_SERVER,
  REMOVE_FILE_FROM_SERVER,
  GET_VOICE_CHANNELS_LIST,
  CREATE_VOICE_CHANNEL,
  UPDATE_VOICE_CHANNEL,
  GET_CURRENT,
  GET_UPLOADED_FILE,
  SET_MAILING_STATUS,
  REMOVE_VOICE_CHANNEL,
  VALIDATE_FILE,
  UNSET_CURRENT,
  AUTO_CREATE_RESTERISK,
  VOICE_CHANNEL_REPORT,
  DOWNLOAD_CALL_LIST,
  TEST_CALL,
  GET_TEST_CALL,
  SET_RESTERISK_INCOMING,
  AUTO_CREATE_RESTERISK_WITH_TRUNK,
  PUBLISH_RESTERISK_CHANNELS,
  GET_VOICES,
} from '../../constants/voiceChannel.actions';

export const getVoices = accountId => {
  return {
    type: GET_VOICES,
    payload: axios.get(`/api/botadmin/accounts/${accountId}/aimylogic/voices`),
  };
};

export const getCurrent = (accountId, id) => {
  return {
    type: GET_CURRENT,
    payload: axios.get(`/dialer/api/v1/${accountId}/callTask/${id}`),
  };
};

export const getChannelsList = (accountId, projectShortName, size = 20, page = 0) => {
  return {
    type: GET_VOICE_CHANNELS_LIST,
    payload: axios.get(`/dialer/api/v1/${accountId}/callTask`, {
      params: {
        projectName: projectShortName,
        size,
        page,
      },
    }),
  };
};

export const createChannel = (accountId, data) => {
  return {
    type: CREATE_VOICE_CHANNEL,
    payload: axios.post(`/dialer/api/v1/${accountId}/callTask`, data),
  };
};

export const updateCallTask = (accountId, callTaskId, data) => {
  return {
    type: UPDATE_VOICE_CHANNEL,
    payload: axios.put(`/dialer/api/v1/${accountId}/callTask/${callTaskId}`, data),
  };
};

export const saveFileToServer = (accountId, file, config = {}) => {
  const filename = file.name;
  let fd = new FormData();
  fd.append('file', file);
  fd.append('name', filename);
  return {
    type: SAVE_FILE_TO_SERVER,
    payload: axios.post(`/dialer/api/v1/${accountId}/callList/upload?agreementApplied=true`, fd, config),
  };
};
export const validateFile = (accountId, file, config = {}) => {
  let fd = new FormData();
  fd.append('file', file);
  fd.append('name', file.name);
  return {
    type: VALIDATE_FILE,
    payload: axios.post(`/dialer/api/v1/${accountId}/callList/validate`, fd, config),
  };
};

export const getFileData = (accountId, callListId) => {
  return {
    type: GET_UPLOADED_FILE,
    payload: axios.get(`/dialer/api/v1/${accountId}/callList/${callListId}`),
  };
};

export const setMailingStatus = (accountId, callTaskId, status) => {
  return {
    type: SET_MAILING_STATUS,
    payload: axios.post(`/dialer/api/v1/${accountId}/callTask/${callTaskId}/control`, status, {
      headers: { 'Content-Type': 'application/json' },
    }),
    status: status,
  };
};

export const removeChannel = (accountId, callTaskId) => {
  return {
    type: REMOVE_VOICE_CHANNEL,
    payload: axios.delete(`/dialer/api/v1/${accountId}/callTask/${callTaskId}`),
  };
};

export const removeFileFromServer = (accountId, callListId) => {
  return {
    type: REMOVE_FILE_FROM_SERVER,
    payload: axios.delete(`/dialer/api/v1/${accountId}/callList/${callListId}`),
  };
};

export const unsetCurrent = () => {
  return {
    type: UNSET_CURRENT,
  };
};

export const createResteriskChannel = projectShortName => {
  return {
    type: AUTO_CREATE_RESTERISK,
    payload: axios.post(`/restapi/botconfig/autoResterisk/${projectShortName}`),
  };
};

export const getVoiceChannelReport = (accountId, callTaskId) => {
  return {
    type: VOICE_CHANNEL_REPORT,
    payload: axios.get(`/dialer/api/v1/${accountId}/callTask/${callTaskId}/report`),
  };
};

export const downloadCallList = (accountId, callListId) => {
  return {
    type: DOWNLOAD_CALL_LIST,
    payload: axios.get(`/dialer/api/v1/${accountId}/callList/${callListId}/download`, { responseType: 'blob' }),
  };
};

export const testCall = (accountId, projectShortName, botId) => {
  return {
    type: TEST_CALL,
    payload: axios.post(`/dialer/api/v1/${accountId}/projects/${projectShortName}/channels/${botId}/testCall`),
  };
};

export const getTestCall = (accountId, projectShortName, botId, testCallId) => {
  return {
    type: GET_TEST_CALL,
    payload: axios.get(
      `/dialer/api/v1/${accountId}/projects/${projectShortName}/channels/${botId}/testCall/${testCallId}`
    ),
  };
};

export const updateResteriskChannelIncoming = (projectShortName, incoming, forced = false) => {
  return {
    type: SET_RESTERISK_INCOMING,
    payload: axios.post(
      `/restapi/botconfig/autoResterisk/${projectShortName}/updateIncoming?incoming=${incoming}&forced=${forced}`
    ),
  };
};

export const createResteriskChannelV2 = (accountId, projectShortName) => {
  return {
    type: AUTO_CREATE_RESTERISK,
    payload: axios.post(`/api/botadmin/accounts/${accountId}/projects/${projectShortName}/autoCallsChannel/dialer`),
  };
};

export const createResteriskChannelWithTrunkId = (accountId, projectShortName, trunkId) => {
  return {
    type: AUTO_CREATE_RESTERISK_WITH_TRUNK,
    payload: axios.put(`/api/botadmin/accounts/${accountId}/projects/${projectShortName}/autoCallsChannel/dialer`, {
      selectRequest: { trunkId: trunkId },
    }),
  };
};

export const publishResteriskChannels = (accountId, projectShortName, channelId = undefined) => {
  return {
    type: PUBLISH_RESTERISK_CHANNELS,
    payload: axios.post(
      `/api/botadmin/accounts/${accountId}/projects/${projectShortName}/autoCallsChannel/publish`,
      channelId && {
        targetChannelId: channelId,
      }
    ),
  };
};
