import React, { FC, useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { without } from 'lodash';
import { Button, Icon } from '@just-ai/just-ui';

import toArray from 'helpers/array/toArray';

import { addKeyToRow } from '../../utils';
import { ITemplateWizardEditField, LocalizationField } from '../../types';
import FieldsCollectionsRow from './FieldsCollectionsRow';
import styles from './styles.module.scss';

export type FieldsList = {
  envName: string;
  header?: LocalizationField;
  type: 'select' | 'text';
  selectOptions: string[];
  selectMultiple?: boolean;
  eachRowSelectUniqValue?: boolean;
  arbitrarySelectOptions?: boolean;
  placeholder?: LocalizationField;
  helperText?: LocalizationField;
};

type FieldsCollectionsProps = ITemplateWizardEditField<
  Record<any, any>,
  {
    addRowText: string;
    fields: FieldsList[];
    rows: Record<string, any>[];
    allowNewRows?: boolean;
  }
>;

const FieldsCollections: FC<FieldsCollectionsProps> = ({ envName, onChange, info }) => {
  const [innerValue, setInnerValue] = useState(() => info.rows?.map(addKeyToRow) || []);
  const ref = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    onChange(innerValue, envName, false);
  }, [envName, innerValue, onChange]);

  const onChangeInner = useCallback((index: number, value: any, name: string) => {
    setInnerValue(prev => {
      prev[index] = Object.assign({}, prev[index], { [name]: value });
      return prev.concat();
    });
  }, []);

  const addRow = useCallback(() => {
    const emptyRow = info.fields.reduce((acc, col) => {
      acc[col.envName] = '';
      return acc;
    }, {} as any);
    setInnerValue(prev => [...prev, addKeyToRow(emptyRow)]);
  }, [info.fields]);

  const delRow = useCallback((index: number) => {
    setInnerValue(prev => {
      prev.splice(index, 1);
      return prev.concat();
    });
  }, []);

  const rowData = useMemo(() => {
    return innerValue.map((row, index) => {
      const fields = info.fields.map(field => {
        field = { ...field };
        switch (field.type) {
          case 'select':
            if (field.eachRowSelectUniqValue) {
              const colValue = toArray(row[field.envName]);
              const selectedOptions: string[] = Object.values(innerValue)
                .map(row => row[field.envName])
                .flat(2)
                .filter(option => !colValue.includes(option));
              field.selectOptions = without(field.selectOptions, ...selectedOptions);
            }
            return field;
          case 'text':
          default:
            return field;
        }
      });
      return { ...row, index, fields };
    });
  }, [innerValue, info.fields]);

  return (
    <div className={styles.FieldsCollections} ref={ref}>
      <div className={styles.FieldsCollections__wrapper}>
        {rowData.map(row => (
          <FieldsCollectionsRow key={row.key} row={row} onDelete={delRow} onChange={onChangeInner} />
        ))}
      </div>
      <Button className={styles.addBtn} type='button' color='info' flat onClick={addRow}>
        <Icon name='farPlus' />
        <span>{info.addRowText}</span>
      </Button>
    </div>
  );
};

export default FieldsCollections;
