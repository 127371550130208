// tslint:disable
// @ts-nocheck
/**
 * Botadmin REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */
export enum AimylogicTtsProviderType {
  YANDEX = 'YANDEX',
  AIMYVOICE = 'AIMYVOICE',
}

/**
 *
 * @export
 * @interface AimylogicVoiceReadDto
 */
export interface AimylogicVoiceReadDto {
  /**
   *
   * @type {string}
   * @memberof AimylogicVoiceReadDto
   */
  uniqueName: string;
  /**
   *
   * @type {string}
   * @memberof AimylogicVoiceReadDto
   */
  voiceName: string;
  /**
   *
   * @type {string}
   * @memberof AimylogicVoiceReadDto
   */
  voiceSampleUrl?: string;
  /**
   *
   * @type {AimylogicTtsProviderType}
   * @memberof AimylogicVoiceReadDto
   */
  type: AimylogicTtsProviderType;
}
/**
 *
 * @export
 * @interface AimylogicVoiceWriteDto
 */
export interface AimylogicVoiceWriteDto {
  /**
   *
   * @type {string}
   * @memberof AimylogicVoiceWriteDto
   */
  uniqueName: string;
}
/**
 *
 * @export
 * @interface AimyvoiceVoiceDto
 */
export interface AimyvoiceVoiceDto {
  /**
   *
   * @type {string}
   * @memberof AimyvoiceVoiceDto
   */
  voiceName: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum BotCategory {
  GAMES = 'GAMES',
  NEWS = 'NEWS',
  ECOMMERCE = 'E_COMMERCE',
  SUPPORT = 'SUPPORT',
  SMARTHOME = 'SMART_HOME',
  PRODUCTIVITY = 'PRODUCTIVITY',
  OTHER = 'OTHER',
}

/**
 *
 * @export
 * @interface BotProjectModificationDto
 */
export interface BotProjectModificationDto {
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  repositoryUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  repositoryLogin?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  repositoryPassword?: string;
  /**
   *
   * @type {RepositoryAuthType}
   * @memberof BotProjectModificationDto
   */
  repositoryAuthType?: RepositoryAuthType;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  gitIntegration?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  repositoryNameGitIntegration?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  repositoryUrlGitIntegration?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  branchNameGitIntegration?: string;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectModificationDto
   */
  repositoryIsPrivateGitIntegration?: boolean;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  contentDirectory?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  defaultBranch?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  reportParameters?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  justAiKey?: string;
  /**
   *
   * @type {BotCategory}
   * @memberof BotProjectModificationDto
   */
  botCategory?: BotCategory;
  /**
   *
   * @type {BotType}
   * @memberof BotProjectModificationDto
   */
  botType?: BotType;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectModificationDto
   */
  cleanupDialogs?: boolean;
  /**
   *
   * @type {number}
   * @memberof BotProjectModificationDto
   */
  cleanupOlderThenDays?: number;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectModificationDto
   */
  jaicfProject?: boolean;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  webHookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  externalBotToken?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  externalTemplate?: string;
  /**
   *
   * @type {object}
   * @memberof BotProjectModificationDto
   */
  environment?: object;
  /**
   *
   * @type {object}
   * @memberof BotProjectModificationDto
   */
  projectSettingsData?: object;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectModificationDto
   */
  changePersonalUser?: boolean;
  /**
   *
   * @type {CallsBotType}
   * @memberof BotProjectModificationDto
   */
  callsBotType?: CallsBotType;
  /**
   *
   * @type {string}
   * @memberof BotProjectModificationDto
   */
  voiceUniqueName?: string;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectModificationDto
   */
  skipBranchExistsCheck?: boolean;
}
/**
 *
 * @export
 * @interface BotProjectReadDto
 */
export interface BotProjectReadDto {
  /**
   *
   * @type {number}
   * @memberof BotProjectReadDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  repositoryUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  repositoryLogin?: string;
  /**
   *
   * @type {RepositoryAuthType}
   * @memberof BotProjectReadDto
   */
  repositoryAuthType?: RepositoryAuthType;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  contentDirectory?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  defaultBranch?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  liveChatConfigId?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  product?: string;
  /**
   *
   * @type {object}
   * @memberof BotProjectReadDto
   */
  language?: object;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  reportParameters?: string;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectReadDto
   */
  existsBitrixChannel?: boolean;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  justAiKey?: string;
  /**
   *
   * @type {BotCategory}
   * @memberof BotProjectReadDto
   */
  botCategory?: BotCategory;
  /**
   *
   * @type {BotType}
   * @memberof BotProjectReadDto
   */
  botType?: BotType;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectReadDto
   */
  cleanupDialogs?: boolean;
  /**
   *
   * @type {number}
   * @memberof BotProjectReadDto
   */
  cleanupOlderThenDays?: number;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectReadDto
   */
  jaicfProject?: boolean;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  webHookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  externalBotToken?: string;
  /**
   *
   * @type {object}
   * @memberof BotProjectReadDto
   */
  environment?: object;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  templateName?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadDto
   */
  templateJson?: string;
  /**
   *
   * @type {Array<TagLabelDto>}
   * @memberof BotProjectReadDto
   */
  tagLabels: Array<TagLabelDto>;
  /**
   *
   * @type {Date}
   * @memberof BotProjectReadDto
   */
  lastModificationData?: Date;
  /**
   *
   * @type {Array<ProjectWarning>}
   * @memberof BotProjectReadDto
   */
  warnings?: Array<ProjectWarning>;
  /**
   *
   * @type {CallsBotType}
   * @memberof BotProjectReadDto
   */
  callsBotType?: CallsBotType;
  /**
   *
   * @type {AimylogicVoiceReadDto}
   * @memberof BotProjectReadDto
   */
  aimylogicVoice?: AimylogicVoiceReadDto;
}
/**
 *
 * @export
 * @interface BotProjectReadWithConfigDto
 */
export interface BotProjectReadWithConfigDto {
  /**
   *
   * @type {number}
   * @memberof BotProjectReadWithConfigDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  shortName?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  repositoryUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  repositoryLogin?: string;
  /**
   *
   * @type {RepositoryAuthType}
   * @memberof BotProjectReadWithConfigDto
   */
  repositoryAuthType?: RepositoryAuthType;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  contentDirectory?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  defaultBranch?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  liveChatConfigId?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  product?: string;
  /**
   *
   * @type {object}
   * @memberof BotProjectReadWithConfigDto
   */
  language?: object;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  reportParameters?: string;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectReadWithConfigDto
   */
  existsBitrixChannel?: boolean;
  /**
   *
   * @type {BotCategory}
   * @memberof BotProjectReadWithConfigDto
   */
  botCategory?: BotCategory;
  /**
   *
   * @type {BotType}
   * @memberof BotProjectReadWithConfigDto
   */
  botType?: BotType;
  /**
   *
   * @type {Array<object>}
   * @memberof BotProjectReadWithConfigDto
   */
  botConfigs?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  justAiKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectReadWithConfigDto
   */
  cleanupDialogs?: boolean;
  /**
   *
   * @type {number}
   * @memberof BotProjectReadWithConfigDto
   */
  cleanupOlderThenDays?: number;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectReadWithConfigDto
   */
  jaicfProject?: boolean;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  webHookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  externalBotToken?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  templateName?: string;
  /**
   *
   * @type {string}
   * @memberof BotProjectReadWithConfigDto
   */
  templateJson?: string;
  /**
   *
   * @type {boolean}
   * @memberof BotProjectReadWithConfigDto
   */
  resteriskAcceptIncoming?: boolean;
  /**
   *
   * @type {object}
   * @memberof BotProjectReadWithConfigDto
   */
  environment?: object;
  /**
   *
   * @type {Array<object>}
   * @memberof BotProjectReadWithConfigDto
   */
  tagLabels?: Array<object>;
  /**
   *
   * @type {Date}
   * @memberof BotProjectReadWithConfigDto
   */
  lastModificationData?: Date;
  /**
   *
   * @type {number}
   * @memberof BotProjectReadWithConfigDto
   */
  uniqueClients?: number;
  /**
   *
   * @type {Array<ProjectWarning>}
   * @memberof BotProjectReadWithConfigDto
   */
  warnings?: Array<ProjectWarning>;
  /**
   *
   * @type {CallsBotType}
   * @memberof BotProjectReadWithConfigDto
   */
  callsBotType?: CallsBotType;
  /**
   *
   * @type {AimylogicVoiceReadDto}
   * @memberof BotProjectReadWithConfigDto
   */
  aimylogicVoice?: AimylogicVoiceReadDto;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum BotType {
  CHATBOT = 'CHAT_BOT',
  CALLSBOT = 'CALLS_BOT',
}

/**
 *
 * @export
 * @interface CallListDeleteDto
 */
export interface CallListDeleteDto {
  /**
   *
   * @type {CallListDeleteStatusDto}
   * @memberof CallListDeleteDto
   */
  status: CallListDeleteStatusDto;
  /**
   *
   * @type {CallListSummaryDto}
   * @memberof CallListDeleteDto
   */
  callListData?: CallListSummaryDto;
  /**
   *
   * @type {Array<string>}
   * @memberof CallListDeleteDto
   */
  callListTaskNames: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CallListDeleteStatusDto {
  Ok = 'ok',
  Error = 'error',
}

/**
 *
 * @export
 * @interface CallListSummaryDto
 */
export interface CallListSummaryDto {
  /**
   *
   * @type {number}
   * @memberof CallListSummaryDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CallListSummaryDto
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof CallListSummaryDto
   */
  creationDate: Date;
  /**
   *
   * @type {number}
   * @memberof CallListSummaryDto
   */
  phonesNumbersCount: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CallsBotType {
  DIALER = 'DIALER',
  IVR = 'IVR',
}

/**
 *
 * @export
 * @interface ConnectVonageAccountDto
 */
export interface ConnectVonageAccountDto {
  /**
   *
   * @type {string}
   * @memberof ConnectVonageAccountDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ConnectVonageAccountDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ConnectVonageAccountDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof ConnectVonageAccountDto
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof ConnectVonageAccountDto
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof ConnectVonageAccountDto
   */
  accountEmail: string;
}
/**
 *
 * @export
 * @interface E2ECheckResult
 */
export interface E2ECheckResult {
  /**
   *
   * @type {string}
   * @memberof E2ECheckResult
   */
  totalStatus?: string;
  /**
   *
   * @type {number}
   * @memberof E2ECheckResult
   */
  totalTime?: number;
  /**
   *
   * @type {Array<E2EComponent>}
   * @memberof E2ECheckResult
   */
  e2EComponents?: Array<E2EComponent>;
}
/**
 *
 * @export
 * @interface E2EComponent
 */
export interface E2EComponent {
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof E2EComponent
   */
  startTime: string;
  /**
   *
   * @type {number}
   * @memberof E2EComponent
   */
  elapsedTime: number;
}
/**
 *
 * @export
 * @interface EnableImputerRequestDto
 */
export interface EnableImputerRequestDto {
  /**
   *
   * @type {string}
   * @memberof EnableImputerRequestDto
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof EnableImputerRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof EnableImputerRequestDto
   */
  phone: string;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  file?: any;
  /**
   *
   * @type {any}
   * @memberof InlineObject
   */
  botProjectFile?: any;
}
/**
 *
 * @export
 * @interface LabelPriorityChangeDto
 */
export interface LabelPriorityChangeDto {
  /**
   *
   * @type {number}
   * @memberof LabelPriorityChangeDto
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof LabelPriorityChangeDto
   */
  newPriority: number;
}
/**
 *
 * @export
 * @interface LiveChatConfigDto
 */
export interface LiveChatConfigDto {
  /**
   *
   * @type {number}
   * @memberof LiveChatConfigDto
   */
  id: number;
  /**
   *
   * @type {LiveChatType}
   * @memberof LiveChatConfigDto
   */
  type: LiveChatType;
}
/**
 *
 * @export
 * @interface LiveChatConfigResponse
 */
export interface LiveChatConfigResponse {
  /**
   *
   * @type {string}
   * @memberof LiveChatConfigResponse
   */
  projectShortName: string;
  /**
   *
   * @type {Array<LiveChatConfigDto>}
   * @memberof LiveChatConfigResponse
   */
  livechats?: Array<LiveChatConfigDto>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum LiveChatType {
  WEBIM = 'WEBIM',
  LIVETEX = 'LIVETEX',
  SALESFORCE = 'SALESFORCE',
  JIVOSITE = 'JIVOSITE',
  OPERATORAPI = 'OPERATORAPI',
  CHAT2DESK = 'CHAT2DESK',
}

/**
 *
 * @export
 * @interface LogLabelCreate
 */
export interface LogLabelCreate {
  /**
   *
   * @type {string}
   * @memberof LogLabelCreate
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof LogLabelCreate
   */
  groupId: number;
  /**
   *
   * @type {number}
   * @memberof LogLabelCreate
   */
  priority: number;
}
/**
 *
 * @export
 * @interface LogLabelDto
 */
export interface LogLabelDto {
  /**
   *
   * @type {number}
   * @memberof LogLabelDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof LogLabelDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof LogLabelDto
   */
  groupId: number;
  /**
   *
   * @type {number}
   * @memberof LogLabelDto
   */
  priority: number;
}
/**
 *
 * @export
 * @interface LogLabelGroupCreate
 */
export interface LogLabelGroupCreate {
  /**
   *
   * @type {string}
   * @memberof LogLabelGroupCreate
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof LogLabelGroupCreate
   */
  priority: number;
}
/**
 *
 * @export
 * @interface LogLabelGroupDto
 */
export interface LogLabelGroupDto {
  /**
   *
   * @type {number}
   * @memberof LogLabelGroupDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof LogLabelGroupDto
   */
  name: string;
  /**
   *
   * @type {Array<LogLabelDto>}
   * @memberof LogLabelGroupDto
   */
  labels: Array<LogLabelDto>;
  /**
   *
   * @type {number}
   * @memberof LogLabelGroupDto
   */
  priority: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ProjectWarning {
  GITHUBWRONGACCOUNTTOKEN = 'GITHUB_WRONG_ACCOUNT_TOKEN',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum RepositoryAuthType {
  PERSONALUSER = 'PERSONAL_USER',
  GUESTUSER = 'GUEST_USER',
}

/**
 *
 * @export
 * @interface SessionLabelCreate
 */
export interface SessionLabelCreate {
  /**
   *
   * @type {string}
   * @memberof SessionLabelCreate
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SessionLabelCreate
   */
  priority: number;
}
/**
 *
 * @export
 * @interface SessionLabelDto
 */
export interface SessionLabelDto {
  /**
   *
   * @type {number}
   * @memberof SessionLabelDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SessionLabelDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SessionLabelDto
   */
  priority: number;
}
/**
 *
 * @export
 * @interface TagLabelCreate
 */
export interface TagLabelCreate {
  /**
   *
   * @type {string}
   * @memberof TagLabelCreate
   */
  tagName: string;
}
/**
 *
 * @export
 * @interface TagLabelDto
 */
export interface TagLabelDto {
  /**
   *
   * @type {number}
   * @memberof TagLabelDto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TagLabelDto
   */
  tagName: string;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Initialize account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling initializeAccount.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/initialize`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Initialize account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeAccount(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).initializeAccount(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Initialize account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeAccount(accountId: number, options?: any): AxiosPromise<void> {
      return AccountsApiFp(configuration).initializeAccount(accountId, options)(axios, basePath);
    },
  };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
  /**
   *
   * @summary Initialize account
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public initializeAccount(accountId: number, options?: any) {
    return AccountsApiFp(this.configuration).initializeAccount(accountId, options)(this.axios, this.basePath);
  }
}

/**
 * AimylogicVoiceApi - axios parameter creator
 * @export
 */
export const AimylogicVoiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get the Aimylogic voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVoicesAimylogic(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listVoicesAimylogic.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/aimylogic/voices`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AimylogicVoiceApi - functional programming interface
 * @export
 */
export const AimylogicVoiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get the Aimylogic voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVoicesAimylogic(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AimylogicVoiceReadDto>> {
      const localVarAxiosArgs = AimylogicVoiceApiAxiosParamCreator(configuration).listVoicesAimylogic(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AimylogicVoiceApi - factory interface
 * @export
 */
export const AimylogicVoiceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get the Aimylogic voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVoicesAimylogic(accountId: number, options?: any): AxiosPromise<Array<AimylogicVoiceReadDto>> {
      return AimylogicVoiceApiFp(configuration).listVoicesAimylogic(accountId, options)(axios, basePath);
    },
  };
};

/**
 * AimylogicVoiceApi - object-oriented interface
 * @export
 * @class AimylogicVoiceApi
 * @extends {BaseAPI}
 */
export class AimylogicVoiceApi extends BaseAPI {
  /**
   *
   * @summary Get the Aimylogic voices list
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimylogicVoiceApi
   */
  public listVoicesAimylogic(accountId: number, options?: any) {
    return AimylogicVoiceApiFp(this.configuration).listVoicesAimylogic(accountId, options)(this.axios, this.basePath);
  }
}

/**
 * AimyvoiceApi - axios parameter creator
 * @export
 */
export const AimyvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get the Aimyvoice voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVoicesAimyvoice(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling listVoicesAimyvoice.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/aimyvoice/voices`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AimyvoiceApi - functional programming interface
 * @export
 */
export const AimyvoiceApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get the Aimyvoice voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVoicesAimyvoice(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AimyvoiceVoiceDto>> {
      const localVarAxiosArgs = AimyvoiceApiAxiosParamCreator(configuration).listVoicesAimyvoice(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AimyvoiceApi - factory interface
 * @export
 */
export const AimyvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get the Aimyvoice voices list
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVoicesAimyvoice(accountId: number, options?: any): AxiosPromise<Array<AimyvoiceVoiceDto>> {
      return AimyvoiceApiFp(configuration).listVoicesAimyvoice(accountId, options)(axios, basePath);
    },
  };
};

/**
 * AimyvoiceApi - object-oriented interface
 * @export
 * @class AimyvoiceApi
 * @extends {BaseAPI}
 */
export class AimyvoiceApi extends BaseAPI {
  /**
   *
   * @summary Get the Aimyvoice voices list
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AimyvoiceApi
   */
  public listVoicesAimyvoice(accountId: number, options?: any) {
    return AimyvoiceApiFp(this.configuration).listVoicesAimyvoice(accountId, options)(this.axios, this.basePath);
  }
}

/**
 * BotConfigsApi - axios parameter creator
 * @export
 */
export const BotConfigsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all livechat configs by accountId
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLivechatConfigsByAccountId(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAllLivechatConfigsByAccountId.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/configs/livechat`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BotConfigsApi - functional programming interface
 * @export
 */
export const BotConfigsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get all livechat configs by accountId
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLivechatConfigsByAccountId(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiveChatConfigResponse>> {
      const localVarAxiosArgs = BotConfigsApiAxiosParamCreator(configuration).getAllLivechatConfigsByAccountId(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BotConfigsApi - factory interface
 * @export
 */
export const BotConfigsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get all livechat configs by accountId
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLivechatConfigsByAccountId(accountId: number, options?: any): AxiosPromise<Array<LiveChatConfigResponse>> {
      return BotConfigsApiFp(configuration).getAllLivechatConfigsByAccountId(accountId, options)(axios, basePath);
    },
  };
};

/**
 * BotConfigsApi - object-oriented interface
 * @export
 * @class BotConfigsApi
 * @extends {BaseAPI}
 */
export class BotConfigsApi extends BaseAPI {
  /**
   *
   * @summary Get all livechat configs by accountId
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BotConfigsApi
   */
  public getAllLivechatConfigsByAccountId(accountId: number, options?: any) {
    return BotConfigsApiFp(this.configuration).getAllLivechatConfigsByAccountId(accountId, options)(
      this.axios,
      this.basePath
    );
  }
}

/**
 * ClientListApi - axios parameter creator
 * @export
 */
export const ClientListApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete call list
     * @param {number} accountId Account identifier
     * @param {number} callListId Dialer call list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClientListByDialerId(accountId: number, callListId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteClientListByDialerId.'
        );
      }
      // verify required parameter 'callListId' is not null or undefined
      if (callListId === null || callListId === undefined) {
        throw new RequiredError(
          'callListId',
          'Required parameter callListId was null or undefined when calling deleteClientListByDialerId.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/call-list/{callListId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'callListId'}}`, encodeURIComponent(String(callListId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClientListApi - functional programming interface
 * @export
 */
export const ClientListApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete call list
     * @param {number} accountId Account identifier
     * @param {number} callListId Dialer call list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClientListByDialerId(
      accountId: number,
      callListId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallListDeleteDto> {
      const localVarAxiosArgs = ClientListApiAxiosParamCreator(configuration).deleteClientListByDialerId(
        accountId,
        callListId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ClientListApi - factory interface
 * @export
 */
export const ClientListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Delete call list
     * @param {number} accountId Account identifier
     * @param {number} callListId Dialer call list id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClientListByDialerId(accountId: number, callListId: number, options?: any): AxiosPromise<CallListDeleteDto> {
      return ClientListApiFp(configuration).deleteClientListByDialerId(accountId, callListId, options)(axios, basePath);
    },
  };
};

/**
 * ClientListApi - object-oriented interface
 * @export
 * @class ClientListApi
 * @extends {BaseAPI}
 */
export class ClientListApi extends BaseAPI {
  /**
   *
   * @summary Delete call list
   * @param {number} accountId Account identifier
   * @param {number} callListId Dialer call list id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientListApi
   */
  public deleteClientListByDialerId(accountId: number, callListId: number, options?: any) {
    return ClientListApiFp(this.configuration).deleteClientListByDialerId(
      accountId,
      callListId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Botadmin request for end to end check
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, options: any = {}): RequestArgs {
      // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
      if (loggingEnabledE2ECheck === null || loggingEnabledE2ECheck === undefined) {
        throw new RequiredError(
          'loggingEnabledE2ECheck',
          'Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.'
        );
      }
      const localVarPath = `/e2e-check`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (loggingEnabledE2ECheck !== undefined && loggingEnabledE2ECheck !== null) {
        localVarHeaderParameter['loggingEnabledE2ECheck'] = String(JSON.stringify(loggingEnabledE2ECheck));
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Botadmin request for end to end check
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(
      loggingEnabledE2ECheck: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<E2ECheckResult> {
      const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Botadmin request for end to end check
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    e2ECheck(loggingEnabledE2ECheck: boolean, options?: any): AxiosPromise<E2ECheckResult> {
      return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, options)(axios, basePath);
    },
  };
};

/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
  /**
   *
   * @summary Botadmin request for end to end check
   * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof E2ECheckApi
   */
  public e2ECheck(loggingEnabledE2ECheck: boolean, options?: any) {
    return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, options)(this.axios, this.basePath);
  }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Connect vonage account
     * @param {number} accountId Account identifier
     * @param {ConnectVonageAccountDto} connectVonageAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectVonageAccountRequest(
      accountId: number,
      connectVonageAccountDto: ConnectVonageAccountDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling connectVonageAccountRequest.'
        );
      }
      // verify required parameter 'connectVonageAccountDto' is not null or undefined
      if (connectVonageAccountDto === null || connectVonageAccountDto === undefined) {
        throw new RequiredError(
          'connectVonageAccountDto',
          'Required parameter connectVonageAccountDto was null or undefined when calling connectVonageAccountRequest.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/email/connect-vonage-account`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof connectVonageAccountDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(connectVonageAccountDto !== undefined ? connectVonageAccountDto : {})
        : connectVonageAccountDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Enable imputer feature request for account
     * @param {number} accountId Account identifier
     * @param {EnableImputerRequestDto} enableImputerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableImputerFeatureRequest(
      accountId: number,
      enableImputerRequestDto: EnableImputerRequestDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling enableImputerFeatureRequest.'
        );
      }
      // verify required parameter 'enableImputerRequestDto' is not null or undefined
      if (enableImputerRequestDto === null || enableImputerRequestDto === undefined) {
        throw new RequiredError(
          'enableImputerRequestDto',
          'Required parameter enableImputerRequestDto was null or undefined when calling enableImputerFeatureRequest.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/email/enable-imputer-request`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof enableImputerRequestDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(enableImputerRequestDto !== undefined ? enableImputerRequestDto : {})
        : enableImputerRequestDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Connect vonage account
     * @param {number} accountId Account identifier
     * @param {ConnectVonageAccountDto} connectVonageAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectVonageAccountRequest(
      accountId: number,
      connectVonageAccountDto: ConnectVonageAccountDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EmailApiAxiosParamCreator(configuration).connectVonageAccountRequest(
        accountId,
        connectVonageAccountDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Enable imputer feature request for account
     * @param {number} accountId Account identifier
     * @param {EnableImputerRequestDto} enableImputerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableImputerFeatureRequest(
      accountId: number,
      enableImputerRequestDto: EnableImputerRequestDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = EmailApiAxiosParamCreator(configuration).enableImputerFeatureRequest(
        accountId,
        enableImputerRequestDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Connect vonage account
     * @param {number} accountId Account identifier
     * @param {ConnectVonageAccountDto} connectVonageAccountDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    connectVonageAccountRequest(
      accountId: number,
      connectVonageAccountDto: ConnectVonageAccountDto,
      options?: any
    ): AxiosPromise<void> {
      return EmailApiFp(configuration).connectVonageAccountRequest(
        accountId,
        connectVonageAccountDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Enable imputer feature request for account
     * @param {number} accountId Account identifier
     * @param {EnableImputerRequestDto} enableImputerRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableImputerFeatureRequest(
      accountId: number,
      enableImputerRequestDto: EnableImputerRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return EmailApiFp(configuration).enableImputerFeatureRequest(
        accountId,
        enableImputerRequestDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
  /**
   *
   * @summary Connect vonage account
   * @param {number} accountId Account identifier
   * @param {ConnectVonageAccountDto} connectVonageAccountDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public connectVonageAccountRequest(
    accountId: number,
    connectVonageAccountDto: ConnectVonageAccountDto,
    options?: any
  ) {
    return EmailApiFp(this.configuration).connectVonageAccountRequest(
      accountId,
      connectVonageAccountDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Enable imputer feature request for account
   * @param {number} accountId Account identifier
   * @param {EnableImputerRequestDto} enableImputerRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public enableImputerFeatureRequest(
    accountId: number,
    enableImputerRequestDto: EnableImputerRequestDto,
    options?: any
  ) {
    return EmailApiFp(this.configuration).enableImputerFeatureRequest(
      accountId,
      enableImputerRequestDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * LabelsApi - axios parameter creator
 * @export
 */
export const LabelsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create log label
     * @param {number} accountId Account identifier
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabel(accountId: number, logLabelCreate: LogLabelCreate, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createLabel.'
        );
      }
      // verify required parameter 'logLabelCreate' is not null or undefined
      if (logLabelCreate === null || logLabelCreate === undefined) {
        throw new RequiredError(
          'logLabelCreate',
          'Required parameter logLabelCreate was null or undefined when calling createLabel.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof logLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(logLabelCreate !== undefined ? logLabelCreate : {})
        : logLabelCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create log label group
     * @param {number} accountId Account identifier
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabelGroup(accountId: number, logLabelGroupCreate: LogLabelGroupCreate, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createLabelGroup.'
        );
      }
      // verify required parameter 'logLabelGroupCreate' is not null or undefined
      if (logLabelGroupCreate === null || logLabelGroupCreate === undefined) {
        throw new RequiredError(
          'logLabelGroupCreate',
          'Required parameter logLabelGroupCreate was null or undefined when calling createLabelGroup.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof logLabelGroupCreate !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(logLabelGroupCreate !== undefined ? logLabelGroupCreate : {})
        : logLabelGroupCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create session label
     * @param {number} accountId Account identifier
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSessionLabel(accountId: number, sessionLabelCreate: SessionLabelCreate, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createSessionLabel.'
        );
      }
      // verify required parameter 'sessionLabelCreate' is not null or undefined
      if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
        throw new RequiredError(
          'sessionLabelCreate',
          'Required parameter sessionLabelCreate was null or undefined when calling createSessionLabel.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sessionLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
        : sessionLabelCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabel(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteLabel.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteLabel.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete labels group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabelGroup(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteLabelGroup.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteLabelGroup.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSessionLabel(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteSessionLabel.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteSessionLabel.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get log label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabel(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLabel.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLabel.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelGroup(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLabelGroup.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLabelGroup.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get label groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelGroups(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLabelGroups.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get log labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabels(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLabels.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSessionLabel(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSessionLabel.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getSessionLabel.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSessionLabels(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getSessionLabels.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update groups priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGroupsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateGroupsPriority.'
        );
      }
      // verify required parameter 'labelPriorityChangeDto' is not null or undefined
      if (labelPriorityChangeDto === null || labelPriorityChangeDto === undefined) {
        throw new RequiredError(
          'labelPriorityChangeDto',
          'Required parameter labelPriorityChangeDto was null or undefined when calling updateGroupsPriority.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/change-priority`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof labelPriorityChangeDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
        : labelPriorityChangeDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabel(accountId: number, id: number, logLabelCreate: LogLabelCreate, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateLabel.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateLabel.');
      }
      // verify required parameter 'logLabelCreate' is not null or undefined
      if (logLabelCreate === null || logLabelCreate === undefined) {
        throw new RequiredError(
          'logLabelCreate',
          'Required parameter logLabelCreate was null or undefined when calling updateLabel.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof logLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(logLabelCreate !== undefined ? logLabelCreate : {})
        : logLabelCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update log label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelGroup(
      accountId: number,
      id: number,
      logLabelGroupCreate: LogLabelGroupCreate,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateLabelGroup.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateLabelGroup.');
      }
      // verify required parameter 'logLabelGroupCreate' is not null or undefined
      if (logLabelGroupCreate === null || logLabelGroupCreate === undefined) {
        throw new RequiredError(
          'logLabelGroupCreate',
          'Required parameter logLabelGroupCreate was null or undefined when calling updateLabelGroup.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/groups/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof logLabelGroupCreate !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(logLabelGroupCreate !== undefined ? logLabelGroupCreate : {})
        : logLabelGroupCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update log labels priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLogLabelsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateLogLabelsPriority.'
        );
      }
      // verify required parameter 'labelPriorityChangeDto' is not null or undefined
      if (labelPriorityChangeDto === null || labelPriorityChangeDto === undefined) {
        throw new RequiredError(
          'labelPriorityChangeDto',
          'Required parameter labelPriorityChangeDto was null or undefined when calling updateLogLabelsPriority.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/change-priority`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof labelPriorityChangeDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
        : labelPriorityChangeDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSessionLabel(
      accountId: number,
      id: number,
      sessionLabelCreate: SessionLabelCreate,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateSessionLabel.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateSessionLabel.');
      }
      // verify required parameter 'sessionLabelCreate' is not null or undefined
      if (sessionLabelCreate === null || sessionLabelCreate === undefined) {
        throw new RequiredError(
          'sessionLabelCreate',
          'Required parameter sessionLabelCreate was null or undefined when calling updateSessionLabel.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sessionLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sessionLabelCreate !== undefined ? sessionLabelCreate : {})
        : sessionLabelCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update sessions priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSessionsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateSessionsPriority.'
        );
      }
      // verify required parameter 'labelPriorityChangeDto' is not null or undefined
      if (labelPriorityChangeDto === null || labelPriorityChangeDto === undefined) {
        throw new RequiredError(
          'labelPriorityChangeDto',
          'Required parameter labelPriorityChangeDto was null or undefined when calling updateSessionsPriority.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/logs/labels/sessions/change-priority`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof labelPriorityChangeDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(labelPriorityChangeDto !== undefined ? labelPriorityChangeDto : {})
        : labelPriorityChangeDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LabelsApi - functional programming interface
 * @export
 */
export const LabelsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create log label
     * @param {number} accountId Account identifier
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabel(
      accountId: number,
      logLabelCreate: LogLabelCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createLabel(
        accountId,
        logLabelCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create log label group
     * @param {number} accountId Account identifier
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabelGroup(
      accountId: number,
      logLabelGroupCreate: LogLabelGroupCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelGroupDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createLabelGroup(
        accountId,
        logLabelGroupCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create session label
     * @param {number} accountId Account identifier
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSessionLabel(
      accountId: number,
      sessionLabelCreate: SessionLabelCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionLabelDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).createSessionLabel(
        accountId,
        sessionLabelCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabel(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabel(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete labels group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabelGroup(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteLabelGroup(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSessionLabel(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).deleteSessionLabel(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get log label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabel(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabel(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelGroup(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelGroupDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroup(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get label groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelGroups(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogLabelGroupDto>> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabelGroups(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get log labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabels(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogLabelDto>> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getLabels(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSessionLabel(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionLabelDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabel(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSessionLabels(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionLabelDto>> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).getSessionLabels(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update groups priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGroupsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateGroupsPriority(
        accountId,
        labelPriorityChangeDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabel(
      accountId: number,
      id: number,
      logLabelCreate: LogLabelCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLabel(
        accountId,
        id,
        logLabelCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update log label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelGroup(
      accountId: number,
      id: number,
      logLabelGroupCreate: LogLabelGroupCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogLabelGroupDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLabelGroup(
        accountId,
        id,
        logLabelGroupCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update log labels priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLogLabelsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateLogLabelsPriority(
        accountId,
        labelPriorityChangeDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSessionLabel(
      accountId: number,
      id: number,
      sessionLabelCreate: SessionLabelCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionLabelDto> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionLabel(
        accountId,
        id,
        sessionLabelCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update sessions priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSessionsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LabelsApiAxiosParamCreator(configuration).updateSessionsPriority(
        accountId,
        labelPriorityChangeDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * LabelsApi - factory interface
 * @export
 */
export const LabelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create log label
     * @param {number} accountId Account identifier
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabel(accountId: number, logLabelCreate: LogLabelCreate, options?: any): AxiosPromise<LogLabelDto> {
      return LabelsApiFp(configuration).createLabel(accountId, logLabelCreate, options)(axios, basePath);
    },
    /**
     *
     * @summary Create log label group
     * @param {number} accountId Account identifier
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLabelGroup(
      accountId: number,
      logLabelGroupCreate: LogLabelGroupCreate,
      options?: any
    ): AxiosPromise<LogLabelGroupDto> {
      return LabelsApiFp(configuration).createLabelGroup(accountId, logLabelGroupCreate, options)(axios, basePath);
    },
    /**
     *
     * @summary Create session label
     * @param {number} accountId Account identifier
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSessionLabel(
      accountId: number,
      sessionLabelCreate: SessionLabelCreate,
      options?: any
    ): AxiosPromise<SessionLabelDto> {
      return LabelsApiFp(configuration).createSessionLabel(accountId, sessionLabelCreate, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabel(accountId: number, id: number, options?: any): AxiosPromise<void> {
      return LabelsApiFp(configuration).deleteLabel(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete labels group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLabelGroup(accountId: number, id: number, options?: any): AxiosPromise<void> {
      return LabelsApiFp(configuration).deleteLabelGroup(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSessionLabel(accountId: number, id: number, options?: any): AxiosPromise<void> {
      return LabelsApiFp(configuration).deleteSessionLabel(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get log label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabel(accountId: number, id: number, options?: any): AxiosPromise<LogLabelDto> {
      return LabelsApiFp(configuration).getLabel(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelGroup(accountId: number, id: number, options?: any): AxiosPromise<LogLabelGroupDto> {
      return LabelsApiFp(configuration).getLabelGroup(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get label groups
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabelGroups(accountId: number, options?: any): AxiosPromise<Array<LogLabelGroupDto>> {
      return LabelsApiFp(configuration).getLabelGroups(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get log labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLabels(accountId: number, options?: any): AxiosPromise<Array<LogLabelDto>> {
      return LabelsApiFp(configuration).getLabels(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSessionLabel(accountId: number, id: number, options?: any): AxiosPromise<SessionLabelDto> {
      return LabelsApiFp(configuration).getSessionLabel(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get session labels
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSessionLabels(accountId: number, options?: any): AxiosPromise<Array<SessionLabelDto>> {
      return LabelsApiFp(configuration).getSessionLabels(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update groups priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGroupsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options?: any
    ): AxiosPromise<void> {
      return LabelsApiFp(configuration).updateGroupsPriority(
        accountId,
        labelPriorityChangeDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update log labels
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelCreate} logLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabel(
      accountId: number,
      id: number,
      logLabelCreate: LogLabelCreate,
      options?: any
    ): AxiosPromise<LogLabelDto> {
      return LabelsApiFp(configuration).updateLabel(accountId, id, logLabelCreate, options)(axios, basePath);
    },
    /**
     *
     * @summary Update log label group
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {LogLabelGroupCreate} logLabelGroupCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLabelGroup(
      accountId: number,
      id: number,
      logLabelGroupCreate: LogLabelGroupCreate,
      options?: any
    ): AxiosPromise<LogLabelGroupDto> {
      return LabelsApiFp(configuration).updateLabelGroup(accountId, id, logLabelGroupCreate, options)(axios, basePath);
    },
    /**
     *
     * @summary Update log labels priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLogLabelsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options?: any
    ): AxiosPromise<void> {
      return LabelsApiFp(configuration).updateLogLabelsPriority(
        accountId,
        labelPriorityChangeDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update session label
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {SessionLabelCreate} sessionLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSessionLabel(
      accountId: number,
      id: number,
      sessionLabelCreate: SessionLabelCreate,
      options?: any
    ): AxiosPromise<SessionLabelDto> {
      return LabelsApiFp(configuration).updateSessionLabel(accountId, id, sessionLabelCreate, options)(axios, basePath);
    },
    /**
     *
     * @summary Update sessions priotiry
     * @param {number} accountId Account identifier
     * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSessionsPriority(
      accountId: number,
      labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
      options?: any
    ): AxiosPromise<void> {
      return LabelsApiFp(configuration).updateSessionsPriority(
        accountId,
        labelPriorityChangeDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * LabelsApi - object-oriented interface
 * @export
 * @class LabelsApi
 * @extends {BaseAPI}
 */
export class LabelsApi extends BaseAPI {
  /**
   *
   * @summary Create log label
   * @param {number} accountId Account identifier
   * @param {LogLabelCreate} logLabelCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public createLabel(accountId: number, logLabelCreate: LogLabelCreate, options?: any) {
    return LabelsApiFp(this.configuration).createLabel(accountId, logLabelCreate, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create log label group
   * @param {number} accountId Account identifier
   * @param {LogLabelGroupCreate} logLabelGroupCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public createLabelGroup(accountId: number, logLabelGroupCreate: LogLabelGroupCreate, options?: any) {
    return LabelsApiFp(this.configuration).createLabelGroup(
      accountId,
      logLabelGroupCreate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create session label
   * @param {number} accountId Account identifier
   * @param {SessionLabelCreate} sessionLabelCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public createSessionLabel(accountId: number, sessionLabelCreate: SessionLabelCreate, options?: any) {
    return LabelsApiFp(this.configuration).createSessionLabel(
      accountId,
      sessionLabelCreate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete log labels
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public deleteLabel(accountId: number, id: number, options?: any) {
    return LabelsApiFp(this.configuration).deleteLabel(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete labels group
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public deleteLabelGroup(accountId: number, id: number, options?: any) {
    return LabelsApiFp(this.configuration).deleteLabelGroup(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete session label
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public deleteSessionLabel(accountId: number, id: number, options?: any) {
    return LabelsApiFp(this.configuration).deleteSessionLabel(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get log label
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public getLabel(accountId: number, id: number, options?: any) {
    return LabelsApiFp(this.configuration).getLabel(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get label group
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public getLabelGroup(accountId: number, id: number, options?: any) {
    return LabelsApiFp(this.configuration).getLabelGroup(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get label groups
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public getLabelGroups(accountId: number, options?: any) {
    return LabelsApiFp(this.configuration).getLabelGroups(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get log labels
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public getLabels(accountId: number, options?: any) {
    return LabelsApiFp(this.configuration).getLabels(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get session labels
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public getSessionLabel(accountId: number, id: number, options?: any) {
    return LabelsApiFp(this.configuration).getSessionLabel(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get session labels
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public getSessionLabels(accountId: number, options?: any) {
    return LabelsApiFp(this.configuration).getSessionLabels(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update groups priotiry
   * @param {number} accountId Account identifier
   * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public updateGroupsPriority(accountId: number, labelPriorityChangeDto: Array<LabelPriorityChangeDto>, options?: any) {
    return LabelsApiFp(this.configuration).updateGroupsPriority(
      accountId,
      labelPriorityChangeDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update log labels
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {LogLabelCreate} logLabelCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public updateLabel(accountId: number, id: number, logLabelCreate: LogLabelCreate, options?: any) {
    return LabelsApiFp(this.configuration).updateLabel(
      accountId,
      id,
      logLabelCreate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update log label group
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {LogLabelGroupCreate} logLabelGroupCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public updateLabelGroup(accountId: number, id: number, logLabelGroupCreate: LogLabelGroupCreate, options?: any) {
    return LabelsApiFp(this.configuration).updateLabelGroup(
      accountId,
      id,
      logLabelGroupCreate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update log labels priotiry
   * @param {number} accountId Account identifier
   * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public updateLogLabelsPriority(
    accountId: number,
    labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
    options?: any
  ) {
    return LabelsApiFp(this.configuration).updateLogLabelsPriority(
      accountId,
      labelPriorityChangeDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update session label
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {SessionLabelCreate} sessionLabelCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public updateSessionLabel(accountId: number, id: number, sessionLabelCreate: SessionLabelCreate, options?: any) {
    return LabelsApiFp(this.configuration).updateSessionLabel(
      accountId,
      id,
      sessionLabelCreate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update sessions priotiry
   * @param {number} accountId Account identifier
   * @param {Array<LabelPriorityChangeDto>} labelPriorityChangeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LabelsApi
   */
  public updateSessionsPriority(
    accountId: number,
    labelPriorityChangeDto: Array<LabelPriorityChangeDto>,
    options?: any
  ) {
    return LabelsApiFp(this.configuration).updateSessionsPriority(
      accountId,
      labelPriorityChangeDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete the bot project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling _delete.'
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling _delete.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Clone the bot project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} name Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clone(accountId: number, projectShortName: string, name: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling clone.');
      }
      // verify required parameter 'projectShortName' is not null or undefined
      if (projectShortName === null || projectShortName === undefined) {
        throw new RequiredError(
          'projectShortName',
          'Required parameter projectShortName was null or undefined when calling clone.'
        );
      }
      // verify required parameter 'name' is not null or undefined
      if (name === null || name === undefined) {
        throw new RequiredError('name', 'Required parameter name was null or undefined when calling clone.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/clone`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (projectShortName !== undefined) {
        localVarQueryParameter['projectShortName'] = projectShortName;
      }

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a project
     * @param {number} accountId Account identifier
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      accountId: number,
      botProjectModificationDto: BotProjectModificationDto,
      product?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling create.');
      }
      // verify required parameter 'botProjectModificationDto' is not null or undefined
      if (botProjectModificationDto === null || botProjectModificationDto === undefined) {
        throw new RequiredError(
          'botProjectModificationDto',
          'Required parameter botProjectModificationDto was null or undefined when calling create.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/botproject`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (product !== undefined && product !== null) {
        localVarHeaderParameter['product'] = String(product);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof botProjectModificationDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(botProjectModificationDto !== undefined ? botProjectModificationDto : {})
        : botProjectModificationDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a bot project from zip
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {any} [file]
     * @param {any} [botProjectFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createByZip(accountId: number, product?: string, file?: any, botProjectFile?: any, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createByZip.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/by-zip`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (product !== undefined && product !== null) {
        localVarHeaderParameter['product'] = String(product);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (botProjectFile !== undefined) {
        localVarFormParams.append('botProjectFile', botProjectFile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    read(accountId: number, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling read.');
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling read.');
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all bot projects
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAll(accountId: number, product?: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling readAll.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/botproject`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      if (product !== undefined && product !== null) {
        localVarHeaderParameter['product'] = String(product);
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      accountId: number,
      id: number,
      botProjectModificationDto: BotProjectModificationDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError('accountId', 'Required parameter accountId was null or undefined when calling update.');
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling update.');
      }
      // verify required parameter 'botProjectModificationDto' is not null or undefined
      if (botProjectModificationDto === null || botProjectModificationDto === undefined) {
        throw new RequiredError(
          'botProjectModificationDto',
          'Required parameter botProjectModificationDto was null or undefined when calling update.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/botproject/{id}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof botProjectModificationDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(botProjectModificationDto !== undefined ? botProjectModificationDto : {})
        : botProjectModificationDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete the bot project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotProjectReadDto> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration)._delete(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Clone the bot project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} name Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clone(
      accountId: number,
      projectShortName: string,
      name: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotProjectReadDto> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).clone(
        accountId,
        projectShortName,
        name,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a project
     * @param {number} accountId Account identifier
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      accountId: number,
      botProjectModificationDto: BotProjectModificationDto,
      product?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotProjectReadDto> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).create(
        accountId,
        botProjectModificationDto,
        product,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create a bot project from zip
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {any} [file]
     * @param {any} [botProjectFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createByZip(
      accountId: number,
      product?: string,
      file?: any,
      botProjectFile?: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotProjectReadDto> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).createByZip(
        accountId,
        product,
        file,
        botProjectFile,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    read(
      accountId: number,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotProjectReadDto> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).read(accountId, id, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all bot projects
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAll(
      accountId: number,
      product?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotProjectReadWithConfigDto>> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).readAll(accountId, product, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      accountId: number,
      id: number,
      botProjectModificationDto: BotProjectModificationDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotProjectReadDto> {
      const localVarAxiosArgs = ProjectsApiAxiosParamCreator(configuration).update(
        accountId,
        id,
        botProjectModificationDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Delete the bot project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(accountId: number, id: number, options?: any): AxiosPromise<BotProjectReadDto> {
      return ProjectsApiFp(configuration)._delete(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Clone the bot project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {string} name Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clone(accountId: number, projectShortName: string, name: string, options?: any): AxiosPromise<BotProjectReadDto> {
      return ProjectsApiFp(configuration).clone(accountId, projectShortName, name, options)(axios, basePath);
    },
    /**
     *
     * @summary Create a project
     * @param {number} accountId Account identifier
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(
      accountId: number,
      botProjectModificationDto: BotProjectModificationDto,
      product?: string,
      options?: any
    ): AxiosPromise<BotProjectReadDto> {
      return ProjectsApiFp(configuration).create(
        accountId,
        botProjectModificationDto,
        product,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Create a bot project from zip
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {any} [file]
     * @param {any} [botProjectFile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createByZip(
      accountId: number,
      product?: string,
      file?: any,
      botProjectFile?: any,
      options?: any
    ): AxiosPromise<BotProjectReadDto> {
      return ProjectsApiFp(configuration).createByZip(
        accountId,
        product,
        file,
        botProjectFile,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    read(accountId: number, id: number, options?: any): AxiosPromise<BotProjectReadDto> {
      return ProjectsApiFp(configuration).read(accountId, id, options)(axios, basePath);
    },
    /**
     *
     * @summary Get all bot projects
     * @param {number} accountId Account identifier
     * @param {string} [product] Product type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAll(accountId: number, product?: string, options?: any): AxiosPromise<Array<BotProjectReadWithConfigDto>> {
      return ProjectsApiFp(configuration).readAll(accountId, product, options)(axios, basePath);
    },
    /**
     *
     * @summary Update the project
     * @param {number} accountId Account identifier
     * @param {number} id Object id
     * @param {BotProjectModificationDto} botProjectModificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(
      accountId: number,
      id: number,
      botProjectModificationDto: BotProjectModificationDto,
      options?: any
    ): AxiosPromise<BotProjectReadDto> {
      return ProjectsApiFp(configuration).update(accountId, id, botProjectModificationDto, options)(axios, basePath);
    },
  };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
  /**
   *
   * @summary Delete the bot project
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public _delete(accountId: number, id: number, options?: any) {
    return ProjectsApiFp(this.configuration)._delete(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Clone the bot project
   * @param {number} accountId Account identifier
   * @param {string} projectShortName Project short name
   * @param {string} name Name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public clone(accountId: number, projectShortName: string, name: string, options?: any) {
    return ProjectsApiFp(this.configuration).clone(
      accountId,
      projectShortName,
      name,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a project
   * @param {number} accountId Account identifier
   * @param {BotProjectModificationDto} botProjectModificationDto
   * @param {string} [product] Product type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public create(
    accountId: number,
    botProjectModificationDto: BotProjectModificationDto,
    product?: string,
    options?: any
  ) {
    return ProjectsApiFp(this.configuration).create(
      accountId,
      botProjectModificationDto,
      product,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create a bot project from zip
   * @param {number} accountId Account identifier
   * @param {string} [product] Product type
   * @param {any} [file]
   * @param {any} [botProjectFile]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public createByZip(accountId: number, product?: string, file?: any, botProjectFile?: any, options?: any) {
    return ProjectsApiFp(this.configuration).createByZip(
      accountId,
      product,
      file,
      botProjectFile,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get the project
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public read(accountId: number, id: number, options?: any) {
    return ProjectsApiFp(this.configuration).read(accountId, id, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get all bot projects
   * @param {number} accountId Account identifier
   * @param {string} [product] Product type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public readAll(accountId: number, product?: string, options?: any) {
    return ProjectsApiFp(this.configuration).readAll(accountId, product, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update the project
   * @param {number} accountId Account identifier
   * @param {number} id Object id
   * @param {BotProjectModificationDto} botProjectModificationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectsApi
   */
  public update(accountId: number, id: number, botProjectModificationDto: BotProjectModificationDto, options?: any) {
    return ProjectsApiFp(this.configuration).update(
      accountId,
      id,
      botProjectModificationDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Botadmin healthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options: any = {}): RequestArgs {
      const localVarPath = `/healthCheck`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Botadmin healthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Botadmin healthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): AxiosPromise<string> {
      return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
    },
  };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
  /**
   *
   * @summary Botadmin healthCheck
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public healthCheck(options?: any) {
    return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
  }
}

/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create tag for account
     * @param {number} accountId Account identifier
     * @param {TagLabelCreate} tagLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTagForAccount(accountId: number, tagLabelCreate: TagLabelCreate, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createTagForAccount.'
        );
      }
      // verify required parameter 'tagLabelCreate' is not null or undefined
      if (tagLabelCreate === null || tagLabelCreate === undefined) {
        throw new RequiredError(
          'tagLabelCreate',
          'Required parameter tagLabelCreate was null or undefined when calling createTagForAccount.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/tags`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof tagLabelCreate !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(tagLabelCreate !== undefined ? tagLabelCreate : {})
        : tagLabelCreate || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete tag for account
     * @param {number} accountId Account identifier
     * @param {number} tagId Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTagForAccount(accountId: number, tagId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteTagForAccount.'
        );
      }
      // verify required parameter 'tagId' is not null or undefined
      if (tagId === null || tagId === undefined) {
        throw new RequiredError(
          'tagId',
          'Required parameter tagId was null or undefined when calling deleteTagForAccount.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/tags/{tagId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'tagId'}}`, encodeURIComponent(String(tagId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Getting all tags for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTagsForAccount(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAllTagsForAccount.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/tags`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update tags for project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagsForProject(
      accountId: number,
      projectShortName: string,
      requestBody: Array<number>,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateTagsForProject.'
        );
      }
      // verify required parameter 'projectShortName' is not null or undefined
      if (projectShortName === null || projectShortName === undefined) {
        throw new RequiredError(
          'projectShortName',
          'Required parameter projectShortName was null or undefined when calling updateTagsForProject.'
        );
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateTagsForProject.'
        );
      }
      const localVarPath = `/api/botadmin/accounts/{accountId}/project/{projectShortName}/tags`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'projectShortName'}}`, encodeURIComponent(String(projectShortName)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === 'function' ? configuration.accessToken() : configuration.accessToken;
        localVarHeaderParameter['Authorization'] = 'Bearer ' + accessToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create tag for account
     * @param {number} accountId Account identifier
     * @param {TagLabelCreate} tagLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTagForAccount(
      accountId: number,
      tagLabelCreate: TagLabelCreate,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagLabelDto> {
      const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).createTagForAccount(
        accountId,
        tagLabelCreate,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete tag for account
     * @param {number} accountId Account identifier
     * @param {number} tagId Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTagForAccount(
      accountId: number,
      tagId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).deleteTagForAccount(accountId, tagId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Getting all tags for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTagsForAccount(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagLabelDto>> {
      const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).getAllTagsForAccount(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update tags for project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagsForProject(
      accountId: number,
      projectShortName: string,
      requestBody: Array<number>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TagsApiAxiosParamCreator(configuration).updateTagsForProject(
        accountId,
        projectShortName,
        requestBody,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Create tag for account
     * @param {number} accountId Account identifier
     * @param {TagLabelCreate} tagLabelCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTagForAccount(accountId: number, tagLabelCreate: TagLabelCreate, options?: any): AxiosPromise<TagLabelDto> {
      return TagsApiFp(configuration).createTagForAccount(accountId, tagLabelCreate, options)(axios, basePath);
    },
    /**
     *
     * @summary Delete tag for account
     * @param {number} accountId Account identifier
     * @param {number} tagId Tag id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTagForAccount(accountId: number, tagId: number, options?: any): AxiosPromise<void> {
      return TagsApiFp(configuration).deleteTagForAccount(accountId, tagId, options)(axios, basePath);
    },
    /**
     *
     * @summary Getting all tags for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTagsForAccount(accountId: number, options?: any): AxiosPromise<Array<TagLabelDto>> {
      return TagsApiFp(configuration).getAllTagsForAccount(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update tags for project
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {Array<number>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTagsForProject(
      accountId: number,
      projectShortName: string,
      requestBody: Array<number>,
      options?: any
    ): AxiosPromise<void> {
      return TagsApiFp(configuration).updateTagsForProject(
        accountId,
        projectShortName,
        requestBody,
        options
      )(axios, basePath);
    },
  };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
  /**
   *
   * @summary Create tag for account
   * @param {number} accountId Account identifier
   * @param {TagLabelCreate} tagLabelCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public createTagForAccount(accountId: number, tagLabelCreate: TagLabelCreate, options?: any) {
    return TagsApiFp(this.configuration).createTagForAccount(
      accountId,
      tagLabelCreate,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete tag for account
   * @param {number} accountId Account identifier
   * @param {number} tagId Tag id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public deleteTagForAccount(accountId: number, tagId: number, options?: any) {
    return TagsApiFp(this.configuration).deleteTagForAccount(accountId, tagId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Getting all tags for account
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public getAllTagsForAccount(accountId: number, options?: any) {
    return TagsApiFp(this.configuration).getAllTagsForAccount(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update tags for project
   * @param {number} accountId Account identifier
   * @param {string} projectShortName Project short name
   * @param {Array<number>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public updateTagsForProject(accountId: number, projectShortName: string, requestBody: Array<number>, options?: any) {
    return TagsApiFp(this.configuration).updateTagsForProject(
      accountId,
      projectShortName,
      requestBody,
      options
    )(this.axios, this.basePath);
  }
}
