import React, { FormEvent } from 'react';
import localize from 'localization';
import { connect } from 'react-redux';
import DefaultEditForm from '../DefaultEditForm';
import { MetaChannelTokenDto, MetaChannelTokenRequiredDto } from 'modules/ChatadapterApi/api/client';
import { FacebookService, Response } from 'modules/ChatadapterApi/service/FacebookService';
import { Spinner } from '@just-ai/just-ui';
import classes from './FacebookEditForm.module.scss';
import { addSnackbar } from 'actions/snackbars.actions';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';

const { translate: t } = localize;

declare global {
  interface Window {
    fbAsyncInit: any;
    FB: any;
  }
}

type AppConfig = {
  zenflow?: {
    companyName: string;
  };
  metaSettings: {
    appId: string;
  };
  botConfigs: BotConfigs;
  currentUser: { account: { id: number } };
};

type StateProps = { appConfig: AppConfig };
type BotConfigs = { channelType: string; id: number }[];

type Props = StateProps & {
  appId?: string;
  editableChannel: { id: number };
  dispatch: (value: {
    type: string;
    snackbar: {
      message: string;
    };
  }) => void;
};

export class FacebookEditForm extends DefaultEditForm {
  appId = '';
  appConfig = {};
  companyName = '';
  channelType = 'FACEBOOK';
  botConfigs = [] as BotConfigs;
  host = 'https://help.ds.tovie.ai';
  scope = 'pages_show_list,pages_manage_metadata,pages_messaging';
  editableChannel = {} as { id: number };
  facebookService = {} as FacebookService;
  modalClassName = classes.facebookModal;

  constructor(props: Props) {
    super(props);
    const { appConfig } = props;

    this.appConfig = appConfig;
    this.appId = appConfig.metaSettings?.appId;
    this.editableChannel = props.editableChannel;
    this.botConfigs = props.appConfig.botConfigs;
    this.companyName = props.appConfig.zenflow?.companyName || '';
    this.facebookService = new FacebookService(props.appConfig.currentUser.account.id);

    this.defaultFields = {
      name: !!props.editableChannel.id,
      botId: true,
      type: true,
    };

    this.state = {
      isLoading: true,
      rollout: true,
      accessToken: '',
      userID: '',
      errors: [],
      bannerDescription: '',
      customSubmitTitle: '',
      startDialogueWithUserMessage: true,
      forceReply: false,
      blockForm: false,
      access_token: '',
      helpUrl: `${this.host}/docs/${this.getLanguage()}/publication_channels`,
    };
  }

  getBannerDescription = () => t('Facebook:Banner:Message', `${this.state.helpUrl}/facebook`);

  updateCustomSubmitTitle = (isLoading: boolean) =>
    this.setState({
      isLoading,
      customSubmitTitle: (
        <span className={classes.facebookButton}>
          {t('Auth:Facebook:Continue')}
          {isLoading && <Spinner inline />}
        </span>
      ),
    });

  getLanguage = () => 'en';

  componentDidMount() {
    this.updateCustomSubmitTitle(true);
    this.setState(
      {
        helpUrl: `${this.host}/docs/${this.getLanguage()}/publication_channels`,
      },
      () => {
        this.setState({
          bannerDescription: this.getBannerDescription(),
          errors: [this.appId ? '' : t('Auth:Facebook:Connection:Fail')],
        });
      }
    );

    if (this.editableChannel.id) {
      this.updateCustomSubmitTitle(false);
      return;
    }

    if (!document.getElementById('fb-root') && this.appId) {
      const fbDiv = document.createElement('div');
      fbDiv.id = 'fb-root';
      document.body.appendChild(fbDiv);

      window.fbAsyncInit = () => {
        window.FB.init({ appId: this.appId, cookie: true, xfbml: true, version: 'v15.0' });
        this.updateCustomSubmitTitle(false);
      };
      (function (d) {
        const script = d.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://connect.facebook.net/en_US/sdk.js`;
        d.getElementsByTagName('head')[0].appendChild(script);
      })(document);
    } else {
      this.updateCustomSubmitTitle(false);
    }
  }

  failed = (error: string) => {
    this.setState({ errors: [error] });
    this.updateCustomSubmitTitle(false);
  };

  statusChangeCallback = (response: Response) => {
    if (response.status === 'connected') {
      const { userID, accessToken } = response.authResponse;
      this.setState({ userID, accessToken });
      this.connectFacebook();
    } else {
      this.failed(t('Auth:Facebook:Connection:Fail'));
    }
  };

  removeAllSameChannels = async () => {
    const facebookChannels = this.botConfigs.filter(config => config.channelType === this.channelType);
    return Promise.all(facebookChannels.map(({ id }) => this.props.removeChannel(id)));
  };

  handleFacebookAccount = async (response: Response) => {
    const error = t('Auth:Facebook:Account:Fail');
    const goToHelp = () => {
      window.open(`${this.state.helpUrl}/instagram`, '_blank');
    };
    const showError = () =>
      this.props.dispatch(
        addSnackbar(
          t('Auth:NoChannels:Instagram', this.companyName),
          'error',
          true,
          t('How:Connect:Instagram'),
          goToHelp
        )
      );

    if (response.data.length === 0 && this.channelType === 'INSTAGRAM') {
      showError();
    }

    if (!response?.error) {
      const payload = {
        token: this.state.accessToken,
        userId: this.state.userID,
        pageIds: [] as string[],
      };

      if (this.channelType === 'FACEBOOK') {
        payload.pageIds = response.data.map(({ id }) => id);
        this.getTokensAndCreateChannels(payload);
      } else {
        const pagesByInstagramId = await this.facebookService.getPagesByInstagramBussinessAccounts(
          response,
          this.state.accessToken
        );
        if (isEmpty(pagesByInstagramId)) {
          showError();
        }
        Object.keys(pagesByInstagramId).forEach(instagramId =>
          this.getTokensAndCreateChannels({ ...payload, pageIds: pagesByInstagramId[instagramId] }, instagramId)
        );
      }
    }

    if (response?.error) {
      this.failed(error);
      console.log(`${error}: ${JSON.stringify(response.error)}`);
    }

    this.updateCustomSubmitTitle(false);
  };

  connectFacebook = () => this.facebookService.getFacebookAccounts(this.handleFacebookAccount);

  getTokensAndCreateChannels = async (payload: MetaChannelTokenRequiredDto, instagramPageId?: string) => {
    const tokens = await this.facebookService.sendFacebookData(payload, this.channelType.toLowerCase());
    if (tokens) {
      await this.removeAllSameChannels();
      await this.createChannels(tokens, instagramPageId);
    }
  };

  createChannels = (tokens: MetaChannelTokenDto[] | null, instagramPageId?: string) => {
    if (tokens) {
      return Promise.all(
        tokens.map(({ token, pageName, pageId }) =>
          this.props.createChannel({
            ...this.editableChannel,
            accessToken: token,
            description: pageName,
            name: pageName,
            botName: pageName,
            customData: instagramPageId ? { instagramPageId } : { facebookPageId: pageId },
          })
        )
      );
    }
  };

  reLogin = () => {
    this.setState({ isLoading: true });
    try {
      if (!window.FB) {
        throw new Error(t('Auth:Facebook:Connection:Fail'));
      }

      window.FB.getLoginStatus((response: Response) => {
        this.setState({ isLoading: true });

        if (response.authResponse) {
          window.FB.logout(this.login);
        } else {
          this.login();
        }
      });
    } catch (error) {
      this.failed(t('Auth:Facebook:Account:Fail'));
    }
  };

  login = async () => {
    try {
      const accessToken = await window.FB.getAccessToken();
      const userId = await window.FB.getUserID();

      if (accessToken && userId) {
        window.FB.getLoginStatus(this.statusChangeCallback);
      } else {
        await window.FB.login(this.checkLoginState, {
          scope: this.scope,
          display: 'popup',
        });
      }
    } catch (error) {
      this.failed(t('Auth:Facebook:Account:Fail'));
    }
  };

  checkLoginState = () => window.FB.getLoginStatus(this.statusChangeCallback);

  renderInputs = () => <></>;

  submit = async (e: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      if (!this.props.editableChannel.accessToken) {
        this.reLogin();
      } else if (Boolean(this.props.editableChannel.id)) {
        await this.saveChannel();
      } else {
        await this.createChannel();
      }
    } catch (e) {
      console.error(e);
    }

    this.setState({ fetching: false });
  };
}

export const mapStateToProps = (state: {
  AppConfigReducer: AppConfig;
  CurrentProjectsReducer: any;
  CurrentUserReducer: any;
}): StateProps => {
  return {
    appConfig: {
      zenflow: state.AppConfigReducer.zenflow,
      metaSettings: state.AppConfigReducer.metaSettings,
      botConfigs: state.CurrentProjectsReducer.currentBot.botConfigs || [],
      currentUser: state.CurrentUserReducer.currentUser,
    },
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      addSnackbar: addSnackbar,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookEditForm as never);
