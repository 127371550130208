import { SOCKETS_CONNECTING, SOCKETS_DISCONNECTING, SOCKETS_MESSAGE_SENDING } from './socketActionsConstants';

const initialState = {
  loaded: false,
  message: 'Init...',
  connected: false,
};

export default function socketReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SOCKETS_CONNECTING:
      return {
        ...state,
        loaded: true,
        message: 'Connecting...',
        connected: false,
      };
    case SOCKETS_DISCONNECTING:
      return {
        ...state,
        loaded: true,
        message: 'Disconnecting...',
        connected: false,
      };
    case SOCKETS_MESSAGE_SENDING:
      return {
        ...state,
        loaded: true,
        message: 'Send message',
        connected: true,
      };

    default:
      return {
        ...state,
      };
  }
}
