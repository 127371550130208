import {
  ADD_GLOBAL_ALERT,
  ADD_SNACKBAR,
  DISMISS_ALL,
  DISMISS_SNACKBAR,
  REMOVE_GLOBAL_ALERT,
  CHANGE_SNACKBAR,
} from '../../constants/snackbars.actions';

export const addSnackbar = (
  message,
  snacktype = 'info',
  componentType = false,
  buttonText = null,
  buttonAction = () => {}
) => {
  const snackbar = {
    message: message,
  };

  if (componentType) {
    snackbar.componentType = componentType;
  }

  if (snacktype) {
    snackbar.type = snacktype;
  }

  if (buttonText) {
    snackbar.buttonText = buttonText;
  }

  if (buttonAction) {
    snackbar.buttonAction = buttonAction;
  }

  return {
    type: ADD_SNACKBAR,
    snackbar,
  };
};

export const changeSnackbar = (id, snackbarData) => {
  return {
    type: CHANGE_SNACKBAR,
    id: id,
    snackbarData,
  };
};

export const dismissSnackbar = id => {
  return {
    type: DISMISS_SNACKBAR,
    id: id,
  };
};

export const dismissAllSnackbars = () => {
  return {
    type: DISMISS_ALL,
  };
};

export const addGlobalAlert = alert => {
  return {
    type: ADD_GLOBAL_ALERT,
    globalAlert: alert,
  };
};

export const dismissGlobalAlert = () => {
  return {
    type: REMOVE_GLOBAL_ALERT,
  };
};
