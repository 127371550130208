import {
  SELECT_ITEM,
  DESELECT_ITEM,
  FILTER_DATA,
  REMOVE_ADD_ACCOUNT_ALERT,
  LOGIN_TO_ACCOUNT,
  LOGOUT_FROM_ACCOUNT,
  CHANGE_CURRENT_LOGIN_ACCOUNT,
  DROP_FILTER,
  SET_CURRENT_LOGIN_ACCOUNT,
  DROP_CURRENT_LOGIN_ACCOUNT,
  NEW_SELECT_FEATURES,
} from '../../constants/accounts.actions';

import { isInternal, isLoggedIn } from '../../isAccessFunction';

import { axios } from '../../pipes/functions';

export const select = id => {
  return {
    type: SELECT_ITEM,
    id,
  };
};

export const getCurrentLoginAccount = () => {
  let loggedInAccount,
    loggedInAccountFeatures = null;
  if (isLoggedIn() && isInternal()) {
    try {
      loggedInAccount = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT'));
      loggedInAccountFeatures = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT_FEATURES'));

      if (!loggedInAccount) {
        loggedInAccountFeatures = null;
        loggedInAccount = null;
      }
    } catch (e) {
      loggedInAccount = null;
      loggedInAccountFeatures = null;
    }
  }

  return {
    type: SET_CURRENT_LOGIN_ACCOUNT,
    obj: loggedInAccount,
    features: loggedInAccountFeatures,
  };
};

export const setCurrentLoginAccount = (obj, features) => {
  return {
    type: SET_CURRENT_LOGIN_ACCOUNT,
    obj: obj,
    features: features,
  };
};

export const changeAccount = newAccountId => {
  return {
    type: CHANGE_CURRENT_LOGIN_ACCOUNT,
    payload: axios.get(`/restapi/accounts/${newAccountId}/login`, {
      params: {
        accountId: newAccountId,
      },
    }),
  };
};

export const dropCurrentLoginAccount = () => {
  return {
    type: DROP_CURRENT_LOGIN_ACCOUNT,
  };
};
export const deselect = () => {
  return {
    type: DESELECT_ITEM,
  };
};
export const setFilter = (what, value) => {
  return {
    type: FILTER_DATA,
    what,
    value,
  };
};
export const dropFilter = () => {
  return {
    type: DROP_FILTER,
  };
};
export const removeAddAccountAlert = key => {
  return {
    type: REMOVE_ADD_ACCOUNT_ALERT,
    key,
  };
};

export const loginToAccount = accountId => {
  return {
    type: LOGIN_TO_ACCOUNT,
    payload: axios.get(`/restapi/accounts/${accountId}/login`, {
      params: {
        accountId: accountId,
      },
    }),
  };
};

export const logoutFromAccount = () => {
  return {
    type: LOGOUT_FROM_ACCOUNT,
    payload: axios.get(`/restapi/accounts/logout`),
  };
};

export const addNewSelectFeatures = (name, data) => {
  return {
    type: NEW_SELECT_FEATURES,
    data: {
      name,
      data,
    },
  };
};
