import React, { Component } from 'react';
import history from 'appHistory';
import { Card, ProgressButton, ActionConfirmModal } from 'altreidsds';
import localize from '../../../localization';
import {
  channelTypesByLang,
  commonChannels,
  allChannels,
  getChannelIconType,
  pseudoOperatorChannels,
} from '../../../constants/channelTypes';
import { getAvailableTariffChannels, isEuroInstance } from '../../../isAccessFunction';
import { Button, ChannelIcon, Badge, Icon } from '@just-ai/just-ui';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import classes from './ChannelItem.module.scss';
import WizardTooltip from '../../../modules/TemplatesWizard/primitives/WizardTooltip';

const { translate } = localize;

const SUCCESS_LABELS = {
  [allChannels.ZENBOX]: "URL Aimybox's webhook is copied to clipboard",
  [allChannels.SBER]: "URL Salut's webhook is copied to clipboard",
  [allChannels.YANDEX]: "URL Alisa's webhook is copied to clipboard",
  [allChannels.FACEBOOK]: 'URL Facebook webhook is copied to clipboard',
  [allChannels.INSTAGRAM]: 'URL Instagram webhook is copied to clipboard',
  [allChannels.CHAT_WIDGET]: 'Widget insertion code is copied to clipboard',
  [allChannels.CHAT_API]: 'Chat API token is copied to clipboard',
  [allChannels.BITRIX]: 'URL Bitrix24 webhook is copied to clipboard',
  [allChannels.I_DIGITAL]: 'URL WhatsApp webhook is copied to clipboard',
  [allChannels.MARUSIA]: 'URL MARUSIA webhook is copied to clipboard',
  [allChannels.AZURE]: 'URL AZURE webhook is copied to clipboard',
  [allChannels.THREADS]: 'URL THREADS webhook is copied to clipboard',
  [allChannels.INCOMING_WEBIM2]: 'URL INCOMING_WEBIM2 webhook is copied to clipboard',
  [allChannels.INBOUND_LIVETEX]: 'URL INBOUND_LIVETEX webhook is copied to clipboard',
  [allChannels.WHATSAPP]: 'URL WhatsApp webhook is copied to clipboard',
};

const statusToColorMap = {
  ok: 'green',
  test_failed: 'red',
  deploy_failed: 'red',
  unavailable: 'gray',
  outdated: 'yellow',
  in_progress: 'blue',
};

const statusToIconMap = {
  ok: 'farCheck',
  test_failed: 'farExclamationTriangle',
  deploy_failed: 'farExclamationTriangle',
  unavailable: 'farBan',
  outdated: 'farExclamationCircle',
  in_progress: 'farSync',
};

const getStatusColor = status => {
  return statusToColorMap[status.toLowerCase()] ?? 'gray';
};

const getStatusIcon = status => {
  return statusToIconMap[status.toLowerCase()] ?? 'farBan';
};

export default class ChannelItem extends Component {
  refInput = null;

  state = {
    text: '',
    loading: false,
    openDeployLogModal: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { channel, projectShortName, currentBot } = this.props;
    const { text, loading, openDeployLogModal } = this.state;

    return (
      !isEqual(channel, nextProps.channel) ||
      projectShortName !== nextProps.projectShortName ||
      !isEqual(currentBot, nextProps.currentBot) ||
      text !== nextState.text ||
      loading !== nextState.loading ||
      openDeployLogModal !== nextState.openDeployLogModal
    );
  }

  copy = () => {
    let supported = document.queryCommandSupported('copy');
    if (supported) {
      this.refInput.select();
      this.refInput.focus();
      window.document.execCommand('copy');
      this.success();
    }
  };

  success = () => {
    const {
      addSnackbar,
      channel: { channelType },
    } = this.props;
    if (SUCCESS_LABELS[channelType]) {
      return addSnackbar(translate(SUCCESS_LABELS[channelType]), 'success');
    }
  };

  copyLog = () => {
    const { channel, addSnackbar } = this.props;
    if (Boolean(this.logInput) && channel.lastDeployResult.logOutput.length > 0) {
      this.logInput.value = channel.lastDeployResult.logOutput;
      let supported = document.queryCommandSupported('copy');
      if (supported) {
        // ... select action
        this.logInput.select();
        this.logInput.focus();
        window.document.execCommand('copy');
        addSnackbar(translate('Deploy log is copied to clipboard'), 'success');
      }
    }
  };

  showDeployLog = () => this.setState({ openDeployLogModal: true });

  hideDeployLog = () => this.setState({ openDeployLogModal: false });

  publishChannel = () => {
    const { publishChannel, channel } = this.props;
    return publishChannel(channel.id);
  };

  setChannelToEdit = () => {
    const { channel, setChannelToEdit, projectShortName } = this.props;
    const { channelType, id } = channel;

    if (channelType === commonChannels.CHAT_WIDGET) {
      history.push(`/chatwidgetEditor/${projectShortName}/${id}`);
    } else {
      setChannelToEdit(id);
    }
  };

  onPublish = async instance => {
    const { deployments, currentBot, setDeployments, channel, channels } = this.props;

    const currentBotDeployments = deployments[currentBot.projectShortName] || [];
    const newDeployments = currentBotDeployments.includes(channel.botserverBotId)
      ? [...currentBotDeployments]
      : [...currentBotDeployments, channel.botserverBotId];

    setDeployments(currentBot.shortName, newDeployments);

    let progress = 0;
    let interval = setInterval(() => {
      progress = Math.min(progress + Math.random() * 0.1, 1);
      instance._setProgress(progress);
    }, 300);

    try {
      const { value } = await this.publishChannel();
      if (
        value?.data?.lastDeployResult?.status === 'DEPLOY_FAILED' ||
        value?.data?.lastDeployResult?.status === 'TEST_FAILED'
      ) {
        instance._stop('error');
      } else {
        instance._stop('success');
      }
      clearInterval(interval);
    } catch (e) {
      instance._stop('error');
      clearInterval(interval);
    }
  };

  renderLink = (channel, channelType, projectShortName) => {
    const {
      YANDEX,
      FACEBOOK,
      INSTAGRAM,
      ZENBOX,
      I_DIGITAL,
      MARUSIA,
      CHAT_WIDGET,
      ALEXA,
      TELEGRAM,
      GOOGLE,
      CHAT_API,
      AZURE,
      INCOMING_WEBIM2,
      THREADS,
      SBER,
      VIBER,
      INBOUND_LIVETEX,
      WHATSAPP,
      WAZZUP,
    } = allChannels;

    const defaultLinkProps = { target: '_blank', rel: 'noopener noreferrer' };
    let linkProps = {};
    if (
      [
        INCOMING_WEBIM2,
        YANDEX,
        FACEBOOK,
        INSTAGRAM,
        ZENBOX,
        I_DIGITAL,
        MARUSIA,
        AZURE,
        CHAT_WIDGET,
        CHAT_API,
        SBER,
        THREADS,
        INBOUND_LIVETEX,
        WHATSAPP,
      ].includes(channelType)
    ) {
      linkProps = {
        onClick: this.copy,
        children: translate(
          (channelType === CHAT_API && 'Copy token') || (channelType === CHAT_WIDGET && 'Get script') || 'Get webhook'
        ),
      };
    } else if (channelType === ALEXA) {
      linkProps = {
        ...defaultLinkProps,
        href: `https://developer.amazon.com/alexa/console/ask/test/${
          JSON.parse(channel.accessToken)[0]
        }/development/${JSON.parse(channel.accessToken)[1].replace('-', '_')}`,
        children: translate('Open Test console'),
      };
    } else if (channelType === TELEGRAM && Boolean(channel.link)) {
      linkProps = {
        ...defaultLinkProps,
        href: channel.link,
        children: (
          <span>
            @{channel.botName} <Icon className='ml-2' size='sm' name='farExternalLink' />
          </span>
        ),
      };
    } else if (channelType === GOOGLE) {
      linkProps = {
        ...defaultLinkProps,
        href: `/restapi/botconfig/google/${projectShortName}/${channel.accessToken}/action.json`,
        children: translate('Get actions json'),
      };
    } else if (channelType === VIBER) {
      if (channel.link) {
        linkProps = {
          ...defaultLinkProps,
          href: channel.link,
          children: (
            <span>
              @{channel.botName} <Icon className='pl-2' size='sm' name='farExternalLink' />
            </span>
          ),
        };
      }
    }

    if (!linkProps.children) return <div></div>;

    const { webhookUrl, chatWidgetScriptUrl } = channel;
    const value = chatWidgetScriptUrl ? `<script src="${chatWidgetScriptUrl}?force=true" async></script>` : webhookUrl;

    return (
      <div>
        <a className={classes.getScriptBtn} {...linkProps} />
        <input
          value={value}
          type='text'
          ref={input => (this.refInput = input)}
          readOnly
          className={classes.hiddenInput}
        />
      </div>
    );
  };

  render() {
    const {
      channel,
      projectShortName,
      currentBot,
      blocked: propsBlocked,
      currentUserTariffUniqueName,
      openRemoveModal,
      isOperatorChannel,
      appConfig,
    } = this.props;

    const { channelType, type, outdated, lastDeployResult, widgetOptions, botName, senderName, description, id } =
      channel;

    let language = 'EN';
    if (currentBot && currentBot.language) {
      language = currentBot.language;
    }

    const currentAvailableChannels = channelTypesByLang[language];
    const { YANDEX, I_DIGITAL, MARUSIA, CHAT_WIDGET, AZURE } = currentAvailableChannels;

    let tariffUniqueName = currentUserTariffUniqueName;
    if (tariffUniqueName?.indexOf('archived') > 0) {
      tariffUniqueName = tariffUniqueName.slice(0, tariffUniqueName.indexOf('archived') - 1);
    }

    const notSupportByCurrentTariff =
      getAvailableTariffChannels()[tariffUniqueName || 'yoga']?.includes(channelType) === false;
    const blocked = propsBlocked || notSupportByCurrentTariff;

    let status = 'OK';

    if (Boolean(channel.lastDeployResult)) {
      if (['IN_PROGRESS', 'TEST_FAILED'].includes(lastDeployResult.status)) {
        status = lastDeployResult.status;
      } else if (lastDeployResult.status === 'DEPLOY_FAILED' || lastDeployResult.status === 'UNAVAILABLE') {
        status = 'DEPLOY_FAILED';
      } else if (outdated || lastDeployResult.status === 'OUTDATED' || lastDeployResult.contentOutdated) {
        status = 'OUTDATED';
      }
    } else {
      status = 'IN_PROGRESS';
    }

    const showPublishButton = !((status === 'OK' && !outdated) || status === 'IN_PROGRESS');

    let label = senderName || botName || description || type;

    if (isOperatorChannel && channelType === 'BITRIX') {
      label = translate('AddChannelCard BITRIX operator label');
    }

    const showHelp = appConfig?.zenflow?.showHelp;

    const settingsButton = (
      <Button
        iconLeft='farCog'
        disabled={channelType === CHAT_WIDGET && status === 'IN_PROGRESS'}
        className={`${classes.itemAction}`}
        data-test-id={`Channels.ChannelItem.settingsButtons_${channelType}`}
        onClick={this.setChannelToEdit}
      />
    );
    return (
      <Card className={classNames(classes.card, `channel_${channel.id}`)} onClick={this.onClick}>
        {channelType === currentAvailableChannels.CHAT_WIDGET &&
        Boolean(widgetOptions) &&
        Boolean(widgetOptions.logo) ? (
          <img src={widgetOptions.logo} className={classes.widgetLogoIcon} />
        ) : (
          <ChannelIcon
            className={classes.widgetLogoIcon}
            type={getChannelIconType(channelType || type)}
            size='medium'
            isEuroInstance={isEuroInstance()}
          />
        )}
        {!isOperatorChannel && this.renderLink(channel, channelType, projectShortName, language)}
        <p className={classes.channelName}>{label}</p>
        {!isOperatorChannel && (
          <>
            <Badge
              text={translate(blocked ? 'ChannelItem status UNAVAILABLE' : `ChannelItem status ${status}`)}
              color={getStatusColor(blocked ? 'unavailable' : status)}
              data-test-id={`Channels.ChannelItem.settingsStatus_${status}`}
              pastel
              iconLeft={getStatusIcon(blocked ? 'unavailable' : status)}
            />
            {!blocked &&
              [YANDEX, I_DIGITAL, MARUSIA, AZURE].includes(channelType) &&
              (status === 'OK' || outdated) &&
              showHelp && (
                <div>
                  <a
                    className={classNames(classes.getScriptBtn, classes.whatNext)}
                    target='_blank'
                    tabIndex={1}
                    href={translate(`Whats next link ${channelType}`)}
                    rel='noreferrer'
                  >
                    {translate("What's next")}
                  </a>
                </div>
              )}
            {status === 'DEPLOY_FAILED' ||
              (status === 'TEST_FAILED' && (
                <a
                  className={classNames(classes.getScriptBtn, classes.viewLogBtn, classes.whatNext)}
                  onClick={this.showDeployLog}
                  tabIndex={1}
                >
                  {translate('View log')}
                </a>
              ))}
          </>
        )}
        {(!pseudoOperatorChannels.includes(channel.channelType) || !isOperatorChannel) && (
          <div className={classes.buttons}>
            {showPublishButton && (
              <ProgressButton
                className={classes.progress_button}
                callback={this.onPublish}
                variant='contained'
                color='primary'
                size='small'
                progressContent={translate('Publishing')}
              >
                {translate('Publish')}
              </ProgressButton>
            )}
            {channelType === CHAT_WIDGET && this.props.isInWizard ? (
              <>
                <span className={classes.disabledByWizardButton} id={`settings-btn-${id}-${channelType}`}>
                  {settingsButton}
                </span>
                <WizardTooltip textAlign='center' placement='top' target={`settings-btn-${id}-${channelType}`}>
                  {translate('ChannelsStep:ChatWidget:SettingsButton:Hint')}
                </WizardTooltip>
              </>
            ) : (
              settingsButton
            )}
            <Button
              iconLeft='farTrashAlt'
              className={classNames(classes.itemAction, 'margin-left-3x')}
              onClick={() => openRemoveModal(id)}
            />
          </div>
        )}
        {status === 'DEPLOY_FAILED' ||
          (status === 'TEST_FAILED' && (
            <ActionConfirmModal
              disableBackdropClick
              open={this.state.openDeployLogModal}
              onClose={this.hideDeployLog}
              title={translate('Deploy log')}
              onOkClick={this.hideDeployLog}
              okText={translate('Ok')}
              onCancelClick={this.copyLog}
              cancelText={translate('Copy log')}
            >
              <input type='text' ref={input => (this.logInput = input)} readOnly className={classes.hiddenInput} />
              <pre>{lastDeployResult.logOutput}</pre>
            </ActionConfirmModal>
          ))}
      </Card>
    );
  }
}
