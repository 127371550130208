const prefix = '@subscribtion/';

export const tariffsGroups = {
  chatbotTariffsUniqueNames: ['free', 'startup', 'growth', 'optimal', 'premium'],
  callsTariffsUniqueNames: ['free', 'calls_startup', 'calls_growth', 'calls_optimal', 'calls_premium'],
};

export const newTariffs = [...tariffsGroups.chatbotTariffsUniqueNames, ...tariffsGroups.callsTariffsUniqueNames];

export const FETCH_TARIFFS = prefix + 'FETCH_TARIFFS';
export const FETCH_TARIFFS_FULFILLED = prefix + 'FETCH_TARIFFS_FULFILLED';
export const FETCH_TARIFFS_PENDING = prefix + 'FETCH_TARIFFS_PENDING';
export const FETCH_TARIFFS_REJECTED = prefix + 'FETCH_TARIFFS_REJECTED';

export const DROP_ERRORS = prefix + 'DROP_ERRORS';
export const SET_CURRENT_TARIFFS = prefix + 'SET_CURRENT_TARIFFS';
