import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { t } from 'localization';

import { useAppSelector } from 'storeHooks';
import { RootState } from 'storeTypes';

import { ITemplateWizardEditField } from '../../types';
import { GoogleTablesBlock } from './blocs/GoogleTablesBlock';
import { EmailBlock } from './blocs/EmailBlock';
import { SmsBlock } from './blocs/SmsBlock';

import styles from './styles.module.scss';

export type ManagerNotifyResult = {
  phone: {
    enabled: boolean;
    number: string;
  };
  email: {
    enabled: boolean;
    address: string;
  };
  googleSheets: {
    enabled: boolean;
    integrationId: string;
    spreadsheetId: string;
    sheetName: string;
  };
};

export interface OnChangeType<ManagerNotifyField> {
  <PROP extends keyof ManagerNotifyField>(key: PROP, value: ManagerNotifyField[PROP]): void;
}

type ManagerNotifyProps = ITemplateWizardEditField<ManagerNotifyResult>;
const ManagerNotify: FC<ManagerNotifyProps> = ({ envName, onChange, value, onErrors, isShowErrors }) => {
  const { currentUser } = useAppSelector((store: RootState) => ({
    currentUser: store.CurrentUserReducer.currentUser,
  }));

  const [innerValue, setInnerValue] = useState<ManagerNotifyResult>(
    () =>
      value ?? {
        phone: {
          enabled: false,
          number: '',
        },
        email: {
          enabled: false,
          address: currentUser.email || '',
        },
        googleSheets: {
          enabled: false,
          integrationId: '',
          spreadsheetId: '',
          spreadsheetName: '',
          sheetName: '',
        },
      }
  );

  useEffect(() => {
    onChange(innerValue, envName, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envName, onChange]);

  useEffect(() => {
    onChange(innerValue, envName, false);
  }, [envName, innerValue, onChange]);

  const onChangeSmsBlock = useCallback<OnChangeType<ManagerNotifyResult['phone']>>((key, val) => {
    setInnerValue(prev => ({
      ...prev,
      phone: { ...prev.phone, [key]: val },
    }));
  }, []);
  const onChangeEmailBlock = useCallback<OnChangeType<ManagerNotifyResult['email']>>((key, val) => {
    setInnerValue(prev => ({
      ...prev,
      email: { ...prev.email, [key]: val },
    }));
  }, []);
  const onChangeGoogleTableBlock = useCallback((val: ManagerNotifyResult['googleSheets']) => {
    setInnerValue(prev => ({ ...prev, googleSheets: val }));
  }, []);

  const errors = useMemo(() => {
    const errors = {
      email: {
        address: '',
      },
      phone: {
        number: '',
      },
      googleSheets: {
        enabled: '',
      },
    };
    if (innerValue.email.enabled && innerValue.email.address === '') {
      errors.email.address = t('Wizard:ManagerNotify:Errors:Email:Empty');
    }
    if (innerValue.phone.enabled && innerValue.phone.number === '') {
      errors.phone.number = t('Wizard:ManagerNotify:Errors:Phone:Empty');
    }
    if (innerValue.googleSheets.enabled && innerValue.googleSheets.integrationId === '') {
      errors.googleSheets.enabled = t('Wizard:ManagerNotify:Errors:Google:EnabledButNotIntegrated');
    }
    return errors;
  }, [innerValue]);

  useEffect(() => {
    if (!errors.email.address && !errors.phone.number && !errors.googleSheets.enabled) {
      onErrors?.(envName, null);
      return;
    }
    onErrors?.(envName, errors);
  }, [envName, errors, onErrors]);

  return (
    <div className={styles.ManagerNotify}>
      <GoogleTablesBlock
        value={innerValue.googleSheets}
        onChange={onChangeGoogleTableBlock}
        isShowErrors={isShowErrors}
        errors={errors.googleSheets}
      />
      <SmsBlock
        value={innerValue.phone}
        onChange={onChangeSmsBlock}
        isShowErrors={isShowErrors}
        errors={errors.phone}
      />
      <EmailBlock
        value={innerValue.email}
        onChange={onChangeEmailBlock}
        isShowErrors={isShowErrors}
        errors={errors.email}
      />
    </div>
  );
};

export default ManagerNotify;
