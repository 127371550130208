import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import localize, { t } from '../../localization';
import { ErrorText, FormGroup, InputText, Label, Modal, Spinner, TextareaAutosize, useToggle } from '@just-ai/just-ui';
import { Subject } from 'rxjs';
import { axios } from 'pipes/functions';
import { LoadCallback } from '@just-ai/analytic-front';
import { bindActionCreators } from 'redux';
import { setCurrentUserOnVerification } from '../../actions/currentUser.actions';
import { useAppDispatch } from '../../storeHooks';
import { isEuroInstance } from '../../isAccessFunction';
import { verificationModalLocalization } from './localization/verificationModal.loc';

localize.addTranslations(verificationModalLocalization);

export const VerificationModalOpen$ = new Subject();

export const VerificationModal: FC<{ accountId: number; currentUser: { email: string } }> = ({
  accountId,
  currentUser,
}) => {
  const dispatch = useAppDispatch();
  const boundActionCreators = useMemo(() => bindActionCreators({ setCurrentUserOnVerification }, dispatch), [dispatch]);
  const [modalVerificationOpened, showModalVerification, closeModalVerification] = useToggle(false);
  const [success, setSuccess] = useState(false);
  const [company, setCompany] = useState('');
  const [callPurposes, setCallPurposes] = useState('');
  const companyRef = useRef<HTMLInputElement>(null);
  const callPurposesRef = useRef<HTMLTextAreaElement>(null);
  const [errors, setErrors] = useState({
    company: false,
    callPurposes: false,
  });
  const [isLoading, setLoading] = useState(false);

  const load: LoadCallback = useCallback(promise => {
    setLoading(true);
    return promise.finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const sub = VerificationModalOpen$.subscribe(value => {
      if (value) showModalVerification();
      if (!value) closeModalVerification();
    });
    return () => {
      sub.unsubscribe();
    };
  }, [closeModalVerification, showModalVerification]);

  const sendVerificationInfo = useCallback(() => {
    let errors = {
      company: false,
      callPurposes: false,
    };
    const companyValue = companyRef.current?.value.trim();
    const callPurposesValue = callPurposesRef.current?.value.trim();
    if (companyValue?.length === 0) errors.company = true;
    if (callPurposesValue?.length === 0) errors.callPurposes = true;
    if (Object.values(errors).filter(Boolean).length > 0) {
      setErrors(errors);
      return;
    }

    load(
      axios.post(`/api/accountsadmin/c/verification/accounts/${accountId}/verification-request`, {
        company: companyValue,
        callPurposes: callPurposesValue,
      })
    )
      .then(() => {
        setSuccess(true);
        boundActionCreators.setCurrentUserOnVerification();
      })
      .catch(console.error);
  }, [accountId, boundActionCreators, load]);

  useEffect(() => {
    setErrors(prevErrors => {
      let newErrors = { ...prevErrors };
      if (prevErrors.company && company.trim().length > 0) newErrors.company = false;
      if (prevErrors.callPurposes && callPurposes.trim().length > 0) newErrors.callPurposes = false;

      return newErrors;
    });
  }, [company, callPurposes, errors.company, errors.callPurposes]);

  return (
    <Modal
      zIndex={1400}
      isOpen={modalVerificationOpened}
      title={success ? t('VerificationModal:SuccessTitle') : t('VerificationModal:Title')}
      onCancelClick={success ? undefined : closeModalVerification}
      buttonCancelOutline
      buttonCancelColor='secondary'
      buttonCancelText={success ? '' : t('Cancel')}
      buttonSubmitColor='primary'
      buttonSubmitText={success ? t('VerificationModal:SuccessButtonText') : t('VerificationModal:SendButtonText')}
      onActionClick={success ? closeModalVerification : sendVerificationInfo}
      buttonSubmitDisabled={Object.values(errors).filter(Boolean).length > 0}
      disableActionButtonAutoFocus
    >
      {isLoading && <Spinner />}
      {success ? (
        <p>{t('VerificationModal:SuccessBody', currentUser.email)}</p>
      ) : (
        <>
          <p>{t('VerificationModal:BodyText', isEuroInstance() ? t('tovi') : t('aimy'))}</p>
          <FormGroup className='mb-4'>
            <Label for='organization'>{t('VerificationModal:OrganizationLabel')}</Label>
            <InputText
              innerRef={companyRef}
              onChange={setCompany}
              autoFocus
              id='organization'
              type='text'
              placeholder={t('VerificationModal:OrganizationPlaceholder')}
              invalid={errors.company}
              errorText={errors.company && t('VerificationModal:PurposeErrorText')}
            />
          </FormGroup>
          <FormGroup className='mb-0'>
            <Label for='purpose'>{t('VerificationModal:PurposeLabel')}</Label>
            <TextareaAutosize
              ref={callPurposesRef}
              rowsMin={4}
              rows={4}
              invalid={errors.callPurposes}
              id='purpose'
              placeholder={t('VerificationModal:PurposePlaceholder')}
              onChange={setCallPurposes}
            />
            {errors.callPurposes && <ErrorText text={t('VerificationModal:PurposeErrorText')} />}
          </FormGroup>
        </>
      )}
    </Modal>
  );
};
