// @ts-nocheck

import React, { createContext, useContext } from 'react';
import webstomp, { Client } from 'webstomp-client';
import SockJS from 'sockjs-client';

import { isDev } from 'pipes/pureFunctions';

const RECONNECT_TIMEOUT = 5000;
const MAX_ATTEMPTS = 3;
export type SubscriberCallback = (body: any, frame?: any) => void;

export type WSContextType = {
  connected: boolean;
  send: (path: string, message?: any) => void;
  subscribe: (path: string, callback: (value: any) => void) => string;
  unsubscribe: (path: string, id: string) => void;
  connect: () => void;
};

const WSContext = createContext({} as WSContextType);

class WSContextProvider extends React.Component {
  constructor(props: object) {
    super(props);
    this.state = {
      connected: false,
    };
  }
  stompClient = null as null | Client;
  listeners = [];
  subscribers = {};
  shouldReconnect = true;
  reconnectTimerId = undefined;
  queueSubscribers = [] as { callback: any; id: string | number; path: string }[];
  queueTimerId = undefined;
  connectedPathsId = {};
  queueMessages = [] as { path: string; message: string }[];
  isReconnecting = false;
  attempt = 0;

  isConnected = () => this.stompClient && this.stompClient.connected;

  getUrlForWS = () => {
    if (process.env.REACT_APP_ENABLE_WS_PROXY) {
      const url = isDev() ? process.env.REACT_APP_SIGNAL_PROXY : `${window.location.protocol}//${window.location.host}`;
      return `${url}/api/signal/websocket`;
    }
  };

  convertSubscribersToQueue = (subscribers: any) => {
    const queue = [] as { callback: any; id: string | number; path: string }[];
    Object.keys(subscribers).forEach(path => {
      Object.keys(subscribers[path]).forEach(id => {
        queue.push({ path, callback: subscribers[path][id], id });
      });
    });
    return queue;
  };

  initWebStomp = ws => {
    this.stompClient = webstomp.over(ws);
    if (this.stompClient) this.stompClient.debug = console.log;
    this.stompClient.connect(
      {},
      _frame => {
        this.setState({ connected: true });
        if (this.isReconnecting) {
          this.queueSubscribers = this.convertSubscribersToQueue(this.subscribers);
          this.subscribers = {};
          this.isReconnecting = false;
        }
        const queueLength = this.queueSubscribers.length;
        for (let i = 0; i < queueLength; i++) {
          const subscriber = this.queueSubscribers.pop();
          subscriber && this.subscribe(subscriber.path, subscriber.callback, subscriber.id);
        }
        const queueMesagesLength = this.queueMessages.length;
        for (let i = 0; i < queueMesagesLength; i++) {
          const msg = this.queueMessages.pop();
          msg && this.send(msg.path, msg.message);
        }
      },
      message => {
        this.setState({ connected: false });
        console.log('STOMP CONNECTION FAIL', message);
      }
    );

    ws.onclose = () => {
      this.setState({ connected: false });
      this.reconnect();
    };
  };

  reconnect = () => {
    this.isReconnecting = true;
    if (this.shouldReconnect) {
      this.reconnectTimerId = setTimeout(() => {
        this.attempt++;
        if (MAX_ATTEMPTS !== 0 && MAX_ATTEMPTS <= this.attempt) {
          this.shouldReconnect = false;
          return console.log(`RECONNECT LIMIT REACHED: ${MAX_ATTEMPTS}`);
        }
        this.setupWebsocket();
      }, RECONNECT_TIMEOUT);
    }
  };

  setupWebsocket = () => {
    this.attempt++;
    if (MAX_ATTEMPTS !== 0 && MAX_ATTEMPTS <= this.attempt) {
      return console.error(`RECONNECT LIMIT REACHED: ${MAX_ATTEMPTS}`);
    }
    if (process.env.REACT_APP_ENABLE_WS_PROXY) {
      try {
        this.disconnect();
        const websocket = new SockJS(this.getUrlForWS());
        this.initWebStomp(websocket);
      } catch (e) {
        console.log(e);
        this.reconnect();
      }
    }
  };

  disconnect = () => {
    try {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
    } catch (e) {
      console.error(e);
    }
  };

  send = (path, message) => {
    if (this.isConnected()) {
      return this.stompClient?.send(path, JSON.stringify(message));
    } else {
      return this.queueMessages.push({ path, message });
    }
  };

  subscribe = (path, callback, oldId) => {
    const id = oldId || String(performance.now());
    if (!this.isConnected()) {
      this.queueSubscribers.push({
        path,
        callback,
        id,
      });
      return id;
    }
    if (Object.keys(this.subscribers[path] || {}).length) {
      this.subscribers[path][id] = callback;
    }
    if (!Object.keys(this.subscribers[path] || {}).length && this.stompClient) {
      this.subscribers[path] = {};
      this.subscribers[path][id] = callback;
      this.connectedPathsId[path] = this.stompClient.subscribe(path, frame => {
        let body = frame && frame.body;
        try {
          body = JSON.parse(body);
        } catch (e) {
          console.error('PARSE BODY ERROR', e);
        }
        Object.keys(this.subscribers[path]).forEach(() => callback(body, frame));
      }).id;
    }
    return id;
  };

  unsubscribe = (path, id) => {
    if (!this.isConnected()) {
      const deletedIndex = this.queueSubscribers.findIndex(s => s.path === path && s.id === id);
      if (deletedIndex !== -1) {
        this.queueSubscribers.splice(deletedIndex, 1);
      }
    }
    if (this.isConnected() && this.subscribers[path] && this.subscribers[path][id]) {
      if (Object.keys(this.subscribers[path]).length === 1) {
        this.stompClient && this.stompClient.unsubscribe(this.connectedPathsId[path]);
      }
      delete this.subscribers[path][id];
    }
  };

  componentWillUnmount() {
    this.shouldReconnect = false;
    this.disconnect();
    if (this.reconnectTimerId) {
      clearTimeout(this.reconnectTimerId);
      this.reconnectTimerId = undefined;
    }
  }

  render() {
    return (
      <WSContext.Provider
        value={{
          connected: this.state.connected,
          send: this.send,
          subscribe: this.subscribe,
          unsubscribe: this.unsubscribe,
          connect: this.setupWebsocket,
        }}
      >
        {this.props.children}
      </WSContext.Provider>
    );
  }
}

const useWSContext = () => useContext(WSContext);

export { WSContext, WSContextProvider, useWSContext };
