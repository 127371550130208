import React from 'react';

import { ActionConfirmModal, AtreidesTypo, Message } from 'altreidsds';
import localize from 'localization';
import classes from './Channels.module.scss';

const { translate } = localize;

export const DeleteChannelModal = ({ open, toggleRemoveModal, okDisabled, removeChannel }) => {
  return (
    <ActionConfirmModal
      disableBackdropClick
      open={open}
      onClose={toggleRemoveModal}
      title={translate('Removing the channel')}
      onCancelClick={toggleRemoveModal}
      okText={translate('Confirm delete channel')}
      cancelText={translate('Cancel delete channel')}
      okDisabled={okDisabled}
      onOkClick={removeChannel}
      onCancelClickColor='dangerous'
      onOkClickColor='dangerous'
      key='settings_action_confirm_key'
    >
      <AtreidesTypo type='p'>
        {translate(
          'This will lead to the deletion of the channel and all the settings. The channel will marked as deleted in Dialogs and Statistics. Do you really want to proceed'
        )}
      </AtreidesTypo>
      <Message
        variant='warning'
        className={classes.deleteChannelWarning}
        message={translate('This action cannot be undone')}
      />
    </ActionConfirmModal>
  );
};
