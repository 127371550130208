import React, { FC, useEffect, useState } from 'react';
import { InputText as JustUiInputText } from '@just-ai/just-ui';

import { LocalizationField, ITemplateWizardEditField } from '../../types';
import styles from './styles.module.scss';

type InputTextProps = ITemplateWizardEditField<
  string,
  {
    title: LocalizationField;
    placeholder: LocalizationField;
    helperText?: LocalizationField;
  }
>;
const InputText: FC<InputTextProps> = ({ value, envName, onChange, info }) => {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    onChange(info.placeholder, envName, true);
  }, [envName, info.placeholder, onChange]);

  useEffect(() => {
    onChange(innerValue, envName, false);
  }, [envName, onChange, innerValue]);

  return (
    <div className={styles.InputText}>
      <div className={styles.InputText__title}>{info.title}</div>
      <div className={styles.InputText__inputWrapper}>
        <div className={styles.InputText__inputContainer}>
          <JustUiInputText
            value={innerValue}
            onChange={setInnerValue}
            placeholder={info.placeholder}
            clearable={!!innerValue}
            maxLength={500}
          />
        </div>
        <div className={styles.InputText__inputWrapper}>
          {info.helperText && <div className={styles.InputText__hint}>{info.helperText}</div>}
        </div>
      </div>
    </div>
  );
};

export default InputText;
