import { isMobile } from 'altreidsds';
import { FORCE_DESKTOP_VIEW, UNDO_FORCE_DESKTOP_VIEW } from '../../constants/UI.actions';

const InitialState = {
  isMobile: isMobile(),
  forceDesktopView: false,
};

export default function UIReducer(state = InitialState, action) {
  switch (action.type) {
    case FORCE_DESKTOP_VIEW: {
      return {
        ...state,
        forceDesktopView: true,
      };
    }

    case UNDO_FORCE_DESKTOP_VIEW: {
      return {
        ...state,
        forceDesktopView: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
