import React, { CSSProperties } from 'react';
import cn from 'classnames';

import { useAppSelector } from 'storeHooks';

import styles from './WizardCard.module.scss';

interface ChatGPTMarkerProps {
  size?: CSSProperties['height'];
}
const ChatGPTCircle = ({ size = 72 }: ChatGPTMarkerProps) => {
  const { isChatGPTLimitExhausted } = useAppSelector(store => ({
    isChatGPTLimitExhausted: store.CurrentUserReducer.isChatGPTLimitExhausted,
  }));
  return (
    <div style={{ '--size': size } as CSSProperties} className={styles.chatGPTMarkerWrapper}>
      <div className={cn(styles.chatGPTMarker, { [styles.isChatGPTLimitExhausted]: isChatGPTLimitExhausted })} />
    </div>
  );
};

export default React.memo(ChatGPTCircle);
