import React, { FC, useCallback } from 'react';
import { InputText } from '@just-ai/just-ui';
import cn from 'classnames';
import { clamp } from 'lodash';
import { t } from '../../../../../localization';
import classes from './AutomessageSequence.module.scss';
import { MessageType, useAutomessageSequenceContext } from './types';

enum TimeSelectorEnum {
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  second = 'second',
}

const TimeSelector: FC<{
  type: TimeSelectorEnum;
  min: number;
  max: number;
  value: number;
  setValue: (value: number) => unknown;
  placeholder?: string;
  valueExceeded?: (onWho: TimeSelectorEnum) => unknown;
  disabled?: boolean;
}> = ({ type, max, min, value, setValue, placeholder = '', disabled }) => {
  const onChange = useCallback(
    (value: string) => {
      const valueToState = clamp(parseInt(value), min, max);
      setValue(valueToState);
    },
    [max, min, setValue]
  );

  return (
    <div className={classes.MessageContainer_radio_timePicker_InputContainer}>
      <InputText
        type='number'
        placeholder={placeholder}
        onChange={onChange}
        value={String(value)}
        disabled={disabled}
      />
      <span className={cn('text-muted', classes.MessageContainer_radio_timePicker_timeValue)}>
        {t(`AutomessageSequence:Radio:SelectTime:OneLetter:${type}`)}
      </span>
    </div>
  );
};

export const TimePicker: FC<{ time: MessageType['time']; messageIndex: number; disabled: boolean }> = ({
  messageIndex,
  time,
  disabled,
}) => {
  const { onChange } = useAutomessageSequenceContext();
  const setValue = useCallback(
    (period: keyof typeof time, value: number) => {
      const newTime = { ...time };
      newTime[period] = value || 0;
      onChange(messageIndex, { time: newTime });
    },
    [messageIndex, onChange, time]
  );

  return (
    <div className={classes.MessageContainer_radio_timePicker}>
      <TimeSelector
        value={time.day}
        setValue={value => setValue('day', value)}
        max={30}
        min={0}
        type={TimeSelectorEnum.day}
        placeholder='0'
        disabled={disabled}
      />
      <TimeSelector
        value={time.hour}
        setValue={value => setValue('hour', value)}
        max={23}
        min={0}
        type={TimeSelectorEnum.hour}
        placeholder='00'
        disabled={disabled}
      />
      <TimeSelector
        value={time.minute}
        setValue={value => setValue('minute', value)}
        max={59}
        min={0}
        type={TimeSelectorEnum.minute}
        placeholder='00'
        disabled={disabled}
      />
      <TimeSelector
        value={time.second}
        setValue={value => setValue('second', value)}
        max={59}
        min={0}
        type={TimeSelectorEnum.second}
        placeholder='00'
        disabled={disabled}
      />
    </div>
  );
};
