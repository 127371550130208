import { get } from 'lodash';

export default function getByPath<RETURN extends any>(obj: object | any[], path: string): RETURN | undefined {
  if (path.startsWith('.')) path = path.substring(1);
  const mapPattern = '[*]';
  const partEdge = path.indexOf(mapPattern);
  if (partEdge === -1) return get(obj, path);
  const [firstPath, otherPaths] = [path.substring(0, partEdge), path.substring(partEdge + mapPattern.length)];
  const result = firstPath ? get(obj, firstPath) : obj;
  if (!result || !Array.isArray(result)) return undefined;
  return result.map((el: any) => getByPath(el, otherPaths)) as RETURN;
}
