const prefix = '@clients/';

export const FILTER_LISTS = prefix + 'FILTER_LISTS';
export const FILTER_LISTS_PENDING = prefix + 'FILTER_LISTS_PENDING';
export const FILTER_LISTS_REJECTED = prefix + 'FILTER_LISTS_REJECTED';
export const FILTER_LISTS_FULFILLED = prefix + 'FILTER_LISTS_FULFILLED';

export const FILTER_CLIENTS = prefix + 'FILTER_CLIENTS';
export const FILTER_CLIENTS_PENDING = prefix + 'FILTER_CLIENTS_PENDING';
export const FILTER_CLIENTS_REJECTED = prefix + 'FILTER_CLIENTS_REJECTED';
export const FILTER_CLIENTS_FULFILLED = prefix + 'FILTER_CLIENTS_FULFILLED';

export const UPLOAD = prefix + 'UPLOAD';
export const UPLOAD_PENDING = prefix + 'UPLOAD_PENDING';
export const UPLOAD_REJECTED = prefix + 'UPLOAD_REJECTED';
export const UPLOAD_FULFILLED = prefix + 'UPLOAD_FULFILLED';

export const FETCH_CLIENTS = prefix + 'FETCH_CLIENTS';
export const FETCH_CLIENTS_PENDING = prefix + 'FETCH_CLIENTS_PENDING';
export const FETCH_CLIENTS_REJECTED = prefix + 'FETCH_CLIENTS_REJECTED';
export const FETCH_CLIENTS_FULFILLED = prefix + 'FETCH_CLIENTS_FULFILLED';

export const SEARCH_CLIENTS = prefix + 'SEARCH_CLIENTS';
export const DROP_SEARCH_RESULTS = prefix + 'DROP_SEARCH_RESULTS';
export const SEARCH_MESSENGER_CLIENTS = prefix + 'SEARCH_MESSENGER_CLIENTS';
export const DROP_MESSENGER_CLIENTS_SEARCH_RESULTS = prefix + 'DROP_MESSENGER_CLIENTS_SEARCH_RESULTS';
