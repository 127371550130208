import { OrderTypes } from './BotsControls';
import { BotType } from '../dashboardTypes';

export function sortByOrder(botList: BotType[], order: OrderTypes) {
  botList.sort((prevBot, nextBot) => {
    return prevBot.name.toLowerCase().localeCompare(nextBot.name.toLowerCase());
  });

  switch (order) {
    case OrderTypes.alphabet_desc:
      botList.sort((prevBot, nextBot) => {
        return prevBot.name.toLowerCase().localeCompare(nextBot.name.toLowerCase());
      });
      break;
    case OrderTypes.old_desc:
      botList.sort((prevBot, nextBot) => {
        if (nextBot.id > prevBot.id) {
          return -1;
        }
        return 1;
      });
      break;
    case OrderTypes.new_desc:
      botList.sort((prevBot, nextBot) => {
        if (prevBot.id > nextBot.id) {
          return -1;
        }
        return 1;
      });
      break;
    default:
      botList.sort((prevBot, nextBot) => {
        const prevDate = new Date(prevBot.lastModificationData) || 0;
        const nextDate = new Date(nextBot.lastModificationData) || 0;
        if (prevDate > nextDate) {
          return -1;
        }
        return 1;
      });
      break;
  }
  return botList;
}
