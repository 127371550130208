import * as socketActions from './socketActions';

import SockJS from 'sockjs-client';
import webstomp from 'webstomp-client';

const RECONNECT_TIMEOUT = 5000;
const onOpen = (webstomp, ws, store) => evt => {
  const stompClient = webstomp.over(ws);
  const destinationGit = '/ws/content/modified';
  const destinationDeploy = '/ws/content/deployed';

  console.log('WS is onOpen');
  stompClient.connect(
    {},
    function (frame) {
      console.log('Connected: ' + frame);
    },
    function (message) {
      // check message for disconnect
      console.log('Closed stomp');
      setTimeout(store.dispatch({ type: 'SOCKETS_CONNECT' }), RECONNECT_TIMEOUT);
    }
  );

  stompClient.subscribe(destinationGit, function (message) {
    let decodeMessage = null;

    try {
      decodeMessage = JSON.parse(message.body);
      const project = decodeMessage.project;
      if (project.shortName === localStorage['CURRENT_PROJECT_SHORT_NAME']) {
        console.log(decodeMessage);
      }
    } catch (e) {
      console.log(e, message);
    }
  });

  stompClient.subscribe(destinationDeploy, function (message) {
    let decodeMessage = null;
    try {
      decodeMessage = JSON.parse(message.body);
      const result = decodeMessage.result;
      const project = decodeMessage.project;
      if (project.shortName === localStorage['CURRENT_PROJECT_SHORT_NAME']) {
        console.log(decodeMessage);
      }
    } catch (e) {
      console.log(e, message);
    }
  });
};

const onClose = store => evt => {
  console.log('WS is onClose');
  console.log('evt ' + evt.data);
  store.dispatch(socketActions.socketsDisconnecting());
};

const onMessage = (ws, store) => evt => {
  // Parse the JSON message received on the websocket
  console.log('SOCKETS_MESSAGE_RECEIVING onMessage');
};

export default function createSocketMiddleware() {
  let ws = null;
  const target = '/restapi/websocket';

  return store => next => action => {
    switch (action.type) {
      case 'SOCKET_CONNECT':
        if (ws !== null) {
          console.log('SOCKETS_DISCONNECTING');
          store.dispatch(socketActions.socketsDisconnecting());
          ws.close();
        }
        ws = new SockJS(target);
        store.dispatch(socketActions.socketsConnecting());

        ws.onmessage = onMessage(ws, store);
        ws.onclose = onClose(store);
        ws.onopen = onOpen(webstomp, ws, store);
        break;

      case 'SOCKET_DISCONNECT':
        if (ws !== null) {
          console.log('SOCKETS_DISCONNECTING');
          store.dispatch(socketActions.socketsDisconnecting());
          ws.close();
        }
        ws = null;
        break;

      default:
        return next(action);
    }
  };
}
