import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export function useBehaviorSubject<DATA>(behaviorSubject: BehaviorSubject<DATA>) {
  const [subjectValue, setSubjectValue] = useState<DATA>(behaviorSubject.getValue());
  useEffect(() => {
    const unsub = behaviorSubject.subscribe(data => {
      setSubjectValue(data);
    });
    return () => unsub.unsubscribe();
  }, [behaviorSubject]);
  return subjectValue;
}
