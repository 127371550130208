import * as currentProjectConsts from 'constants/projects.actions';
import { axios } from 'pipes/functions';
import history from 'appHistory';

/**
 * @param projectShortName
 * @param {any|null} currentBot
 * @returns {{projectShortName, currentBot: null, type: string}}
 */
export const setCurrentProject = (projectShortName, currentBot = null) => {
  localStorage.setItem('CURRENT_PROJECT_SHORT_NAME', projectShortName);
  return {
    type: currentProjectConsts.SET_CURRENT_PROJECT,
    projectShortName,
    currentBot,
  };
};

export const clearCurrentProject = () => {
  history.push('/');

  localStorage.removeItem('CURRENT_PROJECT_SHORT_NAME');
  return {
    type: currentProjectConsts.CLEAR_CURRENT_PROJECT,
  };
};

export const dropCurrentProject = () => {
  localStorage.removeItem('CURRENT_PROJECT_SHORT_NAME');
  return {
    type: currentProjectConsts.CLEAR_CURRENT_PROJECT,
  };
};

export const getDeployStatus = id => {
  return {
    type: currentProjectConsts.GET_DEPLOY_STATUS,
    payload: axios.get(`/restapi/deployments/project/${id}`),
  };
};

export const setDeployStatus = deploy => {
  return {
    type: currentProjectConsts.SET_DEPLOY_STATUS,
    deploy,
  };
};

export const setDeployments = (projectShortName, deployments) => {
  return {
    type: currentProjectConsts.SET_DEPLOYMENTS,
    projectShortName,
    deployments,
  };
};
