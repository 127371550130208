import React from 'react';
//@ts-ignore
import { AtreidesIcon } from 'altreidsds';
import classes from './NewHeader.module.scss';

function Avatar({ avatar }: { avatar?: string }) {
  return (
    <div className={classes.avatarContainer}>
      {avatar ? (
        <img className={classes.avatar} src={avatar} />
      ) : (
        <AtreidesIcon className={classes.avatar} name='register' />
      )}
    </div>
  );
}

export default React.memo(Avatar);
