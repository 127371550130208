import React, { FC } from 'react';

import { LocalizationField, ITemplateWizardField } from '../../types';
import styles from './styles.module.scss';

type TextMessageProps = ITemplateWizardField<{
  title: LocalizationField;
  description: LocalizationField;
}>;
const TextMessage: FC<TextMessageProps> = ({ info }) => {
  return (
    <div className={styles.TextMessage}>
      {info.title && <div className={styles.TextMessage__title} dangerouslySetInnerHTML={{ __html: info.title }} />}
      {info.description && (
        <div className={styles.TextMessage__description} dangerouslySetInnerHTML={{ __html: info.description }} />
      )}
    </div>
  );
};

export default TextMessage;
