import {
  CLEAN_REGISTER_ERRORS,
  GET_PASSWORD_LENGTH_FULFILLED,
  GET_PASSWORD_LENGTH_PENDING,
  GET_PASSWORD_LENGTH_REJECTED,
  REGISTER_FULFILLED,
  REGISTER_PENDING,
  REGISTER_REJECTED,
  REMOVE_REGISTER_ALERT,
} from '../../constants/register.actions';
import localize from '../../localization';

import { parseErrors } from '../../pipes/pureFunctions';
import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

const InitialState = {
  errors: [],
  fetching: false,
  success: false,
  passwordLength: null,
};

export default function RegisterReducer(state = InitialState, action) {
  switch (action.type) {
    case REGISTER_FULFILLED: {
      return {
        ...state,
        fetching: false,
        errors: InitialState.errors,
        success: localize.translate(
          'We sent you a confirmation email. Please check your mailbox to complete an account creation'
        ),
      };
    }
    case REGISTER_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case REGISTER_REJECTED: {
      let errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }
    case CLEAN_REGISTER_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }
    case REMOVE_REGISTER_ALERT: {
      let newRegisterAlert = [...state.errors];
      newRegisterAlert.splice(action.key, 1);
      return {
        ...state,
        errors: newRegisterAlert,
      };
    }
    case LOGOUT_FULFILLED: {
      return {
        ...state,
        success: false,
      };
    }

    case GET_PASSWORD_LENGTH_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_PASSWORD_LENGTH_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_PASSWORD_LENGTH_FULFILLED: {
      return {
        ...state,
        fetching: false,
        passwordLength: action.payload.data.passwordLength,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
