class ChatSupportController {
  initTimer = null;
  timer = null;

  init = (onMessage, email) => {
    clearTimeout(this.initTimer);
    if (Boolean(window.$crisp)) {
      window.$crisp.push([
        'on',
        'message:received',
        messages => {
          let crisp = document.getElementsByClassName('crisp-client');
          if (Boolean(crisp[0]) && !crisp[0].classList.contains('visible')) {
            onMessage && onMessage(messages);
          }
        },
      ]);
      email && window.$crisp.push(['set', 'user:email', email]);
    } else {
      this.initTimer = setTimeout(() => {
        this.init(onMessage, email);
      }, 700);
      console.error('no crisp');
    }
  };

  close = () => {
    clearTimeout(this.timer);
    let crisp = document.getElementsByClassName('crisp-client');
    if (Boolean(crisp[0])) {
      crisp[0].classList.remove('visible');
    }
  };

  show = () => {
    clearTimeout(this.timer);
    let crisp = document.getElementsByClassName('crisp-client');
    if (Boolean(crisp[0]) && window.$crisp) {
      window.$crisp.push(['do', 'chat:show']);
      crisp[0].classList.add('visible');
      window.$crisp.push(['on', 'chat:closed', () => {}]);
    } else {
      console.error('no crisp');
      this.timer = setTimeout(() => {
        this.show();
      }, 700);
    }
  };

  toggle = onClose => {
    let crisp = document.getElementsByClassName('crisp-client');
    if (Boolean(crisp[0]) && window.$crisp) {
      crisp[0].classList.add('visible');
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          this.close();
          onClose && onClose();
        },
      ]);
    }
  };
}

export default new ChatSupportController();
