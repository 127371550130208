import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CountryList from './CountryList';

import classes from './PhoneNumberInput.module.scss';

PhoneNumberInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  ref: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  phoneNumber: PropTypes.object,
  selectFixedPosition: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
};

PhoneNumberInput.defaultProps = {
  phoneNumber: { code: '', number: '' },
};

export function PhoneNumberInput({
  placeholder,
  className,
  name,
  ref,
  required,
  onChange,
  style,
  phoneNumber,
  selectFixedPosition,
  error,
  autoFocus = true,
}) {
  const [showChooseCountry, setShowChooseCountry] = useState(false);
  const [code, setCode] = useState(phoneNumber.code);
  const [country, setCountry] = useState('');
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [number, setNumber] = useState(phoneNumber.number);
  const countryListRef = React.useRef();
  const phoneNumberRef = React.useRef();

  useEffect(() => {
    if (selectFixedPosition) {
      const setPositionTimeout = setTimeout(() => {
        const phoneNumberContainerCoords = phoneNumberRef.current.getBoundingClientRect();
        countryListRef.current.style.position = 'fixed';
        countryListRef.current.style.top = phoneNumberContainerCoords.y + phoneNumberContainerCoords.height + 'px';
        countryListRef.current.style.left = phoneNumberContainerCoords.x + 'px';
        countryListRef.current.style.width = phoneNumberContainerCoords.width + 'px';
      }, 1000);
      return () => clearTimeout(setPositionTimeout);
    }
  }, [selectFixedPosition]);

  useEffect(() => {
    console.log(61, code);
    onChange && onChange({ code, number, country });
    return;
  }, [code, number, country, onChange]);

  const fullPhoneNumber = code.replace(/\D/g, '') + number.replace(/\D/g, '');

  const onToggleShowChooseCountry = useCallback(() => setShowChooseCountry(!showChooseCountry), [showChooseCountry]);

  const onChangeCode = useCallback(event => {
    const code = event.target.value;

    if (code === '') {
      setIsInvalidCode(true);
    } else {
      setIsInvalidCode(false);
    }
    if (code.length <= 4) setCode(code);
  }, []);

  const onChangeNumber = useCallback(event => setNumber(event.target.value), []);

  const formattedNumberValue = formatNumberValue(code, number);

  const setCodeAndCountry = useCallback((code, country) => {
    setCode(code);
    setCountry(country);
  }, []);

  return (
    <div
      className={classNames(
        classes.container,
        className,
        { [classes.error]: error },
        { [classes.error]: isInvalidCode }
      )}
      style={style}
      ref={phoneNumberRef}
    >
      <input
        name={name || 'phoneNumber'}
        id={name || 'phoneNumber'}
        ref={r => (ref = r)}
        style={{ display: 'none' }}
        required={required}
        onChange={onChange}
        value={fullPhoneNumber}
      />
      <div className={classes.setCodeContainer}>
        <span>+ </span>
        <input
          className={classes.setCode}
          type='number'
          onChange={onChangeCode}
          required={required}
          placeholder='XXX'
          value={code}
        />
        <button
          className={classNames(classes.toggleButton, showChooseCountry && classes.on)}
          onClick={onToggleShowChooseCountry}
          type='button'
          tabIndex={-1}
        >
          <ToggleIcon />
        </button>
        <CountryList
          setCode={setCodeAndCountry}
          showChooseCountry={showChooseCountry}
          setShowChooseCountry={setShowChooseCountry}
          containerRef={countryListRef}
          preventSetDefault={Boolean(code)}
        />
      </div>
      <input
        className={classes.setNumber}
        type='tel'
        defaultValue={number}
        placeholder={placeholder}
        value={formattedNumberValue}
        autoFocus={autoFocus}
        onChange={onChangeNumber}
      />
    </div>
  );
}

export function formatNumberValue(code, number) {
  const cleanedNumber = number.replace(/\D/g, '');
  if (code !== '7' || code !== '1' || cleanedNumber.length === 0) return cleanedNumber;
  return (
    '(' +
    cleanedNumber.slice(0, 3) +
    (cleanedNumber.length > 3 ? ') ' : '') +
    (cleanedNumber.length > 3 ? cleanedNumber.slice(3, 6) : '') +
    (cleanedNumber.length > 6 ? '-' : '') +
    (cleanedNumber.length > 6 ? cleanedNumber.slice(6, 8) : '') +
    (cleanedNumber.length > 8 ? '-' : '') +
    (cleanedNumber.length > 8 ? cleanedNumber.slice(8, 10) : '')
  );
}

function ToggleIcon() {
  return (
    <svg width='12' height='7' viewBox='0 0 12 7' fill='none'>
      <path
        d='M6.0003 5.81741e-05C6.13191 -0.000702381 6.26237 0.0245233 6.38421 0.0742879C6.50605 0.124052 6.61686 0.197377 6.7103 0.290059L10.7103 4.29006C10.8986 4.47836 11.0044 4.73376 11.0044 5.00006C11.0044 5.26636 10.8986 5.52176 10.7103 5.71006C10.522 5.89836 10.2666 6.00415 10.0003 6.00415C9.734 6.00415 9.47861 5.89836 9.2903 5.71006L6.0003 2.41006L2.7103 5.71006C2.522 5.89836 2.26661 6.00415 2.0003 6.00415C1.734 6.00415 1.47861 5.89836 1.2903 5.71006C1.102 5.52176 0.996212 5.26636 0.996212 5.00006C0.996212 4.73376 1.102 4.47836 1.2903 4.29006L5.2903 0.290059C5.38374 0.197377 5.49456 0.124052 5.6164 0.0742879C5.73823 0.0245233 5.8687 -0.000702381 6.0003 5.81741e-05Z'
        fill='#EAE9E9'
      />
    </svg>
  );
}
