import {
  REGISTER,
  RESEND_EMAIL,
  REMOVE_REGISTER_ALERT,
  CLEAN_REGISTER_ERRORS,
  GET_PASSWORD_LENGTH,
} from '../../constants/register.actions';
import { axios } from '../../pipes/functions';

export const register = (data, useGCaptcha = false, gRecaptchaResponse) => {
  const options = {};

  const url = `/restapi/public/user/register`;

  if (useGCaptcha) {
    options.headers = { 'g-recaptcha-response': gRecaptchaResponse };
  }
  return {
    type: REGISTER,
    payload: axios.post(url, data, options),
  };
};

export const cleanRegistorErrors = () => {
  return {
    type: CLEAN_REGISTER_ERRORS,
  };
};

export const resendEmail = userId => {
  return {
    type: RESEND_EMAIL,
    payload: axios.post(`/restapi/users/mail/verification`),
  };
};

export const removeRegisterAlert = key => {
  return {
    type: REMOVE_REGISTER_ALERT,
    key,
  };
};

export const getPasswordMinLength = () => {
  return {
    type: GET_PASSWORD_LENGTH,
    payload: axios.get('/restapi/public/registerPasswordLength'),
  };
};
