import { SendSmsCodeDto, SmsCodeEndpointApi, VerifySmsCodeDto } from 'api/client/accountadmin';
import { axios } from 'pipes/functions';

export default class PhoneVerificationService {
  static readonly BASE_PATH = '';
  private PhoneVerificationApi: SmsCodeEndpointApi;

  constructor() {
    this.PhoneVerificationApi = new SmsCodeEndpointApi({}, PhoneVerificationService.BASE_PATH, axios);
  }

  sendSmsCodeForPhoneVerification = async (sendSmsCodeDto: SendSmsCodeDto, gRecaptchaResponse: any) => {
    return this.PhoneVerificationApi.sendSmsCodeForPhoneVerification(sendSmsCodeDto, {
      headers: { 'g-recaptcha-response': gRecaptchaResponse },
    });
  };

  verifySmsCode = async (verifySmsCodeDto: VerifySmsCodeDto) => {
    return this.PhoneVerificationApi.verifySmsCode(verifySmsCodeDto);
  };
}
