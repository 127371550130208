import React, { ReactComponentElement } from 'react';
import classNames from 'classnames';
import classes from './NewHeader.module.scss';
import { NavLink } from 'react-router-dom';
import localize from '../../../localization/index';

const { translate: t } = localize;

export interface LeftListItemInterface {
  link: string;
  label: string;
  beforeIcon?: ReactComponentElement<any>;
  afterIcon?: ReactComponentElement<any>;
  onClick?: () => void;
  pathname: string;
  type?: string;
  'data-test-id'?: string;
}

function LeftListItem({
  link,
  label,
  beforeIcon,
  afterIcon,
  onClick,
  pathname,
  type,
  'data-test-id': dataTestId,
}: LeftListItemInterface) {
  const props = {
    className: classNames(classes.leftListItem, link === pathname && classes.active),
    onClick: onClick,
    'data-test-id': dataTestId,
    children: (
      <>
        {beforeIcon && beforeIcon}
        {t(label)}
        {afterIcon && afterIcon}
      </>
    ),
  };

  if (type === 'external') return <a {...props} target='_blank' rel='noreferrer noopener' href={link} />;

  return <NavLink {...props} to={link} />;
}

export default React.memo(LeftListItem);
