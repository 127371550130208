export enum RealSkill {
  ORDER = 'ORDER',
  FAQ = 'FAQ',
  FAQ_WITH_CHAT_GPT = 'FAQ_WITH_CHAT_GPT',
  COLLECTING_CONTACTS = 'COLLECTING_CONTACTS',
  SERVICE_EVALUATION = 'SERVICE_EVALUATION',
  AUTOMESSAGE = 'AUTOMESSAGE',
}

export enum SkillType {
  AUTOMESSAGE = 'AUTOMESSAGE',
  SCENARIO = 'SCENARIO',
  MENU = 'MENU',
  FAQ = 'FAQ',
  FAQ_WITH_CHAT_GPT = 'FAQ_WITH_CHAT_GPT',
  OPERATOR = 'OPERATOR',
}

export interface BaseSkillConfig {
  type: SkillType;
  skillName: string;
  title: string;
  denySkills?: RealSkill[] | '*';
  description: string;
  shortDescription?: string;
  skillVersion: number;
  withChatGPT?: boolean;
  orderWeight: number;
  language: 'ru' | 'en' | string;
  startState: string;
  menuType?: 'question' | 'transition';
  fixedInMenu?: boolean;
  addToMenuOnce?: boolean;
  questionPath?: string | string[];
  isSpecial?: boolean;
  withBaseInfo?: boolean;
  withOperator?: boolean;
  withMenu?: boolean;
}

export interface ScenarioSkillConfig extends BaseSkillConfig {
  type: SkillType.SCENARIO;
  fields: any[];
}
export interface MenuSkillConfig extends BaseSkillConfig {
  type: SkillType.MENU;
  skillName: string;
}
export interface FAQSkillConfig extends BaseSkillConfig {
  type: SkillType.FAQ;
}
export interface FAQWithChatGPTSkillConfig extends BaseSkillConfig {
  type: SkillType.FAQ_WITH_CHAT_GPT;
}
export interface OperatorSkillConfig extends BaseSkillConfig {
  type: SkillType.OPERATOR;
}
export interface AutomessageSkillConfig extends BaseSkillConfig {
  type: SkillType.AUTOMESSAGE;
}

export type SkillConfig =
  | ScenarioSkillConfig
  | MenuSkillConfig
  | FAQSkillConfig
  | FAQWithChatGPTSkillConfig
  | OperatorSkillConfig
  | AutomessageSkillConfig;

export type WizardState = { values: Record<string, any>; default: Record<string, any> };
