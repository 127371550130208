import { t } from 'localization';

import { TemplateWizardField } from '../FieldBuilder/fields';

import { RealSkill, ScenarioSkillConfig, SkillType } from '../types';

export const collectingContactsConfig: () => ScenarioSkillConfig = () => ({
  type: SkillType.SCENARIO,
  skillName: RealSkill.COLLECTING_CONTACTS,
  title: t('CollectingContactsSkill:Title'),
  description: '',
  shortDescription: t('CollectingContactsSkill:ShortDescription'),
  withBaseInfo: true,
  withOperator: true,
  withMenu: true,
  orderWeight: 20,
  skillVersion: 1,
  language: '',
  startState: '',
  menuType: 'transition',
  fixedInMenu: true,
  fields: [
    {
      type: 'textMessage',
      title: t('CollectingContactsSkill:Fields:StartDialogue'),
    },
    {
      type: 'TextArea',
      envName: 'startText',
      title: t('CollectingContactsSkill:Fields:StartOrderText:Title'),
      placeholder: t('CollectingContactsSkill:Fields:StartOrderText:Placeholder'),
      helperText: t('CollectingContactsSkill:Fields:StartOrderText:HelperText'),
      value: '',
    },
    {
      type: 'textMessage',
      title: t('CollectingContactsSkill:Fields:AgreementReaction'),
    },
    {
      type: 'TextArea',
      envName: 'contactsRequest',
      title: t('CollectingContactsSkill:Fields:ContactsRequest:Title'),
      placeholder: t('CollectingContactsSkill:Fields:ContactsRequest:Placeholder'),
      value: '',
    },
    {
      type: 'DataCollectionTable',
      envName: 'collectedInfo',
      title: '',
      allowNewRows: true,
      preventDeleteLast: true,
      preventDeleteLastHint: t('SkillsWizard:PreventDelete:Hit'),
      columns: [
        {
          envName: 'name',
          maxLength: 20,
          header: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Header'),
          type: 'select',
          selectOptions: [
            t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Email'),
            t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Name'),
          ],
          selectMultiple: false,
          eachRowSelectUniqValue: true,
          arbitrarySelectOptions: true,
          placeholder: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Placeholder'),
        },
        {
          envName: 'value',
          maxLength: 500,
          header: t('CollectingContactsSkill:Fields:OrderInfo:Columns:PhraseOfBot:Header'),
          type: 'text',
          selectOptions: null,
          placeholder: [
            {
              condition: {
                name: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Email'),
              },
              value: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Email:Placeholder'),
            },
            {
              condition: {
                name: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Name'),
              },
              value: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Name:Placeholder'),
            },
            {
              condition: {},
              value: t('CollectingContactsSkill:Fields:OrderInfo:Columns:PhraseOfBot:Header'),
            },
          ],
        },
      ],
      addRowText: t('CollectingContactsSkill:Fields:OrderInfo:AddRowText'),
      value: [
        {
          name: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Email'),
          value: '',
        },
        {
          name: t('CollectingContactsSkill:Fields:OrderInfo:Columns:WhatShouldKnow:Name'),
          value: '',
        },
      ],
    },
    {
      type: 'TextArea',
      envName: 'verifyQuestionsText',
      title: t('CollectingContactsSkill:Fields:VerifyQuestionsText:Title'),
      placeholder: t('CollectingContactsSkill:Fields:VerifyQuestionsText:Placeholder'),
      helperText: t('CollectingContactsSkill:Fields:VerifyQuestionsText:HelperText'),
      value: '',
    },
    {
      type: 'TextArea',
      envName: 'submitText',
      title: t('CollectingContactsSkill:Fields:SubmitOrderText:Title'),
      placeholder: t('CollectingContactsSkill:Fields:SubmitOrderText:Placeholder'),
      helperText: t('CollectingContactsSkill:Fields:SubmitOrderText:HelperText'),
      value: '',
    },
    {
      type: 'textMessage',
      title: t('CollectingContactsSkill:Fields:RejectBotText'),
    },
    {
      type: 'TextArea',
      envName: 'rejectText',
      title: t('CollectingContactsSkill:Fields:RejectOrderText:Title'),
      placeholder: t('CollectingContactsSkill:Fields:RejectOrderText:Placeholder'),
      value: '',
    },
    {
      type: 'textMessage',
      title: t('CollectingContactsSkill:Fields:SendManagerInfo:Title'),
      description: t('CollectingContactsSkill:Fields:SendManagerInfo:Placeholder'),
    },
    {
      type: TemplateWizardField.MANAGER_NOTIFY,
      envName: 'sendPlaces',
    },
  ],
});
