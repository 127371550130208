import React, { useCallback, useRef, useEffect } from 'react';
import { debounce, mergeWith, isEmpty, cloneDeep } from 'lodash';
import { useForceUpdate } from '@just-ai/just-ui';
import { FAQApi, IntentsApi, FaqQuestionData } from '@just-ai/api/dist/generated/Caila';

import { t } from 'localization';

import diffInArrays, { buildEqualFn } from 'helpers/array/diffInArrays';
import { FAQSkillConfig } from 'modules/TemplatesWizard/types';
import SkillCollapseContainer from 'modules/TemplatesWizard/primitives/SkillCollapseContainer';
import { combineMerge } from 'modules/TemplatesWizard';

import TextMessage from '../../FieldBuilder/fields/components/TextMessage';
import { BaseSkill, InitSkillArgs, ViewComponentProps } from '../BaseSkill';
import FAQQuestions, { FAQQuestionsRow } from './FAQQuestions';

import styles from './styles.module.scss';

type FAQTableState = {
  static: FAQQuestionsRow[];
  custom: FAQQuestionsRow[];
};

const getFaqTitleTextBlock = () => ({
  title: t('FAQSkill:Fields:Title'),
  description: t('FAQSkill:Fields:Description'),
});

const getFaqCustomQuestionsTextBlock = () => ({
  title: t('FAQSkill:Fields:CustomQuestions:Title'),
  description: '',
});

const getStaticSuggests = () => [
  {
    question: t('FAQSkill:StaticSuggests:question1'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder1'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question2'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder2'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question3'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder3'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question4'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder4'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question5'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder5'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question6'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder6'),
  },
  {
    question: t('FAQSkill:StaticSuggests:question7'),
    answerPlaceholder: t('FAQSkill:StaticSuggests:answerPlaceholder7'),
  },
];

const getDefaultRows = () =>
  [
    {
      question: t('FAQSkill:StaticSuggests:question1'),
      answer: '',
    },
    {
      question: t('FAQSkill:StaticSuggests:question2'),
      answer: '',
    },
    {
      question: t('FAQSkill:StaticSuggests:question3'),
      answer: '',
    },
    {
      question: t('FAQSkill:StaticSuggests:question4'),
      answer: '',
    },
  ] as FAQQuestionsRow[];

interface FaqSkillProps extends ViewComponentProps {
  skill: FaqSkill;
}
const FaqSkillView = React.memo(({ skill, index }: FaqSkillProps) => {
  const forceUpdate = useForceUpdate();
  useEffect(() => skill.subscribe('forceUpdate', forceUpdate), [skill, forceUpdate]);

  const deleteDisable = useRef(false);
  const innerIntents = useRef<FAQTableState>(
    !isEmpty(skill.initialValues)
      ? skill.initialValues
      : {
          static: [] as FAQQuestionsRow[],
          custom: [] as FAQQuestionsRow[],
        }
  );
  const defaultValues = useRef<FAQTableState>({
    static: [],
    custom: [],
  });

  const onChange = useCallback(() => {
    skill.notify('onFieldsChange', { value: innerIntents.current, isDefaultValue: false });

    const faqQuestionsRows = [...innerIntents.current.static, ...innerIntents.current.custom];

    const isDeleteCurrentlyDisable = deleteDisable.current;
    deleteDisable.current = faqQuestionsRows.length === 1;
    if (isDeleteCurrentlyDisable !== deleteDisable.current) {
      forceUpdate();
    }

    const allDefaultValues = cloneDeep([...defaultValues.current.static, ...defaultValues.current.custom]);
    const res = mergeWith(allDefaultValues, faqQuestionsRows, combineMerge);
    skill.syncIntentsDebounced(res);
  }, [forceUpdate, skill]);

  const onChangeStatic = useCallback(
    (data: FAQQuestionsRow[], isDefault: boolean) => {
      if (isDefault) {
        defaultValues.current.static = data;
        const faqQuestionsRows = [...innerIntents.current.static, ...innerIntents.current.custom];
        const allDefaultValues = cloneDeep([...defaultValues.current.static, ...defaultValues.current.custom]);
        skill.notify('onFieldsChange', { value: defaultValues.current, isDefaultValue: true });
        skill.syncIntentsDebounced(mergeWith(allDefaultValues, faqQuestionsRows, combineMerge));
        return;
      }
      innerIntents.current.static = data;
      onChange();
    },
    [skill, onChange]
  );
  const onChangeCustom = useCallback(
    (data: FAQQuestionsRow[], isDefault: boolean) => {
      if (isDefault) {
        defaultValues.current.custom = data;
        skill.notify('onFieldsChange', { value: defaultValues.current, isDefaultValue: true });
        return;
      }
      innerIntents.current.custom = data;
      onChange();
    },
    [skill, onChange]
  );

  return (
    <SkillCollapseContainer skill={skill} index={index} wrapperClassName={styles.FaqSkill}>
      <div style={{ marginTop: 8 }}>{skill.skillConfig.description}</div>
      <TextMessage info={getFaqTitleTextBlock()} />
      {skill.isInitiated && (
        <FAQQuestions
          questionPlaceholder={t('FAQSkill:QuestionPlaceholder')}
          answerPlaceholder={t('FAQSkill:AnswerPlaceholder')}
          suggests={getStaticSuggests()}
          rows={innerIntents.current.static}
          deleteDisable={deleteDisable.current}
          onChange={onChangeStatic}
        />
      )}
      <TextMessage info={getFaqCustomQuestionsTextBlock()} />
      {skill.isInitiated && (
        <FAQQuestions
          questionPlaceholder={t('FAQSkill:QuestionPlaceholder')}
          answerPlaceholder={t('FAQSkill:AnswerPlaceholder')}
          rows={innerIntents.current.custom}
          deleteDisable={deleteDisable.current}
          onChange={onChangeCustom}
        />
      )}
    </SkillCollapseContainer>
  );
});

export class FaqSkill extends BaseSkill {
  private faqApi: FAQApi;
  private intentsApi: IntentsApi;
  public static knowledgeBaseName = 'FAQ.SkillsWizard';
  public static rootKnowledgeBasePath = `/KnowledgeBase/${this.knowledgeBaseName}`;
  private knowledgeBaseQuestions: FaqQuestionData[] = [];
  public isInitiated = false;

  constructor(public skillConfig: FAQSkillConfig) {
    super(skillConfig);
    this.faqApi = new FAQApi({ basePath: '/cailapub' });
    this.intentsApi = new IntentsApi({ basePath: '/cailapub' });
  }

  onMount = async () => {
    this.notify('loading', true);
    await this.initKnowledgeBase().finally(() => {
      this.forceUpdate();
      this.notify('loading', false);
    });
  };

  override init(args: InitSkillArgs) {
    super.init(args);
    this.initialValues ??= {
      static: getDefaultRows(),
      custom: [],
    };
  }

  initKnowledgeBase = async () => {
    if (!this.accountId || !this.projectId) return;
    const { data: rootIntents } = await this.intentsApi.searchIntents(this.accountId, this.projectId, {
      path: FaqSkill.rootKnowledgeBasePath,
    });
    if (rootIntents.length > 0 && rootIntents.find(intent => intent.path === FaqSkill.rootKnowledgeBasePath)) {
      await this.getAll();
      this.isInitiated = true;
      return;
    }
    await this.intentsApi.createIntent(this.accountId, this.projectId, {
      path: FaqSkill.rootKnowledgeBasePath,
    });
    this.isInitiated = true;
  };

  getAll = async () => {
    if (!this.accountId || !this.projectId) return;
    const { data: questions } = await this.faqApi.getFaqQuestionList(
      this.accountId,
      this.projectId,
      FaqSkill.rootKnowledgeBasePath
    );
    this.knowledgeBaseQuestions = questions.filter(question => question.intent.path !== FaqSkill.rootKnowledgeBasePath);
  };

  syncIntents = async (questions: FAQQuestionsRow[]) => {
    if (!this.accountId || !this.projectId) return;
    const newIntents = questions
      .filter(question => question.question)
      .map(question => ({
        intent: {
          path: this.getQuestionFullPath(question.question),
          phrases: [{ text: question.question }],
        },
        replies: [{ type: 'text', text: question.answer, markup: 'plain' }],
      })) as FaqQuestionData[];

    const { toDelete, toCreate, updateDiff } = diffInArrays(
      newIntents,
      this.knowledgeBaseQuestions,
      buildEqualFn(['intent.path']),
      buildEqualFn(['replies[0].text'])
    );

    const intentToDelete = toDelete.map(question => question.intent.path).filter(Boolean) as string[];

    if (intentToDelete.length > 0) {
      await this.intentsApi.deleteMultipleByPaths(this.accountId, this.projectId, intentToDelete);
    }
    this.knowledgeBaseQuestions = [];

    if (updateDiff.length > 0) {
      await Promise.allSettled(
        updateDiff.map(([oldIntent, newIntent]) => {
          if (!this.accountId || !this.projectId) return Promise.resolve();
          return this.faqApi.updateFaqQuestion(this.accountId, this.projectId, oldIntent.id as number, {
            id: oldIntent.id,
            intent: { ...newIntent.intent, id: oldIntent.intent.id },
            replies: newIntent.replies,
          });
        })
      );
    }

    if (toCreate.length > 0) {
      await Promise.allSettled(
        toCreate.map(question => this.faqApi.createFaqQuestion(this.accountId!, this.projectId!, question))
      );
    }
    await this.getAll();
  };

  syncIntentsDebounced = debounce((questions: FAQQuestionsRow[]) => {
    this.notify('loading', true);
    return this.syncIntents(questions).finally(() => this.notify('loading', false));
  }, 1000);

  ViewComponent = (props: ViewComponentProps) => {
    return <FaqSkillView skill={this} {...props} />;
  };

  private getQuestionFullPath(name: string) {
    return `${FaqSkill.rootKnowledgeBasePath}/Root/${name}`;
  }
}
