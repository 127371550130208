import React from 'react';
import localize from 'localization';
import BaseEditForm from '../BaseEditForm';
import classNames from 'classnames';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import { Banner, Collapse, Icon, InputText } from '@just-ai/just-ui';

import './style.scss';

const { translate } = localize;

export default class TelegramEditForm extends BaseEditForm {
  renderChanneTypeFormControl = () => {
    const { editableChannel } = this.props;
    return (
      <div className={classNames(classes.formControl, classes.hidden)}>
        <p className={classes.label}>{translate('Channel type')}</p>
        <InputText
          id='channel_type'
          disabled
          defaultValue={editableChannel.channelType}
          placeholder={translate('Enter custom', translate('Channel type').toLowerCase())}
        />
      </div>
    );
  };
  getMessageAboutConfiguration = () => {
    const { editableChannel, appConfig } = this.props;
    const { channelType, type } = editableChannel;
    const showHelp = appConfig?.zenflow?.showHelp;

    return (
      showHelp && (
        <div className='telegram_edit_form'>
          <Banner
            variant='info'
            BannerText={() => (
              <Collapse
                className='mb-0'
                label={
                  <div className='d-flex'>
                    <Icon className='mr-3' name='faInfoCircle' />
                    {translate('ChannelsAdding:Telegram:InfoLabel')}
                  </div>
                }
              >
                <span>{translate('ChannelsAdding:Telegram:InfoTitle')}</span>
                <ol style={{ paddingLeft: '1rem' }}>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: translate(
                        'ChannelsAdding:Telegram:InfoItem_1',
                        `<a target="_blank" href='https://t.me/BotFather'>@Botfather</a>`,
                        `<b>/newbot</b>`
                      ),
                    }}
                  ></li>
                  <li>{translate('ChannelsAdding:Telegram:InfoItem_2')}</li>
                  <li>{translate('ChannelsAdding:Telegram:InfoItem_3')}</li>
                  <li>{translate('ChannelsAdding:Telegram:InfoItem_4')}</li>
                </ol>
                <a target='_blank' href={translate(`${channelType || type} doc link`)} rel='noreferrer'>
                  {translate('ChannelsAdding:Telegram:InfoDocsLink')} <Icon size='sm' name='farExternalLink' />
                </a>
              </Collapse>
            )}
          />
        </div>
      )
    );
  };

  renderInputs() {
    const { editableChannel } = this.props;

    return (
      <div className={classNames(classes.formControl)}>
        <p className={classes.label}>{translate('Bot token')}</p>
        <InputText
          innerRef={input => (this.form.botToken = input)}
          id='bot_token'
          defaultValue={editableChannel.accessToken}
        />
      </div>
    );
  }
}
