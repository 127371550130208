// tslint:disable
// @ts-nocheck
/**
 * Integrationadapter REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 *
 * @export
 * @enum {string}
 */
export var IntegrationType;
(function (IntegrationType) {
    IntegrationType["Google"] = "google";
    IntegrationType["Stub"] = "stub";
    IntegrationType["Amocrm"] = "amocrm";
    IntegrationType["Github"] = "github";
})(IntegrationType || (IntegrationType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var TokenStatus;
(function (TokenStatus) {
    TokenStatus["OK"] = "OK";
    TokenStatus["INVALIDGRANT"] = "INVALID_GRANT";
    TokenStatus["UNKNOWNERROR"] = "UNKNOWN_ERROR";
})(TokenStatus || (TokenStatus = {}));
/**
 * AccountTokenApi - axios parameter creator
 * @export
 */
export const AccountTokenApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Delete account all integrations
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountAllIntegrations(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteAccountAllIntegrations.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integrations`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete integration token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationTokenData(accountId, integrationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteIntegrationTokenData.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling deleteIntegrationTokenData.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete project integrations
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectIntegrations(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteProjectIntegrations.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling deleteProjectIntegrations.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integrations/by-project`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get integration token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationTokenData(accountId, integrationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getIntegrationTokenData.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling getIntegrationTokenData.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project integrations
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectIntegrations(accountId, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getProjectIntegrations.");
            }
            // verify required parameter 'projectShortName' is not null or undefined
            if (projectShortName === null || projectShortName === undefined) {
                throw new RequiredError("projectShortName", "Required parameter projectShortName was null or undefined when calling getProjectIntegrations.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integrations/by-project`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Add new integration data
         * @param {number} accountId Account identifier
         * @param {IntegrationType} integrationType Integration type, google just for example
         * @param {TokenData} tokenData
         * @param {string} [projectShortName] Project short name
         * @param {string} [integrationName] Integration name
         * @param {string} [integrationGroup] Integration group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNewIntegrationForAccount(accountId, integrationType, tokenData, projectShortName, integrationName, integrationGroup, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling saveNewIntegrationForAccount.");
            }
            // verify required parameter 'integrationType' is not null or undefined
            if (integrationType === null || integrationType === undefined) {
                throw new RequiredError("integrationType", "Required parameter integrationType was null or undefined when calling saveNewIntegrationForAccount.");
            }
            // verify required parameter 'tokenData' is not null or undefined
            if (tokenData === null || tokenData === undefined) {
                throw new RequiredError("tokenData", "Required parameter tokenData was null or undefined when calling saveNewIntegrationForAccount.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationType !== undefined) {
                localVarQueryParameter["integrationType"] = integrationType;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (integrationName !== undefined) {
                localVarQueryParameter["integrationName"] = integrationName;
            }
            if (integrationGroup !== undefined) {
                localVarQueryParameter["integrationGroup"] = integrationGroup;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tokenData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tokenData !== undefined ? tokenData : {})
                : tokenData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update integration token data
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {TokenData} tokenData
         * @param {string} [integrationName] Integration name
         * @param {string} [integrationGroup] Integration group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegrationTokenData(accountId, integrationId, tokenData, integrationName, integrationGroup, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateIntegrationTokenData.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling updateIntegrationTokenData.");
            }
            // verify required parameter 'tokenData' is not null or undefined
            if (tokenData === null || tokenData === undefined) {
                throw new RequiredError("tokenData", "Required parameter tokenData was null or undefined when calling updateIntegrationTokenData.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/integration/token`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (integrationName !== undefined) {
                localVarQueryParameter["integrationName"] = integrationName;
            }
            if (integrationGroup !== undefined) {
                localVarQueryParameter["integrationGroup"] = integrationGroup;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tokenData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tokenData !== undefined ? tokenData : {})
                : tokenData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccountTokenApi - functional programming interface
 * @export
 */
export const AccountTokenApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Delete account all integrations
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountAllIntegrations(accountId, options) {
            const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).deleteAccountAllIntegrations(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete integration token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationTokenData(accountId, integrationId, options) {
            const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).deleteIntegrationTokenData(accountId, integrationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete project integrations
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectIntegrations(accountId, projectShortName, options) {
            const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).deleteProjectIntegrations(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get integration token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationTokenData(accountId, integrationId, options) {
            const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).getIntegrationTokenData(accountId, integrationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project integrations
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectIntegrations(accountId, projectShortName, options) {
            const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).getProjectIntegrations(accountId, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Add new integration data
         * @param {number} accountId Account identifier
         * @param {IntegrationType} integrationType Integration type, google just for example
         * @param {TokenData} tokenData
         * @param {string} [projectShortName] Project short name
         * @param {string} [integrationName] Integration name
         * @param {string} [integrationGroup] Integration group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNewIntegrationForAccount(accountId, integrationType, tokenData, projectShortName, integrationName, integrationGroup, options) {
            const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).saveNewIntegrationForAccount(accountId, integrationType, tokenData, projectShortName, integrationName, integrationGroup, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update integration token data
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {TokenData} tokenData
         * @param {string} [integrationName] Integration name
         * @param {string} [integrationGroup] Integration group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegrationTokenData(accountId, integrationId, tokenData, integrationName, integrationGroup, options) {
            const localVarAxiosArgs = AccountTokenApiAxiosParamCreator(configuration).updateIntegrationTokenData(accountId, integrationId, tokenData, integrationName, integrationGroup, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccountTokenApi - factory interface
 * @export
 */
export const AccountTokenApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Delete account all integrations
         * @param {number} accountId Account identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountAllIntegrations(accountId, options) {
            return AccountTokenApiFp(configuration).deleteAccountAllIntegrations(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete integration token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIntegrationTokenData(accountId, integrationId, options) {
            return AccountTokenApiFp(configuration).deleteIntegrationTokenData(accountId, integrationId, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete project integrations
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectIntegrations(accountId, projectShortName, options) {
            return AccountTokenApiFp(configuration).deleteProjectIntegrations(accountId, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Get integration token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationTokenData(accountId, integrationId, options) {
            return AccountTokenApiFp(configuration).getIntegrationTokenData(accountId, integrationId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project integrations
         * @param {number} accountId Account identifier
         * @param {string} projectShortName Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectIntegrations(accountId, projectShortName, options) {
            return AccountTokenApiFp(configuration).getProjectIntegrations(accountId, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Add new integration data
         * @param {number} accountId Account identifier
         * @param {IntegrationType} integrationType Integration type, google just for example
         * @param {TokenData} tokenData
         * @param {string} [projectShortName] Project short name
         * @param {string} [integrationName] Integration name
         * @param {string} [integrationGroup] Integration group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNewIntegrationForAccount(accountId, integrationType, tokenData, projectShortName, integrationName, integrationGroup, options) {
            return AccountTokenApiFp(configuration).saveNewIntegrationForAccount(accountId, integrationType, tokenData, projectShortName, integrationName, integrationGroup, options)(axios, basePath);
        },
        /**
         *
         * @summary Update integration token data
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {TokenData} tokenData
         * @param {string} [integrationName] Integration name
         * @param {string} [integrationGroup] Integration group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIntegrationTokenData(accountId, integrationId, tokenData, integrationName, integrationGroup, options) {
            return AccountTokenApiFp(configuration).updateIntegrationTokenData(accountId, integrationId, tokenData, integrationName, integrationGroup, options)(axios, basePath);
        },
    };
};
/**
 * AccountTokenApi - object-oriented interface
 * @export
 * @class AccountTokenApi
 * @extends {BaseAPI}
 */
export class AccountTokenApi extends BaseAPI {
    /**
     *
     * @summary Delete account all integrations
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTokenApi
     */
    deleteAccountAllIntegrations(accountId, options) {
        return AccountTokenApiFp(this.configuration).deleteAccountAllIntegrations(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTokenApi
     */
    deleteIntegrationTokenData(accountId, integrationId, options) {
        return AccountTokenApiFp(this.configuration).deleteIntegrationTokenData(accountId, integrationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTokenApi
     */
    deleteProjectIntegrations(accountId, projectShortName, options) {
        return AccountTokenApiFp(this.configuration).deleteProjectIntegrations(accountId, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get integration token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTokenApi
     */
    getIntegrationTokenData(accountId, integrationId, options) {
        return AccountTokenApiFp(this.configuration).getIntegrationTokenData(accountId, integrationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project integrations
     * @param {number} accountId Account identifier
     * @param {string} projectShortName Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTokenApi
     */
    getProjectIntegrations(accountId, projectShortName, options) {
        return AccountTokenApiFp(this.configuration).getProjectIntegrations(accountId, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Add new integration data
     * @param {number} accountId Account identifier
     * @param {IntegrationType} integrationType Integration type, google just for example
     * @param {TokenData} tokenData
     * @param {string} [projectShortName] Project short name
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTokenApi
     */
    saveNewIntegrationForAccount(accountId, integrationType, tokenData, projectShortName, integrationName, integrationGroup, options) {
        return AccountTokenApiFp(this.configuration).saveNewIntegrationForAccount(accountId, integrationType, tokenData, projectShortName, integrationName, integrationGroup, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update integration token data
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {TokenData} tokenData
     * @param {string} [integrationName] Integration name
     * @param {string} [integrationGroup] Integration group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountTokenApi
     */
    updateIntegrationTokenData(accountId, integrationId, tokenData, integrationName, integrationGroup, options) {
        return AccountTokenApiFp(this.configuration).updateIntegrationTokenData(accountId, integrationId, tokenData, integrationName, integrationGroup, options)(this.axios, this.basePath);
    }
}
/**
 * AmoCrmApi - axios parameter creator
 * @export
 */
export const AmoCrmApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Contract request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByContractRequest(integrationId, amoCrmContractRequestData, options = {}) {
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling addLeadByContractRequest.");
            }
            // verify required parameter 'amoCrmContractRequestData' is not null or undefined
            if (amoCrmContractRequestData === null ||
                amoCrmContractRequestData === undefined) {
                throw new RequiredError("amoCrmContractRequestData", "Required parameter amoCrmContractRequestData was null or undefined when calling addLeadByContractRequest.");
            }
            const localVarPath = `/api/integrationadapter/accounts/amocrm/lead/contract-request`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof amoCrmContractRequestData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(amoCrmContractRequestData !== undefined
                    ? amoCrmContractRequestData
                    : {})
                : amoCrmContractRequestData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create partner request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByCreatePartnerRequest(integrationId, amoCrmCreatePartnerRequestData, options = {}) {
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling addLeadByCreatePartnerRequest.");
            }
            // verify required parameter 'amoCrmCreatePartnerRequestData' is not null or undefined
            if (amoCrmCreatePartnerRequestData === null ||
                amoCrmCreatePartnerRequestData === undefined) {
                throw new RequiredError("amoCrmCreatePartnerRequestData", "Required parameter amoCrmCreatePartnerRequestData was null or undefined when calling addLeadByCreatePartnerRequest.");
            }
            const localVarPath = `/api/integrationadapter/accounts/amocrm/lead/create-partner-request`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof amoCrmCreatePartnerRequestData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(amoCrmCreatePartnerRequestData !== undefined
                    ? amoCrmCreatePartnerRequestData
                    : {})
                : amoCrmCreatePartnerRequestData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Development request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByDevelopmentRequest(integrationId, amoCrmDevelopmentRequestData, options = {}) {
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling addLeadByDevelopmentRequest.");
            }
            // verify required parameter 'amoCrmDevelopmentRequestData' is not null or undefined
            if (amoCrmDevelopmentRequestData === null ||
                amoCrmDevelopmentRequestData === undefined) {
                throw new RequiredError("amoCrmDevelopmentRequestData", "Required parameter amoCrmDevelopmentRequestData was null or undefined when calling addLeadByDevelopmentRequest.");
            }
            const localVarPath = `/api/integrationadapter/accounts/amocrm/lead/development-request`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof amoCrmDevelopmentRequestData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(amoCrmDevelopmentRequestData !== undefined
                    ? amoCrmDevelopmentRequestData
                    : {})
                : amoCrmDevelopmentRequestData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AmoCrmApi - functional programming interface
 * @export
 */
export const AmoCrmApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Contract request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByContractRequest(integrationId, amoCrmContractRequestData, options) {
            const localVarAxiosArgs = AmoCrmApiAxiosParamCreator(configuration).addLeadByContractRequest(integrationId, amoCrmContractRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create partner request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByCreatePartnerRequest(integrationId, amoCrmCreatePartnerRequestData, options) {
            const localVarAxiosArgs = AmoCrmApiAxiosParamCreator(configuration).addLeadByCreatePartnerRequest(integrationId, amoCrmCreatePartnerRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Development request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByDevelopmentRequest(integrationId, amoCrmDevelopmentRequestData, options) {
            const localVarAxiosArgs = AmoCrmApiAxiosParamCreator(configuration).addLeadByDevelopmentRequest(integrationId, amoCrmDevelopmentRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AmoCrmApi - factory interface
 * @export
 */
export const AmoCrmApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Contract request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByContractRequest(integrationId, amoCrmContractRequestData, options) {
            return AmoCrmApiFp(configuration).addLeadByContractRequest(integrationId, amoCrmContractRequestData, options)(axios, basePath);
        },
        /**
         *
         * @summary Create partner request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByCreatePartnerRequest(integrationId, amoCrmCreatePartnerRequestData, options) {
            return AmoCrmApiFp(configuration).addLeadByCreatePartnerRequest(integrationId, amoCrmCreatePartnerRequestData, options)(axios, basePath);
        },
        /**
         *
         * @summary Development request
         * @param {string} integrationId Account integration identifier
         * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLeadByDevelopmentRequest(integrationId, amoCrmDevelopmentRequestData, options) {
            return AmoCrmApiFp(configuration).addLeadByDevelopmentRequest(integrationId, amoCrmDevelopmentRequestData, options)(axios, basePath);
        },
    };
};
/**
 * AmoCrmApi - object-oriented interface
 * @export
 * @class AmoCrmApi
 * @extends {BaseAPI}
 */
export class AmoCrmApi extends BaseAPI {
    /**
     *
     * @summary Contract request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmContractRequestData} amoCrmContractRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmoCrmApi
     */
    addLeadByContractRequest(integrationId, amoCrmContractRequestData, options) {
        return AmoCrmApiFp(this.configuration).addLeadByContractRequest(integrationId, amoCrmContractRequestData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create partner request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmCreatePartnerRequestData} amoCrmCreatePartnerRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmoCrmApi
     */
    addLeadByCreatePartnerRequest(integrationId, amoCrmCreatePartnerRequestData, options) {
        return AmoCrmApiFp(this.configuration).addLeadByCreatePartnerRequest(integrationId, amoCrmCreatePartnerRequestData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Development request
     * @param {string} integrationId Account integration identifier
     * @param {AmoCrmDevelopmentRequestData} amoCrmDevelopmentRequestData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmoCrmApi
     */
    addLeadByDevelopmentRequest(integrationId, amoCrmDevelopmentRequestData, options) {
        return AmoCrmApiFp(this.configuration).addLeadByDevelopmentRequest(integrationId, amoCrmDevelopmentRequestData, options)(this.axios, this.basePath);
    }
}
/**
 * CustomRequestApi - axios parameter creator
 * @export
 */
export const CustomRequestApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Execute some custom request with token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {CustomRequestData} customRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCustomRequest(accountId, integrationId, customRequestData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling executeCustomRequest.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling executeCustomRequest.");
            }
            // verify required parameter 'customRequestData' is not null or undefined
            if (customRequestData === null || customRequestData === undefined) {
                throw new RequiredError("customRequestData", "Required parameter customRequestData was null or undefined when calling executeCustomRequest.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/custom-request`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof customRequestData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(customRequestData !== undefined ? customRequestData : {})
                : customRequestData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CustomRequestApi - functional programming interface
 * @export
 */
export const CustomRequestApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Execute some custom request with token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {CustomRequestData} customRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCustomRequest(accountId, integrationId, customRequestData, options) {
            const localVarAxiosArgs = CustomRequestApiAxiosParamCreator(configuration).executeCustomRequest(accountId, integrationId, customRequestData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * CustomRequestApi - factory interface
 * @export
 */
export const CustomRequestApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Execute some custom request with token
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {CustomRequestData} customRequestData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeCustomRequest(accountId, integrationId, customRequestData, options) {
            return CustomRequestApiFp(configuration).executeCustomRequest(accountId, integrationId, customRequestData, options)(axios, basePath);
        },
    };
};
/**
 * CustomRequestApi - object-oriented interface
 * @export
 * @class CustomRequestApi
 * @extends {BaseAPI}
 */
export class CustomRequestApi extends BaseAPI {
    /**
     *
     * @summary Execute some custom request with token
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {CustomRequestData} customRequestData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomRequestApi
     */
    executeCustomRequest(accountId, integrationId, customRequestData, options) {
        return CustomRequestApiFp(this.configuration).executeCustomRequest(accountId, integrationId, customRequestData, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError("loggingEnabledE2ECheck", "Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.");
            }
            const localVarPath = `/api/integrationadapter/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (zRequestId !== undefined && zRequestId !== null) {
                localVarHeaderParameter["Z-requestId"] = String(zRequestId);
            }
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter["loggingEnabledE2ECheck"] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Method for E2ECheck.
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {string} [zRequestId] Request id header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     * Method for E2ECheck.
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {string} [zRequestId] Request id header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, zRequestId, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, zRequestId, options)(this.axios, this.basePath);
    }
}
/**
 * GitApi - axios parameter creator
 * @export
 */
export const GitApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create git repository for integrationId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} repositoryName Name to create repository
         * @param {boolean} [isPrivate] Whether the repository is private. Default false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRepository(accountId, integrationId, repositoryName, isPrivate, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createRepository.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling createRepository.");
            }
            // verify required parameter 'repositoryName' is not null or undefined
            if (repositoryName === null || repositoryName === undefined) {
                throw new RequiredError("repositoryName", "Required parameter repositoryName was null or undefined when calling createRepository.");
            }
            const localVarPath = `/api/integrationadapter/accounts/{accountId}/git/repository/create`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (repositoryName !== undefined) {
                localVarQueryParameter["repositoryName"] = repositoryName;
            }
            if (isPrivate !== undefined) {
                localVarQueryParameter["isPrivate"] = isPrivate;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get branches for repository.
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} repositoryUri Repository URI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranches(accountId, integrationId, repositoryUri, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getBranches.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling getBranches.");
            }
            // verify required parameter 'repositoryUri' is not null or undefined
            if (repositoryUri === null || repositoryUri === undefined) {
                throw new RequiredError("repositoryUri", "Required parameter repositoryUri was null or undefined when calling getBranches.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/git/repository/branches`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (repositoryUri !== undefined) {
                localVarQueryParameter["repositoryUri"] = repositoryUri;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of repositories for integratioId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepositories(accountId, integrationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getRepositories.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling getRepositories.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/git/repositories`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get access token by integrationId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCredentialsByIntegrationId(accountId, integrationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getUserCredentialsByIntegrationId.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling getUserCredentialsByIntegrationId.");
            }
            const localVarPath = `/api/integrationadapter/accounts/{accountId}/git/user-credentials`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GitApi - functional programming interface
 * @export
 */
export const GitApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create git repository for integrationId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} repositoryName Name to create repository
         * @param {boolean} [isPrivate] Whether the repository is private. Default false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRepository(accountId, integrationId, repositoryName, isPrivate, options) {
            const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).createRepository(accountId, integrationId, repositoryName, isPrivate, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get branches for repository.
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} repositoryUri Repository URI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranches(accountId, integrationId, repositoryUri, options) {
            const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getBranches(accountId, integrationId, repositoryUri, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of repositories for integratioId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepositories(accountId, integrationId, options) {
            const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getRepositories(accountId, integrationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get access token by integrationId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCredentialsByIntegrationId(accountId, integrationId, options) {
            const localVarAxiosArgs = GitApiAxiosParamCreator(configuration).getUserCredentialsByIntegrationId(accountId, integrationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * GitApi - factory interface
 * @export
 */
export const GitApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create git repository for integrationId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} repositoryName Name to create repository
         * @param {boolean} [isPrivate] Whether the repository is private. Default false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRepository(accountId, integrationId, repositoryName, isPrivate, options) {
            return GitApiFp(configuration).createRepository(accountId, integrationId, repositoryName, isPrivate, options)(axios, basePath);
        },
        /**
         *
         * @summary Get branches for repository.
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} repositoryUri Repository URI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranches(accountId, integrationId, repositoryUri, options) {
            return GitApiFp(configuration).getBranches(accountId, integrationId, repositoryUri, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of repositories for integratioId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRepositories(accountId, integrationId, options) {
            return GitApiFp(configuration).getRepositories(accountId, integrationId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get access token by integrationId
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCredentialsByIntegrationId(accountId, integrationId, options) {
            return GitApiFp(configuration).getUserCredentialsByIntegrationId(accountId, integrationId, options)(axios, basePath);
        },
    };
};
/**
 * GitApi - object-oriented interface
 * @export
 * @class GitApi
 * @extends {BaseAPI}
 */
export class GitApi extends BaseAPI {
    /**
     *
     * @summary Create git repository for integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryName Name to create repository
     * @param {boolean} [isPrivate] Whether the repository is private. Default false.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    createRepository(accountId, integrationId, repositoryName, isPrivate, options) {
        return GitApiFp(this.configuration).createRepository(accountId, integrationId, repositoryName, isPrivate, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get branches for repository.
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} repositoryUri Repository URI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    getBranches(accountId, integrationId, repositoryUri, options) {
        return GitApiFp(this.configuration).getBranches(accountId, integrationId, repositoryUri, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get list of repositories for integratioId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    getRepositories(accountId, integrationId, options) {
        return GitApiFp(this.configuration).getRepositories(accountId, integrationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get access token by integrationId
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitApi
     */
    getUserCredentialsByIntegrationId(accountId, integrationId, options) {
        return GitApiFp(this.configuration).getUserCredentialsByIntegrationId(accountId, integrationId, options)(this.axios, this.basePath);
    }
}
/**
 * GoogleSheetsApi - axios parameter creator
 * @export
 */
export const GoogleSheetsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Clear cell data from spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCellData(accountId, integrationId, spreadsheetId, sheetName, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling clearCellData.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling clearCellData.");
            }
            // verify required parameter 'spreadsheetId' is not null or undefined
            if (spreadsheetId === null || spreadsheetId === undefined) {
                throw new RequiredError("spreadsheetId", "Required parameter spreadsheetId was null or undefined when calling clearCellData.");
            }
            // verify required parameter 'sheetName' is not null or undefined
            if (sheetName === null || sheetName === undefined) {
                throw new RequiredError("sheetName", "Required parameter sheetName was null or undefined when calling clearCellData.");
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError("requestBody", "Required parameter requestBody was null or undefined when calling clearCellData.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/cells`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"spreadsheetId"}}`, encodeURIComponent(String(spreadsheetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (sheetName !== undefined) {
                localVarQueryParameter["sheetName"] = sheetName;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a new spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetName New spreasheet name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSpreadsheet(accountId, integrationId, spreadsheetName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createNewSpreadsheet.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling createNewSpreadsheet.");
            }
            // verify required parameter 'spreadsheetName' is not null or undefined
            if (spreadsheetName === null || spreadsheetName === undefined) {
                throw new RequiredError("spreadsheetName", "Required parameter spreadsheetName was null or undefined when calling createNewSpreadsheet.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (spreadsheetName !== undefined) {
                localVarQueryParameter["spreadsheetName"] = spreadsheetName;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete row or column from spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRowOrColumn(accountId, integrationId, spreadsheetId, sheetName, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteRowOrColumn.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling deleteRowOrColumn.");
            }
            // verify required parameter 'spreadsheetId' is not null or undefined
            if (spreadsheetId === null || spreadsheetId === undefined) {
                throw new RequiredError("spreadsheetId", "Required parameter spreadsheetId was null or undefined when calling deleteRowOrColumn.");
            }
            // verify required parameter 'sheetName' is not null or undefined
            if (sheetName === null || sheetName === undefined) {
                throw new RequiredError("sheetName", "Required parameter sheetName was null or undefined when calling deleteRowOrColumn.");
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError("requestBody", "Required parameter requestBody was null or undefined when calling deleteRowOrColumn.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/line`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"spreadsheetId"}}`, encodeURIComponent(String(spreadsheetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (sheetName !== undefined) {
                localVarQueryParameter["sheetName"] = sheetName;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of sheets of spreadsheets
         * @param {number} accountId Account identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfSheets(accountId, spreadsheetId, integrationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getListOfSheets.");
            }
            // verify required parameter 'spreadsheetId' is not null or undefined
            if (spreadsheetId === null || spreadsheetId === undefined) {
                throw new RequiredError("spreadsheetId", "Required parameter spreadsheetId was null or undefined when calling getListOfSheets.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling getListOfSheets.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"spreadsheetId"}}`, encodeURIComponent(String(spreadsheetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of sheets of spreadsheets
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfSpreadsheets(accountId, integrationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getListOfSpreadsheets.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling getListOfSpreadsheets.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Read data from cells
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDataFromCells(accountId, integrationId, spreadsheetId, sheetName, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling readDataFromCells.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling readDataFromCells.");
            }
            // verify required parameter 'spreadsheetId' is not null or undefined
            if (spreadsheetId === null || spreadsheetId === undefined) {
                throw new RequiredError("spreadsheetId", "Required parameter spreadsheetId was null or undefined when calling readDataFromCells.");
            }
            // verify required parameter 'sheetName' is not null or undefined
            if (sheetName === null || sheetName === undefined) {
                throw new RequiredError("sheetName", "Required parameter sheetName was null or undefined when calling readDataFromCells.");
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError("requestBody", "Required parameter requestBody was null or undefined when calling readDataFromCells.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/cells/value`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"spreadsheetId"}}`, encodeURIComponent(String(spreadsheetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (sheetName !== undefined) {
                localVarQueryParameter["sheetName"] = sheetName;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Write data to cells
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDataToCells(accountId, integrationId, spreadsheetId, sheetName, spreadsheetCellListData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling writeDataToCells.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling writeDataToCells.");
            }
            // verify required parameter 'spreadsheetId' is not null or undefined
            if (spreadsheetId === null || spreadsheetId === undefined) {
                throw new RequiredError("spreadsheetId", "Required parameter spreadsheetId was null or undefined when calling writeDataToCells.");
            }
            // verify required parameter 'sheetName' is not null or undefined
            if (sheetName === null || sheetName === undefined) {
                throw new RequiredError("sheetName", "Required parameter sheetName was null or undefined when calling writeDataToCells.");
            }
            // verify required parameter 'spreadsheetCellListData' is not null or undefined
            if (spreadsheetCellListData === null ||
                spreadsheetCellListData === undefined) {
                throw new RequiredError("spreadsheetCellListData", "Required parameter spreadsheetCellListData was null or undefined when calling writeDataToCells.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/cells`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"spreadsheetId"}}`, encodeURIComponent(String(spreadsheetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (sheetName !== undefined) {
                localVarQueryParameter["sheetName"] = sheetName;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof spreadsheetCellListData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(spreadsheetCellListData !== undefined ? spreadsheetCellListData : {})
                : spreadsheetCellListData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Write data to line
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody List of strings to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDataToLine(accountId, integrationId, spreadsheetId, sheetName, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling writeDataToLine.");
            }
            // verify required parameter 'integrationId' is not null or undefined
            if (integrationId === null || integrationId === undefined) {
                throw new RequiredError("integrationId", "Required parameter integrationId was null or undefined when calling writeDataToLine.");
            }
            // verify required parameter 'spreadsheetId' is not null or undefined
            if (spreadsheetId === null || spreadsheetId === undefined) {
                throw new RequiredError("spreadsheetId", "Required parameter spreadsheetId was null or undefined when calling writeDataToLine.");
            }
            // verify required parameter 'sheetName' is not null or undefined
            if (sheetName === null || sheetName === undefined) {
                throw new RequiredError("sheetName", "Required parameter sheetName was null or undefined when calling writeDataToLine.");
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError("requestBody", "Required parameter requestBody was null or undefined when calling writeDataToLine.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/google/spreadsheets/{spreadsheetId}/sheets/line`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"spreadsheetId"}}`, encodeURIComponent(String(spreadsheetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (integrationId !== undefined) {
                localVarQueryParameter["integrationId"] = integrationId;
            }
            if (sheetName !== undefined) {
                localVarQueryParameter["sheetName"] = sheetName;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GoogleSheetsApi - functional programming interface
 * @export
 */
export const GoogleSheetsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Clear cell data from spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCellData(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).clearCellData(accountId, integrationId, spreadsheetId, sheetName, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create a new spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetName New spreasheet name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSpreadsheet(accountId, integrationId, spreadsheetName, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).createNewSpreadsheet(accountId, integrationId, spreadsheetName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete row or column from spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRowOrColumn(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).deleteRowOrColumn(accountId, integrationId, spreadsheetId, sheetName, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of sheets of spreadsheets
         * @param {number} accountId Account identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfSheets(accountId, spreadsheetId, integrationId, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).getListOfSheets(accountId, spreadsheetId, integrationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of sheets of spreadsheets
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfSpreadsheets(accountId, integrationId, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).getListOfSpreadsheets(accountId, integrationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Read data from cells
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDataFromCells(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).readDataFromCells(accountId, integrationId, spreadsheetId, sheetName, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Write data to cells
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDataToCells(accountId, integrationId, spreadsheetId, sheetName, spreadsheetCellListData, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).writeDataToCells(accountId, integrationId, spreadsheetId, sheetName, spreadsheetCellListData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Write data to line
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody List of strings to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDataToLine(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            const localVarAxiosArgs = GoogleSheetsApiAxiosParamCreator(configuration).writeDataToLine(accountId, integrationId, spreadsheetId, sheetName, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * GoogleSheetsApi - factory interface
 * @export
 */
export const GoogleSheetsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Clear cell data from spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCellData(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            return GoogleSheetsApiFp(configuration).clearCellData(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @summary Create a new spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetName New spreasheet name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSpreadsheet(accountId, integrationId, spreadsheetName, options) {
            return GoogleSheetsApiFp(configuration).createNewSpreadsheet(accountId, integrationId, spreadsheetName, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete row or column from spreadsheet
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRowOrColumn(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            return GoogleSheetsApiFp(configuration).deleteRowOrColumn(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of sheets of spreadsheets
         * @param {number} accountId Account identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfSheets(accountId, spreadsheetId, integrationId, options) {
            return GoogleSheetsApiFp(configuration).getListOfSheets(accountId, spreadsheetId, integrationId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of sheets of spreadsheets
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListOfSpreadsheets(accountId, integrationId, options) {
            return GoogleSheetsApiFp(configuration).getListOfSpreadsheets(accountId, integrationId, options)(axios, basePath);
        },
        /**
         *
         * @summary Read data from cells
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDataFromCells(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            return GoogleSheetsApiFp(configuration).readDataFromCells(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @summary Write data to cells
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDataToCells(accountId, integrationId, spreadsheetId, sheetName, spreadsheetCellListData, options) {
            return GoogleSheetsApiFp(configuration).writeDataToCells(accountId, integrationId, spreadsheetId, sheetName, spreadsheetCellListData, options)(axios, basePath);
        },
        /**
         *
         * @summary Write data to line
         * @param {number} accountId Account identifier
         * @param {string} integrationId Account integration identifier
         * @param {string} spreadsheetId Google spreadsheet identifier
         * @param {string} sheetName Google spreadsheet sheet name
         * @param {Array<string>} requestBody List of strings to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeDataToLine(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
            return GoogleSheetsApiFp(configuration).writeDataToLine(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(axios, basePath);
        },
    };
};
/**
 * GoogleSheetsApi - object-oriented interface
 * @export
 * @class GoogleSheetsApi
 * @extends {BaseAPI}
 */
export class GoogleSheetsApi extends BaseAPI {
    /**
     *
     * @summary Clear cell data from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    clearCellData(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
        return GoogleSheetsApiFp(this.configuration).clearCellData(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create a new spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetName New spreasheet name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    createNewSpreadsheet(accountId, integrationId, spreadsheetName, options) {
        return GoogleSheetsApiFp(this.configuration).createNewSpreadsheet(accountId, integrationId, spreadsheetName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete row or column from spreadsheet
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    deleteRowOrColumn(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
        return GoogleSheetsApiFp(this.configuration).deleteRowOrColumn(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    getListOfSheets(accountId, spreadsheetId, integrationId, options) {
        return GoogleSheetsApiFp(this.configuration).getListOfSheets(accountId, spreadsheetId, integrationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get list of sheets of spreadsheets
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    getListOfSpreadsheets(accountId, integrationId, options) {
        return GoogleSheetsApiFp(this.configuration).getListOfSpreadsheets(accountId, integrationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Read data from cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    readDataFromCells(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
        return GoogleSheetsApiFp(this.configuration).readDataFromCells(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Write data to cells
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<SpreadsheetCellListData>} spreadsheetCellListData List of data to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    writeDataToCells(accountId, integrationId, spreadsheetId, sheetName, spreadsheetCellListData, options) {
        return GoogleSheetsApiFp(this.configuration).writeDataToCells(accountId, integrationId, spreadsheetId, sheetName, spreadsheetCellListData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Write data to line
     * @param {number} accountId Account identifier
     * @param {string} integrationId Account integration identifier
     * @param {string} spreadsheetId Google spreadsheet identifier
     * @param {string} sheetName Google spreadsheet sheet name
     * @param {Array<string>} requestBody List of strings to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleSheetsApi
     */
    writeDataToLine(accountId, integrationId, spreadsheetId, sheetName, requestBody, options) {
        return GoogleSheetsApiFp(this.configuration).writeDataToLine(accountId, integrationId, spreadsheetId, sheetName, requestBody, options)(this.axios, this.basePath);
    }
}
/**
 * OAuthAuthenticationApi - axios parameter creator
 * @export
 */
export const OAuthAuthenticationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Handle amoCrm oauth code
         * @param {string} code AmoCrm oauth code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAmoCrmOAuthCode(code, options = {}) {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError("code", "Required parameter code was null or undefined when calling handleAmoCrmOAuthCode.");
            }
            const localVarPath = `/api/integrationadapter/oauth-authentication/amo-crm/code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (code !== undefined) {
                localVarQueryParameter["code"] = code;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Handle github oauth code
         * @param {string} state Google oauth state
         * @param {string} [code] oauth code
         * @param {string} [error] Error from github
         * @param {string} [errorDescription] Error description from github
         * @param {string} [errorUri] Error uri from github
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGitHubOAuthCode(state, code, error, errorDescription, errorUri, options = {}) {
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError("state", "Required parameter state was null or undefined when calling handleGitHubOAuthCode.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/0/oauth-authentication/github/code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (code !== undefined) {
                localVarQueryParameter["code"] = code;
            }
            if (state !== undefined) {
                localVarQueryParameter["state"] = state;
            }
            if (error !== undefined) {
                localVarQueryParameter["error"] = error;
            }
            if (errorDescription !== undefined) {
                localVarQueryParameter["error_description"] = errorDescription;
            }
            if (errorUri !== undefined) {
                localVarQueryParameter["error_uri"] = errorUri;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Handle google oauth code
         * @param {string} code Google oauth code
         * @param {string} state Google oauth state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGoogleOAuthCode(code, state, options = {}) {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError("code", "Required parameter code was null or undefined when calling handleGoogleOAuthCode.");
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError("state", "Required parameter state was null or undefined when calling handleGoogleOAuthCode.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/0/oauth-authentication/google/code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (code !== undefined) {
                localVarQueryParameter["code"] = code;
            }
            if (state !== undefined) {
                localVarQueryParameter["state"] = state;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Request github oauth code
         * @param {number} accountId Account identifier
         * @param {string} redirectUrl Redirect url
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGitHubOauthCode(accountId, redirectUrl, projectShortName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling requestGitHubOauthCode.");
            }
            // verify required parameter 'redirectUrl' is not null or undefined
            if (redirectUrl === null || redirectUrl === undefined) {
                throw new RequiredError("redirectUrl", "Required parameter redirectUrl was null or undefined when calling requestGitHubOauthCode.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/oauth-authentication/github`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (redirectUrl !== undefined) {
                localVarQueryParameter["redirectUrl"] = redirectUrl;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Request google oauth code
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [redirectUrl] Redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGoogleOauthCode(accountId, projectShortName, redirectUrl, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling requestGoogleOauthCode.");
            }
            const localVarPath = `/api/integrationadapter/v1/accounts/{accountId}/oauth-authentication/google`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectShortName !== undefined) {
                localVarQueryParameter["projectShortName"] = projectShortName;
            }
            if (redirectUrl !== undefined) {
                localVarQueryParameter["redirectUrl"] = redirectUrl;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * OAuthAuthenticationApi - functional programming interface
 * @export
 */
export const OAuthAuthenticationApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Handle amoCrm oauth code
         * @param {string} code AmoCrm oauth code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAmoCrmOAuthCode(code, options) {
            const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).handleAmoCrmOAuthCode(code, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Handle github oauth code
         * @param {string} state Google oauth state
         * @param {string} [code] oauth code
         * @param {string} [error] Error from github
         * @param {string} [errorDescription] Error description from github
         * @param {string} [errorUri] Error uri from github
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGitHubOAuthCode(state, code, error, errorDescription, errorUri, options) {
            const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).handleGitHubOAuthCode(state, code, error, errorDescription, errorUri, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Handle google oauth code
         * @param {string} code Google oauth code
         * @param {string} state Google oauth state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGoogleOAuthCode(code, state, options) {
            const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).handleGoogleOAuthCode(code, state, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Request github oauth code
         * @param {number} accountId Account identifier
         * @param {string} redirectUrl Redirect url
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGitHubOauthCode(accountId, redirectUrl, projectShortName, options) {
            const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).requestGitHubOauthCode(accountId, redirectUrl, projectShortName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Request google oauth code
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [redirectUrl] Redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGoogleOauthCode(accountId, projectShortName, redirectUrl, options) {
            const localVarAxiosArgs = OAuthAuthenticationApiAxiosParamCreator(configuration).requestGoogleOauthCode(accountId, projectShortName, redirectUrl, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * OAuthAuthenticationApi - factory interface
 * @export
 */
export const OAuthAuthenticationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Handle amoCrm oauth code
         * @param {string} code AmoCrm oauth code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleAmoCrmOAuthCode(code, options) {
            return OAuthAuthenticationApiFp(configuration).handleAmoCrmOAuthCode(code, options)(axios, basePath);
        },
        /**
         *
         * @summary Handle github oauth code
         * @param {string} state Google oauth state
         * @param {string} [code] oauth code
         * @param {string} [error] Error from github
         * @param {string} [errorDescription] Error description from github
         * @param {string} [errorUri] Error uri from github
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGitHubOAuthCode(state, code, error, errorDescription, errorUri, options) {
            return OAuthAuthenticationApiFp(configuration).handleGitHubOAuthCode(state, code, error, errorDescription, errorUri, options)(axios, basePath);
        },
        /**
         *
         * @summary Handle google oauth code
         * @param {string} code Google oauth code
         * @param {string} state Google oauth state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleGoogleOAuthCode(code, state, options) {
            return OAuthAuthenticationApiFp(configuration).handleGoogleOAuthCode(code, state, options)(axios, basePath);
        },
        /**
         *
         * @summary Request github oauth code
         * @param {number} accountId Account identifier
         * @param {string} redirectUrl Redirect url
         * @param {string} [projectShortName] Project short name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGitHubOauthCode(accountId, redirectUrl, projectShortName, options) {
            return OAuthAuthenticationApiFp(configuration).requestGitHubOauthCode(accountId, redirectUrl, projectShortName, options)(axios, basePath);
        },
        /**
         *
         * @summary Request google oauth code
         * @param {number} accountId Account identifier
         * @param {string} [projectShortName] Project short name
         * @param {string} [redirectUrl] Redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestGoogleOauthCode(accountId, projectShortName, redirectUrl, options) {
            return OAuthAuthenticationApiFp(configuration).requestGoogleOauthCode(accountId, projectShortName, redirectUrl, options)(axios, basePath);
        },
    };
};
/**
 * OAuthAuthenticationApi - object-oriented interface
 * @export
 * @class OAuthAuthenticationApi
 * @extends {BaseAPI}
 */
export class OAuthAuthenticationApi extends BaseAPI {
    /**
     *
     * @summary Handle amoCrm oauth code
     * @param {string} code AmoCrm oauth code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthAuthenticationApi
     */
    handleAmoCrmOAuthCode(code, options) {
        return OAuthAuthenticationApiFp(this.configuration).handleAmoCrmOAuthCode(code, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Handle github oauth code
     * @param {string} state Google oauth state
     * @param {string} [code] oauth code
     * @param {string} [error] Error from github
     * @param {string} [errorDescription] Error description from github
     * @param {string} [errorUri] Error uri from github
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthAuthenticationApi
     */
    handleGitHubOAuthCode(state, code, error, errorDescription, errorUri, options) {
        return OAuthAuthenticationApiFp(this.configuration).handleGitHubOAuthCode(state, code, error, errorDescription, errorUri, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Handle google oauth code
     * @param {string} code Google oauth code
     * @param {string} state Google oauth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthAuthenticationApi
     */
    handleGoogleOAuthCode(code, state, options) {
        return OAuthAuthenticationApiFp(this.configuration).handleGoogleOAuthCode(code, state, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Request github oauth code
     * @param {number} accountId Account identifier
     * @param {string} redirectUrl Redirect url
     * @param {string} [projectShortName] Project short name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthAuthenticationApi
     */
    requestGitHubOauthCode(accountId, redirectUrl, projectShortName, options) {
        return OAuthAuthenticationApiFp(this.configuration).requestGitHubOauthCode(accountId, redirectUrl, projectShortName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Request google oauth code
     * @param {number} accountId Account identifier
     * @param {string} [projectShortName] Project short name
     * @param {string} [redirectUrl] Redirect url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthAuthenticationApi
     */
    requestGoogleOauthCode(accountId, projectShortName, redirectUrl, options) {
        return OAuthAuthenticationApiFp(this.configuration).requestGoogleOauthCode(accountId, projectShortName, redirectUrl, options)(this.axios, this.basePath);
    }
}
