const drawerWidth = 260;
const drawerPaperCloseWidth = 102;

const styles = theme => ({
  hiddenLink: {
    position: 'absolute',
    top: -10000,
    left: -10000,
    display: 'block',
  },
  Popper: {
    marginLeft: 66,
  },
  tooltipClass: {
    zIndex: '1201 !important',
  },
  popoverRoot: {
    minWidth: '220px',
    marginLeft: 82,
    boxShadow: '0 0 1px 1px rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16);',
  },
  menuItemRootSplitter: {
    margin: '2px 0',
    padding: 0,
    height: 1,
    background: 'var(--gray-200)',
    '&:hover': {
      cursor: 'default',
      background: 'var(--gray-200)',
    },
  },
  menuItemRootName: {
    color: 'var(--gray-900)',
    textTransform: 'none',
    fontWeight: 'normal',
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`,
    '&:hover': {
      background: 'inherit',
      cursor: 'default',
    },
  },
  menuItemRoot: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: 'var(--primary)',

    '&:hover': {
      backgroundColor: 'var(--primary)',
      color: 'var(--white)',
      '& a': {
        color: 'var(--white)',
      },
    },

    '& a': {
      textDecoration: 'none',
      display: 'block',
      width: '100%',
    },

    '& a:hover': {
      color: 'var(--white)',
    },
  },
  menuListPadding: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  container: {
    height: '100%',
  },
  brand: {
    color: 'var(--white)',
    fontWeight: 500,
    fontSize: '1.5em',
    textDecoration: 'none',
    '&:hover': {
      color: 'var(--white)',
      textDecoration: 'none',
    },
  },
  menuButton: {
    borderRadius: '2px',
  },
  shift: {},
  drawerPaper: {
    backgroundColor: 'var(--gray-900)',
    position: 'relative',
    width: drawerWidth,
    boxSizing: 'content-box',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& *': {
      fontFamily: '"Roboto", sans-serif !important',
    },
  },
  drawerPaperClose: {
    width: `${drawerPaperCloseWidth}px`,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaperCalling: {
    backgroundColor: 'transparent',
    border: 'none',
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  drawerHeader: {
    padding: `${theme.spacing.unit * 1.75}px ${theme.spacing.unit * 2.5}px`,
    height: '72px !important',
    width: '102px !important',
  },
  appNavUser: {
    padding: `${theme.spacing.unit * 1.75}px ${theme.spacing.unit * 2.5}px`,
  },
  listItemClass: {
    padding: '0',
    width: '100%',

    '&.sidebarPerson': {
      width: 34,
      height: 34,
      padding: '4px 0',
      margin: '0 auto',
      borderRadius: '50%',
      background: 'var(--white)',
      '& svg': {
        width: 20,
        fill: 'var(--gray-950)',
      },
      '&:hover': {
        '& svg': {
          fill: 'inherit',
        },
      },
      '&.active': {
        background: 'var(--primary)',
        '& svg': {
          fill: 'var(--white)',
        },
      },
    },

    '& span.counter': {
      display: 'block',
      width: 8,
      height: 8,
      position: 'absolute',
      top: 7,
      right: -2,
      backgroundColor: 'var(--danger)',
      borderRadius: '50%',
    },
  },
  listItemClassCalls: {
    position: 'relative',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItemClassSplitter: {
    padding: '0',
    width: '100%',
  },
  listItemClassSplitterText: {
    boxSizing: 'border-box',
    textAlign: 'center',

    padding: '1.5em 1.9em',
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
    lineHeight: '1.71429',
    color: 'var(--white)',
    display: 'flex',
    fontWeight: 500,
    opacity: '1',

    '& div': {
      padding: 0,
    },
  },
  disabled: {
    cursor: 'no-drop !important',
  },

  listIcon: {
    color: 'var(--white)',
    marginRight: 0,
    pointerEvents: 'none',
  },
  botIcon: {
    display: 'inline-block',
    minHeight: '34px',
    minWidth: '34px',
    background: 'url("/img/botIcon.png") center center no-repeat',
    backgroundSize: 'contain',
    marginBottom: '0',
    marginRight: '0',
  },
  textListItem: {
    lineHeight: '24px',
    color: 'var(--white)',
  },
  listText: {
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
    padding: '1.5em 1.9em',
    lineHeight: '1.71429',
    color: 'var(--white)',
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    fontWeight: 500,
    textAlign: 'left',
    position: 'relative',

    '&:hover': {
      color: 'var(--gray-100)',
      textDecoration: 'none',

      '& svg': {
        fill: 'var(--gray-100)',
      },
    },
    '&.active': {
      backgroundColor: 'rgb(83, 88, 92, 0.5)',
      '& svg': {
        fill: 'var(--white)',
      },
    },
    '& svg': {
      fill: 'var(--white)',
    },
  },
  listTextCalls: {
    color: 'var(--primary)',
    minHeight: 80,
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div': {
      flexGrow: 0,
    },

    '&:hover': {
      color: 'var(--primary)',
      backgroundColor: 'transparent',
      '& svg': {
        fill: 'var(--primary)',
      },
      '&:before': {
        left: 0,
        top: 0,
        height: '100%',
        width: 4,
        position: 'absolute',
        backgroundColor: 'var(--primary)',
        content: "' '",
        borderRadius: 2,
      },
    },
    '&.active': {
      backgroundColor: 'transparent',
      '& svg': {
        fill: 'var(--primary)',
      },
      '&:before': {
        left: 0,
        top: 0,
        height: '100%',
        width: 4,
        position: 'absolute',
        backgroundColor: 'var(--primary)',
        content: "' '",
        borderRadius: 2,
      },
    },
    '& svg': {
      fill: 'var(--primary)',
    },
  },
  listItemClassBlock: {},
  listIconBlock: {},
  botIconBlock: {
    marginBottom: '10px',
  },
  textListItemBlock: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0',
    lineHeight: '1.5',
  },
  textListItemBlockCalls: {},
  listItemClassSplitterTextBlock: {
    fontSize: '.75em',
    display: 'block',
    textAlign: 'center',
    padding: '1em 0',
    lineHeight: 1,
    width: drawerPaperCloseWidth,
    '& div': {
      padding: 0,
    },
    '&:hover': {
      background: 'inherit',
    },
  },
  listTextBlock: {
    fontSize: '.75em',
    display: 'block',
    textAlign: 'center',
    padding: `.875rem .5rem`,
    lineHeight: 1,
    width: '100%',
    '& svg': {
      marginBottom: theme.spacing.unit / 2,
      marginRight: 0,
    },
  },
  navContainer: {
    minHeight: '100%',
  },
  botNavContainer: {
    flex: '1 1 auto',
  },
  sidebarPersonPop: {
    zIndex: 10003,
  },
  label: {
    top: -1,
    right: 5,
    padding: '1px 4px !important',
  },
  listItemHelpCalls: {
    boxShadow:
      '0px 0px 1px rgba(17, 60, 92, 0.06), 0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.1)',
    border: `1px solid var(--grey-100)`,
    marginTop: `8px !important`,
    '&.sidebarPerson svg': {
      fill: 'var(--primary-600)',
      marginBottom: 0,
    },
  },
  itemsMTop: {
    marginTop: theme.spacing.unit * 8,
  },
});

export default styles;
