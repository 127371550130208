import React, { useContext, useEffect, createContext, useCallback } from 'react';

interface TestWidgetContextType {
  clearError: () => void;
  setHandler: () => void;
}

export const TestWidgetContext = createContext<TestWidgetContextType>({} as TestWidgetContextType);

export const useTestWidgetContext = () => useContext(TestWidgetContext);

export function withTestWidgetContext<Props extends Object>(WrappedComponent: React.ComponentType<Props>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithTestContext = (props: Omit<Props, keyof Object>) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const { setHandler, clearError } = useTestWidgetContext();

    const setChatWidgetHandler = useCallback(() => {
      setHandler();
      document.removeEventListener('justwidget_ready', setChatWidgetHandler);
    }, [setHandler]);

    // props comes afterwards so the can override the default ones.
    useEffect(() => {
      document.addEventListener('justwidget_ready', setChatWidgetHandler);
      return () => {
        document.removeEventListener('justwidget_ready', setChatWidgetHandler);
        clearError && clearError();
      };
    }, [clearError, setChatWidgetHandler]);
    return <WrappedComponent {...{ setHandler, clearError }} {...(props as Props)} />;
  };
  ComponentWithTestContext.displayName = `withTestWidgetContext(${displayName})`;

  return ComponentWithTestContext;
}
