import React, { FC, useCallback } from 'react';
import { Icon, IconProps, Button } from '@just-ai/just-ui';

import history from 'appHistory';
import classes from './wisard-start-card.module.scss';
import { t } from 'localization';
import { useAppSelector } from '../../../../../storeHooks';
import { GA } from '../../../../../pipes/pureFunctions';

export const WizardStartCard: FC<{ link: string; type: string }> = ({ children, link, type }) => {
  const { location } = useAppSelector(store => ({
    location: store.router.location,
  }));

  const onClick = useCallback(() => {
    history.push({ pathname: link, state: location.state });
    GA(undefined, 'Creation_Method_Select', undefined, type);
  }, [link, location.state, type]);

  return (
    <div className={classes.WizardStartCard} onClick={onClick} data-test-id={`WizardStartCard:${type}`}>
      {children}
    </div>
  );
};

export const WizardStartImage: FC = ({ children }) => {
  return <div className={classes.WizardStartImage}>{children}</div>;
};
export const WizardStartLabel: FC<{ icon?: IconProps['name']; background: string; text: string; id?: string }> = ({
  icon,
  background,
  text,
  id,
}) => {
  return (
    <div id={id} className={classes.WizardStartLabel} style={{ background: background }}>
      {icon && <Icon name={icon} size='sm' />}
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export const WizardStartCardBody: FC<{ title: string; list: string[] }> = ({ title, list }) => {
  return (
    <div className={classes.WizardStartCardBody}>
      <h2 className='m-0 text-center'>{title}</h2>
      <ul className={classes.WizardStartCardBodyUl}>
        {list.map(listItem => (
          <li key={listItem} className={classes.liItem}>
            <Icon name='farThumbsUp' color='primary' size='md' className={classes.liIcon} />
            {listItem}
          </li>
        ))}
      </ul>
      <Button color='primary' className='d-flex w-100 mt-2'>
        {t('Select')}
      </Button>
    </div>
  );
};
