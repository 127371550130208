const prefix = '@statistic/';

export const DROP_STATISTIC = prefix + 'DROP_STATISTIC';
export const LOAD_DUMMY_STATISTIC = prefix + 'LOAD_DUMMY_STATISTIC';
export const LOAD_DUMMY_STATISTIC_PENDING = prefix + 'LOAD_DUMMY_STATISTIC_PENDING';

export const STATISTIC_LOAD = prefix + 'STATISTIC_LOAD';
export const STATISTIC_LOAD_FULFILLED = prefix + 'STATISTIC_LOAD_FULFILLED';
export const STATISTIC_LOAD_PENDING = prefix + 'STATISTIC_LOAD_PENDING';
export const STATISTIC_LOAD_REJECTED = prefix + 'STATISTIC_LOAD_REJECTED';

export const UNIQ_CLIENTS_LOAD = prefix + 'UNIQ_CLIENTS_LOAD';
export const UNIQ_CLIENTS_LOAD_FULFILLED = prefix + 'UNIQ_CLIENTS_LOAD_FULFILLED';
export const UNIQ_CLIENTS_LOAD_PENDING = prefix + 'UNIQ_CLIENTS_LOAD_PENDING';
export const UNIQ_CLIENTS_LOAD_REJECTED = prefix + 'UNIQ_CLIENTS_LOAD_REJECTED';
