import React from 'react';
import localize from 'localization';
import { InputText, Textarea } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import classnames from 'classnames';

const { translate: t } = localize;

export default class YandexEditForm extends BaseEditForm {
  submit = async e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fetching: true });
    if (this.form.oauthToken.value.trim() === '') {
      this.setState({
        errors: [t('field OAuth-token is reqiured')],
        fetching: false,
      });
      return false;
    } else {
      this.setState({
        errors: [],
      });
    }
    if (Boolean(this.props.editableChannel.id)) {
      await this.saveChannel();
    } else {
      await this.createChannel();
    }
    this.setState({ fetching: false });
  };

  createChannel = async () => {
    const { createChannel } = this.props;

    const channelData = {
      ...this.props.editableChannel,
      botName: this.form.name.value,
      senderName: this.form.name.value,
      channelType: this.props.editableChannel.channelType,
      customData: {
        oauthToken: this.form.oauthToken.value,
      },
      project: {
        ...this.props.editableChannel.project,
      },
    };

    await createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async () => {
    const { editChannel } = this.props;

    const channelData = {
      botName: this.form.name.value,
      senderName: this.form.name.value,

      accessToken: this.props.editableChannel.accessToken,
      channelType: this.props.editableChannel.channelType,
      rollout: 'MANUAL',
      id: this.props.editableChannel.id,
      branch: 'master',
      customData: {
        oauthToken: this.form.oauthToken.value,
      },
      project: {
        ...this.props.editableChannel.project,
      },
    };

    await editChannel(channelData);
    this.props.onClose();
  };

  cancelEdit = () => {
    this.setState({
      errors: [],
    });
    this.props.onClose();
  };

  renderInputs() {
    const { editableChannel } = this.props;

    let oauthToken = editableChannel.customData;
    if (typeof oauthToken === 'string' && oauthToken.length > 0) {
      oauthToken = JSON.parse(oauthToken).oauthToken;
    } else {
      oauthToken = editableChannel.customData.oauthToken;
    }

    return (
      <>
        <div className={classes.formControl}>
          <p className={classnames(classes.label, classes.labelRequired)}>{t('OauthToken')}</p>
          <InputText
            innerRef={input => (this.form.oauthToken = input)}
            defaultValue={oauthToken}
            placeholder={t('Enter custom', t('OauthToken').toLowerCase())}
          />
          <a
            style={{ fontSize: '0.75rem' }}
            target='_blank'
            rel='noopener noreferrer'
            href='//oauth.yandex.ru/authorize?response_type=token&client_id=c473ca268cd749d3a8371351a8f2bcbd'
          >
            {t('Get OAuth-token')}
          </a>
        </div>
        {editableChannel?.id && (
          <div className={classes.formControl}>
            <p className={classes.label}>{t('Webhook')}</p>
            <Textarea disabled multiline defaultValue={editableChannel.webhookUrl} />
          </div>
        )}
      </>
    );
  }
}
