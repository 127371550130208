import React, { FC, useEffect, useState } from 'react';

import { LocalizationField, ITemplateWizardEditField } from '../../types';
import styles from './styles.module.scss';
import TextAreaLikeElement from '@just-ai/just-ui/dist/TextAreaLikeElement';

type InputTextProps = ITemplateWizardEditField<
  string,
  {
    title: LocalizationField;
    placeholder: LocalizationField;
    helperText?: LocalizationField;
    maxLength?: number;
  }
>;
const InputText: FC<InputTextProps> = ({ value, envName, onChange, info, isShowErrors, errors }) => {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    onChange(info.placeholder, envName, true);
  }, [envName, info.placeholder, onChange]);

  useEffect(() => {
    onChange(innerValue, envName, false);
  }, [envName, onChange, innerValue]);

  return (
    <div className={styles.InputText}>
      <div className={styles.InputText__title}>{info.title}</div>
      <div className={styles.InputText__inputWrapper}>
        <div className={styles.InputText__inputContainer}>
          <TextAreaLikeElement
            richValue={innerValue}
            value={innerValue}
            onChange={setInnerValue}
            placeholder={info.placeholder}
            invalid={isShowErrors && !!errors?.[envName]}
            clearable={!!innerValue}
            maxLength={info.maxLength}
            dataTestId={envName}
          />
        </div>
        <div className={styles.InputText__inputWrapper}>
          {isShowErrors && errors?.[envName] ? (
            <div className={styles.InputText__error} dangerouslySetInnerHTML={{ __html: errors?.[envName] }} />
          ) : (
            info.helperText && (
              <div className={styles.InputText__hint} dangerouslySetInnerHTML={{ __html: info.helperText }} />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default InputText;
