import React, { Component } from 'react';
import classNames from 'classnames';
import localize from '../../../localization';
import { IconButton, AtreidesIcon, Modal, Button } from 'altreidsds';
import { getDateOfPayment } from '../../../pipes/functions';
import Links from './Links';

import classes from './NewHeader.module.scss';
import mobileClasses from './MobileHeader.module.scss';
import TariffInfo from './TariffInfo';
import PersonalInfo from './PersonalInfo';
import { GA } from '../../../pipes/pureFunctions';
import history from '../../../appHistory';

const { translate: t } = localize;

class MobileHeader extends Component {
  state = {
    openLinks: false,
    openTariffInfo: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = prevProps.location;
    const { pathname: currentPathname } = this.props.location;
    if (pathname !== currentPathname) {
      this.setState({ openLinks: false, openTariffInfo: false });
    }
  }

  renderLinks() {
    const { openLinks } = this.state;
    const {
      appConfig,
      location: { pathname },
      logout,
      isOrderBotEnabled,
      ccSessionsAreEnabled,
    } = this.props;
    return (
      <Modal
        open={openLinks}
        className={mobileClasses.popoverContainer}
        classes={{
          modal: mobileClasses.modal,
          modalContent: classNames(mobileClasses.modalContent, mobileClasses.modalContentWithButton),
          modalIconClose: mobileClasses.modalIconClose,
        }}
        onClose={() => this.setState({ openLinks: false })}
      >
        <Links appConfig={appConfig} pathname={pathname} logout={logout} ccSessionsAreEnabled={ccSessionsAreEnabled} />
        {isOrderBotEnabled && (
          <Button
            onClick={() => {
              GA('GAEvent', 'order_bot', 'click');
              history.push('/botDevelopmentRequest');
            }}
            variant='outlined'
            color='primary'
            size='small'
          >
            {t('Request bot development')}
          </Button>
        )}
      </Modal>
    );
  }

  render() {
    const { currentUser, language, logout } = this.props;
    const { tariff } = currentUser || {};

    return (
      <div className={classNames(classes.container, classes.mobileContainer)}>
        <div className={classes.info} onClick={() => this.setState({ openTariffInfo: true })}>
          <IconButton classes={{ root: classes.gamburber }} onClick={() => this.setState({ openLinks: true })}>
            <AtreidesIcon name='gamburger' />
          </IconButton>
        </div>
        <TariffInfo
          tariff={tariff}
          isMobile
          paymentData={getDateOfPayment(
            tariff?.startDate,
            tariff?.manualControl ? tariff?.manualControlPeriodEndDate : tariff?.dueDate,
            tariff?.blocked,
            language,
            language === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'
          )}
          language={language}
        />
        <PersonalInfo
          avatar={currentUser?.avatar}
          email={currentUser?.email}
          logout={logout}
          isParner={currentUser?.account?.partner}
          language={language}
          isMobile
        />

        {this.renderLinks()}
      </div>
    );
  }
}

export default MobileHeader;
