import { t } from 'localization';
import { TemplateWizardField } from '../FieldBuilder/fields';

import { RealSkill, ScenarioSkillConfig, SkillType } from '../types';

export const serviceEvaluationConfig: () => ScenarioSkillConfig = () => ({
  type: SkillType.SCENARIO,
  withBaseInfo: true,
  withOperator: true,
  withMenu: true,
  orderWeight: 30,
  skillName: RealSkill.SERVICE_EVALUATION,
  title: t('ServiceEvaluationSkill:Title'),
  description: t('ServiceEvaluationSkill:Description'),
  shortDescription: t('ServiceEvaluationSkill:ShortDescription'),
  skillVersion: 1,
  language: '',
  startState: '',
  menuType: 'transition',
  fixedInMenu: true,
  fields: [
    {
      type: 'TextArea',
      envName: 'estimationSuggest',
      title: t('ServiceEvaluationSkill:Fields:EstimationSuggest:Title'),
      placeholder: t('ServiceEvaluationSkill:Fields:EstimationSuggest:Placeholder'),
      value: '',
    },
    {
      type: 'textMessage',
      title: t('ServiceEvaluationSkill:Fields:ReactionOnEstimation'),
    },
    {
      type: 'DataCollectionTable',
      envName: 'marks',
      title: '',
      allowNewRows: false,
      disableDrag: true,
      disableRemove: true,
      columns: [
        {
          envName: 'name',
          header: t('ServiceEvaluationSkill:Fields:Marks:Columns:Estimation'),
          type: 'select',
          disabled: true,
          selectOptions: [],
          selectMultiple: false,
          eachRowSelectUniqValue: true,
          arbitrarySelectOptions: false,
          placeholder: '',
        },
        {
          envName: 'value',
          maxLength: 500,
          header: t('ServiceEvaluationSkill:Fields:Marks:Columns:PhraseOfBot'),
          type: 'text',
          selectOptions: null,
          placeholder: [
            {
              condition: {
                name: '1, 2',
              },
              value: t('ServiceEvaluationSkill:Fields:Marks:Placeholder:LowMark'),
            },
            {
              condition: {
                name: '3, 4',
              },
              value: t('ServiceEvaluationSkill:Fields:Marks:Placeholder:RegularMark'),
            },
            {
              condition: {
                name: '5',
              },
              value: t('ServiceEvaluationSkill:Fields:Marks:Placeholder:HighMark'),
            },
          ],
        },
      ],
      addRowText: '',
      value: [
        {
          name: '1, 2',
          value: '',
        },
        {
          name: '3, 4',
          value: '',
        },
        {
          name: '5',
          value: '',
        },
      ],
    },
    {
      type: 'TextArea',
      envName: 'finishDialogText',
      title: t('ServiceEvaluationSkill:Fields:FinishDialogText:Title'),
      placeholder: t('ServiceEvaluationSkill:Fields:FinishDialogText:Placeholder'),
      value: '',
    },
    {
      type: 'textMessage',
      title: t('ServiceEvaluationSkill:Fields:ClientReject'),
    },
    {
      type: 'TextArea',
      envName: 'rejectText',
      title: t('ServiceEvaluationSkill:Fields:RejectOrderText:Title'),
      placeholder: t('ServiceEvaluationSkill:Fields:RejectOrderText:Placeholder'),
      value: '',
    },
    {
      type: 'textMessage',
      title: t('ServiceEvaluationSkill:Fields:SendInfoToManager:Title'),
      description: t('ServiceEvaluationSkill:Fields:SendInfoToManager:Description'),
    },
    {
      type: TemplateWizardField.MANAGER_NOTIFY,
      envName: 'sendPlaces',
    },
  ],
});
