import {
  ADD_GLOBAL_ALERT,
  ADD_SNACKBAR,
  DISMISS_ALL,
  DISMISS_SNACKBAR,
  REMOVE_GLOBAL_ALERT,
  CHANGE_SNACKBAR,
} from '../../constants/snackbars.actions';

const InitialState = {
  snackbars: [],
  globalAlert: null,
};

export default function SnackbarsReducer(state = InitialState, action) {
  switch (action.type) {
    case ADD_SNACKBAR: {
      const currentTimestamp = Date.now();

      const addedSnackbar = {
        ...action.snackbar,
        time: currentTimestamp,
        id: `snackbar_${currentTimestamp}`,
      };

      let newSnackbars = [addedSnackbar, ...state.snackbars];

      return {
        ...state,
        snackbars: newSnackbars,
      };
    }

    case CHANGE_SNACKBAR: {
      const newSnackbars = [...state.snackbars];

      const index = newSnackbars.findIndex(snackbar => {
        return snackbar.id === action.id;
      });

      newSnackbars[index] = {
        ...newSnackbars[index],
        ...action.snackbarData,
      };

      return {
        ...state,
        snackbars: newSnackbars,
      };
    }

    case DISMISS_SNACKBAR: {
      const newSnackbars = [...state.snackbars];
      const removedIndex = newSnackbars.findIndex(snackbar => {
        return snackbar.id === action.id;
      });
      newSnackbars.splice(removedIndex, 1);
      return {
        ...state,
        snackbars: newSnackbars,
      };
    }

    case DISMISS_ALL: {
      return {
        ...state,
        snackbars: InitialState.snackbars,
      };
    }

    case ADD_GLOBAL_ALERT: {
      return {
        ...state,
        globalAlert: action.globalAlert,
      };
    }

    case REMOVE_GLOBAL_ALERT: {
      return {
        ...state,
        globalAlert: InitialState.globalAlert,
      };
    }

    default: {
      return state;
    }
  }
}
