import diffInArrays from 'helpers/array/diffInArrays';

import { RealSkill, SkillConfig } from '../types';

import { orderSkillConfig } from '../skills/Order';
import { faqSkillConfig } from '../skills/FAQ';
import { faqWithChatGPTSkillConfig } from '../skills/FaqWithChatGPT';
import { baseInfoSkillConfig } from '../skills/BaseInfo';
import { collectingContactsConfig } from '../skills/CollectingContacts';
import { operatorSkillConfig } from '../skills/Operator';
import { serviceEvaluationConfig } from '../skills/ServiceEvaluation';
import { menuSkillConfig } from '../skills/Menu';
import { autoMessageConfig } from '../skills/AutoMessage';
import { BaseSkill } from './BaseSkill';
import { ProjectSkillRead } from '@just-ai/api/dist/generated/Editorbe';

const wizardSkillsMap: Record<RealSkill, () => SkillConfig> = {
  [RealSkill.FAQ]: faqSkillConfig,
  [RealSkill.FAQ_WITH_CHAT_GPT]: faqWithChatGPTSkillConfig,
  [RealSkill.ORDER]: orderSkillConfig,
  [RealSkill.COLLECTING_CONTACTS]: collectingContactsConfig,
  [RealSkill.SERVICE_EVALUATION]: serviceEvaluationConfig,
  [RealSkill.AUTOMESSAGE]: autoMessageConfig,
};

export const allFrontSkills = Object.keys(wizardSkillsMap).map(name => ({
  name,
  language: '',
  startState: '',
})) as ProjectSkillRead[];

export function getSkillConfig(skillName: string): SkillConfig | undefined {
  const skillConfigGetter = wizardSkillsMap[skillName as RealSkill];
  return skillConfigGetter?.();
}

export function skillBuilder(skillName: ProjectSkillRead): SkillConfig | undefined {
  const config = getSkillConfig(skillName.name as RealSkill);
  if (!config) return;
  config.startState = skillName.startState;
  return {
    ...config,
    language: skillName.language as 'ru' | 'en',
    startState: skillName.startState,
  };
}

function expandFromRegisteredSkills(
  skillConfig: SkillConfig,
  registeredSkillMap: Record<string, ProjectSkillRead>
): SkillConfig | undefined {
  const matchedRegisteredSkill = registeredSkillMap[skillConfig.skillName];
  if (!matchedRegisteredSkill) return;
  return { ...skillConfig, language: matchedRegisteredSkill.language, startState: matchedRegisteredSkill.startState };
}

export function buildSkillList(
  selectedSkills: ProjectSkillRead[],
  registeredSkills: ProjectSkillRead[]
): SkillConfig[] {
  const registeredSkillMap = registeredSkills.reduce((acc, skill) => {
    acc[skill.name] = skill;
    return acc;
  }, {} as Record<string, ProjectSkillRead>);

  const skills: SkillConfig[] = [];

  for (let realSkillName of selectedSkills) {
    const skillConfig = skillBuilder(realSkillName);
    if (!skillConfig) continue;
    skills.push(skillConfig);
  }

  const baseInfoSkill = expandFromRegisteredSkills(baseInfoSkillConfig(), registeredSkillMap);
  if (baseInfoSkill && skills.some(skill => skill.withBaseInfo)) skills.push(baseInfoSkill);
  const operatorSkill = expandFromRegisteredSkills(operatorSkillConfig(), registeredSkillMap);
  if (operatorSkill && skills.some(skill => skill.withOperator)) skills.push(operatorSkill);
  const menuSkill = expandFromRegisteredSkills(menuSkillConfig(), registeredSkillMap);
  if (menuSkill && skills.some(skill => skill.withMenu)) skills.push(menuSkill);

  return skills.sort((a, b) => a.orderWeight - b.orderWeight);
}

type SkillCard = { skillConfig: SkillConfig; enabled: boolean; canFix: boolean; denyCauses?: SkillConfig[] };

export function whichSkillCanBeAdded(
  currentSkillsName: RealSkill[],
  selectedSkills: RealSkill[],
  registeredSkills: ProjectSkillRead[]
): SkillCard[] {
  const currentSkillsConfigs = currentSkillsName.map(getSkillConfig).filter(Boolean) as SkillConfig[];
  const selectedSkillsConfigs = selectedSkills.map(getSkillConfig).filter(Boolean) as SkillConfig[];

  const skillCards = registeredSkills
    .filter(registeredSkill => {
      const skillConfig = skillBuilder(registeredSkill);
      if (!skillConfig) return false;
      const skillAlreadyAdded = currentSkillsConfigs.find(
        currentSkill => currentSkill.skillName === registeredSkill.name
      );
      return !skillAlreadyAdded;
    })
    .map(registeredSkill => {
      const skillConfig = skillBuilder(registeredSkill);
      let result = { skillConfig, enabled: true, canFix: true } as SkillCard;
      let denySkills: SkillConfig[] = [];

      if (selectedSkillsConfigs.some(selectedSkill => selectedSkill.skillName === registeredSkill.name)) return result;

      if (skillConfig?.denySkills === '*' && (currentSkillsConfigs.length > 0 || selectedSkillsConfigs.length > 0)) {
        result.denyCauses = selectedSkillsConfigs.concat(currentSkillsConfigs);
        result.enabled = false;
        return result;
      }
      denySkills = [...currentSkillsConfigs, ...selectedSkillsConfigs].filter(skill =>
        skillConfig!.denySkills?.includes(skill.skillName as RealSkill)
      );
      if (Array.isArray(skillConfig?.denySkills) && denySkills.length > 0) {
        result.denyCauses = denySkills;
        result.enabled = false;
        return result;
      }

      denySkills = [...currentSkillsConfigs, ...selectedSkillsConfigs].filter(currentSkill => {
        if (!currentSkill.denySkills) return false;
        if (currentSkill.denySkills === '*') return true;
        return currentSkill.denySkills.includes(registeredSkill.name as RealSkill);
      });
      if (denySkills.length > 0) {
        result.denyCauses = denySkills;
        result.enabled = false;
        return result;
      }
      return result;
    });
  return skillCards
    .map(
      skillCard =>
        ({
          ...skillCard,
          canFix: !skillCard.denyCauses?.some(skill => currentSkillsName.includes(skill.skillName as RealSkill)),
        } as SkillCard)
    )
    .sort((a, b) => a.skillConfig.orderWeight - b.skillConfig.orderWeight);
}

export function whichSkillWillBeDeletedWithCurrent(
  currentSkills: BaseSkill[],
  skillName: RealSkill | string,
  registeredSkills: ProjectSkillRead[]
): SkillConfig[] {
  const currentSkillsConfigs = currentSkills
    .map(skill => skill.skillConfig.skillName)
    .filter(name => skillName !== name && getSkillConfig(name));
  const newSkillsConfigs = buildSkillList(
    currentSkillsConfigs.map(name => ({
      name,
      language: '',
      startState: '',
    })),
    registeredSkills
  );

  const { toDelete } = diffInArrays(
    newSkillsConfigs,
    currentSkills.map(el => el.skillConfig),
    (a, b) => a.skillName === b.skillName,
    () => true
  );

  return toDelete;
}
