import React from 'react';
import localize from 'localization';
import { InputText } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import Groups from '../Groups';
import { axios } from '../../../../../pipes/functions';
import forEach from 'lodash/forEach';

const { translate: t } = localize;

export default class LivetexEditForm extends BaseEditForm {
  constructor(props) {
    super(props);
    this.form = {};

    this.defaultFields = {};

    this.state = {
      hasError: false,
      wasChanged: false,
      errors: [],
      destinations: [],
    };

    this.destinationsValues = [];
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.editableChannel?.config?.apiKey) {
        this.destinationsValues = this.props.editableChannel?.config?.destinations || [];
        this.getDestinations();
      }
    }, 0);
  }

  submit = async e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ fetching: true });

    if (this.form.apiKey.value.length > 0) {
      if (Boolean(this.props.editableChannel.id)) {
        await this.saveChannel();
      } else {
        await this.createChannel();
      }
    } else {
      this.setState({
        hasError: true,
      });
    }
    this.setState({ fetching: false });
  };

  createChannel = async () => {
    const { createChannel } = this.props;

    const channelData = {
      type: this.props.editableChannel.channelType,
      description: this.form.description.value,
      config: {
        apiKey: this.form.apiKey.value,
        destinations: this.destinationsValues,
      },
    };

    let errorFlag = false;
    if (channelData?.config?.destinations[0]) {
      forEach(channelData.config.destinations, destination => {
        if (!destination.ids || destination.ids.length === 0) {
          errorFlag = true;
        }
      });
    }

    if (errorFlag) return this.setState({ hasErrors: true });

    await createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async () => {
    const { editChannel } = this.props;

    const channelData = {
      ...this.props.editableChannel,
      config: {
        apiKey: this.form.apiKey.value,
        destinations: this.destinationsValues,
      },
    };

    let errorFlag = false;
    if (channelData?.config?.destinations[0]) {
      forEach(channelData.config.destinations, destination => {
        if (!destination.ids || destination.ids.length === 0) {
          errorFlag = true;
        }
      });
    }

    if (errorFlag) return this.setState({ errors: [t('Check fields value')] });

    await editChannel(channelData);
    this.props.onClose();
  };

  validate = value => {
    const { hasError, wasChanged } = this.state;

    if (!wasChanged) {
      this.setState({ wasChanged: true });
    }

    if (value.length === 0 && !hasError) {
      this.setState({ hasError: true });
    } else if (hasError) {
      this.setState({ hasError: false });
    }
  };

  checkIsFetching = () => {
    const { fetching } = this.props;
    const { hasError, fetching: localFetching } = this.state;
    return fetching || hasError || localFetching;
  };

  addDestination = () => this.props.addDestination();

  changeDestination = (key, property, value) => {
    if (!this.destinationsValues[key]) this.destinationsValues[key] = {};

    this.destinationsValues[key][property] = value;
  };

  addIds = (key, ids) => {
    if (!this.destinationsValues[key]) this.destinationsValues[key] = {};
    this.destinationsValues[key].ids = ids;
  };
  getDestinations = async () => {
    if (this.form.apiKey.value !== '') {
      const data = await axios.get(`/restapi/channels/livetex/getDestinations?key=${this.form.apiKey.value}`);
      if (data.data.length > 0) {
        this.setState({ destinations: data.data.filter(x => x) });
      }
    }
  };

  removeGroup = k => {
    this.props.removeGroup(k);
    this.destinationsValues.splice(k, 1);
  };

  renderFooter = () => {
    const { destinations } = this.state;
    return (
      <Groups
        editableChannel={this.props.editableChannel}
        destinations={destinations}
        removeGroup={this.removeGroup}
        changeDestination={this.changeDestination}
        addIds={this.addIds}
        addDestination={this.addDestination}
        selectIDS={this.props.selectIDS}
      />
    );
  };

  renderInputs() {
    const { editableChannel } = this.props;
    const { hasError } = this.state;

    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Name')}</p>
          <InputText
            innerRef={input => (this.form.description = input)}
            id='description'
            defaultValue={
              editableChannel.id ? editableChannel.description : t(`AddChannelCard ${editableChannel.channelType}`)
            }
            placeholder={t('Enter custom', t('Name').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Access key')}</p>
          <InputText
            id='access_key'
            errorText={hasError}
            defaultValue={editableChannel?.config?.apiKey}
            onBlur={this.getDestinations}
            innerRef={input => (this.form.apiKey = input)}
            onChange={value => this.validate(value)}
            placeholder={t('Enter custom', t('Access key').toLowerCase())}
          />
        </div>
      </>
    );
  }
}
