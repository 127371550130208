import { BASE_WIDGET_OPTIONS, SETTINGS_KEYS } from '../../../../ChatWidgetEditPage/chatWidgetEditConsts';

import BaseEditForm from '../BaseEditForm';
import { isEuroInstance } from '../../../../../isAccessFunction';

export default class ChatWidgetEditForm extends BaseEditForm {
  createChannel = async () => {
    const { createChannel, onClose, appConfig } = this.props;
    const channelData = {
      accessToken: '',
      botName: this.form.name.value,
      branch: 'master',
      channelType: 'CHAT_WIDGET',
      percentage: 100,
      position: 'right',
      project: { defaultBranch: 'master', contentDirectory: '.' },
      rollout: 'MANUAL',
      senderName: this.form.name.value,
      theme: 'defaultv3',
      widgetOptions: SETTINGS_KEYS.reduce((result, key) => {
        result[key] = BASE_WIDGET_OPTIONS[key];
        return result;
      }, {}),
    };
    channelData.widgetOptions.hidePoweredByJustAI = Boolean(appConfig.zenflow?.hideChatwidgetPoweredLabel);
    if (isEuroInstance()) {
      channelData.widgetOptions.poweredLabel = 'Tovie DialogStudio';
      channelData.widgetOptions.poweredLink = 'https://dialogstudio.tovie.ai/';
    }
    await createChannel(channelData);
    onClose();
  };
}
