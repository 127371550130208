import React, { useState } from 'react';
import { ActionConfirmModal, Button } from 'altreidsds';
import { Card, CardContent, CardMedia } from 'altreidsds';
import localize from 'localization';
import history from 'appHistory';
import classes from './Templates.module.scss';

const { translate: t } = localize;

export default function SimpleMediaCard({
  thumbnail,
  title,
  summary,
  name,
  onClick,
  isIVR,
  botWithIVR,
  skillMaster,
  parsedJson,
}) {
  const [confirmModalOpenState, setConfirmModalState] = useState(false);
  let text = summary;
  if (text.length >= 90) {
    text = text.substring(0, 87) + '...';
  }

  const closeConfirmModal = e => {
    e.stopPropagation();
    setConfirmModalState(false);
  };
  const openConfirmModal = () => setConfirmModalState(true);

  let handler = isIVR && botWithIVR ? openConfirmModal : () => onClick(name, title);

  const { withWizard } = parsedJson;

  if (skillMaster && withWizard) handler = undefined;

  const chooseTariff = () => {
    history.push(`/prices`);
  };

  return (
    <div onClick={handler}>
      <Card classes={{ root: classes.templateCard }}>
        {skillMaster && withWizard && (
          <div className={classes.blocked}>
            <div>
              <h3>{t('Tariff $[1]', 'Skill Master')}</h3>
              <span>{t('$[1] is not available at this tariff', 'Wizard')}</span>
              <Button
                data-test-id='TemplateCard.chooseAnotherTariffButton'
                variant='contained'
                color='primary'
                className={classes.cardTariffButton}
                onClick={chooseTariff}
              >
                {t('Choose another tariff')}
              </Button>
            </div>
          </div>
        )}
        <CardMedia
          className={classes.media}
          image={Boolean(thumbnail) ? thumbnail : '/img/No_Image_Available.png'}
          title={title}
        />
        {isIVR && <span className={classes.newLabel}>NEW</span>}
        <CardContent>
          <h3 style={{ marginBottom: '1rem' }} className={classes.cardHeadline}>
            {title}
          </h3>
          <p>{text}</p>
        </CardContent>
        <ActionConfirmModal
          title={t('VoiceChannels forceUpdateModal title')}
          open={confirmModalOpenState}
          onClose={closeConfirmModal}
          onCancelClick={closeConfirmModal}
          cancelText={t('Cancel')}
          onOkClick={() => onClick(name, title)}
          okText={t(isIVR ? 'Continue' : 'VoiceChannels forceUpdateModal btn')}
        >
          <p>{t('VoiceChannels forceUpdateModal text only one channel')}</p>
          <p style={{ fontWeight: 'bold' }}>{botWithIVR}</p>
          <p>{t('VoiceChannels forceUpdateModal text force update')}</p>
        </ActionConfirmModal>
      </Card>
    </div>
  );
}
