import * as TemplatesActions from '../../constants/templates.actions';
import { InitialState } from './teplatesReducerTypes';

export default function BotProjectsReducer(state = InitialState, action) {
  switch (action.type) {
    case TemplatesActions.FETCH_TEMPLATES_FULFILLED: {
      const templatesList = action.payload.data;
      templatesList.forEach(template => {
        try {
          template.parsedJson = JSON.parse(template.json);
        } catch (e) {
          console.error(`!!!!!!!!!!!!!!!!!!! NOT A JSON in ${template.name} !!!!!!!!!!!!!!!!!!!`);
        }
      });
      return {
        ...state,
        templatesList,
        templatesListFetching: false,
        fetched: true,
      };
    }
    case TemplatesActions.FETCH_TEMPLATES_PENDING: {
      return {
        ...state,
        templatesListFetching: true,
        fetched: false,
      };
    }
    case TemplatesActions.FETCH_TEMPLATES_REJECTED: {
      return {
        ...state,
        templatesListFetching: false,
        fetched: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
