import React from 'react';
import PropTypes from 'prop-types';
import localize from 'localization';
import classNames from 'classnames';
import baseClasses from '../BaseEditForm/BaseEditForm.module.scss';
import BaseEditForm from '../BaseEditForm';
import { InputText, SwitchButton, SimpleSelect, ImgUpload } from '@just-ai/just-ui';

const { translate: t } = localize;

class Bitrix24EditForm extends BaseEditForm {
  constructor(props) {
    super(props);
    const { customData } = props.editableChannel || {};

    this.form = {
      botAvatarFile: {
        files: [],
      },
    };

    this.defaultLabel = 'AddChannelCard BITRIX incoming label';
    const hasIdAndCustomData = props.editableChannel?.id && customData;

    this.state = {
      botAvatar: hasIdAndCustomData ? customData.botAvatar : '',
      isOpenLines: hasIdAndCustomData && customData.hasOwnProperty('isOpenLines') ? customData.isOpenLines : true,
      resetSession: hasIdAndCustomData && customData.hasOwnProperty('resetSession') ? customData.resetSession : false,
      rollout: true,
      logoFileError: '',
      fetching: false,
      errors: [],
      loadingImgType: 'localImg',
      botNameError: false,
      channelNameError: false,
    };
  }

  getChannelData = () => {
    const { isOpenLines, resetSession } = this.state;

    const channelData = {
      rollout: 'MANUAL',
      branch: 'master',
      botName: this.form.name.value,
      senderName: this.form.name.value,
      accessToken: this.props.editableChannel.accessToken,
      channelType: this.props.editableChannel.channelType,
      project: {
        ...this.props.editableChannel.project,
      },
      customData: {
        botName: this.form.botName.value,
        botAvatar: this.state.botAvatar,
        isOpenLines: isOpenLines,
        resetSession: resetSession,
        botType: 'B',
      },
    };

    if (Boolean(this.props.editableChannel.id)) {
      channelData.id = this.props.editableChannel.id;
    }

    return channelData;
  };

  submit = async e => {
    e.preventDefault();
    e.stopPropagation();
    const isEdit = Boolean(this.props.editableChannel.id);
    const channelData = this.getChannelData();
    if (!channelData.customData.botName.trim()) {
      this.setState({ botNameError: true, errors: [t('Bot name is not specified')] });
      return;
    } else {
      this.setState({ botNameError: false, errors: [] });
    }

    if (!channelData.botName.trim()) {
      this.setState({ channelNameError: true, errors: [t('Channel name is not specified')] });
      return;
    } else {
      this.setState({ channelNameError: false, errors: [] });
    }

    this.setState({ fetching: true });

    const submitAction = isEdit ? this.saveChannel : this.createChannel;
    const avatarFile = this.form.botAvatarFile.files[0];

    if (Boolean(avatarFile)) {
      const { addSnackbar } = this.props;
      try {
        const url = await this.loadFile(avatarFile);
        channelData.customData.botAvatar = url;
      } catch (e) {
        const { error } = e.response.data;
        console.error(e);
        addSnackbar(error ? t('Error loading file') + ': ' + error : t('Error loading file'));
        this.setState({ fetching: false });
        return;
      }
    }
    await submitAction(channelData);
    this.setState({ fetching: false });
  };

  createChannel = async channelData => {
    const { createChannel } = this.props;

    await createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async channelData => {
    const { editChannel } = this.props;

    await editChannel(channelData);
    this.props.onClose();
  };

  toggleIsOpenLines = value => this.setState({ isOpenLines: value });

  toggleResetSession = value => this.setState({ resetSession: value });

  loadFile = async file => this.props.saveUrlImageToServer(file).then(({ value }) => value.data);

  fileReader = file => {
    const reader = new FileReader();
    reader.onload = () =>
      this.setState({
        botAvatar: reader.result,
        logoFileError: '',
      });
    reader.readAsDataURL(file);
  };

  setAvatar = file => {
    this.form.botAvatarFile.files.push(file);
    const avatar = this.form.botAvatarFile.files[0];

    if (avatar?.type?.match('image.*')) {
      this.setState({ botAvatar: '', logoFileError: '' });
      this.fileReader(avatar);
    } else {
      this.setState({ logoFileError: t('Incorrect image file') });
    }
  };

  setAvatarLink = value => {
    const url = value;
    if (url !== '') {
      if (/\.(gif|jpg|jpeg|tiff|png)$/i.test(url)) {
        this.setState({
          botAvatar: url,
          logoUrlError: '',
        });
      } else {
        this.setState({
          logoUrlError: t('Incorrect image URL'),
        });
      }
    } else {
      this.setState({
        logoUrlError: '',
      });
    }
  };

  setLoadingImgType = value => this.setState({ loadingImgType: value });

  renderInputs() {
    const { classes, editableChannel, currentBot } = this.props;
    const { botAvatar, logoFileError, isOpenLines, resetSession, loadingImgType } = this.state;

    return (
      <>
        <input type='hidden' ref={input => (this.form.botToken = input)} defaultValue='' />
        <div className={baseClasses.formControl}>
          <p className={baseClasses.label}>{t('Bitrix24 bot name')}</p>
          <InputText
            placeholder={t('Enter custom', t('Bitrix24 bot name').toLowerCase())}
            innerRef={input => (this.form.botName = input)}
            id='botName'
            defaultValue={editableChannel.customData.botName || currentBot.name}
            errorText={this.state.botNameError}
          />
        </div>

        <div className={baseClasses.formControl}>
          <p className={baseClasses.label}>{t('Bot avatar')}</p>

          <SimpleSelect
            options={[
              { value: 'localImg', label: t('ChannelsAdding:LoadFile') },
              { value: 'urlImg', label: t('ChannelsAdding:LoadFromLink') },
            ]}
            defaultValue={loadingImgType}
            value={loadingImgType}
            onChange={this.setLoadingImgType}
          />
        </div>

        {loadingImgType === 'localImg' && (
          <div className={baseClasses.bitrixImgUploadContainer}>
            <ImgUpload onChange={this.setAvatar} imgUrl={botAvatar} />
            {logoFileError ? <div className={classes.error}>{logoFileError}</div> : null}
          </div>
        )}

        {loadingImgType === 'urlImg' && (
          <div className={baseClasses.formControl}>
            <InputText
              errorText={Boolean(this.state.logoUrlError)}
              id='botAvatar'
              onChange={this.setAvatarLink}
              innerRef={input => (this.form.botAvatar = input)}
              defaultValue={editableChannel.customData.botAvatar}
              placeholder={t('Placeholder for input link')}
            />
            {this.state.logoUrlError && (
              <small>{`${this.state.logoUrlError} ${t(
                'The link to the image must end with .gif, .jpg, .jpeg, .tiff, or .png'
              )}`}</small>
            )}
          </div>
        )}

        <div className={classNames(baseClasses.formControl, baseClasses.switchContainer)}>
          <SwitchButton
            id='isOpenLines'
            value={isOpenLines}
            color='primary'
            onChange={this.toggleIsOpenLines}
            disabled={Boolean(editableChannel.id)}
          />
          <label htmlFor='isOpenLines'>{t('Is open lines')}</label>
        </div>

        <div className={classNames(baseClasses.formControl, baseClasses.switchContainer)}>
          <SwitchButton id='resetSession' value={resetSession} color='primary' onChange={this.toggleResetSession} />
          <label htmlFor='resetSession'>{t('Send a message about adding to the dialogue')}</label>
        </div>
      </>
    );
  }
}

Bitrix24EditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Bitrix24EditForm;
