import React, { ReactText, useCallback } from 'react';
import { Button, InputGroup, InputText, IconButton } from '@just-ai/just-ui';
import localize from '../../../localization';
import classes from './BotsControls.module.scss';
import { JustSelect } from '@just-ai/just-ui/';
import { TagsFilter } from './TagsFilter';

const { translate: t } = localize;

export enum OrderTypes {
  'lastModified_desc' = 'lastModified_desc',
  'alphabet_desc' = 'alphabet_desc',
  'new_desc' = 'new_desc',
  'old_desc' = 'old_desc',
}

const OrderOptions = () => [
  {
    label: t('BotControls:orderOptions:lastModified'),
    value: OrderTypes['lastModified_desc'],
  },
  {
    label: t('BotControls:orderOptions:alphabet'),
    value: OrderTypes['alphabet_desc'],
  },
  {
    label: t('BotControls:orderOptions:new_desc'),
    value: OrderTypes['new_desc'],
  },
  {
    label: t('BotControls:orderOptions:old_desc'),
    value: OrderTypes['old_desc'],
  },
];

interface BotsControlsInterface {
  showCreateModal: () => void;
  filterBots: (filter: string) => void;
  displayType: 'card' | 'list';
  setDisplayType: (type: 'card' | 'list') => void;
  setOrder: (type: OrderTypes) => void;
  setFilterTags: (type: (string | number)[] | null) => unknown;
  order: keyof typeof OrderTypes;
}
function BotsControls({
  showCreateModal,
  filterBots,
  displayType,
  setDisplayType,
  order,
  setOrder,
  setFilterTags,
}: BotsControlsInterface) {
  const setOrderHandler = useCallback(
    (value: ReactText[] | null) => {
      const singleValue = (value && (value[0] as OrderTypes)) || OrderTypes.lastModified_desc;
      setOrder(singleValue);
    },
    [setOrder]
  );

  const filterBotsHandler = (value: string) => {
    filterBots(value);
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.createButton}
        onClick={showCreateModal}
        color='primary'
        data-test-id='Dashboard.CreateBotButton'
      >
        {t('Dashboard: createButton label')}
      </Button>
      <InputGroup className={classes.searchInput} PrependInner={[{ name: 'faSearch' }]}>
        <InputText
          bsSize='default'
          placeholder={t('Dashboard: searchByName input label')}
          onChange={filterBotsHandler}
          clearable
        />
      </InputGroup>
      <div className={classes.tagsFilter}>
        <TagsFilter onSelect={setFilterTags} />
      </div>
      <div className={classes.selectOrder}>
        <JustSelect
          options={OrderOptions()}
          onChange={setOrderHandler}
          value={order}
          fullWidth
          messages={{
            NOT_FOUND: t('BotControls:not_found'),
            EMPTY: '',
          }}
        />
      </div>
      <div className={classes.displayControl}>
        <IconButton
          //@ts-ignore
          className={displayType === 'card' && classes.active}
          color={displayType === 'card' ? 'primary' : 'none'}
          onClick={() => setDisplayType('card')}
          flat
          name='farTh'
        />
        <IconButton
          //@ts-ignore
          className={displayType === 'list' && classes.active}
          color={displayType === 'list' ? 'primary' : 'none'}
          onClick={() => setDisplayType('list')}
          flat
          name='farListUl'
        />
      </div>
    </div>
  );
}

export default React.memo(BotsControls);
