import {
  FETCH_CHANNELS,
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  REMOVE_CHANNEL,
  DROP_CHANNEL,
  SET_CHANNEL_TO_EDIT,
  SET_CHANNEL_TO_CREATION,
  SAVE_EDITABLE_CHANNEL,
  FETCH_WEBIM_WEBHOOK,
} from '../../constants/operatorChannels.actions';

import { axios } from '../../pipes/functions';

export const fetchChannels = projectShortName => {
  return {
    type: FETCH_CHANNELS,
    payload: axios.get(`/restapi/botconfig/project/${projectShortName}/livechat`),
  };
};

export const createChannel = (currentProjectShortName, channelData) => {
  return {
    type: CREATE_CHANNEL,
    payload: axios.post(`/restapi/botconfig/${currentProjectShortName}/livechat`, channelData),
  };
};

export const removeChannel = channelId => {
  return {
    type: REMOVE_CHANNEL,
    payload: axios.delete(`/restapi/botconfig/livechat/${channelId}`),
  };
};

export const saveEditableChannel = (id, channelData) => {
  return {
    type: SAVE_EDITABLE_CHANNEL,
    payload: axios({
      method: 'put',
      url: `/restapi/botconfig/livechat/${id}`,
      data: channelData,
    }),
  };
};

export const updateChannel = channelData => {
  return {
    type: UPDATE_CHANNEL,
    channelData: channelData,
  };
};

export const setChannelToCreation = channelType => {
  return {
    type: SET_CHANNEL_TO_CREATION,
    channelType: channelType,
  };
};

export const setChannelToEdit = channel => {
  return {
    type: SET_CHANNEL_TO_EDIT,
    channel,
  };
};

export const dropEditableChannel = () => {
  return {
    type: DROP_CHANNEL,
  };
};

export const fetchWebimWebhook = (channelId, liveChatId) => {
  return {
    type: FETCH_WEBIM_WEBHOOK,
    payload: axios.get(`/restapi/botconfig/webim/${liveChatId}/url`, { channelId: channelId, liveChatId: liveChatId }),
  };
};
