import createBrowserHistory from 'history/createBrowserHistory';
import localize from './localization';

const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    const unsaved_data_event = new Event('has_unsaved_data');
    window.hasUnsavedCallback = callback;
    window.dispatchEvent(unsaved_data_event);
    // Show some custom dialog to the user and call
    // callback(true) to continue the transiton, or
    // callback(false) to abort it.
  },
});

history.block((location, action) => {
  // The location and action arguments indicate the location
  // we're transitioning to and how we're getting there.

  // A common use case is to prevent the user from leaving the
  // page if there's a form they haven't submitted yet.
  let oldPath = history.location.pathname.split('/');
  let newPath = location.pathname.split('/');

  if (window.hasUnsaved && history.location.pathname.match(/scenario/g) && oldPath[1] !== newPath[1]) {
    return localize.translate('Scenario is not saved. You will lost the changes if you leave the page.');
  }
});

history.listen(location => {
  // if (location.pathname.includes('404')) debugger;
});

export default history;
