import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { commonLocalization } from './localization/decomposed/common.loc';

import FAQ from '@just-ai/nlu-modules/dist/views/FAQTreePage';

import isAccess, { isLoggedIn, isIncluded } from './isAccessFunction';
import history from './appHistory';

// Containers
import Full from './containers/Full/';

// Views
import page404 from './components/page404';
import page503 from './components/page503';
import page403 from './components/page403';

import NluModuleWrapper from './components/NLUModuleWrapper';

import { Spinner } from './components';
import DashboardWrapper from './views/Dashboard';
import Logout from './views/Pages/Logout';
import { WizardRouter } from './modules/TemplatesWizard/router';
import { BASE_WIZARD_PATH } from './modules/TemplatesWizard/shared/consts';
import { OAuthProvider } from '@just-ai/just-ui';
import MemoizedFAQTemplate from '@just-ai/nlu-modules/dist/views/FAQTemplatePage';

const ChannelSettings = lazy(() => import('./views/Settings'));
const EditorSelector = lazy(() => import('./views/EditorSelector'));
const Statistics = lazy(() => import('./views/Statistics'));
const BotSettings = lazy(() => import('./views/BotSettings'));
const PersonalSettings = lazy(() => import('./views/PersonalSettings'));
const Entities = lazy(() => import('./views/Entities'));
const Dialogs = lazy(() => import('./views/Dialogs'));
const LoginWithAmazon = lazy(() => import('./views/LoginWithAmazon'));
const EntitiesDetail = lazy(() => import('./views/Entities/EntitiesDetail'));
const Knowledge = lazy(() => import('./views/Knowledge'));
const KnowledgeBase = lazy(() => import('./views/Knowledge/Base'));
const Subscription = lazy(() => import('./views/Subscription'));
const ContractRequest = lazy(() => import('./views/ContractRequest'));
const Prices = lazy(() => import('./views/Prices'));
const UniversalWizard = lazy(() => import('./views/TemplateWizard/UniversalWizard'));
const CreateBotRedirect = lazy(() => import('./views/CreateBotRedirect'));
const BotDevelopmentRequest = lazy(() => import('./views/BotDevelopmentRequest/BotDevelopmentRequest'));
const StartWizard = lazy(() => import('./views/StartWizard'));
const ChatWidgetEditPage = lazy(() => import('views/ChatWidgetEditPage'));
const PartnerPage = lazy(() => import('views/PartnerPage'));
const CallTaskPage = lazy(() => import('./views/CallTasks/CallTaskPage'));
const PostSetup = lazy(() => import('./modules/TemplatesWizard/router/post-setup'));
const BotDashboard = lazy(() => import('./views/BotDashboard'));
const TextCampaigns = lazy(() => import('./components/TextCampaigns'));

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!isLoggedIn()) {
        return true;
      } else if (
        isAccess([rest.access]) &&
        (!Boolean(rest.appConfigIncludes) || (Boolean(rest.appConfigIncludes) && isIncluded(rest.appConfigIncludes)))
      ) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/403',
              state: { from: props.location },
            }}
          />
        );
      }
    }}
  />
);

export default (
  <ConnectedRouter history={history}>
    <Router history={history}>
      <OAuthProvider>
        <div>
          <Switch>
            <Route path='/logout' exact name='Logout Page' component={Logout} />
            <Full path='/'>
              <Suspense fallback={<Spinner fullHeight />}>
                <Switch>
                  <PrivateRoute access='ALL' path='/' exact name='Templates' component={DashboardWrapper} />
                  <PrivateRoute
                    access='ALL'
                    path={BASE_WIZARD_PATH + '*'}
                    exact
                    name='Wizard'
                    component={WizardRouter}
                  />
                  <PrivateRoute access='ALL' path='/skills-post-setup/:projectShortName' exact component={PostSetup} />
                  <PrivateRoute access='ALL' path='/dashboard' exact name='Dashboard' component={DashboardWrapper} />
                  <PrivateRoute access='ALL' path='/personal' exact name='personal' component={PersonalSettings} />
                  <PrivateRoute access='ALL' path='/partner' exact name='partner' component={PartnerPage} />
                  <PrivateRoute
                    access='ALL'
                    path='/:projectShortName/channels'
                    exact
                    name='Settings'
                    component={ChannelSettings}
                  />
                  <PrivateRoute
                    access='ALL'
                    appConfigIncludes={['zenflow_billing_feature']}
                    path='/prices'
                    exact
                    name='prices'
                    component={Prices}
                  />
                  <PrivateRoute
                    access='ALL'
                    appConfigIncludes={['zenflow_billing_feature']}
                    path='/subscription/:tariffUniqueName'
                    name='subscription'
                    component={Subscription}
                  />

                  <PrivateRoute access='ALL' path='/contract' name='Contract request' component={ContractRequest} />

                  <PrivateRoute
                    access='ALL'
                    appConfigIncludes={['zenflow_telephony_feature']}
                    path='/callsbots'
                    exact
                    name='Settings'
                    component={DashboardWrapper}
                  />
                  <PrivateRoute
                    access='ALL'
                    path='/loginWithAmazon'
                    exact
                    name='loginWithAmazon'
                    component={LoginWithAmazon}
                  />
                  <PrivateRoute
                    access='ALL'
                    path='/createbotredirect'
                    exact
                    name='CreateBotRedirect'
                    component={CreateBotRedirect}
                  />
                  <PrivateRoute
                    access='ALL'
                    path='/botDevelopmentRequest'
                    exact
                    name='botDevelopmentRequest'
                    component={BotDevelopmentRequest}
                  />

                  <Route path='/404' exact name='404' component={page404} />

                  <Route path='/403' exact name='403' component={page403} />

                  <Route path='/503' exact name='503' component={page503} />

                  <Route
                    access='ALL'
                    path='/:projectShortName'
                    exact
                    render={params => {
                      return <Redirect to={`/scenario/${params.match.params.projectShortName}`} />;
                    }}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/settings/:projectShortName'
                    exact
                    name='Settings'
                    component={BotSettings}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/scenario/:projectShortName'
                    exact
                    name='Scenario'
                    component={EditorSelector} //ScenarioCreate
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/statistics/:projectShortName'
                    exact
                    name='Statistics'
                    component={Statistics}
                  />

                  <PrivateRoute
                    access='knowledge_base'
                    exact
                    path='/faq-template/:projectShortName'
                    name='Creation FAQ page'
                    component={() => {
                      return (
                        <NluModuleWrapper>
                          <MemoizedFAQTemplate />
                        </NluModuleWrapper>
                      );
                    }}
                  />

                  <PrivateRoute
                    access='knowledge_base'
                    exact
                    path='/faq/:projectShortName'
                    name='FAQ page'
                    component={() => {
                      return (
                        <NluModuleWrapper>
                          <FAQ
                            localizationObject={{
                              'AnswerModal:info_file': { eng: ' ', ru: ' ', pt: ' ' },
                              'AnswerBlocks:answer_tooltip': commonLocalization['AnswerBlocks:answer_tooltip_replace'],
                            }}
                          />
                        </NluModuleWrapper>
                      );
                    }}
                  />

                  <PrivateRoute
                    access='ALL'
                    appConfigIncludes={['zenflow_dialogs_feature']}
                    path='/dialogs/:projectShortName'
                    exact
                    name='Dialogs'
                    component={Dialogs}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/entities/:projectShortName'
                    exact
                    name='Entities'
                    component={Entities}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/entities/:projectShortName/:entityName'
                    exact
                    name='EntitiesDetail'
                    component={EntitiesDetail}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/knowledge/:projectShortName/:baseShortName'
                    exact
                    name='Statistics'
                    component={KnowledgeBase}
                  />
                  <PrivateRoute
                    access='ALL'
                    path='/knowledge/:projectShortName'
                    exact
                    name='Statistics'
                    component={Knowledge}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/channels/:projectShortName'
                    exact
                    name='Settings'
                    component={ChannelSettings}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/chatwidgetEditor/:projectShortName/:channelId'
                    exact
                    name='EditorChatWidget'
                    component={ChatWidgetEditPage}
                  />

                  <PrivateRoute
                    access='ALL'
                    appConfigIncludes={['zenflow_telephony_feature']}
                    path='/channels/:projectShortName'
                    exact
                    name='Settings'
                    component={ChannelSettings}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/voicechannels/:projectShortName/:id'
                    exact
                    name='Settings'
                    component={CallTaskPage}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/startwizard/:templateName'
                    exact
                    name='StartWizard'
                    component={StartWizard}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/templatewizard/:projectShortName'
                    exact
                    name='CallTemplateWizard'
                    component={UniversalWizard}
                  />
                  <PrivateRoute
                    access='ALL'
                    path='/incomingtemplatewizard/:projectShortName'
                    exact
                    name='IncomingTemplateWizard'
                    component={UniversalWizard}
                  />
                  <PrivateRoute
                    access='ALL'
                    path='/bottemplatewizard/:projectShortName'
                    exact
                    name='BotTemplateWizard'
                    component={UniversalWizard}
                  />

                  <PrivateRoute
                    access='ALL'
                    path='/bot_dashboard/:projectShortName'
                    exact
                    name='BotDashboard'
                    component={BotDashboard}
                  />

                  <PrivateRoute
                    access='DIALER_CALL_TASK_READ'
                    path='/mailingtasks/:projectShortName'
                    name='Mailing list'
                    component={TextCampaigns}
                  />

                  <Route component={page404} />
                </Switch>
              </Suspense>
            </Full>
          </Switch>
        </div>
      </OAuthProvider>
    </Router>
  </ConnectedRouter>
);
