import React from 'react';

import { Button, Collapse, Grid } from 'altreidsds';
import classNames from 'classnames';
import localize, { t } from '../../localization';
import { KeyboardArrowDown, KeyboardArrowRight } from 'altreidsds/dist/icons';
import { isConfigEnabled, isIncluded } from '../../isAccessFunction';

import moduleClasses from './BotList.module.scss';

export default function CollapsedBotList({ classes, openMenu, toggleAddingBot, botList, closeMenu, currentProject }) {
  const currentBotType = botList.find(item => item.shortName === currentProject);
  const botListFiltered = currentBotType ? botList.filter(botItem => botItem.botType === currentBotType.botType) : [];

  return (
    <Collapse in={openMenu} timeout='auto' classes={{ wrapper: classes.collapseRoot }}>
      <Grid container spacing={0} className={classes.headerBotSelector} onClick={toggleAddingBot}>
        <Grid item className={classNames(moduleClasses.newBotButton)}>
          <div className={moduleClasses.newBotButton_plusIcon}>
            <i className='material-icons'>add</i>
          </div>
          {localize.translate('New bot')}
        </Grid>
      </Grid>
      {botListFiltered.map(item => (
        <Grid
          key={`uniqBotId_${item.shortName}`}
          container
          spacing={0}
          className={classNames(classes.headerBotSelector, 'topBotSelector')}
        >
          <Grid item xs={12} onClick={() => closeMenu(item.shortName)} className='topBotSelector'>
            {item.name}
          </Grid>
        </Grid>
      ))}
    </Collapse>
  );
}

export function ToggleCollapsedBotList({ pathname, toggleMenu, openMenu, botList, currentProject }) {
  const headerStyles = { width: '100%', display: 'flex', alignItems: 'center' };
  const linkContainerStyles = { marginLeft: 'auto' };
  const linkStyles = { display: 'inline-block', margin: '0 20px' };

  const currentBotType = botList.find(bot => bot.shortName === currentProject)?.botType;

  if (~pathname.indexOf('/scenario')) {
    headerStyles.width = 'calc(100vw - 980px)';
    headerStyles.minWidth = '150px';
  }

  if (~pathname.indexOf('/settings')) {
    headerStyles.width = 'calc(100vw - 280px)';
    headerStyles.minWidth = '150px';
  }

  return (
    <div style={headerStyles}>
      <Button
        variant='flat'
        className={moduleClasses.menuToggler}
        onClick={toggleMenu}
        classes={{ label: moduleClasses.flexCenter }}
        data-test-id='CreateScenario.ScenarioName.CollapseButton'
      >
        {openMenu ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        {botList.find(item => item.shortName === currentProject).name}
      </Button>

      {currentBotType === 'CALLS_BOT' && isConfigEnabled('showHelp') && (
        <div style={linkContainerStyles}>
          <a
            style={linkStyles}
            href={t('ToggleCollapsedBotList: LINK what is smart call')}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('ToggleCollapsedBotList: what is smart call')}
          </a>

          {isIncluded(['zenflow_billing_feature']) && (
            <a
              style={linkStyles}
              href={t('ToggleCollapsedBotList: LINK tariff and balance')}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('ToggleCollapsedBotList: tariff and balance')}
            </a>
          )}
        </div>
      )}
    </div>
  );
}
