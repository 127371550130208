import React from 'react';
import localize from 'localization';
import { InputText } from '@just-ai/just-ui';

import BaseEditForm from '../BaseEditForm';
import forEach from 'lodash/forEach';

import classes from '../BaseEditForm/BaseEditForm.module.scss';
import { axios } from '../../../../../pipes/functions';

import Groups from '../Groups';

const { translate: t } = localize;

export default class OperatorApiEditForm extends BaseEditForm {
  defaultFields = {
    name: false,
    botId: true,
    type: true,
  };

  constructor(props) {
    super(props);
    this.form = {};

    this.state = {
      rollout: true,
      errors: [],
      destinations: [],
    };

    this.destinationsValues = [];
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.editableChannel?.config) {
        const customParams = this.props.editableChannel.config;
        if (!!customParams.apiUrl && customParams.apiUrl !== '') {
          this.apiUrl = customParams.apiUrl;
          this.apiKey = customParams.apiKey;
          this.destinationsValues =
            this.props.editableChannel?.config?.destinations?.map(destination => ({ ...destination })) || [];
          this.getDestinations();
        }
      }
    }, 0);
  }

  createChannel = async () => {
    const { createChannel, editableChannel, onClose } = this.props;
    const channelData = {
      type: editableChannel.channelType,
      description: this.form.description.value,
      config: {
        ...editableChannel.config,
        apiUrl: this.form.apiUrl.value,
        destinations: [],
      },
    };

    await createChannel(channelData);
    onClose();
  };

  saveChannel = async () => {
    const { editChannel, editableChannel, onClose } = this.props;

    const channelData = {
      id: editableChannel.id,
      type: editableChannel.type,
      description: this.form.description.value,
      config: {
        ...editableChannel.config,
        apiUrl: this.form.apiUrl.value,
        destinations: this.destinationsValues,
      },
    };

    let errorFlag = false;
    if (channelData?.config?.destinations[0]) {
      forEach(channelData.config.destinations, destination => {
        if (!destination.ids || destination.ids.length === 0) {
          errorFlag = true;
        }
      });
    }

    if (errorFlag) return this.setState({ errors: [t('Check fields value')] });

    await editChannel(channelData);
    onClose();
  };

  getLabelName = () => t('Bot token');

  removeGroup = k => {
    this.props.removeGroup(k);
    this.destinationsValues.splice(k, 1);
  };

  addDestination = () => this.props.addDestination();

  changeDestination = (key, property, value) => {
    if (!this.destinationsValues[key]) this.destinationsValues[key] = {};

    this.destinationsValues[key][property] = value;
  };

  addIds = (key, ids) => {
    if (!this.destinationsValues[key]) this.destinationsValues[key] = {};
    this.destinationsValues[key].ids = ids;
  };

  getDestinations = async () => {
    if (!this.form.apiUrl?.value) return;
    const data = await axios.get(`/restapi/channels/operatorapi/getDestinations`, {
      params: {
        apiUrl: this.form.apiUrl.value,
        apiKey: this.apiKey,
      },
    });

    if (data.data.length > 0) {
      this.setState({ destinations: data.data });
    }
  };

  renderFooter = () => {
    const { destinations } = this.state;
    return (
      <Groups
        editableChannel={this.props.editableChannel}
        destinations={destinations}
        removeGroup={this.removeGroup}
        changeDestination={this.changeDestination}
        addIds={this.addIds}
        addDestination={this.addDestination}
        selectIDS={this.props.selectIDS}
      />
    );
  };

  renderInputs() {
    const { editableChannel } = this.props;

    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Name')}</p>
          <InputText
            innerRef={input => (this.form.description = input)}
            id='description'
            defaultValue={
              editableChannel.id ? editableChannel.description : t(`AddChannelCard ${editableChannel.channelType}`)
            }
            placeholder={t('Enter custom', t('Name').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('API URL')}</p>
          <InputText
            innerRef={input => (this.form.apiUrl = input)}
            id='apiUrl'
            onBlur={this.getDestinations}
            defaultValue={editableChannel.config?.apiUrl}
            placeholder={t('Enter custom', t('API URL'))}
          />
        </div>
      </>
    );
  }
}
