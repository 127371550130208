import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AutomessageSkillConfig } from 'modules/TemplatesWizard/types';
import SkillCollapseContainer from 'modules/TemplatesWizard/primitives/SkillCollapseContainer';

import { BaseSkill, ViewComponentProps } from '../BaseSkill';

import { AutomessageSequence } from './AutomessageSequence';
import { useTranslation } from '@just-ai/just-ui';
import { AutomessageSequenceContext, MessageType } from './AutomessageSequence/types';
import classes from './styles.module.scss';
import TextArea from 'modules/TemplatesWizard/FieldBuilder/fields/components/TextArea';

interface SkillViewProps extends ViewComponentProps {
  skill: AutomessageSkill;
}

const SkillView = React.memo(({ skill, index }: SkillViewProps) => {
  const innerState = useRef<any>({});
  const defaultValues = useRef<any>({});
  const { t } = useTranslation();

  const [messages, setMessages] = useState<MessageType[]>(skill.initialValues?.messages || [new MessageType()]);

  const addMessage = useCallback(() => {
    setMessages(prevMessages => {
      return [...prevMessages, new MessageType()];
    });
  }, []);

  const onChange = useCallback((messageIndex: number, partialMessage: Partial<MessageType>) => {
    setMessages(messages => {
      const newMessages = [...messages];
      newMessages[messageIndex] = {
        ...newMessages[messageIndex],
        ...partialMessage,
      };
      return newMessages;
    });
  }, []);

  const onChangeNoMatch = useCallback(
    (value: string, name: string, isDefaultValue: boolean) => {
      if (!isDefaultValue) {
        innerState.current[name] = value;
        skill.notify('onFieldsChange', { value: innerState.current, isDefaultValue });
        return;
      }
      defaultValues.current[name] = value;
      skill.notify('onFieldsChange', { value: defaultValues.current, isDefaultValue });
    },
    [skill]
  );

  const deleteMessageItem = useCallback((messageIndex: number) => {
    setMessages(messages => {
      const newMessages = [...messages];
      newMessages.splice(messageIndex, 1);
      return newMessages;
    });
  }, []);

  const resortMessages = useCallback((sortedMessages: MessageType[]) => {
    setMessages(messages => {
      return [messages[0], ...sortedMessages];
    });
  }, []);

  useEffect(() => {
    defaultValues.current['messages'] = messages;
    skill.notify('onFieldsChange', { value: defaultValues.current, isDefaultValue: true });
  }, [skill, messages]);

  return (
    <SkillCollapseContainer index={index} skill={skill}>
      <div className={classes.AutomessageSequenceSkillContainer}>
        <div>{skill.skillConfig.description}</div>
        <AutomessageSequenceContext.Provider value={{ addMessage, onChange, deleteMessageItem, resortMessages }}>
          <AutomessageSequence messages={messages} />
        </AutomessageSequenceContext.Provider>
        <TextArea
          onChange={onChangeNoMatch}
          envName='noMatch'
          info={{
            title: t('AutomessageSequence:noMatch:title'),
            placeholder: t('AutomessageSequence:noMatch:placeholder'),
            maxLength: 500,
          }}
          value={skill.initialValues?.noMatch || ''}
        />
        <div className={classes.AutomessageSequenceSkillContainer_footer}>{t('AutomessageSequence:footer:text')}</div>
      </div>
    </SkillCollapseContainer>
  );
});

export class AutomessageSkill extends BaseSkill {
  constructor(public skillConfig: AutomessageSkillConfig) {
    super(skillConfig);
  }

  ViewComponent = (props: ViewComponentProps) => {
    return <SkillView skill={this} {...props} />;
  };
}
