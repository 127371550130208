import { t } from 'localization';
import { TemplateWizardField } from '../FieldBuilder/fields';

import { ScenarioSkillConfig, SkillType, RealSkill } from '../types';

export const orderSkillConfig: () => ScenarioSkillConfig = () => ({
  type: SkillType.SCENARIO,
  withBaseInfo: true,
  withOperator: true,
  withMenu: true,
  skillName: RealSkill.ORDER,
  title: t('OrderSkill:Title'),
  description: '',
  shortDescription: t('OrderSkill:ShortDescription'),
  orderWeight: 10,
  skillVersion: 1,
  startState: '',
  language: '',
  menuType: 'transition',
  fixedInMenu: true,
  fields: [
    {
      type: 'textMessage',
      title: t('OrderSkill:Fields:Header:Title'),
      description: t('OrderSkill:Fields:Header:Description'),
    },
    {
      type: 'TextArea',
      envName: 'startText',
      title: t('OrderSkill:Fields:StartOrderText:Title'),
      placeholder: t('OrderSkill:Fields:StartOrderText:Placeholder'),
      value: '',
    },
    {
      type: 'DataCollectionTable',
      envName: 'questions',
      title: '',
      allowNewRows: true,
      preventDeleteLast: true,
      preventDeleteLastHint: t('SkillsWizard:PreventDelete:Hit'),
      columns: [
        {
          envName: 'name',
          header: t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header'),
          type: 'select',
          maxLength: 20,
          selectOptions: [
            t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Order'),
            t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Delivery'),
            t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Name'),
            t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Phone'),
            t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:AppointmentTime'),
          ],
          selectMultiple: false,
          eachRowSelectUniqValue: true,
          arbitrarySelectOptions: true,
          helperText: '',
        },
        {
          envName: 'value',
          header: t('OrderSkill:Fields:Questions:Columns:HowBotWillRequestInformation:Header'),
          type: 'text',
          maxLength: 500,
          selectOptions: null,
          helperText: '',
          widgetBotAnswer: true,
        },
      ],
      addRowText: t('OrderSkill:Fields:Questions:AddRowText'),
      value: [
        {
          name: t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Order'),
          value: t('OrderSkill:Questions:Values:ForOrder'),
        },
        {
          name: t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Delivery'),
          value: t('OrderSkill:Questions:Values:ForDelivery'),
        },
        {
          name: t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Name'),
          value: t('OrderSkill:Questions:Values:ForName'),
        },
        {
          name: t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:Phone'),
          value: t('OrderSkill:Questions:Values:ForPhone'),
        },
        {
          name: t('OrderSkill:Fields:Questions:Columns:WhatShouldKnow:Header:AppointmentTime'),
          value: t('OrderSkill:Questions:Values:ForAppointmentTime'),
        },
      ],
    },
    {
      type: 'TextArea',
      envName: 'verifyQuestionsText',
      title: t('OrderSkill:Fields:VerifyQuestionsText:Title'),
      placeholder: t('OrderSkill:Fields:VerifyQuestionsText:Placeholder'),
      helperText: t('OrderSkill:Fields:VerifyQuestionsText:HelperText'),
      value: '',
    },
    {
      type: 'TextArea',
      envName: 'submitText',
      title: t('OrderSkill:Fields:SubmitOrderText:Title'),
      placeholder: t('OrderSkill:Fields:SubmitOrderText:Placeholder'),
      value: '',
    },
    {
      type: 'textMessage',
      title: t('OrderSkill:Fields:SendInfoLocation:Title'),
      description: t('OrderSkill:Fields:SendInfoLocation:Description'),
    },
    {
      type: TemplateWizardField.MANAGER_NOTIFY,
      envName: 'sendPlaces',
    },
  ],
});
