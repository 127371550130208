const prefix = '@operatorChannels/';

export const FETCH_CHANNELS = prefix + 'FETCH_CHANNELS';
export const FETCH_CHANNELS_FULFILLED = prefix + 'FETCH_CHANNELS_FULFILLED';
export const FETCH_CHANNELS_PENDING = prefix + 'FETCH_CHANNELS_PENDING';
export const FETCH_CHANNELS_REJECTED = prefix + 'FETCH_CHANNELS_REJECTED';

export const CREATE_CHANNEL = prefix + 'CREATE_CHANNEL';
export const CREATE_CHANNEL_FULFILLED = prefix + 'CREATE_CHANNEL_FULFILLED';
export const CREATE_CHANNEL_PENDING = prefix + 'CREATE_CHANNEL_PENDING';
export const CREATE_CHANNEL_REJECTED = prefix + 'CREATE_CHANNEL_REJECTED';

export const REMOVE_CHANNEL = prefix + 'REMOVE_CHANNEL';
export const REMOVE_CHANNEL_FULFILLED = prefix + 'REMOVE_CHANNEL_FULFILLED';
export const REMOVE_CHANNEL_PENDING = prefix + 'REMOVE_CHANNEL_PENDING';
export const REMOVE_CHANNEL_REJECTED = prefix + 'REMOVE_CHANNEL_REJECTED';

export const SAVE_EDITABLE_CHANNEL = prefix + 'SAVE_EDITABLE_CHANNEL';
export const SAVE_EDITABLE_CHANNEL_FULFILLED = prefix + 'SAVE_EDITABLE_CHANNEL_FULFILLED';
export const SAVE_EDITABLE_CHANNEL_PENDING = prefix + 'SAVE_EDITABLE_CHANNEL_PENDING';
export const SAVE_EDITABLE_CHANNEL_REJECTED = prefix + 'SAVE_EDITABLE_CHANNEL_REJECTED';

export const DROP_CHANNELS = prefix + 'DROP_CHANNELS';
export const DROP_CHANNEL = prefix + 'DROP_CHANNEL';
export const UPDATE_CHANNEL = prefix + 'UPDATE_CHANNEL';

export const EDIT_CHANNEL = prefix + 'EDIT_CHANNEL';
export const EDIT_CHANNEL_FULFILLED = prefix + 'EDIT_CHANNEL_FULFILLED';
export const EDIT_CHANNEL_PENDING = prefix + 'EDIT_CHANNEL_PENDING';
export const EDIT_CHANNEL_REJECTED = prefix + 'EDIT_CHANNEL_REJECTED';

export const SET_CHANNEL_TO_EDIT = prefix + 'SET_CHANNEL_TO_EDIT';
export const SET_CHANNEL_TO_CREATION = prefix + 'SET_CHANNEL_TO_CREATION';

export const FETCH_WEBIM_WEBHOOK = prefix + 'FETCH_WEBIM_WEBHOOK';
export const FETCH_WEBIM_WEBHOOK_FULFILLED = prefix + 'FETCH_WEBIM_WEBHOOK_FULFILLED';
export const FETCH_WEBIM_WEBHOOK_PENDING = prefix + 'FETCH_WEBIM_WEBHOOK_PENDING';
export const FETCH_WEBIM_WEBHOOK_REJECTED = prefix + 'FETCH_WEBIM_WEBHOOK_REJECTED';
