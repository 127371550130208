import {
  CHANGE_LANGUAGE_FULFILLED,
  CHANGE_LANGUAGE_PENDING,
  CHANGE_LANGUAGE_REJECTED,
  CHECK_TOKEN_FULFILLED,
  CHECK_TOKEN_REJECTED,
  DROP_CURRENT_USER,
  DROP_EDITABLE_USER,
  GET_USER_ACCOUNT_FULFILLED,
  GET_USER_ACCOUNT_PENDING,
  GET_USER_ACCOUNT_REJECTED,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  MULTIPLE_FULFILLED,
  MULTIPLE_PENDING,
  MULTIPLE_REJECTED,
  SAVE_EDITABLE_USER_FULFILLED,
  SAVE_EDITABLE_USER_PENDING,
  SAVE_EDITABLE_USER_REJECTED,
  SET_CURRENT_USER,
  GET_USER_SUBSCRIPTION_FULFILLED,
  GET_USER_SUBSCRIPTION_PENDING,
  GET_USER_SUBSCRIPTION_REJECTED,
  CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED,
  CHANGE_USER_SUBSCRIPTION_STATUS_PENDING,
  CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED,
  GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED,
  BLOCK_CURRENT_USER_TARIFF,
  CHECK_VERIFICATION_CODE_FULFILLED,
  CLEAN_LOGIN_ERRORS,
  GET_CALLS_AVAILABILITY_PENDING,
  GET_CALLS_AVAILABILITY_REJECTED,
  GET_CALLS_AVAILABILITY_FULFILLED,
  CHECK_USER_PHONE_COUNTRY_CODE_PENDING,
  CHECK_USER_PHONE_COUNTRY_CODE_REJECTED,
  CHECK_USER_PHONE_COUNTRY_CODE_FULFILLED,
  CHANGE_EMAIL_BY_TOKEN_FULFILLED,
  GET_AVAILABLE_MINUTES_PACKAGES_FULFILLED,
  GET_AVAILABLE_SMS_PACKAGES_FULFILLED,
  SET_PACKAGES_COUNT,
  GET_AVAILABLE_UNIQUES_PACKAGES_FULFILLED,
  GET_MANUAL_CONTROL_INFO_FULFILLED,
  SET_CURRENT_USER_PHONE,
  SET_CURRENT_USER_ON_VERIFICATION,
  GET_GPT_STATUS_FULFILLED,
} from '../../constants/currentUser.actions';
import {
  CREATE_PARTNER_FULFILLED,
  CREATE_PARTNER_PENDING,
  CREATE_PARTNER_REJECTED,
  DROP_ERRORS,
} from '../../constants/referrals.actions';
import localize from '../../localization';
import { getUserLanguage, parseErrors } from '../../pipes/pureFunctions';
import { langBackMap } from '../../constants/botprojects.actions';
import { setAxiosHeadersLanguage } from '../../pipes/functions';
import moment from 'moment';
import { merge } from 'lodash';
import 'moment/locale/ru';
import 'moment/locale/pt-br';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { TariffEnum } from '@just-ai/api/dist/generated/Editorbe';

const MOMENT_LOCALE_MAP = {
  ru: 'ru',
  eng: 'en',
  cn: 'en-gb',
  pt: 'pt-br',
};

setTimeout(() => moment.locale(MOMENT_LOCALE_MAP[getUserLanguage()] || 'en'), 0);

window.moment = moment;

const InitialState = {
  currentUser: null,
  language: getUserLanguage(),
  features: [],
  fetching: false,
  allPermissions: [],
  roles: [],
  error: '',
  errors: [],
  success: '',
  internal: false,
  isChatGPTLimitExhausted: false,
  GPTTariff: 'FREE',

  isMultipleAvailable: false,

  changeUserLanguagePending: false,
  showConfirmNumberModal: false,
  callsAvailableByPhoneNumberCode: false,
  isDefaultTelephonyConnectionAvailable: false,
  justaiMinutes: [],
  asrMinutes: [],
  sms: [],
  packagesCount: {},
};

/**
 * @typedef CurrentUserReducerState
 * @type {Object.<string, *> & {
 *  GPTTariff: TariffEnum,
 *  isChatGPTLimitExhausted: boolean
 * }}
 */

/**
 * @param state {CurrentUserReducerState}
 * @param action {any}
 * @return {CurrentUserReducerState}
 */
export default function CurrentUserReducer(state = InitialState, action) {
  switch (action.type) {
    case MULTIPLE_FULFILLED: {
      return {
        ...state,
        isMultipleAvailable: action.payload.data,
        fetching: false,
      };
    }
    case MULTIPLE_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case MULTIPLE_REJECTED: {
      if (process.env.NODE_ENV === 'development') {
        return {
          ...state,
          fetching: false,
          isMultipleAvailable: true,
        };
      }
      return {
        ...state,
        fetching: false,
      };
    }
    case SET_CURRENT_USER: {
      /*
            *   community
                developer
                basic
                standart
                pro
            * */

      if (!action.currentUser.language) {
        action.currentUser.language = 'RU';
      }
      localStorage.CURRENT_USER = JSON.stringify(action.currentUser);
      let settedLanguage = localStorage.getItem('USER_LANGUAGE');

      if (settedLanguage !== 'ru' && settedLanguage !== 'eng') {
        settedLanguage = langBackMap[action.payload.data.language] || 'ru';
      }

      localStorage.setItem('USER_LANGUAGE', settedLanguage);
      moment.locale(MOMENT_LOCALE_MAP[settedLanguage] || 'en');
      return {
        ...state,
        language: settedLanguage,
        currentUser: action.currentUser,
        allPermissions:
          !!action.currentUser && action.currentUser.allPermissions ? action.currentUser.allPermissions : null,
        features: !!action.currentUser && !!action.currentUser.features ? action.currentUser.features : null,
        roles: !!action.currentUser && action.currentUser.roles ? action.currentUser.roles : null,
        error: '',
        internal: !!action.currentUser && action.currentUser.internal ? action.currentUser.internal : false,
      };
    }

    case CREATE_PARTNER_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case CREATE_PARTNER_FULFILLED: {
      const newCurrentUser = { ...state.currentUser };

      newCurrentUser.account = {
        ...newCurrentUser.account,
        partner: {
          enabled: action.payload.data.enabled,
          id: action.payload.data.id,
        },
      };

      return {
        ...state,
        fetching: false,
        currentUser: newCurrentUser,
      };
    }

    case CREATE_PARTNER_REJECTED: {
      let errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        errors: errors,
      };
    }

    case DROP_ERRORS: {
      return {
        ...state,
        errors: InitialState.errors,
      };
    }

    case DROP_CURRENT_USER: {
      return {
        ...state,
        currentUser: InitialState.currentUser,
        features: InitialState.features,
        fetching: InitialState.fetching,
        allPermissions: InitialState.allPermissions,
        roles: InitialState.roles,
        error: InitialState.error,
        success: InitialState.success,
        internal: InitialState.internal,

        isMultipleAvailable: InitialState.isMultipleAvailable,
      };
    }

    case DROP_EDITABLE_USER: {
      return {
        ...state,
        language: localStorage.getItem('USER_LANGUAGE') || InitialState.language,
      };
    }

    case LOGIN_FULFILLED: {
      localStorage.CURRENT_USER = JSON.stringify(action.payload.data);
      const language = langBackMap[action.payload.data.language] || 'ru';
      localStorage.setItem('USER_LANGUAGE', language);
      return {
        ...state,
        language: language,
        currentUser: action.payload.data,
        allPermissions: action.payload.data.allPermissions,
        features: action.payload.data.features,
        roles: action.payload.data.roles,
        error: '',
        internal: action.payload.data.internal,
        fetching: false,
      };
    }

    case CHECK_VERIFICATION_CODE_FULFILLED: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          phoneVerified: true,
          phone: action.payload.config.number,
        },
      };
    }

    case LOGIN_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case LOGIN_REJECTED: {
      const response = action.payload.response;
      return {
        ...state,
        error: response.data.message ? response.data.message : '',
        fetching: false,
      };
    }
    case CLEAN_LOGIN_ERRORS: {
      return {
        ...state,
        error: false,
      };
    }
    case CHECK_TOKEN_FULFILLED: {
      let onSuccessState = {
        ...state,
        success: localize.translate('Your Email has been successfully confirmed.'),
      };

      if (state.currentUser)
        onSuccessState.currentUser = {
          ...state.currentUser,
          emailVerified: true,
        };

      return onSuccessState;
    }
    case CHECK_TOKEN_REJECTED: {
      return {
        ...state,
      };
    }
    case LOGOUT_FULFILLED: {
      localStorage.removeItem('CURRENT_USER');
      return {
        ...InitialState,
        language: state.language,
      };
    }
    case LOGOUT_REJECTED: {
      return {
        ...state,
      };
    }

    case CHANGE_LANGUAGE_PENDING: {
      return {
        ...state,
        changeUserLanguagePending: true,
      };
    }
    case CHANGE_LANGUAGE_REJECTED: {
      return {
        ...state,
        changeUserLanguagePending: false,
      };
    }

    case CHANGE_LANGUAGE_FULFILLED: {
      const language = langBackMap[action.payload.data.language] || 'ru';
      localStorage.setItem('USER_LANGUAGE', language);
      moment.locale(MOMENT_LOCALE_MAP[language] || 'en');
      setAxiosHeadersLanguage(action.payload.data.language);
      let currentUser = {
        ...state.currentUser,
        language: action.payload.data.language,
      };
      localStorage.setItem('CURRENT_USER', JSON.stringify(currentUser));

      return {
        ...state,
        currentUser: { ...currentUser },
        language: language,
        changeUserLanguagePending: false,
      };
    }

    case SAVE_EDITABLE_USER_FULFILLED: {
      const newCurrentUser = merge({}, state.currentUser, action.payload.data);
      localStorage.CURRENT_USER = JSON.stringify(newCurrentUser);
      localStorage.setItem('USER_LANGUAGE', state.language);
      return {
        ...state,
        fetching: false,
        currentUser: newCurrentUser,
        language: state.language,
      };
    }
    case SAVE_EDITABLE_USER_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case SAVE_EDITABLE_USER_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: !!action.payload.response.data.error
          ? [action.payload.response.data.error]
          : action.payload.response.data.errors,
      };
    }

    case CHANGE_USER_SUBSCRIPTION_STATUS_PENDING:
    case GET_USER_SUBSCRIPTION_PENDING:
    case GET_USER_ACCOUNT_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case CHANGE_USER_SUBSCRIPTION_STATUS_REJECTED:
    case GET_USER_SUBSCRIPTION_REJECTED:
    case GET_USER_ACCOUNT_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_USER_ACCOUNT_FULFILLED: {
      const tariff = action.payload.data;
      let currentUser = {
        ...state.currentUser,
        tariff: {
          ...state.currentUser.tariff,
          ...tariff,
        },
      };

      localStorage.CURRENT_USER = JSON.stringify(currentUser);

      let newState = {
        ...state,
        currentUser: currentUser,
        fetching: false,
      };

      const mlpLimits = tariff?.limitsData?.mlpTokens;

      if (mlpLimits) {
        const tariffNameMap = {
          Free: TariffEnum.FREE,
          Paid: TariffEnum.PAID,
        };
        newState = {
          ...newState,
          isChatGPTLimitExhausted: mlpLimits.usedLimit >= mlpLimits.tariffLimit && mlpLimits.extraLimit <= 0,
          GPTTariff: tariffNameMap[tariff.tariffName] || '',
        };
      }
      return newState;
    }
    case GET_GPT_STATUS_FULFILLED:
      return {
        ...state,
        isChatGPTLimitExhausted: !action.payload.hasQuota,
        GPTTariff: action.payload.tariff,
      };

    case GET_MANUAL_CONTROL_INFO_FULFILLED: {
      let currentUser = {
        ...state.currentUser,
        tariff: {
          ...state.currentUser.tariff,
          manualControlData: action.payload.data,
        },
      };

      localStorage.CURRENT_USER = JSON.stringify(currentUser);
      return {
        ...state,
        currentUser: currentUser,
        fetching: false,
      };
    }

    case CHANGE_USER_SUBSCRIPTION_STATUS_FULFILLED:
    case GET_USER_SUBSCRIPTION_FULFILLED: {
      let currentUser = {
        ...state.currentUser,
        subscription: action.payload.data,
      };

      localStorage.CURRENT_USER = JSON.stringify(currentUser);
      return {
        ...state,
        currentUser: currentUser,
        fetching: false,
      };
    }

    case GET_USER_ACCOUNT_PAYMENT_DATA_FULFILLED: {
      if (
        !Boolean(state.currentUser.paymentProcessing) ||
        (Boolean(state.currentUser.paymentProcessing) &&
          state.currentUser.paymentProcessing.status !== action.payload.data.status)
      ) {
        let currentUser = {
          ...state.currentUser,
          paymentProcessing: action.payload.data,
        };

        localStorage.setItem('CURRENT_USER', JSON.stringify(currentUser));

        return {
          ...state,
          currentUser: currentUser,
          fetching: false,
        };
      } else {
        return state;
      }
    }

    case BLOCK_CURRENT_USER_TARIFF: {
      let currentUser = {
        ...state.currentUser,
        tariff: {
          ...state.currentUser.tariff,
          blocked: true,
        },
      };

      return {
        ...state,
        currentUser: { ...currentUser },
      };
    }

    case GET_CALLS_AVAILABILITY_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_CALLS_AVAILABILITY_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_CALLS_AVAILABILITY_FULFILLED: {
      return {
        ...state,
        fetching: false,
        callsAvailableByPhoneNumberCode: action.payload.data, //isRussianPhone
      };
    }

    case CHECK_USER_PHONE_COUNTRY_CODE_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case CHECK_USER_PHONE_COUNTRY_CODE_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case CHECK_USER_PHONE_COUNTRY_CODE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        isDefaultTelephonyConnectionAvailable: action.payload.data,
      };
    }

    case CHANGE_EMAIL_BY_TOKEN_FULFILLED: {
      const newEmail = action.payload.data.newEmail;
      let newCurrentUser = JSON.parse(localStorage.CURRENT_USER);
      newCurrentUser.email = newEmail;
      newCurrentUser.login = newEmail.toLowerCase();
      localStorage.CURRENT_USER = JSON.stringify(newCurrentUser);
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          email: newEmail,
          login: newEmail.toLowerCase(),
        },
      };
    }

    case GET_AVAILABLE_MINUTES_PACKAGES_FULFILLED: {
      const data = action.payload.data;
      const justaiMinutes = data.filter(item => item.minutesType === 'JUSTAI');
      const asrMinutes = data.filter(item => item.minutesType === 'MIXED');

      const packagesCount = {};
      data.forEach(item => {
        packagesCount[item.uniqueName] = {
          count: 1,
          isEnabled: false,
        };
      });

      return {
        ...state,
        justaiMinutes,
        asrMinutes,
        packagesCount,
      };
    }

    case SET_PACKAGES_COUNT: {
      const data = action.payload.data;
      const packagesCount = state.packagesCount;

      packagesCount[data.name] = {
        count: data.value,
        isEnabled: data.isEnabled,
      };

      return {
        ...state,
        packagesCount,
      };
    }

    case GET_AVAILABLE_SMS_PACKAGES_FULFILLED: {
      const sms = action.payload.data;

      return {
        ...state,
        sms,
      };
    }

    case GET_AVAILABLE_UNIQUES_PACKAGES_FULFILLED: {
      return {
        ...state,
        uniqueClientsByChannel: action.payload.data,
      };
    }

    case SET_CURRENT_USER_PHONE: {
      const parsedPhone = parsePhoneNumberFromString(`+${action.payload.phone}`);

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          phoneVerified: true,
          phone: action.payload.phone,
          ...(parsedPhone !== undefined
            ? {
                phoneNumberNational: {
                  countryCode: parseInt(parsedPhone.countryCallingCode),
                  nationalNumber: parsedPhone.nationalNumber,
                },
              }
            : {}),
        },
      };
    }
    case SET_CURRENT_USER_ON_VERIFICATION: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          account: { ...state.currentUser.account, verificationStatus: 'ACCOUNT_ON_VERIFICATION' },
        },
      };
    }

    default: {
      return state;
    }
  }
}
