import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';

import { t } from 'localization';

import { composeSubComponents } from 'helpers/helpFunctions';

import { Message, MessageType } from './Message';

const Messages$ = new BehaviorSubject<MessageType[]>([]);
const ActiveMessage$ = new Subject<string | number | undefined>();

const MessagesContainer: FC = memo(() => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [activeMessageId, setActiveMessageId] = useState<string | number | undefined>(undefined);
  const messageScrollContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const subscription = Messages$.subscribe(value => setMessages(value));
    const subscription2 = ActiveMessage$.subscribe(value => {
      setActiveMessageId(value);
      if (value && messageScrollContainer.current) {
        const element = messageScrollContainer.current.querySelector(`#${value}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    });

    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
    };
  }, []);

  return (
    <div className='justwidget--messages-container'>
      <div className='justwidget--messages' ref={messageScrollContainer}>
        {/*{messages.map(message => (*/}
        {/*  <Message {...message} key={message.id} active={activeMessageId ? activeMessageId === message.id : false} />*/}
        {/*))}*/}
        {/*<Message*/}
        {/*  id='response'*/}
        {/*  response={{*/}
        {/*    text: 'Привет',*/}
        {/*    buttons: [{ text: '1' }, { text: '2' }, { text: '3' }, { text: '4' }, { text: '5' }],*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Message id='request' request='Привет' />*/}
        <Message id='system' system={t('PseudoWidget:Unavailable:Text')} />
      </div>
    </div>
  );
});

export default composeSubComponents(MessagesContainer, {
  Messages$,
  ActiveMessage$,
});
