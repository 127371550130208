import React, { useEffect, useState } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import cn from 'classnames';
import { Button, Spinner, useId } from '@just-ai/just-ui';

import { t } from 'localization';
import { useAppSelector } from 'storeHooks';

import { composeSubComponents } from 'helpers/helpFunctions';
import { useBehaviorSubject } from 'helpers/hooks/useBehaviorSubject';

import { useSkillsProjectChatWidget } from '../hooks/useSkillsProjectChatWidget';
import WizardTooltip from '../primitives/WizardTooltip';
import MessagesContainer from './MessagesContainer';
import { MenuButtons } from './MenuButtons';

import { TemplatesWizardIsValid$ } from '..';
import classes from './PseudoWidget.module.scss';

const TestWidgetLoading$ = new BehaviorSubject<boolean>(false);
const ShowDeployButton$ = new BehaviorSubject<boolean>(false);
const MenuButtons$ = new BehaviorSubject<string[]>([]);
const DropWidgetState$ = new Subject<string>();

const PseudoWidget = () => {
  const [isShownDeployButton, showDeployButton] = useState(true);
  const [menuButtons, setMenuButtons] = useState<string[]>([]);
  const canStartTesting = useBehaviorSubject(TemplatesWizardIsValid$);
  const isLoading = useBehaviorSubject(TestWidgetLoading$);
  const validateTooltipId = useId();

  const { cloudDomains } = useAppSelector(store => ({
    cloudDomains: store.AppConfigReducer.cloudDomains,
  }));

  useEffect(() => {
    const sub = ShowDeployButton$.subscribe(val => showDeployButton(val));
    const sub2 = MenuButtons$.subscribe(setMenuButtons);
    const sub3 = DropWidgetState$.subscribe(value => {
      setMenuButtons([]);
      showDeployButton(false);
      MessagesContainer.ActiveMessage$.next(undefined);
      MessagesContainer.Messages$.next([
        {
          id: 'PseudoWidget.DefaultEmptyState',
          system: value,
        },
      ]);
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
      sub3.unsubscribe();
    };
  }, []);

  const { testingSkillState, openTestWidgetForSkill } = useSkillsProjectChatWidget();

  return (
    <div className={classes.PseudoWidget}>
      <link href={`//${cloudDomains.aimylogic.domain}/chatadapter/s/defaultv3/css/index.css`} rel='stylesheet' />
      <style type='text/css'>{`
      .justwidget {
        --main-background-color: rgba(255, 255, 255, 1);
        --header-background-color: var(--blue-200);
        --operator-background-color: #D6E2F2;
        --user-background-color: #D9E4D9;

        --scroll-color: #BFBEBD;

        --headline-font-color: var(--gray-800);
        --user-font-color: #000;
        --bot-font-color: #000;
        --form-buttons-color: #5A9CED;
        --form-text-color: #000;

        --font: Roboto, sans-serif;

        --main-bright-color: #5A9CED;
        --main-bright-color-rgb: 74, 144, 226;

        --main-bright-color-disabled: #BFBEBD;
        --main-bright-color-disabled-background: rgba(191,190, 189, 0.3);
        --send-file-background: rgba(228, 229, 230, 0.3);
        --send-progress-background: rgba(74, 144, 226, 0.3);

        --avatar-width: 40px;
        --main-font-size: 14px;
        --username-font-size: 12px;

        --button-size: 26px;
        --button-link-icon-size: 13.5px;
        --avatar-width: 32px;
        z-index: 1 !important;
      }
      `}</style>
      <div
        className={cn('justwidget mobile_right bottom', classes.PseudoWidget_Body)}
        data-test-id='PseudoJustwidget'
        style={{ maxHeight: '70vh', right: 0, bottom: 0 }}
      >
        {(testingSkillState.isLoading || isLoading) && <Spinner size='sm' />}
        <div className='justwidget--inner' style={{ maxHeight: '70vh' }}>
          <div className='justwidget--headline'>
            <h2>{t('PseudoWidget:Title')}</h2>
          </div>
          <MessagesContainer />
          <MenuButtons buttons={menuButtons} />
          {isShownDeployButton && (
            <>
              <div
                id={validateTooltipId}
                className={cn(classes.PseudoWidget_deployButtonContainer, {
                  preventPointerEvents: !canStartTesting,
                })}
              >
                <Button
                  color='primary'
                  disabled={!canStartTesting || testingSkillState.isWidgetOpened}
                  onClick={openTestWidgetForSkill}
                  className={classes.PseudoWidget_deployButtonContainer_deployButton}
                >
                  {t('PseudoWidget:DeployButtonText')}
                </Button>
              </div>
              {!canStartTesting && (
                <WizardTooltip trigger='hover' placement='top' target={validateTooltipId}>
                  {t('FAQSkill:Error:Validate')}
                </WizardTooltip>
              )}
            </>
          )}
        </div>
      </div>
      <div id='widget-root' />
    </div>
  );
};

export default composeSubComponents(PseudoWidget, {
  ShowDeployButton$,
  MenuButtons$,
  Messages$: MessagesContainer.Messages$,
  ActiveMessage$: MessagesContainer.ActiveMessage$,
  DropWidgetState$,
  TestWidgetLoading$,
});
