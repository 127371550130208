import React, { useRef } from 'react';
import { pick } from 'lodash';
import { JustSelect } from '@just-ai/just-ui';

import { t } from 'localization';

import styles from './styles.module.scss';

export enum ConversationTone {
  NEUTRAL = 'NEUTRAL',
  FRIENDLY = 'FRIENDLY',
  FORMAL = 'FORMAL',
}

export enum EmojiUsing {
  NEVER = 'NEVER',
  RARELY = 'RARELY',
  OFTEN = 'OFTEN',
}

type BotSettingsState = { botTone: ConversationTone; botEmojiUsing: EmojiUsing };

interface BotSettingsProps {
  value: BotSettingsState;
  onChange: (data: BotSettingsState) => void;
}
const BotSettings = ({ value, onChange }: BotSettingsProps) => {
  const innerValue = useRef(pick(value, ['botTone', 'botEmojiUsing']));

  return (
    <div className={styles.BotSettings}>
      <div className={styles.input}>
        <span>{t('BotSettings:ConversationTone:Label')}</span>
        <JustSelect
          fullWidth
          withoutSearch
          className={styles.BotSettings__emojiFix}
          classNameForList={styles.BotSettings__emojiFix}
          onChange={val => {
            const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
            innerValue.current.botTone = oneEl.toString() as ConversationTone;
            onChange(innerValue.current);
          }}
          value={innerValue.current.botTone}
          options={Object.keys(ConversationTone).map(tone => ({
            value: tone,
            label: t(`BotSettings:ConversationTone:${tone}`),
          }))}
          position='fixed'
        />
      </div>

      <div className={styles.input}>
        <span>{t('BotSettings:EmojiUsing:Label')}</span>
        <JustSelect
          fullWidth
          withoutSearch
          onChange={val => {
            const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
            innerValue.current.botEmojiUsing = oneEl.toString() as EmojiUsing;
            onChange(innerValue.current);
          }}
          value={innerValue.current.botEmojiUsing}
          classNameForList={styles.BotSettings__emojiFix}
          options={Object.keys(EmojiUsing).map(using => ({
            value: using,
            label: t(`BotSettings:EmojiUsing:${using}`),
          }))}
          position='fixed'
        />
      </div>
    </div>
  );
};

export default React.memo(BotSettings);
