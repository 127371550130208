import * as KnowledgeActions from '../../constants/knowledge.actions';
import { parseErrors } from '../../pipes/pureFunctions';
import { differenceWith, isEqual, uniqWith } from 'lodash';

const InitialState = {
  baseList: [],
  fetching: false,
  fetched: false,
  errors: [],

  currentBase: {
    baseData: null,
    isDisabled: false,
    ttsEnabled: false,
    searchedQuestionList: [],
    editableQuestionId: null,
    questionList: [],
    questionListDiff: [],
    searchedStr: null,
    currentPageQuestionList: [],
    fetching: false,
    fetched: false,
    errors: [],
    fetchingAll: false,
    fetchedAll: false,
    errorsAll: [],
    totalElements: 0,
    totalPages: 0,
    last: false,
    numberOfElements: 0,
    size: 0,
    number: 0,
    snapshot: null,
  },
};

export default function KnowledgeReducer(state = InitialState, action) {
  switch (action.type) {
    case KnowledgeActions.SAVE_BASE_PENDING: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          fetching: true,
          snapshot: InitialState.currentBase.snapshot,
        },
      };
    }

    case KnowledgeActions.SAVE_BASE_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          fetching: false,
          errors: errors,
        },
      };
    }

    case KnowledgeActions.SAVE_BASE_FULFILLED: {
      const replaceMap = action.payload.data;

      const newCurrentPageQuestionList = replaceIds(state.currentBase.currentPageQuestionList, replaceMap);
      const newSearchedQuestionList = replaceIds(state.currentBase.searchedQuestionList, replaceMap);

      let newQuestionList = mergeQuestions(state.currentBase.questionList, state.currentBase.questionListDiff);
      newQuestionList = replaceIds(newQuestionList, replaceMap);

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          baseData: {
            ...state.currentBase.baseData,
            disabled: state.currentBase.isDisabled,
            ttsEnabled: state.currentBase.ttsEnabled,
          },
          fetching: false,
          questionListDiff: InitialState.currentBase.questionListDiff,
          currentPageQuestionList: newCurrentPageQuestionList,
          searchedQuestionList: newSearchedQuestionList,
          questionList: newQuestionList,
        },
      };
    }

    case KnowledgeActions.SEARCH: {
      let searchedQuestionList = mergeQuestions(state.currentBase.questionList, state.currentBase.questionListDiff);
      searchedQuestionList = filteredQuestionList(searchedQuestionList, action.searchedStr);

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          searchedQuestionList: searchedQuestionList,
          editableQuestionId: InitialState.editableQuestionId,
          searchedStr: action.searchedStr,
          snapshot: InitialState.currentBase.snapshot,
        },
      };
    }

    case KnowledgeActions.CANCEL_SEARCH: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          searchedQuestionList: InitialState.currentBase.searchedQuestionList,
          searchedStr: InitialState.searchedStr,
        },
      };
    }

    case KnowledgeActions.ADD_QUESTION: {
      const newQuestionListDiff = [action.question, ...state.currentBase.questionListDiff];
      const newCurrentPageQuestionList = [action.question, ...state.currentBase.currentPageQuestionList];
      const searchedQuestionList = [...state.currentBase.searchedQuestionList];

      if (Boolean(state.currentBase.searchedStr) && state.currentBase.searchedStr.length > 0) {
        if (filteredQuestionList([action.question], state.currentBase.searchedStr).length > 0) {
          searchedQuestionList.unshift(action.question);
        }
      }

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          questionListDiff: newQuestionListDiff,
          currentPageQuestionList: newCurrentPageQuestionList,
          searchedQuestionList: searchedQuestionList,
          editableQuestionId: action.question.id,
          snapshot: InitialState.currentBase.snapshot,
        },
      };
    }

    case KnowledgeActions.REMOVE_QUESTION: {
      const newQuestionListDiff = [...state.currentBase.questionListDiff];
      const newCurrentPageQuestionList = [...state.currentBase.currentPageQuestionList];
      const newSearchedQuestionList = [...state.currentBase.searchedQuestionList];
      const snapshot = {
        questionListDiff: [...newQuestionListDiff],
        currentPageQuestionList: [...newCurrentPageQuestionList],
        searchedQuestionList: [...newSearchedQuestionList],
      };

      if (newSearchedQuestionList.length > 0) {
        const newSearchedQuestionListIndex = newSearchedQuestionList.findIndex(question => {
          return question.id === action.questionId;
        });

        if (newSearchedQuestionListIndex >= 0) {
          newSearchedQuestionList.splice(newSearchedQuestionListIndex, 1);
        }
      }

      const newQuestionListDiffIndex = newQuestionListDiff.findIndex(question => {
        return question.id === action.questionId;
      });

      if (newQuestionListDiffIndex < 0) {
        newQuestionListDiff.push({
          id: action.questionId,
          deleted: true,
        });
      } else if (typeof action.questionId === 'string' && action.questionId.startsWith('new_question_')) {
        newQuestionListDiff.splice(newQuestionListDiffIndex, 1);
      } else {
        newQuestionListDiff[newQuestionListDiffIndex] = {
          id: action.questionId,
          deleted: true,
        };
      }

      const newCurrentPageQuestionListIndex = newCurrentPageQuestionList.findIndex(question => {
        return question.id === action.questionId;
      });

      newCurrentPageQuestionList.splice(newCurrentPageQuestionListIndex, 1);

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          questionListDiff: newQuestionListDiff,
          currentPageQuestionList: newCurrentPageQuestionList,
          searchedQuestionList: newSearchedQuestionList,
          editableQuestionId: null,
          snapshot: snapshot,
        },
      };
    }

    case KnowledgeActions.RESTORE_LAST_STATE: {
      const snapshot = state.currentBase.snapshot;

      const newQuestionListDiff = Boolean(snapshot.questionListDiff)
        ? [...snapshot.questionListDiff]
        : [...state.currentBase.questionListDiff];
      const newCurrentPageQuestionList = Boolean(snapshot.currentPageQuestionList)
        ? [...snapshot.currentPageQuestionList]
        : [...state.currentBase.currentPageQuestionList];
      const newSearchedQuestionList = Boolean(snapshot.searchedQuestionList)
        ? [...snapshot.searchedQuestionList]
        : [...state.currentBase.searchedQuestionList];

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          questionListDiff: newQuestionListDiff,
          currentPageQuestionList: newCurrentPageQuestionList,
          searchedQuestionList: newSearchedQuestionList,
          snapshot: InitialState.currentBase.snapshot,
        },
      };
    }

    case KnowledgeActions.EDIT_QUESTION: {
      const newQuestionListDiff = [...state.currentBase.questionListDiff];
      const newCurrentPageQuestionList = [...state.currentBase.currentPageQuestionList];
      const newSearchedQuestionList = [...state.currentBase.searchedQuestionList];

      const newQuestionListDiffIndex = newQuestionListDiff.findIndex(question => {
        return question.id === action.question.id;
      });

      const newCurrentPageQuestionListIndex = newCurrentPageQuestionList.findIndex(question => {
        return question.id === action.question.id;
      });

      const newQuestion = { ...newCurrentPageQuestionList[newCurrentPageQuestionListIndex] };

      const newQuestionDiff =
        newQuestionListDiffIndex < 0 ? { id: action.question.id } : newQuestionListDiff[newQuestionListDiffIndex];

      const answersDiff = getItemsArrayDiff(newQuestion.answers, action.question.answers);
      if (action.question.hasOwnProperty('answers') && answersDiff.length > 0) {
        newQuestion.answers = action.question.answers;
        if (Boolean(newQuestionDiff.answers)) {
          let newAnswersDiff = uniqWith([...answersDiff, ...newQuestionDiff.answers], (a, b) => a.id === b.id);
          newAnswersDiff = newAnswersDiff.filter(answerDiff => {
            return (
              !(
                typeof answerDiff.id === 'string' &&
                answerDiff.id.startsWith('new_answer_') &&
                answerDiff.hasOwnProperty('deleted')
              ) &&
              (!Boolean(answerDiff.answer) ||
                (Boolean(answerDiff.answer) &&
                  answerDiff.answer.length > 0 &&
                  answerDiff.answer !== ' ' &&
                  answerDiff.answer !== '\n'))
            );
          });
          newQuestionDiff.answers = newAnswersDiff;
        } else {
          newQuestionDiff.answers = answersDiff;
        }
      }
      const synonymsDiff = getItemsArrayDiff(newQuestion.synonyms, action.question.synonyms);
      if (action.question.hasOwnProperty('synonyms') && synonymsDiff.length > 0) {
        newQuestion.synonyms = action.question.synonyms;
        if (Boolean(newQuestionDiff.synonyms)) {
          let newSynonymsDiff = uniqWith([...synonymsDiff, ...newQuestionDiff.synonyms], (a, b) => a.id === b.id);
          newSynonymsDiff = newSynonymsDiff.filter(synonymDiff => {
            return (
              !(
                typeof synonymDiff.id === 'string' &&
                synonymDiff.id.startsWith('new_synonym_') &&
                synonymDiff.hasOwnProperty('deleted')
              ) &&
              (!Boolean(synonymDiff.synonym) ||
                (Boolean(synonymDiff.synonym) &&
                  synonymDiff.synonym.length > 0 &&
                  synonymDiff.synonym !== ' ' &&
                  synonymDiff.synonym !== '\n'))
            );
          });
          newQuestionDiff.synonyms = newSynonymsDiff;
        } else {
          newQuestionDiff.synonyms = synonymsDiff;
        }
      }

      if (action.question.hasOwnProperty('question') && action.question.question !== newQuestion.question) {
        newQuestion.question = action.question.question;
        newQuestionDiff.question = action.question.question;
      }

      if (action.question.hasOwnProperty('disabled') && action.question.disabled !== newQuestion.disabled) {
        newQuestion.disabled = action.question.disabled;
        newQuestionDiff.disabled = action.question.disabled;
      }

      newCurrentPageQuestionList[newCurrentPageQuestionListIndex] = newQuestion;

      if (newSearchedQuestionList.length > 0) {
        const newSearchedQuestionListIndex = newSearchedQuestionList.findIndex(question => {
          return question.id === action.question.id;
        });

        newSearchedQuestionList[newSearchedQuestionListIndex] = newQuestion;
      }

      if (newQuestionListDiffIndex < 0) {
        if (Object.keys(newQuestionDiff).length > 1) {
          //if diff object has more than id property
          newQuestionListDiff.push(newQuestionDiff);
        }
      } else {
        newQuestionListDiff[newQuestionListDiffIndex] = newQuestionDiff;
      }

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          questionListDiff: newQuestionListDiff,
          currentPageQuestionList: newCurrentPageQuestionList,
          searchedQuestionList: newSearchedQuestionList,
          snapshot: InitialState.currentBase.snapshot,
        },
      };
    }

    case KnowledgeActions.TOGGLE_DISABLE_ALL: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          isDisabled: action.disabled,
        },
      };
    }

    case KnowledgeActions.TOGGLE_SPEECH_SYNTHESIS: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          ttsEnabled: action.enabled,
        },
      };
    }

    case KnowledgeActions.TOGGLE_QUESTION_EDIT: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          editableQuestionId: state.currentBase.editableQuestionId === action.questionId ? null : action.questionId,
        },
      };
    }

    case KnowledgeActions.DROP_EDIT_QUESTION: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          editableQuestionId: InitialState.currentBase.editableQuestionId,
        },
      };
    }

    case KnowledgeActions.FETCH_BASE_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }

    case KnowledgeActions.FETCH_BASE_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        fetched: true,
        errors: errors,
      };
    }

    case KnowledgeActions.FETCH_BASE_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        baseList: action.payload.data,
      };
    }

    case KnowledgeActions.FETCH_ALL_QUESTIONS_PENDING: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          fetchingAll: true,
          fetchedAll: false,
          errorsAll: InitialState.currentBase.errorsAll,
        },
      };
    }

    case KnowledgeActions.FETCH_ALL_QUESTIONS_FULFILLED: {
      let currentPageQuestionList = mergeQuestions(action.payload.data.content, state.currentBase.questionListDiff);

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          fetchingAll: false,
          fetchedAll: true,
          // currentPageQuestionList: action.payload.data.content,
          currentPageQuestionList: currentPageQuestionList,
          questionList: action.payload.data.content,
        },
      };
    }

    case KnowledgeActions.FETCH_ALL_QUESTIONS_REJECTED: {
      const errors = parseErrors(action.payload.response);
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          fetchingAll: false,
          fetchedAll: true,
          errorsAll: errors,
        },
      };
    }

    case KnowledgeActions.PAGINATE_PENDING: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          fetching: true,
          fetched: false,
        },
      };
    }

    case KnowledgeActions.PAGINATE_FULFILLED: {
      let newState = { ...state };
      if (action.payload.config.drop) {
        newState = {
          ...newState,
          currentBase: {
            ...state.currentBase,
            searchedQuestionList: InitialState.currentBase.searchedQuestionList,
            editableQuestionId: InitialState.currentBase.editableQuestionId,
            questionList: InitialState.currentBase.questionList,
            questionListDiff: InitialState.currentBase.questionListDiff,
            searchedStr: InitialState.currentBase.searchedStr,
            currentPageQuestionList: [...action.payload.data.content],
            errors: InitialState.currentBase.errors,
            fetchedAll: InitialState.currentBase.fetchedAll,
            errorsAll: InitialState.currentBase.errorsAll,
            totalElements: InitialState.currentBase.totalElements,
            totalPages: action.payload.data.totalPages,
            last: action.payload.data.last,
            numberOfElements: InitialState.currentBase.numberOfElements,
            size: InitialState.currentBase.size,
            number: action.payload.data.number,
            snapshot: InitialState.currentBase.snapshot,
            isDisabled: state.currentBase.baseData.disabled,
            ttsEnabled: state.currentBase.baseData.ttsEnabled,
            fetching: false,
            fetched: true,
          },
        };
      } else {
        let newQuestions = mergeQuestions(action.payload.data.content, state.currentBase.questionListDiff);
        newState = {
          ...newState,
          currentBase: {
            ...state.currentBase,
            isDisabled: state.currentBase.baseData?.disabled,
            fetching: false,
            fetched: true,
            currentPageQuestionList: [...state.currentBase.currentPageQuestionList, ...newQuestions],
            last: action.payload.data.last,
            totalPages: action.payload.data.totalPages,
            number: action.payload.data.number,
          },
        };
      }

      return {
        ...state,
        ...newState,
      };
    }

    case KnowledgeActions.PAGINATE_REJECTED: {
      const errors = parseErrors(action.payload.response);

      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          fetching: false,
          fetched: true,
          errors: errors,
        },
      };
    }

    case KnowledgeActions.DROP_BASE: {
      return {
        ...state,
        currentBase: InitialState.currentBase,
      };
    }

    case KnowledgeActions.DROP_BASE_QUESTIONS: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          isDisabled: InitialState.currentBase.isDisabled,
          ttsEnabled: InitialState.currentBase.ttsEnabled,
          searchedQuestionList: InitialState.currentBase.searchedQuestionList,
          editableQuestionId: InitialState.currentBase.editableQuestionId,
          questionList: InitialState.currentBase.questionList,
          questionListDiff: InitialState.currentBase.questionListDiff,
          searchedStr: InitialState.currentBase.searchedStr,
          currentPageQuestionList: InitialState.currentBase.currentPageQuestionList,
          fetched: InitialState.currentBase.fetched,
          errors: InitialState.currentBase.errors,
          fetchedAll: InitialState.currentBase.fetchedAll,
          errorsAll: InitialState.currentBase.errorsAll,
          totalElements: InitialState.currentBase.totalElements,
          totalPages: InitialState.currentBase.totalPages,
          last: InitialState.currentBase.last,
          numberOfElements: InitialState.currentBase.numberOfElements,
          size: InitialState.currentBase.size,
          number: InitialState.currentBase.number,
          snapshot: InitialState.currentBase.snapshot,
        },
      };
    }

    case KnowledgeActions.SET_CURRENT_BASE: {
      return {
        ...state,
        currentBase: {
          ...state.currentBase,
          ttsEnabled: action.baseData?.ttsEnabled,
          baseData: action.baseData,
        },
      };
    }

    case KnowledgeActions.DROP_BASE_LIST: {
      return {
        ...state,
        baseList: InitialState.baseList,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

const mergeItems = (itemsList, diiList) => {
  const mergedItems = [...itemsList];
  diiList.forEach(diff => {
    if (typeof diff.id === 'string' && diff.id.startsWith('new_')) {
      //add new item
      mergedItems.push(diff);
    } else {
      const index = mergedItems.findIndex(item => {
        return diff.id === item.id;
      });
      if (index >= 0) {
        if (diff.hasOwnProperty('deleted') && diff.deleted) {
          //remove item
          mergedItems.splice(index, 1);
        } else {
          //merge items
          mergedItems[index] = {
            ...mergedItems[index],
            ...diff,
          };
        }
      }
    }
  });
  return mergedItems;
};

const replaceIds = (questionList, replaceMap) => {
  const replacedQuestionList = questionList.map(question => {
    const newQuestion = { ...question };
    if (replaceMap.hasOwnProperty(newQuestion.id)) {
      newQuestion.id = replaceMap[newQuestion.id];
    }

    let newAnswers = newQuestion.answers.filter(answer => {
      return (
        !Boolean(answer.answer) ||
        (Boolean(answer.answer) && answer.answer.length > 0 && answer.answer !== ' ' && answer.answer !== '\n')
      );
    });

    newAnswers = newAnswers.map(answer => {
      const newAnswer = { ...answer };
      if (replaceMap.hasOwnProperty(newAnswer.id)) {
        newAnswer.id = replaceMap[newAnswer.id];
      }
      return newAnswer;
    });

    newQuestion.answers = newAnswers;

    let newSynonyms = newQuestion.synonyms.filter(synonym => {
      return (
        !Boolean(synonym.synonym) ||
        (Boolean(synonym.synonym) && synonym.synonym.length > 0 && synonym.synonym !== ' ' && synonym.synonym !== '\n')
      );
    });

    newSynonyms = newSynonyms.map(synonym => {
      const newSynonym = { ...synonym };
      if (replaceMap.hasOwnProperty(newSynonym.id)) {
        newSynonym.id = replaceMap[newSynonym.id];
      }
      return newSynonym;
    });

    newQuestion.synonyms = newSynonyms;

    return newQuestion;
  });

  return replacedQuestionList;
};

const mergeQuestions = (questionList, diffList) => {
  const mergedQuestionList = [...questionList];

  diffList.forEach(questionDiff => {
    if (typeof questionDiff.id === 'string' && questionDiff.id.startsWith('new_question_')) {
      //add new question
      mergedQuestionList.unshift(questionDiff);
    } else {
      const index = mergedQuestionList.findIndex(question => {
        return questionDiff.id === question.id;
      });
      if (index >= 0) {
        if (questionDiff.hasOwnProperty('deleted') && questionDiff.deleted) {
          //remove question
          mergedQuestionList.splice(index, 1);
        } else {
          //deep merge question
          const newQuestion = { ...mergedQuestionList[index] };
          if (questionDiff.hasOwnProperty('answers') && questionDiff.answers.length > 0) {
            newQuestion.answers = mergeItems(newQuestion.answers, questionDiff.answers);
          }
          if (questionDiff.hasOwnProperty('synonyms') && questionDiff.synonyms.length > 0) {
            newQuestion.synonyms = mergeItems(newQuestion.synonyms, questionDiff.synonyms);
          }
          if (questionDiff.hasOwnProperty('question')) {
            newQuestion.question = questionDiff.question;
          }
          if (questionDiff.hasOwnProperty('disabled')) {
            newQuestion.disabled = questionDiff.disabled;
          }
          mergedQuestionList[index] = newQuestion;
        }
      }
    }
  });

  return mergedQuestionList;
};

const filteredQuestionList = (questionList, searchedStr) => {
  return questionList.filter(question => {
    let containsSearchStr = false;
    if (question.question.toLowerCase().includes(searchedStr.toLowerCase())) {
      containsSearchStr = true;
    } else {
      containsSearchStr = question.answers.some(answer => {
        return answer.answer.toLowerCase().includes(searchedStr.toLowerCase());
      });
      if (!containsSearchStr) {
        containsSearchStr = question.synonyms.some(synonym => {
          return synonym.synonym.toLowerCase().includes(searchedStr.toLowerCase());
        });
      }
    }
    return containsSearchStr;
  });
};

const getItemsArrayDiff = (oldArr, newArr) => {
  const diff = [];

  const isSameItem = (oldArrItem, newArrItem) => {
    return oldArrItem.id === newArrItem.id;
  };

  let deletedItems = differenceWith(oldArr, newArr, isSameItem);
  const addedItems = differenceWith(newArr, oldArr, isSameItem);

  let changedItems = differenceWith(newArr, oldArr, isEqual);
  changedItems = differenceWith(changedItems, deletedItems, isEqual);
  changedItems = differenceWith(changedItems, addedItems, isEqual);

  deletedItems = deletedItems.map(item => {
    return {
      id: item.id,
      deleted: true,
    };
  });

  diff.push(...deletedItems, ...addedItems, ...changedItems);

  return diff;
};
