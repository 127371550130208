import {
  FILTER_LISTS_PENDING,
  FILTER_LISTS_FULFILLED,
  FILTER_LISTS_REJECTED,
  FILTER_CLIENTS_PENDING,
  FILTER_CLIENTS_FULFILLED,
  FILTER_CLIENTS_REJECTED,
  UPLOAD_FULFILLED,
  UPLOAD_PENDING,
  UPLOAD_REJECTED,
  FETCH_CLIENTS_PENDING,
  FETCH_CLIENTS_FULFILLED,
  FETCH_CLIENTS_REJECTED,
  SEARCH_CLIENTS,
  DROP_SEARCH_RESULTS,
  SEARCH_MESSENGER_CLIENTS,
  DROP_MESSENGER_CLIENTS_SEARCH_RESULTS,
} from '../../constants/clients.actions';
import { parseErrors } from '../../pipes/pureFunctions';
import moment from 'moment';

const InitialState = {
  fetching: false,
  fetched: false,
  errors: [],
  clientsAmount: [],
  lists: [],
  listDetail: {
    clients: [],
    fetching: false,
    fetched: false,
    errors: [],
    unfilteredClients: [],
    totalSize: 0,
    id: null,
    name: '',
  },
  clients: {
    listClients: [],
    unfilteredListClients: [],
    fetching: false,
    fetched: false,
    errors: [],
  },
  filter: null,
};

export default function ClientsReducer(state = InitialState, action) {
  switch (action.type) {
    case UPLOAD_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }

    case UPLOAD_FULFILLED: {
      let lists = state.lists;

      lists.splice(0, 0, action.payload.data.clientList);

      return {
        ...state,
        fetching: false,
        fetched: true,
        lists: lists,
      };
    }

    case FILTER_LISTS_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
        listDetail: InitialState.listDetail,
      };
    }

    case FILTER_LISTS_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        filter: action.payload.config.filter,
        lists: action.payload.data.lists,
        clientsAmount: action.payload.data.clientsAmount,
      };
    }

    case UPLOAD_REJECTED:
    case FILTER_LISTS_REJECTED: {
      let errors = parseErrors(action.payload.response);

      return {
        ...state,
        fetching: false,
        fetched: true,
        errors: errors,
      };
    }

    case FILTER_CLIENTS_PENDING: {
      return {
        ...state,
        clients: {
          ...state.clients,
          fetching: true,
          fetched: false,
        },
      };
    }

    case FILTER_CLIENTS_REJECTED: {
      let errors = parseErrors(action.payload.response);

      return {
        ...state,
        clients: {
          ...state.clients,
          fetching: false,
          fetched: true,
          errors: errors,
        },
      };
    }

    case FILTER_CLIENTS_FULFILLED: {
      return {
        ...state,
        filter: action.payload.config.filter,
        clients: {
          ...state.clients,
          fetching: false,
          fetched: true,
          listClients: action.payload.data,
          unfilteredListClients: action.payload.data,
        },
      };
    }

    case FETCH_CLIENTS_REJECTED: {
      let errors = parseErrors(action.payload.response);

      return {
        ...state,
        listDetail: {
          ...state.listDetail,
          fetching: false,
          fetched: true,
          errors: errors,
        },
      };
    }

    case FETCH_CLIENTS_PENDING: {
      return {
        ...state,
        listDetail: {
          ...state.listDetail,
          fetching: true,
          fetched: false,
        },
      };
    }

    case FETCH_CLIENTS_FULFILLED: {
      return {
        ...state,
        listDetail: {
          ...state.listDetail,
          clients: action.payload.data.clients,
          unfilteredClients: action.payload.data.clients,
          totalSize: action.payload.data.totalSize,
          id: action.payload.data.id,
          name: action.payload.data.name,
          fetching: false,
          fetched: true,
        },
      };
    }

    case SEARCH_CLIENTS: {
      let clients = state.listDetail.unfilteredClients;

      clients = clients.filter(client => {
        return (
          (Boolean(client.fullName) && client.fullName.includes(action.searchStr)) ||
          (Boolean(client.city) && client.city.includes(action.searchStr)) ||
          (Boolean(client.phoneNumber) && client.phoneNumber.includes(action.searchStr)) ||
          (Boolean(client.birthDate) && moment(client.birthDate).format('DD.MM.YYYY').includes(action.searchStr))
        );
      });

      return {
        ...state,
        listDetail: {
          ...state.listDetail,
          clients: clients,
        },
      };
    }

    case DROP_SEARCH_RESULTS: {
      return {
        ...state,
        listDetail: {
          ...state.listDetail,
          clients: state.listDetail.unfilteredClients,
        },
      };
    }

    case SEARCH_MESSENGER_CLIENTS: {
      let listClients = state.clients.unfilteredListClients;

      listClients = listClients.filter(client => {
        return (
          (Boolean(client.fullName) && client.fullName.includes(action.searchStr)) ||
          (Boolean(client.city) && client.city.includes(action.searchStr)) ||
          (Boolean(client.phoneNumber) && client.phoneNumber.includes(action.searchStr)) ||
          (Boolean(client.birthDate) && moment(client.birthDate).format('DD.MM.YYYY').includes(action.searchStr))
        );
      });

      return {
        ...state,
        clients: {
          ...state.clients,
          listClients: listClients,
        },
      };
    }

    case DROP_MESSENGER_CLIENTS_SEARCH_RESULTS: {
      return {
        ...state,
        clients: {
          ...state.clients,
          listClients: state.clients.unfilteredListClients,
        },
      };
    }

    default: {
      return state;
    }
  }
}
