import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { ProjectSkillDataUpdate } from '@just-ai/api/dist/generated/Editorbe';
import { ProjectSkillStatusEnum } from '@just-ai/api/dist/generated/Botadmin';
import { Spinner, usePromisifyComponent } from '@just-ai/just-ui';

import useSyncCurrentProjectInState from 'helpers/hooks/useSyncCurrentProjectInState';
import TemplatesWizard from 'modules/TemplatesWizard';
import { useProjectSkill } from 'modules/TemplatesWizard/hooks/useProjectSkill';

import { useWizardHeaderContext } from '../ui';
import PreventLeaveIfUnsavedSkillsModal from './modals/PreventLeaveIfUnsavedSkillsModal';
import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

function usePreventLeaveIfSkillProjectDoesNotFilled(changesUnsaved: boolean) {
  const history = useHistory();

  const [preventLeaveModalNode, askIfTheUserWantsToLeaveThePage] = usePromisifyComponent(
    (resolve, reject, opened) => (
      <PreventLeaveIfUnsavedSkillsModal isOpen={opened} onCancel={resolve} onSubmit={reject} />
    ),
    undefined,
    []
  );
  useEffect(() => {
    if (!changesUnsaved) return;
    const unblock = history.block((tx, action) => {
      askIfTheUserWantsToLeaveThePage().then(() => {
        unblock();
        history.push(tx.pathname);
      });
      return false;
    });

    const beforeUnloadListener = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      unblock?.();
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [history, changesUnsaved, askIfTheUserWantsToLeaveThePage]);

  return { preventLeaveModalNode };
}

const SetupSkills = () => {
  const pageParams = useParams<{ projectShortName: string }>();
  const { updateCurrentProject } = useSyncCurrentProjectInState(pageParams.projectShortName);
  const { state } = useLocation<{ selectedSkills: string[] }>();
  const { skillProject, registeredSkills, updateProjectSkill, loading } = useProjectSkill(pageParams.projectShortName);
  const [changesUnsaved, setChangesUnsaved] = useState(true);

  const { preventLeaveModalNode } = usePreventLeaveIfSkillProjectDoesNotFilled(changesUnsaved);

  useEffect(() => {
    if (!isEmpty(skillProject?.envs)) setChangesUnsaved(false);
  }, [skillProject?.envs]);

  const { setBackPath } = useWizardHeaderContext();
  useEffect(() => {
    setBackPath('/');
  }, [setBackPath]);

  const initialSkills = useMemo(() => {
    if (!skillProject) return;
    if (skillProject.skills.length > 0) return skillProject.skills;
    return state ? registeredSkills?.filter(skill => state?.selectedSkills.includes(skill.name)) : [];
  }, [registeredSkills, skillProject, state]);

  const updateProjectSkillInner = useCallback(
    async (projectSkillData: ProjectSkillDataUpdate, commit?: boolean) => {
      await updateProjectSkill(projectSkillData, commit);
      setChangesUnsaved(false);
      const projectSkillStatus = projectSkillData.status?.status as unknown as ProjectSkillStatusEnum;
      if (!projectSkillStatus) return;
      updateCurrentProject({ projectSkillStatus });
    },
    [updateProjectSkill, updateCurrentProject]
  );

  if (!initialSkills || !skillProject?.envs || !registeredSkills) return null;

  return (
    <div className={styles.SetupSkills}>
      {loading && <Spinner />}
      <TemplatesWizard
        initialSkills={initialSkills}
        onChange={updateProjectSkillInner}
        values={skillProject?.envs}
        registeredSkills={registeredSkills}
      />
      {preventLeaveModalNode}
    </div>
  );
};

export default React.memo(SetupSkills);
