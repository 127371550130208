import {
  LIST_SIP_TRUNK_FULFILLED,
  AVAILABLE_CODECS_FULFILLED,
  CREATE_SIP_TRUNK_FULFILLED,
  LIST_SIP_TRUNK_PENDING,
  CREATE_SIP_TRUNK_PENDING,
  AVAILABLE_CODECS_PENDING,
  READ_SIP_TRUNK_FULFILLED,
  READ_SIP_TRUNK_PENDING,
  UNSET_EDITABLE_SIP_TRUNK,
  EDIT_SIP_TRUNK_PENDING,
  EDIT_SIP_TRUNK_FULFILLED,
  DELETE_SIP_TRUNK_PENDING,
  DELETE_SIP_TRUNK_FULFILLED,
  CREATE_SIP_TRUNK_REJECTED,
  DELETE_SIP_TRUNK_REJECTED,
  EDIT_SIP_TRUNK_REJECTED,
} from '../../constants/sipTrunkList.actions';

const InitialState = {
  sipTrunkList: [],
  availableCodecs: [],
  fetching: false,
  readFetching: false,
  editableSipTrunk: null,
  errorHtml: '',
  fetchingModal: false,
};

export default function sipTrunkListReducer(state = InitialState, action) {
  switch (action.type) {
    case LIST_SIP_TRUNK_PENDING:
      return {
        ...state,
        fetching: true,
        errorHtml: '',
      };

    case LIST_SIP_TRUNK_FULFILLED:
      return {
        ...state,
        sipTrunkList: action.payload.data,
        fetching: false,
      };

    case AVAILABLE_CODECS_PENDING: {
      return {
        ...state,
        fetchingModal: true,
      };
    }

    case AVAILABLE_CODECS_FULFILLED: {
      return {
        ...state,
        availableCodecs: action.payload.data,
        fetchingModal: false,
        errorHtml: '',
      };
    }

    case CREATE_SIP_TRUNK_PENDING: {
      return {
        ...state,
        fetchingModal: true,
        errorHtml: '',
      };
    }

    case CREATE_SIP_TRUNK_REJECTED: {
      return {
        ...state,
        fetchingModal: false,
        errorHtml: action.payload.response.data.error,
      };
    }

    case CREATE_SIP_TRUNK_FULFILLED: {
      let newSipTrunkList = [...state.sipTrunkList, action.payload.data];

      return {
        ...state,
        fetchingModal: false,
        sipTrunkList: newSipTrunkList,
      };
    }

    case READ_SIP_TRUNK_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case READ_SIP_TRUNK_FULFILLED: {
      return {
        ...state,
        editableSipTrunk: action.payload.data,
        fetching: false,
      };
    }

    case EDIT_SIP_TRUNK_PENDING: {
      return {
        ...state,
        fetchingModal: true,
        errorHtml: '',
      };
    }

    case EDIT_SIP_TRUNK_REJECTED: {
      return {
        ...state,
        fetchingModal: false,
        errorHtml: action.payload.response.data.error,
      };
    }

    case EDIT_SIP_TRUNK_FULFILLED: {
      let newSipTrunkList = [...state.sipTrunkList];
      const sipTrunkIndex = newSipTrunkList.findIndex(trunk => trunk.id === action.payload.data.id);
      if (sipTrunkIndex !== -1) newSipTrunkList.splice(sipTrunkIndex, 1, action.payload.data);
      return {
        ...state,
        fetchingModal: false,
        sipTrunkList: newSipTrunkList,
        errorHtml: '',
      };
    }

    case UNSET_EDITABLE_SIP_TRUNK: {
      return {
        ...state,
        editableSipTrunk: null,
      };
    }

    case DELETE_SIP_TRUNK_PENDING: {
      return {
        ...state,
        fetchingModal: true,
        errorHtml: '',
      };
    }

    case DELETE_SIP_TRUNK_REJECTED: {
      return {
        ...state,
        fetchingModal: false,
        errorHtml: action.payload.response.data.error,
      };
    }

    case DELETE_SIP_TRUNK_FULFILLED: {
      let sipTrunkList = [...state.sipTrunkList];
      let index = sipTrunkList.findIndex(trunk => trunk.id === action.payload.data.id);
      sipTrunkList.splice(index, 1);
      return {
        ...state,
        fetchingModal: false,
        errorHtml: '',
        sipTrunkList: sipTrunkList,
      };
    }

    default:
      return state;
  }
}
