import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import localize from '../../../../localization';
import { withStyles } from 'altreidsds';
import { ChannelIcon } from '@just-ai/just-ui';
import DefaultEditForm from './DefaultEditForm';
import YandexEditForm from './YandexEditForm';
import ViberPublicEditForm from './ViberPublicEditForm';
import ChatWidgetEditForm from './ChatWidgetEditForm';
import WeChatEditForm from './WeChatEditForm';
import GoogleEditForm from './GoogleEditForm';
import ChatApiEditForm from './ChatApiEditForm';
import Bitrix24EditForm from './Bitrix24EditForm';
import ViberEditForm from './ViberEditForm';
import AzureEditForm from './AzureEditForm';
import IncomingWebim2EditForm from './IncomingWebim2EditForm';
import WhatsAppEditForm from './WhatsAppEditForm';
import LivetexEditForm from './LivetexEditForm';
import InboundLivetexEditForm from './InboundLivetexEditForm';
import OperatorWebimEditForm from './OperatorWebimEditForm';
import OperatorApiEditForm from './OperatorApiEditForm';

import { withRouter } from 'react-router-dom';
import { getChannelTypes, isEuroInstance } from '../../../../isAccessFunction';
import AtreidesTypo from 'altreidsds/src/lib/Typography';
import HelpLink from '../../../../components/HelpLink';
import { getChannelIconType } from '../../../../constants/channelTypes';
import ThreadsEditForm from './ThreadsEditForm';
import AimychatEditForm from './AimychatEditForm';
import FacebookEditForm from './FacebookEditForm';
import InstagramEditForm from './InstagramEditForm';
import TelegramEditForm from './TelegramEditForm';

const { translate } = localize;

class ChannelEditForm extends PureComponent {
  form = {};

  createChannel = channelData => this.props.actions.createChannel(channelData);

  removeChannel = channelData => this.props.actions.removeChannel(channelData);

  editChannel = channelData => this.props.actions.saveEditableChannel(channelData);

  createOperatorChannel = channelData => this.props.actions.createChannel(channelData, true);

  editOperatorChannel = channelData => this.props.actions.saveEditableChannel(channelData, true);

  renderChannelForm = () => {
    const { classes, editableChannel, errors, fetching, appConfig, onClose, open, currentBot, operatorChannels } =
      this.props;

    const {
      channelType,
      type: operatorChannelType,
      id,
      botName,
      senderName,
      description,
      widgetOptions,
    } = editableChannel || {};

    if (Boolean(channelType || operatorChannelType) === false || !open) {
      return null;
    }

    let type = (channelType || operatorChannelType).toLowerCase();
    let channelTypeCustom = channelType;
    if (isEuroInstance() && type === 'aimychat') {
      channelTypeCustom = 'AIMYCHAT:Tovie';
    }
    const dialogHeadline = (
      <AtreidesTypo type='h2' variant='bold' noMargin>
        {Boolean(id)
          ? translate(
              'Edit channel $[1]',
              botName ||
                senderName ||
                description ||
                translate(`AddChannelCard ${channelTypeCustom || operatorChannelType}`)
            )
          : translate(`AddChannelCard ${channelTypeCustom}`)}
        <HelpLink helpLink={type} type='modalTitle' />
      </AtreidesTypo>
    );

    const headlineIcon =
      channelType === getChannelTypes().CHAT_WIDGET && Boolean(widgetOptions) && Boolean(widgetOptions.logo) ? (
        <img src={widgetOptions.logo} className={classes.widgetLogoIcon} alt='logo' />
      ) : (
        <ChannelIcon
          type={getChannelIconType(channelType || operatorChannelType)}
          size='large'
          isEuroInstance={isEuroInstance()}
        />
      );

    const commonProps = {
      open,
      onClose,
      dialogHeadline,
      headlineIcon,
      editableChannel,
      errors,
      fetching,
      createChannel: this.createChannel,
      removeChannel: this.removeChannel,
      editChannel: this.editChannel,
      cancelEdit: onClose,
      appConfig,
    };

    const webimChannel =
      editableChannel.id && editableChannel.type === 'WEBIM' && operatorChannels.find(x => x.id === editableChannel.id);

    switch (type) {
      case 'wechat': {
        return <WeChatEditForm {...commonProps} />;
      }
      case 'nexmo':
      case 'i_digital':
      case 'whatsapp': {
        return <WhatsAppEditForm {...commonProps} />;
      }
      case 'chat_widget': {
        return <ChatWidgetEditForm {...commonProps} />;
      }
      case 'yandex': {
        return <YandexEditForm {...commonProps} />;
      }
      case 'viber_public': {
        return <ViberPublicEditForm {...commonProps} />;
      }
      case 'google': {
        return <GoogleEditForm {...commonProps} />;
      }
      case 'chat_api': {
        return <ChatApiEditForm {...commonProps} />;
      }
      case 'telegram': {
        return <TelegramEditForm {...commonProps} />;
      }
      case 'bitrix': {
        return (
          <Bitrix24EditForm
            {...commonProps}
            location={this.props.location}
            addSnackbar={this.props.actions.addSnackbar}
            saveUrlImageToServer={this.props.actions.saveUrlImageToServer}
            currentBot={currentBot}
          />
        );
      }
      case 'azure': {
        return (
          <AzureEditForm
            key={`${editableChannel.channelType}_edit_form${editableChannel.id}`}
            {...commonProps}
            currentBot={currentBot}
          />
        );
      }
      case 'viber': {
        return <ViberEditForm key={`${editableChannel.channelType}_edit_form${editableChannel.id}`} {...commonProps} />;
      }
      case 'incoming_webim2': {
        return (
          <IncomingWebim2EditForm
            key={`${editableChannel.channelType}_edit_form${editableChannel.id}`}
            {...commonProps}
          />
        );
      }
      case 'webim': {
        return (
          <OperatorWebimEditForm
            {...commonProps}
            createChannel={this.createOperatorChannel}
            editChannel={this.editOperatorChannel}
            addSnackbar={this.props.actions.addSnackbar}
            appConfig={appConfig}
            editableChannel={webimChannel || editableChannel}
          />
        );
      }
      case 'livetex': {
        return (
          <LivetexEditForm
            {...commonProps}
            key={`${editableChannel.channelType}_edit_form${editableChannel.id}`}
            addDestination={this.props.actions.addDestination}
            removeGroup={this.props.actions.removeGroup}
            selectIDS={this.props.actions.selectIDS}
            createChannel={this.createOperatorChannel}
            editChannel={this.editOperatorChannel}
            appConfig={appConfig}
          />
        );
      }
      case 'inbound_livetex': {
        return <InboundLivetexEditForm {...commonProps} />;
      }
      case 'threads': {
        return (
          <ThreadsEditForm key={`${editableChannel.channelType}_edit_form${editableChannel.id}`} {...commonProps} />
        );
      }
      case 'aimychat': {
        return (
          <AimychatEditForm
            key={`${editableChannel.channelType}_edit_form${editableChannel.id}`}
            {...commonProps}
            createChannel={this.createOperatorChannel}
            editChannel={this.editOperatorChannel}
          />
        );
      }
      case 'operatorapi': {
        return (
          <OperatorApiEditForm
            key={`${editableChannel.channelType}_edit_form${editableChannel.id}`}
            {...commonProps}
            addDestination={this.props.actions.addDestination}
            removeGroup={this.props.actions.removeGroup}
            selectIDS={this.props.actions.selectIDS}
            createChannel={this.createOperatorChannel}
            editChannel={this.editOperatorChannel}
          />
        );
      }
      case 'facebook': {
        return this.props.appConfig.metaSettings?.enabled ? (
          <FacebookEditForm key={`${channelType}_edit_form${id}`} {...commonProps} />
        ) : (
          <DefaultEditForm key={`${channelType}_edit_form${id}`} {...commonProps} />
        );
      }

      case 'instagram': {
        return this.props.appConfig.metaSettings?.enabled ? (
          <InstagramEditForm key={`${channelType}_edit_form${id}`} {...commonProps} />
        ) : (
          <DefaultEditForm key={`${channelType}_edit_form${id}`} {...commonProps} />
        );
      }

      default: {
        return <DefaultEditForm key={`${channelType}_edit_form${id}`} {...commonProps} />;
      }
    }
  };

  render() {
    return this.renderChannelForm();
  }
}

ChannelEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    errors: [...state.ChannelDetailReducer.errors, ...state.OperatorChannelsReducer.errors],
    fetching: state.ChannelDetailReducer.fetching,
    chatWidgetThemes: state.ChannelDetailReducer.chatWidgetThemes,
    editableChannel: state.ChannelDetailReducer.editableChannel,
    operatorChannels: state.OperatorChannelsReducer.channels,
    implementationScript: state.ChannelDetailReducer.implementationScript,
    webhook: state.ChannelDetailReducer.webhook,
    currentBot: state.CurrentProjectsReducer.currentBot,
    currentUser: state.CurrentUserReducer.currentUser,
  };
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  dialogPaper: {
    minWidth: '650px',
  },
  dialogHeadline: {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: 'var(--gray-700)',
  },
  widgetLogoIcon: {
    width: '27px',
    height: '27px',
    borderRadius: '50%',
  },
});

export default withRouter(connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(ChannelEditForm)));
