import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SnackbarsActions from '../../actions/snackbars.actions';

import { withStyles, SnackbarContainer } from 'altreidsds';

const styles = theme => ({
  snackbarContainer: {
    padding: '1em',
    zIndex: 3000,
  },
  snackbar: {
    marginBottom: '.75em',
    wordWrap: 'wrap',
    wordBreak: 'break-all',
  },
  snackbarHideAll: {
    marginBottom: '.75em',
    textAlign: 'center',
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
    padding: 0,
  },
});

class SnackbarContainerWrapper extends Component {
  removeSnackbar = id => {
    const { dismissSnackbar } = this.props.actions;
    dismissSnackbar(id);
  };

  handleCloseAll = () => {
    const { dismissAllSnackbars } = this.props.actions;
    dismissAllSnackbars();
  };

  render() {
    const { classes, snackbars, className } = this.props;

    return <SnackbarContainer removeSnackbar={this.removeSnackbar} snackbars={snackbars} />;
  }
}

function mapStateToProps(state) {
  return {
    snackbars: state.SnackbarsReducer.snackbars,
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(SnackbarsActions, dispatch),
});

SnackbarContainerWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SnackbarContainerWrapper));
