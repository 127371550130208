import React from 'react';
import { Grid, AtreidesTypo, withStyles } from 'altreidsds';
import Robot404 from './robot404';
import localize from '../../localization';

const page403 = props => {
  const { classes } = props;
  return (
    <Grid container alignItems='center' justify='center' className={classes.container404}>
      <Grid item lg={3} md={5} xs={12} align='center'>
        <Robot404 />
        <AtreidesTypo type='h1' variant='bold'>
          {localize.translate('Page 403 Access denied')}
        </AtreidesTypo>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  container404: {
    height: 'calc(100vh - 170px)',

    '& svg': {
      width: '267px',
      maxWidth: '100%',
      height: 'auto',
    },

    '& .h1': {
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 1.5,
      whiteSpace: 'nowrap',
      '@media(max-width: 560px)': {
        whiteSpace: 'normal',
      },
    },

    '& .p': {
      marginTop: '0px',
      marginBottom: theme.spacing.unit * 4,
    },
  },
});

export default withStyles(styles, { withTheme: true })(page403);
