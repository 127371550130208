import { AnswerReply, AnswerReplyType, TAnswerPart } from '@just-ai/just-ui/dist/RichTextEditor/types';
import { t } from '../../../../../localization';

export const answerPartsToReplies = (answerParts: TAnswerPart[]): AnswerReply[] => {
  const getReplyByAnswerType = (part: TAnswerPart): AnswerReply => {
    switch (part.type) {
      case AnswerReplyType.text:
        return {
          type: part.type,
          text: part.value,
          markup: part.markup,
          key: part.id,
        };
      case AnswerReplyType.file:
        return {
          type: part.type,
          fileUrl: part.value,
          fileName: part.fileName,
          uploadDate: part.uploadDate,
          key: part.id,
        };
      case AnswerReplyType.audio:
        return {
          type: part.type,
          audioUrl: part.value,
          audioName: part.audioName || part.fileName,
          uploadDate: part.uploadDate,
          key: part.id,
        };
      case AnswerReplyType.image:
        return {
          type: part.type,
          imageUrl: part.value,
          uploadDate: part.uploadDate,
          key: part.id,
        };
      default:
        return {
          type: part.type,
          text: part.value,
          key: part.id,
        };
    }
  };
  return answerParts.map(part => getReplyByAnswerType(part));
};
const tenMbInBites = 10_485_760;
export const validateFile = (type: TAnswerPart['type'], file: File) => {
  switch (type) {
    case AnswerReplyType.image:
      if (file.size > tenMbInBites) {
        throw new Error(t('AudioUploadField:Error:FileSize'));
      }
      if (!file.type.includes('image')) {
        throw new Error(t('AudioUploadField:Error:InvalidImageExtension'));
      }
      return;
    case AnswerReplyType.file:
      if (file.size > tenMbInBites) {
        throw new Error(t('AudioUploadField:Error:FileSize'));
      }
      return;
    default:
      break;
  }
};
