import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { ProjectSkillRead } from '@just-ai/api/dist/generated/Editorbe';
import { Modal } from '@just-ai/just-ui';

import { t } from 'localization';
import { GA } from 'pipes/pureFunctions';

import { BaseSkill } from 'modules/TemplatesWizard/skillClasses/BaseSkill';
import { whichSkillCanBeAdded } from 'modules/TemplatesWizard/skillClasses/skillBuilder';
import { WizardCard } from 'modules/TemplatesWizard/router/wizard-skill-card';

import { RealSkill, SkillConfig } from '../../types';

import skills from './styles.module.scss';

type AddNewSkillModalProps = {
  onSubmit: (skills: SkillConfig[]) => void;
  onCancel: () => void;
  isOpen: boolean;
  currentSkills: BaseSkill[];
  registeredSkills: ProjectSkillRead[];
};
function AddNewSkillModal({ isOpen, onSubmit, onCancel, currentSkills, registeredSkills }: AddNewSkillModalProps) {
  const [selectedSkills, setSelectedSkills] = useState(() => new Set<SkillConfig>());

  const onToggle = useCallback(
    (skill: SkillConfig) => {
      setSelectedSkills(selected => {
        const current = Array.from(selectedSkills.values()).find(el => el.skillName === skill.skillName);
        if (current) {
          GA(undefined, 'Skill_Remove', undefined, current.skillName);
          selected.delete(current);
        } else {
          GA(undefined, 'Skill_Add', undefined, skill.skillName);
          selected.add(skill);
        }
        return new Set(selected);
      });
    },
    [selectedSkills]
  );

  const onSubmitInner = useCallback(() => {
    onSubmit(Array.from(selectedSkills.values()));
    onCancel();
  }, [onCancel, onSubmit, selectedSkills]);

  useEffect(() => {
    setSelectedSkills(new Set());
  }, [isOpen]);

  const availableSkills = useMemo(() => {
    if (!registeredSkills) return [];
    return whichSkillCanBeAdded(
      currentSkills
        .map(skill => skill.skillConfig.skillName as RealSkill)
        .filter(skillName => Object.values(RealSkill).includes(skillName)),
      Array.from(selectedSkills.values()).map(skill => skill.skillName) as RealSkill[],
      registeredSkills
    );
  }, [currentSkills, registeredSkills, selectedSkills]);

  return (
    <Modal
      size='lg'
      isOpen={isOpen}
      title={t('SkillsWizard:AddNewSkillModal:Title')}
      buttonSubmitText={t('SkillsWizard:AddNewSkillModal:Submit')}
      buttonCancelText={t('SkillsWizard:AddNewSkillModal:Cancel')}
      buttonSubmitColor='primary'
      buttonSubmitDisabled={selectedSkills.size === 0}
      onCancelClick={onCancel}
      onActionClick={onSubmitInner}
      centered
    >
      {availableSkills.length > 0 ? (
        <div className={skills.AddNewSkillModal}>
          {availableSkills.map(({ skillConfig, canFix, enabled, denyCauses }) => (
            <WizardCard
              key={skillConfig.skillName}
              title={skillConfig.title}
              description={skillConfig.shortDescription}
              selected={!!Array.from(selectedSkills.values()).find(el => el.skillName === skillConfig.skillName)}
              disabled={!enabled}
              skillId={skillConfig.skillName}
              isSpecial={skillConfig.isSpecial}
              denyCauses={denyCauses}
              canFix={canFix}
              onSelect={() => onToggle(skillConfig)}
              forceSelect={() =>
                setSelectedSkills(selected => {
                  denyCauses?.forEach(el => selected.delete(el));
                  selected.add(skillConfig);
                  return new Set(selected);
                })
              }
              hideTestButton
            />
          ))}
        </div>
      ) : (
        <div>{t('SkillsWizard:AddNewSkillModal:Empty')}</div>
      )}
    </Modal>
  );
}

export default React.memo(AddNewSkillModal);
