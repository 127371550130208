import { generateId } from '../../../../../components/Wysiwyg/utils';
import { createContext, useContext } from 'react';
import { AnswerReply } from '@just-ai/just-ui/dist/RichTextEditor/types';

export class MessageType {
  uuid: string;
  when: 'afterPrevious' | 'afterTime';
  time: {
    day: number;
    hour: number;
    minute: number;
    second: number;
  };
  replies: AnswerReply[];

  constructor() {
    this.uuid = generateId();
    this.when = 'afterPrevious';
    this.time = {
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
    };
    this.replies = [];
  }
}

export type TAutomessageSequenceContext = {
  addMessage: () => unknown;
  onChange: (index: number, partialMessage: Partial<MessageType>) => unknown;
  deleteMessageItem: (index: number) => unknown;
  resortMessages: (messages: MessageType[]) => unknown;
};
export const AutomessageSequenceContext = createContext<TAutomessageSequenceContext>({} as TAutomessageSequenceContext);
export const useAutomessageSequenceContext = () => useContext(AutomessageSequenceContext);
