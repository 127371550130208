import * as EntitiesActions from '../../constants/entities.actions';
// import localize from "../../localization";

const InitialState = {
  entities: [],
  systemEntities: [],
  detailEntity: [],
  addingEntity: false,
  fetchingEntities: false,
  fetchingSystemEntities: false,
  fetchingDetailEntity: false,
  deleteDetailEntity: false,
};

export default function EntitiesReducer(state = InitialState, action) {
  switch (action.type) {
    case EntitiesActions.LOAD_SYSTEM_ENTITIES_FULFILLED: {
      return {
        ...state,
        systemEntities: action.payload.data,
        fetchingSystemEntities: false,
      };
    }
    case EntitiesActions.LOAD_SYSTEM_ENTITIES_REJECTED: {
      return {
        ...state,
        fetchingSystemEntities: false,
      };
    }
    case EntitiesActions.LOAD_SYSTEM_ENTITIES_PENDING: {
      return {
        ...state,
        fetchingSystemEntities: true,
      };
    }

    case EntitiesActions.LOAD_DETAIL_ENTITY_PENDING: {
      return {
        ...state,
        fetchingDetailEntity: true,
      };
    }
    case EntitiesActions.LOAD_DETAIL_ENTITY_REJECTED: {
      return {
        ...state,
        fetchingDetailEntity: false,
        detailEntity: InitialState.detailEntity,
      };
    }
    case EntitiesActions.LOAD_DETAIL_ENTITY_FULFILLED: {
      return {
        ...state,
        fetchingDetailEntity: false,
        detailEntity: action.payload.data,
      };
    }
    case EntitiesActions.ADD_ENTITY_PENDING: {
      return {
        ...state,
        addingEntity: true,
      };
    }
    case EntitiesActions.ADD_ENTITY_REJECTED: {
      return {
        ...state,
        entities: state.entities,
        addingEntity: false,
      };
    }
    case EntitiesActions.ADD_ENTITY_FULFILLED: {
      return {
        ...state,
        addingEntity: false,
      };
    }

    case EntitiesActions.LOAD_ENTITIES_PENDING: {
      return {
        ...state,
        fetchingEntities: true,
      };
    }
    case EntitiesActions.LOAD_ENTITIES_REJECTED: {
      return {
        ...state,
        entities: InitialState.entities,
        fetchingEntities: false,
      };
    }
    case EntitiesActions.LOAD_ENTITIES_FULFILLED: {
      return {
        ...state,
        entities: action.payload.data,
        fetchingEntities: false,
      };
    }

    case EntitiesActions.SAVE_DETAIL_ENTITY_NAME_PENDING:
    case EntitiesActions.SAVE_DETAIL_ENTITY_PENDING: {
      return {
        ...state,
        fetchingDetailEntity: true,
      };
    }

    case EntitiesActions.SAVE_DETAIL_ENTITY_NAME_REJECTED:
    case EntitiesActions.SAVE_DETAIL_ENTITY_REJECTED: {
      return {
        ...state,
        fetchingDetailEntity: false,
      };
    }

    case EntitiesActions.SAVE_DETAIL_ENTITY_FULFILLED: {
      return {
        ...state,
        detailEntity: JSON.parse(action.payload.config.data),
        fetchingDetailEntity: false,
      };
    }

    case EntitiesActions.SAVE_DETAIL_ENTITY_NAME_FULFILLED: {
      return {
        ...state,
        fetchingDetailEntity: false,
      };
    }

    case EntitiesActions.DELETE_DETAIL_ENTITY_PENDING: {
      return {
        ...state,
        deleteDetailEntity: true,
      };
    }
    case EntitiesActions.DELETE_DETAIL_ENTITY_REJECTED: {
      return {
        ...state,
        deleteDetailEntity: false,
      };
    }
    case EntitiesActions.DELETE_DETAIL_ENTITY_FULFILLED: {
      return {
        ...state,
        deleteDetailEntity: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
