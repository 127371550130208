import {
  CHANGE_CURRENT_PROJECT,
  SET_CURRENT_PROJECT,
  CLEAR_CURRENT_PROJECT,
  SET_DEPLOYMENTS,
} from '../../constants/projects.actions';

import { LOGOUT_FULFILLED } from '../../constants/currentUser.actions';

import { LOGIN_TO_ACCOUNT_FULFILLED } from '../../constants/accounts.actions';

import { SAVE_EDITABLE_PROJECT_FULFILLED } from '../../constants/botprojects.actions';

const InitialState = {
  currentProject: '',
  currentBot: null,
  deployments: {},
};

export default function CurrentProjectsReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
    case CHANGE_CURRENT_PROJECT:
      return {
        ...InitialState,
        currentProject: action.projectShortName,
        currentBot: action.currentBot ? action.currentBot : state.currentBot,
        deployments: {
          [action.currentBot.shortName]: [],
        },
      };

    case LOGOUT_FULFILLED:
    case LOGIN_TO_ACCOUNT_FULFILLED:
    case CLEAR_CURRENT_PROJECT:
      return {
        ...InitialState,
        currentProject: '',
      };

    case SET_DEPLOYMENTS: {
      const newDeployments = { ...state.deployments };
      newDeployments[action.projectShortName] = action.deployments;
      return {
        ...state,
        deployments: newDeployments,
      };
    }

    case SAVE_EDITABLE_PROJECT_FULFILLED: {
      if (state.currentProject === action.payload.data.shortName) {
        return {
          ...state,
          currentBot: action.payload.data,
        };
      }
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
