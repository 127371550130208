import React, { Component } from 'react';
import { Spinner } from '@just-ai/just-ui';
import { bindActionCreators } from 'redux';
import { logout } from '../../../actions/currentUser.actions';
import { GA } from '../../../pipes/pureFunctions';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isInternal } from '../../../isAccessFunction';

class Logout extends Component {
  componentDidMount() {
    const { logout } = this.props;
    logout().then(() => {
      GA('GAEvent', 'logout', 'clicked');
      localStorage.removeItem(`${this.props.currentUser?.account?.id}_HIDE_ONBOARDING`);
      localStorage.removeItem('CURRENT_USER');
      if (isInternal()) {
        localStorage.clear();
      }
      window.location.href = '/c/login';
    });
  }

  render() {
    return <Spinner size='4x' />;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.CurrentUserReducer.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
