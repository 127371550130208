import React from 'react';
import forEach from 'lodash/forEach';
import { Button, IconButton, InputText } from '@just-ai/just-ui';
import DestinationSelect from './DestinationSelect';
import localize from '../../../../../localization';
import classes from './Groups.module.scss';

const { translate: t } = localize;

export default function Groups({
  editableChannel,
  destinations,
  removeGroup,
  changeDestination,
  addIds,
  addDestination,
  selectIDS,
}) {
  let options = [];
  forEach(destinations, item => {
    options.push({ value: item.name, label: item.name });
  });

  return (
    <div>
      {editableChannel?.config?.destinations?.length > 0 &&
        editableChannel.config.destinations.map((destination, k) => (
          <div className={classes.group} key={`destinationConfig_${k}`}>
            <IconButton
              color='secondary'
              name='farTrashAlt'
              onClick={() => removeGroup(k)}
              className={classes.groupDeleteBtn}
              flat
            />
            <div className={classes.groupFieldContainer}>
              <div className={classes.groupField}>
                <label>{t('Name')}</label>
                <InputText
                  type='text'
                  onChange={changeDestination.bind(null, k, 'name')}
                  defaultValue={destination.name}
                  name={`config.destinations[${k}]name`}
                  placeholder={t('Groups field placeholder name')}
                />
              </div>

              <div>
                <label>{t('Description')}</label>
                <InputText
                  type='text'
                  onChange={changeDestination.bind(null, k, 'description')}
                  name={`config.destinations[${k}]description`}
                  defaultValue={destination.description}
                  placeholder={t('Groups field placeholder descriptions')}
                />
              </div>
            </div>

            <DestinationSelect
              key={`${k}_destinationids`}
              k={k}
              destinationIds={destination.ids}
              liveTexOptions={options}
              selectIDS={(k, ids) => {
                addIds(k, ids);
                selectIDS(k, ids);
              }}
            />
          </div>
        ))}

      <Button
        onClick={addDestination}
        className={classes.groupAddBtn}
        iconLeft='faPlus'
        color='primary'
        size='sm'
        square
        flat
      >
        {t('Add group')}
      </Button>
      <br />
    </div>
  );
}
