// tslint:disable
//@ts-nocheck
/**
 * chatadapter api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AccountIdList
 */
export interface AccountIdList {
  /**
   *
   * @type {Array<number>}
   * @memberof AccountIdList
   */
  accountIds: Array<number>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum Author {
  BOT = 'BOT',
  CLIENT = 'CLIENT',
  OPERATOR = 'OPERATOR',
  SYSTEM = 'SYSTEM',
}

/**
 *
 * @export
 * @interface BitrixCrmResult
 */
export interface BitrixCrmResult extends CrmResult {
  /**
   *
   * @type {number}
   * @memberof BitrixCrmResult
   */
  id?: number;
}
/**
 *
 * @export
 * @interface BitrixCrmResultAllOf
 */
export interface BitrixCrmResultAllOf {
  /**
   *
   * @type {number}
   * @memberof BitrixCrmResultAllOf
   */
  id?: number;
}
/**
 *
 * @export
 * @interface BlockClientDto
 */
export interface BlockClientDto {
  /**
   *
   * @type {string}
   * @memberof BlockClientDto
   */
  fallbackMessage: string;
}
/**
 *
 * @export
 * @interface ChatHistoryDto
 */
export interface ChatHistoryDto {
  /**
   *
   * @type {Array<ChatHistoryMessage>}
   * @memberof ChatHistoryDto
   */
  messages: Array<ChatHistoryMessage>;
}
/**
 *
 * @export
 * @interface ChatHistoryMessage
 */
export interface ChatHistoryMessage {
  /**
   *
   * @type {string}
   * @memberof ChatHistoryMessage
   */
  botId: string;
  /**
   *
   * @type {string}
   * @memberof ChatHistoryMessage
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof ChatHistoryMessage
   */
  chatId: string;
  /**
   *
   * @type {string}
   * @memberof ChatHistoryMessage
   */
  textMessage?: string;
  /**
   *
   * @type {string}
   * @memberof ChatHistoryMessage
   */
  event?: string;
  /**
   *
   * @type {string}
   * @memberof ChatHistoryMessage
   */
  bsSessionId?: string;
  /**
   *
   * @type {SystemMessageType}
   * @memberof ChatHistoryMessage
   */
  systemMessageType: SystemMessageType;
  /**
   *
   * @type {string}
   * @memberof ChatHistoryMessage
   */
  eventData: string;
  /**
   *
   * @type {object}
   * @memberof ChatHistoryMessage
   */
  systemData: object;
  /**
   *
   * @type {Author}
   * @memberof ChatHistoryMessage
   */
  author: Author;
  /**
   *
   * @type {OperatorInfoDto}
   * @memberof ChatHistoryMessage
   */
  operatorInfo?: OperatorInfoDto;
  /**
   *
   * @type {Date}
   * @memberof ChatHistoryMessage
   */
  timestamp?: Date;
}
/**
 *
 * @export
 * @interface ChatWidgetData
 */
export interface ChatWidgetData {
  /**
   *
   * @type {string}
   * @memberof ChatWidgetData
   */
  scriptUrl: string;
}
/**
 *
 * @export
 * @interface ClientInfo
 */
export interface ClientInfo {
  /**
   *
   * @type {string}
   * @memberof ClientInfo
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ClientInfo
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientInfo
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientInfo
   */
  username?: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ConversationApiErrorCode {
  SENDERROR = 'SEND_ERROR',
  TARIFFBLOCKED = 'TARIFF_BLOCKED',
  ACCOUNTBLOCKED = 'ACCOUNT_BLOCKED',
}

/**
 *
 * @export
 * @interface ConversationApiResponse
 */
export interface ConversationApiResponse {
  /**
   *
   * @type {boolean}
   * @memberof ConversationApiResponse
   */
  result: boolean;
  /**
   *
   * @type {ConversationApiErrorCode}
   * @memberof ConversationApiResponse
   */
  errorCode?: ConversationApiErrorCode;
}
/**
 *
 * @export
 * @interface CreateExternalBotDto
 */
export interface CreateExternalBotDto {
  /**
   *
   * @type {string}
   * @memberof CreateExternalBotDto
   */
  webHookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CreateExternalBotDto
   */
  externalBotToken: string;
}
/**
 *
 * @export
 * @interface CreateInboundChannelDto
 */
export interface CreateInboundChannelDto {
  /**
   *
   * @type {string}
   * @memberof CreateInboundChannelDto
   */
  accountShortName: string;
  /**
   *
   * @type {string}
   * @memberof CreateInboundChannelDto
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof CreateInboundChannelDto
   */
  token: string;
  /**
   *
   * @type {object}
   * @memberof CreateInboundChannelDto
   */
  customParams?: object;
  /**
   *
   * @type {string}
   * @memberof CreateInboundChannelDto
   */
  operatorChannelId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateInboundChannelDto
   */
  jaicfToken?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateInboundChannelDto
   */
  isJaicfBot?: boolean;
  /**
   *
   * @type {object}
   * @memberof CreateInboundChannelDto
   */
  channelUserData?: object;
}
/**
 *
 * @export
 * @interface CreateOperatorChannelDto
 */
export interface CreateOperatorChannelDto {
  /**
   *
   * @type {string}
   * @memberof CreateOperatorChannelDto
   */
  accountShortName: string;
  /**
   *
   * @type {string}
   * @memberof CreateOperatorChannelDto
   */
  type: string;
  /**
   *
   * @type {object}
   * @memberof CreateOperatorChannelDto
   */
  customParams?: object;
}
/**
 *
 * @export
 * @interface CrmResult
 */
export interface CrmResult {
  /**
   *
   * @type {string}
   * @memberof CrmResult
   */
  crmType: string;
  /**
   *
   * @type {boolean}
   * @memberof CrmResult
   */
  isSuccess: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CrmTask {
  LEADCREATION = 'LEAD_CREATION',
  CONTACTCREATION = 'CONTACT_CREATION',
  TASKCREATION = 'TASK_CREATION',
  DEALCREATION = 'DEAL_CREATION',
  DEALUPDATE = 'DEAL_UPDATE',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum CrmType {
  BITRIX = 'BITRIX',
}

/**
 *
 * @export
 * @interface EmailFileDto
 */
export interface EmailFileDto {
  /**
   *
   * @type {string}
   * @memberof EmailFileDto
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof EmailFileDto
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ExternalBotDto
 */
export interface ExternalBotDto {
  /**
   *
   * @type {string}
   * @memberof ExternalBotDto
   */
  botId: string;
  /**
   *
   * @type {string}
   * @memberof ExternalBotDto
   */
  webHookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalBotDto
   */
  externalBotToken: string;
}
/**
 *
 * @export
 * @interface FileMessageDto
 */
export interface FileMessageDto {
  /**
   *
   * @type {string}
   * @memberof FileMessageDto
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof FileMessageDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FileMessageDto
   */
  mimeType?: string;
  /**
   *
   * @type {number}
   * @memberof FileMessageDto
   */
  size?: number;
}
/**
 *
 * @export
 * @interface InboundChannelDto
 */
export interface InboundChannelDto {
  /**
   *
   * @type {string}
   * @memberof InboundChannelDto
   */
  botId: string;
  /**
   *
   * @type {number}
   * @memberof InboundChannelDto
   */
  accountId: number;
  /**
   *
   * @type {string}
   * @memberof InboundChannelDto
   */
  accountShortName: string;
  /**
   *
   * @type {string}
   * @memberof InboundChannelDto
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof InboundChannelDto
   */
  token: string;
  /**
   *
   * @type {object}
   * @memberof InboundChannelDto
   */
  customParams: object;
  /**
   *
   * @type {OperatorChannelDto}
   * @memberof InboundChannelDto
   */
  operatorChannel?: OperatorChannelDto;
  /**
   *
   * @type {boolean}
   * @memberof InboundChannelDto
   */
  isBlocked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InboundChannelDto
   */
  tariffBlocked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InboundChannelDto
   */
  isJaicfBot?: boolean;
  /**
   *
   * @type {string}
   * @memberof InboundChannelDto
   */
  jaicfToken?: string;
  /**
   *
   * @type {string}
   * @memberof InboundChannelDto
   */
  webhookUrl?: string;
  /**
   *
   * @type {ChatWidgetData}
   * @memberof InboundChannelDto
   */
  chatWidgetData?: ChatWidgetData;
  /**
   *
   * @type {object}
   * @memberof InboundChannelDto
   */
  channelUserData?: object;
}
/**
 *
 * @export
 * @interface LinesQueryData
 */
export interface LinesQueryData {
  /**
   *
   * @type {number}
   * @memberof LinesQueryData
   */
  queryId: number;
  /**
   *
   * @type {string}
   * @memberof LinesQueryData
   */
  botId: string;
  /**
   *
   * @type {number}
   * @memberof LinesQueryData
   */
  accountId: number;
  /**
   *
   * @type {number}
   * @memberof LinesQueryData
   */
  maxLines?: number;
}
/**
 *
 * @export
 * @interface LinesResponseData
 */
export interface LinesResponseData {
  /**
   *
   * @type {number}
   * @memberof LinesResponseData
   */
  queryId: number;
  /**
   *
   * @type {number}
   * @memberof LinesResponseData
   */
  accountId?: number;
  /**
   *
   * @type {number}
   * @memberof LinesResponseData
   */
  linesAvailable: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum MessageSender {
  CLIENT = 'CLIENT',
  BOT = 'BOT',
  OPERATOR = 'OPERATOR',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum MessageStatus {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  NOTSENT = 'NOT_SENT',
}

/**
 *
 * @export
 * @interface MessageStatusDto
 */
export interface MessageStatusDto {
  /**
   *
   * @type {MessageStatus}
   * @memberof MessageStatusDto
   */
  status: MessageStatus;
}
/**
 *
 * @export
 * @interface MetaChannelTokenDto
 */
export interface MetaChannelTokenDto {
  /**
   *
   * @type {string}
   * @memberof MetaChannelTokenDto
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof MetaChannelTokenDto
   */
  pageId: string;
  /**
   *
   * @type {string}
   * @memberof MetaChannelTokenDto
   */
  pageName: string;
}
/**
 *
 * @export
 * @interface MetaChannelTokenRequiredDto
 */
export interface MetaChannelTokenRequiredDto {
  /**
   *
   * @type {string}
   * @memberof MetaChannelTokenRequiredDto
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof MetaChannelTokenRequiredDto
   */
  userId: string;
  /**
   *
   * @type {Array<string>}
   * @memberof MetaChannelTokenRequiredDto
   */
  pageIds: Array<string>;
}
/**
 *
 * @export
 * @interface MetaChannelTokenResultDto
 */
export interface MetaChannelTokenResultDto {
  /**
   *
   * @type {Array<MetaChannelTokenDto>}
   * @memberof MetaChannelTokenResultDto
   */
  result: Array<MetaChannelTokenDto>;
}
/**
 *
 * @export
 * @interface OperatorChannelDto
 */
export interface OperatorChannelDto {
  /**
   *
   * @type {string}
   * @memberof OperatorChannelDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof OperatorChannelDto
   */
  accountId?: number;
  /**
   *
   * @type {string}
   * @memberof OperatorChannelDto
   */
  accountShortName: string;
  /**
   *
   * @type {string}
   * @memberof OperatorChannelDto
   */
  type: string;
  /**
   *
   * @type {object}
   * @memberof OperatorChannelDto
   */
  customParams: object;
  /**
   *
   * @type {string}
   * @memberof OperatorChannelDto
   */
  webhookUrl?: string;
}
/**
 *
 * @export
 * @interface OperatorInfoDto
 */
export interface OperatorInfoDto {
  /**
   *
   * @type {string}
   * @memberof OperatorInfoDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OperatorInfoDto
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorInfoDto
   */
  livechatType: string;
  /**
   *
   * @type {string}
   * @memberof OperatorInfoDto
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorInfoDto
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorInfoDto
   */
  avatarUrl?: string;
  /**
   *
   * @type {string}
   * @memberof OperatorInfoDto
   */
  email?: string;
}
/**
 *
 * @export
 * @interface OriginateQueryItem
 */
export interface OriginateQueryItem {
  /**
   *
   * @type {number}
   * @memberof OriginateQueryItem
   */
  callTaskId: number;
  /**
   *
   * @type {number}
   * @memberof OriginateQueryItem
   */
  callId: number;
  /**
   *
   * @type {string}
   * @memberof OriginateQueryItem
   */
  botId: string;
  /**
   *
   * @type {number}
   * @memberof OriginateQueryItem
   */
  accountId?: number;
  /**
   *
   * @type {object}
   * @memberof OriginateQueryItem
   */
  payload?: object;
  /**
   *
   * @type {string}
   * @memberof OriginateQueryItem
   */
  number: string;
  /**
   *
   * @type {object}
   * @memberof OriginateQueryItem
   */
  callScenarioData?: object;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
}
/**
 *
 * @export
 * @interface ProcessCrmTaskDto
 */
export interface ProcessCrmTaskDto {
  /**
   *
   * @type {string}
   * @memberof ProcessCrmTaskDto
   */
  accountShortName: string;
  /**
   *
   * @type {string}
   * @memberof ProcessCrmTaskDto
   */
  botId: string;
  /**
   *
   * @type {string}
   * @memberof ProcessCrmTaskDto
   */
  chatId: string;
  /**
   *
   * @type {CrmType}
   * @memberof ProcessCrmTaskDto
   */
  crmType: CrmType;
  /**
   *
   * @type {CrmTask}
   * @memberof ProcessCrmTaskDto
   */
  crmTask: CrmTask;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ProcessCrmTaskDto
   */
  parameters?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface ResultDto
 */
export interface ResultDto {
  /**
   *
   * @type {boolean}
   * @memberof ResultDto
   */
  result: boolean;
}
/**
 *
 * @export
 * @interface SendEmailDto
 */
export interface SendEmailDto {
  /**
   *
   * @type {string}
   * @memberof SendEmailDto
   */
  destination: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailDto
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof SendEmailDto
   */
  text: string;
  /**
   *
   * @type {Array<EmailFileDto>}
   * @memberof SendEmailDto
   */
  files?: Array<EmailFileDto>;
  /**
   *
   * @type {string}
   * @memberof SendEmailDto
   */
  html?: string;
  /**
   *
   * @type {boolean}
   * @memberof SendEmailDto
   */
  htmlEnabled?: boolean;
}
/**
 *
 * @export
 * @interface SendEventToBotDto
 */
export interface SendEventToBotDto {
  /**
   *
   * @type {ClientInfo}
   * @memberof SendEventToBotDto
   */
  client: ClientInfo;
  /**
   *
   * @type {string}
   * @memberof SendEventToBotDto
   */
  event: string;
  /**
   *
   * @type {object}
   * @memberof SendEventToBotDto
   */
  eventData?: object;
  /**
   *
   * @type {object}
   * @memberof SendEventToBotDto
   */
  customData?: object;
}
/**
 *
 * @export
 * @interface SendMessageToBotOrOperatorDto
 */
export interface SendMessageToBotOrOperatorDto {
  /**
   *
   * @type {ClientInfo}
   * @memberof SendMessageToBotOrOperatorDto
   */
  client: ClientInfo;
  /**
   *
   * @type {string}
   * @memberof SendMessageToBotOrOperatorDto
   */
  text?: string;
  /**
   *
   * @type {Array<FileMessageDto>}
   * @memberof SendMessageToBotOrOperatorDto
   */
  files?: Array<FileMessageDto>;
  /**
   *
   * @type {object}
   * @memberof SendMessageToBotOrOperatorDto
   */
  customData?: object;
}
/**
 *
 * @export
 * @interface SendMessageToClientDto
 */
export interface SendMessageToClientDto {
  /**
   *
   * @type {ClientInfo}
   * @memberof SendMessageToClientDto
   */
  client: ClientInfo;
  /**
   *
   * @type {string}
   * @memberof SendMessageToClientDto
   */
  text?: string;
  /**
   *
   * @type {Array<FileMessageDto>}
   * @memberof SendMessageToClientDto
   */
  files?: Array<FileMessageDto>;
  /**
   *
   * @type {OperatorInfoDto}
   * @memberof SendMessageToClientDto
   */
  operator?: OperatorInfoDto;
}
/**
 *
 * @export
 * @interface SendRepliesToClientDto
 */
export interface SendRepliesToClientDto {
  /**
   *
   * @type {ClientInfo}
   * @memberof SendRepliesToClientDto
   */
  client: ClientInfo;
  /**
   *
   * @type {object}
   * @memberof SendRepliesToClientDto
   */
  replies: object;
  /**
   *
   * @type {OperatorInfoDto}
   * @memberof SendRepliesToClientDto
   */
  operator?: OperatorInfoDto;
  /**
   *
   * @type {object}
   * @memberof SendRepliesToClientDto
   */
  customData?: object;
}
/**
 *
 * @export
 * @interface SendSmsDto
 */
export interface SendSmsDto {
  /**
   *
   * @type {string}
   * @memberof SendSmsDto
   */
  accountShortName: string;
  /**
   *
   * @type {string}
   * @memberof SendSmsDto
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof SendSmsDto
   */
  destination: string;
  /**
   *
   * @type {object}
   * @memberof SendSmsDto
   */
  providerConfiguration?: object;
}
/**
 *
 * @export
 * @interface SmsProviderConfiguration
 */
export interface SmsProviderConfiguration {
  /**
   *
   * @type {string}
   * @memberof SmsProviderConfiguration
   */
  type: string;
}
/**
 *
 * @export
 * @interface SmsProviderIDigitalConfiguration
 */
export interface SmsProviderIDigitalConfiguration extends SmsProviderConfiguration {
  /**
   *
   * @type {string}
   * @memberof SmsProviderIDigitalConfiguration
   */
  nodeId: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderIDigitalConfiguration
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderIDigitalConfiguration
   */
  source: string;
}
/**
 *
 * @export
 * @interface SmsProviderIDigitalConfigurationAllOf
 */
export interface SmsProviderIDigitalConfigurationAllOf {
  /**
   *
   * @type {string}
   * @memberof SmsProviderIDigitalConfigurationAllOf
   */
  nodeId: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderIDigitalConfigurationAllOf
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderIDigitalConfigurationAllOf
   */
  source: string;
}
/**
 *
 * @export
 * @interface SmsProviderVonageConfiguration
 */
export interface SmsProviderVonageConfiguration extends SmsProviderConfiguration {
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfiguration
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfiguration
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfiguration
   */
  apiSecret: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfiguration
   */
  typeEncoding: string;
}
/**
 *
 * @export
 * @interface SmsProviderVonageConfigurationAllOf
 */
export interface SmsProviderVonageConfigurationAllOf {
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfigurationAllOf
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfigurationAllOf
   */
  apiKey: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfigurationAllOf
   */
  apiSecret: string;
  /**
   *
   * @type {string}
   * @memberof SmsProviderVonageConfigurationAllOf
   */
  typeEncoding: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SmsSendingStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  TARIFFBLOCKED = 'TARIFF_BLOCKED',
}

/**
 *
 * @export
 * @interface SmsSendingStatusDto
 */
export interface SmsSendingStatusDto {
  /**
   *
   * @type {SmsSendingStatus}
   * @memberof SmsSendingStatusDto
   */
  status: SmsSendingStatus;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SystemMessageType {
  NEWOPERATORCHAT = 'NEW_OPERATOR_CHAT',
  CLIENTMESSAGE = 'CLIENT_MESSAGE',
  OPERATORMESSAGE = 'OPERATOR_MESSAGE',
  BOTMESSAGE = 'BOT_MESSAGE',
  OPERATORJOINED = 'OPERATOR_JOINED',
  OPERATORLEFT = 'OPERATOR_LEFT',
  CLIENTLEFT = 'CLIENT_LEFT',
  TRANSFERTOOPERATORGROUP = 'TRANSFER_TO_OPERATOR_GROUP',
  OPERATORCHATCOMPLETED = 'OPERATOR_CHAT_COMPLETED',
  TRANSFERAFTEROPERATORSILENCE = 'TRANSFER_AFTER_OPERATOR_SILENCE',
  CLIENTBLOCKINGBYOPERATOR = 'CLIENT_BLOCKING_BY_OPERATOR',
  CLIENTUNBLOCKINGBYOPERATOR = 'CLIENT_UNBLOCKING_BY_OPERATOR',
}

/**
 *
 * @export
 * @interface TariffVersionDateDto
 */
export interface TariffVersionDateDto {
  /**
   *
   * @type {Date}
   * @memberof TariffVersionDateDto
   */
  versionDate: Date;
}
/**
 *
 * @export
 * @interface UpdateChannelLimitsDto
 */
export interface UpdateChannelLimitsDto {
  /**
   *
   * @type {number}
   * @memberof UpdateChannelLimitsDto
   */
  phrasesCount: number;
  /**
   *
   * @type {number}
   * @memberof UpdateChannelLimitsDto
   */
  knowledgeBasePhrasesCount: number;
  /**
   *
   * @type {boolean}
   * @memberof UpdateChannelLimitsDto
   */
  testWidget: boolean;
}
/**
 *
 * @export
 * @interface UpdateExternalBotDto
 */
export interface UpdateExternalBotDto {
  /**
   *
   * @type {string}
   * @memberof UpdateExternalBotDto
   */
  webHookUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateExternalBotDto
   */
  externalBotToken: string;
}
/**
 *
 * @export
 * @interface UpdateInboundChannelDto
 */
export interface UpdateInboundChannelDto {
  /**
   *
   * @type {string}
   * @memberof UpdateInboundChannelDto
   */
  token: string;
  /**
   *
   * @type {object}
   * @memberof UpdateInboundChannelDto
   */
  customParams?: object;
  /**
   *
   * @type {string}
   * @memberof UpdateInboundChannelDto
   */
  operatorChannelId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateInboundChannelDto
   */
  jaicfToken?: string;
  /**
   *
   * @type {object}
   * @memberof UpdateInboundChannelDto
   */
  channelUserData?: object;
}
/**
 *
 * @export
 * @interface UpdateOperatorChannelDto
 */
export interface UpdateOperatorChannelDto {
  /**
   *
   * @type {object}
   * @memberof UpdateOperatorChannelDto
   */
  customParams?: object;
}
/**
 *
 * @export
 * @interface UpdateStatusDto
 */
export interface UpdateStatusDto {
  /**
   *
   * @type {string}
   * @memberof UpdateStatusDto
   */
  questionId: string;
  /**
   *
   * @type {MessageStatus}
   * @memberof UpdateStatusDto
   */
  status: MessageStatus;
}
/**
 *
 * @export
 * @interface UpdateStatusesDto
 */
export interface UpdateStatusesDto {
  /**
   *
   * @type {Array<UpdateStatusDto>}
   * @memberof UpdateStatusesDto
   */
  statuses: Array<UpdateStatusDto>;
}

/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account tariff version date
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountTariffVersionDate(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountTariffVersionDate.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/billing/tariff-limits/version-date`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account telephony tariff limits
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountTelephonyTariffLimit(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountTelephonyTariffLimit.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/billing/tariff-limits/telephony`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account wasted tariff limits
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountWastedTariffLimit(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getAccountWastedTariffLimit.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/billing/tariff-limits/wasted`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get wasted tariff limits for accounts
     * @param {AccountIdList} accountIdList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWastedTariffLimitsForAccounts(accountIdList: AccountIdList, options: any = {}): RequestArgs {
      // verify required parameter 'accountIdList' is not null or undefined
      if (accountIdList === null || accountIdList === undefined) {
        throw new RequiredError(
          'accountIdList',
          'Required parameter accountIdList was null or undefined when calling getWastedTariffLimitsForAccounts.'
        );
      }
      const localVarPath = `/api/chatadapter/billing/tariff-limits`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof accountIdList !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountIdList !== undefined ? accountIdList : {})
        : accountIdList || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reset unique clients for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetUniqueClients(accountId: number, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling resetUniqueClients.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/billing/unique-clients`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update account tariff data
     * @param {number} accountId Account identifier
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountTariffData(accountId: number, body: object, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateAccountTariffData.'
        );
      }
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling updateAccountTariffData.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/billing/tariff-data`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update channel limits
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateChannelLimitsDto} updateChannelLimitsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateChannelLimits(
      accountId: number,
      botId: string,
      updateChannelLimitsDto: UpdateChannelLimitsDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateChannelLimits.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling updateChannelLimits.'
        );
      }
      // verify required parameter 'updateChannelLimitsDto' is not null or undefined
      if (updateChannelLimitsDto === null || updateChannelLimitsDto === undefined) {
        throw new RequiredError(
          'updateChannelLimitsDto',
          'Required parameter updateChannelLimitsDto was null or undefined when calling updateChannelLimits.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/billing/channel-limits/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateChannelLimitsDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateChannelLimitsDto !== undefined ? updateChannelLimitsDto : {})
        : updateChannelLimitsDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get account tariff version date
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountTariffVersionDate(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffVersionDateDto> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getAccountTariffVersionDate(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get account telephony tariff limits
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountTelephonyTariffLimit(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getAccountTelephonyTariffLimit(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get account wasted tariff limits
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountWastedTariffLimit(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getAccountWastedTariffLimit(
        accountId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get wasted tariff limits for accounts
     * @param {AccountIdList} accountIdList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWastedTariffLimitsForAccounts(
      accountIdList: AccountIdList,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).getWastedTariffLimitsForAccounts(
        accountIdList,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Reset unique clients for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetUniqueClients(
      accountId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).resetUniqueClients(accountId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update account tariff data
     * @param {number} accountId Account identifier
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountTariffData(
      accountId: number,
      body: object,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).updateAccountTariffData(
        accountId,
        body,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update channel limits
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateChannelLimitsDto} updateChannelLimitsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateChannelLimits(
      accountId: number,
      botId: string,
      updateChannelLimitsDto: UpdateChannelLimitsDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BillingApiAxiosParamCreator(configuration).updateChannelLimits(
        accountId,
        botId,
        updateChannelLimitsDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Get account tariff version date
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountTariffVersionDate(accountId: number, options?: any): AxiosPromise<TariffVersionDateDto> {
      return BillingApiFp(configuration).getAccountTariffVersionDate(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get account telephony tariff limits
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountTelephonyTariffLimit(accountId: number, options?: any): AxiosPromise<object> {
      return BillingApiFp(configuration).getAccountTelephonyTariffLimit(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get account wasted tariff limits
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountWastedTariffLimit(accountId: number, options?: any): AxiosPromise<object> {
      return BillingApiFp(configuration).getAccountWastedTariffLimit(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get wasted tariff limits for accounts
     * @param {AccountIdList} accountIdList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWastedTariffLimitsForAccounts(accountIdList: AccountIdList, options?: any): AxiosPromise<object> {
      return BillingApiFp(configuration).getWastedTariffLimitsForAccounts(accountIdList, options)(axios, basePath);
    },
    /**
     *
     * @summary Reset unique clients for account
     * @param {number} accountId Account identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetUniqueClients(accountId: number, options?: any): AxiosPromise<void> {
      return BillingApiFp(configuration).resetUniqueClients(accountId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update account tariff data
     * @param {number} accountId Account identifier
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountTariffData(accountId: number, body: object, options?: any): AxiosPromise<void> {
      return BillingApiFp(configuration).updateAccountTariffData(accountId, body, options)(axios, basePath);
    },
    /**
     *
     * @summary Update channel limits
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateChannelLimitsDto} updateChannelLimitsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateChannelLimits(
      accountId: number,
      botId: string,
      updateChannelLimitsDto: UpdateChannelLimitsDto,
      options?: any
    ): AxiosPromise<void> {
      return BillingApiFp(configuration).updateChannelLimits(
        accountId,
        botId,
        updateChannelLimitsDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
  /**
   *
   * @summary Get account tariff version date
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getAccountTariffVersionDate(accountId: number, options?: any) {
    return BillingApiFp(this.configuration).getAccountTariffVersionDate(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get account telephony tariff limits
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getAccountTelephonyTariffLimit(accountId: number, options?: any) {
    return BillingApiFp(this.configuration).getAccountTelephonyTariffLimit(accountId, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Get account wasted tariff limits
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getAccountWastedTariffLimit(accountId: number, options?: any) {
    return BillingApiFp(this.configuration).getAccountWastedTariffLimit(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get wasted tariff limits for accounts
   * @param {AccountIdList} accountIdList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public getWastedTariffLimitsForAccounts(accountIdList: AccountIdList, options?: any) {
    return BillingApiFp(this.configuration).getWastedTariffLimitsForAccounts(accountIdList, options)(
      this.axios,
      this.basePath
    );
  }

  /**
   *
   * @summary Reset unique clients for account
   * @param {number} accountId Account identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public resetUniqueClients(accountId: number, options?: any) {
    return BillingApiFp(this.configuration).resetUniqueClients(accountId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update account tariff data
   * @param {number} accountId Account identifier
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public updateAccountTariffData(accountId: number, body: object, options?: any) {
    return BillingApiFp(this.configuration).updateAccountTariffData(
      accountId,
      body,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update channel limits
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {UpdateChannelLimitsDto} updateChannelLimitsDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public updateChannelLimits(
    accountId: number,
    botId: string,
    updateChannelLimitsDto: UpdateChannelLimitsDto,
    options?: any
  ) {
    return BillingApiFp(this.configuration).updateChannelLimits(
      accountId,
      botId,
      updateChannelLimitsDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ChatHistoryApi - axios parameter creator
 * @export
 */
export const ChatHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} channelUserId ChannelUserId for the chat history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistory(channelUserId: string, options: any = {}): RequestArgs {
      // verify required parameter 'channelUserId' is not null or undefined
      if (channelUserId === null || channelUserId === undefined) {
        throw new RequiredError(
          'channelUserId',
          'Required parameter channelUserId was null or undefined when calling getChatHistory.'
        );
      }
      const localVarPath = `/restapi/chathistory/{channelUserId}`.replace(
        `{${'channelUserId'}}`,
        encodeURIComponent(String(channelUserId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} channelUserId ChannelUserId for the chat history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistoryJson(accountId: number, channelUserId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getChatHistoryJson.'
        );
      }
      // verify required parameter 'channelUserId' is not null or undefined
      if (channelUserId === null || channelUserId === undefined) {
        throw new RequiredError(
          'channelUserId',
          'Required parameter channelUserId was null or undefined when calling getChatHistoryJson.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/chathistory/{channelUserId}/json`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'channelUserId'}}`, encodeURIComponent(String(channelUserId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} clientId
     * @param {string} botId
     * @param {number} [size] Page size
     * @param {Date} [endTime] End date-time for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistoryV2(
      accountId: number,
      clientId: string,
      botId: string,
      size?: number,
      endTime?: Date,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getChatHistoryV2.'
        );
      }
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getChatHistoryV2.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling getChatHistoryV2.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/chat-history`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      if (botId !== undefined) {
        localVarQueryParameter['botId'] = botId;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (endTime !== undefined) {
        localVarQueryParameter['endTime'] = (endTime as any) instanceof Date ? (endTime as any).toISOString() : endTime;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} clientId
     * @param {string} botId
     * @param {string} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastClientMessage(
      accountId: number,
      clientId: string,
      botId: string,
      chatId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getLastClientMessage.'
        );
      }
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getLastClientMessage.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling getLastClientMessage.'
        );
      }
      // verify required parameter 'chatId' is not null or undefined
      if (chatId === null || chatId === undefined) {
        throw new RequiredError(
          'chatId',
          'Required parameter chatId was null or undefined when calling getLastClientMessage.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/last-client-message`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      if (botId !== undefined) {
        localVarQueryParameter['botId'] = botId;
      }

      if (chatId !== undefined) {
        localVarQueryParameter['chatId'] = chatId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChatHistoryApi - functional programming interface
 * @export
 */
export const ChatHistoryApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} channelUserId ChannelUserId for the chat history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistory(
      channelUserId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ChatHistoryApiAxiosParamCreator(configuration).getChatHistory(channelUserId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} channelUserId ChannelUserId for the chat history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistoryJson(
      accountId: number,
      channelUserId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = ChatHistoryApiAxiosParamCreator(configuration).getChatHistoryJson(
        accountId,
        channelUserId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} clientId
     * @param {string} botId
     * @param {number} [size] Page size
     * @param {Date} [endTime] End date-time for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistoryV2(
      accountId: number,
      clientId: string,
      botId: string,
      size?: number,
      endTime?: Date,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryDto> {
      const localVarAxiosArgs = ChatHistoryApiAxiosParamCreator(configuration).getChatHistoryV2(
        accountId,
        clientId,
        botId,
        size,
        endTime,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} clientId
     * @param {string} botId
     * @param {string} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastClientMessage(
      accountId: number,
      clientId: string,
      botId: string,
      chatId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryDto> {
      const localVarAxiosArgs = ChatHistoryApiAxiosParamCreator(configuration).getLastClientMessage(
        accountId,
        clientId,
        botId,
        chatId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ChatHistoryApi - factory interface
 * @export
 */
export const ChatHistoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} channelUserId ChannelUserId for the chat history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistory(channelUserId: string, options?: any): AxiosPromise<string> {
      return ChatHistoryApiFp(configuration).getChatHistory(channelUserId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} channelUserId ChannelUserId for the chat history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistoryJson(accountId: number, channelUserId: string, options?: any): AxiosPromise<string> {
      return ChatHistoryApiFp(configuration).getChatHistoryJson(accountId, channelUserId, options)(axios, basePath);
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} clientId
     * @param {string} botId
     * @param {number} [size] Page size
     * @param {Date} [endTime] End date-time for filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChatHistoryV2(
      accountId: number,
      clientId: string,
      botId: string,
      size?: number,
      endTime?: Date,
      options?: any
    ): AxiosPromise<ChatHistoryDto> {
      return ChatHistoryApiFp(configuration).getChatHistoryV2(
        accountId,
        clientId,
        botId,
        size,
        endTime,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {number} accountId Account identifier
     * @param {string} clientId
     * @param {string} botId
     * @param {string} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastClientMessage(
      accountId: number,
      clientId: string,
      botId: string,
      chatId: string,
      options?: any
    ): AxiosPromise<ChatHistoryDto> {
      return ChatHistoryApiFp(configuration).getLastClientMessage(
        accountId,
        clientId,
        botId,
        chatId,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ChatHistoryApi - object-oriented interface
 * @export
 * @class ChatHistoryApi
 * @extends {BaseAPI}
 */
export class ChatHistoryApi extends BaseAPI {
  /**
   *
   * @param {string} channelUserId ChannelUserId for the chat history
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatHistoryApi
   */
  public getChatHistory(channelUserId: string, options?: any) {
    return ChatHistoryApiFp(this.configuration).getChatHistory(channelUserId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId Account identifier
   * @param {string} channelUserId ChannelUserId for the chat history
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatHistoryApi
   */
  public getChatHistoryJson(accountId: number, channelUserId: string, options?: any) {
    return ChatHistoryApiFp(this.configuration).getChatHistoryJson(
      accountId,
      channelUserId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId Account identifier
   * @param {string} clientId
   * @param {string} botId
   * @param {number} [size] Page size
   * @param {Date} [endTime] End date-time for filtering
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatHistoryApi
   */
  public getChatHistoryV2(
    accountId: number,
    clientId: string,
    botId: string,
    size?: number,
    endTime?: Date,
    options?: any
  ) {
    return ChatHistoryApiFp(this.configuration).getChatHistoryV2(
      accountId,
      clientId,
      botId,
      size,
      endTime,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {number} accountId Account identifier
   * @param {string} clientId
   * @param {string} botId
   * @param {string} chatId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatHistoryApi
   */
  public getLastClientMessage(accountId: number, clientId: string, botId: string, chatId: string, options?: any) {
    return ChatHistoryApiFp(this.configuration).getLastClientMessage(
      accountId,
      clientId,
      botId,
      chatId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Block client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {string} clientId
     * @param {BlockClientDto} blockClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockClient(
      accountId: number,
      botId: string,
      clientId: string,
      blockClientDto: BlockClientDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling blockClient.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError('botId', 'Required parameter botId was null or undefined when calling blockClient.');
      }
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling blockClient.'
        );
      }
      // verify required parameter 'blockClientDto' is not null or undefined
      if (blockClientDto === null || blockClientDto === undefined) {
        throw new RequiredError(
          'blockClientDto',
          'Required parameter blockClientDto was null or undefined when calling blockClient.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/clients/block`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof blockClientDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(blockClientDto !== undefined ? blockClientDto : {})
        : blockClientDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unblock client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockClient(accountId: number, botId: string, clientId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling unblockClient.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError('botId', 'Required parameter botId was null or undefined when calling unblockClient.');
      }
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling unblockClient.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/clients/unblock`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      if (clientId !== undefined) {
        localVarQueryParameter['clientId'] = clientId;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Block client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {string} clientId
     * @param {BlockClientDto} blockClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockClient(
      accountId: number,
      botId: string,
      clientId: string,
      blockClientDto: BlockClientDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).blockClient(
        accountId,
        botId,
        clientId,
        blockClientDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Unblock client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockClient(
      accountId: number,
      botId: string,
      clientId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = ClientsApiAxiosParamCreator(configuration).unblockClient(
        accountId,
        botId,
        clientId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Block client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {string} clientId
     * @param {BlockClientDto} blockClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockClient(
      accountId: number,
      botId: string,
      clientId: string,
      blockClientDto: BlockClientDto,
      options?: any
    ): AxiosPromise<ResultDto> {
      return ClientsApiFp(configuration).blockClient(
        accountId,
        botId,
        clientId,
        blockClientDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Unblock client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockClient(accountId: number, botId: string, clientId: string, options?: any): AxiosPromise<ResultDto> {
      return ClientsApiFp(configuration).unblockClient(accountId, botId, clientId, options)(axios, basePath);
    },
  };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
  /**
   *
   * @summary Block client
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {string} clientId
   * @param {BlockClientDto} blockClientDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public blockClient(
    accountId: number,
    botId: string,
    clientId: string,
    blockClientDto: BlockClientDto,
    options?: any
  ) {
    return ClientsApiFp(this.configuration).blockClient(
      accountId,
      botId,
      clientId,
      blockClientDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Unblock client
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {string} clientId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientsApi
   */
  public unblockClient(accountId: number, botId: string, clientId: string, options?: any) {
    return ClientsApiFp(this.configuration).unblockClient(
      accountId,
      botId,
      clientId,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * ConversationApi - axios parameter creator
 * @export
 */
export const ConversationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Send event to bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendEventToBotDto} sendEventToBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEventToBot(
      accountId: number,
      botId: string,
      sendEventToBotDto: SendEventToBotDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling sendEventToBot.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError('botId', 'Required parameter botId was null or undefined when calling sendEventToBot.');
      }
      // verify required parameter 'sendEventToBotDto' is not null or undefined
      if (sendEventToBotDto === null || sendEventToBotDto === undefined) {
        throw new RequiredError(
          'sendEventToBotDto',
          'Required parameter sendEventToBotDto was null or undefined when calling sendEventToBot.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/conversaions/event-to-bot`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendEventToBotDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendEventToBotDto !== undefined ? sendEventToBotDto : {})
        : sendEventToBotDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send text or files to bot or operator
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendMessageToBotOrOperatorDto} sendMessageToBotOrOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToBotOrOperator(
      accountId: number,
      botId: string,
      sendMessageToBotOrOperatorDto: SendMessageToBotOrOperatorDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling sendMessageToBotOrOperator.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling sendMessageToBotOrOperator.'
        );
      }
      // verify required parameter 'sendMessageToBotOrOperatorDto' is not null or undefined
      if (sendMessageToBotOrOperatorDto === null || sendMessageToBotOrOperatorDto === undefined) {
        throw new RequiredError(
          'sendMessageToBotOrOperatorDto',
          'Required parameter sendMessageToBotOrOperatorDto was null or undefined when calling sendMessageToBotOrOperator.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/conversaions/message-to-bot-or-operator`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendMessageToBotOrOperatorDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendMessageToBotOrOperatorDto !== undefined ? sendMessageToBotOrOperatorDto : {})
        : sendMessageToBotOrOperatorDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send message to the client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendMessageToClientDto} sendMessageToClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToClient(
      accountId: number,
      botId: string,
      sendMessageToClientDto: SendMessageToClientDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling sendMessageToClient.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling sendMessageToClient.'
        );
      }
      // verify required parameter 'sendMessageToClientDto' is not null or undefined
      if (sendMessageToClientDto === null || sendMessageToClientDto === undefined) {
        throw new RequiredError(
          'sendMessageToClientDto',
          'Required parameter sendMessageToClientDto was null or undefined when calling sendMessageToClient.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/conversaions/message-to-client`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendMessageToClientDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendMessageToClientDto !== undefined ? sendMessageToClientDto : {})
        : sendMessageToClientDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send message to the client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendRepliesToClientDto} sendRepliesToClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendRepliesToClient(
      accountId: number,
      botId: string,
      sendRepliesToClientDto: SendRepliesToClientDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling sendRepliesToClient.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling sendRepliesToClient.'
        );
      }
      // verify required parameter 'sendRepliesToClientDto' is not null or undefined
      if (sendRepliesToClientDto === null || sendRepliesToClientDto === undefined) {
        throw new RequiredError(
          'sendRepliesToClientDto',
          'Required parameter sendRepliesToClientDto was null or undefined when calling sendRepliesToClient.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/conversaions/replies-to-client`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendRepliesToClientDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendRepliesToClientDto !== undefined ? sendRepliesToClientDto : {})
        : sendRepliesToClientDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ConversationApi - functional programming interface
 * @export
 */
export const ConversationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Send event to bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendEventToBotDto} sendEventToBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEventToBot(
      accountId: number,
      botId: string,
      sendEventToBotDto: SendEventToBotDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationApiResponse> {
      const localVarAxiosArgs = ConversationApiAxiosParamCreator(configuration).sendEventToBot(
        accountId,
        botId,
        sendEventToBotDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Send text or files to bot or operator
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendMessageToBotOrOperatorDto} sendMessageToBotOrOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToBotOrOperator(
      accountId: number,
      botId: string,
      sendMessageToBotOrOperatorDto: SendMessageToBotOrOperatorDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationApiResponse> {
      const localVarAxiosArgs = ConversationApiAxiosParamCreator(configuration).sendMessageToBotOrOperator(
        accountId,
        botId,
        sendMessageToBotOrOperatorDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Send message to the client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendMessageToClientDto} sendMessageToClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToClient(
      accountId: number,
      botId: string,
      sendMessageToClientDto: SendMessageToClientDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationApiResponse> {
      const localVarAxiosArgs = ConversationApiAxiosParamCreator(configuration).sendMessageToClient(
        accountId,
        botId,
        sendMessageToClientDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Send message to the client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendRepliesToClientDto} sendRepliesToClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendRepliesToClient(
      accountId: number,
      botId: string,
      sendRepliesToClientDto: SendRepliesToClientDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationApiResponse> {
      const localVarAxiosArgs = ConversationApiAxiosParamCreator(configuration).sendRepliesToClient(
        accountId,
        botId,
        sendRepliesToClientDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ConversationApi - factory interface
 * @export
 */
export const ConversationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Send event to bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendEventToBotDto} sendEventToBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEventToBot(
      accountId: number,
      botId: string,
      sendEventToBotDto: SendEventToBotDto,
      options?: any
    ): AxiosPromise<ConversationApiResponse> {
      return ConversationApiFp(configuration).sendEventToBot(
        accountId,
        botId,
        sendEventToBotDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Send text or files to bot or operator
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendMessageToBotOrOperatorDto} sendMessageToBotOrOperatorDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToBotOrOperator(
      accountId: number,
      botId: string,
      sendMessageToBotOrOperatorDto: SendMessageToBotOrOperatorDto,
      options?: any
    ): AxiosPromise<ConversationApiResponse> {
      return ConversationApiFp(configuration).sendMessageToBotOrOperator(
        accountId,
        botId,
        sendMessageToBotOrOperatorDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Send message to the client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendMessageToClientDto} sendMessageToClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendMessageToClient(
      accountId: number,
      botId: string,
      sendMessageToClientDto: SendMessageToClientDto,
      options?: any
    ): AxiosPromise<ConversationApiResponse> {
      return ConversationApiFp(configuration).sendMessageToClient(
        accountId,
        botId,
        sendMessageToClientDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Send message to the client
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {SendRepliesToClientDto} sendRepliesToClientDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendRepliesToClient(
      accountId: number,
      botId: string,
      sendRepliesToClientDto: SendRepliesToClientDto,
      options?: any
    ): AxiosPromise<ConversationApiResponse> {
      return ConversationApiFp(configuration).sendRepliesToClient(
        accountId,
        botId,
        sendRepliesToClientDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ConversationApi - object-oriented interface
 * @export
 * @class ConversationApi
 * @extends {BaseAPI}
 */
export class ConversationApi extends BaseAPI {
  /**
   *
   * @summary Send event to bot
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {SendEventToBotDto} sendEventToBotDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConversationApi
   */
  public sendEventToBot(accountId: number, botId: string, sendEventToBotDto: SendEventToBotDto, options?: any) {
    return ConversationApiFp(this.configuration).sendEventToBot(
      accountId,
      botId,
      sendEventToBotDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Send text or files to bot or operator
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {SendMessageToBotOrOperatorDto} sendMessageToBotOrOperatorDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConversationApi
   */
  public sendMessageToBotOrOperator(
    accountId: number,
    botId: string,
    sendMessageToBotOrOperatorDto: SendMessageToBotOrOperatorDto,
    options?: any
  ) {
    return ConversationApiFp(this.configuration).sendMessageToBotOrOperator(
      accountId,
      botId,
      sendMessageToBotOrOperatorDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Send message to the client
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {SendMessageToClientDto} sendMessageToClientDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConversationApi
   */
  public sendMessageToClient(
    accountId: number,
    botId: string,
    sendMessageToClientDto: SendMessageToClientDto,
    options?: any
  ) {
    return ConversationApiFp(this.configuration).sendMessageToClient(
      accountId,
      botId,
      sendMessageToClientDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Send message to the client
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {SendRepliesToClientDto} sendRepliesToClientDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConversationApi
   */
  public sendRepliesToClient(
    accountId: number,
    botId: string,
    sendRepliesToClientDto: SendRepliesToClientDto,
    options?: any
  ) {
    return ConversationApiFp(this.configuration).sendRepliesToClient(
      accountId,
      botId,
      sendRepliesToClientDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * CrmApi - axios parameter creator
 * @export
 */
export const CrmApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Process crm task
     * @param {number} accountId Account identifier
     * @param {ProcessCrmTaskDto} processCrmTaskDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCrmTask(accountId: number, processCrmTaskDto: ProcessCrmTaskDto, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling processCrmTask.'
        );
      }
      // verify required parameter 'processCrmTaskDto' is not null or undefined
      if (processCrmTaskDto === null || processCrmTaskDto === undefined) {
        throw new RequiredError(
          'processCrmTaskDto',
          'Required parameter processCrmTaskDto was null or undefined when calling processCrmTask.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/crm/process-task`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof processCrmTaskDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(processCrmTaskDto !== undefined ? processCrmTaskDto : {})
        : processCrmTaskDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CrmApi - functional programming interface
 * @export
 */
export const CrmApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Process crm task
     * @param {number} accountId Account identifier
     * @param {ProcessCrmTaskDto} processCrmTaskDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCrmTask(
      accountId: number,
      processCrmTaskDto: ProcessCrmTaskDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmResult> {
      const localVarAxiosArgs = CrmApiAxiosParamCreator(configuration).processCrmTask(
        accountId,
        processCrmTaskDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * CrmApi - factory interface
 * @export
 */
export const CrmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Process crm task
     * @param {number} accountId Account identifier
     * @param {ProcessCrmTaskDto} processCrmTaskDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    processCrmTask(accountId: number, processCrmTaskDto: ProcessCrmTaskDto, options?: any): AxiosPromise<CrmResult> {
      return CrmApiFp(configuration).processCrmTask(accountId, processCrmTaskDto, options)(axios, basePath);
    },
  };
};

/**
 * CrmApi - object-oriented interface
 * @export
 * @class CrmApi
 * @extends {BaseAPI}
 */
export class CrmApi extends BaseAPI {
  /**
   *
   * @summary Process crm task
   * @param {number} accountId Account identifier
   * @param {ProcessCrmTaskDto} processCrmTaskDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrmApi
   */
  public processCrmTask(accountId: number, processCrmTaskDto: ProcessCrmTaskDto, options?: any) {
    return CrmApiFp(this.configuration).processCrmTask(
      accountId,
      processCrmTaskDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary send email
     * @param {number} accountId Account identifier
     * @param {SendEmailDto} sendEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmail(accountId: number, sendEmailDto: SendEmailDto, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling sendEmail.'
        );
      }
      // verify required parameter 'sendEmailDto' is not null or undefined
      if (sendEmailDto === null || sendEmailDto === undefined) {
        throw new RequiredError(
          'sendEmailDto',
          'Required parameter sendEmailDto was null or undefined when calling sendEmail.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/email/send`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendEmailDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendEmailDto !== undefined ? sendEmailDto : {})
        : sendEmailDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary send email
     * @param {number} accountId Account identifier
     * @param {SendEmailDto} sendEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmail(
      accountId: number,
      sendEmailDto: SendEmailDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = EmailApiAxiosParamCreator(configuration).sendEmail(accountId, sendEmailDto, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary send email
     * @param {number} accountId Account identifier
     * @param {SendEmailDto} sendEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmail(accountId: number, sendEmailDto: SendEmailDto, options?: any): AxiosPromise<ResultDto> {
      return EmailApiFp(configuration).sendEmail(accountId, sendEmailDto, options)(axios, basePath);
    },
  };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
  /**
   *
   * @summary send email
   * @param {number} accountId Account identifier
   * @param {SendEmailDto} sendEmailDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public sendEmail(accountId: number, sendEmailDto: SendEmailDto, options?: any) {
    return EmailApiFp(this.configuration).sendEmail(accountId, sendEmailDto, options)(this.axios, this.basePath);
  }
}

/**
 * ExternalBotApi - axios parameter creator
 * @export
 */
export const ExternalBotApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalBot(accountId: number, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteExternalBot.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling deleteExternalBot.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/external-bot/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalBot(accountId: number, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getExternalBot.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError('botId', 'Required parameter botId was null or undefined when calling getExternalBot.');
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/external-bot/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {CreateExternalBotDto} createExternalBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerExternalBot(
      accountId: number,
      botId: string,
      createExternalBotDto: CreateExternalBotDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling registerExternalBot.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling registerExternalBot.'
        );
      }
      // verify required parameter 'createExternalBotDto' is not null or undefined
      if (createExternalBotDto === null || createExternalBotDto === undefined) {
        throw new RequiredError(
          'createExternalBotDto',
          'Required parameter createExternalBotDto was null or undefined when calling registerExternalBot.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/external-bot/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createExternalBotDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createExternalBotDto !== undefined ? createExternalBotDto : {})
        : createExternalBotDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateExternalBotDto} updateExternalBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExternalBot(
      accountId: number,
      botId: string,
      updateExternalBotDto: UpdateExternalBotDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateExternalBot.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling updateExternalBot.'
        );
      }
      // verify required parameter 'updateExternalBotDto' is not null or undefined
      if (updateExternalBotDto === null || updateExternalBotDto === undefined) {
        throw new RequiredError(
          'updateExternalBotDto',
          'Required parameter updateExternalBotDto was null or undefined when calling updateExternalBot.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/external-bot/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateExternalBotDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateExternalBotDto !== undefined ? updateExternalBotDto : {})
        : updateExternalBotDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExternalBotApi - functional programming interface
 * @export
 */
export const ExternalBotApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalBot(
      accountId: number,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ExternalBotApiAxiosParamCreator(configuration).deleteExternalBot(
        accountId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalBot(
      accountId: number,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalBotDto> {
      const localVarAxiosArgs = ExternalBotApiAxiosParamCreator(configuration).getExternalBot(
        accountId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Register external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {CreateExternalBotDto} createExternalBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerExternalBot(
      accountId: number,
      botId: string,
      createExternalBotDto: CreateExternalBotDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalBotDto> {
      const localVarAxiosArgs = ExternalBotApiAxiosParamCreator(configuration).registerExternalBot(
        accountId,
        botId,
        createExternalBotDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateExternalBotDto} updateExternalBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExternalBot(
      accountId: number,
      botId: string,
      updateExternalBotDto: UpdateExternalBotDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalBotDto> {
      const localVarAxiosArgs = ExternalBotApiAxiosParamCreator(configuration).updateExternalBot(
        accountId,
        botId,
        updateExternalBotDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ExternalBotApi - factory interface
 * @export
 */
export const ExternalBotApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Delete external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteExternalBot(accountId: number, botId: string, options?: any): AxiosPromise<void> {
      return ExternalBotApiFp(configuration).deleteExternalBot(accountId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExternalBot(accountId: number, botId: string, options?: any): AxiosPromise<ExternalBotDto> {
      return ExternalBotApiFp(configuration).getExternalBot(accountId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Register external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {CreateExternalBotDto} createExternalBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerExternalBot(
      accountId: number,
      botId: string,
      createExternalBotDto: CreateExternalBotDto,
      options?: any
    ): AxiosPromise<ExternalBotDto> {
      return ExternalBotApiFp(configuration).registerExternalBot(
        accountId,
        botId,
        createExternalBotDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update external bot
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateExternalBotDto} updateExternalBotDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateExternalBot(
      accountId: number,
      botId: string,
      updateExternalBotDto: UpdateExternalBotDto,
      options?: any
    ): AxiosPromise<ExternalBotDto> {
      return ExternalBotApiFp(configuration).updateExternalBot(
        accountId,
        botId,
        updateExternalBotDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ExternalBotApi - object-oriented interface
 * @export
 * @class ExternalBotApi
 * @extends {BaseAPI}
 */
export class ExternalBotApi extends BaseAPI {
  /**
   *
   * @summary Delete external bot
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalBotApi
   */
  public deleteExternalBot(accountId: number, botId: string, options?: any) {
    return ExternalBotApiFp(this.configuration).deleteExternalBot(accountId, botId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get external bot
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalBotApi
   */
  public getExternalBot(accountId: number, botId: string, options?: any) {
    return ExternalBotApiFp(this.configuration).getExternalBot(accountId, botId, options)(this.axios, this.basePath);
  }

  /**
   *
   * @summary Register external bot
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {CreateExternalBotDto} createExternalBotDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalBotApi
   */
  public registerExternalBot(
    accountId: number,
    botId: string,
    createExternalBotDto: CreateExternalBotDto,
    options?: any
  ) {
    return ExternalBotApiFp(this.configuration).registerExternalBot(
      accountId,
      botId,
      createExternalBotDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update external bot
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {UpdateExternalBotDto} updateExternalBotDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalBotApi
   */
  public updateExternalBot(
    accountId: number,
    botId: string,
    updateExternalBotDto: UpdateExternalBotDto,
    options?: any
  ) {
    return ExternalBotApiFp(this.configuration).updateExternalBot(
      accountId,
      botId,
      updateExternalBotDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * InboundChannelApi - axios parameter creator
 * @export
 */
export const InboundChannelApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Block inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockInboundChannel(accountId: number, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling blockInboundChannel.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling blockInboundChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/block`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {CreateInboundChannelDto} createInboundChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInboundChannel(
      accountId: number,
      botId: string,
      createInboundChannelDto: CreateInboundChannelDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createInboundChannel.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling createInboundChannel.'
        );
      }
      // verify required parameter 'createInboundChannelDto' is not null or undefined
      if (createInboundChannelDto === null || createInboundChannelDto === undefined) {
        throw new RequiredError(
          'createInboundChannelDto',
          'Required parameter createInboundChannelDto was null or undefined when calling createInboundChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createInboundChannelDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createInboundChannelDto !== undefined ? createInboundChannelDto : {})
        : createInboundChannelDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInboundChannel(accountId: number, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteInboundChannel.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling deleteInboundChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInboundChannel(accountId: number, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getInboundChannel.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling getInboundChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unblock inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockInboundChannel(accountId: number, botId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling unblockInboundChannel.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling unblockInboundChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}/unblock`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateInboundChannelDto} updateInboundChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInboundChannel(
      accountId: number,
      botId: string,
      updateInboundChannelDto: UpdateInboundChannelDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateInboundChannel.'
        );
      }
      // verify required parameter 'botId' is not null or undefined
      if (botId === null || botId === undefined) {
        throw new RequiredError(
          'botId',
          'Required parameter botId was null or undefined when calling updateInboundChannel.'
        );
      }
      // verify required parameter 'updateInboundChannelDto' is not null or undefined
      if (updateInboundChannelDto === null || updateInboundChannelDto === undefined) {
        throw new RequiredError(
          'updateInboundChannelDto',
          'Required parameter updateInboundChannelDto was null or undefined when calling updateInboundChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/{botId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'botId'}}`, encodeURIComponent(String(botId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateInboundChannelDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateInboundChannelDto !== undefined ? updateInboundChannelDto : {})
        : updateInboundChannelDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate token
     * @param {number} accountId Account identifier
     * @param {string} channelType Inbound channel type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateInboundChannelToken(accountId: number, channelType: string, token: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling validateInboundChannelToken.'
        );
      }
      // verify required parameter 'channelType' is not null or undefined
      if (channelType === null || channelType === undefined) {
        throw new RequiredError(
          'channelType',
          'Required parameter channelType was null or undefined when calling validateInboundChannelToken.'
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling validateInboundChannelToken.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/inbound-channels/validate-token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      if (channelType !== undefined) {
        localVarQueryParameter['channelType'] = channelType;
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InboundChannelApi - functional programming interface
 * @export
 */
export const InboundChannelApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Block inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockInboundChannel(
      accountId: number,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = InboundChannelApiAxiosParamCreator(configuration).blockInboundChannel(
        accountId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Create inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {CreateInboundChannelDto} createInboundChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInboundChannel(
      accountId: number,
      botId: string,
      createInboundChannelDto: CreateInboundChannelDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundChannelDto> {
      const localVarAxiosArgs = InboundChannelApiAxiosParamCreator(configuration).createInboundChannel(
        accountId,
        botId,
        createInboundChannelDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInboundChannel(
      accountId: number,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = InboundChannelApiAxiosParamCreator(configuration).deleteInboundChannel(
        accountId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInboundChannel(
      accountId: number,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundChannelDto> {
      const localVarAxiosArgs = InboundChannelApiAxiosParamCreator(configuration).getInboundChannel(
        accountId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Unblock inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockInboundChannel(
      accountId: number,
      botId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = InboundChannelApiAxiosParamCreator(configuration).unblockInboundChannel(
        accountId,
        botId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateInboundChannelDto} updateInboundChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInboundChannel(
      accountId: number,
      botId: string,
      updateInboundChannelDto: UpdateInboundChannelDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundChannelDto> {
      const localVarAxiosArgs = InboundChannelApiAxiosParamCreator(configuration).updateInboundChannel(
        accountId,
        botId,
        updateInboundChannelDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Validate token
     * @param {number} accountId Account identifier
     * @param {string} channelType Inbound channel type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateInboundChannelToken(
      accountId: number,
      channelType: string,
      token: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = InboundChannelApiAxiosParamCreator(configuration).validateInboundChannelToken(
        accountId,
        channelType,
        token,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * InboundChannelApi - factory interface
 * @export
 */
export const InboundChannelApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Block inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockInboundChannel(accountId: number, botId: string, options?: any): AxiosPromise<ResultDto> {
      return InboundChannelApiFp(configuration).blockInboundChannel(accountId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Create inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {CreateInboundChannelDto} createInboundChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInboundChannel(
      accountId: number,
      botId: string,
      createInboundChannelDto: CreateInboundChannelDto,
      options?: any
    ): AxiosPromise<InboundChannelDto> {
      return InboundChannelApiFp(configuration).createInboundChannel(
        accountId,
        botId,
        createInboundChannelDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInboundChannel(accountId: number, botId: string, options?: any): AxiosPromise<void> {
      return InboundChannelApiFp(configuration).deleteInboundChannel(accountId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Get inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInboundChannel(accountId: number, botId: string, options?: any): AxiosPromise<InboundChannelDto> {
      return InboundChannelApiFp(configuration).getInboundChannel(accountId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Unblock inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unblockInboundChannel(accountId: number, botId: string, options?: any): AxiosPromise<ResultDto> {
      return InboundChannelApiFp(configuration).unblockInboundChannel(accountId, botId, options)(axios, basePath);
    },
    /**
     *
     * @summary Update inbound channel
     * @param {number} accountId Account identifier
     * @param {string} botId Inbound channel (bot) identifier
     * @param {UpdateInboundChannelDto} updateInboundChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInboundChannel(
      accountId: number,
      botId: string,
      updateInboundChannelDto: UpdateInboundChannelDto,
      options?: any
    ): AxiosPromise<InboundChannelDto> {
      return InboundChannelApiFp(configuration).updateInboundChannel(
        accountId,
        botId,
        updateInboundChannelDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Validate token
     * @param {number} accountId Account identifier
     * @param {string} channelType Inbound channel type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateInboundChannelToken(
      accountId: number,
      channelType: string,
      token: string,
      options?: any
    ): AxiosPromise<ResultDto> {
      return InboundChannelApiFp(configuration).validateInboundChannelToken(
        accountId,
        channelType,
        token,
        options
      )(axios, basePath);
    },
  };
};

/**
 * InboundChannelApi - object-oriented interface
 * @export
 * @class InboundChannelApi
 * @extends {BaseAPI}
 */
export class InboundChannelApi extends BaseAPI {
  /**
   *
   * @summary Block inbound channel
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InboundChannelApi
   */
  public blockInboundChannel(accountId: number, botId: string, options?: any) {
    return InboundChannelApiFp(this.configuration).blockInboundChannel(
      accountId,
      botId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Create inbound channel
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {CreateInboundChannelDto} createInboundChannelDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InboundChannelApi
   */
  public createInboundChannel(
    accountId: number,
    botId: string,
    createInboundChannelDto: CreateInboundChannelDto,
    options?: any
  ) {
    return InboundChannelApiFp(this.configuration).createInboundChannel(
      accountId,
      botId,
      createInboundChannelDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete inbound channel
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InboundChannelApi
   */
  public deleteInboundChannel(accountId: number, botId: string, options?: any) {
    return InboundChannelApiFp(this.configuration).deleteInboundChannel(
      accountId,
      botId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get inbound channel
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InboundChannelApi
   */
  public getInboundChannel(accountId: number, botId: string, options?: any) {
    return InboundChannelApiFp(this.configuration).getInboundChannel(
      accountId,
      botId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Unblock inbound channel
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InboundChannelApi
   */
  public unblockInboundChannel(accountId: number, botId: string, options?: any) {
    return InboundChannelApiFp(this.configuration).unblockInboundChannel(
      accountId,
      botId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update inbound channel
   * @param {number} accountId Account identifier
   * @param {string} botId Inbound channel (bot) identifier
   * @param {UpdateInboundChannelDto} updateInboundChannelDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InboundChannelApi
   */
  public updateInboundChannel(
    accountId: number,
    botId: string,
    updateInboundChannelDto: UpdateInboundChannelDto,
    options?: any
  ) {
    return InboundChannelApiFp(this.configuration).updateInboundChannel(
      accountId,
      botId,
      updateInboundChannelDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Validate token
   * @param {number} accountId Account identifier
   * @param {string} channelType Inbound channel type
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InboundChannelApi
   */
  public validateInboundChannelToken(accountId: number, channelType: string, token: string, options?: any) {
    return InboundChannelApiFp(this.configuration).validateInboundChannelToken(
      accountId,
      channelType,
      token,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * MetaChannelApi - axios parameter creator
 * @export
 */
export const MetaChannelApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get meta channel token
     * @param {number} accountId Account identifier
     * @param {string} channelType Inbound channel type
     * @param {MetaChannelTokenRequiredDto} metaChannelTokenRequiredDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetaChannelToken(
      accountId: number,
      channelType: string,
      metaChannelTokenRequiredDto: MetaChannelTokenRequiredDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getMetaChannelToken.'
        );
      }
      // verify required parameter 'channelType' is not null or undefined
      if (channelType === null || channelType === undefined) {
        throw new RequiredError(
          'channelType',
          'Required parameter channelType was null or undefined when calling getMetaChannelToken.'
        );
      }
      // verify required parameter 'metaChannelTokenRequiredDto' is not null or undefined
      if (metaChannelTokenRequiredDto === null || metaChannelTokenRequiredDto === undefined) {
        throw new RequiredError(
          'metaChannelTokenRequiredDto',
          'Required parameter metaChannelTokenRequiredDto was null or undefined when calling getMetaChannelToken.'
        );
      }
      const localVarPath = `/api/chatadapter/meta-channels/accounts/{accountId}/tokens`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      if (channelType !== undefined) {
        localVarQueryParameter['channelType'] = channelType;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof metaChannelTokenRequiredDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(metaChannelTokenRequiredDto !== undefined ? metaChannelTokenRequiredDto : {})
        : metaChannelTokenRequiredDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MetaChannelApi - functional programming interface
 * @export
 */
export const MetaChannelApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get meta channel token
     * @param {number} accountId Account identifier
     * @param {string} channelType Inbound channel type
     * @param {MetaChannelTokenRequiredDto} metaChannelTokenRequiredDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetaChannelToken(
      accountId: number,
      channelType: string,
      metaChannelTokenRequiredDto: MetaChannelTokenRequiredDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaChannelTokenResultDto> {
      const localVarAxiosArgs = MetaChannelApiAxiosParamCreator(configuration).getMetaChannelToken(
        accountId,
        channelType,
        metaChannelTokenRequiredDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * MetaChannelApi - factory interface
 * @export
 */
export const MetaChannelApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get meta channel token
     * @param {number} accountId Account identifier
     * @param {string} channelType Inbound channel type
     * @param {MetaChannelTokenRequiredDto} metaChannelTokenRequiredDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMetaChannelToken(
      accountId: number,
      channelType: string,
      metaChannelTokenRequiredDto: MetaChannelTokenRequiredDto,
      options?: any
    ): AxiosPromise<MetaChannelTokenResultDto> {
      return MetaChannelApiFp(configuration).getMetaChannelToken(
        accountId,
        channelType,
        metaChannelTokenRequiredDto,
        options
      )(axios, basePath);
    },
  };
};

/**
 * MetaChannelApi - object-oriented interface
 * @export
 * @class MetaChannelApi
 * @extends {BaseAPI}
 */
export class MetaChannelApi extends BaseAPI {
  /**
   *
   * @summary Get meta channel token
   * @param {number} accountId Account identifier
   * @param {string} channelType Inbound channel type
   * @param {MetaChannelTokenRequiredDto} metaChannelTokenRequiredDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MetaChannelApi
   */
  public getMetaChannelToken(
    accountId: number,
    channelType: string,
    metaChannelTokenRequiredDto: MetaChannelTokenRequiredDto,
    options?: any
  ) {
    return MetaChannelApiFp(this.configuration).getMetaChannelToken(
      accountId,
      channelType,
      metaChannelTokenRequiredDto,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * OperatorChannelApi - axios parameter creator
 * @export
 */
export const OperatorChannelApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {CreateOperatorChannelDto} createOperatorChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      createOperatorChannelDto: CreateOperatorChannelDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling createOperatorChannel.'
        );
      }
      // verify required parameter 'operatorChannelId' is not null or undefined
      if (operatorChannelId === null || operatorChannelId === undefined) {
        throw new RequiredError(
          'operatorChannelId',
          'Required parameter operatorChannelId was null or undefined when calling createOperatorChannel.'
        );
      }
      // verify required parameter 'createOperatorChannelDto' is not null or undefined
      if (createOperatorChannelDto === null || createOperatorChannelDto === undefined) {
        throw new RequiredError(
          'createOperatorChannelDto',
          'Required parameter createOperatorChannelDto was null or undefined when calling createOperatorChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/operator-channels/{operatorChannelId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorChannelId'}}`, encodeURIComponent(String(operatorChannelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof createOperatorChannelDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createOperatorChannelDto !== undefined ? createOperatorChannelDto : {})
        : createOperatorChannelDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperatorChannel(accountId: number, operatorChannelId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling deleteOperatorChannel.'
        );
      }
      // verify required parameter 'operatorChannelId' is not null or undefined
      if (operatorChannelId === null || operatorChannelId === undefined) {
        throw new RequiredError(
          'operatorChannelId',
          'Required parameter operatorChannelId was null or undefined when calling deleteOperatorChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/operator-channels/{operatorChannelId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorChannelId'}}`, encodeURIComponent(String(operatorChannelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorChannel(accountId: number, operatorChannelId: string, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling getOperatorChannel.'
        );
      }
      // verify required parameter 'operatorChannelId' is not null or undefined
      if (operatorChannelId === null || operatorChannelId === undefined) {
        throw new RequiredError(
          'operatorChannelId',
          'Required parameter operatorChannelId was null or undefined when calling getOperatorChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/operator-channels/{operatorChannelId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorChannelId'}}`, encodeURIComponent(String(operatorChannelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {UpdateOperatorChannelDto} updateOperatorChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      updateOperatorChannelDto: UpdateOperatorChannelDto,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling updateOperatorChannel.'
        );
      }
      // verify required parameter 'operatorChannelId' is not null or undefined
      if (operatorChannelId === null || operatorChannelId === undefined) {
        throw new RequiredError(
          'operatorChannelId',
          'Required parameter operatorChannelId was null or undefined when calling updateOperatorChannel.'
        );
      }
      // verify required parameter 'updateOperatorChannelDto' is not null or undefined
      if (updateOperatorChannelDto === null || updateOperatorChannelDto === undefined) {
        throw new RequiredError(
          'updateOperatorChannelDto',
          'Required parameter updateOperatorChannelDto was null or undefined when calling updateOperatorChannel.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/operator-channels/{operatorChannelId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'operatorChannelId'}}`, encodeURIComponent(String(operatorChannelId)));
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof updateOperatorChannelDto !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(updateOperatorChannelDto !== undefined ? updateOperatorChannelDto : {})
        : updateOperatorChannelDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate token
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelType Operator channel type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateOperatorChannelToken(
      accountId: number,
      operatorChannelType: string,
      token: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling validateOperatorChannelToken.'
        );
      }
      // verify required parameter 'operatorChannelType' is not null or undefined
      if (operatorChannelType === null || operatorChannelType === undefined) {
        throw new RequiredError(
          'operatorChannelType',
          'Required parameter operatorChannelType was null or undefined when calling validateOperatorChannelToken.'
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling validateOperatorChannelToken.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/operator-channels/validate-token`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      if (operatorChannelType !== undefined) {
        localVarQueryParameter['operatorChannelType'] = operatorChannelType;
      }

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OperatorChannelApi - functional programming interface
 * @export
 */
export const OperatorChannelApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {CreateOperatorChannelDto} createOperatorChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      createOperatorChannelDto: CreateOperatorChannelDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorChannelDto> {
      const localVarAxiosArgs = OperatorChannelApiAxiosParamCreator(configuration).createOperatorChannel(
        accountId,
        operatorChannelId,
        createOperatorChannelDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OperatorChannelApiAxiosParamCreator(configuration).deleteOperatorChannel(
        accountId,
        operatorChannelId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorChannelDto> {
      const localVarAxiosArgs = OperatorChannelApiAxiosParamCreator(configuration).getOperatorChannel(
        accountId,
        operatorChannelId,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {UpdateOperatorChannelDto} updateOperatorChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      updateOperatorChannelDto: UpdateOperatorChannelDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperatorChannelDto> {
      const localVarAxiosArgs = OperatorChannelApiAxiosParamCreator(configuration).updateOperatorChannel(
        accountId,
        operatorChannelId,
        updateOperatorChannelDto,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Validate token
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelType Operator channel type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateOperatorChannelToken(
      accountId: number,
      operatorChannelType: string,
      token: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDto> {
      const localVarAxiosArgs = OperatorChannelApiAxiosParamCreator(configuration).validateOperatorChannelToken(
        accountId,
        operatorChannelType,
        token,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OperatorChannelApi - factory interface
 * @export
 */
export const OperatorChannelApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Create operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {CreateOperatorChannelDto} createOperatorChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      createOperatorChannelDto: CreateOperatorChannelDto,
      options?: any
    ): AxiosPromise<OperatorChannelDto> {
      return OperatorChannelApiFp(configuration).createOperatorChannel(
        accountId,
        operatorChannelId,
        createOperatorChannelDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Delete operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOperatorChannel(accountId: number, operatorChannelId: string, options?: any): AxiosPromise<void> {
      return OperatorChannelApiFp(configuration).deleteOperatorChannel(
        accountId,
        operatorChannelId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Get operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOperatorChannel(accountId: number, operatorChannelId: string, options?: any): AxiosPromise<OperatorChannelDto> {
      return OperatorChannelApiFp(configuration).getOperatorChannel(
        accountId,
        operatorChannelId,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Update operator channel
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelId Operator channel identifier
     * @param {UpdateOperatorChannelDto} updateOperatorChannelDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOperatorChannel(
      accountId: number,
      operatorChannelId: string,
      updateOperatorChannelDto: UpdateOperatorChannelDto,
      options?: any
    ): AxiosPromise<OperatorChannelDto> {
      return OperatorChannelApiFp(configuration).updateOperatorChannel(
        accountId,
        operatorChannelId,
        updateOperatorChannelDto,
        options
      )(axios, basePath);
    },
    /**
     *
     * @summary Validate token
     * @param {number} accountId Account identifier
     * @param {string} operatorChannelType Operator channel type
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateOperatorChannelToken(
      accountId: number,
      operatorChannelType: string,
      token: string,
      options?: any
    ): AxiosPromise<ResultDto> {
      return OperatorChannelApiFp(configuration).validateOperatorChannelToken(
        accountId,
        operatorChannelType,
        token,
        options
      )(axios, basePath);
    },
  };
};

/**
 * OperatorChannelApi - object-oriented interface
 * @export
 * @class OperatorChannelApi
 * @extends {BaseAPI}
 */
export class OperatorChannelApi extends BaseAPI {
  /**
   *
   * @summary Create operator channel
   * @param {number} accountId Account identifier
   * @param {string} operatorChannelId Operator channel identifier
   * @param {CreateOperatorChannelDto} createOperatorChannelDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorChannelApi
   */
  public createOperatorChannel(
    accountId: number,
    operatorChannelId: string,
    createOperatorChannelDto: CreateOperatorChannelDto,
    options?: any
  ) {
    return OperatorChannelApiFp(this.configuration).createOperatorChannel(
      accountId,
      operatorChannelId,
      createOperatorChannelDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Delete operator channel
   * @param {number} accountId Account identifier
   * @param {string} operatorChannelId Operator channel identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorChannelApi
   */
  public deleteOperatorChannel(accountId: number, operatorChannelId: string, options?: any) {
    return OperatorChannelApiFp(this.configuration).deleteOperatorChannel(
      accountId,
      operatorChannelId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Get operator channel
   * @param {number} accountId Account identifier
   * @param {string} operatorChannelId Operator channel identifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorChannelApi
   */
  public getOperatorChannel(accountId: number, operatorChannelId: string, options?: any) {
    return OperatorChannelApiFp(this.configuration).getOperatorChannel(
      accountId,
      operatorChannelId,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Update operator channel
   * @param {number} accountId Account identifier
   * @param {string} operatorChannelId Operator channel identifier
   * @param {UpdateOperatorChannelDto} updateOperatorChannelDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorChannelApi
   */
  public updateOperatorChannel(
    accountId: number,
    operatorChannelId: string,
    updateOperatorChannelDto: UpdateOperatorChannelDto,
    options?: any
  ) {
    return OperatorChannelApiFp(this.configuration).updateOperatorChannel(
      accountId,
      operatorChannelId,
      updateOperatorChannelDto,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @summary Validate token
   * @param {number} accountId Account identifier
   * @param {string} operatorChannelType Operator channel type
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OperatorChannelApi
   */
  public validateOperatorChannelToken(accountId: number, operatorChannelType: string, token: string, options?: any) {
    return OperatorChannelApiFp(this.configuration).validateOperatorChannelToken(
      accountId,
      operatorChannelType,
      token,
      options
    )(this.axios, this.basePath);
  }
}

/**
 * SmsApi - axios parameter creator
 * @export
 */
export const SmsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Send sms
     * @param {number} accountId Account identifier
     * @param {SendSmsDto} sendSmsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms(accountId: number, sendSmsDto: SendSmsDto, options: any = {}): RequestArgs {
      // verify required parameter 'accountId' is not null or undefined
      if (accountId === null || accountId === undefined) {
        throw new RequiredError(
          'accountId',
          'Required parameter accountId was null or undefined when calling sendSms.'
        );
      }
      // verify required parameter 'sendSmsDto' is not null or undefined
      if (sendSmsDto === null || sendSmsDto === undefined) {
        throw new RequiredError(
          'sendSmsDto',
          'Required parameter sendSmsDto was null or undefined when calling sendSms.'
        );
      }
      const localVarPath = `/api/chatadapter/accounts/{accountId}/sms/send`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof sendSmsDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendSmsDto !== undefined ? sendSmsDto : {})
        : sendSmsDto || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SmsApi - functional programming interface
 * @export
 */
export const SmsApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Send sms
     * @param {number} accountId Account identifier
     * @param {SendSmsDto} sendSmsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms(
      accountId: number,
      sendSmsDto: SendSmsDto,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsSendingStatusDto> {
      const localVarAxiosArgs = SmsApiAxiosParamCreator(configuration).sendSms(accountId, sendSmsDto, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * SmsApi - factory interface
 * @export
 */
export const SmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Send sms
     * @param {number} accountId Account identifier
     * @param {SendSmsDto} sendSmsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendSms(accountId: number, sendSmsDto: SendSmsDto, options?: any): AxiosPromise<SmsSendingStatusDto> {
      return SmsApiFp(configuration).sendSms(accountId, sendSmsDto, options)(axios, basePath);
    },
  };
};

/**
 * SmsApi - object-oriented interface
 * @export
 * @class SmsApi
 * @extends {BaseAPI}
 */
export class SmsApi extends BaseAPI {
  /**
   *
   * @summary Send sms
   * @param {number} accountId Account identifier
   * @param {SendSmsDto} sendSmsDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SmsApi
   */
  public sendSms(accountId: number, sendSmsDto: SendSmsDto, options?: any) {
    return SmsApiFp(this.configuration).sendSms(accountId, sendSmsDto, options)(this.axios, this.basePath);
  }
}

/**
 * TelephonyApi - axios parameter creator
 * @export
 */
export const TelephonyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<LinesQueryData>} linesQueryData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableLines(linesQueryData: Array<LinesQueryData>, options: any = {}): RequestArgs {
      // verify required parameter 'linesQueryData' is not null or undefined
      if (linesQueryData === null || linesQueryData === undefined) {
        throw new RequiredError(
          'linesQueryData',
          'Required parameter linesQueryData was null or undefined when calling getAvailableLines.'
        );
      }
      const localVarPath = `/restapi/telephony/get-available-lines`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof linesQueryData !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(linesQueryData !== undefined ? linesQueryData : {})
        : linesQueryData || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<OriginateQueryItem>} originateQueryItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    originate(originateQueryItem: Array<OriginateQueryItem>, options: any = {}): RequestArgs {
      // verify required parameter 'originateQueryItem' is not null or undefined
      if (originateQueryItem === null || originateQueryItem === undefined) {
        throw new RequiredError(
          'originateQueryItem',
          'Required parameter originateQueryItem was null or undefined when calling originate.'
        );
      }
      const localVarPath = `/restapi/telephony/originate`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      if (configuration && (configuration.username || configuration.password)) {
        localVarRequestOptions['auth'] = { username: configuration.username, password: configuration.password };
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        typeof originateQueryItem !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(originateQueryItem !== undefined ? originateQueryItem : {})
        : originateQueryItem || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TelephonyApi - functional programming interface
 * @export
 */
export const TelephonyApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {Array<LinesQueryData>} linesQueryData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableLines(
      linesQueryData: Array<LinesQueryData>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinesResponseData>> {
      const localVarAxiosArgs = TelephonyApiAxiosParamCreator(configuration).getAvailableLines(linesQueryData, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {Array<OriginateQueryItem>} originateQueryItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    originate(
      originateQueryItem: Array<OriginateQueryItem>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = TelephonyApiAxiosParamCreator(configuration).originate(originateQueryItem, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * TelephonyApi - factory interface
 * @export
 */
export const TelephonyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {Array<LinesQueryData>} linesQueryData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableLines(linesQueryData: Array<LinesQueryData>, options?: any): AxiosPromise<Array<LinesResponseData>> {
      return TelephonyApiFp(configuration).getAvailableLines(linesQueryData, options)(axios, basePath);
    },
    /**
     *
     * @param {Array<OriginateQueryItem>} originateQueryItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    originate(originateQueryItem: Array<OriginateQueryItem>, options?: any): AxiosPromise<void> {
      return TelephonyApiFp(configuration).originate(originateQueryItem, options)(axios, basePath);
    },
  };
};

/**
 * TelephonyApi - object-oriented interface
 * @export
 * @class TelephonyApi
 * @extends {BaseAPI}
 */
export class TelephonyApi extends BaseAPI {
  /**
   *
   * @param {Array<LinesQueryData>} linesQueryData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelephonyApi
   */
  public getAvailableLines(linesQueryData: Array<LinesQueryData>, options?: any) {
    return TelephonyApiFp(this.configuration).getAvailableLines(linesQueryData, options)(this.axios, this.basePath);
  }

  /**
   *
   * @param {Array<OriginateQueryItem>} originateQueryItem
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TelephonyApi
   */
  public originate(originateQueryItem: Array<OriginateQueryItem>, options?: any) {
    return TelephonyApiFp(this.configuration).originate(originateQueryItem, options)(this.axios, this.basePath);
  }
}
