import React from 'react';
import cn from 'classnames';
import { TextareaAutosize as TextareaAutosizeFromJustUi, TextareaAutosizeProps } from '@just-ai/just-ui';

import styles from './styles.module.scss';

interface TextareaProps extends TextareaAutosizeProps {}
const TextareaAutosize = (props: TextareaProps) => {
  return <TextareaAutosizeFromJustUi {...props} className={cn(styles.textAreaForWizard, props.className)} />;
};

export default React.memo(TextareaAutosize);
