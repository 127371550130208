const drawerWidth = 260;
const drawerPaperCloseWidth = 80;

const styles = theme => ({
  headerBoundPlace: {
    flex: '1 0 auto',
    textAlign: 'right',
    '@media (max-width: 1400px)': {
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 'calc(100vw - 88px - (100vw - 980px))',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
  },
  popperClose: {
    pointerEvents: 'none',
  },
  collapseRoot: {
    maxHeight: 'calc( 100vh - 80px )',
    overflowY: 'auto',
  },
  menuToggler: {
    border: 'none',
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'inherit',
    padding: '7px 20px 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'auto',
    maxWidth: '100%',
    display: 'block',
    '&>span>svg': {
      marginBottom: '-3px',
    },
  },
  flexCenter: {
    display: 'initial',
  },
  headerBotSelector: {
    cursor: 'pointer',
    padding: '8px 40px',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    '&:hover': {
      backgroundColor: 'rgba(217, 217, 217, 0.2);',
    },
  },
  appBar: {
    boxShadow: 'none',
    position: 'absolute',
    zIndex: 1000,
    width: `100%`,
    background: 'var(--white)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: `1px solid var(--grey-200)`,
  },
  appBarCalling: {
    marginLeft: 0,
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
  },

  docsButton: {
    padding: '0 13px',
  },
  toolbar: {
    padding: '0 1em',
    minHeight: '56px',
    '@media (max-width: 1400px)': {
      paddingBottom: '0.5rem',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  headerSearch: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  callsLinks: {
    paddingRight: 40,
    '& a': {
      whiteSpace: 'nowrap',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '30px',
      textTransform: 'uppercase',
      textDecoration: 'none',
      marginLeft: 30,
    },
  },
  partnerBtn: {
    textDecoration: 'none',
  },
  headerTariffInfoBlock: {
    borderRight: `1px solid var(--grey-100)`,
    maxHeight: 56,
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  borderRight: {
    borderRight: `1px solid var(--grey-100)`,
    paddingRight: 8,
    marginRight: '8px !important',
  },
  tariffName: {
    '&.upp': {
      letterSpacing: 'normal',
    },
  },
  tariffInfo: {
    marginTop: -3,
    cursor: 'pointer',
  },
  tariffInfoPopover: {
    borderRadius: 0,
    padding: theme.spacing.unit * 2,
    marginTop: -theme.spacing.unit * 2,
    marginRight: -theme.spacing.unit * 2,
    marginBottom: -theme.spacing.unit * 2,
    maxWidth: 440,
    marginLeft: -67,
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&:before': {
      display: 'block',
      width: 0,
      height: 0,
      content: '" "',
      position: 'absolute',
      top: 8,
      left: 29,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: `8px solid var(--grey-100)`,
    },
    '&:after': {
      display: 'block',
      width: 0,
      height: 0,
      content: '" "',
      position: 'absolute',
      top: 10,
      left: 30,
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderBottom: `6px solid var(--white)`,
    },
  },
  tariffInfoPopoverContent: {
    borderRadius: 3,
    padding: theme.spacing.unit * 2,
    backgroundColor: 'var(--white)',
    boxShadow: '0 0 1px 1px rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16)',
  },
  tariffInfoItem: {
    backgroundColor: 'var(--gray-100)',
    borderRadius: 3,
    padding: 6,
  },
  gridSpacing4: {
    margin: -2,

    '&>div': {
      padding: '2px !important',
    },
  },
  infinityIcon: {
    fill: `var(--grey-950) !important`,
  },
  docsLink: {
    fontSize: 14,
    textDecoration: 'none',
  },
  widthAuto: {
    width: 'auto',
  },
});

export default styles;
