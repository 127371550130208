const prefix = '@catchAll/';

export const LOAD_CATCH_ALL_SETTINGS = prefix + 'LOAD_CATCH_ALL_SETTINGS';
export const LOAD_CATCH_ALL_SETTINGS_PENDING = prefix + 'LOAD_CATCH_ALL_SETTINGS_PENDING';
export const LOAD_CATCH_ALL_SETTINGS_FULFILLED = prefix + 'LOAD_CATCH_ALL_SETTINGS_FULFILLED';
export const LOAD_CATCH_ALL_SETTINGS_REJECTED = prefix + 'LOAD_CATCH_ALL_SETTINGS_REJECTED';

export const LOAD_CATCH_ALL_ERROR_MESSAGES = prefix + 'LOAD_CATCH_ALL_ERROR_MESSAGES';
export const LOAD_CATCH_ALL_ERROR_MESSAGES_PENDING = prefix + 'LOAD_CATCH_ALL_ERROR_MESSAGES_PENDING';
export const LOAD_CATCH_ALL_ERROR_MESSAGES_FULFILLED = prefix + 'LOAD_CATCH_ALL_ERROR_MESSAGES_FULFILLED';
export const LOAD_CATCH_ALL_ERROR_MESSAGES_REJECTED = prefix + 'LOAD_CATCH_ALL_ERROR_MESSAGES_REJECTED';

export const SAVE_CATCH_ALL_ERROR_MESSAGES = prefix + 'SAVE_CATCH_ALL_ERROR_MESSAGES';
export const SAVE_CATCH_ALL_ERROR_MESSAGES_PENDING = prefix + 'SAVE_CATCH_ALL_ERROR_MESSAGES_PENDING';
export const SAVE_CATCH_ALL_ERROR_MESSAGES_FULFILLED = prefix + 'SAVE_CATCH_ALL_ERROR_MESSAGES_FULFILLED';
export const SAVE_CATCH_ALL_ERROR_MESSAGES_REJECTED = prefix + 'SAVE_CATCH_ALL_ERROR_MESSAGES_REJECTED';

export const LOAD_SIMPLE_WIDGET_CHANNEL = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS';
export const LOAD_SIMPLE_WIDGET_CHANNEL_PENDING = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS_PENDING';
export const LOAD_SIMPLE_WIDGET_CHANNEL_FULFILLED = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS_FULFILLED';
export const LOAD_SIMPLE_WIDGET_CHANNEL_REJECTED = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS_REJECTED';

export const LOAD_VOICE_WIDGET_CHANNEL = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS';
export const LOAD_VOICE_WIDGET_CHANNEL_PENDING = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS_PENDING';
export const LOAD_VOICE_WIDGET_CHANNEL_FULFILLED = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS_FULFILLED';
export const LOAD_VOICE_WIDGET_CHANNEL_REJECTED = prefix + 'LOAD_SIMPLE_WIDGET_CHANNELS_REJECTED';

export const SAVE_CATCH_ALL_SETTINGS = prefix + 'SAVE_CATCH_ALL_SETTINGS';
export const SAVE_CATCH_ALL_SETTINGS_PENDING = prefix + 'SAVE_CATCH_ALL_SETTINGS_PENDING';
export const SAVE_CATCH_ALL_SETTINGS_FULFILLED = prefix + 'SAVE_CATCH_ALL_SETTINGS_FULFILLED';
export const SAVE_CATCH_ALL_SETTINGS_REJECTED = prefix + 'SAVE_CATCH_ALL_SETTINGS_REJECTED';

export const LOAD_VARIABLES = prefix + 'LOAD_VARIABLES';
export const LOAD_VARIABLES_PENDING = prefix + 'LOAD_VARIABLES_PENDING';
export const LOAD_VARIABLES_FULFILLED = prefix + 'LOAD_VARIABLES_FULFILLED';
export const LOAD_VARIABLES_REJECTED = prefix + 'LOAD_VARIABLES_REJECTED';

export const SAVE_VARIABLES = prefix + 'SAVE_VARIABLES';
export const SAVE_VARIABLES_PENDING = prefix + 'SAVE_VARIABLES_PENDING';
export const SAVE_VARIABLES_FULFILLED = prefix + 'SAVE_VARIABLES_FULFILLED';
export const SAVE_VARIABLES_REJECTED = prefix + 'SAVE_CATCH_ALL_SETTINGS_REJECTED';
