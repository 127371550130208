import React, { useCallback } from 'react';
import cn from 'classnames';
import { Icon, Button, useId } from '@just-ai/just-ui';

import { t } from 'localization';

import TextareaAutosize from 'modules/TemplatesWizard/primitives/TextareaAutosize';
import WizardTooltip from 'modules/TemplatesWizard/primitives/WizardTooltip';
import InputWithDropdown from 'modules/TemplatesWizard/primitives/InputWithDropdown';

import { FAQQuestionsRow, FAQQuestionsSuggest } from '../index';
import styles from '../styles.module.scss';

interface FieldsCollectionsProps {
  row: FAQQuestionsRow & { index: number; suggests?: FAQQuestionsSuggest[] };
  answerPlaceholder?: string;
  onDelete: (index: number) => void;
  onChange: (index: number, value: string, name: string) => void;
  deleteDisable?: boolean;
  errors?: Record<string, any>;
  urgentErrors?: Record<string, any>;
  isNeedToShowError?: boolean;
}
const FieldsCollections = ({
  row,
  onDelete,
  onChange,
  answerPlaceholder,
  deleteDisable,
  errors,
  urgentErrors,
  isNeedToShowError,
}: FieldsCollectionsProps) => {
  const tooltipId = useId();
  const onDeleteInner = useCallback(() => {
    onDelete(row.index);
  }, [onDelete, row.index]);

  const answerPlaceholderInner = row.suggests
    ? row.suggests.find(suggest => suggest.question === row.question)?.answerPlaceholder
    : answerPlaceholder;

  const isNeedToShowQuestionsError = isNeedToShowError || urgentErrors?.[`${row.index}.question`];
  const isNeedToShowAnswerError = isNeedToShowError || urgentErrors?.[`${row.index}.answer`];
  return (
    <div className={styles.row}>
      <div className={styles.row__fields}>
        <div className={styles.field}>
          <InputWithDropdown
            type='text'
            onOptionClick={el => {
              onChange(row.index, el.value.toString(), 'question');
            }}
            className={styles.dropDown}
            onChange={val => {
              onChange(row.index, val, 'question');
            }}
            dropdownOptions={(row.suggests || []).map(option => ({
              value: option.question,
              label: option.question,
            }))}
            value={row.question || ''}
            invalid={isNeedToShowQuestionsError && !!errors?.[`${row.index}.question`]}
            errorText={isNeedToShowQuestionsError && errors?.[`${row.index}.question`]}
            dontClearOnBlur
            showAllOptionsWhenValueIsEmpty
            menuPositionFixed
          />
        </div>
        <div className={styles.field}>
          <TextareaAutosize
            className={styles.textArea}
            value={row.answer || ''}
            rowsMin={1}
            rowsMax={20}
            invalid={isNeedToShowAnswerError && !!errors?.[`${row.index}.answer`]}
            errorText={isNeedToShowAnswerError && errors?.[`${row.index}.answer`]}
            placeholder={answerPlaceholderInner}
            bsSize='md'
            onChange={val => onChange(row.index, val, 'answer')}
          />
        </div>
      </div>
      <div
        id={tooltipId}
        className={cn({
          preventPointerEvents: deleteDisable,
        })}
      >
        <Button
          className={styles.deleteBtn}
          type='button'
          color='secondary'
          disabled={deleteDisable}
          size='md'
          withoutPadding
          flat
          onClick={onDeleteInner}
        >
          <Icon name='farTrashAlt' />
        </Button>
      </div>
      {deleteDisable && (
        <WizardTooltip trigger='hover' placement='top' target={tooltipId}>
          {t('FAQSkill:DeleteLastQuestion:Tooltip')}
        </WizardTooltip>
      )}
    </div>
  );
};

export default React.memo(FieldsCollections);
