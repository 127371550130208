import { t } from 'localization';
import { AutomessageSkillConfig, RealSkill, SkillType } from '../types';

export const autoMessageConfig = (): AutomessageSkillConfig => ({
  type: SkillType.AUTOMESSAGE,
  skillName: RealSkill.AUTOMESSAGE,
  title: t('AutoMessageSequence:Skill:title'),
  description: t('AutoMessageSequence:Skill:Description'),
  shortDescription: t('AutoMessageSequence:Skill:ShortDescription'),
  orderWeight: 60,
  isSpecial: true,
  skillVersion: 1,
  startState: '',
  language: '',
  withBaseInfo: false,
  withOperator: false,
  withMenu: false,
  denySkills: '*',
});
