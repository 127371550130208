import React, { useCallback, useMemo } from 'react';
import classes from '../graph-way/graphWay.module.scss';
import { t } from '../../../../localization';
import { NewTemplateCard } from '../graph-way/card';
import { BASE_WIZARD_PATH } from '../../shared/consts';
import { IconProps } from '@just-ai/just-ui';
import history from 'appHistory';
import { useAppSelector } from '../../../../storeHooks';
import { GA } from 'pipes/pureFunctions';

type CallWay = {
  title: string;
  summary: string;
  icon: IconProps['name'];
  type: 'DIALER' | 'IVR';
};

const CALL_TYPES = (): CallWay[] => [
  {
    title: t('CallType_DIALER:Title'),
    summary: t('CallType_DIALER:Summary'),
    icon: 'falPhoneVolume',
    type: 'DIALER',
  },
  {
    title: t('CallType_IVR:Title'),
    summary: t('CallType_IVR:Summary'),
    icon: 'falPhonePlus',
    type: 'IVR',
  },
];

export const CallsTypeSelect = () => {
  const { location } = useAppSelector(store => ({
    location: store.router.location,
  }));

  const _CALL_TYPES = useMemo(() => CALL_TYPES(), []);

  const goToTemplatePage = useCallback(
    (callType: CallWay) => {
      let state = location.hasOwnProperty('state') ? (location.state as object) : {};
      if (typeof state !== 'object') {
        state = {
          state: location.state,
        };
      }
      GA(undefined, 'Calls_Type_Select', undefined, callType.type === 'DIALER' ? 'Outbound' : 'Inbound');
      history.push({
        pathname:
          BASE_WIZARD_PATH +
          (location.pathname.includes('/calls-type-select') ? '/calls-type-select' : '') +
          '/aimygraph',
        /*'/graph-choose',*/
        state: {
          ...state,
          callType: callType.type,
        },
      });
    },
    [location]
  );

  return (
    <div className={classes.graphWayContainer}>
      <h1 className='mt-3 mb-6 text-center'>{t('CallTypeSelect:Title')}</h1>

      <div className={classes.graphWayTemplatesContainer}>
        {_CALL_TYPES.map(callType => (
          <NewTemplateCard
            iconName={callType.icon}
            title={callType.title}
            summary={callType.summary}
            key={callType.title}
            onClick={() => goToTemplatePage(callType)}
          />
        ))}
      </div>

      {/*<pre>{JSON.stringify(filteredTemplatesList, null, 4)}</pre>*/}
    </div>
  );
};
