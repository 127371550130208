import React, { Component } from 'react';
import { SvgIcon, withStyles } from 'altreidsds';
import classNames from 'classnames';

class ChannelDeployStatus extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    /**
         * OK,
         TEST_FAILED - провалены тесты,
         OUTDATED - контент изменён, но изменения не вылиты,
         DEPLOY_FAILED - деплой провален,
         UNAVAILABLE - статус недоступен (редкие случаи, когда всё пошло совсем не так
         IN_PROGRESS - идёт деплой
         * */
    const { classes, className } = this.props;
    if (Boolean(this.props.status)) {
      return (
        <span className={classes.dib}>
          <SvgIcon
            className={classNames(
              className,
              classes.baseIcon,
              this.props.status.toLowerCase() === 'ok' && classes.green,
              this.props.status.toLowerCase() === 'deploy_failed' && classes.red,
              this.props.status.toLowerCase() === 'unavailable' && classes.dead,
              this.props.status.toLowerCase() === 'in_progress' && classes.pending,
              classes[this.props.size]
            )}
          >
            <path d='M7.5,4 C4.46243388,4 2,6.46243388 2,9.5 C2,10 2.09,10.5 2.22,11 L6.3,11 L7.57,7.63 C7.87,6.83 9.05,6.75 9.43,7.63 L11.5,13 L12.09,11.58 C12.22,11.25 12.57,11 13,11 L21.78,11 C21.91,10.5 22,10 22,9.5 C22,6.46243388 19.5375661,4 16.5,4 C14.64,4 13,4.93 12,6.34 C11,4.93 9.36,4 7.5,4 Z M3,12.5 C2.44771525,12.5 2,12.9477153 2,13.5 C2,14.0522847 2.44771525,14.5 3,14.5 L5.44,14.5 L11,20 C12,20.9 12,20.9 13,20 L18.56,14.5 L21,14.5 C21.5522847,14.5 22,14.0522847 22,13.5 C22,12.9477153 21.5522847,12.5 21,12.5 L13.4,12.5 L12.47,14.8 C12.07,15.81 10.92,15.67 10.55,14.83 L8.5,9.5 L7.54,11.83 C7.39,12.21 7.05,12.5 6.6,12.5 L3,12.5 Z' />
          </SvgIcon>
          {this.props.count > 1 ? <span className={classes.counter}>{this.props.count}</span> : null}
        </span>
      );
    } else {
      return null;
    }
  }
}

ChannelDeployStatus.defaultProps = {
  count: 0,
};

const styles = theme => ({
  dib: {
    display: 'inline-block',
  },
  green: {
    fill: 'var(--success)',
  },
  dead: {
    fill: 'var(--danger)',
  },
  red: {
    fill: 'var(--pink)',
  },
  pending: {
    fill: 'var(--gray-700)',
  },
  medium: {
    width: '24px',
    height: '24px',
    fontSize: '24px',
  },
  mini: {
    width: '16px',
    height: '16px',
    fontSize: '16px',
  },
  baseIcon: {
    verticalAlign: 'middle',
  },
  counter: {
    margin: '0 3px',
    fontSize: '12px',
  },
});
export default withStyles(styles, { withTheme: true })(ChannelDeployStatus);
