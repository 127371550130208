import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import classes from './PhoneNumberInput.module.scss';

import countries from 'countries-list/dist/countries.min';

import { getUserLanguage } from '../../pipes/pureFunctions';
import localize from 'localization';
import { connect } from 'react-redux';

const { translate } = localize;

const russianCountriesName = require('i18n-iso-countries/langs/ru.json');

export function getCountryList(lang, isEuroInstance = false) {
  let countryList = [];
  const mainList = [];
  const sortList = {};
  const topCountries = isEuroInstance ? ['US', 'GB', 'BR', 'ES'] : ['KZ', 'RU', 'UA', 'BY'];

  Object.keys(countries).map(countryKey => {
    const { name, phone } = countries[countryKey];
    const localizeCountryName = lang === 'ru' ? russianCountriesName.countries[countryKey] : name;
    let phoneCode = phone.split(',')[0];
    if (countryKey === 'KZ') {
      phoneCode = '7';
    }
    const value = [countryKey, localizeCountryName, localizeCountryName.toLowerCase(), phoneCode];

    if (topCountries.includes(countryKey)) {
      sortList[countryKey] = value;
    } else {
      mainList.push(value);
    }
  });

  mainList.sort((a, b) => (a[1] > b[1] ? 1 : -1));

  if (isEuroInstance) {
    countryList = [sortList['US'], sortList['GB'], sortList['BR'], sortList['ES'], ...mainList];
  } else {
    countryList = [sortList['RU'], sortList['KZ'], sortList['UA'], sortList['BY'], ...mainList];
  }

  return countryList;
}

function CountryList({
  showChooseCountry,
  setCode,
  setShowChooseCountry,
  containerRef,
  countryIsoCode,
  preventSetDefault,
}) {
  const [search, setSearch] = useState('');
  const onSearch = useCallback(event => setSearch(event.target.value), []);

  const lang = getUserLanguage();
  const countriesList = useMemo(() => getCountryList(lang), [lang]);

  const filteredCountryList = useMemo(() => {
    if (search.trim().length === 0) return countriesList;
    const donwcaseSearch = search.toLowerCase();
    return countriesList.filter(country => country[2].includes(donwcaseSearch) || country[3].includes(donwcaseSearch));
  }, [countriesList, search]);

  useEffect(() => {
    if (countryIsoCode && !preventSetDefault) {
      const countryByIsoCode = filteredCountryList.find(country => country[0] === countryIsoCode);
      if (countryByIsoCode) setCode(countryByIsoCode[3], countryByIsoCode[1]);
    }
  }, [countryIsoCode, filteredCountryList, preventSetDefault, setCode]);

  return (
    <div className={classNames(classes.countryListContainer, showChooseCountry && classes.visible)} ref={containerRef}>
      <div className={classes.searchInput}>
        <input
          type='text'
          value={search}
          onChange={onSearch}
          tabIndex={showChooseCountry ? 0 : -1}
          placeholder={translate('PhoneInput search placeholder')}
        />
        <SearchIcon />
      </div>
      <div className={classes.countryShadowWrapper}>
        <div className={classes.countryList}>
          {filteredCountryList.map(country => (
            <button
              className={classes.country}
              type='button'
              key={country[0]}
              tabIndex={-1}
              onClick={() => {
                setCode(country[3], country[1]);
                setShowChooseCountry(false);
              }}
            >
              <div>
                <div className={classes.flagWrapper}>
                  <div>
                    <span className={`flag-icon flag-icon-${country[0].toLowerCase()} ${classes.flag}`} />
                  </div>
                </div>
                <span style={{ fontWeight: ['7', '76', '380', '375'].includes(country[3]) ? '600' : 'normal' }}>
                  {country[1]}
                </span>
              </div>
              <span>+{country[3]}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

function SearchIcon() {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3905 12.5786H14.2939L20 18.2962L18.2962 20L12.5786 14.2939V13.3905L12.2699 13.0703C10.9663 14.191 9.27387 14.8656 7.43282 14.8656C3.32762 14.8656 0 11.538 0 7.43282C0 3.32762 3.32762 0 7.43282 0C11.538 0 14.8656 3.32762 14.8656 7.43282C14.8656 9.27387 14.191 10.9663 13.0703 12.2699L13.3905 12.5786ZM2.28702 7.43282C2.28702 10.2802 4.58548 12.5786 7.43282 12.5786C10.2802 12.5786 12.5786 10.2802 12.5786 7.43282C12.5786 4.58548 10.2802 2.28702 7.43282 2.28702C4.58548 2.28702 2.28702 4.58548 2.28702 7.43282Z'
        fill='var(--gray-200)'
      />
    </svg>
  );
}

function mapStateToProps(state) {
  return {
    countryIsoCode: state.CurrentUserReducer.currentUser?.tariff?.countryIsoCode,
  };
}

export default React.memo(connect(mapStateToProps)(CountryList));
