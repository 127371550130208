import React, { useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useToggle } from '@just-ai/just-ui';

import { ScenarioSkillConfig } from 'modules/TemplatesWizard/types';
import SkillCollapseContainer from 'modules/TemplatesWizard/primitives/SkillCollapseContainer';
import FieldBuilder from 'modules/TemplatesWizard/FieldBuilder';

import { BaseSkill, ViewComponentProps } from '../BaseSkill';

interface SkillVewProps extends ViewComponentProps {
  skill: ScenarioSkill;
}
const SkillVew = React.memo(({ skill, index }: SkillVewProps) => {
  const innerState = useRef<any>(skill.initialValues || {});
  const defaultValues = useRef<any>({});
  const blocksErrors = useRef<Record<string, any>>({});
  const [isShowErrors, showError, hideError] = useToggle(false);

  const onChange = useCallback(
    (value: string, name: string, isDefaultValue: boolean) => {
      if (!isDefaultValue) {
        innerState.current[name] = value;
        skill.notify('onFieldsChange', { value: innerState.current, isDefaultValue });
        return;
      }
      defaultValues.current[name] = value;
      skill.notify('onFieldsChange', { value: defaultValues.current, isDefaultValue });
    },
    [skill]
  );

  const onErrors = useCallback(
    (envName: string, errors: any | null) => {
      if (errors === null) {
        delete blocksErrors.current[envName];
      } else {
        blocksErrors.current[envName] = errors;
      }
      skill.isValid = isEmpty(blocksErrors.current);
    },
    [skill]
  );

  const onValidate = useCallback(() => {
    const isValid = skill.isValid;
    !isValid ? showError() : hideError();
    return isValid;
  }, [hideError, showError, skill.isValid]);

  return (
    <SkillCollapseContainer skill={skill} index={index} onValidate={onValidate}>
      <div style={{ marginTop: 8, marginBottom: 20 }}>{skill.skillConfig.description}</div>
      <FieldBuilder
        onErrors={onErrors}
        fields={skill.skillConfig.fields || []}
        isShowErrors={isShowErrors}
        initialValues={skill.initialValues}
        onChange={onChange}
      />
    </SkillCollapseContainer>
  );
});

export class ScenarioSkill extends BaseSkill {
  constructor(public skillConfig: ScenarioSkillConfig) {
    super(skillConfig);
  }

  ViewComponent = (props: ViewComponentProps) => {
    return <SkillVew skill={this} {...props} />;
  };
}
